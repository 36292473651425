<template>
    <v-card
        class="full-width"

    >
        <v-toolbar
            elevation="0"
            dense
            flat
        >
            <v-tabs
                show-arrows
                grow
                class="elevation-1"
                v-model="currentDefectTabIndex"
            >
                <v-tab v-for="(itemPart, i) in productParts" :key="i">
                    {{ getProductsPartById(itemPart.parts_id)+': '+itemPart.title}}
                    <v-spacer/>
                    <v-btn
                        icon
                        color="primary"
                        @click.stop="openDialogEditPartName(itemPart.id)"
                    >
                        <v-icon small>edit</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="pink"
                        @click.stop="deleteProductPart(itemPart.id)"
                        small
                    >
                        <v-icon small>delete</v-icon>
                    </v-btn>
                </v-tab>
            </v-tabs>
            <v-btn
                color="success"
                @click.stop="addPart"
                fab
                dark
                x-small
                class="ml-4"
            >
                <v-icon small dark>add</v-icon>
            </v-btn>
        </v-toolbar>
        <v-tabs-items class="elevation-1 text-left" v-model="currentDefectTabIndex">
            <v-tab-item
                v-for="(itemPart, i) in productParts"
                :key="i"
                class="scroll-tabs defects_list"
            >
                <v-list
                    v-if="productsDefects"
                    class="defects_list"
                    expand
                    dense
                >
                    <v-list-group
                        v-for="(ProductsDefects, i) in getProductsDefectsByPartId(itemPart.parts_id)"
                        :key="i"
                        no-action
                        multiple
                        v-model="ProductsDefects.expanded"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ProductsDefects.title+
                                ' ('+getCountProductDefects(itemPart.id, ProductsDefects.defects_groups_id)+')' + (defects ? '' : '')}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="defects in ProductsDefects.defects"
                            :key="defects.defects_id"
                        >
                            <v-list-item-content class="py-0">
                                <v-list-item-title
                                    class="pl-2 ma-0 align-top"
                                >

                                    <v-checkbox
                                        dense
                                        hide-details
                                        class="pa-0 ma-0"
                                        :input-value="getProductDefect(itemPart, ProductsDefects.defects_groups_id, defects.defects_id)"
                                        @change="changeProductDefect(itemPart, defects, ProductsDefects, $event)"
                                    >
                                        <template v-slot:label>
                                            <div style="font-size: 0.8em;" class="black--text">{{defects.title}}</div>
                                        </template>
                                    </v-checkbox>

                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </v-tab-item>
        </v-tabs-items>

        <v-dialog
            v-model="dialogEditPartName"
            max-width="30%"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Edit part name
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-if="currentPart"
                        v-model="currentPart.title"
                        label=""
                        dense
                        single-line
                        autofocus
                    ></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="darken-1"
                        text
                        @click="cancelEditPartName"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="savePartName"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="addProductPartDialog"
            max-width="30%"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Add part
                </v-card-title>

                <v-card-text>
                    <v-select
                        :items="productsDefects"
                        item-text="title"
                        item-value="parts_id"
                        hide-details
                        dense
                        cache-items
                        autofocus
                        v-model="newPart"
                    ></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="darken-1"
                        text
                        @click="closeProductPartDialog"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click.s.stop="saveNewProductPart"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <confirm ref="confirm"/>
    </v-card>
</template>

<script>

    import { mapGetters, mapActions } from "vuex";
    import { mapCacheActions } from 'vuex-cache';

    import Confirm from '@/components/Confirm'

    export default {
        name: "Defects",
        props: {
            productsId: {
                type: Number|String,
                required: true
            }
        },
        mounted() {
            this.initProductsDefects()
            this.getPorductParts()
        },
        data: () => ({
            currentDefectTabIndex: 0,
            productParts: [],
            dialogEditPartName: false,
            currentPart: null,
            model: [1],
            defects: 1,
            addProductPartDialog: false,
            newPart: null
        }),
        computed:{
            ...mapGetters(['productsDefects'])
        },
        methods: {
            closeProductPartDialog() {
                this.addProductPartDialog = false
                this.newPart = null
            },
            deleteProductPart(id) {
                axios
                    .delete(`/api/products_parts/${id}`)
                    .then(response => {
                        this.getPorductParts()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            saveNewProductPart(){
                console.log('saveNewProductPart', this.newPart)
                axios
                    .post('/api/products_parts', { 'products_id': this.productsId, 'parts_id': this.newPart })
                    .then(response => {
                        this.getPorductParts()
                        this.addProductPartDialog = false
                        this.newPart = null
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getProductsPartById(parts_id){
                let title = ''
                const parts = _.find(this.productsDefects, { 'parts_id': parseInt(parts_id)})
                if (parts) {
                    title = parts.title
                }
                return title
            },
            changeProductDefect(itemPart, defects, ProductsDefects, $event) {
                if ($event) { //add defect
                    axios
                        .post('/api/products_defects', { 'products_id': this.productsId, 'defects_id': defects.defects_id, 'products_parts_id': itemPart.id })
                        .then(response => {
                            this.addProductDefect(itemPart.id, response.data)
                        })
                        .catch(err => {
                        console.log(err)
                    })
                } else { //delete defect
                    axios
                        .delete(`/api/products_defects/${this.getProductDefectId(itemPart.id, defects.defects_groups_id, defects.defects_id)}`)
                        .catch(err => {
                            console.log(err)
                        })
                    this.removeProductDefectById(itemPart.parts_id, defects.defects_groups_id, defects.defects_id)
                }
            },
            getCountProductDefects(parts_id, defects_groups_id) {
                let productsParts = _.find(this.productParts, { 'id': parseInt(parts_id)})
                let countDefects = 0
                if (productsParts && productsParts.products_defects) {
                    for (const products_defects in productsParts.products_defects) {
                        if (productsParts.products_defects[products_defects].defect.defects_groups_id == defects_groups_id) {
                            countDefects++
                        }
                    }
                }
                return countDefects
            },
            getProductDefect (itemPart, defects_groups_id, defects_id) {
                let productsParts = _.find(this.productParts, { 'id': parseInt(itemPart.id)})
                if (productsParts && productsParts.products_defects) {
                    let productsDefect = _.find(productsParts.products_defects, { 'defects_id': parseInt(defects_id)})
                    if (productsDefect && productsDefect.defect) {
                        return true
                    }
                }
                return false
            },
            getProductDefectId (parts_id, defects_groups_id, defects_id) {
                let productsParts = _.find(this.productParts, { 'id': parseInt(parts_id)})
                if (productsParts && productsParts.products_defects) {
                    const productsDefect = _.find(productsParts.products_defects, { 'defects_id': parseInt(defects_id)})
                    if (productsDefect && productsDefect.defect) {
                        return productsDefect.id
                    }
                }
                return null
            },
            addProductDefect(parts_id, defects) {
                let productsParts = _.find(this.productParts, { 'id': parseInt(parts_id)})
                if (productsParts && productsParts.products_defects) {
                    productsParts.products_defects.push(defects)
                }
            },
            removeProductDefectById(parts_id, defects_groups_id, defects_id){
                _.forEach(this.productParts, function(value, key) {
                    if (value.parts_id == parseInt(parts_id)) {
                        _.remove(value.products_defects, { 'defects_id': parseInt(defects_id)})
                    }
                });
                this.defects = (Math.random()*1e32).toString(36)
            },
            castArray(obj) {
               return _.castArray(obj)
            },
            addPart(){
                this.addProductPartDialog = true
            },
            async deletePart(partId) {
                if (await this.$refs.confirm.open('Delete part', 'Are you sure?', { color: 'success' })) {
                    this.currentDefectTabIndex = 0
                }
            },
            openDialogEditPartName(partId) {
                this.currentPart = this.getProductPartById(partId)
                this.tmpPartTitle = this.currentPart.title
                this.dialogEditPartName = true
            },
            savePartName() {
                this.dialogEditPartName = false
                axios
                    .put(`/api/products_parts/${this.currentPart.id}`, { 'title': this.currentPart.title})
                    .then(response => {
                        this.getPorductParts()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            cancelEditPartName() {
                this.currentPart.title = this.tmpPartTitle
                this.dialogEditPartName = false
            },
            getProductsDefectsByPartId(partId){
                let productsDefects = _.find(this.productsDefects, { 'parts_id': parseInt(partId)})
                return _.castArray(productsDefects.groups)
            },
            getProductPartById(partId) {
                return _.find(this.productParts, {'id': partId})
            },
            getPorductParts(productsId) {
                axios
                    .get(`/api/products_parts/${this.productsId}`)
                    .then(response => {
                        this.productParts = response.data
                    }).catch(err => {
                    console.log(err)
                })
            },
            ...mapActions(['initProductsDefects'])
        },
        components:{
            Confirm
        }
    }
</script>

<style scoped>

.defects_list .v-list-item {
    display: inline-block;
    padding-left: 16px !important;
    width: 25%;
    /*color: black;*/
    overflow: hidden;
    min-height: unset !important;
    /*min-height: 23px;
    border-bottom-color: #121b3e;
    border-bottom-width: 1px;
    border-bottom-style: solid;*/
}

.defects_list .v-list-item__title, .defects_list .v-chip {
    white-space: unset !important;
    height: fit-content;
}

    .scroll-tabs{
        display: grid;
        /*grid-template-rows: 48px  calc(100vh - 200px);*/
        overflow-y: auto;
    }

</style>