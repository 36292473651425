<template>
    <div class="width-100">
        <v-row no-gutters>
            <v-col class="d-flex">
                <v-data-table
                        :headers="headers"
                        :items="orders_"
                        item-key="order_id"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :options.sync="options"
                        :server-items-length="pagination.totalItems"
                        :loading="loading"
                        :fixed-header="true"
                        :hide-default-header="ordersPageConfig.hideDefaultHeader"
                        sort-desc
                        class= "elevation-1 width-100 orders-table"
                        :footer-props="{
                            itemsPerPageOptions: pagination.rowsPerPageItems,
                            showFirstLastPage: true,
                           }"
                >
                    <template v-slot:top>
                        <v-divider></v-divider>
                        <v-toolbar dense flat color="white" class="pa-0 ma-0">
                            <v-btn class="pl-1" small icon color="pink" :disabled="addNewOrderDialog" @click="addNewOrderDialog = true"><v-icon>mdi-tab-plus</v-icon></v-btn>
                            <v-divider vertical style="float:left" class="ml-2 mr-0"></v-divider>
                            <v-btn large icon color="green" @click="getDataFromApi"><v-icon>mdi-cached</v-icon></v-btn>
                            <v-divider vertical class="mr-1" v-if="tags.length"></v-divider>
                            <v-row justify="space-around" style="max-width: 95%;">
                                <v-col cols="12" class="py-0">
<!--                                    <v-chip-group
                                            active-class="primary&#45;&#45;text"
                                            show-arrows
                                            v-if="tags.length"
                                    >
                                        <v-chip v-for="tag in tags" :key="tag">
                                            {{ tag }}
                                        </v-chip>
                                    </v-chip-group>-->
                                    <order-filters-bar
                                        :drawer="filterDrawer"
                                        :filtersLength="filtersLength"
                                        @closeMenu="closeFiltersMenu"
                                        @filterOrders="filterOrders"
                                        @clearFilters="clearFilters"/>
                                </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-divider vertical style="float:left"></v-divider>
                            <v-btn large icon color="green" @click="getDataFromApi"><v-icon>mdi-cached</v-icon></v-btn>
                            <v-divider vertical style="float:left"></v-divider>
                            <v-menu
                                offset-y
                                open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        x-small icon
                                        class="mx-2"
                                        color="grey"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-tools</v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                    dense
                                    nav
                                    class="caption"
                                >
                                    <v-list-item dense class="caption">
                                        <v-checkbox dense hide-details label="Hide headers" v-model="ordersPageConfig.hideDefaultHeader"/>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-checkbox dense hide-details label="Hide duty date" v-model="ordersPageConfig.hideDutyDate"/>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-checkbox dense hide-details label="Hide site" v-model="ordersPageConfig.hideSite"/>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-checkbox dense hide-details label="Hide stock data" v-model="ordersPageConfig.hideStockData"/>
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-checkbox dense hide-details label="Hide eBay fee" v-model="ordersPageConfig.hideEbayFee"/>
                                    </v-list-item>

                                </v-list>
                            </v-menu>
                            <v-menu
                                offset-y
                                open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        x-small icon
                                        class="mr-2"
                                        color="green"
                                        :disabled="pagination.totalItems == 0 || pagination.totalItems > 200"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-export</v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                    dense
                                    nav
                                >
                                    <v-list-item>
                                        <v-list-item-title>Orders</v-list-item-title>
                                        <v-btn small icon class="mr-2" color="green" :disabled="pagination.totalItems > 200" @click="exportOrders"><v-icon>mdi-download</v-icon></v-btn>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>Orders products</v-list-item-title>
                                        <v-icon>mdi-download</v-icon>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-badge
                                        :content="filtersLength"
                                        :value="filtersLength"
                                        color="green"
                                        overlap
                                        right
                                >
                                    <span slot="badge" @click="getDataFromApi">{{ filtersLength }}</span>
                                    <v-btn small icon color="blue"
                                           @click.stop="filterDrawer = !filterDrawer"
                                    >
                                        <v-icon v-if="!filtersLength"
                                        >
                                            mdi-filter-outline
                                        </v-icon>
                                        <v-icon v-else
                                        >
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </v-badge>
                        </v-toolbar>
                        <v-divider></v-divider>
                    </template>
                    <template slot="item" slot-scope="props">
                        <tr :key="props.item.orders_id" :class="props.item.orders_id === selectedRow ? 'teal lighten-5' : null" @click="toggleSelectedRow(props.item.orders_id)">
                            <td style="width: 55px; min-width: 55px; max-width: 55px;">
                                <table-id
                                        :item="props.item"
                                        @openOrderDialog="openOrderDialog"
                                />
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="width: 75px; min-width: 75px; max-width: 75px;">
                                <table-actions
                                        :item="props.item"
                                        @openOrderDialog="openOrderDialog"
                                        @openInvoiceDialog="openInvoiceDialog"
                                        @openCelnicaDialog="openCelnicaDialog"
                                        @openPrintDialog="openPrintDialog"
                                        @openBuyerCheckoutMessageDialog="openBuyerCheckoutMessageDialog"
                                        @openShippingDialog="openShippingDialog"
                                />

                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="vertical-align: top; max-width: 30vw; min-width:20vw;">
                                <order-info
                                        :item="props.item"
                                        :hideStockData="ordersPageConfig.hideStockData"
                                        :hideEbayFee="ordersPageConfig.hideEbayFee"
                                        @openProductDialog="openProductDialog"
                                ></order-info>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="vertical-align: top; min-width: 30vw; max-width:40vw;">
                                <div class="pa-1 text-left caption">
                                    <p class="ma-0 font-weight-bold">{{ props.item.delivery_name }}</p>
                                    <p class="ma-0">{{ props.item.delivery_street_address}}</p>
                                    <p class="ma-0">{{ props.item.delivery_city ? props.item.delivery_city+',' : '' }} {{ props.item.delivery_postcode  ? props.item.delivery_postcode+',' : ''}} {{ props.item.delivery_state ? props.item.delivery_state+',' : ''}} <br/> <span class="font-weight-bold">{{ props.item.delivery_country }}</span></p>
                                    <p class="ma-0 font-weight-medium">Tel: {{ props.item.customers_telephone }}</p>
                                </div>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="min-width: 90px; max-width: 125px;">
                                <table-total :item="props.item"/>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="min-width: 100px; max-width: 100px;" class="caption">
                                <v-row v-if="props.item.date_purchased" style="width: 70px;" class="ma-0 pa-0 ml-1 Pending">
                                    <v-col cols="6" class="ma-0 pa-0 text-left">Pend:</v-col>
                                    <v-col cols="6" class="ma-0 pa-0">{{ $moment(props.item.date_purchased).format('DD&nbsp;MMM&nbsp;YY') }}</v-col>
                                </v-row>
                                <v-row v-if="props.item.date_paid" style="width: 70px;" class="ma-0 pa-0 ml-1  Paid">
                                    <v-col cols="6" class="ma-0 pa-0 text-left">Paid:</v-col>
                                    <v-col cols="6" class="ma-0 pa-0">{{ $moment(props.item.date_paid).format('DD&nbsp;MMM&nbsp;YY') }}</v-col>
                                </v-row>
                                <v-row v-if="props.item.date_shipped" style="width: 70px;" class="ma-0 pa-0 ml-1  Delivered">
                                    <v-col cols="6" class="ma-0 pa-0 text-left">Ship:</v-col>
                                    <v-col cols="6" class="ma-0 pa-0">{{ $moment(props.item.date_shipped).format('DD&nbsp;MMM&nbsp;YY') }}</v-col>
                                </v-row>
                                <v-row v-if="props.item.date_refund" style="width: 70px;" class="ma-0 pa-0 ml-1  Refunded">
                                    <v-col cols="6" class="ma-0 pa-0 text-left">Ref:</v-col>
                                    <v-col cols="6" class="ma-0 pa-0">{{ $moment(props.item.date_refund).format('DD&nbsp;MMM&nbsp;YY') }}</v-col>
                                </v-row>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="min-width: 70px; max-width: 70px;" class="caption" v-if="!ordersPageConfig.hideDutyDate">
                               <span v-if="props.item.duty_date && props.item.duty_date.duty_date" class="pa-2">
                                    {{ $moment(props.item.duty_date.duty_date).format('DD&nbsp;MMM&nbsp;YY') }}
                                </span>
                            </td>
                            <td v-if="!ordersPageConfig.hideDutyDate">
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="min-width: 70px; max-width: 70px;" class="caption">
                                <span :class="props.item.orders_status_name.orders_status_name" class="pa-2">
                                    {{ props.item.orders_status_name.orders_status_name}}
                                </span>
                            </td>
                            <td v-if="!ordersPageConfig.hideSite">
                                <v-divider vertical></v-divider>
                            </td>
                            <td style="min-width: 70px; max-width: 70px;" class="caption" v-if="!ordersPageConfig.hideSite">
                                 <span v-if="props.item.site">
                                    {{  props.item.site.substring( props.item.site.indexOf("-") + 1)  }}
                                     <!--                            {{ props.item.site.split('-').pop() }}-->
                                </span>
                            </td>
                        </tr>
                    </template>

                    <template v-if="loading" v-slot:body.append>
                        <tr>
                            <td :colspan="headers.length+1" class="pa-0" style="height: 2px;"><v-progress-linear color="blue" :height="2" indeterminate></v-progress-linear></td>
                        </tr>
                    </template>

                </v-data-table>
                <div class="filters-wrapper">
                    <order-filters
                            :drawer="filterDrawer"
                            :filtersLength="filtersLength"
                            @closeMenu="closeFiltersMenu"
                            @filterOrders="filterOrders"
                            @clearFilters="clearFilters"/>
                </div>

            </v-col>
        </v-row>

        <scrollable-dialog :show="showRefundDialog" :fullscreen="false" :scrollable="true" width="auto ">
            <template v-slot:content>
                <refund-order
                        :order="currentOrder"
                        @closeDialog="closeRefundDialog"
                        @orderUpdated="orderUpdated"/>
            </template>
        </scrollable-dialog>

        <scrollable-dialog :show="showCancelDialog" :fullscreen="false" :scrollable="true" width="auto ">
            <template v-slot:content>
                <cancel-order
                        :order="currentOrder"
                        @closeDialog="closeCancelDialog"
                        @orderUpdated="orderUpdated"/>
            </template>
        </scrollable-dialog>

        <scrollable-dialog :show="showInvoiceDialog" :fullscreen="false" :scrollable="true" width="70%">
            <template v-slot:content>
                <invoice
                        :order="currentOrder"
                        @closeDialog="closeInvoiceDialog"/>
            </template>
        </scrollable-dialog>

<!--        <scrollable-dialog :show="addNewOrderDialog" :fullscreen="false" :scrollable="true" width="auto ">
            <template v-slot:content>
                <new-order
                    @newOrderAdded="newOrderAdded"
                    @closeDialog="addNewOrderDialog = false"
                />
            </template>
        </scrollable-dialog>-->

        <v-dialog name="NewOrderDialog" v-model="addNewOrderDialog" :fullscreen="false" width="auto " :scrollable="true" :key="'neworderdialog'" hide-overlay @click:outside="addNewOrderDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>New order</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="addNewOrderDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <new-order
                        @newOrderAdded="newOrderAdded"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="CelnicaDialog" v-model="showCelnicaDialog" :fullscreen="false" width="60%" :scrollable="true" :key="'celnica_'+currentOrderId" hide-overlay @click:outside="showCelnicaDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>Select orders for export to celnisprava.cz</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showCelnicaDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <celnica
                        :orders="currentCelnicaOrders"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="ShippingDialog" v-model="showShippingDialog" :fullscreen="false" width="60%" :scrollable="true" :key="'ShippingDialog_'+currentOrderId" hide-overlay @click:outside="showShippingDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{ currentOrderId }} - Tracking numbers</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showShippingDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <shipping
                        :order="currentOrder"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="PrintDialog" v-model="showPrintDialog" :fullscreen="false" width="60%" :scrollable="true" :key="'printdialog_'+currentOrderId" hide-overlay @click:outside="showPrintDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{currentOrderId}} - eBay payments</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showPrintDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-layout
                        v-if="startGettingPrintData"
                        class="ma-4 pa-4 text-center font-weight-bold"
                        justify-center
                    >
                        Please wait!
                    </v-layout>
                    <print-dialog
                        v-else
                        :order="currentOrder"
                        :EbayPaypal="currentEbayPaypal"
                    />

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="BuyerCheckoutMessageDialog" v-model="showBuyerCheckoutMessageDialog" :fullscreen="false" width="60%" :scrollable="true" :key="'BuyerCheckoutMessageDialog_'+currentOrderId" hide-overlay @click:outside="showBuyerCheckoutMessageDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{currentOrderId}} - Buyer Checkout Message</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showBuyerCheckoutMessageDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-3">
                    {{currentBuyerCheckoutMessage}}
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--<custom-dialog :show="addNewOrderDialog"
                       :title="'Add new order'"
                       @closeDialog="addNewOrderDialog = false"
        >

            <template v-slot:content>
                <new-order @newOrderAdded="newOrderAdded" v-if="addNewOrderDialog"></new-order>
            </template>
        </custom-dialog>-->
    </div>
</template>

<script>
    import ordersApi from '@/services/orders.api';
    import OrderFilters from './components/OrderFilters';
    import OrderFiltersBar from './components/OrderFiltersBar';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import EventBus from "@/event-bus";
    import TableId from './components/TableId';
    import TableTotal from "./components/TableTotal";
    import OrderInfo from './components/OrderInfo';
    import TableActions from './components/TableActions';
    import ScrollableDialog from "@/components/ScrollableDialog";
    import RefundOrder from './components/RefundOrder';
    import CancelOrder from "./components/CancelOrder";
    import Celnica from "./components/Celnica";
    import Shipping from "./components/Shipping";
    import PrintDialog from "./components/PrintDialog";
    import Invoice from "./components/Invoice";
    import NewOrder from "./components/NewOrder";

    export default {
        name: "Orders",
        data: () => ({
            sortDesc: false,
            headers: [
                {
                    text: 'ID',
                    value: 'orders_id',
                    align: 'center',
                    sortable: false,
                    width: '5%'
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Action',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                    width: '5%'
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Order',
                    value: 'info',
                    align: 'center',
                    sortable: false,
                    width: '25%'
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Address',
                    value: 'address',
                    align: 'center',
                    sortable: false,
                    width: '35%'
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Total',
                    value: 'total',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Dates',
                    value: 'date_purchased',
                    align: 'center',
                    sortable: true,
                    width: '80px',
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                /*{
                    text: 'Paid',
                    value: 'date_paid',
                    align: 'center',
                    sortable: true,
                    width: '80px',
                },
                {
                    text: 'Shipped',
                    value: 'date_shipped',
                    align: 'center',
                    sortable: true,
                    width: '80px',
                },*/
                {
                    text: 'Duty',
                    value: 'duty_date',
                    align: 'center',
                    sortable: true,
                    width: '80px',
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '',
                    value: '',
                    align: 'center',
                    sortable: false,
                    divider: true
                },
                {
                    text: 'Site',
                    value: 'site',
                    align: 'center',
                    sortable: false,
                },

            ],
            orders: [],
            options:{},
            totalOrders: 0,
            pagination: {
                descending: true,
                page: 1,
                rowsPerPage: 10,
                totalItems: 0,
                rowsPerPageItems: [10, 15, 20, 50, 100]
            },
            loading: false,
            filterDrawer: false,
            filtersLength: 0,
            ordersSearchType: "orderParams",
            filtersRequest: null,
            sortBy: ['date_purchased'],
            selectedRow: null,
            tags: [],
            currentCustomersId: null,
            currentOrderId: null,
            currentOrder: null,
            currentCelnicaOrders: [],
            currentProduct: {
                products_id: null,
                products_name: null
            },
            showRefundDialog: false,
            showCancelDialog: false,
            showInvoiceDialog: false,
            showCelnicaDialog: false,
            showPrintDialog: false,
            startGettingPrintData: false,
            currentEbayPaypal: { ebay: [], paypal: null},
            addNewOrderDialog: false,
            needLoadOrder: true,
            showBuyerCheckoutMessageDialog: false,
            currentBuyerCheckoutMessage: '',
            showShippingDialog: false,
            fab: false,
            ordersPageConfig: {
                hideDefaultHeader: false,
                hideDutyDate: false,
                hideStockData: false,
                hideSite: false,
                hideEbayFee: false,
                autoReloadSmartFilter: true,
            }
        }),
        computed: {
            orders_: {
                get() {
                    return JSON.parse(JSON.stringify(this.orders));
                },
                set(optionValue) {
                },
            },
            request: {
                get(){
                    let searchParam = {};
                    if(this.globalSearchParams && this.globalSearchParams.orderParams){
                        searchParam = this.globalSearchParams.orderParams;
                    }

                    if (this.filtersRequest == null) {
                        const ordersRequest = localStorage.getItem("ordersRequest");
                        if (ordersRequest) {
                            this.filtersRequest =  JSON.parse(ordersRequest)
                        }
                    }

                    if (this.orders_statusesList) {
                        const filledFilters = localStorage.getItem("ordersFilers");
                        if (filledFilters) {
                            let filledFilters_parse = JSON.parse(filledFilters);
                            this.settags(filledFilters_parse)
                        }
                    }

                    return{ ...searchParam, ...this.filtersRequest }
                },
                set(){
                    this.getDataFromApi()
                }

            },
            ...mapGetters([
                'globalSearchParams',
                'order',
                'orders_statusesList'
            ]),
        },
        mounted(){
            EventBus.$on('reloadOrders',  (type = "orderParams") => {
                this.ordersSearchType = type
                this.getDataFromApi()
            });

            EventBus.$on('searchOrder',  () => {
                this.resetOrdersCurrentPage()
            });


            if (localStorage.getItem('orders_page_config')) {
                this.ordersPageConfig = JSON.parse(localStorage.getItem('orders_page_config'));
            }

        },
        watch: {
            options: {
                handler () {
                    // console.log(this.options);
                    this.getDataFromApi()
                },
                deep: true,
            },
            ordersPageConfig: {
                handler () {
                    localStorage.setItem('orders_page_config', JSON.stringify(this.ordersPageConfig))
                    if (this.ordersPageConfig.hideDutyDate) {
                        this.headers[12].class=this.headers[13].class='hided'
                    } else {
                        this.headers[12].class=this.headers[13].class=''
                    }
                    if (this.ordersPageConfig.hideSite) {
                        this.headers[15].class=this.headers[16].class='hided'
                    } else {
                        this.headers[15].class=this.headers[16].class=''
                    }
                },
                deep: true,
            },
            orders_statusesList: {
                handler () {
                    if (this.orders_statusesList) {
                        console.log('watch orders_statusesList',this.orders_statusesList)

                        if (this.filtersRequest !== null) {
                            const filledFilters = localStorage.getItem("ordersFilers");
                            if (filledFilters) {
                                let filledFilters_parse = JSON.parse(filledFilters);
                                this.settags(filledFilters_parse)
                            }
                        }

                    }
                }
            }
        },
        methods:{
            async loadOrder(dialog){
                console.log('loadOrder')
                try {
                    await this.$store.dispatch('fetchOrder', this.currentOrderId)
                } catch (ex) {
                    console.log('loadOrder - error')
                } finally {
                    console.log('loadOrder - finally')
                    console.log(this.order)
                    this.currentOrder = this.order;
                    this[dialog] = true;
                }
            },
            resetOrdersCurrentPage() {
                console.log(this.options.page)
                if (this.options.page === 1) {
                    this.getDataFromApi()
                } else {
                    console.log('reset current page')
                    this.options.page = 1
                }
            },
            settags(filledFilters) {
                this.tags = []
                this.filtersLength = Object.keys(filledFilters).length
                if(Object.entries(filledFilters)){

                    let thisthis = this
                    _.forEach(filledFilters, function(value, key) {

                        let tag_value = null
                        switch (key) {
                            case 'orders_status':
                                Object.keys(thisthis.orders_statusesList).forEach(key => {
                                    if (thisthis.orders_statusesList[key].orders_status_id === value) tag_value = 'Status:'+thisthis.orders_statusesList[key].orders_status_name
                                });
                                break;
                            case 'date_purchased':
                                tag_value = 'Date:'+value.toString().replace(',', ' - ')
                                break;
                            default:
                                tag_value = key+':'+value;
                                break;
                        }

                        if (tag_value && !find(thisthis.tags, tag_value)) thisthis.tags.push(tag_value);
                    })
                }
            },
            async getDataFromApi(){
                if (this.loading) return;
                this.loading = true;

                const response = await ordersApi.getOrdersFromApi(this.request, this.options)
                this.orders = response.data.data
                this.pagination.totalItems = response.data.total
                this.loading = false;

            },
            async exportOrders() {
                this.request.with = []
                this.request.without = ['duty_date', 'orders_status_name']
                this.loading = true;
                await ordersApi.exportOrders(this.request, this.options)
                    .then(response => {
                    const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/^"(.*)"$/, '$1');
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();// you need to remove that elelment which is created before.
                }).finally(() => {
                        this.loading = false;
                    })
            },
            filterOrders({filledFilters}) {
                this.filtersLength = Object.keys(filledFilters).length
                let i = 1;
                let uri = {};
                if (Object.entries(filledFilters)) {
                    localStorage.setItem("ordersFilers", JSON.stringify(filledFilters));
                    this.settags(filledFilters)
                    for (const [key, value] of Object.entries(filledFilters)) {

                        if(key === 'date_paid' || key ==='date_purchased'){
                            if(value.length){
                                uri[`filterGroups[${i}][field]`]=key;
                                uri[`filterGroups[${i}][filters][0][value]`]=value[0]+' 00:00:00';
                                uri[`filterGroups[${i}][filters][0][condition]`]='GREATER_THAN_OR_EQUAL';
                                uri[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                                uri[`filterGroups[${i}][filters][0][operator]`]='and';
                                uri[`filterGroups[${i}][filters][1][value]`]=value[1]+' 23:59:59';
                                uri[`filterGroups[${i}][filters][1][condition]`]='LESS_THAN_OR_EQUAL';
                                uri[`filterGroups[${i}][filters][1][type]`]='stringfilter';
                                uri[`filterGroups[${i}][filters][1][operator]`]='and';
                                uri[`filterGroups[${i}][operator]`]='and';
                                i++;
                            }
                        }else{
                            uri[`filterGroups[${i}][field]`]=key;
                            uri[`filterGroups[${i}][filters][0][value]`]=value;
                            uri[`filterGroups[${i}][filters][0][condition]`]='EQUAL';
                            uri[`filterGroups[${i}][filters][0][type]`]='numericfilter';
                            uri[`filterGroups[${i}][filters][0][operator]`]='or';
                            uri[`filterGroups[${i}][operator]`]='and';
                            i++;
                        }
                    }
                }

                this.filtersRequest = {...uri};

                localStorage.setItem("ordersRequest", JSON.stringify(this.filtersRequest));

                this.resetOrdersCurrentPage();
                this.closeFiltersMenu();

            },
            closeFiltersMenu(){
                this.filterDrawer = false;
            },
            clearFilters(){
                this.filtersLength = 0;
                this.closeFiltersMenu();
            },
            toggleSelectedRow: function (rowId) {
                this.selectedRow = rowId
            },
            openOrderDialog(orderId, activeTab = 'tab-order-info'){
                EventBus.$emit('openOrderDialog', orderId, activeTab);
            },
            openRefundDialog(order){
                EventBus.$emit('openRefundDialog', order);
            },
            openCancelDialog(order){
                EventBus.$emit('openCancelDialog', order);
            },
            openInvoiceDialog(orderId){
                this.currentOrderId = orderId;
                this.loadOrder('showInvoiceDialog')
            },
            async openCelnicaDialog(customersId){
                let uri = {};
                uri[`filterGroups[1][field]`]='customers_id';
                uri[`filterGroups[1][filters][0][value]`]=customersId;
                uri[`filterGroups[1][filters][0][condition]`]='EQUAL';
                uri[`filterGroups[1][filters][0][type]`]='numericfilter';
                uri[`filterGroups[1][filters][0][operator]`]='or';
                uri[`filterGroups[1][operator]`]='and';
                uri[`filterGroups[2][field]`]='orders_status';
                uri[`filterGroups[2][filters][0][value]`]=6;
                uri[`filterGroups[2][filters][0][condition]`]='EQUAL';
                uri[`filterGroups[2][filters][0][type]`]='numericfilter';
                uri[`filterGroups[2][filters][0][operator]`]='or';
                uri[`filterGroups[2][operator]`]='and';
                const response = await ordersApi.getOrdersFromApi({...uri}, {})
                this.currentCelnicaOrders = response.data.data
                this.showCelnicaDialog = true;
            },
            openPrintDialog(orderId) {
                this.loading = true;
                this.showPrintDialog = true;
                this.startGettingPrintData = true;
                this.currentOrderId = orderId;
                this.currentEbayPaypal = { ebay: [], paypal: null};
                axios.get(
                    '/api/orders/print/'+orderId
                ).then(response => {
                    this.currentEbayPaypal = response.data;

                }).finally(() => {
                    this.startGettingPrintData = false
                    this.loading = false;
                })

            },
            openBuyerCheckoutMessageDialog(orderId, message) {
                this.currentOrderId = orderId
                this.currentBuyerCheckoutMessage = message
                this.showBuyerCheckoutMessageDialog = true
            },
            openShippingDialog(orderId) {
                this.currentOrderId = orderId
                this.loadOrder('showShippingDialog')
            },
            closeRefundDialog(){
                this.showRefundDialog = false;
                this.currentOrderId = null;
            },
            closeCancelDialog(){
                this.showCancelDialog = false;
                this.currentOrderId = null;
            },
            closeInvoiceDialog(){
                this.showInvoiceDialog = false;
                this.currentOrderId = null;
            },
            openProductDialog(product){
                product.descriptions = {}
                product.descriptions.products_name = product.products_name
                EventBus.$emit('openProductDialog', product);
            },
            newOrderAdded(newOrder){
                this.addNewOrderDialog= false;
                return this.newOrderAdd(newOrder).then( () => {
                    this.needLoadOrder = false;
                    this.openOrderDialog(this.order.orders_id);
                });
            },
            orderUpdated(){
                console.log('orderUpdated',this.order);
                this.currentOrder = this.order;
            },
            ...mapActions([
                "initCategories",
                "newOrderAdd"
            ]),
            ...mapMutations([
                'setOrder',
            ]),

        },
        components:{
            OrderFilters,
            OrderFiltersBar,
            TableId,
            TableTotal,
            OrderInfo,
            TableActions,
            ordersApi,
            ScrollableDialog,
            NewOrder,
            RefundOrder,
            CancelOrder,
            Invoice,
            Celnica,
            Shipping,
            PrintDialog
        },
        destroyed(){
            this.$store.dispatch('saveSearchRequest', null);
        },

    }
</script>

<style>

    .hided {
        display: none;
    }
    .width-25{
        max-width: 25vw;
        min-width: 20vw;
    }

    .width-30{
        max-width: 30vw;
        min-width: 20vw;
    }

    .width-40{
        max-width: 40vw;
        min-width: 30vw;
    }

    .width-50{
        max-width: 50vw;
        min-width: 35vw;
    }

    .orders-table .v-data-footer {
        position: static;
    }

    .orders-table .v-data-table__wrapper {
        margin-bottom: 0;
        height: calc(100vh - 35px - 48px - 24px - 48px);
        overflow: auto;
    }

    .v-dialog.refund-dialog, .refund-dialog .v-data-table__wrapper{
        overflow: visible;
    }

    .invoice-dialog {
        height: 90%;
    }

    .invoice-dialog .wrap-content {
        overflow: hidden;
        height: 100%;
    }

    .invoice-dialog .wrap-content .content {
        height: calc(100vh - 35px - 48px - 24px - 48px);
    }

</style>