var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c(
            "v-form",
            {
              ref: "formTrackingNumber",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.validTrackingNumber,
                callback: function($$v) {
                  _vm.validTrackingNumber = $$v
                },
                expression: "validTrackingNumber"
              }
            },
            [
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                            Name\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                            Tracking Number\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                            Carrier\n                        "
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.order.order_products, function(
                            order_products
                          ) {
                            return _c(
                              "tr",
                              { key: order_products.products_id },
                              [
                                _c("td", { staticClass: "px-2" }, [
                                  _vm._v(_vm._s(order_products.products_name))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center px-2",
                                    attrs: { size: "10%" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        dense: "",
                                        rules: _vm.rules,
                                        required: "",
                                        autofocus: "",
                                        clearable: "",
                                        reverse: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeTrakingNumber(
                                            $event,
                                            order_products
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          order_products.ShipmentTrackingNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            order_products,
                                            "ShipmentTrackingNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "order_products.ShipmentTrackingNumber"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center px-2",
                                    attrs: { size: "10%" }
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass: "text-right",
                                      staticStyle: { width: "150px" },
                                      attrs: {
                                        items: _vm.carriers,
                                        dense: "",
                                        rules: _vm.rules,
                                        required: "",
                                        reverse: ""
                                      },
                                      model: {
                                        value:
                                          order_products.ShippingCarrierUsed,
                                        callback: function($$v) {
                                          _vm.$set(
                                            order_products,
                                            "ShippingCarrierUsed",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "order_products.ShippingCarrierUsed"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-checkbox", {
            attrs: { label: "Reload orders" },
            model: {
              value: _vm.reloadOrders,
              callback: function($$v) {
                _vm.reloadOrders = $$v
              },
              expression: "reloadOrders"
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.submitTracking } },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }