<template>
<!--    <v-dialog v-model="show" :key="id" persistent width="99%" :content-class="className ? className : ''" style="height: calc(100vh - 200px); overflow-x: hidden;" >
        <v-card class="wrap-content">
            <v-row no-gutters>
                <v-col cols="12">
                    <div class="d-flex">
                        <v-btn icon @click.stop="close">
                            <v-icon>keyboard_backspace</v-icon>
                        </v-btn>
                        <p class="text-truncate text-left title ma-0">

                            {{ title }}
                        </p>
                        <v-spacer/>
                        <v-btn icon  @click.stop="close">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <div class="content">
                <slot name="content"></slot>
            </div>

        </v-card>
    </v-dialog>-->
    <v-dialog v-model="show" :fullscreen="fullscreen" :scrollable="true" hide-overlay>
        <v-card>
            <v-card-title class="ma-0 pa-0">
                <v-toolbar dark dense color="blue darken-3">
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click.stop="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </v-card-title>
            <v-card-text class="pa-0">
                <slot name="content"></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "CustomDialog",
        props: {
            id:{},
            title:{},
            show:{},
            className:{},
            fullscreen: {},
            scrollable: {}
        },
        data: () => ({

        }),
        methods:{
            close(){
                this.$emit('closeDialog')
            }
        }
    }
</script>

<style scoped lang="scss">

    /*.wrap-content{*/
    /*    display: grid;*/
    /*    grid-template-rows: 60px  calc(100vh - 200px - 60px);*/

    /*.content{*/
    /*    max-height: 100%;*/
    /*    overflow: auto;*/
    /*    min-height: 150px;*/
    /*}*/
    /*}*/

</style>