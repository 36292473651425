import { render, staticRenderFns } from "./ProductPrice.vue?vue&type=template&id=7b8b9420&scoped=true&"
import script from "./ProductPrice.vue?vue&type=script&lang=js&"
export * from "./ProductPrice.vue?vue&type=script&lang=js&"
import style0 from "./ProductPrice.vue?vue&type=style&index=0&id=7b8b9420&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8b9420",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/tools/public_html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b8b9420')) {
      api.createRecord('7b8b9420', component.options)
    } else {
      api.reload('7b8b9420', component.options)
    }
    module.hot.accept("./ProductPrice.vue?vue&type=template&id=7b8b9420&scoped=true&", function () {
      api.rerender('7b8b9420', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/pages/Catalog/components/ProductPrice.vue"
export default component.exports