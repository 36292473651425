<template>
    <v-container v-if="item" class="product-item ma-0 pa-0" style="min-width: 500px;">
        <v-row align="stretch" justify="space-between" no-gutters>
            <!-- <v-col cols="1" class="mr-1" style="max-width: 1px;">
                <v-divider
                        class="mx-0 px-0"
                        vertical
                ></v-divider>
            </v-col>
            -->
            <v-col cols="12" style="min-width: 490px;">
                <v-row class="align-center" no-gutters>
                    <v-col class="d-flex justify-content-between align-center ma-0 pa-0" cols="12">
                        <v-icon class="mr-1" dense @click="printProductBarcode(item.products_id)">mdi-barcode</v-icon>
                        <p v-if="!showEditName" class="flex-grow-1 body-1 primary--text ma-0 text-left eclipse pr-2"
                           @click="editName">{{ item.descriptions.products_name }} </p>
                        <v-text-field
                                v-else
                                v-model="item.descriptions.products_name"
                                :rules="rules"
                                autofocus
                                class="body-2"
                                counter="80"
                                dense
                                single-line
                                @blur="showEditName = !showEditName"
                                @change="editInfo"
                                @keydown="escapeCloseEditName"
                        >
                            <template v-slot:append>
                                <v-btn class="flex-grow-0" color="success" icon small @click="editInfo">
                                    <v-icon>save</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <!--<v-btn color="green" :disabled="!enableEditDescription" icon @click="clickShowEditDescription" class="flex-grow-0">
                            <v-icon small>mdi-alpha-d-circle-outline</v-icon>
                        </v-btn>-->
                        <v-btn class="flex-grow-0" icon @click="clickProductSpecifics">
                            <v-icon color="green" small>mdi-file-tree</v-icon>
                        </v-btn>
                        <v-btn :color="hasNote() ? 'green' : 'lime'" :disabled="!enableEditNote" class="flex-grow-0"
                               icon
                               @click="clickShowNote">
                            <v-icon small>mdi-alpha-n-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-if="showNote">
                    <v-row no-gutters>
                        <v-col class="pr-2 pb-2" cols="12">
                            <v-textarea
                                    v-model="item.product_note.products_note"
                                    append-outer-icon="save"
                                    auto-grow
                                    autofocus
                                    background-color="blue lighten-4"
                                    class="body-2"
                                    color="light-green"
                                    dense
                                    hide-details
                                    label="Note"
                                    outlined
                                    @click:append-outer="editInfo"
                            />
                        </v-col>
                    </v-row>
                </div>
                <div v-if="showEditDescription">
                    <v-row no-gutters>
                        <v-col class="pr-2 pb-2" cols="12">
                            <v-textarea
                                    v-model="item.descriptions.products_description"
                                    append-outer-icon="save"
                                    auto-grow
                                    autofocus
                                    background-color="green lighten-5"
                                    class="body-2"
                                    color="light-blue"
                                    dense
                                    hide-details
                                    label="Description"
                                    outlined
                                    @click:append-outer="editInfo"
                            />
                        </v-col>
                    </v-row>
                </div>
                <div v-else-if="!showNote && !showEditDescription">
                    <v-row class="mt-0" no-gutters style="max-width: 800px;">
                        <v-col class="d-flex justify-space-between align-end ma-0 pa-0" cols="12">
                            <v-select v-model="weight" :cache-items=true :items="productWeightsList"
                                      :menu-props="{ auto: true, offsetY: true }"
                                      class="caption font-weight-regular currency-select pr-1"
                                      dense
                                      hide-details
                                      item-text="products_options_values_name"
                                      item-value="products_options_values_id"
                                      label=""
                                      single-line
                                      style="width:110px; max-width: 110px;"
                                      @input=""
                            >
                                <template #prepend>
                                    <v-label>Weight:</v-label>
                                </template>
                            </v-select>

                            <v-select v-model="condition" :cache-items=true
                                      :items="productConditionsList"
                                      :menu-props="{ auto: true, offsetY: true }"
                                      class="caption font-weight-regular product-info-product-conditions-list pr-1"
                                      dense
                                      hide-details
                                      item-text="products_options_values_name"
                                      item-value="products_options_values_id"
                                      label=""
                                      style="width:165px; max-width: 165px; height: 25px;"
                                      @input=""
                            >
                                <template #prepend>
                                    <v-label>Condition:</v-label>
                                </template>
                            </v-select>

                            <v-checkbox v-model="freeShip" class="ma-0" dense false-value="28" hide-details
                                        true-value="29">
                                <template #prepend>
                                    <v-label>Free Shipment:</v-label>
                                </template>
                            </v-checkbox>

                            <v-checkbox v-model="bestOffer" class="ma-0" dense false-value="534" hide-details
                                        true-value="533">
                                <template #prepend>
                                    <v-label>Best Offers:</v-label>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" no-gutters style="max-width: 800px;">
                        <v-col class="d-flex justify-space-between pt-1 ma-0 pa-0" cols="12">
                            <v-checkbox v-model="repair" class="ma-0" dense false-value="546" hide-details
                                        true-value="545">
                                <template #prepend>
                                    <v-label>Repair:</v-label>
                                </template>
                            </v-checkbox>
                            <!--                    <v-checkbox v-model="cmCom" hide-details dense true-value="596" false-value="597" class="ma-0">
                                                    <template #prepend>
                                                        <v-label>CM.com:</v-label>
                                                    </template>
                                                </v-checkbox>-->
                            <v-checkbox
                                    v-model="gtc"
                                    :readonly="!!item.products_status"
                                    class="ma-0"
                                    dense
                                    false-value="31"
                                    hide-details
                                    true-value="30"
                                    @click="checkBoxGTCClick()"
                            >
                                <template #prepend>
                                    <v-label>GTC:</v-label>
                                </template>
                            </v-checkbox>
                            <v-checkbox v-model="otk" class="ma-0" dense false-value="581" hide-details
                                        true-value="580">
                                <template #prepend>
                                    <v-label>Hold:</v-label>
                                </template>
                            </v-checkbox>
                            <v-checkbox v-model="colony" :readonly="!!item.products_status" class="ma-0"
                                        dense false-value="601" hide-details true-value="600"
                                        @click="checkBoxColonyClick()">
                                <template #prepend>
                                    <v-icon
                                            v-if="colony == 601 && item.products_status"
                                            color="orange darken-2"
                                            small
                                    >
                                        mdi-arrow-up-bold-box-outline
                                    </v-icon>
                                    <v-label :class="colony === 600 ? '' : 'red--text'" color="red">Colony:</v-label>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0" no-gutters>
                        <v-col class="d-flex justify-start pt-1 ma-0 pa-0" cols="12">
                            <v-flex class="text-left" flex-grow-0>
                                <p class="ma-0 font-weight-bold">Q.: <span
                                        :class="(item.products_quantity == 0 ? 'red--text' : '')"><v-icon
                                        v-if="item.products_quantity === 0" class="mb-1" color="red"
                                        small>mdi-alarm-light</v-icon>{{ item.products_quantity }}</span>
                                    <warehouse-info :product="item"/>
                                </p>
                            </v-flex>
                            <v-flex class="text-left ml-4" flex-grow-0>
                                <p class="font-weight-normal text-left ma-0 pl-1">
                                    <v-icon class="mr-1" small>mdi-file-tree</v-icon>
                                    <span class="font-weight-bold">{{ productCategoryShop === 1 ? '🌟 ' : '' }}{{ productCategory }}</span> |
                                    <v-icon class="mr-1" small>mdi-bullseye-arrow</v-icon>
                                    <span class="font-weight-bold">{{ productTargetCategory }}</span>
                                    <v-btn class="flex-grow-0" icon @click="openEditProductCategoryDialog">
                                        <v-icon color="default" small>mdi-playlist-edit</v-icon>
                                    </v-btn>
                                </p>

                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex class="text-left ml-4 mr-1" flex-grow-0>
                                <set-statistic
                                        v-if="set && ! hideSets"
                                        :product="item"
                                        :set="set"
                                ></set-statistic>
                            </v-flex>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <confirm ref="confirm"/>

    </v-container>
</template>

<script>
import Confirm from '@/components/Confirm'

import SetStatistic from '@/components/SetStatistic'
import WarehouseInfo from "@/components/WarehouseInfo";
import EventBus from "@/event-bus";

import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {setup} from 'axios-cache-adapter'
import {mapGetters} from 'vuex'

const api = setup({
    // `axios-cache-adapter` options
    cache: {
        maxAge: 60 * 1000,
        exclude: {query: false}
    }
})

export default {
    name: "ProductInfo",
    props: {
        item: {
            type: Object,
            required: true,
        },
        sets: {
            type: Object,
            required: true,
        },
        hideSets: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            value: [],
            set: null,
            edit: false,
            initialItem: {},
            showNote: false,
            showDefect: false,
            enableEditNote: true,
            showEditName: false,
            showEditDescription: false,
            enableEditDescription: true,
            rules: [v => v.length <= 80 || 'Max 80 characters'],
        }
    },
    mounted: function () {
        if (this.item.product_set && this.item.product_set[0] && this.item.product_set[0].set_id) {
            this.getSet()
            //EventBus.$emit('getSet', this.item)
        }
        this.initialItem = _.cloneDeep(this.item)
    },
    computed: {
        /*set() {
            if (this.item.product_set && this.item.product_set[0] && this.item.product_set[0].set_id && this.sets[this.item.product_set[0].set_id]){
                return this.sets[this.item.product_set[0].set_id]
            } else {
                return null
            }
        },*/
        productConditionsList() {
            if (this.productOptions) return this.productOptions[2].options_value
            else return []
        },
        productWeightsList() {
            if (this.productOptions) {
                return this.productOptions[3].options_value
            } else return []
        },
        productDiscountList() {
            if (this.productOptions) {
                return this.productOptions[26].options_value
            } else return []
        },
        currencies() {
            return this.currenciesList
        },
        ...mapGetters([
            'currenciesList',
            'productCategories',
            'productOptions',
        ]),
        products_name: {
            get() {
                console.log('Get products name')
                return this.item.descriptions.products_name

            },
            set(val) {
                if (this.item.descriptions) {
                    this.item.descriptions.products_name = val
                } else {

                }
            }
        },
        price: {
            get() {
                return parseFloat(this.item.products_price).toFixed(2)
            },
            set(val) {
                this.setAttributeValueById(2, val)
                this.editInfo()
            }

        },
        weight: {
            get() {
                if (this.item.products_attributes && this.item.products_attributes[3]) return this.item.products_attributes[3].options_value[0]
                else return null
            },
            set(val) {
                this.setAttributeValueById(3, val)
                this.editInfo()
            }

        },
        condition: {
            get() {
                if (this.item.products_attributes && this.item.products_attributes[2]) return this.item.products_attributes[2].options_value[0]
                else return null
            },
            set(val) {
                this.setAttributeValueById(2, val)
                this.editInfo()
            }

        },
        repair: {
            get() {
                return this.getAttributeValueById(15)
            },
            set(val) {
                this.setAttributeValueById(15, val)
                this.editInfo()
            }

        },
        cmCom: {
            get() {
                return this.getAttributeValueById(23)
            },
            set(val) {
                this.setAttributeValueById(23, val)
                this.editInfo()
            }

        },
        otk: {
            get() {
                return this.getAttributeValueById(18)
            },
            set(val) {
                this.setAttributeValueById(18, val)
                this.editInfo()
            }

        },
        colony: {
            get() {
                return this.getAttributeValueById(25)
            },
            set(val) {
                this.setAttributeValueById(25, val)
                this.editInfo()
            }

        },
        freeShip: {
            get() {
                return this.getAttributeValueById(7)
            },
            set(val) {
                this.setAttributeValueById(7, val)
                this.editInfo()
            }

        },
        bestOffer: {
            get() {
                return this.getAttributeValueById(11)
            },
            set(val) {
                this.setAttributeValueById(11, val)
                this.editInfo()
            }

        },
        gtc: {
            get() {
                return this.getAttributeValueById(8)
            },
            set(val) {
                this.setAttributeValueById(8, val)
                this.editInfo()
            }
        },
        productCategory() {
            if (this.productCategories && this.item.category) {
                const category = this.getCategory(this.productCategories, this.item.category.categories_id)
                return category ? category.descriptions.categories_name : ''
            }
        },
        productCategoryShop() {
            if (this.productCategories && this.item.category) {
                const category = this.getCategory(this.productCategories, this.item.category.categories_id)
                return category ? category.categories_shop : 0
            }
        },
        productTargetCategory() {
            if (this.productCategories && this.item.targetcatid) {
                const category = this.getCategory(this.productCategories, this.item.targetcatid)
                return category ? category.descriptions.categories_name : ''
            }
        },
        /*memoizeDecorator: fn => {
            const cache = {}
            return (...arg) => {
                const stringifiedArg = JSON.stringify(arg)
                return (cache[stringifiedArg]) =
                    typeof (cache[stringifiedArg] === 'undefined' ? fn(...arg) : cache[stringifiedArg])
            }
        }*/
    },
    watch: {
        sets: {
            deep: true,
            immediate: true,
            handler() {
                //console.log('The list of sets has changed!', this.sets);
                if (this.item.product_set && this.item.product_set[0] && this.item.product_set[0].set_id && this.sets[this.item.product_set[0].set_id]) {
                    //console.log('The set has defined!', this.sets[this.item.product_set[0].set_id]);
                    this.set = this.sets[this.item.product_set[0].set_id]
                }
            }
        }
    },
    methods: {
        clickProductSpecifics() {
            EventBus.$emit('openProductSpecificsDialog', this.item);
        },
        openEditProductCategoryDialog() {
            EventBus.$emit('openEditProductCategoryDialog', this.item);
        },
        clickShowNote() {
            if (this.showNote) {
                this.showNote = false
                this.showEditDescription = false
                this.enableEditDescription = true
            } else {
                this.showNote = true
                this.showEditDescription = false
                this.enableEditDescription = false
            }
        },
        clickShowEditDescription() {
            if (this.showEditDescription) {
                this.showEditDescription = false
                this.showNote = false
                this.enableEditNote = true
            } else {
                this.showEditDescription = true
                this.showNote = false
                this.enableEditNote = false
            }
        },
        async checkBoxColonyClick() {
            if (this.item.products_status) {
                if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                    this.colony = this.colony == 600 ? 601 : 600;
                }
            }
        },
        async checkBoxGTCClick() {
            if (this.item.products_status) {
                if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                    this.gtc = this.gtc == 30 ? 31 : 30;
                }
            }
        },
        hasNote() {
            return this.item.product_note.products_note != ' ' && this.item.product_note.products_note != ''
        },
        api() {

        },
        escapeCloseEditName(event) {
            if (event.keyCode === 27) {
                this.showEditName = false
            }
        },
        editName() {
            this.showEditName = true
            //this.$emit('editName', this.item, 'name')
        },
        getAttributeValueById(id) {
            if (this.item.products_attributes[id]) {
                return this.item.products_attributes[id].options_values_id.toString()
            } else return ''
        },
        setAttributeValueById(id, value) {
            if (this.item.products_attributes[id]) {
                this.item.products_attributes[id].options_values_id = value
            } else {
                this.item.products_attributes[id] = {};
                this.item.products_attributes[id].options_id = id
                this.item.products_attributes[id].options_values_id = value
                this.item.products_attributes[id].products_id = this.item.products_id
            }
            /*if (this.item.products_attributes[id].options_value) {
                this.item.products_attributes[id].options_value[0].products_options_values_id = value
            } else {
                this.item.products_attributes[id].options_value = [];
                this.item.products_attributes[id].options_value.push({products_options_values_id: value})
            }*/
        },
        getCategory(arr, id) {
            for (let e of arr) {
                if (e.categories_id === id) return e;
                if (e.children && e.children.length) {
                    let found = this.getCategory(e.children, id);
                    if (found) return found;
                }
            }
        },
        getSetMemoized() {
            const memoizeDecorator = fn => {
                const cache = {}
                return (...arg) => {
                    //const stringifiedArg = JSON.stringify(arg)
                    return (cache[arg[0]]) =
                        typeof (cache[arg[0]] === 'undefined' ? fn(...arg) : cache[arg[0]])
                }
            }
            const getSetMemo = memoizeDecorator(this.getSet)
            if (this.item.product_set && this.item.product_set[0] && this.item.product_set[0].set_id) {
                getSetMemo(this.item.product_set[0].set_id)
            }
        },
        async getSet() {
            if (this.item.product_set && this.item.product_set[0] && this.item.product_set[0].set_id) {
                if (this.sets[this.item.product_set[0].set_id]) {
                    //this.set = this.sets[this.item.product_set[0].set_id]
                } else {
                    await axios.get('/api/sets/' + this.item.product_set[0].set_id + '?with_cache=0')
                        .then(response => {
                            // console.log('set ', response.data)
                            this.sets[this.item.product_set[0].set_id] = response.data
                            this.set = this.sets[this.item.product_set[0].set_id]
                        });
                }
            }
        },
        difference(object, base, excluded = []) {
            return _.transform(object, (result, value, key) => {
                if (!_.isEqual(value, base[key]) || excluded.includes(key)) {
                    result[key] = value
                }
            });
        },
        editInfo() {
            this.$emit('productChanged', this.difference(this.item, this.initialItem, ['products_id']))
            this.initialItem = _.cloneDeep(this.item)
            this.enableEditDescription = true
            this.enableEditNote = true
            this.showNote = false
            this.showEditDescription = false
        },
        printProductBarcode(products_id) {
            EventBus.$emit('printProductBarcode', products_id);
        },
    },
    components: {
        TreeSelect,
        SetStatistic,
        WarehouseInfo,
        Confirm
    }
}
</script>

<style scoped>
.product-item label {
    font-size: 12px !important;
}

.v-btn--icon.v-size--default {
    height: 20px;
    width: 20px;
    min-width: 20px;
}

.v-btn--icon.v-size--default .v-icon {
    height: 20px;
    width: 20px;
}

p.eclipse {
    max-width: 95%;
}

.w50 {
    width: 50px;
}

.w90 {
    width: 90px;
}

.v-input__prepend-outer label {
    text-overflow: unset !important;
    max-width: 100%;
    white-space: nowrap;
    font-weight: 900 !important;
}

div .v-select__selection .v-select__selection--comma {
    margin: 0 !important;
    text-overflow: unset !important;
}

.v-text-field {
    margin: 0 !important;
}

</style>

<style>

.product-info-product-conditions-list .v-input__control {
    height: 25px !important;
}

.product-info-product-conditions-list .v-input__control .v-input__slot {
    height: 25px !important;
}

.product-info-product-conditions-list .v-input__control .v-input__slot .v-select__slot {
    height: 25px !important;
}

.product-info-product-conditions-list .v-input__control .v-input__slot .v-select__slot .v-select__selections {
    height: 25px !important;
}

.v-select__selections input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    max-width: 100% !important;
}

.v-select__selection {
    max-width: 100% !important;
}

.v-select__slot {
    align-items: flex-end !important;
}

</style>