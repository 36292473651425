<template>
    <add-new-product />
</template>

<script>

import AddNewProduct from "./components/AddNewProduct";

export default {
    name: "AddProduct",
    components: {
        AddNewProduct
    }
}
</script>

<style scoped>

</style>