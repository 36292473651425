var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "scroll-table",
        attrs: {
          dense: "",
          "hide-default-footer": _vm.hideDefaultFooter,
          headers: _vm.headers,
          items: _vm.emailList
        },
        on: { "click:row": _vm.showEmail },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  {
                    class: [
                      { "font-weight-bold": !props.item.is_read },
                      "text-left"
                    ],
                    on: {
                      click: function($event) {
                        return _vm.showEmail(props.item)
                      }
                    }
                  },
                  [
                    _c("td", { staticClass: "px-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm
                            .$moment(props.item.email_date)
                            .format("MM-DD-YYYY")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "px-1" }, [
                      _vm._v(_vm._s(props.item.email_from))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "px-1" }, [
                      _vm._v(_vm._s(props.item.email_subject))
                    ])
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm.readEmail
        ? _c(
            "v-row",
            { staticClass: "text-left" },
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "px-3" },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("From: ")
                      ]),
                      _vm._v(_vm._s(_vm.readEmail.email_from))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("To: ")
                      ]),
                      _vm._v(_vm._s(_vm.readEmail.email_to))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Date: ")
                      ]),
                      _vm._v(
                        _vm._s(
                          _vm
                            .$moment(_vm.readEmail.email_date)
                            .format("MM-DD-YYYY")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Subject: ")
                      ]),
                      _vm._v(_vm._s(_vm.readEmail.email_subject))
                    ]),
                    _vm._v(" "),
                    _c("v-divider"),
                    _vm._v(" "),
                    _c("v-card", { staticClass: "mt-2", attrs: { flat: "" } }, [
                      _c("p", {
                        directives: [
                          { name: "linkified", rawName: "v-linkified" }
                        ],
                        domProps: {
                          innerHTML: _vm._s(_vm.readEmail.email_body)
                        }
                      })
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }