var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product && _vm.product.stocks_quantity
    ? _c(
        "span",
        { staticClass: "ml-2 caption teal--text text--darken-2" },
        _vm._l(_vm.product.stocks_quantity, function(stock, index) {
          return _c("i", { key: index }, [
            _vm._v("\n        (" + _vm._s(_vm.getStockName(stock))),
            _vm.product.stocks_quantity.length > 1 &&
            _vm.product.stocks_quantity.length !== index + 1
              ? _c("span", [_vm._v(", ")])
              : _vm._e(),
            _vm._v(")\n    ")
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }