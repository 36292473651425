<template>
    <v-card class="elevation-0" style="height: 100%;">
        <v-layout column>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="order_products"
                        hide-default-footer
                        class="scroll-table"
                        dense
                        id="tableOrderDialogProducts"
                        :items-per-page=order_products.length
                >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>
<!--                                <order-table-info-id :item="props.item"/>-->
                                <a @click="openProductDialog(props.item.product)">{{ props.item.products_id }}</a>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td class="text-left">
                                <v-edit-dialog
                                    @close="updateOrder(props.item, props.item.products_quantity,props.item.products_price)"
                                >
                                    {{ props.item.products_name }}
                                    <v-text-field
                                        type="string"
                                        slot="input"
                                        autofocus
                                        v-model="props.item.products_name"
                                    />
                                </v-edit-dialog>
                                <span v-if="props.item.auctionID"> ({{ props.item.auctionID }})</span>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td class="text-center">
                                <v-edit-dialog
                                        @close="updateOrder(props.item, props.item.products_quantity,props.item.products_price)"
                                >
                                    {{ props.item.products_quantity }}
                                    <v-text-field
                                        type="number"
                                        slot="input"
                                        autofocus
                                        v-model.number="props.item.products_quantity"
                                    />
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td class="text-right">
                                <v-edit-dialog
                                        @close="updateOrder(props.item, props.item.products_quantity,props.item.products_price)"
                                >
                                    {{ props.item.products_price.toFixed(2) }}
                                    <v-text-field
                                            type="number"
                                            step="0.01"
                                            slot="input"
                                            autofocus
                                            v-model.number="props.item.products_price"
                                    />
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td class="text-right">{{(parseFloat(props.item.products_price) * props.item.products_quantity).toFixed(2) }} </td>
                            <td>
                                <v-divider vertical></v-divider>
                            </td>
                            <td class="justify-center">

                                <v-icon
                                        @click="deleteOrderProduct(props.item)"
                                        color="red"
                                >
                                    delete
                                </v-icon>
                            </td>
                        </tr>
                    </template>

                    <template  slot="header.add"  slot-scope="props">
                        <v-icon big slot="activator" color="green" @click="openDialog"> add_box </v-icon>
                        <add-dialog
                                v-model="dialog"
                                @add-product="newProductAdd"
                        >
                            Add product
                        </add-dialog>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        <v-card-actions>
            <v-layout class="ma-0">
                <v-row class="subtitle-1 ma-0">
                    <v-col cols="3" class="pa-0">
                        <v-icon color="amber" @click="printOrderReceipt(order)">mdi-cube-outline</v-icon>
                        <v-btn
                            small
                            color="primary"
                            @click="openCancelDialog"
                            v-show="order.order_products.length && order.orders_status_name.orders_status_name === 'Pending'"
                        >Cancel</v-btn>
                        <v-btn
                            small
                            color="primary"
                            @click="openRefundDialog"
                            v-show="order.orders_status_name.orders_status_name === 'Paid' || order.orders_status_name.orders_status_name === 'Delivered' || order.orders_status_name.orders_status_name === 'PartialRefunded'"
                        >Refund</v-btn>
                        <v-btn
                            small
                            color="green"
                            @click="getFromEbayPayment"
                            v-show="order.payment_method === 'IntegratedMerchantCreditCard'"
                        >Get eBay Payment</v-btn>
                    </v-col>
                    <v-col cols="3" class="pa-0">
                        <strong>SubTotal: {{  subTotal.toFixed(2) }}
                            {{ order ? order.currency : null }}
                        </strong>
                    </v-col>
                    <v-col cols="3" class="pa-0">
                        <strong>
                            <v-edit-dialog>
                                Shipment:&nbsp;{{ (order_shipping ? order_shipping.toFixed(2) : '0.00') }}
                                {{ order ? order.currency : null }}
                                <v-text-field
                                    type="number"
                                    step="0.01"
                                    slot="input"
                                    autofocus
                                    v-model.number="shipment"
                                    :value="order_shipping"

                                />
                            </v-edit-dialog>
                        </strong>
                    </v-col>
                    <v-col cols="3" class="pa-0">
                        <strong>Total: {{ finalTotal.toFixed(2) }}
                            {{order ? order.currency : null}}
                        </strong>
                        <br/>
                        <span
                            v-if="order.order_ebayfees"
                        >
                            eBay fees: <span v-html="order.order_ebayfees.text"></span>
                        </span>
                        <br/>
                        <span
                            v-if="order.order_ebaytotal"
                        >
                            eBay total: <span v-html="order.order_ebaytotal.text"></span>
                        </span>
                    </v-col>
                </v-row>
            </v-layout>
        </v-card-actions>
    </v-card>
</template>

<script>
    import AddDialog from '../../components/AddDialog'
    import { mapActions, mapGetters } from 'vuex';
    import EventBus from "@/event-bus";

    export default {
        name: "OrderTableInfo",
        props: {
            order_products: {
                required: true,
                type: Array
            },
            order_sub_total: {},
            order_shipping: {},
            order_total: {}
        },
        data() {
            return {
                headers: [
                    {text: 'ID', align: 'center', sortable: false, value: 'products_id', width: "75px"},
                    {text: '', align: 'center'},
                    {text: 'Product', align: 'left', sortable: false, value: 'products_name'},
                    {text: '', align: 'center'},
                    {text: 'Quantity', align: 'center', sortable: false, value: 'products_quantity', width: "75px"},
                    {text: '', align: 'center'},
                    {text: 'Price', align: 'center', sortable: false, value: 'products_price', width: "100px"},
                    {text: '', align: 'center'},
                    {text: 'Amount', align: 'center', sortable: false, value: 'amount', width: "75px"},
                    {text: '', align: 'center'},
                    {text: 'add', align: 'center', sortable: false, value: 'add', width: "30px"}
                ],
                dialog: false,

                // editedIndex: -1,
                // editedItem: {
                //     products_id: '',
                //     products_name: '',
                //     products_quantity: 0,
                //     final_price: 0,
                // },
                // defaultItem: {
                //     products_id: '',
                //     products_name: '',
                //     products_quantity: 0,
                //     final_price: 0,
                // }
            }
        },
        mounted(){
            // console.log(this.order)
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            },
            products_count: function () {
                return this.order_products.length;
            },
            subTotal: function() {
                return this.order_products.reduce(function(a, c){return a + Number((c.products_quantity*c.products_price) || 0)}, 0)
            },
            finalTotal: function() {
                return this.subTotal*1 + this.order_shipping*1
            },
            shipment:{
                get(){
                    // console.log(this.order_shipping)
                    return this.order_shipping
                },
                set(newVal){
                    this.$emit('inputShipping', newVal);
                }
            },
            ...mapGetters([
                'orderId',
                'order'
            ]),

        },
        methods: {
            async getFromEbayPayment() {
                this.loading = true;

                try {
                    await this.getEbayPayment(this.order);
                } catch (ex) {
                    console.log('getEbayPayment - error')
                    console.log(ex)
                } finally {
                    EventBus.$emit('reloadOrderDialog');
                }

                this.loading = false;
            },
            openProductDialog(item) {
                EventBus.$emit('openProductDialog', item);
            },
            updateOrder(item, quantity, price){
                item.products_quantity = quantity || 0
                item.products_price = price || 0
                if (item.products_quantity !== 0 && item.products_price !== 0) this.$emit('productChanged', item, item.products_quantity, item.products_price)
            },
            deleteOrderProduct(item) {
                if(confirm('Are you sure you want to delete this item?')){
                    this.$emit('productRemoved', item)
                }
            },
            openDialog(){
                this.dialog=true
            },
            newProductAdd(product){

                const newProduct = {
                    "orders_id":this.orderId,
                    "products_id":product.products_id,
                    "products_name":product.descriptions.products_name,
                    "products_price":0,
                    "final_price":0,
                    "products_quantity":1
                }
                this.$store.dispatch('newProductAdd', newProduct)
            },
            openRefundDialog(){
                EventBus.$emit('openRefundDialog', this.order.orders_id);
            },
            openCancelDialog(){
                EventBus.$emit('openCancelDialog', this.order.orders_id);
            },
            openInvoiceDialog(){
                console.log('openInvoiceDialog - 1');
                this.$emit('openInvoiceDialog', this.order.orders_id);
            },
            printOrderReceipt(item) {
                window.open("/receipt/"+item.orders_id+"?lang=cz", '_blank')
            },
            ...mapActions([
                "getEbayPayment",
            ]),

        },
        components: {
            AddDialog
        }
    }
</script>

<style scoped>

    .scroll-table{
        overflow-y: auto;
        overflow-x: hidden !important;
        max-height: 38vh;
    }

</style>

<style>
    #tableOrderDialogProducts div{
        overflow-x: hidden !important;
    }
</style>