<template>
    <div class="ordered-datepicker">
        <v-menu
                ref="menu"
                v-model="menu"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field
                    dense
                    :hide-details="hideDetails"
                    :value="date ? $moment(date).format('YYYY-MM-DD') : ''"
                    class="caption font-weight-regular text-center full-width"
                    :label="labelName"
                    readonly
                    v-on="on"
                    :rules="rules"
                    :required="required"
                    :disabled="disabled"
                />
            </template>
            <v-date-picker
                v-model="date"
                @input="menu = false"
                no-title
                class="caption font-weight-regular text-center"
            >
            </v-date-picker>
        </v-menu>

    </div>
    
</template>

<script>
    import moment from 'moment'
    export default {
        name: "CustomDatepicker",
        props: ['value', 'labelName', 'rules', 'required', 'hideDetails', 'disabled'],
        data() {
            return {
                menu: false
            }
        },
        computed: {
            date: {
                get: function () {
                    let tmp = ''
                    if (this.value !== null) {
                        if (_.isDate(this.value)) tmp = moment(this.value).format('YYYY-MM-DD')
                        else if (_.isString(this.value)) tmp = this.value.substr(0, 10)
                    }
                    //let date = this.value == null ?  null : (_.isString(this.value) ? this.value.substr(0, 10) : this.value )
                    return tmp
                },
                set: function (newValue) {
                    //console.log(newValue);
                    this.$emit('input', newValue)
                }
            }
        },
        mounted(){

        },
        methods: {
            getIcon(){}

        }
    }
</script>

<style>



</style>