var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": "",
        id: "SalesGoodsFlow"
      }
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "mb-2" },
        [
          _c(
            "v-flex",
            { attrs: { sm2: "" } },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  items: _vm.filer_auction_accounts,
                  "prepend-icon": "account_box",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.account,
                  callback: function($$v) {
                    _vm.account = $$v
                  },
                  expression: "account"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { sm2: "" } },
            [
              _vm.orders_statusesList
                ? _c("v-select", {
                    attrs: {
                      dense: "",
                      items: _vm.orders_statusesArray,
                      "menu-props": { maxHeight: "400" },
                      "item-text": "orders_status_name",
                      "item-value": "orders_status_id",
                      multiple: "",
                      "prepend-icon": "mdi-filter-variant",
                      "hide-details": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", { class: item.orders_status_name }, [
                                _vm._v(_vm._s(item.orders_status_name))
                              ])
                            ]
                          }
                        },
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              index === 0
                                ? _c("v-chip", { attrs: { "x-small": "" } }, [
                                    _c("span", [
                                      _vm._v(_vm._s(item.orders_status_name))
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              index === 1
                                ? _c(
                                    "span",
                                    { staticClass: "green--text caption" },
                                    [
                                      _vm._v(
                                        "\n          (+" +
                                          _vm._s(
                                            _vm.order_statuses.length - 1
                                          ) +
                                          ")\n        "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      854889299
                    ),
                    model: {
                      value: _vm.order_statuses,
                      callback: function($$v) {
                        _vm.order_statuses = $$v
                      },
                      expression: "order_statuses"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { sm2: "" } },
            [
              _vm.currenciesList
                ? _c("v-select", {
                    attrs: {
                      dense: "",
                      items: _vm.currenciesList,
                      "prepend-icon": "euro_symbol",
                      "hide-details": "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.currency,
                      callback: function($$v) {
                        _vm.currency = $$v
                      },
                      expression: "currency"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { sm2: "" } },
            [
              _c(
                "v-menu",
                {
                  ref: "menu",
                  attrs: {
                    "close-on-content-click": false,
                    "return-value": _vm.dates,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "290px"
                  },
                  on: {
                    "update:returnValue": function($event) {
                      _vm.dates = $event
                    },
                    "update:return-value": function($event) {
                      _vm.dates = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                attrs: {
                                  dense: "",
                                  "prepend-icon": "event",
                                  readonly: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.dateRangeText,
                                  callback: function($$v) {
                                    _vm.dateRangeText = $$v
                                  },
                                  expression: "dateRangeText"
                                }
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menu,
                    callback: function($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-date-picker",
                    {
                      attrs: { range: "", "no-title": "" },
                      model: {
                        value: _vm.dates,
                        callback: function($$v) {
                          _vm.dates = $$v
                        },
                        expression: "dates"
                      }
                    },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.menu = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.changeDates(_vm.dates)
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "JqxCheckBox",
            {
              staticStyle: { "margin-left": "10px", float: "left" },
              attrs: { hasThreeStates: true, width: 120, height: 25 },
              on: {
                change: function($event) {
                  return _vm.changeHasDutyDate($event)
                }
              },
              model: {
                value: _vm.hasDutyDate,
                callback: function($$v) {
                  _vm.hasDutyDate = $$v
                },
                expression: "hasDutyDate"
              }
            },
            [
              _vm.hasDutyDate !== null
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.hasDutyDate
                          ? "Has duty date"
                          : "Doesn't have duty date"
                      )
                    )
                  ])
                : _c("span", [_vm._v("Any duty date")])
            ]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                disabled: _vm.isDownload || _vm.dates.length <= 1
              },
              on: { click: _vm.refreshSalesGoodsFlowDataTable }
            },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [_vm._v("refresh")])
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasDutyDate === false
            ? _c(
                "v-badge",
                {
                  attrs: {
                    bordered: "",
                    color: "success",
                    icon: "mdi-currency-eur",
                    overlap: "",
                    light: ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        icon: "",
                        disabled: _vm.dates.length <= 1
                      },
                      on: { click: _vm.Download }
                    },
                    [
                      _c("v-icon", { attrs: { color: "success" } }, [
                        _vm._v("mdi-file-download-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-btn",
                {
                  staticStyle: { "margin-right": "0px" },
                  attrs: {
                    small: "",
                    icon: "",
                    disabled: _vm.dates.length <= 1
                  },
                  on: { click: _vm.Download }
                },
                [
                  _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v("mdi-file-download-outline")
                  ])
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "grey", disabled: _vm.showMassUpdateDialog },
                  on: { click: _vm.massUpdate }
                },
                [_vm._v("update")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticStyle: { height: "100%" }, attrs: { xs12: "" } },
        [
          _vm.salesGoodsFlowDataAdapter
            ? _c("JqxDataTable", {
                ref: "SalesGoodsFlowDataTable",
                attrs: {
                  width: "100%",
                  height: "100%",
                  source: _vm.salesGoodsFlowDataAdapter,
                  columns: _vm.salesGoodsFlowColumns,
                  pageable: false,
                  serverProcessing: true,
                  pagerButtonsCount: 2,
                  pageSizeOptions: [25, 50, 100, 150, 200, 500],
                  pagerMode: "advanced",
                  altRows: true,
                  filterable: true,
                  filterMode: "default",
                  sortable: true,
                  statusBarHeight: 25,
                  theme: _vm.jqwTheme,
                  localization: _vm.localization,
                  showAggregates: true,
                  aggregatesHeight: 25,
                  enableBrowserSelection: true,
                  enableHover: false
                },
                on: {
                  rowClick: function($event) {
                    return _vm.onRowClickSalesGoodsFlowDataTable($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "50%" },
          model: {
            value: _vm.showMassUpdateDialog,
            callback: function($$v) {
              _vm.showMassUpdateDialog = $$v
            },
            expression: "showMassUpdateDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Update selected orders")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showMassUpdateDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-2", attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _vm.auction_accounts
                            ? _c("v-select", {
                                attrs: {
                                  dense: "",
                                  items: _vm.auction_accounts,
                                  "prepend-icon": "account_box",
                                  placeholder: "Please select an account",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.massUpdateAccount,
                                  callback: function($$v) {
                                    _vm.massUpdateAccount = $$v
                                  },
                                  expression: "massUpdateAccount"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loadingMassUpdate,
                                    disabled: !_vm.massUpdateAccount
                                  },
                                  on: { click: _vm.massUpdateSave }
                                },
                                [_vm._v("Update")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.loadingMassUpdate,
            callback: function($$v) {
              _vm.loadingMassUpdate = $$v
            },
            expression: "loadingMassUpdate"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(
                    "\n                    Please stand by\n                    "
                  ),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          attrs: {
            value: _vm.alert,
            color: "pink",
            dark: "",
            border: "left",
            transition: "scale-transition",
            dismissible: "",
            prominent: "",
            type: "warning",
            position: "center"
          }
        },
        [_vm._v("\n            Please select the items!\n        ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }