var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _vm.historyData
            ? _c("v-data-table", {
                staticClass: "scroll-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.historyData,
                  loading: _vm.loading,
                  "item-key": "id",
                  options: _vm.options,
                  "server-items-length": _vm.pagination.totalItems,
                  "fixed-header": true,
                  "footer-props": {
                    itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
                    showFirstLastPage: true
                  },
                  dense: ""
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.log_datetime",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm
                                  .$moment(props.item.log_datetime)
                                  .format("DD.MM.YYYY HH:mm")
                              ) +
                              "\n            "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3215460785
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }