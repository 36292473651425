<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Change password</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form aria-label="ChangePassword" ref="changePassword">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.password"
                                        :rules="[rules.required]"
                                        label="New password"
                                        type="password"
                                        name="new_password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.passsword_confirmation"
                                        :rules="passwordConfirmationRules"
                                        label="Password confirmation"
                                        name="passsword_confirmation"
                                        type="password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn
                                @click="validate"
                                :loading="loading"
                                color="primary">Submit</v-btn>
                    </v-form>
                    <form id="change-password-logout-form" action="/logout" method="POST" style="display: none;">
                        <input type="hidden" name="_token" :value="token">
                    </form>
                </v-card-text>
            </v-card>
        </v-col>

    </v-row>

</template>

<script>
    export default {
        name: "changePassword",
        data(){
            return {
                input: {
                    password: '',
                    passsword_confirmation: ''
                },
                rules: {
                    required: value => !!value || '',
                },
                loading: false
            }
        },
        computed:{
            passwordConfirmationRules() {
                return [
                    v => this.input.password === v || 'Passwords must match',
                    v => !!v || 'Confirmation Passwords is required'
                ];
            },
            token() {
                let token = document.head.querySelector('meta[name="csrf-token"]');
                return token.content
            }
        },
        methods: {
            validate(){
                if(this.$refs.changePassword.validate()){
                    this.loading = true
                    this.submit()
                }
            },
            submit(){
                axios.post('/api/change-password', this.input)
                    .then(response => {
                        this.loading = false;
                        this.logout();
                    })
                    .catch(error => {
                        this.loading = false
                });
            },
            logout(){
                document.getElementById('change-password-logout-form').submit()
            }
        }
    }
</script>

<style scoped>

</style>