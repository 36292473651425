var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.fullscreen,
        scrollable: _vm.scrollable,
        width: _vm.width,
        "hide-overlay": ""
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }