<template>
    <div class="full-width">
<!--        <v-row no-gutters v-if="this.$route.params.id">
            <v-col cols="12">
               <p class="text-truncate text-left title ma-0" v-if="productLoaded &&  product.descriptions">
                   {{ product.products_id }} - {{ product.descriptions.products_name }}
               </p>
            </v-col>
        </v-row>
        <v-divider/>-->

        <v-row no-gutters justify="center" v-if="productLoaded">
            <v-col cols="5" class="d-flex align-center">

                <v-subheader class="font-weight-black">Quantity:
                <span class="ml-1" :class="product.products_quantity == 0 ? 'text--lighten-red' : 'text--lighten-green'">{{product.products_quantity}}<v-icon color="red" class="mb-1" v-if="product.products_quantity === 0" small>mdi-alarm-light</v-icon></span>
                </v-subheader>
                <warehouse-info :product="product" />
                <v-spacer/>

            </v-col>
            <v-col cols="2" class="d-flex align-center">
                <v-spacer/>
                <v-icon small @click.stop="openEditProductDialog(product)">mdi-square-edit-outline</v-icon>
                <v-icon small @click.stop="printProductBarcode(product)" class="ml-1">mdi-barcode-scan</v-icon>
                <v-spacer/>
            </v-col>
            <v-col cols="5" class="d-flex align-right">
                <v-spacer/>
                <v-subheader class="justify-center font-weight-black">Status:</v-subheader>
                <v-radio-group dense row hide-details
                               v-model="productStatus"
                               class="justify-center ma-0 pa-0">
                    <v-radio
                            color="red"
                            value="0"
                            class="ma-0 pa-0"
                    />
                    <v-radio
                            color="success"
                            value="1"
                            class="ma-0 pa-0"
                    />
                </v-radio-group>

            </v-col>
<!--            <v-col cols="1" class="d-flex align-center">
                <v-btn small text icon color="blue" @click="refresh"><v-icon>mdi-cached</v-icon></v-btn>
            </v-col>-->
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-tabs v-model="productTabActive" show-arrows hide-slider grow class="elevation-1  scroll-tabs">

                    <v-tab v-for="(productTab, i) in productTabs"
                           :key="productTab.key"
                           :href="'#tab-' + productTab.key"
                           ripple
                           :disabled="disabledTab(productTab.name)"
                           @change="changeTab(productTab.key)">
                        {{productTab.name}}
                    </v-tab>

                    <v-tabs-items class="elevation-1" touchless v-model="productTabActive">
                        <v-tab-item
                                v-for="(productTab, i) in productTabs"
                                :key="productTab.key"
                                :value="'tab-' + productTab.key"
                                class="text-center  scroll-tab-item"
                        >
                            <v-progress-circular
                                    v-show="loadingTab"
                                    :size="70"
                                    :width=7
                                    color="green"
                                    indeterminate
                                    class="ma-5"
                            />

                            <component :is="productTab.component"
                                       v-if="productLoaded"
                                       v-show="!loadingTab"
                                       :isActive="productTabActive"
                                       @componentReady="loadingTab=false"
                                       class="full-height"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-col>
        </v-row>
    </div>

</template>

<script>
    import Check from '@/pages/Catalog/product/Check'
    import ProductHistory from '@/pages/Catalog/product/ProductHistory'
    import ProductEmail from '@/pages/Catalog/product/ProductEmail'
    import PriceHistory from '@/pages/Catalog/product/PriceHistory'
    import Images from '@/pages/Catalog/product/Images'
    import Set from '@/components/Set'
    import WarehouseInfo from "@/components/WarehouseInfo";
    import {mapGetters} from "vuex";
    import EventBus from "@/event-bus";

    export default {
        name: "SingleProduct",
        props: {
            id: {},
            activeTab: {}
        },
        data(){
            return {
                purchase: 45,
                productTabs: [
                    { name: 'Check', order:1, key: 'check', component: Check},
                    { name: 'Images', order:2, key: 'images', component: Images},
                    { name: 'Set', order:3, key: 'set', component: Set},
                    { name: 'Email', key: 'email', order:4, component: ProductEmail},
                    { name: 'Product history', key: 'product-history', order:5, component: ProductHistory},
                    { name: 'Price history', key: 'price-history', order:6, component: PriceHistory},
                ],
                productTabActive: null,
                loadingTab: true,
                productLoaded: false,
            }
        },
        computed:{
            productStatus: {
                get(){
                    return this.$store.getters.productStatus
                },
                set(newVal){
                    this.$store.dispatch('updateProduct', {products_id: this.product.products_id, products_status: newVal})
                }
            },
            ...mapGetters([
                'product',
            ]),
        },
        mounted(){
            this.loadProduct();

            if(this.activeTab){
                this.productTabActive =  this.activeTab
            }else{
                this.productTabActive = 'tab-check'
            }
        },
        methods:{
            disabledTab(tabName) {
                switch(tabName) {
                    case 'Set':
                        console.log(this.product);
                        if (this.product && this.product.product_set && this.product.product_set[0]) {
                            return false;
                        }
                        return true;
                        break;
                    case 'Images':
                        if (this.product) {
                            let diffDays = this.$moment().diff(this.$moment(this.product.products_date_available), "days")
                            if (diffDays > 180 && this.product.products_status === 0  && this.product.products_quantity === 0 && this.product.category && this.product.category.categories_id === 218) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        return true;
                        break;
                    default:
                        return false;
                }
            },
            refresh() {
                this.loadProduct();
            },
            async loadProduct(){
                try {
                    await this.$store.dispatch('fetchProduct',
                        {
                            'id' : this.id ? this.id : this.$route.params.id,
                            'params' : {
                                'with': ['category', 'product_set']
                            }
                        })
                } catch (ex) {
                    this.error = "Failed to load product"
                } finally {
                    this.productLoaded = true
                }
            },
            changeTab(tabId){
                if(`tab-${tabId}` !== this.productTabActive){
                    this.loadingTab=false
                }
                this.productTabActive = `tab-${tabId}`

                EventBus.$emit('changeProductDialogTab', this.productTabActive);
            },
            printProductBarcode(product) {
                window.open("/barcode/"+product.products_id, '_blank')
            },
            openEditProductDialog(item) {
                EventBus.$emit('closeEditProductDialog');
                EventBus.$emit('closeProductDialog');
                setTimeout(function() {
                    EventBus.$emit('openEditProductDialog', item)
                }, 25)
            }
        },
        components:{
            WarehouseInfo,
        }

    }
</script>

<style scoped>

    .full-width{
        align-self: self-start;
    }

    .v-subheader{
        height: auto;
    }

    .v-dialog .scroll-tabs {
        display: grid;
        grid-template-rows: 48px  calc(100vh - 200px - 48px);
    }

    .full-height, #tab-images {
        height: 100%;
    }

    .v-tabs-items{
        height:100%;
        overflow-x:hidden;
        overflow-y:auto;
    }

</style>