import { render, staticRenderFns } from "./ProductDefects.vue?vue&type=template&id=d052ecac&scoped=true&"
import script from "./ProductDefects.vue?vue&type=script&lang=js&"
export * from "./ProductDefects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d052ecac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/tools/public_html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d052ecac')) {
      api.createRecord('d052ecac', component.options)
    } else {
      api.reload('d052ecac', component.options)
    }
    module.hot.accept("./ProductDefects.vue?vue&type=template&id=d052ecac&scoped=true&", function () {
      api.rerender('d052ecac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/ProductDefects.vue"
export default component.exports