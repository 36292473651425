<template>
    <h1>Tra</h1>
</template>

<script>
    export default {
        name: "FedexTracking",
        props:{
            isActive:{}
        },
        data(){
            return {
                address: {}
            }
        },
        mounted() {
            this.init()

        },
        methods:{
            init(){
                return setTimeout(() => {
                        console.log('FedexTracking')
                        this.$emit('componentReady')
                    },
                    1000)
            }
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-fedex-track"){
                    if(this.order !== null){
                        this.$emit('componentReady')
                    }else{
                        this.init()
                    }


                }

            }
        }
    }
</script>

<style scoped>

</style>