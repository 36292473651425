<template>
    <v-row v-if="set" class="mt-2">
        <v-col>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="indigo--text"><span class="font-weight-bold">Set&nbspName:&nbsp;</span>{{ set.set_name.replaceAll(' ', '&nbsp;') }}</v-col>
                <v-spacer></v-spacer>
                <v-col class="purple--text"><span class="font-weight-bold">Purchase&nbsp;Price:&nbsp;</span>{{Math.round(set.set_total_price_EUR,0)}}&nbsp;EUR</v-col>
                <v-spacer></v-spacer>
                <v-col class="light-green--text"><span class="font-weight-bold">Sold&nbsp;Price:&nbsp;</span>{{Math.round(set.set_sold_EUR,0)}}&nbsp;EUR</v-col>
                <v-spacer></v-spacer>
                <v-col cols="1">
                    <v-btn x-small icon color="blue" id="refresh" @click="init"><v-icon>mdi-cached</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-data-table
                            :items="set.products"
                            :headers="headers"
                            :items-per-page="100"
                            dense
                    >
                        <template slot="item.products_id" slot-scope="props">
                            <a @click="openProductDialog(props.item.product)">{{ props.item.products_id }}</a>
                        </template>

                        <template slot="item.price" slot-scope="props">
                            {{ props.item.products_price}} {{props.item.currencies_code}}
                        </template>

                        <template slot="item.quantity_sum" slot-scope="props">
                            {{ countQuantity(props.item, 'quantity') }}

                        </template>

                        <template slot="item.final_sum" slot-scope="props">
                            {{ countQuantity(props.item, 'products_price_EUR') }}
                        </template>


                    </v-data-table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

</template>

<script>
    import {mapGetters} from "vuex";
    import EventBus from "@/event-bus";

    export default {
        name: "Set",
        props:{
            isActive:{},
            setInfo: {}
        },
        data(){
            return {
                set: null,
                setId: null,
                headers: [
                    {text: 'ID', value: 'products_id', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'Product', value: 'product.descriptions.products_name', align: 'left',
                        sortable: false, width: "40%"},
                    {text: 'P.Q', value: 'products_quantity', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'P.P', value: 'price', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'S.Q', value: 'quantity_sum', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'S.F', value: 'final_sum', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'C.Q', value: 'product.products_quantity', align: 'center',
                        sortable: false, width: "15%"},
                    {text: 'Status', value: 'product.products_status', align: 'center',
                        sortable: false, width: "auto"},
                ]

            }
        },
        computed:{
            ...mapGetters([
                'product',
            ]),
        },
        mounted() {
            console.log(this.product)
            if(this.product){
                this.setId = this.product.product_set[0].set_id
                this.init()
            }else if(this.setInfo){
                console.log(this.setInfo)
                this.set = this.setInfo
                this.setId = this.setInfo.set_id
                this.$emit('componentReady')
            }
        },
        methods: {
            init(){
                // console.log(this.product);
                if (this.setId) {
                    axios.get('/api/sets/'+this.setId)
                        .then(response => {
                            this.set = response.data
                            this.$emit('componentReady')
                        })
                        .catch(e => console.log(e))
                } else this.$emit('componentReady');

            },
            countQuantity(product, prop){
                let fullSum = 0
                if(product.order_products.length){
                    product.order_products.forEach(item => {
                        fullSum += item[prop]
                    })
                }
                return fullSum
            },
            openProductDialog(item) {
                EventBus.$emit('openProductDialog', item);
            }

        },
        watch:{
            isActive(){
                if(this.isActive === "tab-set"){
                    if(this.product !== null){
                        this.init()
                    }
                }
            },
        },
    }
</script>

<style scoped>

</style>