var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.statusHistoryData
        ? _c("v-data-table", {
            staticClass: "scroll-table",
            attrs: {
              "disable-sort": "",
              "hide-default-footer": "",
              headers: _vm.headers,
              items: _vm.statusHistoryData,
              dense: "",
              width: "100%"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(props) {
                    return [
                      _c("tr", [
                        _c("td", { staticClass: "px-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(props.item.date_added)
                                .format("MM-DD-YYYY")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [_c("v-divider", { attrs: { vertical: "" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            props.item.customer_notified
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "green" } },
                                  [_vm._v("done")]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "red" } },
                                  [_vm._v("notifications_off")]
                                )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [_c("v-divider", { attrs: { vertical: "" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            class:
                              "px-2 " +
                              props.item.order_status.orders_status_name
                          },
                          [
                            _vm._v(
                              _vm._s(props.item.order_status.orders_status_name)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [_c("v-divider", { attrs: { vertical: "" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", {
                          staticClass: "px-2 text-left",
                          domProps: { innerHTML: _vm._s(props.item.comments) }
                        })
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              2334526400
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }