<template>
    <v-row>
        <v-col>
            <!-- <v-btn small text icon color="blue" @click="init"><v-icon>mdi-cached</v-icon></v-btn> -->
            <v-data-table
                v-if="historyData"
                :headers="headers"
                :items="historyData"
                :loading="loading"
                item-key="id"
                :options.sync="options"
                :server-items-length="pagination.totalItems"
                class="scroll-table"
                :fixed-header="true"
                :footer-props="{
                            itemsPerPageOptions: pagination.rowsPerPageItems,
                            showFirstLastPage: true,
                           }"
                dense
            >
                <template slot="item.log_datetime" slot-scope="props">
                    {{ $moment(props.item.log_datetime).format('DD.MM.YYYY HH:mm') }}
                </template>

            </v-data-table>
        </v-col>
    </v-row>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ProductHistory",
        props:{
            isActive:{},
        },
        data(){
            return {
                historyData: null,
                loading: false,
                options:{
                    groupBy: [],
                    groupDesc: [],
                    itemsPerPage: 0,
                    multiSort: false,
                    mustSort: false,
                    page: 1,
                    sortBy: [],
                    sortDesc: []
                },
                pagination: {
                    descending: true,
                    page: 1,
                    rowsPerPage: 15,
                    totalItems: 0,
                    rowsPerPageItems: [10, 15, 20, 50, 100]
                },
                headers: [
                    {text: 'Date', align: 'center', value: 'log_datetime', sortable: true, name: 'date'},
                    {text: 'Place', align: 'center', value: 'auktion', sortable: false},
                    {text: 'Auction', align: 'center', value: 'action', sortable: false},
                    {text: 'ItemID', align: 'center', value: 'auktion_ItemID', sortable: false},
                ]

            }
        },
        computed:{
            ...mapGetters([
                'productId'
            ]),
        },
        mounted() {
            this.options.itemsPerPage = this.pagination.rowsPerPage
        },
        methods: {
            async init(){
                if (this.loading) {
                    return;
                }
                this.loading = true
                await axios.get(`/api/products_history?
                filterGroups[0][field]=products_id
                &filterGroups[0][filters][0][value]=${this.productId}
                &filterGroups[0][filters][0][condition]=EQUAL
                &filterGroups[0][filters][0][type]=numericfilter
                &filterGroups[0][filters][0][operator]=or
                &filterGroups[0][operator]=or&current_page=${this.options.page}&pagesize=${this.options.itemsPerPage}`)
                    .then(response => {
                        this.loading = false
                        this.historyData = response.data.data
                        this.pagination.totalItems = response.data.total
                        this.$emit('componentReady')
                    })
            },
        },
        watch:{
            options: {
                handler () {
                    this.init()
                },
                deep: true,
            },
            isActive(){
                if(this.isActive === "tab-product-history"){
                    if(this.historyData !== null){
                        this.init()
                    }
                }
            },
        },
    }
</script>

<style scoped>

</style>