var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: false, width: "600px", scrollable: false },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._t("default")], 2)
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-container",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", wrap: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex",
                          attrs: { sm: "10", cols: "8" }
                        },
                        [
                          _c("v-autocomplete", {
                            staticClass: "body-1",
                            attrs: {
                              items: _vm.orders,
                              loading: _vm.isLoading,
                              "search-input": _vm.search,
                              autofocus: true,
                              "item-text": "forSearch",
                              "item-value": "customers_id",
                              "return-object": "",
                              autocomplete: "off",
                              dense: "",
                              clearable: true
                            },
                            on: {
                              "update:searchInput": function($event) {
                                _vm.search = $event
                              },
                              "update:search-input": function($event) {
                                _vm.search = $event
                              },
                              change: _vm.saveUserToOrder
                            },
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expand-transition",
            [
              _vm.selected
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list",
                        { staticClass: "full-width" },
                        [
                          _c(
                            "v-list-item",
                            {
                              key: _vm.selected.orders_id,
                              attrs: { link: "" }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "truncate" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.selected.forSearch) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "green" },
                                      on: { click: _vm.addCustomer }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                save\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }