var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-upload-timeline" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.timeline, function(event) {
              return _c(
                "v-timeline",
                {
                  key: event.id,
                  attrs: {
                    dense: "",
                    clipped: "",
                    light: "",
                    "align-top": false
                  }
                },
                [
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { color: event.color, small: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", {
                            attrs: { xs7: "", "text-xs-left": "" },
                            domProps: { textContent: _vm._s(event.text) }
                          }),
                          _vm._v(" "),
                          _c("v-flex", {
                            attrs: { xs5: "", "text-xs-left": "" },
                            domProps: { textContent: _vm._s(event.time) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        _vm._b({}, "v-footer", _vm.localAttrs, false),
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("input", {
              ref: "file",
              attrs: {
                type: "file",
                id: "file",
                onClick: "this.value = ''",
                accept: "application/zip"
              },
              on: {
                change: function($event) {
                  return _vm.handleFileUpload()
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs6: "" } },
            [
              _c("v-progress-linear", {
                model: {
                  value: _vm.uploadPercentage,
                  callback: function($$v) {
                    _vm.uploadPercentage = $$v
                  },
                  expression: "uploadPercentage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs2: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.disableSubmit || !_vm.file },
                  on: {
                    click: function($event) {
                      return _vm.submitFile()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "pa-1", attrs: { xs12: "" } },
            [
              _c("v-progress-linear", {
                attrs: { color: "purple", height: "20" },
                model: {
                  value: _vm.processingPercentage,
                  callback: function($$v) {
                    _vm.processingPercentage = $$v
                  },
                  expression: "processingPercentage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }