<template>
    <v-form class="full-width">
    <v-card class="d-flex justify-start full-width" flat tile>
            <v-card style="max-width: 30%" flat tile class="pt-4">
                <v-select
                    dense
                    hide-details
                    v-model="form.orders_status"
                    :items="statuses"
                    label="Status"
                    clearable
                    return-object
                    @change="onChangeOrdersStatus"
                    @click:clear="onClear('orders_status')"
                    item-text="orders_status_name"
                    item-value="orders_status_id"
                    class="px-1"
                >
                </v-select>
            </v-card>
<!--            <v-card style="max-width: 30%" flat tile class="pt-4">
                <v-select dense
                          hide-details
                          v-model="form.site"
                          :items="sitesList"
                          label="Site"
                          @input="onChange"
                          @click:clear="onClear('site')"
                          @keyup.enter="()=> { onChange(); filterOrders(); }"
                          class="px-1"
                />
            </v-card>
            <v-card  style="max-width: 30%" flat tile class="pt-4">
                <v-select dense
                          hide-details
                          v-model="form['order_products.ShippingCarrierUsed']"
                          :items="carrierList"
                          label="Carrier"
                          @change="onChange"
                          @click:clear="onClear('order_products.ShippingCarrierUsed')"
                          @keyup.enter="()=> { onChange(); filterOrders(); }"
                          class="px-1"
                />
            </v-card>-->

    </v-card>
    </v-form>
</template>

<script>
    import {mapGetters} from "vuex";
    import CustomDateRange from "../../../components/CustomDateRange";

    export default {
        name: "OrderFiltersBar",
        props: {
            drawer: {
                type: Boolean,
                required: true,
            },
            filtersLength: {
                type: Number,
                required: true,
            },
        },
        data: () => ({
            menu: false,
            filledFilters: {},
            form: {
                PayPalTransactionID: null,
                'order_products.ShipmentTrackingNumber': null,
                customers_email_address: null,
                site: null,
                orders_status: {orders_status_name: '', orders_status_id: null},
                'order_products.ShippingCarrierUsed': null,
                date_paid: [],
                date_purchased: [],
                days: {text: 'All', value: null}
            },
            formInit: {
                PayPalTransactionID: null,
                'order_products.ShipmentTrackingNumber': null,
                customers_email_address: null,
                site: null,
                orders_status: {orders_status_name: '', orders_status_id: null},
                'order_products.ShippingCarrierUsed': null,
                date_paid: [],
                date_purchased: [],
                days: {text: 'All', value: null}
            },
            daysList: [
                {text: 'ALL', value: null},
                {text: '5 days', value: 5},
                {text: '10 days', value: 10},
                {text: '30 days', value: 30},
                {text: '60 days', value: 60}
            ],
            carrierList:[
                {text: 'CZECK POST', value: 'CZECK POST'},
                {text: 'FedEx', value: 'FedEx'},
            ]

        }),
        mounted() {
            const ordersFilterOrdersStatus = localStorage.getItem("ordersFilters-ordersStatus");
            if (ordersFilterOrdersStatus) {
                this.form.orders_status = {orders_status_name: '', orders_status_id: parseInt(ordersFilterOrdersStatus)}
            }
        },
        computed:{
            sitesList:{
                get(){
                    return this.auction_accounts
                },
                set(){

                }
            },
            statuses:{
                get(){
                    if(this.orders_statusesList){
                        return Object.keys(this.orders_statusesList).map(key => {
                                return this.orders_statusesList[key];
                            });
                    }
                    return []
                },
                set(){

                }
            },
            showFilterButton() {
                return Object.keys(this.filledFilters).length
            },
            isDrawerOpen:{
                get(){
                    const filledFilters = localStorage.getItem("ordersFilers");
                    if (filledFilters) {
                        let filledFilters_parse = JSON.parse(filledFilters);
                        if (Object.entries(filledFilters_parse)) {
                            for (const [ key, value ] of Object.entries(filledFilters_parse)) {
                                switch (key) {
                                    case 'key':
                                        break
                                    default:
                                        break
                                }
                            }
                        }
                    }

                    const ordersFilterChangeDays = localStorage.getItem("ordersFilters-days");
                    if (ordersFilterChangeDays) {
                        this.form.days = {text: ordersFilterChangeDays+' days', value: parseInt(ordersFilterChangeDays)}
                    }

                    const ordersFilterOrdersStatus = localStorage.getItem("ordersFilters-ordersStatus");
                    if (ordersFilterOrdersStatus) {
                        this.form.orders_status = {orders_status_name: '', orders_status_id: parseInt(ordersFilterOrdersStatus)}
                    }

                    return this.drawer;
                },
                set(val){
                    if (!val) this.$emit('closeMenu')
                }
            },
            ...mapGetters([
                'productCategories',
                'auction_accounts',
                'orders_statusesList'
            ]),
        },
        methods:{
            closeMenu(){
                this.$emit('closeMenu')
            },
            onChange(){
                const filters = Object.keys(this.form)
                        .filter(e => {
                            return this.form[e] !== null && this.form[e] !== undefined && typeof this.form[e] !== 'object' && e !== 'days'})
                        .reduce( (o, e) => {
                            o[e]  = this.form[e]
                            return o;
                        }, {});


                Object.keys(filters)
                 .map(e => {
                     if (filters[e]) {
                         console.log('Object.keys',e)
                         this.filledFilters[e] = filters[e]
                         localStorage.setItem('ordersFilters-'+_.camelCase(e), filters[e])
                     } else {
                         localStorage.removeItem('ordersFilters-'+_.camelCase(e))
                     }

                 })
            },
            changeDates(range){
                if(range.length){
                    this.form.date_paid = range;
                    this.filledFilters = {...this.filledFilters, date_paid: range}
                }else{
                    this.onClear("date_paid");
                    this.form.date_paid = [];
                }
            },
            onChangeOrdersStatus(){
                if(this.form.orders_status){
                    this.filledFilters = {...this.filledFilters, orders_status: this.form.orders_status.orders_status_id}
                    localStorage.setItem('ordersFilters-ordersStatus', this.form.orders_status.orders_status_id)
                } else {
                    if (find(this.filledFilters, 'orders_status')) delete this.filledFilters['orders_status'];
                    localStorage.removeItem('ordersFilters-ordersStatus')
                }
                this.filterOrders()
            },
            onChangeDays(){
                if(this.form.days.value){
                    const dateTo = this.$moment().format('YYYY-MM-DD');
                    const dateFrom = this.$moment().subtract(this.form.days.value,'d').format('YYYY-MM-DD');
                    this.filledFilters = {...this.filledFilters, date_purchased: [dateFrom, dateTo]}
                    localStorage.setItem('ordersFilters-days', this.form.days.value)
                } else {
                    console.log(this.filledFilters)
                    if (this.filledFilters.date_purchased) delete this.filledFilters['date_purchased'];
                    localStorage.removeItem('ordersFilters-days')
                }
            },
            filterOrders(){
                //this.onChange()
                //this.onChangeDays()
                //this.onChangeOrdersStatus()
                console.log('filterOrders', this.filledFilters)

                this.$emit('filterOrders', {'filledFilters': this.filledFilters });
            },
            clearFilters(){
                localStorage.removeItem("ordersFilers")
                localStorage.removeItem("ordersRequest")
                this.form = _.cloneDeep(this.formInit);
                this.filledFilters = {};
                //this.filterOrders();
                if (this.filtersLength > 0) {
                    this.filterOrders();
                }
                else this.$emit('closeMenu');

            },
            onClear(type){
                console.log("ordersFilers-"+_.camelCase(type))



                if(type === "date_purchased"){
                    this.form.date_purchased = [];
                    this.form.days = this.formInit.days;
                }

                if(type === "days"){
                    this.form.days = this.formInit.days
                }

                localStorage.removeItem("ordersFilers-"+_.camelCase(type))
                console.log("onClear-"+type)
                delete this.filledFilters[type];
            }

        },
        components:{
            CustomDateRange
        }
    }
</script>

<style scoped>

    .v-label{
        font-size: 12px;
        max-width: 100%;
    }

    .filter-button{
        top: 100px;
        right:0;
        position: fixed;
    }

    .filters-container{
        position: fixed;
        width: 256px;
        height: 80%;
        transform: translateX(-236px);
        top: 48px;
    }

</style>