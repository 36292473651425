var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.defects.groups.length
    ? _c(
        "div",
        _vm._l(_vm.defects.groups, function(groupDefects) {
          return _c(
            "v-list",
            {
              key: groupDefects.defects_groups_id,
              attrs: { flat: "", subheader: "" }
            },
            [
              _c("v-subheader", [_vm._v(_vm._s(groupDefects.title))]),
              _vm._v(" "),
              _c(
                "v-list-item-group",
                {
                  attrs: { multiple: "", "active-class": "" },
                  model: {
                    value: _vm.productDefectsModel,
                    callback: function($$v) {
                      _vm.productDefectsModel = $$v
                    },
                    expression: "productDefectsModel"
                  }
                },
                _vm._l(groupDefects.defects, function(defect) {
                  return _c("v-list-item", {
                    key: groupDefects.defects_id,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var active = ref.active
                            return [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-checkbox", {
                                    attrs: { "input-value": active },
                                    on: { onChange: _vm.changeDefects }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(defect.title))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }