const product = {
    state:{
        product: null,
        productId: null,
        productStatus: null,
        // priceHistoryData: null,

        productsAttributes: null,
        productBestOffer: null,
        helperMethods: {
            getAttributeValueById: (state, id) => {
                if (state.productsAttributes && state.productsAttributes[id]) {
                    return state.productsAttributes[id].options_values_id.toString()
                } else return []
            }
        }
    },
    mutations: {
        setProduct(state, product){
            console.log('setProduct', product)
            state.product = product
        },
        setProductId(state, products_id){
            state.productId = products_id
        },
        setProductStatus(state, status){
            state.productStatus = status
        },
        setProductBestOffer(state, id) {
            console.log(id)
            //state.productBestOffer = id
        },
        setProductAttributes(state, attributes) {
            if (attributes) state.productsAttributes = _.keyBy(attributes, 'options_id')
        }
        // setPriceHistoryData(state, priceHistoryData){
        //     state.priceHistoryData = priceHistoryData
        // }
    },
    actions: {
        fetchProduct ({ commit }, request) {
            let productsId = request
            let params = {}
            if (typeof request === 'object') {
                productsId = request.id
                params = request.params
            }
            /*}*/
            commit('setProduct', null)
            commit('setProductId', null)
            commit('setProductStatus', null)
            const defaultParams = {
                'filterGroups[0][field]': 'products_id',
                'filterGroups[0][filters][0][value]': productsId,
                'filterGroups[0][filters][0][condition]': 'EQUAL',
                'filterGroups[0][filters][0][type]': 'numericfilter',
                'filterGroups[0][filters][0][operator]': 'or',
                'filterGroups[0][operator]': 'or',
                'with': [
                    'descriptions',
                    'product_set',
                    'stocks_quantity.stocks_move_to_full.parentStocks'
                ]
            };

            const allparams = {...defaultParams, ...params}
            //Object.assign( {}, defaultParams, params );

            return axios
                .get('/api/products'
                    // filterGroups[0][field]=products_id
                    // &filterGroups[0][filters][0][value]=${id}
                    // &filterGroups[0][filters][0][condition]=EQUAL
                    // &filterGroups[0][filters][0][type]=numericfilter
                    // &filterGroups[0][filters][0][operator]=or
                    // &filterGroups[0][operator]=or
                    // &with[]=descriptions
                    // &with[]=product_set`
                    , {
                    params: allparams
                })
                .then(response => {
                    commit('setProduct', response.data.data[0])
                    commit('setProductId', response.data.data[0].products_id)
                    commit('setProductStatus', response.data.data[0].products_status.toString())
                    if (response.data.data[0].products_attributes) commit('setProductAttributes', response.data.data[0].products_attributes)
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Product is not present.');
                })
        },
        // fetchPriceHistoryData({ commit, state }){
        //     return axios
        //         .get(`/api/products_price?
        //         filterGroups[0][field]=products_id
        //         &filterGroups[0][filters][0][value]=${state.productId}
        //         &filterGroups[0][filters][0][condition]=EQUAL
        //         &filterGroups[0][filters][0][type]=numericfilter
        //         &filterGroups[0][filters][0][operator]=or
        //         &filterGroups[0][operator]=and
        //         &filterGroups[1][field]=start
        //         &filterGroups[1][filters][0][value]=0
        //         &filterGroups[1][filters][0][condition]=EQUAL
        //         &filterGroups[1][filters][0][type]=numericfilter
        //         &filterGroups[1][filters][0][operator]=or
        //         &filterGroups[1][operator]=and
        //         &pagesize=100000`)
        //         .then(response => {
        //             console.log(response.data.data)
        //             commit('setPriceHistoryData', response.data.data)
        //         }).catch((error) => {
        //             console.log(error);
        //             console.warn('Request was not sent properly. Price Data is not present.');
        //         })
        //
        // },
        updateProductStatus({commit}, statusVal) {
            this.updateProduct({commit}, {products_id: this.products_id, products_status: statusVal})
        },
        updateProduct({commit}, product) {
            return axios.put(`/api/products/${product.products_id}`, product)
                .then(response => {
                    commit('setProduct', response.data)
                    commit('setProductId', response.data.products_id)
                    commit('setProductStatus', response.data.products_status.toString())
                })
        }
    },
    getters: {
        product: state => state.product,
        productId:  state => state.productId,
        productStatus:  state => state.productStatus,
        productBestOffer: state => state.helperMethods.getAttributeValueById(state, 11)
        // priceHistoryData: state => state.priceHistoryData,
    }
}

export default product;