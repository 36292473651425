var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "return-value": _vm.dates,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      on: {
        "update:returnValue": function($event) {
          _vm.dates = $event
        },
        "update:return-value": function($event) {
          _vm.dates = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      "prepend-icon": "event",
                      readonly: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _vm.dates.length
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "clear-icon",
                                      on: { click: _vm.clearDates }
                                    },
                                    [_vm._v("close")]
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.dateRangeText,
                      callback: function($$v) {
                        _vm.dateRangeText = $$v
                      },
                      expression: "dateRangeText"
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-date-picker",
        {
          attrs: { range: "" },
          model: {
            value: _vm.dates,
            callback: function($$v) {
              _vm.dates = $$v
            },
            expression: "dates"
          }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.menu = false
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.changeDates(_vm.dates)
                }
              }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }