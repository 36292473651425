<template>
    <div>
        <v-autocomplete
                id="product_search"
                v-model="selected"
                :items="results"
                :loading="isLoading"
                :search-input.sync="search"
                :autofocus="true"
                item-text="check_number"
                item-value="check_number"
                @change="pushProduct"
                return-object
                autocomplete="off"
                dense
        ></v-autocomplete>

            <v-form
                ref="newProduct"
                v-if="selected"
                lazy-validation
                v-model="validNewPurchase"
            >
                <v-row >
                <v-col class="d-flex flex-column justify-space-between">
                    <v-subheader>Check Numbers</v-subheader>
                    <v-text-field
                        dense
                        v-model="selected.check_number"
                        disabled
                        :rules="rules"
                        required
                    >
                    </v-text-field>
                </v-col>
                <v-col class="d-flex flex-column justify-space-between">
                    <v-subheader>Date</v-subheader>
                    <datepicker
                        v-model="selected.check_last_modified"
                        class="caption font-weight-regular"
                        :rules="rules"
                        required=true
                        :hideDetails="false"
                        :disabled="true"
                    ></datepicker>

                </v-col>
                <v-col class="d-flex flex-column justify-space-between">
                    <v-subheader>Quantity</v-subheader>
                    <v-text-field
                        dense
                        v-model="selected.products_quantity"
                        :rules="rules"
                        required
                    ></v-text-field>
                </v-col>
                <v-col class="d-flex flex-column justify-space-between">
                    <v-subheader>Purchase price</v-subheader>
                    <div class="d-flex">
                        <v-text-field
                            dense
                            v-model="selected.products_price"
                            :rules="rules"
                            required
                        ></v-text-field>
                        <v-select
                            dense
                            v-model="selected.currencies_code"
                            :items="currencies"
                            class="caption font-weight-regular"
                            :rules="rules"
                            required
                            disabled
                        ></v-select>
                    </div>

                </v-col>
                <v-col class="d-flex flex-column justify-space-between">
                    <v-subheader>Action</v-subheader>
                    <v-btn color="success" @click="addToCheck">add</v-btn>
                </v-col>
                </v-row>
            </v-form>


    </div>
</template>

<script>
    import Datepicker from '@/components/CustomDatepicker.vue'
    import { mapGetters } from 'vuex'


    export default {
        name: "FindCheck",
        data(){
            return {
                results: [],
                isLoading: false,
                selected:null,
                search: null,
                rules: [
                    v => !!v || 'Required',
                ],
                validNewPurchase: true
            }
        },
        computed:{
            currencies(){
                return this.currenciesList
            },
            ...mapGetters([
                'currenciesList'
            ]),
        },
        mounted(){
        },
        watch: {
            async search(val){
                if(val){
                    this.isLoading = true

                    await axios.get(`/api/checks?
                    filterGroups[0][field]=check_number&
                    filterGroups[0][filters][0][value]=${val}&
                    filterGroups[0][filters][0][condition]=CONTAINS&
                    filterGroups[0][filters][0][type]=stringfilter&
                    filterGroups[0][filters][0][operator]=or&
                    filterGroups[0][operator]=and`)
                        .then(response => {
                            response.data.data.forEach(function(element) {
                                element.check_number =  element.check_number.toString()
                                element.check_last_modified =  element.check_date
                                element.currencies_code = element.currency
                                element.products_check = element.check_id

                            });
                            this.results = response.data.data
                            console.log(response.data)

                        })
                        .catch(error => { console.log(error)})
                        .finally(() => (this.isLoading = false))

                }
            }
        },
        methods:{
            pushProduct(val){
                this.selected.quantity = 1
                // console.log(val)
            },
            addToCheck(){
                if(this.$refs.newProduct.validate()){
                    console.log(this.selected)
                    this.$emit('input', this.selected)
                    //this.selected = {}
                }

            }
        },
        components:{
            Datepicker
        }

    }
</script>

<style scoped>

    .v-input {
        align-items: flex-end;
    }

</style>