<template>
    <v-dialog v-model="show" :fullscreen="false" width="600px" :scrollable="false" >
        <v-card>
            <v-card-title>
                <span class="headline"><slot></slot></span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="py-0">
                    <v-row justify="center" wrap>
                        <v-col sm="10" cols="8" class="d-flex">
                            <v-autocomplete class="body-1"
                                            v-model="selected"
                                            :items="orders"
                                            :loading="isLoading"
                                            :search-input.sync="search"
                                            :autofocus="true"
                                            item-text="forSearch"
                                            item-value="customers_id"
                                            @change="saveUserToOrder"
                                            return-object
                                            autocomplete="off"
                                            dense
                                            :clearable="true"
                            />


                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-expand-transition>
                <v-col cols="12" v-if="selected" class="py-0">
                    <v-list class="full-width">
                        <v-list-item link :key="selected.orders_id">
                            <v-list-item-content>
                                <v-list-item-title class="truncate">
                                    {{ selected.forSearch }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon
                                        @click="addCustomer"
                                        color="green"
                                >
                                    save
                                </v-icon>
                            </v-list-item-icon>

                        </v-list-item>
                    </v-list>
                </v-col>

            </v-expand-transition>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click.stop="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
    import ordersApi from '@/services/orders.api';

    export default {
        name: "AddCustomer",
        props: {
            value: Boolean,
            searchBy: {
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                orders: [],
                selected: null,
                search: null,
            }
        },
        computed: {
            show: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        watch:{
            search(val){
                clearTimeout(this._timerId)
                if(val)
                    this._timerId = setTimeout(() => {
                        this.getResults(val);
                    }, 500)
            }
        },
        methods: {
            async getResults(value){
                this.isLoading = true;
                let uri = [];
                uri[`filterGroups[0][field]`]=this.searchBy;
                uri[`filterGroups[0][filters][0][value]`]=value;
                uri[`filterGroups[0][filters][0][condition]`]='CONTAINS';
                uri[`filterGroups[0][filters][0][type]`]='stringfilter';
                uri[`filterGroups[0][filters][0][operator]`]='or';
                uri[`filterGroups[0][operator]`]='or';
                const response = await ordersApi.getOrdersFromApi(uri, null);
                response.data.data.forEach(function(element) {
                    // console.log(element);
                    element.forSearch = element.delivery_name+' '+element.customers_email_address
                });

                this.orders = response.data.data;

                // this.orders = this.orders.filter((order, index, self) =>
                //     index === self.findIndex((t) => (
                //         t[key] === order[key]
                //     ))
                // );

                // console.log(this.orders);

                this.isLoading = false;
            },
            saveUserToOrder(obj){
                // console.log('saveUserToOrder', obj);
            },
            addCustomer(){
                this.show = false;
                this.orders = [];
                this.$emit('addCustomer', this.selected);
                this.selected = null;
            },
            close(){
                this.selected = null;
                this.$emit('closeDialog')
            }
        }
    }
</script>

<style scoped>

</style>