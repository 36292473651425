var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-icon",
        {
          on: {
            click: function($event) {
              _vm.productForm = true
            }
          }
        },
        [_vm._v("build")]
      ),
      _vm._v(" "),
      _c("custom-dialog", {
        attrs: {
          show: _vm.productForm,
          id: _vm.product.products_id,
          title: _vm.product.products_id ? _vm.product.products_id : "Edit"
        },
        on: { closeDialog: _vm.closeCustomDialog },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("product-form", {
                  attrs: { productForEdit: _vm.product },
                  on: { saveProductInfo: _vm.saveProductInfo }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }