var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "cam" } },
    [
      _c("v-progress-linear", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "ma-0",
        staticStyle: { position: "absolute", "z-index": "99999999" },
        attrs: {
          id: "loader",
          height: "10",
          color: "white",
          indeterminate: true
        }
      }),
      _vm._v(" "),
      _c("header-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLogin,
            expression: "isLogin"
          }
        ],
        attrs: {
          favorites_menu: _vm.favorites_menu,
          main_menu: _vm.main_menu,
          failedJobs: _vm.failedJobs
        },
        on: { toggle_favorites: _vm.toggle_favorites }
      }),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              class: _vm.fillHeight + " pa-2",
              attrs: {
                fluid: "",
                "align-start": "",
                "fill-weight": "",
                "text-center": ""
              }
            },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: _vm.editedProduct.products_id,
          attrs: {
            name: "ProductDialog",
            fullscreen: _vm.fullscreenProductDialog,
            width: "800px",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.showProductDialog = false
            }
          },
          model: {
            value: _vm.showProductDialog,
            callback: function($$v) {
              _vm.showProductDialog = $$v
            },
            expression: "showProductDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.editedProduct.descriptions
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-truncate text-left title ma-0"
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.editedProduct.products_id) +
                                    " - " +
                                    _vm._s(
                                      _vm.editedProduct.descriptions
                                        .products_name
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.reloadProductDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-reload")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.fullscreenProductDialog = !_vm.fullscreenProductDialog
                                }
                              }
                            },
                            [
                              _vm.fullscreenProductDialog
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-collapse")
                                  ])
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-expand")
                                  ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeProductDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("single-product", {
                    staticClass: "pa-2",
                    attrs: {
                      id: _vm.editedProduct.products_id,
                      activeTab: _vm.activeProductDialogTab,
                      changeProductDialogTab: _vm.changeProductDialogTab
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "Defects" + _vm.editedProduct.products_id,
          attrs: {
            name: "Defects",
            fullscreen: true,
            width: "800px",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.showProductDefectDialog = false
            }
          },
          model: {
            value: _vm.showProductDefectDialog,
            callback: function($$v) {
              _vm.showProductDefectDialog = $$v
            },
            expression: "showProductDefectDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.editedProduct.descriptions
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-truncate text-left title ma-0"
                              },
                              [
                                _vm._v(
                                  "\n                            Defects: " +
                                    _vm._s(_vm.editedProduct.products_id) +
                                    " - " +
                                    _vm._s(
                                      _vm.editedProduct.descriptions
                                        .products_name
                                    ) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showProductDefectDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("defects", {
                    staticClass: "pa-2",
                    attrs: { productsId: _vm.editedProduct.products_id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: _vm.editedProduct.products_id + "description",
          attrs: {
            name: "ProductDescriptionDialog",
            fullscreen: _vm.fullscreenProductDescriptionDialog,
            width: "80%",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          model: {
            value: _vm.showProductDescriptionDialog,
            callback: function($$v) {
              _vm.showProductDescriptionDialog = $$v
            },
            expression: "showProductDescriptionDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { "min-height": "80vh" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _c(
                          "p",
                          { staticClass: "text-truncate text-left title ma-0" },
                          [
                            _vm._v(
                              "\n                            Edit product multilingual description\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.fullscreenProductDescriptionDialog = !_vm.fullscreenProductDescriptionDialog
                                }
                              }
                            },
                            [
                              _vm.fullscreenProductDescriptionDialog
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-collapse")
                                  ])
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-expand")
                                  ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeProductDescriptionDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("product-descriptions", {
                    staticClass: "pa-2",
                    attrs: { id: _vm.editedProduct.products_id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: _vm.editedProduct.products_id + "productspecifics",
          attrs: {
            name: "ProductSpecificsDialog",
            fullscreen: _vm.fullscreenProductSpecificsDialog,
            width: "80%",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          model: {
            value: _vm.showProductSpecificsDialog,
            callback: function($$v) {
              _vm.showProductSpecificsDialog = $$v
            },
            expression: "showProductSpecificsDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { "min-height": "80vh" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _c(
                          "p",
                          { staticClass: "text-truncate text-left title ma-0" },
                          [
                            _vm._v(
                              "\n                            Edit eBay product specifics\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.fullscreenProductSpecificsDialog = !_vm.fullscreenProductSpecificsDialog
                                }
                              }
                            },
                            [
                              _vm.fullscreenProductSpecificsDialog
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-collapse")
                                  ])
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-expand")
                                  ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeProductSpecificsDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("product-specifics", {
                    staticClass: "pa-2",
                    attrs: { id: _vm.editedProduct.products_id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: _vm.currentOrderId,
          attrs: {
            name: "OrderDialog",
            fullscreen: _vm.fullscreenOrderDialog,
            width: "1000px",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.showOrderDialog = false
            }
          },
          model: {
            value: _vm.showOrderDialog,
            callback: function($$v) {
              _vm.showOrderDialog = $$v
            },
            expression: "showOrderDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.currentOrderId
                              ? "Order: " + _vm.currentOrderId
                              : "Order"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.reloadOrderDialog }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-reload")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.fullscreenOrderDialog = !_vm.fullscreenOrderDialog
                                }
                              }
                            },
                            [
                              _vm.fullscreenOrderDialog
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-collapse")
                                  ])
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-expand")
                                  ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeOrderDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("order", {
                    attrs: {
                      id: _vm.currentOrderId,
                      activeTab: _vm.activeOrderDialogTab,
                      needLoadOrder: _vm.needLoadOrder,
                      changeOrderDialogTab: _vm.changeOrderDialogTab,
                      fullscreen: _vm.fullscreenOrderDialog
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "edit_product_" + _vm.editedProduct.products_id,
          attrs: {
            name: "EditProductDialog",
            fullscreen: _vm.fullscreenEditProductDialog,
            width: "1000px",
            scrollable: true,
            "hide-overlay": "",
            persistent: ""
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.showEditProductDialog = false
            }
          },
          model: {
            value: _vm.showEditProductDialog,
            callback: function($$v) {
              _vm.showEditProductDialog = $$v
            },
            expression: "showEditProductDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { "min-height": "70vh" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          "Edit product - " +
                            _vm._s(_vm.editedProduct.products_id)
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.reloadEditProductDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-reload")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.fullscreenEditProductDialog = !_vm.fullscreenEditProductDialog
                                }
                              }
                            },
                            [
                              _vm.fullscreenEditProductDialog
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-collapse")
                                  ])
                                : _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-arrow-expand")
                                  ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeEditProductDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("product-form", {
                    attrs: { productid: _vm.editedProduct.products_id }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "refund_order_" + _vm.currentOrderId,
          attrs: {
            name: "RefundDialog",
            fullscreen: false,
            width: "50vw",
            scrollable: true,
            "hide-overlay": ""
          },
          on: { "click:outside": _vm.closeRefundDialog },
          model: {
            value: _vm.showRefundDialog,
            callback: function($$v) {
              _vm.showRefundDialog = $$v
            },
            expression: "showRefundDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.currentOrderId
                              ? "Refund order - " + _vm.currentOrderId
                              : ""
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeRefundDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("refund-order", { attrs: { orderId: _vm.currentOrderId } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "cancel_order_" + _vm.currentOrderId,
          attrs: {
            name: "CancelDialog",
            fullscreen: false,
            width: "50vw",
            scrollable: true,
            "hide-overlay": ""
          },
          on: { "click:outside": _vm.closeCancelDialog },
          model: {
            value: _vm.showCancelDialog,
            callback: function($$v) {
              _vm.showCancelDialog = $$v
            },
            expression: "showCancelDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.currentOrderId
                              ? "Cancel order - " + _vm.currentOrderId
                              : ""
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeCancelDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("cancel-order", { attrs: { orderId: _vm.currentOrderId } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "JqxNotification",
        {
          ref: "notification",
          attrs: {
            template: _vm.notification.template,
            autoClose: _vm.notification.autoClose,
            autoOpen: _vm.notification.autoOpen,
            opacity: _vm.notification.opacity,
            position: _vm.notification.position,
            animationOpenDelay: _vm.notification.animationOpenDelay,
            animationCloseDelay: _vm.notification.animationCloseDelay,
            width: _vm.notification.width
          }
        },
        [_c("div", [_c("div")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }