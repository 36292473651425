var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { height: "100%" }, attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticStyle: { height: "calc(100% - 30px)" } },
        [
          _c(
            "v-form",
            {
              ref: "shipmentDHLLabel",
              model: {
                value: _vm.labelForm,
                callback: function($$v) {
                  _vm.labelForm = $$v
                },
                expression: "labelForm"
              }
            },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "justify-center font-weight-black ma-0 mb-1",
                  staticStyle: { height: "45px" }
                },
                [_vm._v("Recipient")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "justify-center",
                  staticStyle: { "margin-top": "-10px" },
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-sm-2",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "body-1 full-width",
                        attrs: {
                          dense: "",
                          rules: [
                            function(v) {
                              return (v && v.length <= 50) || ""
                            }
                          ],
                          counter: "",
                          maxlength: "50",
                          color: "blue darken-2",
                          placeholder: "Enter delivery name",
                          label: "Person",
                          "hide-details": false
                        },
                        model: {
                          value: _vm.ship.delivery_name,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "delivery_name", $$v)
                          },
                          expression: "ship.delivery_name"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 mt-0 mx-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pr-sm-2",
                              attrs: { cols: "10" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width mt-4",
                                staticStyle: {
                                  "margin-top": "-1px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return (v && v.length <= 50) || ""
                                    }
                                  ],
                                  counter: "",
                                  maxlength: "50",
                                  color: "blue darken-2",
                                  placeholder: "Enter delivery street",
                                  label: "Street",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.delivery_street,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "delivery_street", $$v)
                                  },
                                  expression: "ship.delivery_street"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "2" } },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width mt-4",
                                staticStyle: {
                                  "margin-top": "-1px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return (v && v.length <= 10) || ""
                                    }
                                  ],
                                  counter: "",
                                  maxlength: "10",
                                  color: "blue darken-2",
                                  placeholder: "Enter delivery street number",
                                  label: "#",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.delivery_streetNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ship,
                                      "delivery_streetNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ship.delivery_streetNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 mt-0 mx-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width mt-4",
                                staticStyle: {
                                  "margin-top": "10px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return (v && v.length <= 20) || ""
                                    }
                                  ],
                                  counter: "",
                                  maxlength: "20",
                                  color: "blue darken-2",
                                  placeholder: "Enter Phone",
                                  label: "Phone",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.customers_telephone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ship,
                                      "customers_telephone",
                                      $$v
                                    )
                                  },
                                  expression: "ship.customers_telephone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "body-1 full-width",
                        attrs: {
                          dense: "",
                          counter: "",
                          maxlength: "50",
                          color: "blue darken-2",
                          placeholder: "enter delivery company",
                          label: "Company",
                          "hide-details": false
                        },
                        model: {
                          value: _vm.ship.delivery_company,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "delivery_company", $$v)
                          },
                          expression: "ship.delivery_company"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-sm-1 pa-0 mt-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pr-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width",
                                staticStyle: {
                                  "margin-top": "-1px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return (v && v.length <= 50) || ""
                                    }
                                  ],
                                  counter: "",
                                  maxlength: "50",
                                  color: "blue darken-2",
                                  placeholder: "enter delivery city",
                                  label: "City",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.delivery_city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "delivery_city", $$v)
                                  },
                                  expression: "ship.delivery_city"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pr-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width",
                                staticStyle: {
                                  "margin-top": "-1px !important"
                                },
                                attrs: {
                                  dense: "",
                                  counter: "",
                                  maxlength: "35",
                                  color: "blue darken-2",
                                  placeholder: "enter delivery state",
                                  label: "Province",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.delivery_state,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "delivery_state", $$v)
                                  },
                                  expression: "ship.delivery_state"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-sm-1 pa-0 mt-0",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pr-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "body-1 full-width mt-4",
                                staticStyle: {
                                  "margin-top": "10px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: [
                                    function(v) {
                                      return (v && v.length <= 17) || ""
                                    }
                                  ],
                                  counter: "",
                                  maxlength: "17",
                                  color: "blue darken-2",
                                  placeholder: "enter delivery postcode",
                                  label: "Zip",
                                  "hide-details": false
                                },
                                model: {
                                  value: _vm.ship.delivery_postcode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "delivery_postcode", $$v)
                                  },
                                  expression: "ship.delivery_postcode"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pr-sm-2",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "body-1 full-width mt-4",
                                staticStyle: {
                                  "margin-top": "10px !important"
                                },
                                attrs: {
                                  dense: "",
                                  rules: _vm.rules.required,
                                  items: _vm.countries,
                                  label: "Country",
                                  "hide-details": false,
                                  readonly: !_vm.isCountryEditing
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeCountry($event)
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-outer",
                                    fn: function() {
                                      return [
                                        _c("v-icon", {
                                          key: "icon-" + _vm.isCountryEditing,
                                          attrs: {
                                            small: "",
                                            color: _vm.isCountryEditing
                                              ? "success"
                                              : "info"
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.isCountryEditing
                                                ? "mdi-lock-open-variant"
                                                : "mdi-lock"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.isCountryEditing = !_vm.isCountryEditing
                                            }
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.ship.delivery_country,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "delivery_country", $$v)
                                  },
                                  expression: "ship.delivery_country"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.ship.serviceType === "V53WPAK"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-subheader",
                        {
                          staticClass: "justify-center font-weight-black ma-0",
                          staticStyle: {
                            height: "45px",
                            "margin-bottom": "-10px !important"
                          }
                        },
                        [_vm._v("Commodities")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pt-2",
                          style:
                            _vm.ship.order_products.length === 1
                              ? ""
                              : "height: " +
                                _vm.heightDialog +
                                "px; overflow-y:auto;"
                        },
                        _vm._l(_vm.ship.order_products, function(
                          product,
                          index
                        ) {
                          return _vm.ship.order_products.length
                            ? _c(
                                "v-row",
                                {
                                  key: product.products_id,
                                  staticClass: "justify-center",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 pr-sm-2",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "full-width",
                                        attrs: {
                                          items: _vm.descriptionTypes,
                                          dense: "",
                                          label: "Description",
                                          "hide-details": true
                                        },
                                        model: {
                                          value: product.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              product,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "product.description"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-sm-1 pa-0 mt-1",
                                          attrs: { justify: "space-between" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-3 pa-0 pr-sm-2",
                                              attrs: { cols: "12", sm: "3" }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "body-1 full-width mt-5",
                                                staticStyle: {
                                                  "margin-top":
                                                    "10px !important"
                                                },
                                                attrs: {
                                                  items:
                                                    _vm.countriesManufacture,
                                                  dense: "",
                                                  label: "Origin",
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value:
                                                    product.countryManufacture,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "countryManufacture",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "product.countryManufacture"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pr-sm-2",
                                              attrs: { cols: "12", sm: "3" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Quantity",
                                                  type: "number",
                                                  readonly: "",
                                                  "hide-details": true
                                                },
                                                model: {
                                                  value: product.quantity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "quantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "product.quantity"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pr-sm-2",
                                              attrs: { cols: "12", sm: "3" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Pieces",
                                                  type: "number",
                                                  readonly: "",
                                                  "hide-details": true
                                                },
                                                model: {
                                                  value: product.pieces,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "pieces",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "product.pieces"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12", sm: "3" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Weight",
                                                  type: "number",
                                                  suffix: "KG",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.countTotal(
                                                      "weight"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: product.weight,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "weight",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "product.weight"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 full-width",
                                        attrs: {
                                          dense: "",
                                          color: "blue darken-2",
                                          placeholder:
                                            "enter delivery postcode",
                                          label: "Product",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: product.products_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              product,
                                              "products_name",
                                              $$v
                                            )
                                          },
                                          expression: "product.products_name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-sm-1 pa-0 mt-1",
                                          attrs: { justify: "space-between" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 pr-sm-2",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: _vm.rules.required,
                                                  label: "Custom price",
                                                  type: "number",
                                                  suffix: _vm.ship.currency,
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.countTotal(
                                                      "custom_price"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: product.custom_price,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "custom_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "product.custom_price"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  display: "none"
                                                },
                                                attrs: {
                                                  label: "Insured price",
                                                  type: "number",
                                                  suffix: _vm.ship.currency,
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.countTotal(
                                                      "insured_price"
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: product.insured_price,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      product,
                                                      "insured_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "product.insured_price"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  index !== _vm.ship.order_products.length - 1
                                    ? _c("v-divider", { staticClass: "ma-4" })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-subheader",
                {
                  staticClass: "justify-center font-weight-black mt-2 mb-1",
                  staticStyle: { height: "45px" }
                },
                [_vm._v("Package")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "justify-center",
                  staticStyle: { "margin-top": "-10px" },
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-sm-2",
                      attrs: { cols: "6", sm: "3" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "body-1 full-width",
                        attrs: {
                          dense: "",
                          color: "blue darken-2",
                          label: "Total Customs Price",
                          readonly: "",
                          suffix: _vm.ship.currency
                        },
                        model: {
                          value: _vm.ship.total_custom_price,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "total_custom_price", $$v)
                          },
                          expression: "ship.total_custom_price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-sm-2",
                      attrs: { cols: "6", sm: "3" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "body-1 full-width",
                        attrs: {
                          dense: "",
                          rules: [
                            function(v) {
                              return (!!v && v <= 31) || "Max: 31"
                            }
                          ],
                          color: "blue darken-2",
                          label: "Total Weight",
                          suffix: "KG"
                        },
                        model: {
                          value: _vm.ship.total_weight,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "total_weight", $$v)
                          },
                          expression: "ship.total_weight"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-sm-2",
                      attrs: { cols: "4", sm: "2" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          rules: [
                            function(v) {
                              return (
                                (!!v && v >= 15 && v <= 120) ||
                                "Min: 15, Max: 120"
                              )
                            }
                          ],
                          label: "Length",
                          name: "dhlLeght",
                          type: "number",
                          suffix: "cm"
                        },
                        model: {
                          value: _vm.ship.length,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "length", $$v)
                          },
                          expression: "ship.length"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pr-sm-2",
                      attrs: { cols: "4", sm: "2" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          rules: [
                            function(v) {
                              return (
                                (!!v && v >= 11 && v <= 60) ||
                                "Min: 11, Max: 60"
                              )
                            }
                          ],
                          label: "Width",
                          name: "dhlWidth",
                          type: "number",
                          suffix: "cm"
                        },
                        model: {
                          value: _vm.ship.width,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "width", $$v)
                          },
                          expression: "ship.width"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "4", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          rules: [
                            function(v) {
                              return (
                                (!!v && v >= 1 && v <= 60) || "Min: 1, Max: 60"
                              )
                            }
                          ],
                          label: "Height",
                          name: "dhlHeight",
                          type: "number",
                          suffix: "cm"
                        },
                        model: {
                          value: _vm.ship.height,
                          callback: function($$v) {
                            _vm.$set(_vm.ship, "height", $$v)
                          },
                          expression: "ship.height"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-subheader",
                {
                  staticClass: "justify-center font-weight-black mt-2",
                  staticStyle: { height: "45px" }
                },
                [_vm._v("Additional paraments")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "justify-center",
                  staticStyle: { "margin-top": "-10px" },
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.ship.serviceType !== "V53WPAK"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: {
                              flat: "",
                              dense: "",
                              "hide-details": "",
                              disabled: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "14px" }
                                        },
                                        [_vm._v("Return label")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              622072697
                            ),
                            model: {
                              value: _vm.ship.print_return,
                              callback: function($$v) {
                                _vm.$set(_vm.ship, "print_return", $$v)
                              },
                              expression: "ship.print_return"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.ship.serviceType !== "V53WPAK"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: {
                              flat: "",
                              dense: "",
                              "hide-details": "",
                              disabled: _vm.isNeighborDisabled
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "14px" }
                                        },
                                        [_vm._v("No neighbor (€0.29)")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1473064990
                            ),
                            model: {
                              value: _vm.ship.neighbor,
                              callback: function($$v) {
                                _vm.$set(_vm.ship, "neighbor", $$v)
                              },
                              expression: "ship.neighbor"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.ship.serviceType !== "V53WPAK"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: { flat: "", dense: "", "hide-details": "" },
                            on: { change: _vm.changeNamedPersonOnly },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "14px" }
                                        },
                                        [_vm._v("Named person only €1.49)")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2634251360
                            ),
                            model: {
                              value: _vm.ship.NamedPersonOnly,
                              callback: function($$v) {
                                _vm.$set(_vm.ship, "NamedPersonOnly", $$v)
                              },
                              expression: "ship.NamedPersonOnly"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-0 pt-0 dhl-insurance",
                          attrs: { row: "", dense: "", "hide-details": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v("Insurance:")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.ship.additional_insurance,
                            callback: function($$v) {
                              _vm.$set(_vm.ship, "additional_insurance", $$v)
                            },
                            expression: "ship.additional_insurance"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c("v-radio", {
                            staticClass: "mr-2",
                            attrs: { value: "500", dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "13px" } },
                                      [_vm._v("€500")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _vm.ship.total_custom_price > 500
                            ? _c("v-radio", {
                                staticClass: "mr-2",
                                attrs: { value: "2500", dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "13px"
                                              }
                                            },
                                            [_vm._v("€2.500 (+€6)")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2267244487
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ship.total_custom_price > 2500
                            ? _c("v-radio", {
                                staticClass: "mr-2",
                                attrs: { value: "25000", dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "13px"
                                              }
                                            },
                                            [_vm._v("€25.000 (+€18)")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3839068488
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { width: "70vw", persistent: "" },
                  model: {
                    value: _vm.alert,
                    callback: function($$v) {
                      _vm.alert = $$v
                    },
                    expression: "alert"
                  }
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                dark: "",
                                dense: "",
                                color: "blue darken-3"
                              }
                            },
                            [
                              _c("v-toolbar-title", [_vm._v("Attention!!!")]),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-toolbar-items",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.alert = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "fade",
                                mode: "out-in",
                                appear: ""
                              }
                            },
                            [
                              _c("v-alert", {
                                staticClass: "ma-0",
                                attrs: {
                                  dismissible: "",
                                  color: _vm.type,
                                  elevation: "2"
                                },
                                domProps: { innerHTML: _vm._s(_vm.onSaveText) },
                                model: {
                                  value: _vm.alert,
                                  callback: function($$v) {
                                    _vm.alert = $$v
                                  },
                                  expression: "alert"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _vm.ship.serviceType === "V01PAK"
            ? _c(
                "v-subheader",
                { staticClass: "justify-center font-weight-black mt-2" },
                [
                  _vm._v(
                    "DHL Paket shipping cost: " +
                      _vm._s(_vm.shippingcost) +
                      " EUR ( " +
                      _vm._s(
                        Math.round(_vm.shippingcost * 25.5).toLocaleString()
                      ) +
                      " CZK )"
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "warning", loading: _vm.creatingRequested },
              on: { click: _vm.validateShippingLable }
            },
            [_c("span", [_vm._v("Validate")])]
          ),
          _vm._v(" "),
          _vm.validate
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary", loading: _vm.creatingRequested },
                  on: { click: _vm.getShippingLable }
                },
                [_c("span", [_vm._v("Ship")])]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }