<template>
    <v-dialog v-model="show" :fullscreen="fullscreen" :scrollable="scrollable" :width="width" hide-overlay>
        <slot name="content"></slot>
    </v-dialog>
</template>

<script>
    export default {
        name: "ScrollableDialog",
        props: {
            show:{},
            fullscreen:{},
            scrollable:{},
            width:{}
        }
    }
</script>