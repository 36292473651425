<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://admin:a1z5c6d8@cameramate.com/tools/currencies.php?disableMenu=1"></iframe>
</template>

<script>
    export default {
        name: "Currencies"
    }
</script>

<style scoped>

</style>