var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _vm.productLoaded
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "5" } },
                [
                  _c("v-subheader", { staticClass: "font-weight-black" }, [
                    _vm._v("Quantity:\n                "),
                    _c(
                      "span",
                      {
                        staticClass: "ml-1",
                        class:
                          _vm.product.products_quantity == 0
                            ? "text--lighten-red"
                            : "text--lighten-green"
                      },
                      [
                        _vm._v(_vm._s(_vm.product.products_quantity)),
                        _vm.product.products_quantity === 0
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mb-1",
                                attrs: { color: "red", small: "" }
                              },
                              [_vm._v("mdi-alarm-light")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("warehouse-info", { attrs: { product: _vm.product } }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "2" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openEditProductDialog(_vm.product)
                        }
                      }
                    },
                    [_vm._v("mdi-square-edit-outline")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-1",
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.printProductBarcode(_vm.product)
                        }
                      }
                    },
                    [_vm._v("mdi-barcode-scan")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex align-right", attrs: { cols: "5" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-subheader",
                    { staticClass: "justify-center font-weight-black" },
                    [_vm._v("Status:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "justify-center ma-0 pa-0",
                      attrs: { dense: "", row: "", "hide-details": "" },
                      model: {
                        value: _vm.productStatus,
                        callback: function($$v) {
                          _vm.productStatus = $$v
                        },
                        expression: "productStatus"
                      }
                    },
                    [
                      _c("v-radio", {
                        staticClass: "ma-0 pa-0",
                        attrs: { color: "red", value: "0" }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        staticClass: "ma-0 pa-0",
                        attrs: { color: "success", value: "1" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  staticClass: "elevation-1  scroll-tabs",
                  attrs: { "show-arrows": "", "hide-slider": "", grow: "" },
                  model: {
                    value: _vm.productTabActive,
                    callback: function($$v) {
                      _vm.productTabActive = $$v
                    },
                    expression: "productTabActive"
                  }
                },
                [
                  _vm._l(_vm.productTabs, function(productTab, i) {
                    return _c(
                      "v-tab",
                      {
                        key: productTab.key,
                        attrs: {
                          href: "#tab-" + productTab.key,
                          ripple: "",
                          disabled: _vm.disabledTab(productTab.name)
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeTab(productTab.key)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(productTab.name) +
                            "\n                    "
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "elevation-1",
                      attrs: { touchless: "" },
                      model: {
                        value: _vm.productTabActive,
                        callback: function($$v) {
                          _vm.productTabActive = $$v
                        },
                        expression: "productTabActive"
                      }
                    },
                    _vm._l(_vm.productTabs, function(productTab, i) {
                      return _c(
                        "v-tab-item",
                        {
                          key: productTab.key,
                          staticClass: "text-center  scroll-tab-item",
                          attrs: { value: "tab-" + productTab.key }
                        },
                        [
                          _c("v-progress-circular", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingTab,
                                expression: "loadingTab"
                              }
                            ],
                            staticClass: "ma-5",
                            attrs: {
                              size: 70,
                              width: 7,
                              color: "green",
                              indeterminate: ""
                            }
                          }),
                          _vm._v(" "),
                          _vm.productLoaded
                            ? _c(productTab.component, {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.loadingTab,
                                    expression: "!loadingTab"
                                  }
                                ],
                                tag: "component",
                                staticClass: "full-height",
                                attrs: { isActive: _vm.productTabActive },
                                on: {
                                  componentReady: function($event) {
                                    _vm.loadingTab = false
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }