<template>
    <v-card outlined :rounded="false" flat style="border: none; display: flex !important; flex-direction: column;" class="rounded-0">
        <v-card-title class="ma-0 pa-0">
            <v-toolbar dark dense color="blue darken-3" class="text-truncate">
                Action on selected products ({{ selectedProducts.length }})
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click.stop="closeGroupActionDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="flex-grow: 1; overflow: auto; height:400px;" class="rounded-0">
            <v-row no-gutters>
                <v-col cols="8" class="d-flex justify-space-between align-start ma-0">
                    <v-spacer></v-spacer>
                    <v-radio-group
                        dense
                        row
                        hide-details
                        v-model="StatusForChecked"
                        @change="setStatusForChecked"
                    >
                        <v-radio
                            color="success"
                            :value=1
                            label="On"
                        ></v-radio>
                        <v-radio
                            color="red"
                            :value=0
                            label="Off"
                        ></v-radio>
                    </v-radio-group>
                    <v-spacer></v-spacer>
                </v-col>

                <v-col cols="4" class="d-flex justify-space-between align-start ma-0">
                    <v-checkbox
                        v-model="onEbay"
                        label="Update on eBay"
                        hide-details
                        class="text-truncate"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" class="d-flex justify-space-between align-start mt-1">
                    <tree-select
                         :load-options="loadOptions"
                         :options="productsOptions"
                         :auto-load-root-options="true"
                         :normalizer="normalizer"
                         :searchable="true"
                         :always-open="true"
                         v-model="CategoryForChecked"
                         placeholder="Category for checked products"
                         @input="setCategoryForChecked"
                         name="productCategory"
                         :clearable="false"
                         search-nested
                         :show-count="true"
                    />
                </v-col>
            </v-row>
            <confirm ref="confirm"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-progress-linear
                    v-model="upload"
                    color="blue-grey"
                    height="25"
                    reactive
                    v-if="upload"
                    class="mr-2"
            >
                <template v-slot="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
            <v-checkbox
                v-else
                dense
                hide-details
                v-model="refreshProductsList"
                label="Refresh prroducts list"
                class="pt-0 mt-0"
                style="display: none;"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn color="default" small @click="closeGroupActionDialog">Close</v-btn>
            <v-btn color="success" small @click="saveGroupActionDialog">Update</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

    import { mapGetters } from 'vuex'
    import TreeSelect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
    import EventBus from "@/event-bus";
    import Confirm from '@/components/Confirm'

    export default {
        name: "GroupActionDialog",
        props: {
            selectedProducts: {
                type: Array,
                required: true,
            }
        },
        data: () => ({
            upload: 0,
            productsOptions: null,
            CategoryForChecked: null,
            StatusForChecked: null,
            refreshProductsList: true,
            initialSelectedProducts: null,
            onEbay: false,
            normalizer(node) {
                if(node.descriptions){
                    return {
                        id: node.descriptions.categories_id,
                        label: (node.categories_shop === 1 ? '🌟 ' : '') + node.descriptions.categories_name,
                        children: node.children,
                    }
                }
            }
        }),
        watch: {
            upload: function (newUpload, oldUpload) {
                if (newUpload === 100 && this.refreshProductsList) {
                    //EventBus.$emit('reloadProducts');
                }
            }
        },
        computed:{
            ...mapGetters([
                'productCategories',
            ]),
        },
        methods:{
            async loadOptions({action}){
                if (action === LOAD_ROOT_OPTIONS) {
                    this.productsOptions = this.productCategories;
                }
            },
            setCategoryForChecked(val){
                this.CategoryForChecked = val;
            },
            setStatusForChecked(val){
                this.StatusForChecked = val;
            },
            closeGroupActionDialog(){
                this.$emit('closeGroupActionDialog')
                this.upload = 0;
                this.CategoryForChecked = null
                this.StatusForChecked = null
                this.initialSelectedProducts = null
                this.onEbay = false
            },
            asyncSave(key, total) {

                let product = this.initialSelectedProducts[key];
                let mProduct = this.selectedProducts[key];
                let prms = new Promise((resolve, reject) => {
                    if (this.StatusForChecked !== null && this.StatusForChecked !== undefined) {
                        product.products_status = this.StatusForChecked;
                    }
                    if (this.CategoryForChecked !== null && this.CategoryForChecked !== undefined && product.category) {
                        product.category.categories_id = this.CategoryForChecked;
                    }
                    let forSave = this.difference(product, this.selectedProducts[key], ['products_id'])
                    this.$emit('updateProduct', forSave, () => {
                        _.assign(mProduct, forSave)
                        return true
                    }, reject);

                    resolve(product)
                })
                prms.then((res) => {
                    this.upload += 100/total
                }).catch((err) => {
                    // handle error
                })
            },
            difference(object, base, excluded = []) {
                return _.transform(object, (result, value, key) => {
                    if (!_.isEqual(value, base[key]) || excluded.includes(key)) {
                        result[key] = value
                    }
                });
            },
            async saveGroupActionDialog(){
                this.upload = 0
                if (await this.$refs.confirm.open('Change Product', 'Are you sure?', { color: 'success' })) {
                    let total = this.selectedProducts.length;
                    this.initialSelectedProducts = _.cloneDeep(this.selectedProducts)
                    for (let key in this.selectedProducts) {
                        this.asyncSave(key, total)
                    }
                }
            }
        },
        components: {
            TreeSelect,
            Confirm
        }
    }
</script>

<style scoped>

</style>