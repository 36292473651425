var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex text-subtitle-2" },
    [
      _c(
        "span",
        {
          class: {
            "text-left mr-1 green--text": _vm.set.set_complited > 0,
            "text-left mr-1 red--text text--lighten-1":
              _vm.set.set_complited < 0
          }
        },
        [_vm._v(_vm._s(_vm.set.set_complited + "%"))]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "text-left" }, [
        _vm._v(_vm._s(" - " + _vm.set.set_name))
      ]),
      _vm._v(" "),
      _c(
        "v-icon",
        {
          attrs: { color: "success", size: "medium" },
          on: {
            click: function($event) {
              _vm.dialog = true
            }
          }
        },
        [_vm._v("info")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
            scrollable: true
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.product
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-truncate text-left title ma-0 pl-1"
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.product.products_id) +
                                    " - " +
                                    _vm._s(
                                      _vm.product.descriptions.products_name
                                    ) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.dialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4" },
                [_c("set", { attrs: { setInfo: _vm.set } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }