var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "8", md: "4" } },
        [
          _c(
            "v-card",
            { staticClass: "elevation-12" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [_c("v-toolbar-title", [_vm._v("Reset Password")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: { dismissible: "", type: _vm.status },
                      model: {
                        value: _vm.show,
                        callback: function($$v) {
                          _vm.show = $$v
                        },
                        expression: "show"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.message) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    { ref: "reset", attrs: { "aria-label": "Reset Password" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.email,
                                    _vm.rules.max255chars
                                  ],
                                  label: "Email",
                                  name: "email"
                                },
                                model: {
                                  value: _vm.input.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "email", $$v)
                                  },
                                  expression: "input.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.loading },
                          on: { click: _vm.validate }
                        },
                        [
                          _vm._v(
                            "\n                        Send Reset Link\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "auth.login" } } },
                        [_vm._v("Login")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }