<template>
    <div class="ordered-datepicker">
        <v-menu
                ref="menu"
                v-model="menu"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field
                    dense
                    :value="date"
                    :rules="rules"
                    prepend-icon="event"
                    class="caption font-weight-regular"
                    :label="labelName"
                    readonly
                    v-on="on"
                    :append-icon="isCleared ? 'clear' : null"
                    @click:append="toggleClear"
                />
            </template>
            <v-date-picker
                    v-model="date"
                    @input="menu = false"
                    no-title
                    class="caption font-weight-regular text-right">
            </v-date-picker>
        </v-menu>

    </div>
    
</template>

<script>
    export default {
        name: "OrderedDatepicker",
        props: {
            value: String,
            labelName: String,
            rules: Array,
            isCleared: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menu: false,
                newDate: null,
            }
        },
        computed: {
            date: {
                get: function () {
                    return (this.value === null || this.value === '0000-00-00 00:00:00') ?  null : this.value.substr(0, 10)
                },
                set: function (newValue) {
                    this.$emit('input', newValue)
                }
            }
        },
        methods: {
            toggleClear: function() {
                this.value = null
                this.$emit('input', this.value)
            }
        }
    }
</script>

<style scoped>

</style>