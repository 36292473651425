<template>
    <div style="width: 100%">
        <v-data-table
            v-if="statusHistoryData"
            disable-sort
            hide-default-footer
            :headers="headers"
            :items="statusHistoryData"
            class="scroll-table"
            dense
            width="100%"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td class="px-2">{{ $moment(props.item.date_added).format('MM-DD-YYYY') }}</td>
                    <td>
                        <v-divider vertical></v-divider>
                    </td>
                    <td>
                        <v-icon small color="green" v-if="props.item.customer_notified">done</v-icon>
                        <v-icon small color="red" v-else>notifications_off</v-icon>
                    </td>
                    <td>
                        <v-divider vertical></v-divider>
                    </td>
                    <td :class="'px-2 '+props.item.order_status.orders_status_name">{{props.item.order_status.orders_status_name }}</td>
                    <td>
                        <v-divider vertical></v-divider>
                    </td>
                    <td class="px-2 text-left" v-html="props.item.comments"></td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "StatusHistory",
        props:{
            isActive:{},
            id: {}
        },
        data(){
            return {
                headers: [
                    {text: 'Date added', align: 'center', value: 'date_added'},
                    {text: '', align: 'center'},
                    {text: 'Notified',  align: 'center', sortable: false, value: 'customer_notified'},
                    {text: '', align: 'center'},
                    {text: 'Status', align: 'center', value: 'orders_status_id'},
                    {text: '', align: 'center'},
                    {text: 'Comment', align: 'center', sortable: false, value: 'comments'}
                ],
                statusHistoryData: null
            }
        },
        computed: {
            ...mapGetters([
                'orders_statusesList',
            ]),
        },
        mounted() {
            this.init()
        },
        methods:{
            init(){
                axios.get(`/api/orders_status_history/${this.id ? this.id : this.$route.params.id}`)
                    .then(response => {
                            this.statusHistoryData = response.data
                            this.$emit('componentReady')
                    });
            }
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-status-history"){
                    this.init()
                }
            }
        }
    }
</script>

<style scoped>

    .scroll-table{
        overflow: auto;
        max-height: 80vh;
    }

</style>