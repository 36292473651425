<template>
    <v-card>
        <v-card-text class="pt-4">
            <v-form
                ref="formTrackingNumber"
                v-model="validTrackingNumber"
                lazy-validation
            >
                <v-simple-table
                    dense
                >
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-center">
                                Name
                            </th>
                            <th class="text-center">
                                Tracking Number
                            </th>
                            <th class="text-center">
                                Carrier
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="order_products in order.order_products"
                            :key="order_products.products_id"
                        >
                            <td class="px-2">{{ order_products.products_name }}</td>
                            <td size="10%" class="text-center px-2">
                                <v-text-field
                                    v-model="order_products.ShipmentTrackingNumber"
                                    style="width: 150px;"
                                    dense
                                    :rules="rules"
                                    required
                                    @change="changeTrakingNumber($event, order_products)"
                                    autofocus
                                    clearable
                                    reverse
                                ></v-text-field>
                            </td>
                            <td size="10%" class="text-center px-2">
                                <v-select
                                    v-model="order_products.ShippingCarrierUsed"
                                    :items="carriers"
                                    style="width: 150px;"
                                    dense
                                    :rules="rules"
                                    required
                                    class="text-right"
                                    reverse
                                ></v-select>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer/>
            <v-checkbox
                v-model="reloadOrders"
                label="Reload orders"
            ></v-checkbox>
            <v-spacer/>
            <v-btn
                color="primary"
                @click="submitTracking"
            >Submit</v-btn>
            <v-spacer/>
        </v-card-actions>
    </v-card>
    
</template>

<script>

    import EventBus from "@/event-bus";

    export default {
        name: "Shipping",
        props: {
            order: {
                required: true
            }
        },
        data: () => ({
            loading: false,
            carriers: [
                { text: 'FedEx', value: 'FedEx' },
                { text: 'CZECK POST', value: 'CZECK POST' }
            ],
            validTrackingNumber: true,
            rules: [
                v => !!v || 'Required',
            ],
            reloadOrders: true
        }),
        mounted(){
        },
        methods: {
            changeTrakingNumber(val, order_products) {
                console.log(val)
                switch (val[0]) {
                    case 'j': //Fedex
                        order_products.ShipmentTrackingNumber = val.substring(val.length-12, val.length)
                        order_products.ShippingCarrierUsed = 'FedEx'
                        break
                    case 'a': //Fedex
                        order_products.ShipmentTrackingNumber = val.substring(1, val.length-4);
                        order_products.ShippingCarrierUsed = 'FedEx'
                        break;
                    case 'b': //CZECK POST
                        order_products.ShipmentTrackingNumber = val.substring(1, val.length);
                        order_products.ShippingCarrierUsed = 'CZECK POST'
                        break;
                    default:
                        //order_products.ShipmentTrackingNumber = ''
                        //order_products.ShippingCarrierUsed = ''
                        break
                }
            },
            submitTracking(){
                console.log(this.order.order_products);
                if (this.$refs.formTrackingNumber.validate()) {
                    console.log('submitTracking');
                    axios.post('/api/orders/complete/'+this.order.orders_id,
                        {order_products: this.order.order_products}
                    ).then(response => {
                        console.log(response.data)
                        if (this.reloadOrders) EventBus.$emit('reloadOrders');
                    })
                }
            }
        }
    }
</script>