<template>
    <div class="pa-0">
        <v-btn small icon color="pink" :disabled="addNewProductDialog"><v-icon @click="showAddNewProductDialog">mdi-tab-plus</v-icon></v-btn>
<!--        <custom-dialog :show="addNewProductDialog"
                       :title="title"
                       @closeDialog="addNewProductDialog = false"
        >
            <template v-slot:content>
                <product-form :productid="productId" @productAdded="newProductAdded" @saveProductInfo="saveProductInfo"/>
            </template>
        </custom-dialog>-->
        <v-dialog name="AddNewProductDialog" v-model="addNewProductDialog" :fullscreen="fullscreenAddNewProductDialog" width="60vw" :scrollable="true" :key="'add_new_porduct_'+dialogId" persistent :transition="false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="closeAddNewProductDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <product-form
                        :productid="productId"
                        @productAdded="newProductAdded"
                        @saveProductInfo="saveProductInfo"
                        @fullscreen="fullscreenAddNewProductDialog = true"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>

    import { mapGetters } from 'vuex'
    import CustomDialog from "../../../components/CustomDialog";
    import ProductForm from "./ProductForm";
    import ReusableProduct from "../../../store/product/ReusableProduct"

    export default {
        name: "AddNewProduct",
        props: {
            productForEdit: {
                required: false,
                type: Object,
                default: null,
            }
        },
        data: () => ({
            addNewProductDialog:false,
            title: 'Add new product',
            productId: null,
            dialogId: '',
            fullscreenAddNewProductDialog: false
        }),
        mounted() {
            //this.productId = '33937'
            /*if (!this.$store.hasModule("products/product_"+this.productId)) {
                this.$store.registerModule(['products', "product_"+this.productId], ReusableProduct)
            }*/
        },
        computed: {
            ...mapGetters([
                'productOptions'
            ]),
        },
        methods:{
            showAddNewProductDialog() {
                this.dialogId = _.uniqueId()

                this.fullscreenAddNewProductDialog = false
                this.addNewProductDialog = true;
            },
            closeAddNewProductDialog() {
                this.addNewProductDialog = false;
                this.fullscreenAddNewProductDialog = false
                this.title = 'Add new product'
            },
            newProductAdded(product){
                this.title = product.products_id
            },
            saveProductInfo(product){

                this.addNewProductDialog= false;
                //TODO request for save info
            }
        },
        components: {
            CustomDialog,
            ProductForm
        }
    }
</script>

<style scoped>

</style>