var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: { "max-height": "48px", height: "48px" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { dense: "", flat: "", light: "", color: "grey lighten-5" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "RefreshButton" } }, [
                _vm._v("Refresh")
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "CalcButton" } }, [
                _vm._v("Calc all")
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "OnButton" } }, [_vm._v("ON all")]),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "OffButton", disabled: "" } }, [
                _vm._v("OFF all")
              ]),
              _vm._v(" "),
              _c("div", { attrs: { id: "ebay-siteID" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-flex", { staticClass: "pa-1", attrs: { xs12: "" } }, [
        _c("div", { attrs: { id: "productTable" } })
      ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }