var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "shipmentLabel",
      model: {
        value: _vm.labelForm,
        callback: function($$v) {
          _vm.labelForm = $$v
        },
        expression: "labelForm"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "justify-center mt-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 pr-sm-2", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-select",
                {
                  staticClass: "text-body-2",
                  attrs: {
                    "hide-details": true,
                    items: _vm.serviceTypes,
                    dense: ""
                  },
                  model: {
                    value: _vm.ship.serviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.ship, "serviceType", $$v)
                    },
                    expression: "ship.serviceType"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                      staticStyle: { width: "55px" },
                      attrs: { slot: "prepend" },
                      slot: "prepend"
                    },
                    [_vm._v("\n                    Service:\n                ")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-select",
                {
                  staticClass: "text-body-2",
                  attrs: {
                    "hide-details": true,
                    items: _vm.dropOffTypes,
                    dense: ""
                  },
                  model: {
                    value: _vm.ship.dropOff,
                    callback: function($$v) {
                      _vm.$set(_vm.ship, "dropOff", $$v)
                    },
                    expression: "ship.dropOff"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                      staticStyle: { width: "55px" },
                      attrs: { slot: "prepend" },
                      slot: "prepend"
                    },
                    [
                      _vm._v(
                        "\n                    Drop Off:\n                "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-select",
                {
                  staticClass: "text-body-2",
                  attrs: {
                    "hide-details": true,
                    items: _vm.fedexAccounts,
                    dense: ""
                  },
                  model: {
                    value: _vm.ship.fedexAccount,
                    callback: function($$v) {
                      _vm.$set(_vm.ship, "fedexAccount", $$v)
                    },
                    expression: "ship.fedexAccount"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                      staticStyle: { width: "55px" },
                      attrs: { slot: "prepend" },
                      slot: "prepend"
                    },
                    [_vm._v("\n                    Account:\n                ")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-subheader",
        {
          staticClass: "justify-center font-weight-black ma-0",
          staticStyle: { height: "30px" }
        },
        [_vm._v("Recipient")]
      ),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "pa-2",
          attrs: { color: "blue-grey lighten-5", elevation: "2" }
        },
        [
          _c(
            "v-row",
            { staticClass: "justify-center mt-0", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 pr-sm-2", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-text-field",
                    {
                      staticClass: "text-body-2 full-width",
                      attrs: {
                        "hide-details": true,
                        rules: _vm.rules.required,
                        color: "blue darken-2",
                        dense: "",
                        placeholder: "enter delivery name"
                      },
                      model: {
                        value: _vm.ship.delivery_name,
                        callback: function($$v) {
                          _vm.$set(_vm.ship, "delivery_name", $$v)
                        },
                        expression: "ship.delivery_name"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                          staticStyle: { width: "50px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend"
                        },
                        [
                          _vm._v(
                            "\n                        Person:\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-text-field",
                    {
                      staticClass: "text-body-2 full-width mt-4",
                      attrs: {
                        "hide-details": true,
                        rules: _vm.rules.required,
                        color: "blue darken-2",
                        dense: "",
                        placeholder: "enter delivery street address"
                      },
                      model: {
                        value: _vm.ship.street,
                        callback: function($$v) {
                          _vm.$set(_vm.ship, "street", $$v)
                        },
                        expression: "ship.street"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                          staticStyle: { width: "50px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend"
                        },
                        [
                          _vm._v(
                            "\n                        Street:\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-text-field",
                    {
                      staticClass: "text-body-2 full-width mt-4",
                      attrs: {
                        "hide-details": true,
                        color: "blue darken-2",
                        dense: "",
                        placeholder: "Enter Phone"
                      },
                      model: {
                        value: _vm.ship.customers_telephone,
                        callback: function($$v) {
                          _vm.$set(_vm.ship, "customers_telephone", $$v)
                        },
                        expression: "ship.customers_telephone"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                          staticStyle: { width: "50px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend"
                        },
                        [
                          _vm._v(
                            "\n                        Phone:\n                    "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-text-field",
                    {
                      staticClass: "text-body-2 full-width",
                      attrs: {
                        "hide-details": true,
                        color: "blue darken-2",
                        dense: "",
                        placeholder: "enter delivery company"
                      },
                      model: {
                        value: _vm.ship.delivery_company,
                        callback: function($$v) {
                          _vm.$set(_vm.ship, "delivery_company", $$v)
                        },
                        expression: "ship.delivery_company"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                          staticStyle: { width: "60px" },
                          attrs: { slot: "prepend" },
                          slot: "prepend"
                        },
                        [
                          _vm._v(
                            "\n                        Company:\n                    "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-sm-1 pa-0 mt-3",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width",
                              attrs: {
                                "hide-details": true,
                                rules: _vm.rules.required,
                                color: "blue darken-2",
                                dense: "",
                                placeholder: "enter delivery city"
                              },
                              model: {
                                value: _vm.ship.delivery_city,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "delivery_city", $$v)
                                },
                                expression: "ship.delivery_city"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "50px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                City:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width",
                              attrs: {
                                "hide-details": true,
                                color: "blue darken-2",
                                dense: "",
                                placeholder: "enter delivery state"
                              },
                              model: {
                                value: _vm.ship.delivery_state,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "delivery_state", $$v)
                                },
                                expression: "ship.delivery_state"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "55px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Province:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-sm-1 pa-0 mt-3",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width mt-4",
                              attrs: {
                                "hide-details": true,
                                rules: _vm.rules.required,
                                color: "blue darken-2",
                                dense: "",
                                placeholder: "enter delivery postcode"
                              },
                              model: {
                                value: _vm.ship.delivery_postcode,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "delivery_postcode", $$v)
                                },
                                expression: "ship.delivery_postcode"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "50px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Postal:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-autocomplete",
                            {
                              staticClass: "text-body-2 full-width mt-4",
                              attrs: {
                                "hide-details": true,
                                items: _vm.countries,
                                rules: _vm.rules.required,
                                dense: ""
                              },
                              model: {
                                value: _vm.ship.delivery_country,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "delivery_country", $$v)
                                },
                                expression: "ship.delivery_country"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "55px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Country:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-subheader",
        {
          staticClass: "justify-center font-weight-black ma-0",
          staticStyle: { height: "30px" }
        },
        [_vm._v("Commodities")]
      ),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "pa-2",
          attrs: { color: "brown lighten-5", elevation: "2" }
        },
        _vm._l(_vm.ship.order_products, function(product, index) {
          return _vm.ship.order_products.length
            ? _c(
                "v-row",
                {
                  key: product.products_id,
                  staticClass: "justify-center my-1 mx-1",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pr-sm-2",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-sm-1 pa-0 mt-1",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pa-0 pr-sm-2",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "text-body-2 full-width",
                                    attrs: {
                                      "hide-details": true,
                                      items: _vm.descriptionTypes,
                                      dense: ""
                                    },
                                    model: {
                                      value: product.description,
                                      callback: function($$v) {
                                        _vm.$set(product, "description", $$v)
                                      },
                                      expression: "product.description"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pa-0 pr-sm-2",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "text-body-2 full-width",
                                    attrs: {
                                      "hide-details": true,
                                      color: "blue darken-2",
                                      dense: "",
                                      placeholder: "enter delivery postcode"
                                    },
                                    model: {
                                      value: product.products_name,
                                      callback: function($$v) {
                                        _vm.$set(product, "products_name", $$v)
                                      },
                                      expression: "product.products_name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-sm-1 pa-0 mt-0",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pa-0 pr-sm-2",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass:
                                        "text-body-2 right-input pt-0",
                                      attrs: {
                                        "hide-details": true,
                                        rules: _vm.rules.required,
                                        suffix: _vm.ship.currency,
                                        type: "number"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.countTotal("custom_price")
                                        }
                                      },
                                      model: {
                                        value: product.custom_price,
                                        callback: function($$v) {
                                          _vm.$set(product, "custom_price", $$v)
                                        },
                                        expression: "product.custom_price"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                          staticStyle: { width: "40px" },
                                          attrs: { slot: "prepend" },
                                          slot: "prepend"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Price:\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pa-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass:
                                        "text-body-2 right-input pt-0",
                                      attrs: {
                                        "hide-details": true,
                                        suffix: _vm.ship.currency,
                                        type: "number"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.countTotal("insured_price")
                                        }
                                      },
                                      model: {
                                        value: product.insured_price,
                                        callback: function($$v) {
                                          _vm.$set(
                                            product,
                                            "insured_price",
                                            $$v
                                          )
                                        },
                                        expression: "product.insured_price"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                          staticStyle: { width: "45px" },
                                          attrs: { slot: "prepend" },
                                          slot: "prepend"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Insured:\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-0 pa-0",
                                  attrs: { cols: "4", sm: "3" }
                                },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass:
                                        "text-body-2 right-input  pt-0",
                                      attrs: {
                                        "hide-details": true,
                                        suffix: "KG"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.countTotal("weight")
                                        }
                                      },
                                      model: {
                                        value: product.weight,
                                        callback: function($$v) {
                                          _vm.$set(product, "weight", $$v)
                                        },
                                        expression: "product.weight"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                          staticStyle: { width: "45px" },
                                          attrs: { slot: "prepend" },
                                          slot: "prepend"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Weight:\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          index != _vm.ship.order_products.length - 1
                            ? _c("v-divider", { staticClass: "my-2 pt-0 pl-1" })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-subheader",
        {
          staticClass: "justify-center font-weight-black mt-2",
          staticStyle: { height: "30px" }
        },
        [_vm._v("Package")]
      ),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "pa-2",
          attrs: { color: "deep-orange lighten-5", elevation: "2" }
        },
        [
          _c(
            "v-row",
            { staticClass: "justify-center mt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 ma-0 pr-sm-2",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-sm-1",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pr-sm-2", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width right-input",
                              attrs: {
                                "hide-details": true,
                                suffix: _vm.ship.currency,
                                color: "blue darken-2",
                                dense: "",
                                readonly: ""
                              },
                              model: {
                                value: _vm.ship.total_custom_price,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "total_custom_price", $$v)
                                },
                                expression: "ship.total_custom_price"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "55px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Customs:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pr-sm-2", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width right-input",
                              attrs: {
                                "hide-details": true,
                                suffix: _vm.ship.currency,
                                color: "blue darken-2",
                                dense: "",
                                readonly: ""
                              },
                              model: {
                                value: _vm.ship.total_insured_price,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "total_insured_price", $$v)
                                },
                                expression: "ship.total_insured_price"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "55px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Insured:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "text-body-2 full-width right-input",
                              attrs: {
                                "hide-details": true,
                                rules: _vm.rules.required,
                                color: "blue darken-2",
                                dense: "",
                                readonly: "",
                                suffix: "KG",
                                type: "number"
                              },
                              model: {
                                value: _vm.ship.total_weight,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "total_weight", $$v)
                                },
                                expression: "ship.total_weight"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "55px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Weight:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "my-0 mx-sm-1",
                      staticStyle: { "margin-top": "8px !important" },
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 my-0 mt-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-select",
                            {
                              staticClass: "text-body-2 mt-4",
                              attrs: { items: _vm.signaturetypes, dense: "" },
                              model: {
                                value: _vm.ship.signature,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "signature", $$v)
                                },
                                expression: "ship.signature"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "75px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Signature:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-sm-1",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0 mt-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-select",
                            {
                              staticClass: "text-body-2 ma-0 pa-0",
                              attrs: {
                                "hide-details": true,
                                items: _vm.packtypes,
                                dense: ""
                              },
                              on: { change: _vm.isValid },
                              model: {
                                value: _vm.ship.packtype,
                                callback: function($$v) {
                                  _vm.$set(_vm.ship, "packtype", $$v)
                                },
                                expression: "ship.packtype"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                  staticStyle: { width: "75px" },
                                  attrs: { slot: "prepend" },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n                                Packaging:\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ship.packtype === "YOUR_PACKAGING"
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mx-sm-1 mt-3",
                          attrs: { justify: "space-between", "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mt-3 pa-0 pr-sm-2",
                              attrs: { cols: "3" }
                            },
                            [
                              _c(
                                "v-text-field",
                                {
                                  staticClass: "text-body-2 right-input",
                                  attrs: {
                                    "hide-details": true,
                                    rules: [_vm.packingRules.required],
                                    dense: "",
                                    type: "number"
                                  },
                                  model: {
                                    value: _vm.ship.length,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ship, "length", $$v)
                                    },
                                    expression: "ship.length"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                      staticStyle: { width: "25px" },
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                L:\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "mt-3 pa-0 pr-sm-2",
                              attrs: { cols: "3" }
                            },
                            [
                              _c(
                                "v-text-field",
                                {
                                  staticClass: "text-body-2 right-input",
                                  attrs: {
                                    "hide-details": true,
                                    rules: [_vm.packingRules.required],
                                    dense: "",
                                    type: "number"
                                  },
                                  model: {
                                    value: _vm.ship.width,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ship, "width", $$v)
                                    },
                                    expression: "ship.width"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                      staticStyle: { width: "25px" },
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                W:\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "mt-3 pa-0", attrs: { cols: "3" } },
                            [
                              _c(
                                "v-text-field",
                                {
                                  staticClass: "text-body-2 right-input",
                                  attrs: {
                                    "hide-details": true,
                                    rules: [_vm.packingRules.required],
                                    dense: "",
                                    type: "number"
                                  },
                                  model: {
                                    value: _vm.ship.height,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ship, "height", $$v)
                                    },
                                    expression: "ship.height"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 pa-0 font-weight-medium text-no-wrap",
                                      staticStyle: { width: "25px" },
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                H:\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "mt-3 pa-0", attrs: { cols: "3" } },
                            [
                              _c("v-select", {
                                staticClass: "text-body-2",
                                attrs: {
                                  items: _vm.units,
                                  color: "blue darken-2",
                                  dense: "",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.ship.units,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ship, "units", $$v)
                                  },
                                  expression: "ship.units"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-sheet",
        {
          staticClass: "pa-2",
          attrs: { color: "yellow lighten-5", elevation: "2" }
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-switch", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      label: "Return label"
                    },
                    model: {
                      value: _vm.ship.return,
                      callback: function($$v) {
                        _vm.$set(_vm.ship, "return", $$v)
                      },
                      expression: "ship.return"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.creatingRequested },
                      on: { click: _vm.getRate }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Get " +
                            _vm._s(_vm.ship.return ? "return" : "") +
                            " shipping cost"
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.creatingRequested,
                        color: "primary"
                      },
                      on: { click: _vm.getShippingLable }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Generate " +
                            _vm._s(_vm.ship.return ? "return" : "") +
                            " shipping lable"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      color: "orange darken-3",
                      dense: "",
                      label: "ETD"
                    },
                    model: {
                      value: _vm.enableETD,
                      callback: function($$v) {
                        _vm.enableETD = $$v
                      },
                      expression: "enableETD"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "70vw" },
          model: {
            value: _vm.alert,
            callback: function($$v) {
              _vm.alert = $$v
            },
            expression: "alert"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0", attrs: { color: _vm.type } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.type + " darken-3",
                        dark: "",
                        dense: ""
                      }
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Attention!!!")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.alert = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", {
                staticClass: "pa-2",
                staticStyle: { "max-height": "50vh" },
                domProps: { innerHTML: _vm._s(_vm.onSaveText) }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }