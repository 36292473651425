<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form id="login_form" method="POST" action="/login" aria-label="Login" ref="loginForm">
                        <input type="hidden" name="_token" :value="csrf_token">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="email"
                                        :rules="[rules.required, rules.email, rules.max255chars]"
                                        label="Email"
                                        name="email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="password"
                                        :rules="[rules.required, rules.max255chars]"
                                        label="Password"
                                        name="password"
                                        type="password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-switch v-model="remember" label="Remember Me" name="remember" :value="remember"></v-switch>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" @click="validate">Login</v-btn>
                        <router-link :to="{name: 'auth.email'}">Forgot Password?</router-link>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "Login",
        data: () => ({
            email: '',
            password: '',
            remember: true,
            rules: {
                max255chars: v => v.length <= 230 || 'Input too long!',
                required: value => !!value || '',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
        }),
        computed: {
            csrf_token() {
                let token = document.head.querySelector('meta[name="csrf-token"]')
                return token.content
            }
        },
        methods: {
            validate() {
                if (this.$refs.loginForm.validate()) {
                    document.getElementById("login_form").submit()
                }
            }
        }
    }
</script>

<style scoped>

</style>