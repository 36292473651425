var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "pa-3", attrs: { xs12: "", "text-left": "" } },
        [
          _vm.noteData
            ? _c(
                "v-form",
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      name: "input-7-4",
                      label: "Order note"
                    },
                    model: {
                      value: _vm.noteData.orders_note,
                      callback: function($$v) {
                        _vm.$set(_vm.noteData, "orders_note", $$v)
                      },
                      expression: "noteData.orders_note"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        disabled: _vm.noteData.orders_note == null
                      },
                      on: { click: _vm.saveOrderNote }
                    },
                    [_vm._v("\n                Save\n            ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }