<template>
    <router-view></router-view>
    <!--<v-app id="cam">-->
        <!--<v-progress-linear id="loader" height="5"  v-show="loading" class="ma-0" color="success" :indeterminate="true" style="position: absolute; z-index: 99999999;"></v-progress-linear>-->

        <!--<header-bar-->
                <!--:favorites_menu="favorites_menu"-->
                <!--:main_menu="main_menu"-->
                <!--:failedJobs = "failedJobs" />-->

        <!--<v-content>-->
            <!--<v-container fluid align-top fill-height fill-weight text-center class="pa-2">-->
                <!--<router-view></router-view>-->
            <!--</v-container>-->

        <!--</v-content>-->
        <!--<form id="logout-form" action="/logout" method="POST" style="display: none;">-->
            <!--<input type="hidden" name="_token" :value="csrf">-->
        <!--</form>-->
    <!--</v-app>-->
</template>

<script>

    // import HeaderBar from '../components/HeaderBar.vue'
    //
    // import { mapActions } from 'vuex'
    //
    // import Pusher from 'pusher-js';

    // export default {
    //     name: "App",
    //     data () {
    //         return {
    //             failedJobs: 0,
    //             syncClient: null,
    //             pusher: null,
    //             channel: null,
    //             countries: '', // this.$store.countries,
    //             favorites_menu: [],
    //             main_menu: [
    //                 {
    //                     action: 'local_activity',
    //                     title: 'Catalog',
    //                     active: true,
    //                     items: [
    //                         { title: 'Products', tag: '/products', icon: 'question_answer' },
    //                         { title: 'Add product', tag: '/add_product', icon: 'question_answer' },
    //                         { title: 'Products for eBay', tag: '/products_for_ebay', icon: 'question_answer' },
    //                         { title: 'Sets', tag: '/sets', icon: 'question_answer' },
    //                         { title: 'Stock', tag: '/stock', icon: 'question_answer' },
    //                         { title: 'Upload images', tag: '/upload_images', icon: 'question_answer' },
    //                         { title: 'Products attributes', tag: '/products_attributes', icon: 'question_answer' },
    //                     ]
    //                 },
    //                 {
    //                     action: 'local_activity',
    //                     title: 'Sales',
    //                     active: false,
    //                     items: [
    //                         { title: 'Orders', tag: '/orders', icon: 'question_answer' },
    //                         { title: 'Checks', tag: '/checks', icon: 'question_answer' },
    //                         { title: 'Best offers', tag: '/bestoffers', icon: 'question_answer' },
    //                         { title: 'Customers', tag: '/customers', icon: 'question_answer' },
    //                     ]
    //                 },
    //                 {
    //                     action: 'local_activity',
    //                     title: 'Tools',
    //                     active: false,
    //                     items: [
    //                         { title: 'Failed jobs', tag: '/failed_jobs', icon: 'dashboard' },
    //                         { title: 'Blogs', tag: '/blogs', icon: 'dashboard' },
    //                     ]
    //                 },
    //                 {
    //                     action: 'local_activity',
    //                     title: 'eBay',
    //                     active: false,
    //                     items: [
    //                         { title: 'eBay category', tag: '/ebay_category', icon: 'question_answer' },
    //                         { title: 'Ebay Category Specifics', tag: '/ebay_categoryspecifics', icon: 'question_answer' },
    //                     ]
    //                 },
    //                 {
    //                     action: 'local_activity',
    //                     title: 'Reports',
    //                     active: false,
    //                     items: [
    //                         { title: 'Goods Flow', tag: '/goods_flow', icon: 'question_answer' },
    //                         { title: 'eBay Traffic Report', tag: '/ebay_traffic_report', icon: 'question_answer' },
    //                     ]
    //                 },
    //                 {
    //                     action: 'local_activity',
    //                     title: 'Configuration',
    //                     active: false,
    //                     items: [
    //                         { title: 'Currencies', tag: '/currencies', icon: 'question_answer' },
    //                         { title: 'Orders Status', tag: '/prders_status', icon: 'question_answer' },
    //                         { title: 'Defects', tag: '/defects', icon: 'question_answer' },
    //                     ]
    //                 },
    //             ],
    //             user_menu: [
    //             ],
    //             loading: false,
    //             csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    //         }
    //     },
    //     created() {
    //         axios.interceptors.request.use((config) => {
    //             this.loading=true
    //             return config;
    //         }, (error) => {
    //             // trigger 'loading=false' event here
    //             return Promise.reject(error);
    //         });
    //
    //         axios.interceptors.response.use((response) => {
    //             this.loading=false
    //             return response;
    //         }, (error) => {
    //             if(error.response.status === 401){
    //                 window.location.href = '/login'
    //             }
    //         });
    //     },
    //     mounted() {
    //         if (localStorage.getItem('favorites_menu')) this.favorites_menu = JSON.parse(localStorage.getItem('favorites_menu'));
    //         this.initPusher()
    //         this.subscribePusher()
    //         this.initCurrencies()
    //         this.initCountries()
    //         this.initStatuses()
    //
    //     },
    //     methods: {
    //         toggle_favorites: function (subItem) {
    //
    //             const i = _.findIndex(this.favorites_menu, subItem);
    //
    //             if (i > -1) {
    //                 this.favorites_menu.splice(i, 1)
    //             } else {
    //                 this.favorites_menu.push(subItem)
    //             }
    //         },
    //         initPusher: function () {
    //             this.pusher = new Pusher('e35cfe809776adceb457', {
    //                 cluster: 'eu',
    //                 forceTLS: true
    //             });
    //         },
    //         subscribePusher: function () {
    //             this.channel = this.pusher.subscribe('user');
    //             this.channel.bind("App\\Events\\FailedJobEvent", this.processEvent);
    //         },
    //         processEvent: function (data) {
    //             if (!_.isUndefined(data.event.event_id)) {
    //                 switch (data.event.event_id) {
    //                     case "App\\Events\\FailedJobEvent":
    //                         this.failedJobs = data.event.failed_job_count;
    //                         console.log(data);
    //                         break;
    //                 }
    //             }
    //         },
    //         ...mapActions([
    //             'initCurrencies',
    //             'initCountries',
    //             "initStatuses"
    //         ]),
    //
    //     },
    //     components: {
    //         HeaderBar,
    //         Pusher
    //     }
    // }
</script>

<style>


</style>