<template>
    <div style="height: 100%;">
        <v-row class="caption px-2 justify-center" v-if="createNewLabel" style="height: 100%;">
            <v-col cols="12">
                <v-card flat class=" dhl-ship" style="height: 100%;">
                    <v-row class="justify-center" style="height: 100%;">
                        <v-col cols="11" style="height: 100%;">
                            <dhl-shipment-form
                                v-if="order"
                                @refresh="getLabel"
                                :fullscreen="fullscreen"
                            >
                            </dhl-shipment-form>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="caption px-2" v-if="!createNewLabel">
            <v-col cols="12">
                <v-card flat class=" dhl-ship">
                    <v-card-text class="text-left">
                        <v-list
                            subheader
                            two-line
                        >
                            <v-list-item
                                v-for="(shippinglable, index) in shippinglables"
                                :key="index"
                                dense
                            >
                                <v-list-item-avatar>
                                    <v-icon
                                        color="red"
                                        x-large
                                    >
                                        mdi-file-pdf
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="shippinglable.name"></v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date: {{ new Date(shippinglable.metadata.timestamp * 1000).toLocaleDateString("en-US")}}, size: {{ shippinglable.metadata.size}} bytes
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1" @click="loadFile(shippinglable.path)">mdi-download</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-row class="text-center">
                            <v-col>
                            <v-btn @click="deleteLabel('direct')" v-if="shippinglables.length" color="error">Delete shipping label</v-btn>
                            </v-col>
                        </v-row>

                        <v-list
                            subheader
                            two-line
                        >
                            <v-list-item
                                v-for="(shippinglable, index) in returnlables"
                                :key="index"
                                dense
                            >
                                <v-list-item-avatar>
                                    <v-icon
                                        color="red"
                                        x-large
                                    >
                                        mdi-file-pdf
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="shippinglable.name"></v-list-item-title>

                                    <v-list-item-subtitle>
                                        Date: {{ new Date(shippinglable.metadata.timestamp * 1000).toLocaleDateString("en-US")}}, Size: {{ shippinglable.metadata.size}} bytes
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon color="grey lighten-1" @click="loadFile(shippinglable.path)">mdi-download</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-row class="text-center">
                            <v-col>
                                <v-btn @click="deleteLabel('return')" v-if="returnlables.length" color="error">Delete return label</v-btn>
                            </v-col>
                        </v-row>

<!--                        <v-row class="text-center">
                            <v-col>
                                <v-progress-linear
                                    indeterminate
                                    color="green"
                                    v-if="!shippinglables.length && !returnlables.length"
                                ></v-progress-linear>
                                <v-btn @click="returnLabel" v-else-if="!returnlables.length" color="success">Return label</v-btn>
                                <v-btn @click="directLabel" v-else-if="!shippinglables.length" color="success">Shipping label</v-btn>
                            </v-col>
                        </v-row>-->

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import DhlShipmentForm from './DhlShipmentForm'

    export default {
        name: "DhlShipment",
        props:{
            isActive:{},
            fullscreen: {}
        },
        data(){
            return {
                createNewLabel: false,
                tracking: {
                    shipexpresslabel: null,
                    commericalinvoice: null
                },
                shippinglables: [],
                returnlables: []
            }
        },
        computed: {
            // countries(){
            //     return _.map(this.countriesList, 'countries_name')
            // },
            ...mapGetters([
                'order',
                // 'countriesList',
            ]),
        },
        mounted() {
            if(this.order && this.order.order_products[0]){
                //this.init(this.order.order_products[0].ShipmentTrackingNumber)
                this.getLabel()
            }
        },
        methods:{
            init(trackNumber){
                this.tracking = {
                    "TrackingNumber": `${trackNumber}`,
                    "shipexpresslabel":null, // "https://storage.googleapis.com/eu-cameramate-images/fedex/shipexpresslabel_27931.epl", //"https://storage.googleapis.com/eu-cameramate-images/fedex/shipexpresslabel_27931.epl"
                    "commericalinvoice": null, // "https://storage.googleapis.com/eu-cameramate-images/fedex/shipexpresslabel_27931.epl", //"https://storage.googleapis.com/eu-cameramate-images/fedex/shipexpresslabel_27931.epl"
                    "orders_id": "27931"
                }

                if(!this.tracking.shipexpresslabel && !this.tracking.commericalinvoice){
                    this.createNewLabel=true
                }

                this.$emit('componentReady')
            },
            async getLabel(){
                this.createNewLabel = false
                this.shippinglables = []
                await axios.post('/api/orders/dhl/'+this.order.orders_id, {type: 'files'})
                    .then(response => {
                        if(response.status === 200 && response.data && ((response.data.direct && response.data.direct.length) || (response.data.return && response.data.return.length))){
                            this.shippinglables = response.data.direct
                            this.returnlables = response.data.return
                        } else {
                            this.createNewLabel = true
                        }
                    }).finally(() => {
                        this.$emit('componentReady')
                    })
            },
            loadFile(path) {
                let newWindow = window.open("/api/gstorage/get?filename="+path, 'LALALALLALALA')
                newWindow.name = 'lalalalal'
            },
            deleteLabel(what){
                axios.post('/api/orders/dhl/'+this.order.orders_id, {type: 'deletefiles', what: what})
                    .finally(() => {
                        this.getLabel()
                })
            },
            returnLabel() {
                this.order.return = true;
                this.createNewLabel = true;
                this.$emit('componentReady')
            },
            directLabel() {
                this.order.return = false;
                this.createNewLabel = true;
                this.$emit('componentReady')
            }
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-dhl-ship"){
                    if(this.order !== null){
                        this.$emit('componentReady')
                    }
                }
            }
        },
        components:{
            DhlShipmentForm
        }
    }
</script>

<style scoped>
    .dhl-ship {
        height: 100%;
        overflow: hidden;
    }
</style>