<template>
    <v-layout align-space-around class="elevation-1 caption px-2" wrap>
        <v-flex sm7 xs12>
            <v-card>
                <v-tabs
                        v-model="orderAddressTab"
                        background-color="transparent"
                        grow
                >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                            href="#delivery"
                            ripple
                            @click="() => { this.showCopyToBillingButton = true; this.showCopyToDeliveryButton = false}"
                    >
                        Delivery
                    </v-tab>
                    <v-tab
                            href="#shipping"
                            ripple
                            @click="() => { this.showCopyToBillingButton = false; this.showCopyToDeliveryButton = true}"
                    >
                        Billing
                        <v-chip
                                v-if="!isBillingaddressFormValid"
                                class="ma-2"
                                color="red"
                                outlined
                                pill
                                small
                        >
                            Please fill
                            <v-icon class="ml-1" color="red" small>error</v-icon>
                        </v-chip>
                    </v-tab>
                </v-tabs>
                <v-flex class="text-right absolute address-form-buttons" sm6 xs5>
                    <v-btn
                            v-show="false"
                            class="body-1 address-action"
                            color="primary"
                            small
                            @click="addressFormCopyToDelivery"
                    >Copy From Billing
                    </v-btn>
                    <v-btn
                            v-show="showCopyToDeliveryButton"
                            class="body-1 address-action"
                            color="primary"
                            small
                            @click="addressFormCopyToBilling"
                    >Copy From Delivery
                    </v-btn>
                </v-flex>
                <v-tabs-items v-model="orderAddressTab">
                    <v-tab-item
                            :eager="true"
                            class="mt-0 mb-1"
                            value="delivery"
                    >
                        <v-form ref="addressForm" v-model="addressValid" class="mt-2">
                            <v-card class="address" flat style="padding-top:5px;">
                                <v-layout>
                                    <v-flex xs12>
                                        <v-text-field
                                                v-model="form.delivery_name"
                                                :append-icon="(newOrder ? 'mdi-magnify' : '')"
                                                :hide-details=true
                                                :rules="rules.required"
                                                class="body-1"
                                                colorvaluer="blue darken-2"
                                                dense
                                                label="Delivery name"
                                                @change="submitForm"
                                                @click:append="openAddCustomerDialog('delivery_name')"
                                        />
                                        <!--                                        <v-icon big slot="activator" color="green" @click="openDialog"> add_box </v-icon>-->
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs12>
                                        <v-text-field
                                                v-model="form.delivery_street_address"
                                                :hide-details=true
                                                :rules="rules.required"
                                                class="body-1 mt-4"
                                                color="blue darken-2"
                                                dense
                                                label="Delivery street address"
                                                placeholder="enter delivery street address"
                                                required
                                                @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.delivery_state"
                                                      :hide-details=true
                                                      class="body-1 mt-4"
                                                      color="blue darken-2"
                                                      dense
                                                      label="Delivery state"
                                                      placeholder="enter delivery state"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.delivery_city"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="blue darken-2"
                                                      dense
                                                      label="Delivery city"
                                                      placeholder="enter delivery city"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6>
                                        <v-autocomplete v-model="form.delivery_country"
                                                        :hide-details=true
                                                        :items="countries"
                                                        :rules="rules.required"
                                                        class="body-1 mt-4"
                                                        color="pink"
                                                        dense
                                                        label="Delivery country"
                                                        @change="submitForm"
                                        />
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.delivery_postcode"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="blue darken-2"
                                                      dense
                                                      label="Delivery postcode"
                                                      placeholder="enter delivery postcode"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.customers_email_address"
                                                      :append-icon="(newOrder ? 'mdi-magnify' : '')"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="purple darken-2"
                                                      dense
                                                      label="Email address"
                                                      placeholder="enter email address"
                                                      @change="submitForm"
                                                      @click:append="openAddCustomerDialog('customers_email_address')"
                                        />
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field
                                                v-model="form.customers_telephone"
                                                :append-outer-icon="(newOrder || !(order.ebay_site && order.ebay_site.length)) ? '' : 'mdi-update'"
                                                :hide-details=true
                                                :loading="updatingPhoneNumber"
                                                :rules="rules.required"
                                                class="body-1 mt-4"
                                                color="blue darken-2"
                                                dense
                                                label="Telephone"
                                                placeholder="enter telephone"
                                                @change="submitForm"
                                                @click:append-outer="getPhoneNumberFromEbay()"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item
                            :eager="true"
                            class="mt-3"
                            value="shipping"
                    >
                        <v-form ref="billingaddressForm" v-model="billingaddressForm">
                            <v-card class="address" flat style="padding-top:5px;">
                                <v-layout>
                                    <v-flex xs12>
                                        <v-text-field
                                                v-model="form.billing_name"
                                                :append-icon="(newOrder ? 'mdi-magnify' : '')"
                                                :hide-details=true
                                                :rules="rules.required"
                                                class="body-1"
                                                color="purple darken-2"
                                                dense
                                                label="Billing name"
                                                placeholder="enter billing name"
                                                @change="submitForm"
                                                @click:append="openAddCustomerDialog('billing_name')"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs12>
                                        <v-text-field v-model="form.billing_street_address"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="purple darken-2"
                                                      dense
                                                      label="Billing street address"
                                                      placeholder="enter billing street address"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.billing_state"
                                                      :hide-details=true
                                                      class="body-1 mt-4"
                                                      color="purple darken-2"
                                                      dense
                                                      label="Billing state"
                                                      placeholder="enter billing state"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.billing_city"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="purple darken-2"
                                                      dense
                                                      label="Billing city"
                                                      placeholder="enter billing city"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6>
                                        <v-autocomplete v-model="form.billing_country"
                                                        :hide-details=true
                                                        :items="countries"
                                                        :rules="rules.required"
                                                        class="body-1 mt-4"
                                                        color="pink"
                                                        dense
                                                        label="Billing country"
                                                        @change="submitForm"
                                        />
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="form.billing_postcode"
                                                      :hide-details=true
                                                      :rules="rules.required"
                                                      class="body-1 mt-4"
                                                      color="purple darken-2"
                                                      dense
                                                      label="Billing postcode"
                                                      placeholder="enter billing postcode"
                                                      @change="submitForm"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>

            </v-card>
        </v-flex>
        <v-flex class="hidden-sm-and-up" xs12>
            <v-divider/>
        </v-flex>
        <v-flex sm5 xs12>
            <v-form ref="additionalParams" class="pt-2">
                <v-list dense flat>
                    <v-list-item-group>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs6>
                                    <v-select v-model="form.orders_status"
                                              :items="statuses"
                                              :rules="rules.required"
                                              class="font-weight-regular full-width"
                                              dense
                                              item-text="orders_status_name"
                                              item-value="orders_status_id"
                                              label="Status"
                                              prepend-icon="timeline"
                                              @change="submitForm"
                                    />
                                </v-flex>
                                <v-flex xs6>
                                    <v-select v-model="form.currency"
                                              :disabled="(form.orders_status !== OrderStatusEnum.PENDING && !newOrder)"
                                              :items="currencies"
                                              :rules="rules.required"
                                              class="caption font-weight-regular full-width"
                                              dense
                                              label="Currency"
                                              prepend-icon="euro_symbol"
                                              @change="submitForm"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs6>
                                    <v-select
                                            v-model="form.payment_method"
                                            :items="paymentMethods"
                                            :rules="rules.required"
                                            class="caption font-weight-regular full-width"
                                            dense
                                            label="Payment method"
                                            prepend-icon="account_balance_wallet"
                                            @change="submitForm"
                                    />
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field
                                            v-if="!newOrder"
                                            v-model="form.PayPalTransactionID"
                                            :disabled="form.payment_method !== 'PayPal'"
                                            class="caption font-weight-regular"
                                            dense
                                            label="PayPal Id"
                                            prepend-icon="payment"
                                            @change="submitForm"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs6>
                                    <v-select
                                            v-model="orderAccount"
                                            :items="activeAuctionAccounts"
                                            class="caption font-weight-regular full-width"
                                            dense
                                            label="Auction account"
                                            prepend-icon="account_box"
                                            @change="submitForm"
                                    />
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field
                                            v-if="!newOrder"
                                            v-model="orderSource"
                                            class="caption font-weight-regular"
                                            dense
                                            disabled
                                            label="Source"
                                            prepend-icon="payment"
                                            readonly
                                    />
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs6>
                                    <v-select
                                        v-if="!newOrder"
                                        v-model="orderEbaySiteId"
                                        :items="ebaySites"
                                        class="caption font-weight-regular full-width"
                                        dense
                                        label="eBay Site"
                                        prepend-icon="account_box"
                                        @change="submitForm"
                                    />
                                </v-flex>
                                <v-flex xs6>
                                  <v-text-field
                                      v-if="!newOrder"
                                      v-model="form.temp_ebay_OrderID"
                                      class="caption font-weight-regular"
                                      dense
                                      label="EbayID"
                                      prepend-icon="account_box"
                                      @change="submitForm"
                                  />
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs6>
                                    <ordered-datepicker
                                            v-model="form.date_purchased"
                                            :labelName="'Ordered'"
                                            :rules="rules.required"
                                            class="caption font-weight-regular"
                                            @input="submitForm"/>
                                </v-flex>
                                <v-flex xs6>
                                    <ordered-datepicker
                                            v-model="form.date_paid"
                                            :labelName="'Paid'"
                                            :rules="(form.orders_status === OrderStatusEnum.PAID ? rules.required : [])"
                                            class="caption font-weight-regular"
                                            @input="submitForm"/>
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                        <v-list-item>
                            <v-layout>
                                <v-flex xs4>
                                    <ordered-datepicker
                                            v-model="form.date_shipped"
                                            :labelName="'Shipped'"
                                            :isCleared="false"
                                            :rules="(form.orders_status === OrderStatusEnum.DELIVERED ? rules.required : [])"
                                            class="caption font-weight-regular"
                                            @input="submitForm"
                                    />
                                </v-flex>

                                <v-flex xs4>
                                    <ordered-datepicker
                                            v-if="form.duty_date && !newOrder"
                                            v-model="form.duty_date.duty_date"
                                            :labelName="'Cleared'"
                                            :isCleared="true"
                                            class="caption font-weight-regular"
                                            @input="submitForm"
                                    />
                                </v-flex>

                                <v-flex xs4>
                                    <ordered-datepicker
                                            v-model="form.date_refund"
                                            :labelName="'Refunded'"
                                            :rules="(form.orders_status === OrderStatusEnum.REFUNDED ? rules.required : [])"
                                            class="caption font-weight-regular"
                                            @input="submitForm"/>
                                </v-flex>
                            </v-layout>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-form>
        </v-flex>
        <v-flex v-if="newOrder" xs12>
            <v-row>
                <v-col>
                    <v-btn
                            class="body-1 address-action mb-3"
                            small
                            @click="clearForm"
                    >Clear
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                    <v-tooltip
                            left
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    class="body-1 address-action mb-3"
                                    color="success"
                                    small
                                    v-bind="attrs"
                                    @click="saveNewOrder"
                                    v-on="on"
                            >Create new order
                            </v-btn>
                        </template>
                        <span>You will be able to add products after creating an order.</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-flex>
        <add-customer
                v-model="searchCustomerDialog"
                :searchBy="searchByCustomerDialog"
                @addCustomer=saveUserToOrder
                @closeDialog="searchCustomerDialog = false"
        >
            Search Customer
        </add-customer>
        <v-dialog v-model="isAllert" :fullscreen="false" :scrollable="false" width="600px">
            <v-card>
                <v-card-title class="ma-0 pa-0" color="red">
                    <v-toolbar color="red darken-3" dark dense>
                        <v-toolbar-title>Alert!!!</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="py-2 mx-auto font-weight-bold font-large-3" justify="center">
                    Please, fill all <span class="px-2" style="color: red;">"red"</span> fields!
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="isAllert = !isAllert">Cancel</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <confirm ref="confirm"/>
    </v-layout>
</template>

<script>
import {mapGetters} from "vuex";
import OrderedDatepicker from "../pages/order/OrderedDatepicker";
import AddCustomer from "./AddCustomer";

import Confirm from '@/components/Confirm'

import {
    OrderStatusEnum
} from "./../enums";

import ordersApi from '@/services/orders.api';

export default {
    name: "OrderAddressForm",
    props: {
        order: {
            type: Object,
            required: false,
            default: null,
        },
        newOrder: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: () => ({
        isAllert: false,
        rules: {
            max25chars: v => v.length <= 30 || 'Input too long!',
            required: [
                v => !!v || 'Field is required'
            ],
            select: value => {
                console.log('select', value)
                return []
                value.length > 0 || "Item is required in select"
            },
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
        },
        showCopyToBillingButton: true,
        showCopyToDeliveryButton: false,
        addressValid: false,
        billingaddressForm: false,
        orders: [],
        form: {
            customers_id: null,
            date_shipped: null,
            date_paid: null,
            ebay_siteID: null,
            date_purchased: null,
            PayPalTransactionID: null,
            payment_method: null,
            orders_status: null,
            customers_email_address: null,

            customers_city: null,
            customers_company: null,
            customers_country: null,
            customers_name: null,
            customers_postcode: null,
            customers_state: null,
            customers_street_address: null,

            delivery_name: null,
            delivery_street_address: null,
            delivery_state: null,
            delivery_city: null,
            delivery_postcode: null,
            delivery_country: null,

            billing_name: null,
            billing_street_address: null,
            billing_state: null,
            billing_city: null,
            billing_postcode: null,
            billing_country: null,
            customers_telephone: null,
            currency: null,
            duty_date: {
                "id": null,
                "duty_date": null
            },
            date_refund: null,
            temp_ebay_OrderID: null,
            OrderID: null,
            site: 'CM'
        },
        formInit: {
            customers_id: null,
            date_shipped: null,
            date_paid: null,
            ebay_siteID: null,
            date_purchased: null,
            PayPalTransactionID: null,
            payment_method: null,
            orders_status: null,
            customers_email_address: null,

            customers_city: null,
            customers_company: null,
            customers_country: null,
            customers_name: null,
            customers_postcode: null,
            customers_state: null,
            customers_street_address: null,

            delivery_name: null,
            delivery_street_address: null,
            delivery_state: null,
            delivery_city: null,
            delivery_postcode: null,
            delivery_country: null,

            billing_name: null,
            billing_street_address: null,
            billing_state: null,
            billing_city: null,
            billing_postcode: null,
            billing_country: null,
            customers_telephone: null,
            currency: null,
            duty_date: {
                "id": null,
                "duty_date": null
            },
            date_refund: null,
            temp_ebay_OrderID: null,
            OrderID: null,
            site: 'CM'
        },
        searchCustomerDialog: false,
        searchByCustomerDialog: null,
        site: null,
        isBillingaddressFormValid: true,
        updatingPhoneNumber: false,
        orderAddressTab: null
    }),
    computed: {
        statuses() {
            if (this.orders_statusesList) {
                return Object.values(this.orders_statusesList)
            }
        },
        activeAuctionAccounts() {
            console.log('auction_accounts', this.auction_accounts)
            return _.filter(this.auction_accounts, {active: true})
        },
        currencies() {
            return this.currenciesList
        },
        ebaySites() {
          let eBaySites = _.toArray(this.ebay_global_sites)
          let sites = []
          let i = 0
          while (i < eBaySites.length) {
            sites.push({text: eBaySites[i].country, value: eBaySites[i].value})
            i++;
          }
          console.log('ebaySites', sites)
          return sites
        },
        orderSource() {
            if (this.order && this.order.ebay_site && this.order.ebay_site.length) {
                return this.order.ebay_site[0].url
            } else if (this.order && this.order.site && this.order.site !== '') {
                return this.order.site
            } else return '';
        },
        orderAccount: {
            get: function () {
                if (this.order && this.order.site) {
                    /*console.log('start get orderAccount')
                    let tmp = this.order.site.substring( this.order.site.indexOf("-") + 1)
                    console.log('get orderAccount', tmp)*/
                    return this.order.site
                } else return ''
            },
            set: function (newValue) {
                if (this.order && this.order.site) {
                    //this.order.site = this.order.site.substring(0, this.order.site.indexOf("-"))+'-'+newValue
                    this.order.site = newValue
                }
            }
        },
        orderEbaySiteId: {
            get: function () {
                if (this.order) {
                    return this.order.ebay_siteID
                } else return ''
            },
            set: function (newValue) {
                if (this.order) {
                    this.order.ebay_siteID = newValue
                }
            }
        },

        countries() {
            return _.map(this.countriesList, 'countries_name')
        },
        OrderStatusEnum() {
            return OrderStatusEnum
        },
        ...mapGetters([
            'currenciesList',
            'countriesList',
            'orders_statusesList',
            'paymentMethods',
            'auction_accounts',
            'ebay_global_sites',
            'ebayTokensList'
        ]),
    },
    watch: {},
    mounted() {
        if (this.order) {
            this.form = this.order;
            this.orders.push(this.order);

            if (this.$refs.addressForm) this.$refs.addressForm.validate();
            if (this.$refs.billingaddressForm) this.$refs.billingaddressForm.validate();
            if (this.$refs.additionalParams) this.$refs.additionalParams.validate();
        }
    },
    methods: {
        checkForm() {
            let isValid = false;

            isValid = this.$refs.addressForm.validate()
            isValid = this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate()
            isValid = this.$refs.additionalParams.validate()

            for (const [key, value] of Object.entries(this.form)) {
                switch (key) {
                    case 'orders_status':
                        if (value === OrderStatusEnum.PENDING) { //Pending
                            if (!this.form.date_purchased) isValid = false
                        }
                        if (value === OrderStatusEnum.DELIVERED) { //Delivered
                            if (!this.form.date_shipped) isValid = false
                        }
                        if (value === OrderStatusEnum.PAID) { //Paid
                            if (!this.form.date_paid) isValid = false
                        }
                        if (value === OrderStatusEnum.REFUNDED) { //Refunded
                            if (!this.form.date_refund) isValid = false
                        }
                        break;
                }
            }
            return isValid;
        },
        submitForm() {
            console.log('submitForm')

            if (!this.newOrder) {
                if (this.checkForm()) {
                    this.form.customers_name = this.form.delivery_name;
                    this.form.customers_street_address = this.form.delivery_street_address;
                    this.form.customers_state = this.form.delivery_state;
                    this.form.customers_city = this.form.delivery_city;
                    this.form.customers_postcode = this.form.delivery_postcode;
                    this.form.customers_country = this.form.delivery_country;
                    if (this.form.temp_ebay_OrderID) {
                      this.form.OrderID = this.form.temp_ebay_OrderID;
                    }
                    this.$emit('submitForm', this.form)
                } else {
                    this.isAllert = true;
                }
            }
        },
        clearForm() {
            this.form = _.cloneDeep(this.formInit);
            this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate();
        },
        saveNewOrder() {
            this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate()
            let addressForm = this.$refs.addressForm.validate() && this.$refs.billingaddressForm.validate()
            let additionalParams = this.$refs.additionalParams.validate()
            if ((addressForm && additionalParams) && (additionalParams && addressForm)) {
                this.$emit('saveNewOrder', this.form)
                this.form = _.cloneDeep(this.formInit);
            }
        },
        addressFormCopyToDelivery() {
            this.form.delivery_name = this.form.billing_name;
            this.form.delivery_street_address = this.form.billing_street_address;
            this.form.delivery_state = this.form.billing_state;
            this.form.delivery_city = this.form.billing_city;
            this.form.delivery_postcode = this.form.billing_postcode;
            this.form.delivery_country = this.form.billing_country;

            this.form.customers_name = this.form.billing_name;
            this.form.customers_street_address = this.form.billing_street_address;
            this.form.customers_state = this.form.billing_state;
            this.form.customers_city = this.form.billing_city;
            this.form.customers_postcode = this.form.billing_postcode;
            this.form.customers_country = this.form.billing_country;

            //this.$refs.addressForm.validate();
            //this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate();
            if (!this.newOrder) {
                if (this.checkForm()) {
                    this.submitForm()
                }
            }
        },
        addressFormCopyToBilling() {
            this.form.billing_name = this.form.delivery_name;
            this.form.billing_street_address = this.form.delivery_street_address;
            this.form.billing_state = this.form.delivery_state;
            this.form.billing_city = this.form.delivery_city;
            this.form.billing_postcode = this.form.delivery_postcode;
            this.form.billing_country = this.form.delivery_country;

            this.form.customers_name = this.form.delivery_name;
            this.form.customers_street_address = this.form.delivery_street_address;
            this.form.customers_state = this.form.delivery_state;
            this.form.customers_city = this.form.delivery_city;
            this.form.customers_postcode = this.form.delivery_postcode;
            this.form.customers_country = this.form.delivery_country;

            this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate()
            //this.$refs.addressForm.validate();
            //this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate();
            if (!this.newOrder) {
                if (this.checkForm()) {
                    this.submitForm()
                }
            }
        },
        openAddCustomerDialog(searchBy) {
            this.searchByCustomerDialog = searchBy;
            this.searchCustomerDialog = true;
        },
        saveUserToOrder(order) {
            this.form.customers_name = order.delivery_name;
            this.form.customers_street_address = order.delivery_street_address;
            this.form.customers_state = order.delivery_state;
            this.form.customers_city = order.delivery_city;
            this.form.customers_postcode = order.delivery_postcode;
            this.form.customers_country = order.delivery_country;

            this.form.delivery_name = order.delivery_name;
            this.form.delivery_street_address = order.delivery_street_address;
            this.form.delivery_state = order.delivery_state;
            this.form.delivery_city = order.delivery_city;
            this.form.delivery_postcode = order.delivery_postcode;
            this.form.delivery_country = order.delivery_country;

            this.form.billing_name = order.billing_name;
            this.form.billing_street_address = order.billing_street_address;
            this.form.billing_state = order.billing_state;
            this.form.billing_city = order.billing_city;
            this.form.billing_postcode = order.billing_postcode;
            this.form.billing_country = order.billing_country;

            this.form.customers_email_address = order.customers_email_address;
            this.form.customers_telephone = order.customers_telephone;
            this.form.customers_id = order.customers_id

            //this.$refs.addressForm.validate();
            //this.$refs.additionalParams.validate();
            //this.isBillingaddressFormValid = this.$refs.billingaddressForm.validate();
            this.checkForm()
        },
        getPhoneNumberFromEbay() {
            this.updatingPhoneNumber = true;
            axios.get(`/api/ebay/order/?orderId=${this.order.OrderID}`)
                .then(response => {
                    if (response.data && response.data.data.ShippingAddress && response.data.data.ShippingAddress.Phone && response.data.data.ShippingAddress.Phone != '') {
                        this.form.customers_telephone = response.data.data.ShippingAddress.Phone;
                        this.submitForm()
                    }
                }).catch((error) => {
                console.log(error);
                console.warn('Request was not sent properly. Order is not present.');
            }).finally(() => {
                this.updatingPhoneNumber = false
            })
        }
    },
    components: {
        OrderedDatepicker,
        AddCustomer,
        Confirm
    }

}
</script>

<style scoped>

.address .layout .flex {
    padding: 5px;
}

.autocomplete {
    position: relative;
}

@media all and (max-width: 960px) {
    .address-action {
        min-width: 0;
        margin: 0;
    }
}

</style>