<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://oldadmin.cameramate.com/tools/stock.php?disableMenu=1"></iframe>
</template>

<script>
    export default {
        name: "Stock"
    }
</script>

<style scoped>

</style>