var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ma-2 caption" },
    [
      _c(
        "v-col",
        { staticClass: "d-flex align-left pa-0", attrs: { cols: "12" } },
        [
          _c(
            "p",
            {
              staticClass: "mr-1 mb-0 align-left",
              staticStyle: { "text-align": "left !important" }
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.item.delivery_name.slice(0, 25) +
                      (_vm.item.delivery_name.length > 25 ? "..." : "")
                  )
                )
              ]),
              _vm._v(
                " - " +
                  _vm._s(
                    _vm.item.customers_email_address.slice(0, 25) +
                      (_vm.item.customers_email_address.length > 25
                        ? "..."
                        : "")
                  )
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass: "d-flex aling-left mb-1 pa-0 text-left",
          attrs: { cols: "12" }
        },
        [
          _vm.item.PayPalTransactionID
            ? _c("p", { staticClass: "mr-2 caption align-left" }, [
                _c("span", { staticClass: "font-weight-medium" }, [
                  _vm._v("PayPalId:")
                ]),
                _vm._v(
                  " " + _vm._s(_vm.item.PayPalTransactionID) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.order_products[0] &&
          _vm.item.order_products[0].ShipmentTrackingNumber
            ? _c("p", { staticClass: "mr-1 mb-0 align-left" }, [
                _c("span", { staticClass: "font-weight-medium" }, [
                  _vm._v("TrackingNumber: ")
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.item.order_products[0].ShipmentTrackingNumber) +
                    "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.OrderID && _vm.item.OrderID !== ""
            ? _c("span", { staticClass: "mr-1 mb-0 align-left" }, [
                _c("span", { staticClass: "font-weight-medium" }, [
                  _vm._v("eBayID: ")
                ]),
                _vm._v(
                  "\n            " + _vm._s(_vm.item.OrderID) + "\n            "
                ),
                !_vm.hideEbayFee
                  ? _c("span", [
                      _vm.item.order_ebayfees
                        ? _c("span", { staticClass: "font-italic" }, [
                            _vm._v("\n                    Fee: "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.item.order_ebayfees.text)
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.order_ebaytotal
                        ? _c("span", { staticClass: "font-italic" }, [
                            _vm._v("\n                    Total: "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.item.order_ebaytotal.text)
                              }
                            })
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.item.order_products.length
        ? _c(
            "v-col",
            { staticClass: "mt-1 pa-0", attrs: { cols: "12" } },
            _vm._l(_vm.item.order_products, function(product) {
              return _c(
                "v-row",
                {
                  key: product.orders_products_id,
                  staticStyle: { color: "#666666" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left py-0 px-2",
                      attrs: { cols: "12" }
                    },
                    [
                      _vm._v("\n                ("),
                      _c(
                        "a",
                        {
                          staticClass: "primary--text",
                          staticStyle: {
                            cursor: "pointer",
                            color: "black !important",
                            "text-decoration": "underline"
                          },
                          on: {
                            click: function($event) {
                              return _vm.openProductDialog(product)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                " + _vm._s(product.products_id)
                          )
                        ]
                      ),
                      _vm._v(")\n                "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "eclipse text-left ma-0 font-italic font-weight-medium",
                          staticStyle: { color: "black !important" }
                        },
                        [_vm._v(_vm._s(product.products_name))]
                      ),
                      _vm._v(" "),
                      !_vm.hideStockData
                        ? _c("warehouse-info", {
                            attrs: { product: product.product }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }