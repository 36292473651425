var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column text-right justify-center pa-2" },
    [
      _c("p", { staticClass: "pa-0 ma-0" }, [
        _vm._v(_vm._s(_vm.orderSubTotal) + " " + _vm._s(this.item.currency))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "pa-0 ma-0" }, [
        _vm._v(_vm._s(_vm.orderShipping) + " " + _vm._s(this.item.currency))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "pa-0 ma-0 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.ordertTotal) + " " + _vm._s(this.item.currency))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }