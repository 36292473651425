<template>
    <v-card>
<!--        <v-card-title class="ma-0 pa-0">
            <v-toolbar dark dense color="blue darken-3">
                <v-toolbar-title>Cancel - {{ order.orders_id}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click.stop="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </v-card-title>-->
        <v-card-text class="pt-4">
            <v-data-table
                v-if="currentOrder && currentOrder.orders_status_name.orders_status_name === 'Pending'"
                :headers="headers"
                :items="currentOrder.order_products"
                item-key="products_id"
                :loading="loading"
                hide-default-header
                hide-default-footer
                class= "elevation-1 width-100 refund-product-table"
            >
                <template slot="item" slot-scope="props">

                    <tr>
                        <td colspan="4">
                            <div class="d-flex align-center">
                                <p class="mr-1 ml-2 mb-0">({{props.item.products_id}})</p>
                                <p class="eclipse mb-0 mr-1 font-weight-bold">{{ props.item.products_name}}</p>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td class="pl-2">
                            <v-checkbox v-model="props.item.restock" hide-details dense class="ma-0">
                                <template #prepend>
                                    <v-label>Restock:</v-label>
                                </template>
                            </v-checkbox>
                        </td>
                        <td>
                            <tree-select class="pr-2"
                                         :load-options="loadOptions"
                                         :options="productsOptions"
                                         :auto-load-root-options="true"
                                         :searchable="true"
                                         :normalizer="normalizer"
                                         placeholder="Select category"
                                         v-model="props.item.product.targetcatid"
                                         appendToBody
                            />
                        </td>
                    </tr>

                </template>

                <template v-if="loading" v-slot:body.prepend>
                    <tr>
                        <td :colspan="headers.length+1" class="pa-0" style="height: 2px;">
                            <v-progress-linear color="blue" :height="2" indeterminate/>
                        </td>
                    </tr>
                </template>

            </v-data-table>
            <v-progress-linear
                v-else-if="currentOrder == null"
                color="green darken-4"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
            <p v-else>The order was canceled.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer/>
            <v-btn @click="cancelOrder" color="red" :disabled="!(currentOrder && currentOrder.orders_status_name.orders_status_name === 'Pending')">Cancel order</v-btn>
            <!--<v-btn @click="close">Close</v-btn>-->
            <v-spacer/>
        </v-card-actions>
    </v-card>
    
</template>

<script>
    import TreeSelect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
    import { mapActions, mapGetters } from "vuex";
    import EventBus from "@/event-bus";

    export default {
        name: "CancelOrder",
        props: {
            orderId: {
                required: true
            }
        },
        data: () => ({
            productsOptions: null,
            normalizer(node) {
                if (node && node.descriptions) {
                    return {
                        id: node.descriptions.categories_id,
                        label: node.descriptions.categories_name,
                        children: node.children,
                    }
                }
            },
            headers: [
                {
                    text: 'Product',
                    value: 'product',
                    align: 'center',
                    sortable: true,
                    width: '300px'
                },
                {
                    text: 'Category',
                    value: 'category',
                    align: 'center',
                    width: '100%'
                },

            ],
            loading: false,
            currentOrder: null,
            products: []
        }),
        computed: {
            ...mapGetters([
                'productCategories',
                'order'
            ]),

        },
        mounted(){
            this.loadOrder()
        },
        methods: {
            async loadOrder(){
                console.log('loadOrder')
                try {
                    await this.$store.dispatch('fetchOrder', this.orderId)
                } catch (ex) {
                    console.log('loadOrder - error')
                } finally {
                    this.order.order_products.map(p => {
                        p.restock = true;
                        return p
                    });
                    this.currentOrder = this.order;
                }
            },
            async cancelOrder(){
                this.loading = true;

                const canceledProducts =  this.currentOrder.order_products.map(p => {
                    return {
                        orders_id: p.orders_id,
                        orders_products_id: p.orders_products_id,
                        products_id: p.products_id,
                        products_quantity: p.products_quantity,
                        restock: p.restock,
                        targetcatid: p.product.targetcatid
                    }
                });

                try {
                    await this.cancelOrderProducts(canceledProducts);
                } catch (ex) {
                    console.log('loadOrder - error')
                } finally {
                    this.currentOrder = this.order;
                }

                this.loading = false;
                //this.$emit('orderUpdated');
                //this.close();

            },
            ...mapActions([
                "cancelOrderProducts",
            ]),
            async loadOptions({action}){
                if (action === LOAD_ROOT_OPTIONS) {
                    this.productsOptions = this.productCategories;
                }

            },
        },
        components:{
            TreeSelect
        }
    }
</script>

<style>

    .refund-product-table td{
        padding: 8px 12px !important;

    }

</style>