<template>
    <!-- <vue-pull-refresh :on-refresh="initialize" :config="pullConfig" > -->
    <v-layout align-space-around justify-start column fill-height >
        <v-flex xs12 style="max-height: 48px; height: 48px;">
            <v-toolbar dense flat light color="grey lighten-5">
                <v-spacer></v-spacer>
                <v-btn
                        id="RefreshButton"
                        :loading="loading"
                        :disabled="loading"
                        @click="initialize"
                        class="hidden-sm-and-down"
                >Refresh</v-btn>
            </v-toolbar>
        </v-flex>
        <v-flex xs12>
            <v-layout wrap class="bestoffers_wrap">
                <best-offer-item
                        v-for="(bestoffer, index) in bestoffers"
                        :key="index"
                        :bestoffer="bestoffer"
                        :bestoffers="bestoffers"
                        :index="index"
                        @readyToUpdate="openDialog"
                        @updaterange="updaterange"></best-offer-item>
            </v-layout>
        </v-flex>

        <v-dialog v-model="dialog" max-width="420">
            <v-card v-if="respondedOffer" class="pa-3">
                <v-card-title class="headline">Respond to offer</v-card-title>
                <v-card-text class="font-weight-bold">Check offer detail before sending:</v-card-text>
                <v-layout wrap>
                    <v-flex sm3 class="text-right">Product:</v-flex>
                    <v-flex sm9 class="pl-1 font-weight-bold red--text text--lighten-1">
                        <span v-text="respondedOffer.product.descriptions.products_name"></span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex sm3 class="text-right">Offer price:</v-flex>
                    <v-flex sm9 class=" pl-1 font-weight-bold red--text text--lighten-1">
                        <span v-text="respondedOffer.Price_currency"></span> <span v-text="respondedOffer.Price_value"></span>
                    </v-flex>
                </v-layout>

                <v-layout wrap v-if="sendCounter">
                    <v-flex sm3 class="text-right">Counter Offer:</v-flex>
                    <v-flex sm9 class=" pl-1 font-weight-bold red--text text--lighten-1">
                        <span v-text="respondedOffer.Price_currency"></span> <span v-text="sendCounter"></span>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-if="respondedOffer.sellerResponse">
                    <v-flex sm3 class="text-right">Response:</v-flex>
                    <v-flex sm9 class=" pl-1 text--lighten-1">
                        <span v-text="respondedOffer.sellerResponse"></span>
                    </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-flex sm3 class="text-right">Markup:</v-flex>
                    <v-flex sm9 class="font-weight-bold red--text text--lighten-1">
                        <span v-text="markup"></span>
                    </v-flex>

                </v-layout>

                <v-layout class="danger" v-if="errors.dialogMessage" v-html="errors.dialogMessage"></v-layout>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="closeDialog">Cancel</v-btn>
                    <v-btn color="green darken-1" text @click.native="respondToOffer(respondedOffer)">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
    <!-- </vue-pull-refresh> -->

</template>

<script>

    import BestOfferItem from '../../components/BestOfferItem'

    export default {
        name: "BestOffers",
        data() {
            return {
                pullConfig: {
                    errorLabel: "Something is broken :(",
                    startLabel: "Updated",
                    readyLabel: "Pull down to update",
                    loadingLabel: 'Loading...',
                    pullDownHeight: 40
                },
                dialog: false,
                respondedOffer: null,
                currentIndex: null,
                sendCounter: null,

                search: '',
                markup: null,

                bestoffers: [],
                sets: [],
                loading: true,
                errors: {
                    dialogMessage: null
                }
            }
        },
        mounted() {
            this.initialize();
        },
        methods: {
            initialize () {
                this.loading = true;

                let filterByID = '';
                if (this.$route.params.id !== 'undefined') {
                    filterByID = 'filterGroups[3][field]=BestOfferID&' +
                        '   filterGroups[3][filters][0][value]='+this.$route.params.id+'&' +
                        '   filterGroups[3][filters][0][condition]=EQUAL&' +
                        '   filterGroups[3][filters][0][type]=stringfilter&' +
                        '   filterGroups[3][filters][0][operator]=and&' +
                        '   filterGroups[3][operator]=and&';
                }

                //for local testing

                // axios.get('/api/bestoffers?' +
                //     'filterGroups[0][field]=Status&' +
                //     '   filterGroups[0][filters][0][value]=Pending&' +
                //     '   filterGroups[0][filters][0][condition]=EQUAL&' +
                //     '   filterGroups[0][filters][0][type]=stringfilter&' +
                //     '   filterGroups[0][filters][0][operator]=and&' +
                //     '   filterGroups[0][operator]=and&' +
                //     'filterGroups[1][field]=BestOfferCodeType&' +
                //     '   filterGroups[1][filters][0][value]=BuyerBestOffer&' +
                //     '   filterGroups[1][filters][0][condition]=EQUAL&' +
                //     '   filterGroups[1][filters][0][type]=stringfilter&' +
                //     '   filterGroups[1][filters][0][operator]=or&' +
                //     '   filterGroups[1][filters][1][value]=BuyerCounterOffer&' +
                //     '   filterGroups[1][filters][1][condition]=EQUAL&' +
                //     '   filterGroups[1][filters][1][type]=stringfilter&' +
                //     '   filterGroups[1][filters][1][operator]=or&' +
                //     '   filterGroups[1][operator]=and&' +
                //     'filterGroups[2][field]=ExpirationTime&' +
                //     '   filterGroups[2][filters][0][value]=2019-09-23 00:22:57&' +
                //     '   filterGroups[2][filters][0][condition]=GREATER_THAN&' +
                //     '   filterGroups[2][filters][0][type]=datefilter&' +
                //     '   filterGroups[2][filters][0][operator]=and&' +
                //     '   filterGroups[2][operator]=and&' +
                //     '   pagesize=1000&' +
                //     'with[]=product.purchase_price.products_set&with[]=product.descriptions&with[]=product.ebay_categories&without[]=product.images')

                axios.get('/api/bestoffers?' +
                    'filterGroups[0][field]=Status&' +
                    '   filterGroups[0][filters][0][value]=Pending&' +
                    '   filterGroups[0][filters][0][condition]=EQUAL&' +
                    '   filterGroups[0][filters][0][type]=stringfilter&' +
                    '   filterGroups[0][filters][0][operator]=and&' +
                    '   filterGroups[0][operator]=and&' +
                    'filterGroups[1][field]=BestOfferCodeType&' +
                    '   filterGroups[1][filters][0][value]=BuyerBestOffer&' +
                    '   filterGroups[1][filters][0][condition]=EQUAL&' +
                    '   filterGroups[1][filters][0][type]=stringfilter&' +
                    '   filterGroups[1][filters][0][operator]=or&' +
                    '   filterGroups[1][filters][1][value]=BuyerCounterOffer&' +
                    '   filterGroups[1][filters][1][condition]=EQUAL&' +
                    '   filterGroups[1][filters][1][type]=stringfilter&' +
                    '   filterGroups[1][filters][1][operator]=or&' +
                    '   filterGroups[1][operator]=and&' +
                    'filterGroups[2][field]=ExpirationTime&' +
                    '   filterGroups[2][filters][0][value]=now&' +
                    '   filterGroups[2][filters][0][condition]=GREATER_THAN&' +
                    '   filterGroups[2][filters][0][type]=datefilter&' +
                    '   filterGroups[2][filters][0][operator]=and&' +
                    '   filterGroups[2][operator]=and&' +
                    '   pagesize=1000&' +
                    '   sortdatafield=products_id&' +
                    filterByID +
                    'with[]=product.purchase_price.products_set&' +
                    'with[]=product.descriptions&' +
                    'with[]=product.ebay_categories&' +
                    'without[]=product.images')
                    .then(response => {
                            this.bestoffers = response.data.data
                            this.loading = false
                            this.loadSet(response.data)

                    });
            },
            loadSet: function (sets) {
                sets.data.forEach((element) => {
                    if (element.product.purchase_price.products_set && element.product.purchase_price.products_set !== null) {
                        this.loading = true;
                        axios.get('/api/sets/'+element.product.purchase_price.products_set.set_id)
                            .then(response => (
                                element.product.purchase_price.products_set = response.data,
                                    this.loading = false
                            ));
                    }
                });

            },
            openDialog(offer,index, markup, respondType, formData=null){
                this.markup = markup
                this.currentIndex = index
                this.respondedOffer = {...offer};
                this.respondedOffer.Status = respondType;

                if(formData){
                    this.sendCounter = formData.counterOffer
                    this.respondedOffer.sellerResponse = formData.sellerResponse ? formData.sellerResponse : '';
                }

                this.dialog = true;
            },
            closeDialog(){
                this.dialog = false;
                this.errors.dialogMessage = null;
                this.sendCounter = false
            },

            respondToOffer(){
                this.respondedOffer.Price_value = this.sendCounter;
                axios.put(`/api/bestoffers/${this.respondedOffer.BestOfferID}`, this.respondedOffer)
                    .then(response => {
                        console.log(response.data);
                        if (response.data.BestOffer[0].CallStatus === "Success") {
                            this.bestoffers.splice(this.currentIndex, 1);
                            this.respondedOffer = null;
                            this.currentIndex = null;
                            this.dialog = false;
                        }else{
                            this.errors.dialogMessage = 'Responce returned with status -&nbsp;<span class="red--text">'+response.data.BestOffer[0].CallStatus+'</span>'

                        }
                    }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. something happens.');
                });
            },
            updaterange(new_offer_range, productId, index){
                axios.put(`/api/products/${productId}`, { 'offer_range' : new_offer_range } )
                    .then(response => {
                         this.bestoffers[index].product.offer_range = new_offer_range;
                    }).catch((error) => {
                        console.log(error);
                        console.warn('Auto respond values were not updated. something happens.');
                })
            }
        },
        computed:{

        },
        components: {
            BestOfferItem
        }

    }
</script>

<style scoped>

    .danger{
        border: 1px solid red;
        padding: 8px;
    }

</style>