var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "width-100" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 width-100 orders-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.orders_,
                  "item-key": "order_id",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  options: _vm.options,
                  "server-items-length": _vm.pagination.totalItems,
                  loading: _vm.loading,
                  "fixed-header": true,
                  "hide-default-header": _vm.ordersPageConfig.hideDefaultHeader,
                  "sort-desc": "",
                  "footer-props": {
                    itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
                    showFirstLastPage: true
                  }
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "pa-0 ma-0",
                              attrs: { dense: "", flat: "", color: "white" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pl-1",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    color: "pink",
                                    disabled: _vm.addNewOrderDialog
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.addNewOrderDialog = true
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-tab-plus")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "ml-2 mr-0",
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    icon: "",
                                    color: "green"
                                  },
                                  on: { click: _vm.getDataFromApi }
                                },
                                [_c("v-icon", [_vm._v("mdi-cached")])],
                                1
                              ),
                              _vm._v(" "),
                              _vm.tags.length
                                ? _c("v-divider", {
                                    staticClass: "mr-1",
                                    attrs: { vertical: "" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticStyle: { "max-width": "95%" },
                                  attrs: { justify: "space-around" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("order-filters-bar", {
                                        attrs: {
                                          drawer: _vm.filterDrawer,
                                          filtersLength: _vm.filtersLength
                                        },
                                        on: {
                                          closeMenu: _vm.closeFiltersMenu,
                                          filterOrders: _vm.filterOrders,
                                          clearFilters: _vm.clearFilters
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    icon: "",
                                    color: "green"
                                  },
                                  on: { click: _vm.getDataFromApi }
                                },
                                [_c("v-icon", [_vm._v("mdi-cached")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "open-on-hover": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    "x-small": "",
                                                    icon: "",
                                                    color: "grey"
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-tools")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "caption",
                                      attrs: { dense: "", nav: "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide headers"
                                            },
                                            model: {
                                              value:
                                                _vm.ordersPageConfig
                                                  .hideDefaultHeader,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ordersPageConfig,
                                                  "hideDefaultHeader",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersPageConfig.hideDefaultHeader"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide duty date"
                                            },
                                            model: {
                                              value:
                                                _vm.ordersPageConfig
                                                  .hideDutyDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ordersPageConfig,
                                                  "hideDutyDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersPageConfig.hideDutyDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide site"
                                            },
                                            model: {
                                              value:
                                                _vm.ordersPageConfig.hideSite,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ordersPageConfig,
                                                  "hideSite",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersPageConfig.hideSite"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide stock data"
                                            },
                                            model: {
                                              value:
                                                _vm.ordersPageConfig
                                                  .hideStockData,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ordersPageConfig,
                                                  "hideStockData",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersPageConfig.hideStockData"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide eBay fee"
                                            },
                                            model: {
                                              value:
                                                _vm.ordersPageConfig
                                                  .hideEbayFee,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ordersPageConfig,
                                                  "hideEbayFee",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ordersPageConfig.hideEbayFee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "open-on-hover": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "x-small": "",
                                                    icon: "",
                                                    color: "green",
                                                    disabled:
                                                      _vm.pagination
                                                        .totalItems == 0 ||
                                                      _vm.pagination
                                                        .totalItems > 200
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "", nav: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Orders")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                small: "",
                                                icon: "",
                                                color: "green",
                                                disabled:
                                                  _vm.pagination.totalItems >
                                                  200
                                              },
                                              on: { click: _vm.exportOrders }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-download")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Orders products")
                                          ]),
                                          _vm._v(" "),
                                          _c("v-icon", [_vm._v("mdi-download")])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    content: _vm.filtersLength,
                                    value: _vm.filtersLength,
                                    color: "green",
                                    overlap: "",
                                    right: ""
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "badge" },
                                      on: { click: _vm.getDataFromApi },
                                      slot: "badge"
                                    },
                                    [_vm._v(_vm._s(_vm.filtersLength))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        color: "blue"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.filterDrawer = !_vm.filterDrawer
                                        }
                                      }
                                    },
                                    [
                                      !_vm.filtersLength
                                        ? _c("v-icon", [
                                            _vm._v(
                                              "\n                                            mdi-filter-outline\n                                        "
                                            )
                                          ])
                                        : _c("v-icon", [
                                            _vm._v(
                                              "\n                                            mdi-filter\n                                        "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider")
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item",
                      fn: function(props) {
                        return [
                          _c(
                            "tr",
                            {
                              key: props.item.orders_id,
                              class:
                                props.item.orders_id === _vm.selectedRow
                                  ? "teal lighten-5"
                                  : null,
                              on: {
                                click: function($event) {
                                  return _vm.toggleSelectedRow(
                                    props.item.orders_id
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "55px",
                                    "min-width": "55px",
                                    "max-width": "55px"
                                  }
                                },
                                [
                                  _c("table-id", {
                                    attrs: { item: props.item },
                                    on: { openOrderDialog: _vm.openOrderDialog }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "75px",
                                    "min-width": "75px",
                                    "max-width": "75px"
                                  }
                                },
                                [
                                  _c("table-actions", {
                                    attrs: { item: props.item },
                                    on: {
                                      openOrderDialog: _vm.openOrderDialog,
                                      openInvoiceDialog: _vm.openInvoiceDialog,
                                      openCelnicaDialog: _vm.openCelnicaDialog,
                                      openPrintDialog: _vm.openPrintDialog,
                                      openBuyerCheckoutMessageDialog:
                                        _vm.openBuyerCheckoutMessageDialog,
                                      openShippingDialog: _vm.openShippingDialog
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "vertical-align": "top",
                                    "max-width": "30vw",
                                    "min-width": "20vw"
                                  }
                                },
                                [
                                  _c("order-info", {
                                    attrs: {
                                      item: props.item,
                                      hideStockData:
                                        _vm.ordersPageConfig.hideStockData,
                                      hideEbayFee:
                                        _vm.ordersPageConfig.hideEbayFee
                                    },
                                    on: {
                                      openProductDialog: _vm.openProductDialog
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "vertical-align": "top",
                                    "min-width": "30vw",
                                    "max-width": "40vw"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pa-1 text-left caption" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.item.delivery_name)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "ma-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            props.item.delivery_street_address
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "ma-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            props.item.delivery_city
                                              ? props.item.delivery_city + ","
                                              : ""
                                          ) +
                                            " " +
                                            _vm._s(
                                              props.item.delivery_postcode
                                                ? props.item.delivery_postcode +
                                                    ","
                                                : ""
                                            ) +
                                            " " +
                                            _vm._s(
                                              props.item.delivery_state
                                                ? props.item.delivery_state +
                                                    ","
                                                : ""
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.item.delivery_country
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 font-weight-medium"
                                        },
                                        [
                                          _vm._v(
                                            "Tel: " +
                                              _vm._s(
                                                props.item.customers_telephone
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "min-width": "90px",
                                    "max-width": "125px"
                                  }
                                },
                                [
                                  _c("table-total", {
                                    attrs: { item: props.item }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "caption",
                                  staticStyle: {
                                    "min-width": "100px",
                                    "max-width": "100px"
                                  }
                                },
                                [
                                  props.item.date_purchased
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "ma-0 pa-0 ml-1 Pending",
                                          staticStyle: { width: "70px" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 text-left",
                                              attrs: { cols: "6" }
                                            },
                                            [_vm._v("Pend:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      props.item.date_purchased
                                                    )
                                                    .format("DD MMM YY")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.date_paid
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "ma-0 pa-0 ml-1  Paid",
                                          staticStyle: { width: "70px" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 text-left",
                                              attrs: { cols: "6" }
                                            },
                                            [_vm._v("Paid:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      props.item.date_paid
                                                    )
                                                    .format("DD MMM YY")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.date_shipped
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "ma-0 pa-0 ml-1  Delivered",
                                          staticStyle: { width: "70px" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 text-left",
                                              attrs: { cols: "6" }
                                            },
                                            [_vm._v("Ship:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      props.item.date_shipped
                                                    )
                                                    .format("DD MMM YY")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  props.item.date_refund
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "ma-0 pa-0 ml-1  Refunded",
                                          staticStyle: { width: "70px" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 text-left",
                                              attrs: { cols: "6" }
                                            },
                                            [_vm._v("Ref:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ma-0 pa-0",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      props.item.date_refund
                                                    )
                                                    .format("DD MMM YY")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [_c("v-divider", { attrs: { vertical: "" } })],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.ordersPageConfig.hideDutyDate
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "caption",
                                      staticStyle: {
                                        "min-width": "70px",
                                        "max-width": "70px"
                                      }
                                    },
                                    [
                                      props.item.duty_date &&
                                      props.item.duty_date.duty_date
                                        ? _c("span", { staticClass: "pa-2" }, [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm
                                                    .$moment(
                                                      props.item.duty_date
                                                        .duty_date
                                                    )
                                                    .format("DD MMM YY")
                                                ) +
                                                "\n                                "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.ordersPageConfig.hideDutyDate
                                ? _c(
                                    "td",
                                    [
                                      _c("v-divider", {
                                        attrs: { vertical: "" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "caption",
                                  staticStyle: {
                                    "min-width": "70px",
                                    "max-width": "70px"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pa-2",
                                      class:
                                        props.item.orders_status_name
                                          .orders_status_name
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            props.item.orders_status_name
                                              .orders_status_name
                                          ) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.ordersPageConfig.hideSite
                                ? _c(
                                    "td",
                                    [
                                      _c("v-divider", {
                                        attrs: { vertical: "" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.ordersPageConfig.hideSite
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "caption",
                                      staticStyle: {
                                        "min-width": "70px",
                                        "max-width": "70px"
                                      }
                                    },
                                    [
                                      props.item.site
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  props.item.site.substring(
                                                    props.item.site.indexOf(
                                                      "-"
                                                    ) + 1
                                                  )
                                                ) +
                                                "\n                                     "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      }
                    },
                    _vm.loading
                      ? {
                          key: "body.append",
                          fn: function() {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "pa-0",
                                    staticStyle: { height: "2px" },
                                    attrs: { colspan: _vm.headers.length + 1 }
                                  },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        color: "blue",
                                        height: 2,
                                        indeterminate: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filters-wrapper" },
                [
                  _c("order-filters", {
                    attrs: {
                      drawer: _vm.filterDrawer,
                      filtersLength: _vm.filtersLength
                    },
                    on: {
                      closeMenu: _vm.closeFiltersMenu,
                      filterOrders: _vm.filterOrders,
                      clearFilters: _vm.clearFilters
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("scrollable-dialog", {
        attrs: {
          show: _vm.showRefundDialog,
          fullscreen: false,
          scrollable: true,
          width: "auto "
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("refund-order", {
                  attrs: { order: _vm.currentOrder },
                  on: {
                    closeDialog: _vm.closeRefundDialog,
                    orderUpdated: _vm.orderUpdated
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("scrollable-dialog", {
        attrs: {
          show: _vm.showCancelDialog,
          fullscreen: false,
          scrollable: true,
          width: "auto "
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("cancel-order", {
                  attrs: { order: _vm.currentOrder },
                  on: {
                    closeDialog: _vm.closeCancelDialog,
                    orderUpdated: _vm.orderUpdated
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("scrollable-dialog", {
        attrs: {
          show: _vm.showInvoiceDialog,
          fullscreen: false,
          scrollable: true,
          width: "70%"
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("invoice", {
                  attrs: { order: _vm.currentOrder },
                  on: { closeDialog: _vm.closeInvoiceDialog }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "neworderdialog",
          attrs: {
            name: "NewOrderDialog",
            fullscreen: false,
            width: "auto ",
            scrollable: true,
            "hide-overlay": ""
          },
          on: {
            "click:outside": function($event) {
              _vm.addNewOrderDialog = false
            }
          },
          model: {
            value: _vm.addNewOrderDialog,
            callback: function($$v) {
              _vm.addNewOrderDialog = $$v
            },
            expression: "addNewOrderDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [_vm._v("New order")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.addNewOrderDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [_c("new-order", { on: { newOrderAdded: _vm.newOrderAdded } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "celnica_" + _vm.currentOrderId,
          attrs: {
            name: "CelnicaDialog",
            fullscreen: false,
            width: "60%",
            scrollable: true,
            "hide-overlay": ""
          },
          on: {
            "click:outside": function($event) {
              _vm.showCelnicaDialog = false
            }
          },
          model: {
            value: _vm.showCelnicaDialog,
            callback: function($$v) {
              _vm.showCelnicaDialog = $$v
            },
            expression: "showCelnicaDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Select orders for export to celnisprava.cz")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showCelnicaDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("celnica", { attrs: { orders: _vm.currentCelnicaOrders } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "ShippingDialog_" + _vm.currentOrderId,
          attrs: {
            name: "ShippingDialog",
            fullscreen: false,
            width: "60%",
            scrollable: true,
            "hide-overlay": ""
          },
          on: {
            "click:outside": function($event) {
              _vm.showShippingDialog = false
            }
          },
          model: {
            value: _vm.showShippingDialog,
            callback: function($$v) {
              _vm.showShippingDialog = $$v
            },
            expression: "showShippingDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(_vm.currentOrderId) + " - Tracking numbers"
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showShippingDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [_c("shipping", { attrs: { order: _vm.currentOrder } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "printdialog_" + _vm.currentOrderId,
          attrs: {
            name: "PrintDialog",
            fullscreen: false,
            width: "60%",
            scrollable: true,
            "hide-overlay": ""
          },
          on: {
            "click:outside": function($event) {
              _vm.showPrintDialog = false
            }
          },
          model: {
            value: _vm.showPrintDialog,
            callback: function($$v) {
              _vm.showPrintDialog = $$v
            },
            expression: "showPrintDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.currentOrderId) + " - eBay payments")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showPrintDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _vm.startGettingPrintData
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "ma-4 pa-4 text-center font-weight-bold",
                          attrs: { "justify-center": "" }
                        },
                        [
                          _vm._v(
                            "\n                        Please wait!\n                    "
                          )
                        ]
                      )
                    : _c("print-dialog", {
                        attrs: {
                          order: _vm.currentOrder,
                          EbayPaypal: _vm.currentEbayPaypal
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "BuyerCheckoutMessageDialog_" + _vm.currentOrderId,
          attrs: {
            name: "BuyerCheckoutMessageDialog",
            fullscreen: false,
            width: "60%",
            scrollable: true,
            "hide-overlay": ""
          },
          on: {
            "click:outside": function($event) {
              _vm.showBuyerCheckoutMessageDialog = false
            }
          },
          model: {
            value: _vm.showBuyerCheckoutMessageDialog,
            callback: function($$v) {
              _vm.showBuyerCheckoutMessageDialog = $$v
            },
            expression: "showBuyerCheckoutMessageDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(_vm.currentOrderId) +
                            " - Buyer Checkout Message"
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showBuyerCheckoutMessageDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pa-3" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.currentBuyerCheckoutMessage) +
                    "\n                "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }