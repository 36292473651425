<template>
    <v-form class="auto-reply" ref="formAuto">
        <v-layout wrap>
            <v-flex xs10 sm4>
                <v-text-field
                        v-model="accept"
                        label='Accept'
                        :rules="acceptRules"
                        solo
                        height="28"
                ></v-text-field>
            </v-flex>
            <v-flex xs10 sm4>
                <v-text-field
                        v-model="decline"
                        label='Decline'
                        :rules="declineRules"
                        solo
                        height="28"
                        :class="{'ml-0': $vuetify.breakpoint.smAndDown, 'ml-1': $vuetify.breakpoint.mdAndUp}"
                ></v-text-field>
            </v-flex>
            <v-flex xs2 sm4>
                <v-btn
                        small
                        icon
                        @click="saveNewValues"
                        :disabled="(accept === '' && decline === '')"
                        :class="{'ml-0': $vuetify.breakpoint.smAndDown, 'ml-1': $vuetify.breakpoint.smAndUp}"
                        :loading="loading"
                >
                    <v-icon>save</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>

    </v-form>
</template>

<script>
    export default {
        name: "AutoRespondForm",
        props: {
            offer_range:{
                required: true
            },
            price:{
                required: true
            },
            loading: {
                required: false
            }
        },
        data() {
            return {
                accept: null,
                decline: null,
                //loading: false,
                acceptRules:[
                    value => value < this.price  || "Can't be bigger than Buy Now price",
                ],
                declineRules:[
                    value => value < this.price  || "Can't be bigger than Buy Now price",
                ]

            }
        },
        mounted(){
            this.initialize()
        },
        methods:{
            initialize(){
                this.accept = this.toValue(this.offer_range.split(';')[1], this.price)
                this.decline = this.toValue(this.offer_range.split(';')[0], this.price)


                if(this.accept && this.decline){
                    this.acceptRules.push(
                        value => value > this.decline  || "Can't be less than Decline",
                    );

                    this.declineRules.push(
                        value => value < this.accept || "Can't be bigger than Accept",
                    );
                }

            },
            validate(){
                return this.$refs.formAuto.validate()
            },
            toValue(percent, val){
                return percent ? Math.ceil(percent * val/100) : ''
            },
            saveNewValues(){

                if(this.validate()){

                    this.acceptRules = [];
                    this.declineRules = [];

                    let newDecline = '';
                    let newAccept = '';

                    if(this.accept){
                        newAccept = Math.round((this.accept/this.price*100)*100)/100
                    }

                    if(this.decline){
                        newDecline = Math.round((this.decline/this.price*100)*100)/100
                    }

                    const new_offer_range = `${newDecline};${newAccept}`;

                    this.$emit('updaterange', new_offer_range)
                }
            }
        },
        /*watch:{
            offer_range(){
                this.loading = false;
            }
        }*/
    }
</script>

<style>

    .auto-reply .v-input__slot{
        min-height: auto;
        margin: 0;
    }

    .auto-reply .v-btn{
        margin-top: 0;
    }

    .auto-reply .v-text-field.v-text-field--solo .v-input__control{
        min-height: auto;
    }

</style>