<template>
    <div class="d-flex flex-column text-right justify-center pa-2">
        <p class="pa-0 ma-0">{{ orderSubTotal }}&nbsp;{{this.item.currency}}</p>
        <p class="pa-0 ma-0">{{ orderShipping}}&nbsp;{{this.item.currency}}</p>
        <p class="pa-0 ma-0 font-weight-bold">{{ ordertTotal }}&nbsp;{{this.item.currency}}</p>
    </div>
</template>

<script>
    import Order from '../../order/Order';
    export default {
        name: "TableTotal",
        props: {
            item: {
                required: true
            }
        },
        computed:{
            orderShipping() {
                return this.item.order_shipping ? this.item.order_shipping.value : 0
            },
            orderSubTotal(){
                if(this.item.order_sub_total && this.item.order_sub_total.value){
                    return this.item.order_sub_total.value
                }
                if(this.item.order_products.length > 0){
                    return this.item.order_products.reduce(function(a, c){return a + Number((c.products_quantity*c.products_price) || 0)}, 0);
                }
                return 0;
            },
            ordertTotal(){
                if(this.item.order_total && this.item.order_total.value){
                    return this.item.order_total.value
                }
                return this.orderSubTotal + this.orderShipping


            }
        }
    }
</script>

<style scoped>

</style>