const OrderStatusEnum = Object.freeze({
    PENDING: 1,
    PROCESSING: 2,
    DELIVERED: 3,
    CANCELED: 4,
    FAXED: 5,
    PAID: 6,
    COMBINED: 7,
    SHIPPED: 8,
    REFUNDED: 9,
    PARTIALREFUNDED: 10
});

export default OrderStatusEnum;