var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": "",
        id: "Checks"
      }
    },
    [
      _c(
        "v-flex",
        { staticStyle: { height: "100%" }, attrs: { xs12: "" } },
        [
          _c(
            "JqxSplitter",
            {
              ref: "mainSplitter",
              attrs: {
                panels: _vm.panels,
                width: "100%",
                height: "100%",
                theme: _vm.jqwTheme
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "jqxTabs",
                    {
                      ref: "SetsTabs",
                      attrs: {
                        width: "100%",
                        height: "100%",
                        position: "top",
                        animationType: "none"
                      },
                      on: {
                        selecting: function($event) {
                          return _vm.onSetsTabsSelecting($event)
                        },
                        selected: function($event) {
                          return _vm.onSetsTabsSelected($event)
                        }
                      }
                    },
                    [
                      _c("ul", [
                        _c("li", [_vm._v("Checks")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Sold products")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("All products")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { overflow: "hidden" } },
                        [
                          _vm.setsDataAdapter
                            ? _c("JqxDataTable", {
                                ref: "SetsDataTable",
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  source: _vm.setsDataAdapter,
                                  columns: _vm.setsColumns,
                                  pageable: true,
                                  serverProcessing: true,
                                  pagerButtonsCount: 2,
                                  pagerMode: "default",
                                  altRows: true,
                                  filterable: true,
                                  filterMode: "default",
                                  sortable: true,
                                  statusBarHeight: 25,
                                  theme: _vm.jqwTheme
                                },
                                on: {
                                  rowClick: function($event) {
                                    return _vm.onRowClickSets($event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { overflow: "hidden" } },
                        [
                          _vm.ordersProductsDataAdapter
                            ? _c("JqxDataTable", {
                                ref: "ordersProductsDataTable",
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  source: _vm.ordersProductsDataAdapter,
                                  columns: _vm.ordersProductsColumns,
                                  serverProcessing: true,
                                  pagerButtonsCount: 2,
                                  pagerMode: "advanced",
                                  pageSizeOptions: [25, 50, 100],
                                  altRows: true,
                                  pageable: _vm.pageable,
                                  sortable: true,
                                  editSettings: _vm.editSettings,
                                  localization: _vm.localization,
                                  theme: _vm.jqwTheme,
                                  filterable: true,
                                  showToolbar: true,
                                  renderToolbar: _vm.renderOrdersProductsToolbar
                                },
                                on: {
                                  rowClick: function($event) {
                                    return _vm.onRowClickOrdersProducts($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-left absolute full-width",
                              staticStyle: { top: "45px", left: "10px" },
                              attrs: { xs12: "", sm12: "" }
                            },
                            [
                              _c(
                                "JqxCheckBox",
                                {
                                  staticStyle: { float: "left" },
                                  attrs: {
                                    width: 150,
                                    height: 25,
                                    checked: true,
                                    hasThreeStates: false
                                  },
                                  model: {
                                    value: _vm.orderFilter,
                                    callback: function($$v) {
                                      _vm.orderFilter = $$v
                                    },
                                    expression: "orderFilter"
                                  }
                                },
                                [_vm._v("Order status filter")]
                              ),
                              _vm._v(" "),
                              _c("JqxDateTimeInput", {
                                ref: "filerByDataOrdersProductsDataTable",
                                staticStyle: { float: "left" },
                                attrs: { autoCreate: false },
                                on: {
                                  change: function($event) {
                                    return _vm.changeFilerByDataOrdersProductsDataTable()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { float: "left" },
                                  attrs: {
                                    "x-small": "",
                                    disabled: _vm.isAddSelectedDialog
                                  },
                                  on: { click: _vm.showAddSelectedDialog }
                                },
                                [_vm._v("Add selected")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    "hide-overlay": "",
                                    persistent: "",
                                    width: "300"
                                  },
                                  model: {
                                    value: _vm.isAddSelectedDialog,
                                    callback: function($$v) {
                                      _vm.isAddSelectedDialog = $$v
                                    },
                                    expression: "isAddSelectedDialog"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { color: "wight" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pt-4" },
                                        [
                                          _vm.selectedProducts.length === 0
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "text-bold-200 red--text text--darken-3",
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            There are not selected products!\n                                        "
                                                  )
                                                ]
                                              )
                                            : !_vm.addSelectedProductRunning
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "text-bold-200 green--text text--darken-4",
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Are you ready?\n                                        "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Please stand by\n                                        "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { "justify-center": "" } },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isAddSelectedDialog = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("Close")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.selectedProducts
                                                        .length,
                                                    expression:
                                                      "selectedProducts.length"
                                                  }
                                                ]
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.selectedProducts
                                                          .length,
                                                      expression:
                                                        "selectedProducts.length"
                                                    }
                                                  ],
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.runAddSelectedProduct
                                                  }
                                                },
                                                [_vm._v("Run!")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { overflow: "hidden" } },
                        [
                          _vm.allProductsDataAdapter
                            ? _c("JqxDataTable", {
                                ref: "allProductsDataTable",
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  source: _vm.allProductsDataAdapter,
                                  columns: _vm.allProductsColumns,
                                  serverProcessing: true,
                                  pagerButtonsCount: 2,
                                  pagerMode: "advanced",
                                  pageSizeOptions: [25, 50, 100],
                                  altRows: true,
                                  pageable: true,
                                  sortable: true,
                                  editSettings: _vm.editSettings,
                                  localization: _vm.localization,
                                  theme: _vm.jqwTheme,
                                  filterable: true
                                },
                                on: {
                                  rowClick: function($event) {
                                    return _vm.onRowClickAllProducts($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-left absolute full-width",
                              staticStyle: { top: "45px", left: "10px" },
                              attrs: { xs12: "", sm12: "" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: {
                                    float: "right",
                                    right: "25px"
                                  },
                                  attrs: {
                                    "x-small": "",
                                    disabled: _vm.isAddSelectedAllProductsDialog
                                  },
                                  on: {
                                    click: _vm.showAddSelectedAllProductsDialog
                                  }
                                },
                                [_vm._v("Add selected")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    "hide-overlay": "",
                                    persistent: "",
                                    width: "300"
                                  },
                                  model: {
                                    value: _vm.isAddSelectedAllProductsDialog,
                                    callback: function($$v) {
                                      _vm.isAddSelectedAllProductsDialog = $$v
                                    },
                                    expression: "isAddSelectedAllProductsDialog"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { color: "wight" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pt-4" },
                                        [
                                          _vm.selectedAllProducts.length === 0
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "text-bold-200 red&#45;&#45;text text&#45;&#45;darken-3",
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            There are not selected products!\n                                        "
                                                  )
                                                ]
                                              )
                                            : !_vm.addSelectedAllProductRunning
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "text-bold-200 green&#45;&#45;text text&#45;&#45;darken-4",
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Are you ready?\n                                        "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Please stand by\n                                        "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { "justify-center": "" } },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isAddSelectedAllProductsDialog = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("Close")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.selectedAllProducts
                                                        .length,
                                                    expression:
                                                      "selectedAllProducts.length"
                                                  }
                                                ]
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.selectedAllProducts
                                                          .length,
                                                      expression:
                                                        "selectedAllProducts.length"
                                                    }
                                                  ],
                                                  attrs: { color: "success" },
                                                  on: {
                                                    click:
                                                      _vm.runAddSelectedAllProduct
                                                  }
                                                },
                                                [_vm._v("Run!")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.tabSelectedIndex == 0
                    ? _c(
                        "v-flex",
                        {
                          staticClass:
                            "text-right absolute checks-form-buttons",
                          attrs: { xs5: "", sm6: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", color: "red" },
                              on: { click: _vm.addNewCheck }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", color: "green" },
                              on: { click: _vm.refreshCheckDataTable }
                            },
                            [_c("v-icon", [_vm._v("mdi-cached")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tabSelectedIndex == 1
                    ? _c(
                        "v-flex",
                        {
                          staticClass:
                            "text-right absolute checks-form-buttons",
                          attrs: { xs5: "", sm6: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", color: "green" },
                              on: { click: _vm.refreshOrdersProductsDataTable }
                            },
                            [_c("v-icon", [_vm._v("mdi-cached")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tabSelectedIndex == 2
                    ? _c(
                        "v-flex",
                        {
                          staticClass:
                            "text-right absolute checks-form-buttons",
                          attrs: { xs5: "", sm6: "" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", color: "green" },
                              on: { click: _vm.refreshAllProductsDataTable }
                            },
                            [_c("v-icon", [_vm._v("mdi-cached")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.currentSet || _vm.isNewCheck
                    ? _c(
                        "JqxSplitter",
                        {
                          ref: "horizontalSplitter",
                          attrs: {
                            width: "100%",
                            height: "100%",
                            orientation: "horizontal",
                            showSplitBar: false,
                            panels: [
                              {
                                size: 105,
                                min: 105,
                                max: 105,
                                collapsible: false
                              },
                              { min: 100, collapsible: false }
                            ],
                            theme: _vm.jqwTheme
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.currentSet || _vm.isNewCheck
                                ? _c(
                                    "v-form",
                                    {
                                      ref: "formCheck",
                                      attrs: { "lazy-validation": "" },
                                      model: {
                                        value: _vm.validCheck,
                                        callback: function($$v) {
                                          _vm.validCheck = $$v
                                        },
                                        expression: "validCheck"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ma-0",
                                              attrs: {
                                                justify: "center",
                                                align: "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 !important"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pb-0",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c("datepicker", {
                                                            staticClass:
                                                              "font-weight-regular ma-1",
                                                            attrs: {
                                                              labelName: "Date",
                                                              rules: _vm.rules,
                                                              required: "true",
                                                              hideDetails: false
                                                            },
                                                            on: {
                                                              input: _vm.saveSet
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.currentSet
                                                                  .set_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.currentSet,
                                                                  "set_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "currentSet.set_date"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pb-0",
                                                          attrs: { cols: "9" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "font-weight-regular ma-1",
                                                            attrs: {
                                                              dense: "",
                                                              label: "Name",
                                                              rules: _vm.rules,
                                                              required: ""
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.saveSet
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.currentSet
                                                                  .set_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.currentSet,
                                                                  "set_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "currentSet.set_name"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 !important"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "font-weight-regular ma-1",
                                                            attrs: {
                                                              dense: "",
                                                              label: "Total",
                                                              readonly: "",
                                                              "hide-details": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          staticClass:
                                                                            "caption font-weight-regular",
                                                                          staticStyle: {
                                                                            width:
                                                                              "58px"
                                                                          },
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            label:
                                                                              "",
                                                                            items:
                                                                              _vm.currencies,
                                                                            rules:
                                                                              _vm.rules,
                                                                            required:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            "single-line":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change:
                                                                              _vm.saveSet
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.checkCurrency,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.checkCurrency = $$v
                                                                            },
                                                                            expression:
                                                                              "checkCurrency"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              3206530748
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.currentSet
                                                                  .total,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.currentSet,
                                                                  "total",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "currentSet.total"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _vm.isNewCheck
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "success",
                                                                    fab: "",
                                                                    "x-small":
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      _vm.isSetUpdating
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.addCheck
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "add"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    fab: "",
                                                                    "x-small":
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      _vm.isSetUpdating
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.saveSet
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "save"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.currentSet
                                                                  .set_date
                                                              ) +
                                                              "\n                                            "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.productsSetDataAdapter && _vm.currentSetId
                                ? _c("JqxDataTable", {
                                    ref: "ProductsSetDataTable",
                                    attrs: {
                                      width: "100%",
                                      height: "100%",
                                      source: _vm.productsSetDataAdapter,
                                      columns: _vm.productsSetColumns,
                                      serverProcessing: true,
                                      showAggregates: true,
                                      aggregatesHeight: 25,
                                      editSettings: _vm.editSettings,
                                      editable: true,
                                      localization: _vm.localization,
                                      theme: _vm.jqwTheme
                                    },
                                    on: {
                                      rowClick: function($event) {
                                        return _vm.onRowClickProductsCheck(
                                          $event
                                        )
                                      },
                                      cellEndEdit: function($event) {
                                        return _vm.onCellEndEdit($event)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.productsSetDataAdapter
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "text-right absolute checks-form-buttons",
                                      staticStyle: {
                                        "margin-right": "18px",
                                        "margin-top": "2px"
                                      },
                                      attrs: { xs5: "", sm6: "" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            icon: "",
                                            color: "green"
                                          },
                                          on: {
                                            click:
                                              _vm.refreshProductsSetDataTable
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-cached")])],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }