<template>
    <v-card outlined :rounded="false" flat style="border: none; display: flex !important; flex-direction: column;" class="rounded-0">
        <v-card-text style="flex-grow: 1; overflow: auto; height:385px;" class="rounded-0">
            <v-row no-gutters>
                <v-col cols="6" class="d-flex justify-space-between align-start ma-0">Category:</v-col>
                <v-col cols="6" class="d-flex justify-space-between align-start ma-0">Target:</v-col>
                <v-col cols="6" class="d-flex justify-space-between align-start ma-0">
                    <tree-select
                        name="productCategory"
                        :clearable="false"
                        :load-options="loadOptionsProductCategories"
                        :options="productsOptionsCategories"
                        :auto-load-root-options="true"
                        :searchable="true"
                        :normalizer="normalizer"
                        v-model="product.category.categories_id"
                        :always-open="true"
                        search-nested
                        :show-count="true"
                        :defaultExpandLevel="2"
                    />
                </v-col>
                <v-col cols="6" class="d-flex justify-space-between align-start ma-0">
                    <tree-select
                        name="productTargetCategory"
                        :clearable="false"
                        :load-options="loadOptionsProductTargetCategories"
                        :options="productsOptionsCategoriesTarget"
                        :auto-load-root-options="true"
                        :searchable="true"
                        :normalizer="normalizer"
                        v-model="product.targetcatid"
                        :always-open="true"
                        search-nested
                        :disable-branch-nodes="true"
                        :show-count="true"
                        :defaultExpandLevel="2"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider class="my-0" style="width: 100%;"/>
        <v-card-actions class="text-right">
            <v-spacer/>
            <v-btn
                small
                color="primary"
                class="body-1 ml-2"
                @click="saveProductCategory"
            >Save</v-btn>
            <v-btn
                small
                color="default"
                class="body-1 ml-2"
                @click="closeDialog"
            >Close</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { LOAD_ROOT_OPTIONS } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
    name: "EditProductCategory",
    props: {
        product: {
            required: false,
            type: Object,
            default: {
                targetcatid: 0,
                category: {
                    categories_id: 0
                }
            },
        }
    },
    data: () => ({
        productsOptionsCategories: null,
        productsOptionsCategoriesTarget: null,
        normalizer(node) {
            if(node && node.descriptions){
                return {
                    id: node.descriptions.categories_id,
                    label: (node.categories_shop === 1 ? '🌟 ' : '') + node.descriptions.categories_name,
                    children: node.children,
                }
            }
        },
    }),
    mounted() {
    },
    computed: {
        ...mapGetters([
            'productCategories',
        ]),
    },
    methods: {
        async loadOptionsProductCategories({action}){
            if (action === LOAD_ROOT_OPTIONS) {
                this.productsOptionsCategories = this.productCategories;
            }
        },
        async loadOptionsProductTargetCategories({action}){
            if (action === LOAD_ROOT_OPTIONS) {
                this.productsOptionsCategoriesTarget = _.filter(this.productCategories, function(o) { return o.categories_target; });
            }
        },
        saveProductCategory(){
            EventBus.$emit('saveProductCategory', this.product);
        },
        closeDialog(){
            EventBus.$emit('closeEditProductCategoryDialog');
        }
    },
    components: {
        TreeSelect,
    }
}
</script>

<style scoped>

</style>