<template>
    <div>
        <v-icon @click="productForm = true">build</v-icon>
        <custom-dialog :show="productForm"
                       :id="product.products_id"
                       :title="product.products_id ? product.products_id : 'Edit'"
                       @closeDialog="closeCustomDialog"
        >

            <template v-slot:content>
                <product-form :productForEdit="product"  @saveProductInfo="saveProductInfo"/>
            </template>
        </custom-dialog>
    </div>

    
</template>

<script>
    import CustomDialog from "@/components/CustomDialog";
    import ProductForm from "./ProductForm";

    export default {
        name: "EditProduct",
        props: {
            product: {
                required: true,
                type: Object
            }
        },
        data: () => ({
            productForm: false,

        }),
        methods:{
            closeCustomDialog(){
                this.productForm = false;
            },
            saveProductInfo(){
                this.productForm = false;
                //TODO request for save info
            }
        },
        components:{
            CustomDialog,
            ProductForm
        }
    }
</script>

<style scoped>

</style>