<template>
    <v-layout wrap>
        <v-flex xs12 text-left class="pa-3">
            <v-form v-if="noteData">
                <v-textarea
                        outlined
                        name="input-7-4"
                        label="Order note"
                        v-model="noteData.orders_note"
                ></v-textarea>
                <v-btn color="success" @click="saveOrderNote" :disabled="noteData.orders_note == null">
                    Save
                </v-btn>
            </v-form>

        </v-flex>

    </v-layout>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "Note",
        props:{
            isActive:{}
        },
        data(){
            return {
                noteData: null
            }
        },
        computed:{
            ...mapGetters([
                'order'
            ]),
        },
        mounted() {
            this.init()
        },
        methods:{
            async init(){
                this.noteData = await this.order.order_note
                this.$emit('componentReady')
            },
            saveOrderNote(){
                this.$store.dispatch('updateOrder', {'order_note': this.noteData })
                    .catch(error => {
                        alert(`Note is not saved. ${error}`)
                    })
            }
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-note"){
                    if(this.order !== null){
                        this.$emit('componentReady')
                    }else{
                        this.init()
                    }


                }

            }
        }
    }
</script>

<style scoped>

</style>