<template>
    <v-container id="main-search-bar" class="ma-0 pt-0" style="background-color: transparent !important;">
        <v-card
                class="d-flex flex-row"
                flat
                tile
                style="background-color: transparent !important;"
        >
            <v-card
                    outlined
                    tile
                    style="background-color: transparent !important;"
            >
                <v-overflow-btn
                        id="main-search-bar-selector"
                        style="width: 175px; z-index: 1000;"
                        small
                        dense
                        hide-details
                        hide-selected
                        :items="searchOption"
                        v-model="searchBy"
                        item-value="field"
                        item-text="text"
                        return-object
                        dark
                ></v-overflow-btn>
            </v-card>
            <v-card
                    outlined
                    tile
                    style="background-color: transparent !important; width: 100%;"
            >
                <v-text-field
                        dense
                        solo
                        flat
                        hide-details
                        append-icon="mdi-magnify"
                        label="Search"
                        v-model="search"
                        :name="searchBy.field"
                        @keyup.enter="searchAndOpenDialog"

                        autocomplete="off"
                        @change="changeSearch"
                        @click:clear="clearSearch"
                        @click:append="searchAndOpenDialog"
                        style="background-color: transparent !important; width: 100%; padding-top: 2px;"
                ></v-text-field>
            </v-card>
        </v-card>
            <v-dialog v-model="searchDialog" persistent max-width="90%" class="search-results" >
                <v-card class="wrap-results">
                    <v-card-title id="dialog-title">
                        <span class="headline">Search results</span>
                        <v-spacer/>
                        <v-btn icon  @click.stop="closeAndReset">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text style="">
                        <v-container style="height: 100%;">
                            <v-row v-if="loading" class="ma-0 pa-1" style="height: 100%;">
                                <v-col cols="12" class="text-center">
                                    <v-progress-circular
                                    :size="150"
                                    :width="7"
                                    color="purple"
                                    indeterminate></v-progress-circular>
                                </v-col>
                            </v-row>
                            <v-row v-else style="height: 100%;">
                                <v-col :cols="(results.orders.length ? 6 : 12)" v-if="results.products.length">
                                    <h2>Products</h2>
                                    <div>
                                        <v-list dense >
                                            <v-list-item-group color="primary">
                                                <v-list-item
                                                        v-for="(product, i) in results.products"
                                                        :key="i"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title @click="openProductDialog(product)">
                                                            <p class="primary--text ma-0"> {{ product.products_id }} - {{ product.descriptions.products_name}}</p>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-icon small @click="printProductBarcode(product)">mdi-barcode-scan</v-icon>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <router-link
                                                exact
                                                :to="{ name: 'Products'}"
                                                class="title mt-3"
                                                @click.native="handleSearch('Products')"
                                                style="text-decoration: none;"
                                                v-if="(results.products_total > 1)"
                                        >
                                            <v-btn>All results ({{ results.products_total }})</v-btn></router-link>
                                    </div>
                                </v-col>

                                <v-divider
                                    vertical
                                ></v-divider>

                                <v-col :cols="(results.products.length ? 6 : 12)" v-if="results.orders.length">
                                    <h2>Orders</h2>
                                    <div>
                                        <v-list dense>
                                            <v-list-item-group color="primary">
                                                <v-list-item
                                                        v-for="(order, i) in results.orders"
                                                        :key="i"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <p class="primary--text ma-0">
                                                                <span :class="order.orders_status_name.orders_status_name" class="pa-2"  @click="openOrderDialog(order)">{{ order.orders_id }} - {{ order.site }} - {{ order.date_purchased}}</span>
                                                                <v-icon small color="amber" @click="printOrderReceipt(order)">mdi-cube-outline</v-icon>
                                                            </p>
                                                            <v-list dense>
                                                                <v-list-item-group color="primary">
                                                                    <v-list-item
                                                                            dense
                                                                            v-for="(product, i) in order.order_products"
                                                                            :key="i"
                                                                    >
                                                                        <v-list-item-content>
                                                                            <v-list-item-title @click="openProductDialog(product.product)">
                                                                                {{ product.products_id }} - {{ product.products_name}}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                        <v-icon small @click="printProductBarcode(product)">mdi-barcode-scan</v-icon>
                                                                    </v-list-item>
                                                                </v-list-item-group>
                                                            </v-list>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <router-link
                                                exact
                                                :to="{ name: 'Orders'}"
                                                class="title mt-3"
                                                @click.native="handleSearch('Orders','orderParams')"
                                                style="text-decoration: none;"
                                                v-if="(results.orders_total > 1)"
                                        >
                                            <v-btn>All results ({{ results.orders_total }})</v-btn></router-link>
                                    </div>

                                </v-col>

                                <v-col cols="12" v-if="!results.products.length  && !results.order_products.length && !results.orders.length">Nothing found</v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>

    import EventBus from "@/event-bus";
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: "SearchBar",
        data: () => ({
            search: '',
            isOpen: false,
            searchOption: [
                {text: 'Product ID', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter', with: null},
                {text: 'Product Name', field: 'products_name', condition: 'CONTAINS', type: 'stringfilter', with: null },
                {text: 'Order ID', field: 'orders_id', condition: 'STARTS_WITH', type: 'numericfilter', with: 'order_products.product.descriptions'},
                {text: 'eBay ID', field: 'items_id', condition: 'CONTAINS', type: 'stringfilter', with: 'order'},
                {text: 'eBay Order ID', field: 'OrderID', condition: 'CONTAINS', type: 'stringfilter', with: 'order'},
                {text: 'PayPal ID', field: 'PayPalTransactionID', condition: 'CONTAINS', type: 'stringfilter', with: null},
                {text: 'Tracking ID', field: 'ShipmentTrackingNumber', condition: 'CONTAINS', type: 'stringfilter', with: 'order'},
                {text: 'Buyer email', field: 'customers_email_address', condition: 'CONTAINS', type: 'stringfilter', with: null},
            ],
            searchBy:  {text: 'By product id', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter', with: null},
            loading: false,
            productsFields: {
                products_id: 'products_id',
                products_name: 'descriptions.products_name',
                items_id: 'products_on_ebay.ItemID',
                orders_id: 'product_orders.orders:orders_id',
                OrderID: 'product_orders.orders:OrderID',
                PayPalTransactionID: 'product_orders.orders:PayPalTransactionID',
                ShipmentTrackingNumber: 'product_orders:ShipmentTrackingNumber',
                customers_email_address: 'product_orders.orders:customers_email_address',
            },
            ordersFields: {
                orders_id: 'orders_id',
                OrderID: 'OrderID',
                PayPalTransactionID: 'PayPalTransactionID',
                ShipmentTrackingNumber: 'order_products.ShipmentTrackingNumber',
                customers_email_address: 'customers_email_address',
                products_id: 'order_products.products_id',
                products_name: 'order_products.products_name',
                items_id: 'order_products.auctionID'
            },
            results:{
                products: [],
                orders: [],
                order_products: [],
                products_on_bay: [],
                products_total: 0,
                order_products_total: 0,
                orders_total: 0,
                products_on_bay_total: 0
            },
            init_results:{
                products: [],
                orders: [],
                order_products: [],
                products_on_bay: [],
                products_total: 0,
                order_products_total: 0,
                orders_total: 0,
                products_on_bay_total: 0
            },
            searchDialog: false,
            productParams: null,
            orderParams: null,
            currentRoute: null,
        }),
        created() {
            this.currentRoute = this.$route.name;

            if (localStorage.getItem('search_bar_search_by')) {
                this.searchBy = JSON.parse(localStorage.getItem('search_bar_search_by'));
            }
            if (localStorage.getItem('search_bar_search')) {
                this.search = JSON.parse(localStorage.getItem('search_bar_search'));
            }
        },
        watch: {
            searchBy: {
                handler() {
                    localStorage.setItem('search_bar_search_by', JSON.stringify(this.searchBy))
                },
                deep: true,
            },
            search: {
                handler() {
                    localStorage.setItem('search_bar_search', JSON.stringify(this.search))
                },
                deep: true,
            },
        },
        methods: {
            changeSearch() {
                if (this.search === '') this.clearSearch()
            },
            clearSearch() {
                this.search = '';
                this.searchDialog = false;

                this.saveSearchRequest({
                    productParams: {},
                    orderParams: {},
                })

                if(this.$router.currentRoute.name === "Products"){
                    //EventBus.$emit('reloadProducts');
                    EventBus.$emit('searchProduct');
                }else if(this.$router.currentRoute.name === "Orders"){
                    //EventBus.$emit('reloadOrders');
                    EventBus.$emit('searchOrder');
                }
            },
            searchAndOpenDialog() {
                if(this.search) {
                    this.getResults(this.search);
                }
            },
            getResults(val) {
                this.results= _.cloneDeep(this.init_results);
                this.loading = true
                let self = this

                if (this.$router.currentRoute.name === 'Products' && ['products_id', 'products_name', 'orders_id', 'items_id', 'OrderID'].includes(this.searchBy.field)){
                    switch (this.searchBy.field) {
                        case 'products_id':
                            this.productParams = {
                                'filterGroups[0][field]': 'products_id',
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[0][filters][0][type]': this.searchBy.type,
                                'filterGroups[0][filters][0][operator]': 'or',
                                'filterGroups[0][operator]': 'and',
                            };
                            break;
                        case 'products_name':
                            this.productParams = {
                                'filterGroups[0][field]': 'descriptions.products_name',
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[0][filters][0][type]': this.searchBy.type,
                                'filterGroups[0][filters][0][operator]': 'or',
                                'filterGroups[0][operator]': 'and',
                            };
                            break;
                        case 'orders_id':
                            this.productParams = {
                                'filterGroups[0][field]': 'product_orders.orders:orders_id',
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[0][filters][0][type]': this.searchBy.type,
                                'filterGroups[0][filters][0][operator]': 'or',
                                'filterGroups[0][operator]': 'and',
                            };
                            break;
                        case 'OrderID':
                            this.productParams = {
                                'filterGroups[0][field]': 'product_orders.orders:OrderID',
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[0][filters][0][type]': this.searchBy.type,
                                'filterGroups[0][filters][0][operator]': 'or',
                                'filterGroups[0][operator]': 'and',
                            };
                            break;
                        case 'items_id':
                            this.productParams = {
                                'filterGroups[0][field]': 'product_orders.auctionID',
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[0][filters][0][type]': this.searchBy.type,
                                'filterGroups[0][filters][0][operator]': 'or',
                                'filterGroups[0][operator]': 'and',
                                'filterGroups[1][field]': 'products_on_ebay.ItemID',
                                'filterGroups[1][filters][0][value]': val,
                                'filterGroups[1][filters][0][condition]': this.searchBy.condition,
                                'filterGroups[1][filters][0][type]': this.searchBy.type,
                                'filterGroups[1][filters][0][operator]': 'or',
                                'filterGroups[1][operator]': 'or',
                            };
                            break;
                    }

                    this.saveSearchRequest({
                        productParams: this.productParams
                    })


                    EventBus.$emit('searchProduct');

                }
                else if(this.$router.currentRoute.name === 'Orders'){
                    this.orderParams = {
                        'filterGroups[0][field]': this.ordersFields[this.searchBy.field],
                        'filterGroups[0][filters][0][value]': val,
                        'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                        'filterGroups[0][filters][0][type]': this.searchBy.type,
                        'filterGroups[0][filters][0][operator]': 'or',
                        'filterGroups[0][operator]': 'and',
                    };

                    this.saveSearchRequest({
                        orderParams: this.orderParams
                    })

                    EventBus.$emit('searchOrder');
                }
                else {
                    this.productParams = {
                        'filterGroups[0][field]': this.productsFields[this.searchBy.field],
                        'filterGroups[0][filters][0][value]': val,
                        'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                        'filterGroups[0][filters][0][type]': this.searchBy.type,
                        'filterGroups[0][filters][0][operator]': 'or',
                        'filterGroups[0][operator]': 'and',
                    };

                    this.orderParams = {
                        'filterGroups[0][field]': this.ordersFields[this.searchBy.field],
                        'filterGroups[0][filters][0][value]': val,
                        'filterGroups[0][filters][0][condition]': this.searchBy.condition,
                        'filterGroups[0][filters][0][type]': this.searchBy.type,
                        'filterGroups[0][filters][0][operator]': 'or',
                        'filterGroups[0][operator]': 'and',
                    };

                    this.saveSearchRequest({
                        productParams: this.productParams,
                        orderParams: this.orderParams
                    })

                    this.searchDialog = true;

                    if (this.searchBy.field === 'orders_id' || this.searchBy.field === 'PayPalTransactionID' || this.searchBy.field === 'ShipmentTrackingNumber'  || this.searchBy.field === 'customers_email_address'  || this.searchBy.field === 'OrderID'  )  {
                        axios.all([
                            axios.get('/api/orders', {
                                params: {
                                    ...this.orderParams,
                                    'with[]': 'order_products.product.descriptions',
                                    'pagesize': '10'
                                },
                            })
                        ])
                            .then(axios.spread(function (products, orders) {
                                self.results.orders = products.data.data;
                                self.results.orders_total = products.data.total;
                            }))
                            .catch(function (error) {
                                console.log(error)
                            })
                            .finally(() => (this.loading = false))
                    } else {
                        axios.all([
                            axios.get('/api/products', {
                                params: {
                                    ...this.productParams,
                                    'with[]': 'descriptions',
                                    'pagesize': '10'
                                },
                            }),
                            axios.get('/api/orders', {
                                params: {
                                    ...this.orderParams,
                                    'with[]': 'order_products.product.descriptions',
                                    'pagesize': '10'
                                },
                            })
                        ])
                            .then(axios.spread(function (products, orders) {
                                self.results.products = products.data.data;
                                self.results.products_total = products.data.total;
                                self.results.orders = orders.data.data;
                                self.results.orders_total = orders.data.total;
                            }))
                            .catch(function (error) {
                                console.log(error)
                            })
                            .finally(() => (this.loading = false))
                    }

                }
            },
            closeAndReset(){
                this.searchDialog = false;
                this.results= _.cloneDeep(this.init_results);
            },
            handleSearch(pageName, ordersType = null){
                this.searchDialog = false;

                if(this.$router.currentRoute.name === pageName && pageName === "Products"){
                     //EventBus.$emit('reloadProducts');
                }else if(this.$router.currentRoute.name === pageName && pageName === "Orders"){
                     //EventBus.$emit('reloadOrders', ordersType);
                }
            },
            openProductDialog(item) {
                EventBus.$emit('openProductDialog', item);
            },
            openOrderDialog(item){
                EventBus.$emit('openOrderDialog', item.orders_id, 'tab-order-info');
            },
            printOrderReceipt(item) {
                window.open("/receipt/"+item.orders_id+"?lang=cz", '_blank')
            },
            printProductBarcode(product) {
                window.open("/barcode/"+product.products_id, '_blank')
            },
            ...mapActions([
                "saveSearchRequest"
            ]),
        }
    }
</script>

<style scoped lang="scss">

    @import "../../sass/_variables.scss";

    .autocomplete {
        position: relative;
    }

    .autocomplete-results {
        position: absolute;
        top: 35px;
        max-height: 400px;
        overflow-y: auto;
    }

    .autocomplete-results p{
        background-color: $gray-dark;
    }

    .search-results{
        height: calc(100vh - 200px);
    }

    .wrap-results{
        display: grid;
        grid-template-rows: 60px  calc(100vh - 200px - 60px - 60px) 60px;

        .v-card__text{
            max-height: 100%;
            overflow: auto;
        }
    }



    .search-results .v-card__title{
        position: fixed;
        background: white;
        width: 90%;
        z-index: 10;
    }

</style>

<style>

    #main-search-bar .v-select.v-input--dense .v-select__selection--comma {
        margin: 7px 4px 7px 10px !important;
        overflow: unset !important;
        text-overflow: unset !important;
    }

    #main-search-bar .v-input__slot {
        background-color: transparent !important;
    }
    #main-search-bar .v-text-field__slot {
        background-color: transparent !important;
    }

    .v-input__slot:hover {
        background-color: transparent !important;
    }

</style>