var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "8", md: "4" } },
        [
          _c(
            "v-card",
            { staticClass: "elevation-12" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [_c("v-toolbar-title", [_vm._v("Change password")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "changePassword",
                      attrs: { "aria-label": "ChangePassword" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [_vm.rules.required],
                                  label: "New password",
                                  type: "password",
                                  name: "new_password"
                                },
                                model: {
                                  value: _vm.input.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "password", $$v)
                                  },
                                  expression: "input.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.passwordConfirmationRules,
                                  label: "Password confirmation",
                                  name: "passsword_confirmation",
                                  type: "password"
                                },
                                model: {
                                  value: _vm.input.passsword_confirmation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.input,
                                      "passsword_confirmation",
                                      $$v
                                    )
                                  },
                                  expression: "input.passsword_confirmation"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { loading: _vm.loading, color: "primary" },
                          on: { click: _vm.validate }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticStyle: { display: "none" },
                      attrs: {
                        id: "change-password-logout-form",
                        action: "/logout",
                        method: "POST"
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "hidden", name: "_token" },
                        domProps: { value: _vm.token }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }