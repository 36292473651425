export default {
    getOrdersFromApi(request, options = {}) {
        /*if(!options) {
            options = {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["date_purchased"],
                sortDesc: [true],
                groupBy:[],
                groupDesc: [],
                mustSort: false,
                multiSort: false
            }
        } else {*/
            options = {...{
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ["date_purchased"],
                    sortDesc: [true],
                    groupBy:[],
                    groupDesc: [],
                    mustSort: false,
                    multiSort: false
                }, ...options
            }
        /*}*/

        const searchParam = {
            'with': ['order_products.product:products_id,targetcatid',
                'order_products.product.stocks_quantity.stocks_move_to_full.parentStocks',
                //'order_products.product.descriptions',
                'order_sub_total',
                'order_shipping',
                'order_total',
                'duty_date',
                'ebay_site',
                'order_ebayfees',
                'order_ebaytotal'
            ],
            'pagesize': options.itemsPerPage,
            'current_page': options.page,
            'sortdatafield': options.sortBy[0],
            'sortorder': (options.sortDesc[0] ? 'desc' : 'asc'),
            'withCount': ['order_emails']
        }

        return axios.get('/api/orders',
            {
                params: {...searchParam, ...request },
            })


    },
    exportOrders(request, options = {}) {
        options = {...{
                page: 1,
                itemsPerPage: 10,
                sortBy: ["date_purchased"],
                sortDesc: [true],
                groupBy:[],
                groupDesc: [],
                mustSort: false,
                multiSort: false
            }, ...options
        }

        const searchParam = {
            'with': [],
            'without': ['duty_date', 'orders_status_name'],
            'sortdatafield': options.sortBy[0],
            'sortorder': (options.sortDesc[0] ? 'desc' : 'asc'),
        }

        return axios.post('/api/orders/export',
            {...searchParam, ...request },
            {responseType: 'blob'}
            )
    }
}

