var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "8", md: "4" } },
        [
          _c(
            "v-card",
            { staticClass: "elevation-12" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [_c("v-toolbar-title", [_vm._v("Login")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "loginForm",
                      attrs: {
                        id: "login_form",
                        method: "POST",
                        action: "/login",
                        "aria-label": "Login"
                      }
                    },
                    [
                      _c("input", {
                        attrs: { type: "hidden", name: "_token" },
                        domProps: { value: _vm.csrf_token }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.email,
                                    _vm.rules.max255chars
                                  ],
                                  label: "Email",
                                  name: "email"
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.max255chars
                                  ],
                                  label: "Password",
                                  name: "password",
                                  type: "password"
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Remember Me",
                                  name: "remember",
                                  value: _vm.remember
                                },
                                model: {
                                  value: _vm.remember,
                                  callback: function($$v) {
                                    _vm.remember = $$v
                                  },
                                  expression: "remember"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.validate }
                        },
                        [_vm._v("Login")]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "auth.email" } } },
                        [_vm._v("Forgot Password?")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }