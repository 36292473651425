<template>
    <v-layout class="pa-2" style="height: 100% !important;">
        <v-tabs v-model="orderTabActive" show-arrows hide-slider grow class="elevation-1 scroll-tabs order-tabs">

            <v-tab v-for="(orderTab, i) in orderTabs"
                   :key="orderTab.key"
                   :href="'#tab-' + orderTab.key"
                   ripple
                   @change="changeTab(orderTab.key)"
                   :disabled="disabledTab(orderTab.name)"
            >
                {{orderTab.name}}
            </v-tab>

            <v-tabs-items class="elevation-1" touchless v-model="orderTabActive" id="order-tab-items">
                <v-tab-item
                        v-for="(orderTab, i) in orderTabs"
                        :key="orderTab.key"
                        :value="'tab-' + orderTab.key"
                        class="text-center"
                >
                    <v-progress-circular
                        v-show="loadingTab"
                        :size="70"
                        :width=7
                        color="green"
                        indeterminate
                        class="ma-5"
                   />

                    <component :is="orderTab.component"
                               v-if="OrderLoaded"
                               v-show="!loadingTab"
                               :isActive="orderTabActive"
                               @componentReady="loadingTab=false"
                               :id="id ? id : $route.params.id"
                               :fullscreen="fullscreen"
                    :class="orderTab.key"/>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-layout>
</template>

<script>

    import { mapGetters } from "vuex";
    import OrderInfo from './OrderInfo'
    import FedexShipment from './FedexShipment'
    import DhlShipment from './DhlShipment'
    import StatusHistory from './StatusHistory'
    import BuyerHistory from './BuyerHistory'
    import FedexTracking from './FedexTracking'
    import Note from './Note'
    import Emails from './Emails'
    import EventBus from "@/event-bus";

    export default {
        name: "Order",
        props:{
            id:{},
            activeTab: {
                /*type: String,
                default: function () {
                    return 'tab-order-info'
                }*/
            },
            needLoadOrder: {
                type: Boolean,
                default: function () {
                    return true
                }
            },
            fullscreen: {
                type: Boolean,
                default: function () {
                    return true
                }
            }
        },
        data () {
            return {
                orderTabs: [
                    { name: 'Order Info', order:1, key: 'order-info', component: OrderInfo},
                    { name: 'Fedex', key: 'fedex-ship', order:2, component: FedexShipment},
                    //{ name: 'DHL', key: 'dhl-ship', order:3, component: DhlShipment},
                    { name: 'Status History', key: 'status-history', order:4, component: StatusHistory},
                    { name: 'Buyer History', key: 'buyer-history', order:5, component: BuyerHistory},
                    //{ name: 'Fedex Tracking', order:5, key: 'fedex-track', component: FedexTracking},
                    { name: 'Note', order:6, key: 'order-note', component: Note},
                    { name: 'Email', order:7, key: 'email', component: Emails}
                ],
                loadingTab: true,
                OrderLoaded: false,
                orderTabActive: null,
            }
        },
        mounted(){
            if(this.needLoadOrder){
                this.loadOrder()
            }else{
                this.OrderLoaded = true
            }

            console.log(this.activeTab)

            if (this.activeTab) {
                this.orderTabActive =  this.activeTab
            } else {
                this.orderTabActive = 'tab-order-info'
            }
        },
        computed: {
            ...mapGetters([
                'order',
            ]),
        },
        methods: {
            disabledTab(tabName) {
                switch(tabName) {
                    /*case 'Note':
                        return !(this.order && this.order.order_note && this.order.order_note.orders_note);
                        break;*/
                    default:
                        return false;
                }
            },
            changeTab(tabId){
                if(`tab-${tabId}` !== this.orderTabActive){
                    this.loadingTab=true
                }
                this.orderTabActive = `tab-${tabId}`
                EventBus.$emit('changeOrderDialogTab', this.orderTabActive);
            },
            async loadOrder(){
                try {
                    await this.$store.dispatch('fetchOrder', this.id ? this.id : this.$route.params.id)
                } catch (ex) {
                    this.error = "Failed to load order"
                } finally {
                    this.OrderLoaded = true
                }
            },
        },
        components:{
            OrderInfo,
            FedexShipment,
            StatusHistory,
            BuyerHistory,
            Note,
            Emails
        }

    }
</script>

<style scoped>

    .v-dialog .scroll-tabs {
        /*display: grid;*/
        /*grid-template-rows: 48px  calc(100vh - 200px - 48px);*/
        /*height: calc(100% - 48px) !important;*/
        /*min-height: calc(100vh - 200px - 48px) !important;*/
        display: grid;
        grid-template-rows: 48px  calc(100vh - 200px - 48px);
    }

    .v-tabs-items {
        max-height:100%;
        overflow-x:hidden;
        overflow-y:auto;

    }

    #tab-order-info {
        overflow-y: auto;
        overflow-x: hidden;
    }

    #tab-fedex-ship, #tab-status-history, #tab-buyer-history, #tab-email {
        overflow-y: auto;
        overflow-x: hidden;
    }

    #tab-email {
        height: 100%;
    }

    #tab-dhl-ship, #tab-dhl-ship .dhl-ship, .dhl-ship, #tab-dhl-ship .dhl-ship>div, #tab-dhl-ship .dhl-ship>div>div, #tab-dhl-ship .dhl-ship>div>div .v-card {
        height: 100%;
    }

    .fedex-ship {
        max-height: 80vh;
    }

</style>