<template xmlns="http://www.w3.org/1999/html">
    <v-layout align-space-around justify-start column fill-height>
        <v-flex xs12 style="height: 100%">
            <v-row v-if="auctions.length > 0">
                <v-col
                    v-for="(item, i) in auctions"
                    :key="i"
                    cols="12"
                >
                    <v-card>
                        <v-card-title
                            class="headline ma-0 pa-2"
                        >
                            <a :href="'https://'+item.url" target="_blank">{{ item.name }}</a>
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                color="pink"
                                @click="openEditAuction(item)"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="indigo"
                                @click="openEditAuctionLogin(item, newAuctionLogin)"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                @click="deleteAuction(item.id)"
                            >
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-subtitle v-if="item.logins.length > 0">
                            <v-row>
                                <v-col
                                    v-for="(login, i) in item.logins"
                                    :key="i"
                                    cols="6"
                                    style="text-align: left;"
                                >
                                    <v-card>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="10">
                                                    <strong>Username:</strong> {{ login.username}} <br>
                                                    <strong>Password:</strong> {{ login.password}} <br>
                                                    <strong>Email:</strong> {{ login.email}}
                                                </v-col>
                                                <v-col cols="2" style="text-align: right;">
                                                    <v-btn
                                                        icon
                                                        color="pink"
                                                        @click="openEditAuctionLogin(item, login)"
                                                    >
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                    <br/>
                                                    <v-btn
                                                        icon
                                                        @click="deleteAuctionLogin(login.id)"
                                                    >
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-flex>
        <v-fab-transition>
            <v-btn
                color="pink"
                dark
                absolute
                bottom
                right
                fab
                class="mb-10"
                @click="openEditAuction(newAuction)"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
        <v-dialog name="editAuction" v-model="showEditAuction" :fullscreen="false" width="50vw" :scrollable="true" key="editAuction" persistent>
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>Add/Edit auction</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="closeEditAuction">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text
                    class="pa-0"
                    v-if="currentAuction"
                >
                    <v-form
                        class="ma-2"
                        ref="auction"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-text-field
                            v-model="currentAuction.name"
                            :rules="nameRules"
                            id="name"
                            label="Name"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="currentAuction.url"
                            :rules="nameRules"
                            label="URL"
                            id="url"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions
                    v-if="currentAuction"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="currentAuction.id"
                        color="green"
                        @click="saveAuction"
                    >
                        Save
                    </v-btn>
                    <v-btn
                        v-else
                        color="green"
                        @click="addAuction"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog name="editAuctionLogin" v-model="showEditAuctionLogin" :fullscreen="false" width="50vw" :scrollable="true" key="editAuctionLogin" persistent>
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>Add/Edit auction login</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="closeEditAuctionLogin">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text
                    class="pa-0"
                    v-if="currentAuctionLogin"
                >
                    <v-form
                        class="ma-2"
                        ref="auction"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-text-field
                            v-model="currentAuctionLogin.username"
                            :rules="nameRules"
                            id="username"
                            label="Username"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="currentAuctionLogin.password"
                            :rules="nameRules"
                            label="Password"
                            id="password"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="currentAuctionLogin.email"
                            :rules="nameRules"
                            label="Email"
                            id="email"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions
                    v-if="currentAuctionLogin"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="currentAuctionLogin.id"
                        color="green"
                        @click="saveAuctionLogin"
                    >
                        Save
                    </v-btn>
                    <v-btn
                        v-else
                        color="green"
                        @click="addAuctionLogin"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <confirm ref="confirm"></confirm>
    </v-layout>
</template>

<script>
import Confirm from '@/components/Confirm'

    export default {
        name: "AuctionsBuy",
        data(){
            return {
                valid: true,
                nameRules: [
                    v => !!v || 'Required',
                ],
                auctions: [],
                showEditAuction: false,
                showEditAuctionLogin: false,
                currentAuction: null,
                currentAuctionLogin: null,
                newAuction: {
                    id: null,
                    name: '',
                    url: ''
                },
                newAuctionLogin: {
                    id: null,
                    auktions_for_buy_id: null,
                    username: '',
                    password: '',
                    email: ''
                }
            }
        },
        mounted(){
            this.init();
        },
        methods: {
            init(){
                axios.get(`/api/auctions_buy?with[]=logins`)
                    .then(response => {
                        console.log(response.data.data)
                        this.auctions = response.data.data
                    });
            },
            openEditAuction(auction) {
                this.currentAuction = auction;
                this.showEditAuction = true;
            },
            closeEditAuction() {
                this.showEditAuction = false;
                this.newAuction = {
                    id: null,
                    name: '',
                    url: ''
                }
                this.currentAuction = this.newAuction
            },
            openEditAuctionLogin(auction, login) {
                this.currentAuction = auction;
                this.currentAuctionLogin = login;
                this.showEditAuctionLogin = true;
            },
            closeEditAuctionLogin() {
                this.showEditAuctionLogin = false;
                this.newAuctionLogin = {
                    id: null,
                    auktions_for_buy_id: null,
                    username: '',
                    password: '',
                    email: ''
                }
                this.currentAuctionLogin = this.newAuctionLogin
            },
            saveAuction() {
                if (this.$refs.auction.validate()) {
                    axios.put(`/api/auctions_buy/${this.currentAuction.id}`, this.currentAuction)
                        .then(response => {
                            this.closeEditAuction()
                            this.init()
                        });
                } else {
                    console.log('OPPsQQQ')
                }
            },
            addAuction() {
                if (this.$refs.auction.validate()) {
                    axios.post(`/api/auctions_buy`, this.currentAuction)
                        .then(response => {
                            this.closeEditAuction()
                            this.init()
                        });
                } else {
                    console.log('OPPsQQQ')
                }
            },
            saveAuctionLogin() {
                if (this.$refs.auction.validate()) {
                    axios.put(`/api/auctions_buy_login/${this.currentAuctionLogin.id}`, this.currentAuctionLogin)
                        .then(response => {
                            this.closeEditAuctionLogin()
                            this.init()
                        });
                } else {
                    console.log('OPPsQQQ')
                }
            },
            addAuctionLogin() {
                if (this.$refs.auction.validate()) {
                    this.currentAuctionLogin.auktions_for_buy_id = this.currentAuction.id
                    axios.post(`/api/auctions_buy_login`, this.currentAuctionLogin)
                        .then(response => {
                            this.closeEditAuctionLogin()
                            this.init()
                        });
                } else {
                    console.log('OPPsQQQ')
                }
            },
            async deleteAuction(id) {
                if (await this.$refs.confirm.open('Delete auction', 'Are you sure?', { color: 'success' })) {
                    axios.delete(`/api/auctions_buy/${id}`)
                        .then(response => {
                            this.showEditAuction = false
                            this.init()
                        });
                }
            },
            async deleteAuctionLogin(id) {
                if (await this.$refs.confirm.open('Delete auction login', 'Are you sure?', { color: 'success' })) {
                    axios.delete(`/api/auctions_buy_login/${id}`)
                        .then(response => {
                            this.showEditAuctionLogin = false
                            this.init()
                        });
                }
            }
        },
        components: {
            Confirm
        }
    }
</script>

<style scoped>

</style>