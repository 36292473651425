var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { staticClass: "pa-0", staticStyle: { height: "100%" } },
    [
      _vm.awaitNewProduct && _vm.productid == null
        ? _c(
            "v-card",
            {
              staticClass: "rounded-0",
              staticStyle: {
                border: "none",
                display: "flex !important",
                "flex-direction": "column"
              },
              attrs: {
                outlined: "",
                rounded: false,
                flat: "",
                id: "new-product-dialog-card"
              }
            },
            [
              _c(
                "v-card-text",
                {
                  staticClass: "rounded-0",
                  staticStyle: {
                    "flex-grow": "1",
                    overflow: "auto",
                    height: "550px"
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "335px" },
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "\n                        Place:\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex justify-space-between align-start ma-0",
                          attrs: { cols: "10" }
                        },
                        [
                          _c("tree-select", {
                            staticClass: "pl-0",
                            attrs: {
                              clearable: false,
                              name: "place",
                              "load-options": _vm.loadOptions,
                              options: _vm.productsOptionsCategories,
                              "auto-load-root-options": true,
                              searchable: true,
                              normalizer: _vm.normalizer,
                              "always-open": true
                            },
                            model: {
                              value: _vm.placeId,
                              callback: function($$v) {
                                _vm.placeId = $$v
                              },
                              expression: "placeId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "my-2",
                    staticStyle: { width: "100%" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "\n                        Template:\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              items: _vm.products_templates,
                              "item-text": "products_name",
                              "item-value": "products_id",
                              label: "",
                              "hide-details": "",
                              "single-line": ""
                            },
                            on: { change: _vm.selectTemplate },
                            model: {
                              value: _vm.products_template,
                              callback: function($$v) {
                                _vm.products_template = $$v
                              },
                              expression: "products_template"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-center text-h6 ma-0 pa-0 font-weight-bold",
                          attrs: { cols: "12" }
                        },
                        [_vm._v("\n                    OR\n                ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "\n                        Copy:\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("search-product", {
                            attrs: { clearSearch: _vm.clearSearch },
                            on: { "add-product": _vm.searchProduct }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", {
                staticClass: "my-0",
                staticStyle: { width: "100%" }
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "text-right" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "body-1 ml-2",
                      attrs: {
                        small: "",
                        color: "primary",
                        disabled: _vm.product_for_copy == null
                      },
                      on: { click: _vm.createNewProduct }
                    },
                    [_vm._v("Add")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.awaitNewProduct
        ? _c(
            "v-card",
            {
              staticClass: "rounded-0",
              staticStyle: {
                border: "none",
                display: "flex !important",
                "flex-direction": "column"
              },
              attrs: {
                outlined: "",
                rounded: false,
                flat: "",
                id: "new-product-dialog-card"
              }
            },
            [
              _c(
                "v-card-text",
                {
                  staticClass: "rounded-0",
                  staticStyle: { "flex-grow": "1", overflow: "auto" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-0 pb-0 pt-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              counter: "80",
                              rules: _vm.rules.max80chars,
                              "prepend-icon": "mdi-google-lens"
                            },
                            on: { change: _vm.saveProductInfo },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.openProductDialog.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-transit-connection-variant\n                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.printProductBarcode(
                                                _vm.product
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-barcode-scan\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              558682459
                            ),
                            model: {
                              value: _vm.form.descriptions.products_name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.descriptions,
                                  "products_name",
                                  $$v
                                )
                              },
                              expression: "form.descriptions.products_name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "12", xs: "12", lg: "6" }
                        },
                        [
                          _c(
                            "v-form",
                            { ref: "formProductMainForm" },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex  align-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "min-width": "70px" }
                                        },
                                        [_vm._v("Category:")]
                                      ),
                                      _vm._v(" "),
                                      _c("tree-select", {
                                        staticClass: "px-2",
                                        attrs: {
                                          rules: _vm.rules.required,
                                          name: "category",
                                          clearable: false,
                                          "load-options": _vm.loadOptions,
                                          options:
                                            _vm.productsOptionsCategories,
                                          "auto-load-root-options": true,
                                          searchable: true,
                                          normalizer: _vm.normalizer,
                                          required: true
                                        },
                                        on: { select: _vm.saveProductInfo },
                                        model: {
                                          value:
                                            _vm.form.category.categories_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.category,
                                              "categories_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.category.categories_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex  align-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "min-width": "70px" }
                                        },
                                        [_vm._v("Target:")]
                                      ),
                                      _vm._v(" "),
                                      _c("tree-select", {
                                        staticClass: "px-2",
                                        attrs: {
                                          rules: _vm.rules.required,
                                          name: "target",
                                          clearable: false,
                                          "load-options": _vm.loadOptions,
                                          options:
                                            _vm.productsOptionsCategoriesTarget,
                                          "auto-load-root-options": true,
                                          searchable: true,
                                          normalizer: _vm.normalizer,
                                          required: true
                                        },
                                        on: { select: _vm.saveProductInfo },
                                        model: {
                                          value: _vm.form.targetcatid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "targetcatid",
                                              $$v
                                            )
                                          },
                                          expression: "form.targetcatid"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "my-2" }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-1",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-baseline my-0 py-0 pr-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Weight:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-center",
                                          staticStyle: { width: "50%" }
                                        },
                                        [
                                          _c("v-select", {
                                            staticClass:
                                              "font-weight-regular pr-1",
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              "single-line": "",
                                              rules: _vm.rules.required,
                                              "cache-items": true,
                                              items: _vm.productWeightsList,
                                              "menu-props": {
                                                auto: true,
                                                offsetY: true
                                              },
                                              "item-text":
                                                "products_options_values_name",
                                              "item-value":
                                                "products_options_values_id"
                                            },
                                            model: {
                                              value: _vm.weight,
                                              callback: function($$v) {
                                                _vm.weight = $$v
                                              },
                                              expression: "weight"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-baseline my-0 py-0",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Quantity:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "max-width": "80px",
                                              "min-width": "80px"
                                            },
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              reverse: "",
                                              disabled: "",
                                              type: "number"
                                            },
                                            on: {
                                              change: _vm.changeProductQuantity
                                            },
                                            model: {
                                              value: _vm.form.products_quantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "products_quantity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.products_quantity"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-1 mt-1",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center my-0 py-0 pr-1",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Condition:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-center",
                                          staticStyle: { width: "50%" }
                                        },
                                        [
                                          _c("v-select", {
                                            staticClass:
                                              "font-weight-regular currency-select pr-1",
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              "single-line": "",
                                              rules: _vm.rules.required,
                                              "cache-items": true,
                                              items: _vm.productConditionsList,
                                              "menu-props": {
                                                auto: true,
                                                offsetY: true
                                              },
                                              "item-text":
                                                "products_options_values_name",
                                              "item-value":
                                                "products_options_values_id"
                                            },
                                            model: {
                                              value: _vm.condition,
                                              callback: function($$v) {
                                                _vm.condition = $$v
                                              },
                                              expression: "condition"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center my-0 py-0",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Purchase:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("v-text-field", {
                                            staticClass:
                                              "font-weight-regular currency-select pl-1 ma-0 pa-0",
                                            attrs: {
                                              rules: _vm.rules.required,
                                              dense: "",
                                              "hide-details": "",
                                              reverse: "",
                                              step: "0.01",
                                              type: "number"
                                            },
                                            on: { change: _vm.saveProductInfo },
                                            model: {
                                              value:
                                                _vm.form.purchase_price
                                                  .products_price,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.purchase_price,
                                                  "products_price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.purchase_price.products_price"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            staticClass:
                                              "font-weight-regular currency-select pl-1 ma-0 pa-0",
                                            staticStyle: {
                                              "max-width": "80px",
                                              "min-width": "80px"
                                            },
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              "single-line": "",
                                              rules: _vm.rules.required,
                                              "cache-items": true,
                                              items: _vm.currenciesList,
                                              "menu-props": {
                                                auto: true,
                                                offsetY: true
                                              }
                                            },
                                            on: { change: _vm.saveProductInfo },
                                            model: {
                                              value:
                                                _vm.form.purchase_price
                                                  .currencies_code,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.purchase_price,
                                                  "currencies_code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.purchase_price.currencies_code"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pr-1 mt-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-baseline my-0 py-0 pr-2",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Discount:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-center",
                                          staticStyle: { width: "50%" }
                                        },
                                        [
                                          _c("v-select", {
                                            staticClass:
                                              "font-weight-regular currency-select pr-1",
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              "single-line": "",
                                              "cache-items": true,
                                              items: _vm.productDiscountList,
                                              "menu-props": {
                                                auto: true,
                                                offsetY: true
                                              },
                                              "item-text":
                                                "products_options_values_name",
                                              "item-value":
                                                "products_options_values_name"
                                            },
                                            on: { change: _vm.saveProductInfo },
                                            model: {
                                              value: _vm.form.discount.persent,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form.discount,
                                                  "persent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.discount.persent"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-checkbox", {
                                            staticClass: "ma-0",
                                            attrs: {
                                              "input-value": _vm.getProductsAttributesValueByName(
                                                "autodisc"
                                              ),
                                              "hide-details": "",
                                              dense: "",
                                              "true-value": "548",
                                              "false-value": "547"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateProductProperty(
                                                  "autodisc",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-baseline my-0 py-0",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 pr-2 text-left",
                                          staticStyle: {
                                            "font-weight": "bolder",
                                            "max-width": "80px",
                                            "min-width": "80px"
                                          }
                                        },
                                        [_vm._v("Price:")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("v-text-field", {
                                            staticClass:
                                              "font-weight-regular currency-select pl-1 ma-0 pa-0",
                                            attrs: {
                                              rules: _vm.rules.required,
                                              dense: "",
                                              "hide-details": "",
                                              reverse: "",
                                              step: "0.01",
                                              type: "number"
                                            },
                                            on: { change: _vm.saveProductInfo },
                                            model: {
                                              value: _vm.productPrice,
                                              callback: function($$v) {
                                                _vm.productPrice = _vm._n($$v)
                                              },
                                              expression: "productPrice"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            staticClass:
                                              "font-weight-regular currency-select pl-1 ma-0 pa-0",
                                            staticStyle: {
                                              "max-width": "80px",
                                              "min-width": "80px"
                                            },
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              rules: _vm.rules.required,
                                              "cache-items": true,
                                              items: _vm.currenciesList,
                                              "menu-props": {
                                                auto: true,
                                                offsetY: true
                                              },
                                              readonly:
                                                _vm.form.products_status == 1
                                            },
                                            on: { change: _vm.saveProductInfo },
                                            model: {
                                              value: _vm.form.base_currency,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "base_currency",
                                                  $$v
                                                )
                                              },
                                              expression: "form.base_currency"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", lg: "6" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pr-1",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex align-center",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "repair"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "545",
                                      "false-value": "546",
                                      label: "Repair"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "repair",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "freeship"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "29",
                                      "false-value": "28",
                                      label: "Free Shipment"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "freeship",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "bestoffer"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "533",
                                      "false-value": "534",
                                      label: "Best Offers"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "bestoffer",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "colony"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "600",
                                      "false-value": "601",
                                      label: "Colony"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "colony",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "CMCom"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "596",
                                      "false-value": "597",
                                      label: "CM.com"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "CMCom",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    staticStyle: { "white-space": "nowrap" },
                                    attrs: {
                                      "input-value": _vm.getProductsAttributesValueByName(
                                        "GTCCom"
                                      ),
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": "30",
                                      "false-value": "31",
                                      label: "GTC"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProductProperty(
                                          "GTCCom",
                                          $event
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-2" }),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            { ref: "formEbayCategories" },
                            [
                              _c("v-simple-table", {
                                staticStyle: { overflow: "hidden" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "60px",
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                      Ebay.com\n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", {
                                                staticStyle: { width: "30px" }
                                              }),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "caption font-weight-regular pl-1 ma-0 pa-0",
                                                      staticStyle: {
                                                        "max-width": "95%"
                                                      },
                                                      attrs: {
                                                        dense: "",
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        rules:
                                                          _vm.rules.required,
                                                        "cache-items": true,
                                                        items:
                                                          _vm.ebayCategoriesCom,
                                                        "item-text": "name",
                                                        "item-value": "catid"
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeEbayCategories
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .ebay_categories[0]
                                                            .catid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .ebay_categories[0],
                                                            "catid",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.ebay_categories[0].catid"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: { width: "0px" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                     \n                                "
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "60px",
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                      Ebay.de\n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", {
                                                staticStyle: { width: "30px" }
                                              }),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "caption font-weight-regular pl-1 ma-0 pa-0",
                                                      staticStyle: {
                                                        "max-width": "95%"
                                                      },
                                                      attrs: {
                                                        dense: "",
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        rules:
                                                          _vm.rules.required,
                                                        "cache-items": true,
                                                        items:
                                                          _vm.ebayCategoriesDe,
                                                        "item-text": "name",
                                                        "item-value": "catid"
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeEbayCategories
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .ebay_categories[77]
                                                            .catid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .ebay_categories[77],
                                                            "catid",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.ebay_categories[77].catid"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: { width: "0px" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                     \n                                    "
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    width: "60px",
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                      Ebay.co.uk\n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: { width: "30px" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                     \n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "caption font-weight-regular pl-1 ma-0 pa-0",
                                                      staticStyle: {
                                                        "max-width": "95%"
                                                      },
                                                      attrs: {
                                                        dense: "",
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        rules:
                                                          _vm.rules.required,
                                                        "cache-items": true,
                                                        items:
                                                          _vm.ebayCategoriesCouk,
                                                        "item-text": "name",
                                                        "item-value": "catid"
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeEbayCategories
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .ebay_categories[3]
                                                            .catid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .ebay_categories[3],
                                                            "catid",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.ebay_categories[3].catid"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: { width: "0px" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                     \n                                "
                                                  )
                                                ]
                                              )
                                            ])
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3941190407
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", lg: "12" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _vm.isLiteVersion
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          staticClass: "elevation-1",
                                          attrs: {
                                            "show-arrows": "",
                                            grow: ""
                                          },
                                          model: {
                                            value: _vm.descTabActive,
                                            callback: function($$v) {
                                              _vm.descTabActive = $$v
                                            },
                                            expression: "descTabActive"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.textTabs, function(
                                            textTab
                                          ) {
                                            return _c(
                                              "v-tab",
                                              {
                                                key: textTab.key,
                                                attrs: {
                                                  href: "#tab-" + textTab.key,
                                                  ripple: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(textTab.name) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tabs-items",
                                            {
                                              staticClass: "elevation-1",
                                              attrs: { touchless: "" },
                                              model: {
                                                value: _vm.descTabActive,
                                                callback: function($$v) {
                                                  _vm.descTabActive = $$v
                                                },
                                                expression: "descTabActive"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tab-item",
                                                {
                                                  staticClass:
                                                    "text-center  scroll-tab-item",
                                                  attrs: {
                                                    value: "tab-description"
                                                  }
                                                },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "",
                                                      name: "input-7-4",
                                                      placeholder:
                                                        "Product description"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.saveProductInfo
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.descriptions
                                                          .products_description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form.descriptions,
                                                          "products_description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.descriptions.products_description"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tab-item",
                                                {
                                                  staticClass:
                                                    "text-center  scroll-tab-item",
                                                  attrs: { value: "tab-note" }
                                                },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "",
                                                      name: "input-7-4",
                                                      placeholder:
                                                        "Product note"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.saveProductInfo
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.product_note
                                                          .products_note,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form.product_note,
                                                          "products_note",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.product_note.products_note"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("defects", {
                                        staticClass: "pa-2",
                                        attrs: { productsId: _vm.productid }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", {
                staticClass: "my-0",
                staticStyle: { width: "100%" }
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 pt-0",
                    attrs: {
                      label: "Lite version",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.isLiteVersion,
                      callback: function($$v) {
                        _vm.isLiteVersion = $$v
                      },
                      expression: "isLiteVersion"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color: "primary",
                        loading: _vm.awaitSaveProduct
                      },
                      on: { click: _vm.saveProductInfo }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }