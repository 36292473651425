var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center", wrap: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex pa-0 ma-0",
                  attrs: { sm: "2", cols: "4" }
                },
                [
                  _c("v-select", {
                    staticClass: "mr-3 styled-select caption",
                    attrs: {
                      items: _vm.searchOption,
                      "item-value": "field",
                      "item-text": "text",
                      "return-object": "",
                      "hide-details": "",
                      "single-line": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.searchBy,
                      callback: function($$v) {
                        _vm.searchBy = $$v
                      },
                      expression: "searchBy"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex pa-0 ma-0",
                  attrs: { sm: "10", cols: "8" }
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.results,
                      loading: _vm.isLoading,
                      "search-input": _vm.search,
                      autofocus: false,
                      "item-text": "forSearch",
                      "item-value": "products_id",
                      "return-object": "",
                      autocomplete: "off",
                      dense: "",
                      "hide-details": ""
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.search = $event
                      },
                      "update:search-input": function($event) {
                        _vm.search = $event
                      },
                      change: _vm.add
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }