var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c("OrderAddressForms", {
            attrs: { newOrder: true },
            on: { saveNewOrder: _vm.saveNewOrder, submitForm: _vm.submitForm }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }