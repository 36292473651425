<template>
    <v-layout align-space-around justify-start column fill-height id="SalesGoodsFlow">
        <v-toolbar class="mb-2">
            <v-flex sm2>
                <v-select
                    dense
                    v-model="account"
                    :items="filer_auction_accounts"
                    prepend-icon="account_box"
                    hide-details
                    clearable
                ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex sm2>
                <v-select
                    dense
                    v-if="orders_statusesList"
                    v-model="order_statuses"
                    :items="orders_statusesArray"
                    :menu-props="{ maxHeight: '400' }"
                    item-text="orders_status_name"
                    item-value="orders_status_id"
                    multiple
                    prepend-icon="mdi-filter-variant"
                    hide-details
                >
                    <template v-slot:item="{ item }">
                        <span :class="item.orders_status_name">{{ item.orders_status_name }}</span>
                    </template>
                    <template v-slot:selection="{ item, index }">
                        <v-chip
                            x-small
                            v-if="index === 0"
                        >
                            <span>{{ item.orders_status_name }}</span>
                        </v-chip>
                        <span
                            v-if="index === 1"
                            class="green--text caption"
                        >
          (+{{ order_statuses.length - 1 }})
        </span>
                    </template>
                </v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex sm2>
                <v-select
                    dense
                    v-if="currenciesList"
                    v-model="currency"
                    :items="currenciesList"
                    prepend-icon="euro_symbol"
                    hide-details
                    clearable
                ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex sm2>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="dateRangeText"
                            dense
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range no-title>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="success" @click="changeDates(dates)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-spacer></v-spacer>
<!--            <v-checkbox
                v-model="hasDutyDate"
                @change="changeHasDutyDate($event)"
                label="Duty date"
                hide-details
                :indeterminate="indeterminateDutyDate"
                dense
            ></v-checkbox>-->
            <JqxCheckBox
                style="margin-left: 10px;
                float: left;"
                v-model="hasDutyDate"
                :hasThreeStates="true"
                @change="changeHasDutyDate($event)"
                :width="120"
                :height="25">
                <span v-if="hasDutyDate !== null">{{ (hasDutyDate ? "Has duty date" : "Doesn't have duty date") }}</span>
                <span v-else>Any duty date</span>
            </JqxCheckBox>
            <v-spacer></v-spacer>
            <v-btn icon @click="refreshSalesGoodsFlowDataTable" :disabled="isDownload || dates.length <= 1">
                <v-icon color="primary">refresh</v-icon>
            </v-btn>
            <v-badge
                v-if="hasDutyDate === false"
                bordered
                color="success"
                icon="mdi-currency-eur"
                overlap
                light
            >
                <v-btn small icon @click="Download" :disabled="dates.length <= 1">
                    <v-icon color="success">mdi-file-download-outline</v-icon>
                </v-btn>
            </v-badge>
            <v-btn v-else small icon @click="Download" :disabled="dates.length <= 1" style="margin-right: 0px;">
                <v-icon color="success">mdi-file-download-outline</v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon color="grey" @click="massUpdate" :disabled="showMassUpdateDialog">update</v-icon>
            </v-btn>
        </v-toolbar>
<!--        <v-data-table
                :headers="headers"
                :items="order_product"
                :options.sync="options"
                :server-items-length="pagination.totalItems"
                :loading="loading"
                class="elevation-1 width-100"
                dense
                :footer-props="{
                                itemsPerPageOptions: pagination.rowsPerPageItems,
                                showFirstLastPage: true,
                               }"
        >

            <template slot="item.products_id" slot-scope="{ item }">
                <div class="ma-auto" ><a @click.stop="openProductDialog(item)">{{ item.products_id  }}</a></div>
            </template>
            <template slot="item.orders_id" slot-scope="{ item }">
                <span :class="item.order.orders_status_name.orders_status_name">{{item.orders_id}}</span>
            </template>
            <template slot="item.order.date_paid" slot-scope="{ item }">
                <span>{{new Date(item.order.date_paid).toISOString().slice(0, 10)}}</span>
            </template>
            <template slot="item.order.duty_date.duty_date" slot-scope="{ item }">
                <span>{{ (item.order.duty_date ? new Date(item.order.duty_date.duty_date).toISOString().slice(0, 10) : '') }}</span>
            </template>
        </v-data-table>-->
        <v-flex xs12 style="height: 100%">
            <JqxDataTable
                v-if="salesGoodsFlowDataAdapter"
                ref="SalesGoodsFlowDataTable"
                width="100%"
                height="100%"
                :source="salesGoodsFlowDataAdapter"
                :columns="salesGoodsFlowColumns"
                :pageable="false"
                :serverProcessing="true"
                :pagerButtonsCount="2"
                :pageSizeOptions="[25, 50, 100, 150, 200, 500]"
                :pagerMode="'advanced'"
                :altRows="true"
                :filterable="true"
                :filterMode="'default'"
                :sortable="true"
                @rowClick="onRowClickSalesGoodsFlowDataTable($event)"
                :statusBarHeight="25"
                :theme="jqwTheme"
                :localization="localization"
                :showAggregates="true"
                :aggregatesHeight="25"
                :enableBrowserSelection="true"
                :enableHover="false"
            />
        </v-flex>

        <v-dialog v-model="showMassUpdateDialog" persistent width="50%">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>Update selected orders</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showMassUpdateDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text justify-center>
                    <v-row class="mt-2" justify-center>
                        <v-col cols="8">
                            <v-select
                                dense
                                v-if="auction_accounts"
                                v-model="massUpdateAccount"
                                :items="auction_accounts"
                                prepend-icon="account_box"
                                placeholder="Please select an account"
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-layout justify-center>
                                <v-btn :loading="loadingMassUpdate" :disabled="! massUpdateAccount" @click="massUpdateSave">Update</v-btn>
                            </v-layout>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="loadingMassUpdate"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                    Please stand by
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-alert
            :value="alert"
            color="pink"
            dark
            border="left"
            transition="scale-transition"
            dismissible
            prominent
            type="warning"
            position="center"
        >
            Please select the items!
        </v-alert>
    </v-layout>
</template>

<script>
    import JqxDataTable from "jqwidgets-scripts/jqwidgets-vue/vue_jqxdatatable.vue"
    import JqxCheckBox from "jqwidgets-scripts/jqwidgets-vue/vue_jqxcheckbox.vue";
    import EventBus from "@/event-bus";
    import { mapGetters } from 'vuex';

    export default {
        name: "GoodsFlow",
        components: {
            JqxDataTable,
            JqxCheckBox
        },
        data: function () {
            return {
                pagination: {
                    descending: true,
                    page: 1,
                    rowsPerPage: 10,
                    totalItems: 0,
                    rowsPerPageItems: [10, 15, 20, 50, 100, 500]
                },
                localization: {
                    thousandsSeparator: ''
                },
                order_product: [],
                order_statuses: [2,3,6,8],
                loading: false,
                options: {},
                editedItem: {
                    descriptions: {products_description: '', products_name: ''},
                    product_note: {products_note: ''},
                    products_id: null
                },
                productDialog: false,
                menu: false,
                dates: [],
                account: null,
                currency: null,
                filters: {account: [], currency: [], dates: [], order_statuses: [], hasDutyDate: []},
                isDownload: false,
                headers: [
                    {
                        text: 'ID',
                        align: 'center',
                        sortable: true,
                        width: "75px",
                        value: 'products_id',
                    },
                    {
                        text: 'Product',
                        align: 'left',
                        sortable: true,
                        value: 'products_name',
                    },
                    {
                        text: 'Order',
                        align: 'center',
                        sortable: true,
                        width: "75px",
                        value: 'orders_id',
                    },
                    {
                        text: 'Paid',
                        align: 'center',
                        sortable: true,
                        width: "75px",
                        value: 'date_paid',
                    },
                    {
                        text: 'Duty',
                        align: 'center',
                        sortable: false,
                        width: "75px",
                        value: 'order.duty_date.duty_date',
                    },
                    {
                        text: 'Price',
                        align: 'center',
                        sortable: false,
                        width: "75px",
                        class: "pa-1",
                        value: 'products_price',
                    },
                    {
                        text: '',
                        align: 'left',
                        sortable: false,
                        width: "75px",
                        class: "pa-1",
                        value: 'order.order_currency',
                    },
                    {
                        text: 'Check',
                        align: 'center',
                        sortable: false,
                        width: "75px",
                        value: 'check_number',
                    },
                    {
                        text: 'Account',
                        align: 'center',
                        sortable: true,
                        value: 'ebayid',
                    },
                    {
                        text: 'Country',
                        align: 'center',
                        sortable: false,
                        value: 'order.delivery_country',
                    },
                ],
                salesGoodsFlowDataAdapter: null,
                jqwTheme: 'material', //office,
                hasDutyDate: null,
                indeterminateDutyDate: true,
                showMassUpdateDialog: false,
                massUpdateAccount: null,
                loadingMassUpdate: false,
                filer_auction_accounts: [
                    {text: 'leica-post', value: 'leica-post', active: true},
                    {text: 'eu-tradefactor', value: 'eu-tradefactor', active: true},
                    {text: 'camexpres', value: 'camexpres', active: true},
                    {text: 'user13867742', value: 'user13867742', active: false},
                    {text: 'kinoptik', value: 'kinoptik', active: false},
                    //{text: 'aukro.cz-photo-vision', value: 'aukro.cz-photo-vision', active: false},
                    //{text: 'CM', value: 'CM', active: true},
                    //{text: 'ebay.de-camexpres', value: 'ebay.de-camexpres', active: false},
                    //{text: 'ebay.de-leica-post', value: 'ebay.de-leica-post', active: false},
                    //{text: 'ebay.fr-camexpres', value: 'ebay.fr-camexpres', active: false},
                    //{text: 'molotok.ru-trade-partner', value: 'molotok.ru-trade-partner', active: false},
                ],
                alert: false
            }
        },
        watch: {
            options: {
                handler () {
                    //this.getDataFromApi()
                },
                deep: true,
            },
        },
        methods: {
            massUpdate() {
                this.showMassUpdateDialog = true;
            },
            async massUpdateSave() {
                this.showMassUpdateDialog = false;
                if (this.$refs.SalesGoodsFlowDataTable) {
                    let selection = this.$refs.SalesGoodsFlowDataTable.getSelection();
                    if (selection.length) {
                        this.loadingMassUpdate = true
                        let data = selection.map( (currentValue) => {
                            return {
                                'orders_id' : currentValue['orders_id'],
                                'site': this.massUpdateAccount
                            }
                        } )
                        await axios.post('/api/orders/massupdate', data)
                            .then(() => {
                                this.refreshSalesGoodsFlowDataTable()
                                this.loadingMassUpdate = false
                                this.showMassUpdateDialog = false;
                            });

                    } else {
                        this.alert = true;
                        this.showMassUpdateDialog = false;
                    }
                } else {
                    this.alert = true;
                    this.showMassUpdateDialog = false;

                }
            },
            getFilters() {

                if (this.account) {
                    this.filters['account'][`filterGroups[1][field]`]='site';
                    this.filters['account'][`filterGroups[1][filters][0][value]`]=this.account;
                    this.filters['account'][`filterGroups[1][filters][0][condition]`]='CONTAINS';
                    this.filters['account'][`filterGroups[1][filters][0][type]`]='stringfilter';
                    this.filters['account'][`filterGroups[1][filters][0][operator]`]='or';
                    this.filters['account'][`filterGroups[1][operator]`]='and';
                } else this.filters['account'] = []

                if (this.currency) {
                    this.filters['currency'][`filterGroups[2][field]`]='order.currency';
                    this.filters['currency'][`filterGroups[2][filters][0][value]`]=this.currency;
                    this.filters['currency'][`filterGroups[2][filters][0][condition]`]='EQUAL';
                    this.filters['currency'][`filterGroups[2][filters][0][type]`]='stringfilter';
                    this.filters['currency'][`filterGroups[2][filters][0][operator]`]='or';
                    this.filters['currency'][`filterGroups[2][operator]`]='and';
                } else this.filters['currency'] = []

                if (this.dates.length) {
                    this.filters['dates'][`filterGroups[3][field]`]= this.hasDutyDate ? 'order.duty_date.duty_date' : 'date_paid';
                    this.filters['dates'][`filterGroups[3][filters][0][value]`]=this.dates[0]+' 00:00:00';
                    this.filters['dates'][`filterGroups[3][filters][0][condition]`]='GREATER_THAN_OR_EQUAL';
                    this.filters['dates'][`filterGroups[3][filters][0][type]`]='stringfilter';
                    this.filters['dates'][`filterGroups[3][filters][0][operator]`]='and';
                    this.filters['dates'][`filterGroups[3][filters][1][value]`]=this.dates[1]+' 23:59:59';
                    this.filters['dates'][`filterGroups[3][filters][1][condition]`]='LESS_THAN_OR_EQUAL';
                    this.filters['dates'][`filterGroups[3][filters][1][type]`]='stringfilter';
                    this.filters['dates'][`filterGroups[3][filters][1][operator]`]='and';
                    this.filters['dates'][`filterGroups[3][operator]`]='and';
                } else this.filters['dates'] = []

                if (this.order_statuses.length) {
                    this.filters['order_statuses'][`filterGroups[4][field]`]='order.orders_status';
                    this.filters['order_statuses'][`filterGroups[4][filters][0][value]`]=this.order_statuses;
                    this.filters['order_statuses'][`filterGroups[4][filters][0][condition]`]='IN';
                    this.filters['order_statuses'][`filterGroups[4][filters][0][type]`]='stringfilter';
                    this.filters['order_statuses'][`filterGroups[4][filters][0][operator]`]='or';
                    this.filters['order_statuses'][`filterGroups[4][operator]`]='and';
                } else this.filters['order_statuses'] = []

                if (this.hasDutyDate === true) {
                    this.filters['hasDutyDate'][`filterGroups[5][field]`]='order.duty_date';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][value]`]='true';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][condition]`]='NOT_EMPTY';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][type]`]='stringfilter';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][operator]`]='or';
                    this.filters['hasDutyDate'][`filterGroups[5][operator]`]='and';
                } else if (this.hasDutyDate === false)  {
                    this.filters['hasDutyDate'][`filterGroups[5][field]`]='order.duty_date';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][value]`]='true';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][condition]`]='EMPTY';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][type]`]='stringfilter';
                    this.filters['hasDutyDate'][`filterGroups[5][filters][0][operator]`]='or';
                    this.filters['hasDutyDate'][`filterGroups[5][operator]`]='and';
                } else {
                    this.filters['hasDutyDate'] = []
                }

                return new URLSearchParams({...this.filters['account'], ...this.filters['currency'], ...this.filters['dates'], ...this.filters['order_statuses'], ...this.filters['hasDutyDate']}).toString();
            },
            changeHasDutyDate(event) {
                if (this.hasDutyDate === false && this.indeterminateDutyDate === false) {
                    this.indeterminateDutyDate = true
                } else {
                    this.indeterminateDutyDate = false
                }
            },
            Download() {
                this.loading = true;
                axios({
                    url: this.getSalesGoodsFlowUrl()+'&download='+((this.hasDutyDate === false) ? 'VAT' : 'All'),
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    EventBus.$emit('openNotification', 'Export completed successfully!!!', { template: 'success'});
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', ((this.hasDutyDate === false) ? 'VAT' : '')+`SalesGoReport-${this.$moment().format("DD-MM-YYYY hh-mm-ss")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;

                }).catch(error => { console.log(error)});
            },
            async getDataFromApi () {
                if (!this.account && !this.currency && !this.dates.length) {
                    this.order_product = [];
                    return;
                }
                this.loading = true;

                const searchParam = {
                    'pagesize':this.options.itemsPerPage,
                    'current_page':this.options.page,
                    'sortdatafield': this.options.sortBy[0],
                    'sortorder': (this.options.sortDesc[0] ? 'desc' : 'asc'),
                };

                if (this.isDownload) {
                    let isDownload = [];
                    isDownload['download'] = 1;
                    if (this.dates.length) {
                        let date1 = new Date(this.dates[0]);
                        let date2 = new Date(this.dates[1]);
                        let diffDays = Math.abs(parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10));
                        if (diffDays < 366) {
                            axios({
                                url: 'api/goods_flow_sales',
                                method: 'GET',
                                params: {...searchParam, ...this.filters[0], ...this.filters[1], ...this.filters[2], ...isDownload},
                                responseType: 'blob', // important
                            }).then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'file.xlsx');
                                document.body.appendChild(link);
                                link.click();
                            }).catch(error => { console.log(error)});
                        } else {
                            alert('There is so long diapason:'+diffDays+'days');
                        }

                    }
                } else {
                    await axios.get('api/goods_flow_sales',
                        { params: {...searchParam, ...this.filters[0], ...this.filters[1], ...this.filters[2]},
                        })
                        .then(response => {
                            this.loading = false;
                            this.order_product = response.data.data;
                            this.pagination.totalItems = response.data.total
                        })
                        .catch(error => { console.log(error)})
                }
            },
            changeDates(dates) {
                let range = [];
                range[0] = (dates[0] <= dates[1] ? dates[0] : dates[1] );
                range[1] = (dates[0] >= dates[1] ? dates[0] : dates[1] );
                this.$refs.menu.save(dates);
            },
            closeProductDialog() {
                this.productDialog = false;
            },
            getSalesGoodsFlowUrl() {
                return `api/goods_flow_sales?${this.getFilters()}`
            },
            getSalesGoodsFlowDataAdapter() {

                let salesGoodsFlowSource = {
                    pagesize: 25,
                    id: 'orders_products_id',
                    url: this.getSalesGoodsFlowUrl(),
                    root: 'data',
                    datatype: "json",
                    sortcolumn: 'orders.date_paid',
                    sortdirection: 'asc',
                    totalrecords: 0,
                    //async: false,
                    datafields: [
                        { name: 'orders_products_id', type: 'int' },
                        { name: 'orders_products.products_id', type: 'int', map: 'products_id' },
                        { name: 'products_name', type: 'string', map: 'products_name' },
                        { name: 'site', type: 'string', map: 'site' },
                        { name: 'orders_id', type: 'int' },
                        { name: 'products_quantity', type: 'int' },
                        { name: 'products_name', type: 'string' },
                        { name: 'OrderID', type: 'string', map: 'order>OrderID' },
                        { name: 'final_price', type: 'float' },
                        { name: 'order_ebayfees', type: 'string', map: 'order>order_ebayfees>text' },
                        { name: 'order_ebaytotal', type: 'string', map: 'order>order_ebaytotal>text' },
                        { name: 'order_total', type: 'float', map: 'margin>Total_price' },
                        { name: 'currency', type: 'string', map: 'order>order_currency' },
                        { name: 'status', type: 'string', map: 'order>orders_status_name>orders_status_name' },
                        { name: 'orders.PayPalTransactionID', type: 'string', map: 'order>PayPalTransactionID' },
                        { name: 'order.date_purchased', type: 'date', map: 'order>date_purchased' },
                        { name: 'orders.date_paid', type: 'date', map: 'order>date_paid' },
                        { name: 'order.duty_date', type: 'date', map: 'order>duty_date>duty_date' },
                        { name: 'product_check>0>products_check', type: 'int', map: 'product_check>0>check>check_number' },
                        { name: 'product_check>0>check_last_modified', type: 'date', map: 'product_check>0>check_last_modified' },
                        { name: 'check_date', type: 'date', map: 'product_check>0>check>check_date' },
                        { name: 'product_check>0>c_currency', type: 'string', map: 'product_check>0>c_currency' },
                        { name: 'product_check>0>c_purchase_price', type: 'float', map: 'product_check>0>c_purchase_price' },
                        { name: 'product_check>0>duty_currency', type: 'string', map: 'product_check>0>duty_currency' },
                        { name: 'product_check>0>duty_price', type: 'float', map: 'product_check>0>duty_price' },
                        { name: 'products_checks_id', type: 'int', map: 'product_check>0>id' },
                        { name: 'delivery_address_country', type: 'int', map: 'order>delivery_address_country>countries_name' },
                        { name: 'geo_zone', type: 'int', map: 'order>delivery_address_country>geo_zone>0>geo_zone_name' },
                        { name: 'profit', type: 'float'},
                        { name: 'DPH', type: 'float'},
                    ],
                    beforeprocessing: function (data) {
                        salesGoodsFlowSource.totalrecords = data.total;
                    },
                };
                this.salesGoodsFlowDataAdapter = new jqx.dataAdapter(salesGoodsFlowSource ,
                    {
                        formatData: function (data) {
                            data.page = data.pagenum+1;
                            return data;
                        }
                    })
            },
            refreshSalesGoodsFlowDataTable() {
                if (this.$refs.SalesGoodsFlowDataTable) {
                    this.getSalesGoodsFlowDataAdapter()
                    this.$refs.SalesGoodsFlowDataTable.source = this.salesGoodsFlowDataAdapter;
                    this.$refs.SalesGoodsFlowDataTable.refresh()
                } else {
                    this.getSalesGoodsFlowDataAdapter()
                }
            },
            onRowClickSalesGoodsFlowDataTable(event) {
                if (event.args.dataField === "orders_products.products_id") {
                    this.openProductDialog({
                        products_id: event.args.row['orders_products.products_id'],
                        descriptions: {products_name: event.args.row.products_name}
                    })

                } else if (event.args.dataField === "orders_id") {
                    this.openOrderDialog(event.args.row['orders_id'])
                }
            },
            openOrderDialog(orderId) {
                EventBus.$emit('openOrderDialog', orderId);
            },
            openProductDialog(item) {
                EventBus.$emit('openProductDialog', item);
            },
        },
        computed: {
            isSelectedRows() {
                if (salesGoodsFlowDataAdapter !== null) {
                    let selected = this.$refs.SalesGoodsFlowDataTable.getSelection();
                    return selected.length ? true : false
                } else {
                    return false
                }
            },
            orders_statusesArray() {
                return Object.values(this.orders_statusesList)
            },
            salesGoodsFlowColumns() {
                return [
                    { text: 'ID', dataField: 'orders_products.products_id', align: 'center', width: 50,
                        cellClassName: function (row, column, value, data) {
                            return (data['products_checks_id'] === undefined ? 'pointer underline Paid' : 'pointer underline');
                        }
                    },
                    { text: 'Product', dataField: 'products_name', align: 'center', aggregates: ['count'],
                        cellClassName: function (row, column, value, data) {
                            return ((data['order.duty_date'] === null && data['geo_zone'] === undefined)? 'Paid' : '');
                        }
                    },
                    { text: 'Order', dataField: 'orders_id', align: 'center', cellsAlign: 'center', width: '50px',
                        cellClassName: function (row, column, value, data) {
                            return 'pointer underline'+' '+data.status;
                        }
                    },
                    { text: 'Paid', dataField: 'orders.date_paid', align: 'center', cellsAlign: 'center', width: '80px', filterable: false, sortable: true, cellsFormat: 'dd MMM yy'},
                    { text: 'Duty date', dataField: 'order.duty_date', align: 'center', cellsAlign: 'center', width: '80px', filterable: false, sortable: false, cellsFormat: 'dd MMM yy'},
                    { text: 'Z', dataField: 'geo_zone', align: 'center', cellsAlign: 'center', width: '20px', filterable: false, sortable: false},
                    { text: 'Q', dataField: 'products_quantity', align: 'center', cellsAlign: 'center', width: '20px', filterable: false, sortable: false},
                    { text: 'Payment', dataField: 'orders.PayPalTransactionID', align: 'center', cellsAlign: 'center', width: '150px', filterable: false, sortable: true, cellsRenderer: (row, column, value, rowData) => {
                            //console.log(rowData)
                            if (value) {
                                return value.toString()
                            } else {
                                //return ''
                                return rowData.OrderID+'<br/>Fee:'+rowData.order_ebayfees+'<br/>Total:'+rowData.order_ebaytotal
                            }
                        }
                    },
                    { text: 'Price', dataField: 'final_price', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.currency
                        }
                    },
                    { text: 'Total', dataField: 'order_total', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.currency
                        }
                    },
                    { text: 'Check', dataField: 'product_check>0>products_check', align: 'center', cellsAlign: 'center', width: '75px', filterable: false, sortable: false},
                    { text: 'Check date', dataField: 'check_date', align: 'center', cellsAlign: 'center', width: '90px', filterable: false, sortable: false, cellsFormat: 'dd MMM yy'},
                    { text: 'Check price', dataField: 'product_check>0>c_purchase_price', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            if (rowData.products_checks_id !== undefined) return value+' '+rowData['product_check>0>c_currency']
                            else return '&nbsp;'
                        }
                    },
                    { text: 'Duty', dataField: 'product_check>0>duty_price', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            if (rowData.products_checks_id !== undefined) return value+' '+rowData['product_check>0>duty_currency']
                            else return '&nbsp;'
                        }
                    },
                    { text: 'Profit', dataField: 'profit', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellClassName: function (row, column, value, data) {
                            if (value < 0) {
                                return 'font-weight-black red--text text--lighten-1'
                            } else {
                                return 'font-weight-black'
                            }
                        },
                        aggregates:
                        [{
                            'Total': (aggregatedValue, currentValue, column, record) => {
                                return aggregatedValue + currentValue;
                            }
                        }],
                            aggregatesRenderer: (aggregates, column, element) => {
                            let renderString = '<div style="margin: 4px; float: right;  height: 100%;">';
                            renderString += aggregates.Total + '</div>';
                            return renderString;
                        }
                    },
                    { text: 'DPH', dataField: 'DPH', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        aggregates:
                            [{
                                'Total': (aggregatedValue, currentValue, column, record) => {
                                    return aggregatedValue + currentValue;
                                }
                            }],
                        aggregatesRenderer: (aggregates, column, element) => {
                            let renderString = '<div style="margin: 4px; float: right;  height: 100%;">';
                            renderString += aggregates.Total + '</div>';
                            return renderString;
                        }
                    },
                    { text: 'Account', dataField: 'site', align: 'center'},
                ]
            },
            dateRangeText () {
                let range = [];
                range[0] = (this.dates[0] <= this.dates[1] ? this.dates[0] : this.dates[1] );
                range[1] = (this.dates[0] >= this.dates[1] ? this.dates[0] : this.dates[1] );
                return range.join(' ~ ')
            },
            ...mapGetters([
                'auction_accounts',
                'currenciesList',
                'orders_statusesList'
            ]),
        }
    }
</script>

<style scoped>

</style>

<style>
    #SalesGoodsFlow .jqx-tabs-bar {
        display: none !important;
    }

    #SalesGoodsFlow .pointer:hover {
        cursor: pointer;
    }

    #SalesGoodsFlow .underline {
        text-decoration: underline;
    }

</style>