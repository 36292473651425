<template>
    <v-form ref="shipmentLabel" v-model="labelForm">
        <v-row class="justify-center mt-4" no-gutters>
            <v-col class="pa-0 pr-sm-2" cols="12" sm="4">
                <v-select
                        v-model="ship.serviceType"
                        :hide-details=true
                        :items="serviceTypes"
                        class="text-body-2"
                        dense
                >
                    <div
                            slot="prepend"
                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                            style="width: 55px;"
                    >
                        Service:
                    </div>
                </v-select>
            </v-col>
            <v-col class="pa-0" cols="12" sm="4">
                <v-select
                        v-model="ship.dropOff"
                        :hide-details=true
                        :items="dropOffTypes"
                        class="text-body-2"
                        dense
                >
                    <div
                            slot="prepend"
                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                            style="width: 55px;"
                    >
                        Drop Off:
                    </div>
                </v-select>
            </v-col>
            <v-col class="pa-0" cols="12" sm="4">
                <v-select
                        v-model="ship.fedexAccount"
                        :hide-details=true
                        :items="fedexAccounts"
                        class="text-body-2"
                        dense
                >
                    <div
                            slot="prepend"
                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                            style="width: 55px;"
                    >
                        Account:
                    </div>
                </v-select>
            </v-col>
        </v-row>

        <v-subheader class="justify-center font-weight-black ma-0" style="height: 30px;">Recipient</v-subheader>
        <v-sheet
                class="pa-2"
                color="blue-grey lighten-5"
                elevation="2"
        >
            <v-row class="justify-center mt-0" no-gutters>
                <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                    <v-text-field v-model="ship.delivery_name"
                                  :hide-details=true
                                  :rules="rules.required"
                                  class="text-body-2 full-width"
                                  color="blue darken-2"
                                  dense
                                  placeholder="enter delivery name"
                    >
                        <div
                                slot="prepend"
                                class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                style="width: 50px;"
                        >
                            Person:
                        </div>
                    </v-text-field>
                    <v-text-field v-model="ship.street"
                                  :hide-details=true
                                  :rules="rules.required"
                                  class="text-body-2 full-width mt-4"
                                  color="blue darken-2"
                                  dense
                                  placeholder="enter delivery street address"
                    >
                        <div
                                slot="prepend"
                                class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                style="width: 50px;"
                        >
                            Street:
                        </div>
                    </v-text-field>
                    <v-text-field v-model="ship.customers_telephone"
                                  :hide-details=true
                                  class="text-body-2 full-width mt-4"
                                  color="blue darken-2"
                                  dense
                                  placeholder="Enter Phone"
                    >
                        <div
                                slot="prepend"
                                class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                style="width: 50px;"
                        >
                            Phone:
                        </div>
                    </v-text-field>
                </v-col>
                <v-col class="pa-0" cols="12" sm="6">
                    <v-text-field v-model="ship.delivery_company"
                                  :hide-details=true
                                  class="text-body-2 full-width"
                                  color="blue darken-2"
                                  dense
                                  placeholder="enter delivery company"
                    >
                        <div
                                slot="prepend"
                                class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                style="width: 60px;"
                        >
                            Company:
                        </div>
                    </v-text-field>
                    <v-row class="mx-sm-1 pa-0 mt-3" justify="space-between">
                        <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                            <v-text-field v-model="ship.delivery_city"
                                          :hide-details=true
                                          :rules="rules.required"
                                          class="text-body-2 full-width"
                                          color="blue darken-2"
                                          dense
                                          placeholder="enter delivery city"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 50px;"
                                >
                                    City:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                            <v-text-field v-model="ship.delivery_state"
                                          :hide-details=true
                                          class="text-body-2 full-width"
                                          color="blue darken-2"
                                          dense
                                          placeholder="enter delivery state"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 55px;"
                                >
                                    Province:
                                </div>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-sm-1 pa-0 mt-3" justify="space-between">
                        <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                            <v-text-field v-model="ship.delivery_postcode"
                                          :hide-details=true
                                          :rules="rules.required"
                                          class="text-body-2 full-width mt-4"
                                          color="blue darken-2"
                                          dense
                                          placeholder="enter delivery postcode"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 50px;"
                                >
                                    Postal:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                            <v-autocomplete v-model="ship.delivery_country"
                                            :hide-details=true
                                            :items="countries"
                                            :rules="rules.required"
                                            class="text-body-2 full-width mt-4"
                                            dense
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 55px;"
                                >
                                    Country:
                                </div>
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-sheet>
        <v-subheader class="justify-center font-weight-black ma-0" style="height: 30px;">Commodities</v-subheader>
        <v-sheet
                class="pa-2"
                color="brown lighten-5"
                elevation="2"
        >
            <v-row v-for="(product, index) in ship.order_products" v-if="ship.order_products.length" :key="product.products_id"
                   class="justify-center my-1 mx-1"
                   no-gutters>
                <v-row>
                    <v-col class="pa-0 pr-sm-2" cols="12" sm="6">
                        <v-row class="mx-sm-1 pa-0 mt-1" justify="space-between">
                            <v-col class="mt-0 pa-0 pr-sm-2" cols="6">
                                <v-select
                                        v-model="product.description"
                                        :hide-details=true
                                        :items="descriptionTypes"
                                        class="text-body-2 full-width"
                                        dense
                                >
                                </v-select>
                            </v-col>
                            <v-col class="mt-0 pa-0 pr-sm-2" cols="6">
                                <v-text-field v-model="product.products_name"
                                              :hide-details=true
                                              class="text-body-2 full-width"
                                              color="blue darken-2"
                                              dense
                                              placeholder="enter delivery postcode"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--                    <v-row justify="space-between" class="mx-sm-1 pa-0 mt-1">
                                                <v-col cols="12" sm="3" class="mt-3 pa-0 pr-sm-2">
                                                    <v-select
                                                        :items="countriesManufacture"
                                                        v-model="product.countryManufacture"
                                                        dense
                                                        class="text-body-2 full-width"
                                                        :hide-details=true
                                                        height="32px"
                                                    >
                                                        <div
                                                            slot="prepend"
                                                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                                            style="width: 45px;"
                                                        >
                                                            Origin:
                                                        </div>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" sm="3" class="pa-0 pr-sm-2">
                                                    <v-text-field
                                                        v-model="product.quantity"
                                                        type="number"
                                                        readonly
                                                        :hide-details=true
                                                        class="text-body-2 right-input"
                                                        style="display: none;"
                                                    >
                                                        <div
                                                            slot="prepend"
                                                            class="text-body-2 pa-0 text-no-wrap"
                                                            style="width: 15px;"
                                                        >
                                                            Q:
                                                        </div>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3" class="pa-0 pr-sm-2">
                                                    <v-text-field
                                                        v-model="product.pieces"
                                                        type="number"
                                                        readonly
                                                        :hide-details=true
                                                        class="text-body-2 right-input"
                                                        style="display: none;"
                                                    >
                                                        <div
                                                            slot="prepend"
                                                            class="text-body-2 pa-0 text-no-wrap"
                                                            style="width: 15px;"
                                                        >
                                                            P:
                                                        </div>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>-->
                    </v-col>
                    <v-col class="pa-0" cols="12" sm="6">
                        <v-row class="mx-sm-1 pa-0 mt-0" justify="space-between">
                            <v-col class="mt-0 pa-0 pr-sm-2" cols="4">
                                <v-text-field
                                        v-model="product.custom_price"
                                        :hide-details=true
                                        :rules="rules.required"
                                        :suffix="ship.currency"
                                        class="text-body-2 right-input pt-0"
                                        type="number"
                                        @input="countTotal('custom_price')"
                                >
                                    <div
                                            slot="prepend"
                                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                            style="width: 40px;"
                                    >
                                        Price:
                                    </div>
                                </v-text-field>
                            </v-col>
                            <v-col class="mt-0 pa-0" cols="4">
                                <v-text-field
                                        v-model="product.insured_price"
                                        :hide-details=true
                                        :suffix="ship.currency"
                                        class="text-body-2 right-input pt-0"
                                        type="number"
                                        @input="countTotal('insured_price')"
                                >
                                    <div
                                            slot="prepend"
                                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                            style="width: 45px;"
                                    >
                                        Insured:
                                    </div>
                                </v-text-field>
                            </v-col>
                            <v-col class="mt-0 pa-0" cols="4" sm="3">
                                <v-text-field
                                        v-model="product.weight"
                                        :hide-details=true
                                        class="text-body-2 right-input  pt-0"
                                        suffix="KG"
                                        @input="countTotal('weight')"
                                >
                                    <div
                                            slot="prepend"
                                            class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                            style="width: 45px;"
                                    >
                                        Weight:
                                    </div>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider v-if="index != ship.order_products.length - 1" class="my-2 pt-0 pl-1"></v-divider>
                    </v-col>
                </v-row>
            </v-row>
        </v-sheet>
        <v-subheader class="justify-center font-weight-black mt-2" style="height: 30px;">Package</v-subheader>
        <v-sheet
                class="pa-2"
                color="deep-orange lighten-5"
                elevation="2"
        >
            <v-row class="justify-center mt-4" no-gutters>
                <v-col class="pa-0 ma-0 pr-sm-2" cols="12" sm="6">
                    <v-row class="mx-sm-1" justify="space-between">
                        <v-col class="pa-0 pr-sm-2" cols="4">
                            <v-text-field v-model="ship.total_custom_price"
                                          :hide-details=true
                                          :suffix="ship.currency"
                                          class="text-body-2 full-width right-input"
                                          color="blue darken-2"
                                          dense
                                          readonly
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 55px;"
                                >
                                    Customs:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="pa-0 pr-sm-2" cols="4">
                            <v-text-field v-model="ship.total_insured_price"
                                          :hide-details=true
                                          :suffix="ship.currency"
                                          class="text-body-2 full-width right-input"
                                          color="blue darken-2"
                                          dense
                                          readonly
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 55px;"
                                >
                                    Insured:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="pa-0" cols="4">
                            <v-text-field v-model="ship.total_weight"
                                          :hide-details=true
                                          :rules="rules.required"
                                          class="text-body-2 full-width right-input"
                                          color="blue darken-2"
                                          dense
                                          readonly
                                          suffix="KG"
                                          type="number"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 55px;"
                                >
                                    Weight:
                                </div>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="my-0 mx-sm-1" justify="space-between" style="margin-top: 8px !important;">
                        <v-col class="pa-0 my-0 mt-1" cols="12">
                            <v-select v-model="ship.signature"
                                      :items="signaturetypes"
                                      class="text-body-2 mt-4"
                                      dense
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 75px;"
                                >
                                    Signature:
                                </div>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0" cols="12" sm="6">
                    <v-row class="mx-sm-1" justify="space-between">
                        <v-col class="pa-0 mt-1" cols="12">
                            <v-select v-model="ship.packtype"
                                      :hide-details=true
                                      :items="packtypes"
                                      class="text-body-2 ma-0 pa-0"
                                      dense
                                      @change="isValid"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 75px;"
                                >
                                    Packaging:
                                </div>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="ship.packtype === 'YOUR_PACKAGING' " class="mx-sm-1 mt-3" justify="space-between"
                           no-gutters>
                        <v-col class="mt-3 pa-0 pr-sm-2" cols="3">
                            <v-text-field v-model="ship.length"
                                          :hide-details=true
                                          :rules="[packingRules.required]"
                                          class="text-body-2 right-input"
                                          dense
                                          type="number"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 25px;"
                                >
                                    L:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-3 pa-0 pr-sm-2" cols="3">
                            <v-text-field v-model="ship.width"
                                          :hide-details=true
                                          :rules="[packingRules.required]"
                                          class="text-body-2 right-input"
                                          dense
                                          type="number"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 25px;"
                                >
                                    W:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-3 pa-0" cols="3">
                            <v-text-field v-model="ship.height"
                                          :hide-details=true
                                          :rules="[packingRules.required]"
                                          class="text-body-2 right-input"
                                          dense
                                          type="number"
                            >
                                <div
                                        slot="prepend"
                                        class="text-body-2 pa-0 font-weight-medium text-no-wrap"
                                        style="width: 25px;"
                                >
                                    H:
                                </div>
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-3 pa-0" cols="3">
                            <v-select v-model="ship.units"
                                      :items="units"
                                      class="text-body-2"
                                      color="blue darken-2"
                                      dense
                                      disabled
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>

        <v-divider/>
        <v-sheet
                class="pa-2"
                color="yellow lighten-5"
                elevation="2"
        >
            <v-row justify="center">
                <v-col cols="3">
                    <v-switch
                            v-model="ship.return"
                            class="ma-0 pa-0"
                            dense
                            flat
                            hide-details
                            label="Return label"
                    ></v-switch>
                </v-col>
                <v-col>
                    <v-btn :loading="creatingRequested" @click="getRate">
                        <span>Get {{ (ship.return ? 'return' : '') }} shipping cost</span>
                    </v-btn>
                    <v-btn :loading="creatingRequested" color="primary" @click="getShippingLable">
                        <span>Generate {{ (ship.return ? 'return' : '') }} shipping lable</span>
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                            v-model="enableETD"
                            color="orange darken-3"
                            dense
                            label="ETD"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-sheet>
        <!--        <transition name="fade" mode="out-in" appear>
                    <v-alert dismissible  v-model="alert" :color="type" elevation="2" >
                        {{ onSaveText }}
                    </v-alert>
                </transition>-->

        <!--        <v-row no-gutters v-show="shippngcost">
                    <v-col cols="12">
                        <span v-html="shippngcost"></span>
                    </v-col>
                </v-row>-->

        <v-dialog
                v-model="alert"
                persistent
                scrollable
                width="70vw"
        >
            <v-card flat>
                <v-card-title :color="type" class="ma-0 pa-0">
                    <v-toolbar :color="type+' darken-3'" dark dense>
                        <v-toolbar-title>Attention!!!</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark icon @click="alert = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-2" style="max-height: 50vh;" v-html="onSaveText">
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-form>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "FedexShipmentForm",
    data() {
        return {
            ship: {
                serviceType: 'INTERNATIONAL_ECONOMY',
                dropOff: 'REGULAR_PICKUP',
                delivery_name: null,
                delivery_city: null,
                customers_telephone: null,
                street: null,
                delivery_company: null,
                delivery_state: null,
                delivery_postcode: null,
                delivery_country: null,
                order_products: [],
                currency: null,
                total_custom_price: 0,
                total_insured_price: null,
                total_weight: 0,
                unitsw: 'KG',
                units: "CM",
                packtype: 'YOUR_PACKAGING',
                length: null,
                width: null,
                height: null,
                signature: '',
                return: false,
                fedexAccount: "338446179"
            },
            rules: {
                required: [value => !!value || ''],
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
            packingRules: {
                required: value => {
                    if (this.ship.packtype === 'YOUR_PACKAGING') {
                        return !!value || ''
                    }
                    return true
                }
            },
            labelForm: true,
            fedexAccounts: [
                {text: 'Main (338446179)', value: "338446179"},
                {text: 'Small (202096841)', value: "202096841"},
            ],
            serviceTypes: [
                {text: 'International Priority', value: "FEDEX_INTERNATIONAL_PRIORITY"},
                {text: 'International Economy', value: 'INTERNATIONAL_ECONOMY'},
                {text: 'Priority Overnight', value: 'PRIORITY_OVERNIGHT'},
                {text: 'Regional Economy', value: 'FEDEX_REGIONAL_ECONOMY'}
            ],
            dropOffTypes: [
                {text: 'Regular Pickup', value: 'REGULAR_PICKUP'},
                {text: 'Request Courier', value: 'REQUEST_COURIER'}
            ],
            descriptionTypes: [
                {text: 'Old used', value: 'Old used'},
                {text: 'Old used Lens', value: 'Old used Lens'},
                {text: 'Old used Cameras', value: 'Old used Cameras'},
                {text: 'Old used Accessories', value: 'Old used Accessories'},
            ],
            countriesManufacture: [
                "DE",
                "CH"
            ],
            currencies: [
                "EUR",
                "CZK"
            ],
            units: [
                "CM"
            ],
            packtypes: [
                {value: 'YOUR_PACKAGING', text: "Your Packaging"},
                {value: 'FEDEX_PAK', text: "FedEx Pak"},
            ],
            unitsw: [
                "KG",
                "lbs"
            ],
            signaturetypes: [
                {value: '', text: "None specified"},
                {value: 'DIRECT', text: "Direct signature required"},
                {value: 'ADULT', text: "Adult signature required"},
            ],
            onSaveText: '',
            alert: false,
            type: 'success',
            creatingRequested: false,
            shippngcost: null,
            enableETD: true
        }
    },
    computed: {
        countries() {
            return _.map(this.countriesList, 'countries_name')
        },
        ...mapGetters([
            'order',
            'countriesList',
        ]),
    },
    mounted() {
        if (this.order) {
            const fromOrder = {
                delivery_name: this.order.delivery_name,
                delivery_city: this.order.delivery_city,
                customers_telephone: this.order.customers_telephone,
                street: this.order.delivery_street_address,
                delivery_company: null,
                delivery_state: this.order.delivery_state,
                delivery_postcode: this.order.delivery_postcode,
                delivery_country: this.order.delivery_country,
                currency: this.order.currency,
                return: (this.order.return ? this.order.return : false)
            }
            this.ship = {...this.ship, ...fromOrder}
            let self = this
            this.order.order_products.forEach(function (product) {
                self.ship.order_products.push(
                    {
                        orders_products_id: product.orders_products_id,
                        products_id: product.products_id,
                        products_name: '/' + product.products_id + '/' + product.products_name,
                        description: '',
                        quantity: product.products_quantity,
                        countryManufacture: "DE",
                        pieces: 1,
                        weight: product.product.products_weight,
                        custom_price: product.products_price * product.products_quantity,
                        insured_price: 0
                    }
                )

                self.ship.total_custom_price += product.products_price * product.products_quantity
                if (product.products_insured_price) self.ship.total_insured_price += product.products_insured_price
                self.ship.total_weight += product.product.products_weight
            })

            this.$refs.shipmentLabel.validate()
        }
    },
    methods: {
        isValid() {
            this.$refs.shipmentLabel.validate()
        },
        getShippingLable() {

            if (this.$refs.shipmentLabel.validate()) {
                this.creatingRequested = true
                this.ship.type = 'ship'
                this.ship.enableETD = this.enableETD
                axios.post('/api/orders/fedex/' + this.order.orders_id, this.ship)
                    .then(response => {
                        if (response.data.labels) {
                            //this.showAlert('Label is created', 'success')
                            this.type = ''
                            this.$emit('refresh')
                        } else {
                            this.onSaveText = response.data
                            this.alert = true
                            this.type = 'warning'
                        }
                    }).finally(() => {
                    this.creatingRequested = false
                })
            } else {
                this.showAlert('Fill all fields', 'warning')
                this.creatingRequested = false
            }

        },
        getRate() {
            if (this.$refs.shipmentLabel.validate()) {
                this.creatingRequested = true
                this.ship.type = 'rate'
                this.shippngcost = ''
                axios.post('/api/orders/fedex/' + this.order.orders_id, this.ship)
                    .then(response => {
                        if (response.status === 200) {
                            this.shippngcost = response.data.html
                            this.onSaveText = response.data.html
                            this.type = 'info'
                            this.alert = true
                        } else {
                            this.showAlert('Somsing is wrong!!!', 'success')
                        }
                    }).finally(() => {
                    this.creatingRequested = false
                })
            } else {
                this.showAlert('Fill all fields', 'warning')
            }
        },
        showAlert(text, type) {
            this.onSaveText = text
            this.type = type
            this.alert = true
        },
        countTotal(prop) {
            this.ship[`total_${prop}`] = 0
            this.ship.order_products.forEach((product) => {
                if (product[prop])
                    this.ship[`total_${prop}`] += parseFloat(product[prop])
            })
        }
    }
}
</script>

<style>
.centered-input input {
    text-align: center
}

.right-input input {
    text-align: right;
}
</style>