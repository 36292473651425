const products = {
    namespaced: true,
    state:{
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    }
}

export default products;