var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass:
                        "grey lighten-4 justify-start font-weight-black"
                    },
                    [
                      _vm._v(
                        "\n                    PURCHASES:\n                    "
                      ),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            text: "",
                            icon: "",
                            color: "default"
                          },
                          on: { click: _vm.openAddDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-plus-thick")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            text: "",
                            icon: "",
                            color: "default"
                          },
                          on: { click: _vm.openAddToCheckkDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-note-plus-outline")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            text: "",
                            icon: "",
                            color: "default"
                          },
                          on: { click: _vm.initInflow }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-cached")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1 check-purchase-table",
                    attrs: {
                      headers: _vm.inflow.headers,
                      items: _vm.inflow.data,
                      "hide-default-footer": _vm.toggleFooter(_vm.inflow.data),
                      "disable-sort": "",
                      dense: "",
                      loading: _vm.loadingInflow,
                      "loading-text": "Loading... Please wait"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "font-weight-black" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(props.item.id) +
                                      "\n                                "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isCheckSelected(
                                            props.item.id
                                          ),
                                          expression:
                                            "isCheckSelected(props.item.id)"
                                        }
                                      ],
                                      attrs: {
                                        color: "green",
                                        dark: "",
                                        small: ""
                                      }
                                    },
                                    [_vm._v("check")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                props.item.check
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                    #" +
                                          _vm._s(
                                            props.item.check.check_number
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm
                                              .$moment(
                                                props.item.check.check_date
                                              )
                                              .format("YYYY-MM-DD")
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                props.item.check_last_modified
                                  ? _c(
                                      "div",
                                      { staticClass: "date-field" },
                                      [
                                        _c("datepicker", {
                                          staticClass:
                                            "caption font-weight-regular mt-0 mb-1",
                                          attrs: { "hide-details": "" },
                                          on: {
                                            input: function($event) {
                                              return _vm.updateCheck(props.item)
                                            }
                                          },
                                          model: {
                                            value:
                                              props.item.check_last_modified,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "check_last_modified",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "props.item.check_last_modified"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-edit-dialog",
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(props.item.products_quantity) +
                                          "\n                                    "
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          slot: "input",
                                          type: "number"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateCheck(props.item)
                                          }
                                        },
                                        slot: "input",
                                        model: {
                                          value: props.item.products_quantity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              props.item,
                                              "products_quantity",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "props.item.products_quantity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-baseline"
                                  },
                                  [
                                    _c(
                                      "v-edit-dialog",
                                      [
                                        _c("span", { staticClass: "pr-3" }, [
                                          _vm._v(
                                            _vm._s(props.item.products_price)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("v-text-field", {
                                          attrs: {
                                            slot: "input",
                                            type: "number",
                                            step: "0.01"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.updateCheck(props.item)
                                            }
                                          },
                                          slot: "input",
                                          model: {
                                            value: props.item.products_price,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "products_price",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "props.item.products_price"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-baseline"
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass:
                                        "caption font-weight-regular currency-select mt-0 mb-1 pa-0",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        items: _vm.currencies
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateCheck(props.item)
                                        }
                                      },
                                      model: {
                                        value: props.item.currencies_code,
                                        callback: function($$v) {
                                          _vm.$set(
                                            props.item,
                                            "currencies_code",
                                            $$v
                                          )
                                        },
                                        expression: "props.item.currencies_code"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                props.item.check
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                               " +
                                          _vm._s(props.item.check.ebay_id) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "920", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("New purchase")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formNewPurchase",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.validNewPurchase,
                                callback: function($$v) {
                                  _vm.validNewPurchase = $$v
                                },
                                expression: "validNewPurchase"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("datepicker", {
                                        staticClass: "font-weight-regular",
                                        attrs: {
                                          labelName: "Date",
                                          rules: _vm.rules,
                                          required: "true",
                                          hideDetails: false
                                        },
                                        on: { input: function($event) {} },
                                        model: {
                                          value:
                                            _vm.newInflow.check_last_modified,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newInflow,
                                              "check_last_modified",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newInflow.check_last_modified"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: "Quantity",
                                          rules: _vm.rules,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.newInflow.products_quantity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newInflow,
                                              "products_quantity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newInflow.products_quantity"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: "Purchase Price",
                                          rules: _vm.rules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.newInflow.products_price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newInflow,
                                              "products_price",
                                              $$v
                                            )
                                          },
                                          expression: "newInflow.products_price"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "caption font-weight-regular",
                                        attrs: {
                                          dense: "",
                                          label: "Currency",
                                          items: _vm.currencies,
                                          rules: _vm.rules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.newInflow.currencies_code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newInflow,
                                              "currencies_code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newInflow.currencies_code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeNewPurchase("dialog")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.saveNewPurchase }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "920" },
              model: {
                value: _vm.addToCheckDialog,
                callback: function($$v) {
                  _vm.addToCheckDialog = $$v
                },
                expression: "addToCheckDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Add Product to Check")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("find-check", {
                                    on: { input: _vm.setNewPurchase }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeNewPurchase("addToCheckDialog")
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass:
                        "grey lighten-4 justify-start font-weight-black mt-2"
                    },
                    [
                      _vm._v(
                        "\n                    SALES:\n                    "
                      ),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            text: "",
                            icon: "",
                            color: "default"
                          },
                          on: { click: _vm.initOrders }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-cached")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.orders.headers,
                      items: _vm.orders.data,
                      "hide-default-footer": _vm.toggleFooter(_vm.orders.data),
                      dense: "",
                      loading: _vm.loadingOrders,
                      "loading-text": "Loading... Please wait",
                      "sort-by": ["order.date_purchased", "order.date_paid"],
                      "sort-desc": [true, true],
                      "multi-sort": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                { staticClass: "font-weight-black" },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openOrderDialog(
                                            props.item.orders_id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(props.item.orders_id))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isOrderSelected(
                                            props.item.orders_id
                                          ),
                                          expression:
                                            "isOrderSelected(props.item.orders_id)"
                                        }
                                      ],
                                      attrs: {
                                        color: "green",
                                        dark: "",
                                        small: ""
                                      }
                                    },
                                    [_vm._v("check")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [
                                props.item.order &&
                                props.item.order.date_purchased
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm
                                              .$moment(
                                                props.item.order.date_purchased
                                              )
                                              .format("YYYY-MM-DD")
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                props.item.order && props.item.order.date_paid
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm
                                              .$moment(
                                                props.item.order.date_paid
                                              )
                                              .format("YYYY-MM-DD")
                                          ) +
                                          "\n                                "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(props.item.products_quantity))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(props.item.products_price) + " "),
                                props.item.order
                                  ? _c("span", [
                                      _vm._v(_vm._s(props.item.order.currency))
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                props.item.order
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          props.item.order.orders_status_name
                                            .orders_status_name
                                      },
                                      [
                                        _vm._v(
                                          "\n                                       " +
                                            _vm._s(
                                              props.item.order
                                                .orders_status_name
                                                .orders_status_name
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                props.item.order
                                  ? _c("span", [
                                      _vm._v(_vm._s(props.item.order.site))
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-subheader",
                    {
                      staticClass:
                        "grey lighten-4 justify-start font-weight-black mt-2"
                    },
                    [
                      _vm._v(
                        "\n                    CORRESPONDENS:\n                    "
                      ),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            text: "",
                            icon: "",
                            color: "default"
                          },
                          on: { click: _vm.initCorrespondence }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-cached")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-simple-table", {
                    staticClass: "elevation-1 scroll-table",
                    attrs: {
                      dense: "",
                      loading: _vm.loadingCorrespondence,
                      "loading-text": "Loading... Please wait"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center font-weight-normal",
                                    attrs: { width: "30%" }
                                  },
                                  [_vm._v("Check ID")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center font-weight-normal",
                                    attrs: { width: "30%" }
                                  },
                                  [_vm._v("Order ID")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center font-weight-normal"
                                  },
                                  [_vm._v("Quantity")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center font-weight-normal"
                                  },
                                  [_vm._v("Action")]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "tr",
                                {
                                  staticClass: "pa-0 ma-0",
                                  staticStyle: { height: "1px" }
                                },
                                [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      staticStyle: { height: "1px" },
                                      attrs: { colspan: "4" }
                                    },
                                    [
                                      _c("v-progress-linear", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          height: "3",
                                          indeterminate: "",
                                          active: _vm.loadingCorrespondence,
                                          absolute: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.select_orders.length &&
                                          _vm.select_check.length,
                                        expression:
                                          "select_orders.length && select_check.length"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "td",
                                      { attrs: { align: "center" } },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "font-weight-regular font-size-small ma-auto",
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            items: _vm.select_check,
                                            "item-value": "products_checks_id",
                                            "item-text": "products_checks_id",
                                            "return-object": ""
                                          },
                                          model: {
                                            value: _vm.newCorrespondence.check,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newCorrespondence,
                                                "check",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "newCorrespondence.check"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { attrs: { align: "center" } },
                                      [
                                        _c("v-select", {
                                          staticClass:
                                            "font-weight-regular font-size-small ma-auto",
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            items: _vm.select_orders,
                                            "item-value": "orders_id",
                                            "item-text": "orders_id",
                                            "return-object": ""
                                          },
                                          model: {
                                            value: _vm.newCorrespondence.order,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newCorrespondence,
                                                "order",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "newCorrespondence.order"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-weight-regular ma-auto",
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            number: ""
                                          },
                                          model: {
                                            value:
                                              _vm.newCorrespondence.quantity,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newCorrespondence,
                                                "quantity",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "newCorrespondence.quantity"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.newCorrespondence.order
                                                    .orders_id &&
                                                  _vm.newCorrespondence.check
                                                    .products_checks_id,
                                                expression:
                                                  "newCorrespondence.order.orders_id && newCorrespondence.check.products_checks_id"
                                              }
                                            ],
                                            attrs: { color: "success" },
                                            on: { click: _vm.addCorrespondence }
                                          },
                                          [_vm._v("add")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.correspondence.data, function(item) {
                                  return _c("tr", { key: item.id }, [
                                    _c("td", { attrs: { width: "30%" } }, [
                                      _vm._v(
                                        _vm._s(item.products_checks_id) +
                                          "\n                                    "
                                      ),
                                      item.check
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                        #" +
                                                _vm._s(
                                                  item.check.check_number
                                                ) +
                                                "\n                                    "
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { attrs: { width: "30%" } },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openOrderDialog(
                                                  item.order_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            item.order
                                              ? _c(
                                                  "span",
                                                  {
                                                    class:
                                                      item.order
                                                        .orders_status_name
                                                        .orders_status_name
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.order_id) +
                                                        "\n                                        "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.order &&
                                        (item.order.orders_status === 4 ||
                                          item.order.orders_status === 7 ||
                                          item.order.orders_status === 9)
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "red",
                                                  medium: "",
                                                  dense: ""
                                                }
                                              },
                                              [_vm._v("error")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.quantity))]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.id,
                                                expression: "item.id"
                                              }
                                            ],
                                            attrs: {
                                              color: "red",
                                              size: "medium"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteCorrespondence(
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("delete")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                })
                              ],
                              2
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              staticStyle: {
                position: "absolute",
                width: "100%",
                bottom: "15px"
              },
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right font-weight-bold my-auto",
                          attrs: { cols: "2" }
                        },
                        [_vm._v("Purchased:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left my-auto",
                          attrs: { cols: "1" }
                        },
                        [_vm._v(_vm._s(_vm.statistics.purchased))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right font-weight-bold my-auto",
                          attrs: { cols: "2" }
                        },
                        [_vm._v(" Sold:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left my-auto",
                          attrs: { cols: "1" }
                        },
                        [_vm._v(_vm._s(_vm.statistics.sold))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right font-weight-bold my-auto",
                          attrs: { cols: "2" }
                        },
                        [_vm._v("Remain:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left d-flex pa-0",
                          attrs: { cols: "1" }
                        },
                        [
                          _vm.statistics.purchased !== null &&
                          _vm.statistics.sold !== null
                            ? _c(
                                "v-text-field",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    readonly: ""
                                  },
                                  model: {
                                    value: _vm.remainQantity,
                                    callback: function($$v) {
                                      _vm.remainQantity = _vm._n($$v)
                                    },
                                    expression: "remainQantity"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-1",
                                      attrs: {
                                        slot: "append-outer",
                                        color:
                                          _vm.remainQantity !==
                                          _vm.product.products_quantity
                                            ? "red"
                                            : "grey",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveProductQuantity()
                                        }
                                      },
                                      slot: "append-outer"
                                    },
                                    [_vm._v("save")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }