var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-0",
      staticStyle: {
        border: "none",
        display: "flex !important",
        "flex-direction": "column"
      },
      attrs: { outlined: "", rounded: false, flat: "" }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "text-truncate",
              attrs: { dark: "", dense: "", color: "blue darken-3" }
            },
            [
              _vm._v(
                "\n            Action on selected products (" +
                  _vm._s(_vm.selectedProducts.length) +
                  ")\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeGroupActionDialog.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-text",
        {
          staticClass: "rounded-0",
          staticStyle: { "flex-grow": "1", overflow: "auto", height: "400px" }
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "8" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { dense: "", row: "", "hide-details": "" },
                      on: { change: _vm.setStatusForChecked },
                      model: {
                        value: _vm.StatusForChecked,
                        callback: function($$v) {
                          _vm.StatusForChecked = $$v
                        },
                        expression: "StatusForChecked"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { color: "success", value: 1, label: "On" }
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: { color: "red", value: 0, label: "Off" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "4" }
                },
                [
                  _c("v-checkbox", {
                    staticClass: "text-truncate",
                    attrs: { label: "Update on eBay", "hide-details": "" },
                    model: {
                      value: _vm.onEbay,
                      callback: function($$v) {
                        _vm.onEbay = $$v
                      },
                      expression: "onEbay"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start mt-1",
                  attrs: { cols: "12" }
                },
                [
                  _c("tree-select", {
                    attrs: {
                      "load-options": _vm.loadOptions,
                      options: _vm.productsOptions,
                      "auto-load-root-options": true,
                      normalizer: _vm.normalizer,
                      searchable: true,
                      "always-open": true,
                      placeholder: "Category for checked products",
                      name: "productCategory",
                      clearable: false,
                      "search-nested": "",
                      "show-count": true
                    },
                    on: { input: _vm.setCategoryForChecked },
                    model: {
                      value: _vm.CategoryForChecked,
                      callback: function($$v) {
                        _vm.CategoryForChecked = $$v
                      },
                      expression: "CategoryForChecked"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("confirm", { ref: "confirm" })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _vm.upload
            ? _c("v-progress-linear", {
                staticClass: "mr-2",
                attrs: { color: "blue-grey", height: "25", reactive: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var value = ref.value
                        return [
                          _c("strong", [_vm._v(_vm._s(Math.ceil(value)) + "%")])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3002676263
                ),
                model: {
                  value: _vm.upload,
                  callback: function($$v) {
                    _vm.upload = $$v
                  },
                  expression: "upload"
                }
              })
            : _c("v-checkbox", {
                staticClass: "pt-0 mt-0",
                staticStyle: { display: "none" },
                attrs: {
                  dense: "",
                  "hide-details": "",
                  label: "Refresh prroducts list"
                },
                model: {
                  value: _vm.refreshProductsList,
                  callback: function($$v) {
                    _vm.refreshProductsList = $$v
                  },
                  expression: "refreshProductsList"
                }
              }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "default", small: "" },
              on: { click: _vm.closeGroupActionDialog }
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "success", small: "" },
              on: { click: _vm.saveGroupActionDialog }
            },
            [_vm._v("Update")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }