var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0", staticStyle: { height: "100%" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-data-table", {
                staticClass: "scroll-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.order_products,
                  "hide-default-footer": "",
                  dense: "",
                  id: "tableOrderDialogProducts",
                  "items-per-page": _vm.order_products.length
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(props) {
                      return [
                        _c("tr", [
                          _c("td", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.openProductDialog(
                                      props.item.product
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.products_id))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-left" },
                            [
                              _c(
                                "v-edit-dialog",
                                {
                                  on: {
                                    close: function($event) {
                                      return _vm.updateOrder(
                                        props.item,
                                        props.item.products_quantity,
                                        props.item.products_price
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(props.item.products_name) +
                                      "\n                                    "
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "input",
                                      type: "string",
                                      autofocus: ""
                                    },
                                    slot: "input",
                                    model: {
                                      value: props.item.products_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.item,
                                          "products_name",
                                          $$v
                                        )
                                      },
                                      expression: "props.item.products_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              props.item.auctionID
                                ? _c("span", [
                                    _vm._v(
                                      " (" + _vm._s(props.item.auctionID) + ")"
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-edit-dialog",
                                {
                                  on: {
                                    close: function($event) {
                                      return _vm.updateOrder(
                                        props.item,
                                        props.item.products_quantity,
                                        props.item.products_price
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(props.item.products_quantity) +
                                      "\n                                    "
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "input",
                                      type: "number",
                                      autofocus: ""
                                    },
                                    slot: "input",
                                    model: {
                                      value: props.item.products_quantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.item,
                                          "products_quantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "props.item.products_quantity"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-edit-dialog",
                                {
                                  on: {
                                    close: function($event) {
                                      return _vm.updateOrder(
                                        props.item,
                                        props.item.products_quantity,
                                        props.item.products_price
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        props.item.products_price.toFixed(2)
                                      ) +
                                      "\n                                    "
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "input",
                                      type: "number",
                                      step: "0.01",
                                      autofocus: ""
                                    },
                                    slot: "input",
                                    model: {
                                      value: props.item.products_price,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.item,
                                          "products_price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "props.item.products_price"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                (
                                  parseFloat(props.item.products_price) *
                                  props.item.products_quantity
                                ).toFixed(2)
                              ) + " "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [_c("v-divider", { attrs: { vertical: "" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "justify-center" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "red" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteOrderProduct(props.item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    delete\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "header.add",
                    fn: function(props) {
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              slot: "activator",
                              big: "",
                              color: "green"
                            },
                            on: { click: _vm.openDialog },
                            slot: "activator"
                          },
                          [_vm._v(" add_box ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "add-dialog",
                          {
                            on: { "add-product": _vm.newProductAdd },
                            model: {
                              value: _vm.dialog,
                              callback: function($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Add product\n                        "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-layout",
            { staticClass: "ma-0" },
            [
              _c(
                "v-row",
                { staticClass: "subtitle-1 ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "amber" },
                          on: {
                            click: function($event) {
                              return _vm.printOrderReceipt(_vm.order)
                            }
                          }
                        },
                        [_vm._v("mdi-cube-outline")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.order.order_products.length &&
                                _vm.order.orders_status_name
                                  .orders_status_name === "Pending",
                              expression:
                                "order.order_products.length && order.orders_status_name.orders_status_name === 'Pending'"
                            }
                          ],
                          attrs: { small: "", color: "primary" },
                          on: { click: _vm.openCancelDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.order.orders_status_name
                                  .orders_status_name === "Paid" ||
                                _vm.order.orders_status_name
                                  .orders_status_name === "Delivered" ||
                                _vm.order.orders_status_name
                                  .orders_status_name === "PartialRefunded",
                              expression:
                                "order.orders_status_name.orders_status_name === 'Paid' || order.orders_status_name.orders_status_name === 'Delivered' || order.orders_status_name.orders_status_name === 'PartialRefunded'"
                            }
                          ],
                          attrs: { small: "", color: "primary" },
                          on: { click: _vm.openRefundDialog }
                        },
                        [_vm._v("Refund")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.order.payment_method ===
                                "IntegratedMerchantCreditCard",
                              expression:
                                "order.payment_method === 'IntegratedMerchantCreditCard'"
                            }
                          ],
                          attrs: { small: "", color: "green" },
                          on: { click: _vm.getFromEbayPayment }
                        },
                        [_vm._v("Get eBay Payment")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pa-0", attrs: { cols: "3" } }, [
                    _c("strong", [
                      _vm._v(
                        "SubTotal: " +
                          _vm._s(_vm.subTotal.toFixed(2)) +
                          "\n                            " +
                          _vm._s(_vm.order ? _vm.order.currency : null) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pa-0", attrs: { cols: "3" } }, [
                    _c(
                      "strong",
                      [
                        _c(
                          "v-edit-dialog",
                          [
                            _vm._v(
                              "\n                                Shipment: " +
                                _vm._s(
                                  _vm.order_shipping
                                    ? _vm.order_shipping.toFixed(2)
                                    : "0.00"
                                ) +
                                "\n                                " +
                                _vm._s(_vm.order ? _vm.order.currency : null) +
                                "\n                                "
                            ),
                            _c("v-text-field", {
                              attrs: {
                                slot: "input",
                                type: "number",
                                step: "0.01",
                                autofocus: "",
                                value: _vm.order_shipping
                              },
                              slot: "input",
                              model: {
                                value: _vm.shipment,
                                callback: function($$v) {
                                  _vm.shipment = _vm._n($$v)
                                },
                                expression: "shipment"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pa-0", attrs: { cols: "3" } }, [
                    _c("strong", [
                      _vm._v(
                        "Total: " +
                          _vm._s(_vm.finalTotal.toFixed(2)) +
                          "\n                            " +
                          _vm._s(_vm.order ? _vm.order.currency : null) +
                          "\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.order.order_ebayfees
                      ? _c("span", [
                          _vm._v("\n                            eBay fees: "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.order.order_ebayfees.text)
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.order.order_ebaytotal
                      ? _c("span", [
                          _vm._v("\n                            eBay total: "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.order.order_ebaytotal.text)
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }