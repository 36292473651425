<template>
    <div v-if="defects.groups.length">
        <v-list flat subheader
                v-for="groupDefects in defects.groups"
                :key="groupDefects.defects_groups_id"
        >
            <v-subheader>{{groupDefects.title}}</v-subheader>

            <v-list-item-group
                    v-model="productDefectsModel"
                    multiple
                    active-class=""
            >
                <v-list-item v-for="defect in groupDefects.defects" :key="groupDefects.defects_id">
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active" @onChange="changeDefects"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>{{defect.title}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "ProductDefects",
        props: {
            defects:{
                required: true
            },
            productDefects:{

            }
        },
        data: () => ({
            selectedDefects: [],
        }),
        mounted(){
            // console.log('Lens', this.defects)
            // console.log('Lens', this.productDefects)
        },
        computed: {
            productDefectsModel:{
                get(){
                    if(this.productDefects){
                        return this.productDefects.products_defects.map(defect => defect.defects_id);
                    }

                },
                set(val){
                    this.selectedDefects = [];
                    console.log(val);
                    for(let i =0; i < val.length; i++ ){
                        // val[i]

                        for(let j=0; j < this.defects.groups.length; j++ ){
                            const selectedDefect = this.defects.groups[j].defects.find(defect => {
                                return defect.defects_id === val[i];
                            });
                            if(selectedDefect) this.selectedDefects.push(selectedDefect);
                        }

                    }
                    console.log(this.selectedDefects)

                }
            }
        },
        methods:{
            changeDefects(){
                console.log('changeDefects',this.productDefectsModel)
            }
        },
    }
</script>

<style scoped>

</style>