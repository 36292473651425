<template>
    <div class="d-flex text-subtitle-2">
        <span :class="{ 'text-left mr-1 green--text': set.set_complited > 0, 'text-left mr-1 red--text text--lighten-1': set.set_complited < 0 }">{{ set.set_complited+'%'}}</span>
        <span class="text-left">{{ ' - '+ set.set_name }}</span>
        <v-icon color="success" size="medium" @click="dialog = true">info</v-icon>
        <v-dialog v-model="dialog" fullscreen hide-overlay  transition="dialog-bottom-transition" :scrollable="true" @keydown.esc="dialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>
                            <p class="text-truncate text-left title ma-0 pl-1" v-if="product">
                                {{ product.products_id }} - {{ product.descriptions.products_name}}
                            </p>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon @click.stop="dialog = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-4">
                    <set :setInfo="set"></set>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Set from '@/components/Set'

    export default {
        name: "ProductSetInfo",
        props: {
            set: {
                type: Object,
                required: true,
            },
            product: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            dialog: false,
        }),
        components:{
            Set
        }
    }
</script>

<style scoped>

</style>