<template>
    <v-app id="cam">
        <v-progress-linear id="loader" height="10"  v-show="loading" class="ma-0" color="white" :indeterminate="true" style="position: absolute; z-index: 99999999;"></v-progress-linear>
        <header-bar
                :favorites_menu="favorites_menu"
                :main_menu="main_menu"
                :failedJobs = "failedJobs"
                v-show="isLogin"
                @toggle_favorites="toggle_favorites"
        />

        <v-main>
            <v-container fluid align-start :class="fillHeight+' pa-2'" fill-weight text-center>
                <router-view></router-view>
            </v-container>

        </v-main>

        <v-dialog name="ProductDialog" v-model="showProductDialog" :fullscreen="fullscreenProductDialog" width="800px" :scrollable="true" :key="editedProduct.products_id" hide-overlay persistent @keydown.esc="showProductDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>
                            <p class="text-truncate text-left title ma-0" v-if="editedProduct.descriptions">
                            {{ editedProduct.products_id }} - {{ editedProduct.descriptions.products_name }}
                        </p>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="reloadProductDialog">
                                <v-icon small>mdi-reload</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="fullscreenProductDialog = !fullscreenProductDialog">
                                <v-icon small v-if="fullscreenProductDialog">mdi-arrow-collapse</v-icon>
                                <v-icon small v-else>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="closeProductDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <single-product :id="editedProduct.products_id" :activeTab="activeProductDialogTab" :changeProductDialogTab="changeProductDialogTab" class="pa-2"></single-product>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="Defects" v-model="showProductDefectDialog" :fullscreen="true" width="800px"  :scrollable="true" :key="'Defects'+editedProduct.products_id" hide-overlay persistent @keydown.esc="showProductDefectDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>
                            <p class="text-truncate text-left title ma-0" v-if="editedProduct.descriptions">
                                Defects: {{ editedProduct.products_id }} - {{ editedProduct.descriptions.products_name }}
                            </p>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="showProductDefectDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <defects :productsId="editedProduct.products_id" class="pa-2"></defects>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="ProductDescriptionDialog" v-model="showProductDescriptionDialog" :fullscreen="fullscreenProductDescriptionDialog" width="80%" :scrollable="true" :key="editedProduct.products_id+'description'" hide-overlay persistent>
            <v-card style="min-height: 80vh;">
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>
                            <p class="text-truncate text-left title ma-0">
                                Edit product multilingual description
                            </p>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="fullscreenProductDescriptionDialog = !fullscreenProductDescriptionDialog">
                                <v-icon small v-if="fullscreenProductDescriptionDialog">mdi-arrow-collapse</v-icon>
                                <v-icon small v-else>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="closeProductDescriptionDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <product-descriptions :id="editedProduct.products_id" class="pa-2"></product-descriptions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="ProductSpecificsDialog" v-model="showProductSpecificsDialog" :fullscreen="fullscreenProductSpecificsDialog" width="80%" :scrollable="true" :key="editedProduct.products_id+'productspecifics'" hide-overlay persistent>
            <v-card style="min-height: 80vh;">
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>
                            <p class="text-truncate text-left title ma-0">
                                Edit eBay product specifics
                            </p>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="fullscreenProductSpecificsDialog = !fullscreenProductSpecificsDialog">
                                <v-icon small v-if="fullscreenProductSpecificsDialog">mdi-arrow-collapse</v-icon>
                                <v-icon small v-else>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="closeProductSpecificsDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <product-specifics :id="editedProduct.products_id" class="pa-2"></product-specifics>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="OrderDialog" v-model="showOrderDialog" :fullscreen="fullscreenOrderDialog" width="1000px" :scrollable="true" :key="currentOrderId" hide-overlay persistent @keydown.esc="showOrderDialog = false">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{ currentOrderId ? 'Order: '+currentOrderId : 'Order' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="reloadOrderDialog">
                                <v-icon small>mdi-reload</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="fullscreenOrderDialog = !fullscreenOrderDialog">
                                <v-icon small v-if="fullscreenOrderDialog">mdi-arrow-collapse</v-icon>
                                <v-icon small v-else>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="closeOrderDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <order :id="currentOrderId" :activeTab=activeOrderDialogTab :needLoadOrder="needLoadOrder" :changeOrderDialogTab="changeOrderDialogTab" :fullscreen="fullscreenOrderDialog"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="EditProductDialog" v-model="showEditProductDialog" :fullscreen="fullscreenEditProductDialog" width="1000px" :scrollable="true" :key="'edit_product_'+editedProduct.products_id" hide-overlay persistent @keydown.esc="showEditProductDialog = false">
            <v-card style="min-height: 70vh;">
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>Edit product - {{ editedProduct.products_id }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="reloadEditProductDialog">
                                <v-icon small>mdi-reload</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="fullscreenEditProductDialog = !fullscreenEditProductDialog">
                                <v-icon small v-if="fullscreenEditProductDialog">mdi-arrow-collapse</v-icon>
                                <v-icon small v-else>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn icon dark @click.stop="closeEditProductDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <product-form
                        :productid="editedProduct.products_id"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="RefundDialog" v-model="showRefundDialog" :fullscreen="false" width="50vw" :scrollable="true" :key="'refund_order_'+currentOrderId" hide-overlay @click:outside="closeRefundDialog">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{ currentOrderId ? 'Refund order - '+currentOrderId : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="closeRefundDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <refund-order
                        :orderId="currentOrderId"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog name="CancelDialog" v-model="showCancelDialog" :fullscreen="false" width="50vw" :scrollable="true" :key="'cancel_order_'+currentOrderId" hide-overlay @click:outside="closeCancelDialog">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark dense color="blue darken-3">
                        <v-toolbar-title>{{ currentOrderId ? 'Cancel order - '+currentOrderId : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click.stop="closeCancelDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <cancel-order
                        :orderId="currentOrderId"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <JqxNotification
            ref="notification"
            :template="notification.template"
            :autoClose="notification.autoClose"
            :autoOpen="notification.autoOpen"
            :opacity="notification.opacity"
            :position="notification.position"
            :animationOpenDelay="notification.animationOpenDelay"
            :animationCloseDelay="notification.animationCloseDelay"
            :width="notification.width"
        >
            <div>
                <div></div>
            </div>
        </JqxNotification>
    </v-app>
</template>

<script>

    //import ProductDefects from "assets/js/components/ProductDefects";
    import { mapActions } from "vuex";
    import { mapCacheActions } from "vuex-cache";
    import HeaderBar from '../components/HeaderBar.vue'
    import SingleProduct from '../pages/Catalog/product/SingleProduct'
    import Order from '../pages/order/Order';
    import EventBus from "@/event-bus";
    import RefundOrder from '../pages/Sales/components/RefundOrder';
    import CancelOrder from "../pages/Sales/components/CancelOrder";
    import ProductDescriptions from "../pages/Catalog/ProductDescriptions";
    import ProductForm from '../pages/Catalog/components/ProductForm';
    import ProductSpecifics from "../pages/EBay/ProductSpecifics"
    import JqxNotification from "jqwidgets-scripts/jqwidgets-vue/vue_jqxnotification.vue";
    import Defects from "../pages/Catalog/Defects"

    import Pusher from 'pusher-js';

    export default {
        name: "App",
        data () {
            return {
                notification: {
                    template: 'info',
                    autoClose: true,
                    autoOpen: false,
                    opacity: 1,
                    position: 'top-right',
                    animationOpenDelay: 800,
                    animationCloseDelay: 2500,
                    width: 'auto'
                },
                failedJobs: 0,
                syncClient: null,
                pusher: null,
                channel: null,
                countries: '', // this.$store.countries,
                favorites_menu: [],
                main_menu: [
                    {
                        action: 'local_activity',
                        title: 'Snipers',
                        active: true,
                        items: [
                            { title: 'Search', tag: '/sniper_search', icon: 'question_answer' },
                            { title: 'Buy', tag: '/sniper_buy', icon: 'question_answer' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'Catalog',
                        active: true,
                        items: [
                            { title: 'Products', tag: '/products', icon: 'question_answer' },
                            { title: 'Products Old', tag: '/products_old', icon: 'question_answer' },
                            { title: 'Add product', tag: '/add_product', icon: 'question_answer' },
                            { title: 'Add product OLD', tag: '/add_product_old', icon: 'question_answer' },
                            { title: 'Products for eBay', tag: '/products_for_ebay', icon: 'question_answer' },
                            { title: 'Sets', tag: '/sets', icon: 'question_answer' },
                            { title: 'Sets Old', tag: '/sets_old', icon: 'question_answer' },
                            //{ title: 'Sets Old New', tag: '/sets_old_new', icon: 'question_answer' },
                            { title: 'Stock', tag: '/stock', icon: 'question_answer' },
                            { title: 'Upload images', tag: '/upload_images', icon: 'question_answer' },
                            { title: 'Products attributes', tag: '/products_attributes', icon: 'question_answer' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'Sales',
                        active: false,
                        items: [
                            { title: 'Orders', tag: '/orders', icon: 'question_answer' },
                            { title: 'Orders Old', tag: '/orders_old', icon: 'sales' },
                            { title: 'Checks', tag: '/checks', icon: 'question_answer' },
                            //{ title: 'Checks Old', tag: '/checks_old', icon: 'question_answer' },
                            //{ title: 'Best offers', tag: '/bestoffers', icon: 'question_answer' },
                            //{ title: 'Customers', tag: '/customers', icon: 'question_answer' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'Tools',
                        active: false,
                        items: [
                            { title: 'Failed jobs', tag: '/failed_jobs', icon: 'dashboard' },
                            { title: 'Blogs', tag: '/blogs', icon: 'dashboard' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'eBay',
                        active: false,
                        items: [
                            { title: 'eBay category', tag: '/ebay_category', icon: 'question_answer' },
                            { title: 'Ebay Category Specifics', tag: '/ebay_categoryspecifics', icon: 'question_answer' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'Reports',
                        active: false,
                        items: [
                            { title: 'Goods Flow', tag: '/goods_flow', icon: 'question_answer' },
                            { title: 'eBay Traffic Report', tag: '/ebay_traffic_report', icon: 'question_answer' },
                        ]
                    },
                    {
                        action: 'local_activity',
                        title: 'Configuration',
                        active: false,
                        items: [
                            { title: 'Passwords', tag: '/auctions_buy', icon: 'question_answer' },
                            { title: 'Currencies', tag: '/currencies', icon: 'question_answer' },
                            { title: 'Orders Status', tag: '/orders_status', icon: 'question_answer' },
                            { title: 'Defects', tag: '/defects', icon: 'question_answer' },
                        ]
                    },
                ],
                user_menu: [
                ],
                loading: false,
                isLogin: false,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showProductDialog: false,
                fullscreenProductDialog: false,
                showProductDescriptionDialog: false,
                fullscreenProductDescriptionDialog: true,
                showProductSpecificsDialog: false,
                fullscreenProductSpecificsDialog: true,
                activeProductDialogTab: null,
                editedProduct: {
                    descriptions: {products_description: '', products_name: ''},
                    product_note: {products_note: ''},
                    products_id: null
                },
                defaultEditedProduct: {
                    descriptions: {products_description: '', products_name: ''},
                    product_note: {products_note: ''},
                    products_id: null
                },
                showOrderDialog: false,
                fullscreenOrderDialog: false,
                currentOrderId: null,
                currentOrder: null,
                activeOrderDialogTab: null,
                needLoadOrder: true,
                showRefundDialog: false,
                showCancelDialog: false,
                fillHeight: 'fill-height',
                showEditProductDialog: false,
                fullscreenEditProductDialog: true,
                showProductDefectDialog: false
            }
        },
        computed: {
            uniqueId() {
                return  _.now.toString()
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = (to.name !== undefined) ? to.name + ' - CM Admin' : 'CM Admin';
                    if (to.query.fillHeight !== undefined) {
                        this.fillHeight = (to.query.fillHeight === '0') ? '' : 'fill-height'
                    }
                }
            },
        },
        created() {
            axios.interceptors.request.use((config) => {
                this.loading=true
                return config;
            }, (error) => {
                // trigger 'loading=false' event here
                return Promise.reject(error);
            });

            axios.interceptors.response.use((response) => {
                this.loading=false;
                if (response.config.method === 'put' || response.config.method === 'post') {
                    let ran = (new Date%9e6).toString(36);
                    $('body').append('<div id="messageNotification_'+ran+'" style="display:none;z-index: 9999;"><div>Saved!</div></div>');

                    $("#messageNotification_"+ran).jqxNotification({
                        width: 250, position: "top-right", opacity: 1,
                        autoOpen: true, animationOpenDelay: 0, animationCloseDelay: 500, autoCloseDelay: 1000, autoClose: true, template: "success", blink: false
                    });
                    $(".jqx-notification-container").css('z-index', 9999);
                    $("#messageNotification_"+ran).on('close', function () {
                        $("#messageNotification_"+ran).jqxNotification('destroy');
                    });
                }
                this.loading=false;
                this.isLogin=true;
                return response;
            }, (error) => {
                this.loading=false
                let message = '';
                switch (error.response.status) {
                    case 401:
                        message = 'Request failed with status code 401'
                        break;
                    case 404:
                        message = 'The requested resource does not exist or has been deleted'
                        break;
                    case 405:
                        message = 'Method Not Allowed'
                        break;
                    case 500:
                        message = 'Request failed with status code 500'
                        break;
                    case 422:
                        if (error.response.data) {
                            message = error.response.data.message+'<br/>' ?? error.response.statusText+'<br/>'
                            _.forEach(error.response.data.errors ?? [], element => message += `<br/>${element.join('<br/>')}<br/>`);
                        }
                        break;
                    default:
                        message = 'Unknown error';
                }

                this.displayErrorNotification(message)
                return Promise.reject(error)
            });
        },
        mounted() {

            let thitis = this
            $( document ).ajaxStart(function() {
                    thitis.loading=true;
                }
            )
            $( document ).ajaxComplete(function() {
                thitis.loading=false;
            });

            EventBus.$on('openNotification',  (notification = '', options = {}) => {
                for (let key in options) {
                    this.$refs.notification[key] = options[key];
                }
                this.$refs.notification.$el.querySelector('.jqx-notification-content').innerHTML = notification;
                $(".jqx-notification-container").css('z-index', 9999);

                this.$refs.notification.open();
            });

            EventBus.$on('openProductDialog',  (data, activeTab) => {
                this.closeOrderDialog()
                this.closeEditProductDialog()
                this.openProductDialog(data, activeTab)
            });
            EventBus.$on('changeProductDialogTab',  (data) => {
                this.changeProductDialogTab(data)
            });
            EventBus.$on('openOrderDialog',  (orderId, activeTab) => {
                this.closeProductDialog()
                this.closeEditProductDialog()
                this.openOrderDialog(orderId, activeTab)
            });
            EventBus.$on('changeOrderDialogTab',  (data) => {
                this.changeOrderDialogTab(data)
            });
            EventBus.$on('openRefundDialog',  (orderId) => {
                this.closeRefundDialog()
                this.openRefundDialog(orderId)
            });
            EventBus.$on('openCancelDialog',  (orderId) => {
                this.closeCancelDialog()
                this.openCancelDialog(orderId)
            });
            EventBus.$on('openProductDescriptionDialog',  (data) => {
                this.closeProductDescriptionDialog()
                this.openProductDescriptionDialog(data)
            });
            EventBus.$on('openEditProductDialog',  (data) => {
                this.closeProductDialog()
                this.openEditProductDialog(data)
            });
            EventBus.$on('closeEditProductDialog',  () => {
                this.closeEditProductDialog()
            });
            EventBus.$on('closeProductDialog',  () => {
                this.closeProductDialog()
            });
            EventBus.$on('openProductSpecificsDialog',  (data) => {
                this.closeProductSpecificsDialog()
                this.openProductSpecificsDialog(data)
            });
            EventBus.$on('reloadOrderDialog',  () => {
                this.reloadOrderDialog()
            });
            EventBus.$on('reloadProductDialog',  () => {
                this.reloadProductDialog()
            });

            EventBus.$on('printProductBarcode',  (products_id) => {
                this.printProductBarcode(products_id)
            });

            EventBus.$on('showProductDefect',  (products) => {
                this.editedProduct = products
                this.showProductDefectDialog = true
            });



            if (localStorage.getItem('favorites_menu')) {
                this.favorites_menu = JSON.parse(localStorage.getItem('favorites_menu'));
            }

            this.initPusher()
            this.subscribePusher()
            this.initCategories()
            this.makeVuetifyDialogMovable()
        },
        methods: {
            openProductDefectDialog(item) {
                this.editedProduct = _.cloneDeep(item)
            },
            displayErrorNotification(message) {
                const ran = (new Date%9e6).toString(36);
                $('body').append('<div id="messageNotification_'+ran+'" style="display:none;z-index: 9999;"><div>'+message+'</div></div>');

                $("#messageNotification_"+ran).jqxNotification({
                    width: 250, position: "top-right", opacity: 0.9,
                    autoOpen: true, animationOpenDelay: 0, autoClose: false, template: "error", blink: true
                });
                $(".jqx-notification-container").css('z-index', 9999);
                $("#messageNotification_"+ran).on('close', function () {
                    $("#messageNotification_"+ran).jqxNotification('destroy');
                });
            },
            now() {
                return _.now.toString()
            },
            toggle_favorites: function (subItem) {

                const i = _.findIndex(this.favorites_menu, subItem);

                if (i > -1) {
                    this.favorites_menu.splice(i, 1)
                } else {
                    this.favorites_menu.push(subItem)
                }
                localStorage.setItem('favorites_menu', JSON.stringify(this.favorites_menu))
            },
            initPusher: function () {
                this.pusher = new Pusher('e35cfe809776adceb457', {
                    cluster: 'eu',
                    forceTLS: true
                });
            },
            subscribePusher: function () {
                this.channel = this.pusher.subscribe('user');
                this.channel.bind("App\\Events\\FailedJobEvent", this.processEvent);
            },
            processEvent: function (data) {
                if (!_.isUndefined(data.event.event_id)) {
                    switch (data.event.event_id) {
                        case "App\\Events\\FailedJobEvent":
                            this.failedJobs = data.event.failed_job_count;
                            console.log(data);
                            break;
                    }
                }
            },
            openProductDialog(item, activeTab = 'tab-check') {
                this.editedProduct = []
                this.editedProduct = _.cloneDeep(item)
                this.activeProductDialogTab = activeTab
                this.showProductDialog = true;
            },
            closeProductDialog() {
                this.showProductDialog = false;
                this.editedProduct = this.defaultEditedProduct;
            },
            openProductDescriptionDialog(item) {
                this.editedProduct = _.cloneDeep(item)
                window.open("/product_descriptions/"+item.products_id, '_blank')
                //this.showProductDescriptionDialog = true;
            },
            closeProductDescriptionDialog() {
                this.showProductDescriptionDialog = false;
                this.editedProduct = this.defaultEditedProduct;
            },
            openEditProductDialog(item) {
                this.editedProduct = _.cloneDeep(item)
                this.showEditProductDialog = true;
            },
            closeEditProductDialog() {
                this.showEditProductDialog = false;
                this.editedProduct = this.defaultEditedProduct;
            },
            openProductSpecificsDialog(item) {
                this.editedProduct = _.cloneDeep(item)
                this.showProductSpecificsDialog = true;
            },
            closeProductSpecificsDialog() {
                this.showProductSpecificsDialog = false;
                this.editedProduct = this.defaultEditedProduct;
            },
            reloadProductDialog() {
                let tmpProduct = this.editedProduct;
                let tmpProductDialogTab= this.activeProductDialogTab;
                this.closeProductDialog()
                let that = this;
                setTimeout(function() {
                    that.openProductDialog(tmpProduct, tmpProductDialogTab)
                    }, 25);
            },
            reloadEditProductDialog() {
                let tmpProduct = this.editedProduct;
                this.closeEditProductDialog()
                let that = this;
                setTimeout(function() {
                    that.openEditProductDialog(tmpProduct)
                    }, 25);
            },
            changeProductDialogTab(activeProductDialogTab) {
                this.activeProductDialogTab = activeProductDialogTab;
            },
            openOrderDialog(orderId, activeTab = null) {
                this.currentOrderId = orderId
                this.activeOrderDialogTab = activeTab
                this.showOrderDialog = true;
            },
            closeOrderDialog() {
                this.showOrderDialog = false;
                this.currentOrderId = null
                this.activeOrderDialogTab = null
            },
            reloadOrderDialog() {
                let tmpOrderID = this.currentOrderId;
                let tmpOrderTab = this.activeOrderDialogTab;
                this.closeOrderDialog()
                let that = this;
                setTimeout(function() {
                    that.openOrderDialog(tmpOrderID, tmpOrderTab)
                }, 25);
            },
            changeOrderDialogTab(activeOrderDialogTab) {
                this.activeOrderDialogTab = activeOrderDialogTab;
            },
            openRefundDialog(orderId){
                this.showOrderDialog = false;
                this.showProductDialog = false;
                this.currentOrderId = orderId;
                this.showRefundDialog = true;
            },
            closeRefundDialog(){
                this.showRefundDialog = false;
                this.currentOrderId = null;
            },
            openCancelDialog(orderId){
                this.showOrderDialog = false;
                this.showProductDialog = false;
                this.currentOrderId = orderId;
                this.showCancelDialog = true;
            },
            closeCancelDialog(){
                this.showCancelDialog = false;
                this.currentOrderId = null;
            },
            orderUpdated(){

            },
            printProductBarcode(products_id) {
                window.open("/barcode/"+products_id, '_blank')
            },
            makeVuetifyDialogMovable() {
                const d = {};
                document.addEventListener("mousedown", e => {
                    const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
                    if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-toolbar__content")) { // element which can be used to move element
                        d.el = closestDialog; // element which should be moved
                        d.mouseStartX = e.clientX;
                        d.mouseStartY = e.clientY;
                        d.elStartX = d.el.getBoundingClientRect().left;
                        d.elStartY = d.el.getBoundingClientRect().top;
                        d.el.style.position = "fixed";
                        d.el.style.margin = 0;
                        d.oldTransition = d.el.style.transition;
                        d.el.style.transition = "none"
                    }
                });
                document.addEventListener("mousemove", e => {
                    if (d.el === undefined) {
                        return;
                    }
                    d.el.style.left = Math.min(
                        Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
                        window.innerWidth - d.el.getBoundingClientRect().width
                    ) + "px";
                    d.el.style.top = Math.min(
                        Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
                        window.innerHeight - d.el.getBoundingClientRect().height
                    ) + "px";
                });
                document.addEventListener("mouseup", () => {
                    if (d.el === undefined) {
                        return;
                    }
                    d.el.style.transition = d.oldTransition;
                    d.el = undefined
                });
                setInterval(() => { // prevent out of bounds
                    const dialog = document.querySelector(".v-dialog.v-dialog--active");
                    if (dialog === null) {
                        return;
                    }
                    dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
                    dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
                }, 100);
            },
            ...mapCacheActions([
                "initCategories",
            ])
        },
        components: {
            HeaderBar,
            Pusher,
            SingleProduct,
            Order,
            RefundOrder,
            CancelOrder,
            ProductDescriptions,
            ProductSpecifics,
            JqxNotification,
            ProductForm,
            Defects
        }
    }
</script>

<style>
    html, body, #cam {
        overflow: hidden
    }
    .v-data-table tbody tr:nth-of-type(even) {
        background-color: #fff;
    }
    .v-data-table tbody tr:nth-of-type(odd) {
        background-color: #fafafa;
    }

    .order-tabs, .order-tabs .v-window__container {
        height: 100% !important;
    }

    #order-tab-items, #tab-fedex-ship, .fedex-ship {
        height: 100% !important;
    }

    .jqx-grid-column-header {
        z-index: inherit !important;
    }

    .jqx-scrollbar {
        z-index: inherit !important;
    }

    .Paid {
        color:red !important;
    }
    .Paid.jqx-fill-state-pressed-material {
        color:red !important;
    }

    .Shipped {
        color:blue !important;
    }
    .Shipped.jqx-fill-state-pressed-material  {
        color:blue !important;
    }

    .Delivered, .order0 {
        color:green !important;
    }
    .Delivered.jqx-fill-state-pressed-material {
        color:green !important;
    }

    .Pending {
        color:#666666 !important;
    }
    .Pending.jqx-fill-state-pressed-material  {
        color:#666666 !important;
    }

    .Canceled {
        color:#C3F !important;
    }
    .Canceled.jqx-fill-state-pressed-material  {
        color:#C3F !important;
    }

    .Refunded, .PartialRefunded {
        color:#F0F !important;
    }
    .Refunded.jqx-fill-state-pressed-material, .PartialRefunded.jqx-fill-state-pressed-material  {
        color:#F0F !important;
    }

    .Combined {
        color:#0FF !important;
    }
    .Combined.jqx-fill-state-pressed-material  {
        color:#0FF !important;
    }
    .v-text-field input, .v-textarea textarea {
        opacity: unset;
    }

</style>