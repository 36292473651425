<template>
    <v-row>
        <v-col>
            <emails :emailList="emailList" class="ma-1"></emails>
        </v-col>
    </v-row>

</template>

<script>
    import Emails  from '@/components/Emails'
    import {mapGetters} from "vuex";

    export default {
        name: "ProductEmail",
        props:{
            isActive:{},
        },
        data(){
            return {
                emailList: [],
            }
        },
        computed:{
            ...mapGetters([
                'productId',
            ]),
        },
        mounted() {
            this.init()

        },
        methods: {
            init(){
                axios.get(`/api/customers_email?
                    filterGroups[0][field]=products_id
                    &filterGroups[0][filters][0][value]=${this.productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=and
                    &pagesize=100000`)
                    .then(response => {
                        this.emailList = response.data.data
                        this.$emit('componentReady')
                        // console.log(this.emailList )
                    }).catch(e => {
                    console.log(e)
                })
            },
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-email"){
                    if(this.productId !== null){
                        this.init()
                    }
                }
            },
        },
        components: {
            Emails
        }
    }
</script>

<style scoped>

</style>