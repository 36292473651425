
const order = {
    state:{
        order: {},
        orderId: null,
        customerId: null,
    },
    mutations: {
        setOrder(state, order){
            state.order = order
        },
        setCustomerId(state, customers_id){
            state.customerId = customers_id
        },
        setOrderId(state, orders_id){
            state.orderId = orders_id
        },
        // updateOrder(state, order){
        //     state.order = order;
        // },
        updateProduct(state, orderProduct){
            const productIndex = state.order.order_products.findIndex(x => x.products_id === orderProduct.productId);
            state.order.order_products[productIndex] = orderProduct.product;
            // console.log('mutation updateProduct',state.order.order_products)
        },
        deleteProduct(state, orderProductId){
            const productIndex = state.order.order_products.findIndex(x => x.products_id === orderProductId);
            state.order.order_products.splice(productIndex, 1);
        },
        addProduct(state, newProduct){
            state.order.order_products.push(newProduct)
        }
    },
    actions: {
        fetchOrder ({ commit }, id) {
            commit('setOrder', {})
            commit('setCustomerId', null)
            commit('setOrderId', null)
            return axios
                .get(`/api/orders/${id}`, {
                        params: {
                            'with': [
                                'order_products.product.descriptions',
                                //'order_products.product:products_id,products_weight,targetcatid',
                                'order_total',
                                'order_shipping',
                                'order_sub_total',
                                'ebay_site',
                                'delivery_address_country.geo_zone',
                                'order_ebayfees',
                                'order_ebaytotal',
                                'order_note'
                            ]
                        }
                    }
                )
                .then(response => {
                    commit('setOrder', response.data.data[0])
                    commit('setCustomerId', response.data.data[0].customers_id)
                    commit('setOrderId', response.data.data[0].orders_id)
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Order is not present.');
                })
        },
        updateOrder(context, order){
            return axios
                .put(`/api/orders/${context.state.orderId}`,  order)
                .then((response) => {
                    console.log(response)
                    context.commit('setOrder', order)
                })
                .catch(error => console.log(error))
        },
        updateOrderProducts(context, orderProduct){
            axios.put(`/api/orders_products/${orderProduct.orders_products_id}`, orderProduct)
                .then((response) => {
                    context.commit('updateProduct', {'productId': orderProduct.products_id, 'product':orderProduct})
                    context.state.order.order_sub_total.value = context.state.order.order_products.reduce(function(a, c){return a + Number((c.products_quantity*c.products_price) || 0)}, 0)
                    context.state.order.order_total.value = context.state.order.order_sub_total.value + context.state.order.order_shipping.value
                    context.dispatch('updateOrder', context.state.order);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        deleteOrderProducts(context, payload){
            axios.delete(`/api/orders_products/${payload.orderProductId}`)
                .then((response) => {
                    context.commit('deleteProduct', payload.productId)
                    context.dispatch('fetchOrder', context.state.order.orders_id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        newProductAdd(context, newProduct){
            axios.post('/api/orders_products', newProduct)
                .then(response => {
                    let prod = response.data
                    context.commit('addProduct', prod)
                    context.dispatch('fetchOrder', prod.orders_id)
                })
        },

        newOrderAdd({commit}, newOrder){
            return axios.post('/api/orders', newOrder)
                .then(response => {
                    console.log(response.data.orders_id)
                    commit('setOrder', response.data)
                    commit('setOrderId', response.data.orders_id);
                    //context.dispatch('fetchOrder', response.data.orders_id)
                })
                /*newOrder.orders_id = Math.ceil(Math.random() * 10);
                newOrder.customerId = Math.ceil(Math.random() * 10);
                newOrder.order_products = [];
                newOrder.currency = 'US';
                newOrder.order_products = [];

                commit('setOrder', newOrder)
                commit('setCustomerId', newOrder)
                commit('setOrderId', newOrder);*/
        },
        refundOrderProduct(context, data) {
            return axios.put('/api/orders/refund/'+data[0].orders_id, data)
                .then((response) => {
                    context.commit('setOrder', response.data)
                });
        },
        cancelOrderProducts(context, data) {
            return axios.put('/api/orders/cancel/'+data[0].orders_id, data)
                .then(response => {
                    console.log(response);
                    context.commit('setOrder', response.data)
                })
        },
        getEbayPayment(context, data) {
            return axios.get('/api/orders/EbayPayments/'+data.orders_id)
                .then(response => {
                    //console.log(response);
                    //context.commit('setOrder', response.data)
                })
        }
    },
    getters: {
        order: state => state.order,
        customerId: state => state.customerId,
        orderId:  state => state.orderId,
    }
}

export default order