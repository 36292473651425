<template>
  <v-container v-if="item" class="product-item ma-0 pa-0">
    <v-row class="ma-1" no-gutters>
      <v-col>
        <v-text-field
            v-if="isGTC"
            v-model="price"
            :rules="rules"
            class="font-weight-regular currency-select ma-0 pa-0"
            dense
            hide-details
            reverse
            single-line
            @change="editInfo"
        ></v-text-field>
        <v-text-field
            v-if="isAuction"
            v-model="start_price"
            :rules="rules"
            class="font-weight-regular currency-select ma-0 pa-0"
            dense
            hide-details
            reverse
            single-line
            @change="editInfo"
        >
          <template v-slot:prepend>
            <v-tooltip
                bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="red" small>
                  mdi-alert-circle-outline
                </v-icon>
              </template>
              Auctions!!!
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="pl-2">
        <v-select v-model="currency" :cache-items=true :items="currencies"
                  :menu-props="{ auto: true, offsetY: true }"
                  class="caption font-weight-regular currency-select pr-1 ma-0 pa-0"
                  dense
                  hide-details
                  label=""
                  single-line
                  style="min-width:55px;"
                  @change="editInfo"
                  @input=""
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="d-flex justify-space-between text-center" cols="12">
        <v-spacer></v-spacer>
        <v-checkbox v-model="autoDisc" class="ma-0 pt-2" dense false-value="547" hide-details true-value="548">
          <template #prepend>
            <v-label>Auto Discount:</v-label>
          </template>
        </v-checkbox>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="d-flex justify-space-between text-center" cols="12">
        <v-spacer></v-spacer>
        <v-select v-model="discount" :cache-items=true :items="productDiscountList"
                  :menu-props="{ auto: true, offsetY: true }"
                  class="caption font-weight-regular currency-select pr-1 ma-0 pa-0"
                  dense
                  hide-details
                  item-text="products_options_values_name"
                  item-value="products_options_values_name"
                  label=""
                  single-line
                  style="width:75px; max-width: 75px; margin-top: -4px !important;"
                  @input=""
        >
          <template #prepend>
            <v-label>%:</v-label>
          </template>
          <template v-slot:selection="{ item, index }">
            <div :class="'discount-'+item.products_options_values_name">{{ item.products_options_values_name }}</div>
          </template>
          <template v-slot:item="data">
            <div :class="'discount-'+data.item.products_options_values_name">{{
                data.item.products_options_values_name
              }}
            </div>
          </template>
        </v-select>
        <v-spacer></v-spacer>
      </v-col>
      <v-col v-if="item.purchase_price" class="text-center font-weight-medium text-caption" cols="12">
        Purchase: {{ item.purchase_price.products_price }} {{ item.purchase_price.currencies_code }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="bestoffer_min"
            :rules="rules"
            class="font-weight-regular currency-select ma-0 ml-4 mr-4 pa-0"
            dense
            hide-details
            reverse
            single-line
            @change="editInfo"
        >
          <template v-slot:prepend>
            <v-label>BOMIN:</v-label>
          </template>
          <template v-slot:append-outer>
            <v-label>%</v-label>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "ProductPrice",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: [
        value => !!value || 'Required.',
        value => value !== 0 || '> 0',
      ],
      initialItem: {}
    }
  },
  computed: {
    start_price: {
      get() {
        if (this.item.start_price[this.item.base_currency]) {
          return parseFloat(
              (
                  (this.item.start_price[this.item.base_currency].products_price
                      || this.item.start_price[this.item.base_currency].products_price !== ''
                  ) ? this.item.start_price[this.item.base_currency].products_price : 0
              )
          )
        } else return 0;

      },
      set(val) {
        if (!this.item.start_price[this.item.base_currency]) this.item.start_price[this.item.base_currency] = {}
        this.item.start_price[this.item.base_currency].products_price = val
        this.item.start_price[this.item.base_currency].currency = this.item.base_currency
        this.item.start_price[this.item.base_currency].start = 1
      }
    },
    price: {
      get() {
        if (this.item.price[this.item.base_currency]) {
          return parseFloat(((this.item.price[this.item.base_currency].products_price || this.item.price[this.item.base_currency].products_price !== '') ? this.item.price[this.item.base_currency].products_price : 0))
        } else return 0;

      },
      set(val) {
        if (!this.item.price[this.item.base_currency]) this.item.price[this.item.base_currency] = {}
        this.item.price[this.item.base_currency].products_price = val
        this.item.price[this.item.base_currency].currency = this.item.base_currency
      }
    },
    bestoffer_min: {
      get() {
        if (this.item.offer_range) {
          let bestoffer_range = this.item.offer_range.split(';')
          if (bestoffer_range.length) {
            return parseFloat(bestoffer_range[0])
          }
        }
        return "";
      },
      set(val) {
        if (val !== "") this.item.offer_range = val + ';'
      }
    },
    currency: {
      get() {
        return this.item.base_currency
      },
      set(val) {
        if (val !== this.item.base_currency) {
          if (!this.item.price[val]) {
            this.item.price[val] = {}
            this.item.price[val].products_price = 0;
            this.item.price[val].currency = val;
          }
          this.item.base_currency = val
        }
      }
    },
    currencies() {
      return this.currenciesList
    },
    ...mapGetters([
      'currenciesList',
      'productOptions'
    ]),
    productDiscountList() {
      if (this.productOptions) {
        return this.productOptions[26].options_value
      } else return []
    },
    autoDisc: {
      get() {
        return this.getAttributeValueById(16)
      },
      set(val) {
        this.setAttributeValueById(16, val)
        this.editInfo()
      }

    },
    isGTC() {
      return this.getAttributeValueById(8) === '30'
    },
    isAuction() {
      return this.getAttributeValueById(8) === '31'
    },
    discount: {
      get() {
        if (this.item.discount) {
          return {products_options_values_name: this.item.discount.persent};
        } else if (this.item.products_attributes && this.item.products_attributes[26] && this.item.products_attributes[26].options_value) {
          return {products_options_values_name: this.item.products_attributes[26].options_value[0].products_options_values_name};
        } else return {products_options_values_name: 0};
      },
      set(val) {
        if (this.item.discount) {
          this.item.discount.persent = val
        } else {
          this.item.discount = {};
          this.item.discount.persent = val
        }
        this.editInfo();
      }

    },
  },
  mounted() {
    this.initialItem = _.cloneDeep(this.item)
  },
  methods: {
    difference(object, base, excluded = []) {
      return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key]) || excluded.includes(key)) {
          result[key] = value
        }
      });
    },
    editInfo() {
      this.$emit('productChanged', this.difference(this.item, this.initialItem, ['products_id']))
      this.initialItem = _.cloneDeep(this.item)
    },
    getAttributeValueById(id) {
      if (this.item.products_attributes[id]) {
        return this.item.products_attributes[id].options_values_id.toString()
      } else return []
    },
    setAttributeValueById(id, value) {
      if (this.item.products_attributes[id]) {
        this.item.products_attributes[id].options_values_id = value
      } else {
        this.item.products_attributes[id] = {};
        this.item.products_attributes[id].options_id = id
        this.item.products_attributes[id].options_values_id = value
        this.item.products_attributes[id].products_id = this.item.products_id
      }
      /*if (this.item.products_attributes[id].options_value) {
          this.item.products_attributes[id].options_value[0].products_options_values_id = value
      } else {
          this.item.products_attributes[id].options_value = [];
          this.item.products_attributes[id].options_value.push({products_options_values_id: value})
      }*/
    },
  }
}
</script>

<style scoped>

.v-input__prepend-outer label {
  text-overflow: unset !important;
  max-width: 100%;
  white-space: nowrap;
  font-size: 12px !important;
}

.discount-20 {
  background-color: lawngreen;
  width: 100%;
  height: 100%;
  color: black;
}

.discount-25 {
  background-color: yellow;
  width: 100%;
  height: 100%;
  color: black;
}

.discount-30 {
  background-color: orangered;
  width: 100%;
  height: 100%;
  color: black;
}

</style>

<style>


</style>