var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: { "max-height": "48px", height: "48px" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { dense: "", flat: "", light: "", color: "grey lighten-5" }
            },
            [
              _c("div", {
                staticStyle: {
                  "margin-right": "10px",
                  width: "100%",
                  overflow: "hidden",
                  display: "none"
                },
                attrs: { id: "jqxProgressBar1" }
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { id: "RefreshButton" }, on: { click: _vm.Refresh } },
                [_vm._v("Refresh")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "only-selected", disabled: "" } }, [
                _vm._v("Selected")
              ]),
              _vm._v(" "),
              _c("select", { attrs: { id: "ebay-siteID" } }, [
                _c(
                  "option",
                  {
                    attrs: {
                      value: "0",
                      selected: "selected",
                      "data-iconurl": "/images/flags/1x1/us.svg"
                    }
                  },
                  [_vm._v("United States")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    attrs: {
                      value: "77",
                      "data-iconurl": "/images/flags/1x1/de.svg"
                    }
                  },
                  [_vm._v("Germany")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    attrs: {
                      value: "3",
                      "data-iconurl": "/images/flags/1x1/gb.svg"
                    }
                  },
                  [_vm._v("UK")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    attrs: {
                      value: "71",
                      "data-iconurl": "/images/flags/1x1/fr.svg"
                    }
                  },
                  [_vm._v("France")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    attrs: {
                      value: "101",
                      "data-iconurl": "/images/flags/1x1/it.svg"
                    }
                  },
                  [_vm._v("Italy")]
                ),
                _vm._v(" "),
                _c(
                  "option",
                  {
                    attrs: {
                      value: "186",
                      "data-iconurl": "/images/flags/1x1/es.svg"
                    }
                  },
                  [_vm._v("Spain")]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("div", { attrs: { id: "splitter" } }, [
          _c("div", [_c("div", { attrs: { id: "categoryTable" } })]),
          _vm._v(" "),
          _c("div", [_c("div", { attrs: { id: "categoryTableNested" } })])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }