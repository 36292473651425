var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.orders, function(order) {
              return _c(
                "v-col",
                { key: order.orders_id, attrs: { cols: "4" } },
                [
                  _c("v-checkbox", {
                    staticClass: "shrink mt-0",
                    attrs: {
                      dense: "",
                      type: "checkbox",
                      id: "celniorders",
                      value: order.orders_id,
                      "input-value": order.orders_id,
                      label: "" + order.orders_id,
                      "true-value": order.orders_id,
                      "hide-details": "",
                      multiple: ""
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.productTypes,
                      label: "Select type of product",
                      "persistent-hint": "",
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.selectedProductType,
                      callback: function($$v) {
                        _vm.selectedProductType = $$v
                      },
                      expression: "selectedProductType"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.selected.length === 0 },
              on: { click: _vm.getCelnicaFile }
            },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }