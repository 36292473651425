<template>
    <v-card>
        <v-card-text class="pt-4" v-show="EbayPaypal">
            <v-row
                no-gutters
                v-show="EbayPaypal['ebay']"
            >
                <v-col
                    cols="12"
                    v-for="(order_products, index) in EbayPaypal['ebay']"
                    :key="index"
                >
                    <strong>eBay:</strong>
                    <a
                        v-if="order_products.ebay_receipt"
                        @click="openLink(order_products.ebay_receipt)"
                        :class="!order_products.is_stored ? 'font-weight-bold' : ''"

                    >{{ order_products.products_name }}</a>
                    <span v-else>{{ order_products.products_name }}</span>
                </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row no-gutters>
                <v-col
                    cols="12"
                    v-if="EbayPaypal['paypal']"
                >
                    <strong>PayPal: </strong>
                     <a @click="openLink(EbayPaypal['paypal'])">
                         <strong>Instant payment received!</strong>
                     </a>
                </v-col>
<!--                <v-col
                    cols="12"
                    v-else
                >
                    <strong>PayPal: </strong>Instant payment could not be found!
                </v-col>-->
            </v-row>
        </v-card-text>
    </v-card>
    
</template>

<script>
    export default {
        name: "PrintDialog",
        props: {
            EbayPaypal: {
                required: true
            }
        },
        methods: {
            openLink(page) {
                window.open("/api/gstorage/get?filename="+page, '_blank')
            }
        }
    }
</script>