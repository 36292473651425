var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "formAuto", staticClass: "auto-reply" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", sm4: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Accept",
                  rules: _vm.acceptRules,
                  solo: "",
                  height: "28"
                },
                model: {
                  value: _vm.accept,
                  callback: function($$v) {
                    _vm.accept = $$v
                  },
                  expression: "accept"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs10: "", sm4: "" } },
            [
              _c("v-text-field", {
                class: {
                  "ml-0": _vm.$vuetify.breakpoint.smAndDown,
                  "ml-1": _vm.$vuetify.breakpoint.mdAndUp
                },
                attrs: {
                  label: "Decline",
                  rules: _vm.declineRules,
                  solo: "",
                  height: "28"
                },
                model: {
                  value: _vm.decline,
                  callback: function($$v) {
                    _vm.decline = $$v
                  },
                  expression: "decline"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs2: "", sm4: "" } },
            [
              _c(
                "v-btn",
                {
                  class: {
                    "ml-0": _vm.$vuetify.breakpoint.smAndDown,
                    "ml-1": _vm.$vuetify.breakpoint.smAndUp
                  },
                  attrs: {
                    small: "",
                    icon: "",
                    disabled: _vm.accept === "" && _vm.decline === "",
                    loading: _vm.loading
                  },
                  on: { click: _vm.saveNewValues }
                },
                [_c("v-icon", [_vm._v("save")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }