const ReusableProduct = {
    namespaced: true,
    state:{
        product: null,
        productId: null,
        productStatus: null,
        // priceHistoryData: null,
        productPropertyId: {
            bestoffer: 11,
            weight: 3,
            condition: 2,
            repair: 15,
            freeship: 7,
            colony: 25,
            autodisc: 16,
            GTCCom: 8,
            GTCDe: 9,
            CMCom: 23 //upload on cameramate.com
        },
        productsAttributes: null,
        productBestOffer: null,
        helperMethods: {
            getAttributeValueById: (state, id) => {
                if (state.productsAttributes && state.productsAttributes[id]) {
                    //return state.productsAttributes[id].options_values_id.toString()
                    return state.productsAttributes[id]
                } else return {
                    options_id: id,
                    options_values_id: '',
                    products_id: state.productId ?? 0,
                    products_attributes_id: 0
                }
            },
            setAttributeValueById(state, id, value){
                if(state.productsAttributes[id]){
                    state.productsAttributes[id].options_values_id = value
                } else {
                    state.productsAttributes[id] = {};
                    state.productsAttributes[id].options_id = id
                    state.productsAttributes[id].options_values_id = value
                    state.productsAttributes[id].products_id = state.productId ?? 0
                    state.productsAttributes[id].products_attributes_id = 0
                }
            },
        }
    },
    mutations: {
        setProduct(state, product){
            state.product = product
        },
        setProductId(state, products_id){
            state.productId = products_id
        },
        setProductStatus(state, status){
            state.productStatus = status
        },
        setProductBestOffer(state, attribute) {
            state.productBestOffer = attribute.options_values_id
            state.helperMethods.setAttributeValueById(state, attribute.options_id, attribute.options_values_id)
        },
        setProductAttribute(state, attribute) {
            state.helperMethods.setAttributeValueById(state, attribute.options_id, attribute.options_values_id)
        },
        setProductAttributes(state, attributes) {
            if (attributes) state.productsAttributes = _.keyBy(attributes, 'options_id')
        }
        // setPriceHistoryData(state, priceHistoryData){
        //     state.priceHistoryData = priceHistoryData
        // }
    },
    actions: {
        fetchProduct ({ commit }, request) {
            let productsId = request
            let params = {}
            if (typeof request === 'object') {
                productsId = request.id
                params = request.params
            }
            /*}*/
            commit('setProduct', null)
            commit('setProductId', null)
            commit('setProductStatus', null)
            const defaultParams = {
                'filterGroups[0][field]': 'products_id',
                'filterGroups[0][filters][0][value]': productsId,
                'filterGroups[0][filters][0][condition]': 'EQUAL',
                'filterGroups[0][filters][0][type]': 'numericfilter',
                'filterGroups[0][filters][0][operator]': 'or',
                'filterGroups[0][operator]': 'or',
                'with': [
                    'descriptions',
                    'product_set'
                ]
            };

            const allparams = {...defaultParams, ...params}
            //Object.assign( {}, defaultParams, params );

            return axios
                .get('/api/products'
                    // filterGroups[0][field]=products_id
                    // &filterGroups[0][filters][0][value]=${id}
                    // &filterGroups[0][filters][0][condition]=EQUAL
                    // &filterGroups[0][filters][0][type]=numericfilter
                    // &filterGroups[0][filters][0][operator]=or
                    // &filterGroups[0][operator]=or
                    // &with[]=descriptions
                    // &with[]=product_set`
                    , {
                    params: allparams
                })
                .then(response => {
                    if (response.data && response.data.data && response.data.data.length) {

                        if (response.data.data[0].products_attributes) commit('setProductAttributes', response.data.data[0].products_attributes)

                        if (response.data.data[0].products_attributes) {
                            response.data.data[0].products_attributes = _.keyBy(response.data.data[0].products_attributes, 'options_id')
                        }
                        if (response.data.data[0].price) {
                            response.data.data[0].price = _.keyBy(response.data.data[0].price, 'currency')
                        }
                        if (response.data.data[0].products_for_ebay) {
                            response.data.data[0].products_for_ebay = _.keyBy(response.data.data[0].products_for_ebay, 'siteID')
                        }
                        if (response.data.data[0].products_on_ebay) {
                            response.data.data[0].products_on_ebay = _.keyBy(response.data.data[0].products_on_ebay, 'siteID')
                        }
                        if (response.data.data[0].ebay_categories) {
                            response.data.data[0].ebay_categories = _.keyBy(response.data.data[0].ebay_categories, 'siteID')
                        }
                        commit('setProduct', response.data.data[0])
                        commit('setProductId', response.data.data[0].products_id)
                        commit('setProductStatus', response.data.data[0].products_status.toString())
                    }
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Product is not present.');
                })
        },
        updateProductStatus({commit}, statusVal) {
            this.updateProduct({commit}, {products_id: this.products_id, products_status: statusVal})
        },
        updateProduct({commit}, product) {
            return axios.put(`/api/products/${product.products_id}`, product)
                .then(response => {
                    //commit('setProduct', response.data)
                    //commit('setProductId', response.data.products_id)
                    //commit('setProductStatus', response.data.products_status.toString())
                })
        },
        updateState ({ commit }, state) {
            return new Promise((resolve, reject) => {
                commit('set'+_.upperFirst(state.property), state.value)
                resolve()
            })
        },
        updateProductProperty ({ commit }, payload ) {
            return new Promise((resolve, reject) => {
                commit('setProductAttribute', payload.value)
                /*switch (payload.property) {
                    case "bestoffer":
                        commit('setProductBestOffer', payload.value)
                        break;
                }*/
                resolve()
            })
        }
    },
    getters: {
        product: state => state.product,
        productId:  state => state.productId,
        productStatus:  state => state.productStatus,
        productBestOffer: state => state.helperMethods.getAttributeValueById(state, 11),
        product_: (state) => (property) => {
            return state.helperMethods.getAttributeValueById(state, state.productPropertyId[property])
        },
        productsAttributes: (state) => {
            return state.productsAttributes
        }
        // priceHistoryData: state => state.priceHistoryData,
    }
}

export default ReusableProduct;