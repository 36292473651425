<template>
    <div class="product-filters">
        <v-navigation-drawer
                v-model="isDrawerOpen"
                @keydown.esc="closeMenu"
                light
                right
                disable-resize-watcher
                disable-route-watcher
                fixed
                temporary
                absolute
                width="300"
                class="px-4 pt-2"
        >
            <v-form>
                <v-row>
                    <v-col cols="12" class="mt-1 ma-0 pa-0">
                        <p class="title ma-0 pa-0">Product filters</p>
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <v-select dense
                                  clearable
                                  v-model="form.products_status"
                                  :items="statuses"
                                  label="Status:"
                                  @change="onChange($event)"
                        >
                        </v-select>
                        <v-select dense
                                  clearable
                                  :cache-items=true
                                  v-model="form.products_weight"
                                  label="Weight"
                                  :items="productWeightsList"
                                  :menu-props="{ auto: true, offsetY: true }"
                                  @change="onChange"
                                  item-text="products_options_values_name"
                                  item-value="products_options_values_name"
                        />
                        <v-select dense
                                  clearable
                                  :cache-items=true
                                  v-model="form.products_attributes.condition.value"
                                  label="Condition"
                                  :items="productConditionsList"
                                  :menu-props="{ auto: true, offsetY: true }"
                                  @change="onChange"
                                  item-text="products_options_values_name"
                                  item-value="products_options_values_id"
                        >
                        </v-select>
                        <v-select dense
                                  clearable
                                  hide-details
                                  :cache-items=true
                                  v-model="form['discount.persent']"
                                  label="Discount"
                                  :items="productDiscountList"
                                  :menu-props="{ auto: true, offsetY: true }"
                                  @change="onChange"
                                  item-text="products_options_values_name"
                                  item-value="products_options_values_name"
                        />
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <tree-select
                            class="mb-2"
                            clearable
                            hide-details
                            :options="productCategories"
                            :auto-load-root-options="false"
                            :searchable="true"
                            :normalizer="normalizer"
                            @input="onChange"
                            placeholder="Category"
                            v-model="form['category.categories_id']"
                        />
                        <tree-select
                            clearable
                            hide-details
                            :load-options="loadOptions"
                            :options="productsOptions"
                            :auto-load-root-options="false"
                            :searchable="true"
                            :normalizer="normalizer"
                            @input="onChange"
                            placeholder="Target"
                            v-model="form.targetcatid"
                            style="display: none;"
                        />
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row class="pl-3">
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.otk.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.otk.value)"
                                @change="onChange"
                                true-value="580"
                                false-value="581"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Hold</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.otk.value !== null"
                                        @click="form.products_attributes.otk.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.colony.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.colony.value)"
                                @change="onChange"
                                true-value="600"
                                false-value="601"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Colony</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.colony.value !== null"
                                        @click="form.products_attributes.colony.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.autoDiscount.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.autoDiscount.value)"
                                @change="onChange"
                                true-value="548"
                                false-value="547"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Auto Discount</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.autoDiscount.value !== null"
                                        @click="form.products_attributes.autoDiscount.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.repair.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.repair.value)"
                                @change="onChange"
                                true-value="545"
                                false-value="546"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Repair</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.repair.value !== null"
                                        @click="form.products_attributes.repair.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.cmCom.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.cmCom.value)"
                                @change="onChange"
                                true-value="596"
                                false-value="597"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>CM.com</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.cmCom.value !== null"
                                        @click="form.products_attributes.cmCom.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.gtc.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.gtc.value)"
                                @change="onChange"
                                true-value="30"
                                false-value="31"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>GTC</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.gtc.value !== null"
                                        @click="form.products_attributes.gtc.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.bestOffers.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.bestOffers.value)"
                                @change="onChange"
                                true-value="533"
                                false-value="534"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Bestoffers</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.bestOffers.value !== null"
                                        @click="form.products_attributes.bestOffers.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 pl-2">
                        <v-checkbox
                                v-model="form.products_attributes.freeShip.value"
                                hide-details
                                :indeterminate="isIndeterminate(form.products_attributes.freeShip.value)"
                                @change="onChange"
                                true-value="29"
                                false-value="28"
                                class="checkbox-product-filter">
                            <template #append>
                                <v-label>Free ship</v-label>
                                <v-icon
                                        small
                                        right
                                        color="orange darken-2"
                                        v-show="form.products_attributes.freeShip.value !== null"
                                        @click="form.products_attributes.freeShip.value = null; onChange()"
                                >clear</v-icon>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="mt-3 pt-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <div class="d-flex justify-space-between align-center my-3">
                    <v-btn small @click="clearFilters">Clear</v-btn>
                    <v-btn v-if="isDrawerOpen"
                           small
                           color="green"
                           :disabled="isFilterEnmpty"
                           @click="filterProducts">
                        Filter
                    </v-btn>
                </div>
            </v-form>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import Datepicker from '@/components/CustomDatepicker.vue'
    import {mapGetters} from "vuex";
    import TreeSelect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'



    export default {
        name: "ProductFilters",
        props: {
            drawer: {
                type: Boolean,
                required: true,
            },
            enableFilterWithSearch: {
                type: Boolean,
                required: true,
            },
            filtersLength: {
                type: Number,
                required: true,
            },
        },
        data: () => ({
            form: {
                products_status: null,
                products_weight: null,
                'category.categories_id': null,
                targetcatid: null,
                'discount.persent': null,
                products_attributes: {
                    otk: {
                        options_id: 18,
                        value: null,
                    },
                    colony: {
                        options_id: 25,
                        value: null,
                    },
                    autoDiscount:{
                        options_id: 16,
                        value: null,
                    },
                    repair:{
                        options_id: 15,
                        value: null,
                    },
                    cmCom:{
                        options_id: 23,
                        value: null,
                    },
                    gtc:{
                        options_id: 8,
                        value: null,
                    },
                    bestOffers:{
                        options_id: 11,
                        value: null,
                    },
                    freeShip:{
                        options_id: 7,
                        value: null,
                    },
                    condition:{
                        options_id: 2,
                        value: null,
                    }

                },

            },
            productsOptions: null,
            normalizer(node) {
                if(node && node.descriptions){
                    return {
                        id: node.descriptions.categories_id,
                        label: node.descriptions.categories_name,
                        children: node.children,
                    }
                }
            },
            statuses: [{value: 1, text: "On"}, {value: 0, text: "Off"}],
            formInit: {
                products_status: null,
                products_weight: null,
                'category.categories_id': null,
                targetcatid: null,
                'discount.persent': null,
                products_attributes: {
                    otk: {
                        options_id: 18,
                        value: null,
                    },
                    colony: {
                        options_id: 25,
                        value: null,
                    },
                    autoDiscount:{
                        options_id: 16,
                        value: null,
                    },
                    repair:{
                        options_id: 15,
                        value: null,
                    },
                    cmCom:{
                        options_id: 23,
                        value: null,
                    },
                    gtc:{
                        options_id: 8,
                        value: null,
                    },
                    bestOffers:{
                        options_id: 11,
                        value: null,
                    },
                    freeShip:{
                        options_id: 7,
                        value: null,
                    },
                    condition:{
                        options_id: 2,
                        value: null,
                    }
                },
            },
            filledFilters: {},
            filledAttr: []
        }),
        mounted() {
            const filledFilters = localStorage.getItem("productsFilers");
            if (filledFilters) {
                this.form = JSON.parse(filledFilters)
                this.onChange()
            }
        },
        computed: {
            isDrawerOpen:{
                get(){
                    return this.drawer;
                },
                set(val){
                    if (!val) this.$emit('closeMenu')
                }
            },
            showFilterButton() {
                return _.size(this.filledFilters) || this.filledAttr.length
            },
            productWeightsList() {
                if (this.productOptions) {
                    return this.productOptions[3].options_value
                } else return []
            },
            productDiscountList() {
                if (this.productOptions) {
                    return this.productOptions[26].options_value
                }
                else return []
            },
            productConditionsList() {
                if (this.productOptions) return this.productOptions[2].options_value
                else return []
            },
            ...mapGetters([
                'productOptions',
                'productCategories',
            ]),
            isFilterEnmpty: function () {
                return _.isEmpty(this.filledFilters)
            }
        },
        methods:{
            closeMenu(){
                this.$emit('closeMenu')
            },
            onChange(){
                localStorage.setItem('productsFilers', JSON.stringify(this.form))
                this.filledFilters = Object.keys(this.form)
                    .filter(e => (this.form[e] !== null && this.form[e] !== undefined && typeof this.form[e] !== 'object' ))
                    .reduce( (o, e) => {
                        o[e]  = this.form[e]
                        return o;
                    }, {});

                this.filledAttr = Object.entries(this.form.products_attributes)
                    .map((item) => (item[1]))
                    .filter(({id, value}) => value !== null && value !== false  && value !== undefined)
            },
            filterProducts(){
                this.$emit('OnEnableFilterWithSearch');
                this.$emit('filterProducts', {'filledFilters': this.filledFilters,"filledAttr": this.filledAttr});
            },
            async loadOptions({action}){
                if (action === LOAD_ROOT_OPTIONS) {
                    this.productsOptions = this.productCategories;
                }
            },
            clearFilters(){
                this.form = _.cloneDeep(this.formInit);
                this.filledAttr = [];
                this.filledFilters = {};
                if (this.filtersLength > 0) this.filterProducts()
                else this.$emit('closeMenu')
            },
            isIndeterminate(value){
                return !value;
            },
        },
        components:{
            Datepicker,
            TreeSelect
        }
    }
</script>

<style scoped>

    .v-label{
        font-size: 12px;
        max-width: 100%;
    }

    .filter-button{
        top: 100px;
        right:0;
        position: fixed;
    }

    .filters-container{
        position: fixed;
        width: 256px;
        height: 80%;
        transform: translateX(-236px);
        top: 48px;
    }


</style>
<style>

    .checkbox-product-filter {
        margin: 0;
    }

    .checkbox-product-filter .v-input__control {
        width: 25px !important;
        max-width: 25px !important;
    }
    .checkbox-product-filter .v-input__append-outer {
        text-align: left !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-left: 0;
        margin-right: 0;
        padding-left: 4px;
    }
/*
    .product-filters-drawer .v-navigation-drawer__content{
        padding-top: 8px;
    }

    .product-filters-drawer .v-input--selection-controls .v-input__prepend-outer{
        margin-right: 0;
    }
*/
    .product-filters-drawer  .vue-treeselect__control{
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        color: rgba(0,0,0,.6)

    }

    .product-filters-drawer .vue-treeselect__placeholder{
        color: rgba(0,0,0,.6);
    }

</style>