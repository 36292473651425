var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "8", md: "4" } },
        [
          _c(
            "v-card",
            { staticClass: "elevation-12" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary" } },
                [_c("v-toolbar-title", [_vm._v("Register")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "register", attrs: { "aria-label": "Register" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.email,
                                    _vm.rules.max255chars
                                  ],
                                  label: "Email",
                                  name: "email"
                                },
                                model: {
                                  value: _vm.input.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "email", $$v)
                                  },
                                  expression: "input.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                ref: "password",
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.max255chars,
                                    _vm.rules.min6chars
                                  ],
                                  label: "Password",
                                  name: "password",
                                  type: "password"
                                },
                                model: {
                                  value: _vm.input.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.input, "password", $$v)
                                  },
                                  expression: "input.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.passwordConfirmationRules,
                                  label: "Password Confirm",
                                  name: "password_confirmation",
                                  type: "password"
                                },
                                model: {
                                  value: _vm.input.password_confirmation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.input,
                                      "password_confirmation",
                                      $$v
                                    )
                                  },
                                  expression: "input.password_confirmation"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { loading: _vm.loading, color: "primary" },
                          on: { click: _vm.validate }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.message) +
                      "\n                "
                  ),
                  _vm.url
                    ? _c("div", [
                        _c("p", [
                          _vm._v(
                            "Click on the URL to be directed to the personalized app login page"
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("a", { attrs: { href: _vm.url } }, [
                            _vm._v(_vm._s(_vm.url))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "mb-0",
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }