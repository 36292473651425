<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://admin:a1z5c6d8@cameramate.com/tools/orders_status.php?disableMenu=1"></iframe>
</template>

<script>
    export default {
        name: "OrdersStatus"
    }
</script>

<style scoped>

</style>