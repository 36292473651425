<template>
    <v-layout align-space-around justify-start column fill-height >
        <v-flex xs12 style="max-height: 48px; height: 48px;">
            <v-toolbar dense flat light color="grey lighten-5">
                <v-spacer></v-spacer>
                <v-btn id="RefreshButton">Refresh</v-btn>
                <v-btn id="CalcButton">Calc all</v-btn>
                <v-btn id="OnButton">ON all</v-btn>
                <v-btn id="OffButton" disabled>OFF all</v-btn>
                <div id="ebay-siteID">
                </div>
            </v-toolbar>
        </v-flex>
        <v-flex xs12 class="pa-1">
            <div id="productTable"></div>
        </v-flex>
        <confirm ref="confirm"/>
    </v-layout>
</template>

<script>
    import JqxGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid.vue';
    import { mapGetters } from 'vuex'
    import EventBus from "@/event-bus";
    import Confirm from '@/components/Confirm'

    export default {
        name: "Products",
        components: {
            JqxGrid,
            Confirm
        },
        data() {
            return {
                selectedSiteId: 0
            }
        },
        mounted() {

            let thisis = this;

            $('#RefreshButton').click(function() {
                $('#productTable').jqxGrid('updateBoundData');
            });

            let save_enabled = true;

            let source = {
                datatype: "json",
                datafields: [
                    { name: 'products_id', type: 'number' },
                    { name: 'descriptions.products_name', map: 'descriptions>products_name', type: 'string'},
                    { name: 'categories_id', map: 'category>categories_id', type: 'number'},
                    { name: 'base_currency', type: 'string'},
                    { name: 'products_price', map: 'products_price_EUR', type: 'float'},
                    { name: 'products_price_origin', map: 'products_price', type: 'float'},
                    { name: 'purchase_currency', map: 'purchase_price>currencies_code', type: 'string'},
                    { name: 'purchase_price', map: 'purchase_price>purchase_price_EUR', type: 'float'},
                    { name: 'discount', map: 'discount>persent', type: 'number'},
                    { name: 'margin', type: 'float'},
                    { name: 'products_for_ebay'},
                    { name: 'products_on_ebay.siteID'},
                    { name: 'products_on_ebay.ItemID'},
                    { name: 'ebay_categories'},
                    { name: 'ebay_categories_update'},
                    { name: 'products_status', type: 'number'},
                    { name: 'products_on_ebay'},
                    { name: 'products_attributes'},

                    //US
                    { name: 'ebay_site_0_id', map: 'products_for_ebay>0>id', type: 'number'},
                    { name: 'ebay_site_0_price', map: 'products_for_ebay>0>price', type: 'float'},
                    { name: 'ebay_site_0_enabled', map: 'products_for_ebay>0>enabled'},
                    { name: 'ebay_site_0_rate', map: 'products_for_ebay>0>ebay_site>currency>value', type: 'float'},

                    //DE
                    { name: 'ebay_site_77_id', map: 'products_for_ebay>77>id', type: 'number'},
                    { name: 'ebay_site_77_price', map: 'products_for_ebay>77>price', type: 'float'},
                    { name: 'ebay_site_77_enabled', map: 'products_for_ebay>77>enabled'},
                    { name: 'ebay_site_77_rate', map: 'products_for_ebay>77>ebay_site>currency>value', type: 'float'},

                    //UK
                    { name: 'ebay_site_3_id', map: 'products_for_ebay>3>id', type: 'number'},
                    { name: 'ebay_site_3_price', map: 'products_for_ebay>3>price', type: 'float'},
                    { name: 'ebay_site_3_enabled', map: 'products_for_ebay>3>enabled'},
                    { name: 'ebay_site_3_rate', map: 'products_for_ebay>3>ebay_site>currency>value', type: 'float'},

                    //FR
                    { name: 'ebay_site_71_id', map: 'products_for_ebay>71>id', type: 'number'},
                    { name: 'ebay_site_71_price', map: 'products_for_ebay>71>price', type: 'float'},
                    { name: 'ebay_site_71_enabled', map: 'products_for_ebay>71>enabled'},
                    { name: 'ebay_site_71_rate', map: 'products_for_ebay>71>ebay_site>currency>value', type: 'float'},

                    //IT
                    { name: 'ebay_site_101_id', map: 'products_for_ebay>101>id', type: 'number'},
                    { name: 'ebay_site_101_price', map: 'products_for_ebay>101>price', type: 'float'},
                    { name: 'ebay_site_101_enabled', map: 'products_for_ebay>101>enabled'},
                    { name: 'ebay_site_101_rate', map: 'products_for_ebay>101>ebay_site>currency>value', type: 'float'},

                    //ES
                    { name: 'ebay_site_186_id', map: 'products_for_ebay>186>id', type: 'number'},
                    { name: 'ebay_site_186_price', map: 'products_for_ebay>186>price', type: 'float'},
                    { name: 'ebay_site_186_enabled', map: 'products_for_ebay>186>enabled'},
                    { name: 'ebay_site_186_rate', map: 'products_for_ebay>186>ebay_site>currency>value', type: 'float'},

                ],
                pagesize: 10,
                id: 'products_id',
                url: '/api/products_for_ebay',
                root: 'data',
                beforeprocessing: function (data) {
                    source.totalrecords = data.total;
                },
                sortcolumn: 'products_id',
                sortdirection: 'desc',
                sort: function () {
                    $("#productTable").jqxGrid('updatebounddata', 'sort');
                },
                filter: function () {
                    $("#productTable").jqxGrid('updatebounddata', 'filter');
                },
                updaterow: function (rowid, rowdata, commit) {
                    // synchronize with the server - send update command
                    // call commit with parameter true if the synchronization with the server is successful
                    // and with parameter false if the synchronization failder.
                    //console.log(rowdata);
                    //console.log(rowid);
                    /*$.ajax( {
                        'url': "/api/products_for_ebay/"+row.id,
                        'type': 'PUT',
                        'data': row
                    });*/
                    commit(true);
                },
            };
            let dataAdapter = new $.jqx.dataAdapter(source, {
                formatData: function (data) {
                    data.page = data.pagenum+1;
                    return data;
                },
                processData: function (data) {
                    let item = $("#ebay-siteID").jqxDropDownList('getSelectedItem');
                    data.ebay_siteID = item.value;
                }
            });

            $("#ebay-siteID").jqxDropDownList({ width: 150, height: 38, source: this.ebay_global_sites, displayMember: "country", valueMember: "value", autoDropDownHeight: true, selectedIndex: 0});

            //$("#ebay-siteID").jqxDropDownList('disableAt', 1 );
            $("#ebay-siteID").jqxDropDownList('disableAt', 4 );
            $("#ebay-siteID").jqxDropDownList('disableAt', 5 );

            $('#ebay-siteID').on('select', function (event) {
                thisis.selectedSiteId = event.args.item.value
                $("#productTable").jqxGrid({columns: productTableColumns()});
                $('#productTable').jqxGrid('updatebounddata');
            });

            $("#productTable").on('cellvaluechanged', function (event)
            {
                let args = event.args;
                let datafield = event.args.datafield;
                let rowBoundIndex = args.rowindex;
                let value = args.newvalue;
                let oldvalue = args.oldvalue;
                let siteID = $("#productTable").jqxGrid('getcolumnproperty', datafield, 'columngroup');
                let data = $('#productTable').jqxGrid('getrowdata', rowBoundIndex);
                //console.log(event);

                switch (datafield) {
                    case 'ebay_categories_update':
                        $('#productTable').jqxGrid({ disabled: true});
                        $.ajax( {
                            dataType: 'json',
                            url: "/api/products_to_ebay_category",
                            type: 'POST',
                            data: value,
                        }).done(function( datas, textStatus, jqXHR ) {
                            let tmp = data.ebay_categories;
                            //console.log(tmp);
                            data.ebay_categories[datas.data.siteID] = datas.data;
                            //console.log(data.ebay_categories);
                            //console.log(datas);
                            $("[class~='ebay_site_"+data.products_id+"-"+datas.data.siteID+"_enabled']").removeClass('bg-warning');
                            $('#productTable').jqxGrid({ disabled: false});
                        });
                        break;
                    case 'ebay_site_'+siteID+'_enabled':
                    case 'ebay_site_'+siteID+'_price':
                        //$('#productTable').jqxGrid('setcolumnproperty', 'ebay_site_'+siteID+'_enabled', 'editable', false);

                        if (datafield.split('_').pop() == 'price' && value == 0) break;

                        if (typeof value === 'boolean') value = (value ? 1 : 0);
                        if (!_.has(data.products_for_ebay[siteID], 'id')) {
                            //console.log(typeof value);
                            data.products_for_ebay[siteID].products_id = data.products_id;
                            data.products_for_ebay[siteID].id = null;
                            data.products_for_ebay[siteID].price = null;
                            data.products_for_ebay[siteID].enabled = null;
                            data.products_for_ebay[siteID][datafield.split('_').pop()] = value;
                            //console.log(data.products_for_ebay[siteID]);
                            $.ajax( {
                                dataType: 'json',
                                url: "/api/products_for_ebay",
                                type: 'POST',
                                data: data.products_for_ebay[siteID]
                            }).done(function( datas, textStatus, jqXHR ) {
                                data.products_for_ebay[siteID].id = datas.data.id;
                                //console.log(data.products_for_ebay[siteID]);
                                //console.log(datas);
                                //$('#productTable').jqxGrid('setcolumnproperty', 'ebay_site_'+siteID+'_enabled', 'editable', true);
                            });
                        } else {
                            data.products_for_ebay[siteID][datafield.split('_').pop()] = value;
                            //console.log(data);
                            $.ajax( {
                                dataType: 'json',
                                url: "/api/products_for_ebay/"+data.products_for_ebay[siteID].id,
                                type: 'PUT',
                                data: data.products_for_ebay[siteID]
                            }).done(function( datas, textStatus, jqXHR ) {
                                //$('#productTable').jqxGrid('setcolumnproperty', 'ebay_site_'+siteID+'_enabled', 'editable', true);
                            });
                        }
                        break;
                }
            });

            let initrowdetails = function (index, parentElement, gridElement, record) {

                let catid = record.ebay_categories[0].pivot.catid.toString();
                let grid = $($(parentElement).children()[0]);
                let sourceNested = {
                    datatype: "json",
                    datafields: [
                        { name: 'id', type: 'int' },
                        { name: 'catid', type: 'int' },
                        { name: 'parentid', type: 'int' },
                        { name: 'name', type: 'string'},
                        { name: 'selected', type: 'int'},
                        { name: 'siteID', type: 'int'},
                        { name: 'leaf', type: 'int'},
                        { name: 'products_count', type: 'int'},
                        { name: 'final_value_fee', type: 'float'},
                        { name: 'final_value_fee_max', type: 'float'},
                    ],
                    pagesize: 20,
                    id: 'id',
                    sortcolumn: 'siteID',
                    sortdirection: 'asc',
                };
                let nestedGridAdapter = new $.jqx.dataAdapter(sourceNested);

                const ebay_global_sites = this.ebay_global_sites;

                if (grid != null) {
                    grid.jqxDataTable(
                        {
                            width: '98%',
                            height: '100%',
                            pageable: false,
                            source: nestedGridAdapter,
                            columnsResize: false,
                            filterable: true,
                            enableBrowserSelection: false,
                            filterHeight: 0,
                            showHeader: false,
                            autoRowHeight: false,
                            selectionMode: 'custom',
                            sortable: true,
                            ready: function () {
                                let filtertype = 'numericfilter';
                                let filtergroup = new $.jqx.filter();
                                let filter = filtergroup.createfilter(filtertype, catid, 'EQUAL');
                                filtergroup.addfilter(1, filter);
                                //grid.jqxDataTable('removeFilter','catid');
                                grid.jqxDataTable('addFilter', 'catid', filtergroup);

                                //filter US and DE
                                let filtergroup_2 = new $.jqx.filter();
                                filter = filtergroup.createfilter(filtertype, '101,186', 'NOT_IN');
                                filtergroup_2.addfilter(1, filter);
                                grid.jqxDataTable('addFilter', 'siteID', filtergroup_2);

                                grid.jqxDataTable('applyFilters');
                                sourceNested.url = '/api/ebay_category';
                                grid.jqxDataTable('updateBoundData', 'filter');
                            },
                            columns: [
                                { text: 'siteID', dataField: 'siteID', cellsAlign: 'center', width: 38, editable: false,
                                    // row - row's index.
                                    // column - column's data field.
                                    // value - cell's value.
                                    // rowData - rendered row's object.
                                    cellsRenderer: function (row, column, value, rowData) {
                                        let image = "<div style='margin: 5px; margin-bottom: 3px;'>";
                                        //let img = '<span class="flag-icon flag-icon-'+ebay_global_sites[rowData.siteID].name+'"></span>';
                                        let img = '<span>'+rowData.siteID+'</span>';
                                        image += img;
                                        image += "</div>";
                                        return image;
                                    }
                                },
                                { text: 'ID', dataField: 'catid', cellsAlign: 'center', width: 60, editable: false },
                                { text: 'Name', dataField: 'name', align: 'center', editable: false},
                                { text: 'Selected', dataField: 'selected', cellsAlign: 'center', width: 38, editable: false,
                                    // row - row's index.
                                    // column - column's data field.
                                    // value - cell's value.
                                    // rowData - rendered row's object.
                                    cellsRenderer: function (row, column, value, rowData) {
                                        if (rowData.leaf == 0) return '';
                                        value = _.has(record.ebay_categories, rowData.siteID);
                                        let image = "<div style='margin: 5px; margin-bottom: 3px;'>";
                                        let img = '<input type="checkbox" onclick=\'$("#productTable").jqxGrid("setcellvalue",'+record.boundindex+', "ebay_categories_update", { siteID : '+rowData.siteID+', products_id: '+record.products_id+', catId: '+rowData.catid+'}); this.disabled = "true";\' value="" '+(value ? 'checked=""' : '')+' '+(value != 0 ? 'disabled' : '')+'>';
                                        image += img;
                                        image += "</div>";
                                        return image;
                                    }
                                },
                                { text: 'Fee', dataField: 'final_value_fee', align: 'center', width: 75, cellsFormat :'p2', cellsAlign: 'center', filterable: false, sortable: false, editable: false},
                                { text: 'Max', dataField: 'final_value_fee_max', align: 'center', width: 75, cellsFormat :'f2', cellsAlign: 'center',  filterable: false, sortable: false, editable: false}
                            ]
                        });
                }
            };

            $('#productTable').on('rowexpand', function (event)
            {
                // event arguments.
                var args = event.args;
                // row details.
                var details = args.details;
                //console.log(details);
                let selected = $('#productTable').jqxGrid('getselectedrowindex');
                if (selected != event.args.rowindex) $('#productTable').jqxGrid('hiderowdetails', selected);
                $('#productTable').jqxGrid('selectrow', event.args.rowindex);
            });

            let productTableColumns = function() {
                let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');
                return [
                    { text: 'ID', dataField: 'products_id', width: '60', align: 'center', cellsalign: 'center', editable: false, filtertype: 'textbox', filtercondition: 'CONTAINS',
                        cellClassName: function (row, column, value, data) {
                            if (data.products_status) {
                                return "text-success";
                            }
                            return "text-warning";
                        }
                    },
                    { text: 'Title', dataField: 'descriptions.products_name', align: 'center', editable: false, sortable: false, filtertype: 'textbox', filtercondition: 'CONTAINS',
                        cellsrenderer: function (row, column, value) {
                            let dataRecord = $('#productTable').jqxGrid('getrowdata', row);
                            let html = value;
                            let warning = '';
                            if (_.has(dataRecord.products_attributes, 25) && dataRecord.products_attributes[25].options_values_id !== 600 ) {
                                warning = "text-danger";
                            }
                            if (_.has(dataRecord.products_on_ebay, 77)) {
                                html = '<a class="font-italic '+warning+'" href="http://cgi.ebay.com/ws/eBayISAPI.dll?ViewItem&ssPageName=STRK:MESE:IT&item='+dataRecord.products_on_ebay[77].ItemID+'" target="_blank">'+value+'</a>';
                            }
                            html = '<div class="jqx-grid-cell-left-align" style="margin-top:8px;">'+html+'</div>';
                            return html;
                        }
                    },
                    { text: 'Disc, %', dataField: 'discount', align: 'center', editable: false, cellsformat:'f0', cellsalign: 'right', width: 75, sortable: false, filterable: false},
                    { text: 'Price', dataField: 'products_price', align: 'center', editable: false, cellsformat:'c0', cellsalign: 'right', width: 75, sortable: false, filterable: false, aggregates: ['sum'] },
                    { text: 'Purchase', dataField: 'purchase_price', align: 'center', editable: false, cellsformat:'c0', cellsalign: 'right', width: 75, sortable: false, filterable: false },
                    { text: 'Margin', dataField: 'margin', align: 'center', editable: false, cellsformat:'c0', cellsalign: 'right', width: 75, sortable: false, filterable: false },
                    { text: '', align: 'center', editable: false, cellsalign: 'center', width: 75, sortable: false, filterable: false, columntype: 'button', hidden: true,
                        cellsrenderer: function () {
                            return "Calc";
                        }, buttonclick: function (row) {
                            let editrow = row;
                            let dataRecord = $("#productTable").jqxGrid('getrowdata', editrow);
                            save_enabled = false;

                            let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');

                            $.each(dataRecord.products_for_ebay, function (index, value) {
                                let siteID = index;
                                if (siteID != ebaySite.value) return;
                                //if (_.has(dataRecord.products_on_ebay, siteID) && value.price != 0) {
                                    //if (!confirm("Are you sure?")) return;
                                //}
                                let site_column = 'ebay_site_'+siteID+'_price';
                                let site_currency_rate = value.ebay_site.currency.rate_to_EUR;

                                //ebay category fee
                                let final_value_fee = 0, final_value_fee_max = 0;
                                if (_.has(dataRecord.ebay_categories, siteID)) {
                                    final_value_fee = dataRecord.ebay_categories[siteID].final_value_fee;
                                    final_value_fee_max = dataRecord.ebay_categories[siteID].final_value_fee_max;
                                }

                                let purchase_price_site_currency = dataRecord.purchase_price*site_currency_rate;
                                let margin_site_currency = dataRecord.margin*site_currency_rate;

                                let new_products_price = (purchase_price_site_currency + margin_site_currency)/(1 - final_value_fee/100);

                                let new_final_value_fee_value = new_products_price*final_value_fee/100;

                                let cuted_new_final_value_fee_value = new_final_value_fee_value;
                                if (final_value_fee_max != 0) cuted_new_final_value_fee_value = (new_final_value_fee_value > final_value_fee_max ? final_value_fee_max : new_final_value_fee_value);

                                new_final_value_fee_value = new_final_value_fee_value - cuted_new_final_value_fee_value;
                                new_products_price = new_products_price - new_final_value_fee_value;
                                if (value.price != new_products_price) $("#productTable").jqxGrid('setcellvalue', editrow, site_column, Math.round(new_products_price));
                            });
                            save_enabled = true;
                        }
                    },

                    { text: 'Cat', dataField: 'categories_id', hidden: true, editable: false, filterable: false },
                    { text: '', dataField: 'ebay_categories_update', hidden: true, editable: false, filterable: false },
                    { text: '', dataField: 'products_on_ebay.siteID', hidden: true, editable: false, filterable: false },

                    //US
                    { text: 'Price', dataField: 'ebay_site_'+ebaySite.value+'_price', columngroup: ''+ebaySite.value+'', align: 'center', width: 70, cellsformat:'f0', cellsalign: 'right', columntype: 'numberinput', filterable: false, sortable: false,
                        aggregates: ['sum'],
                        aggregatesrenderer: function (aggregates, column, element, summaryData) {
                            let renderstring = "<div class='jqx-widget-content' style='float: left; width: 100%; height: 100%;'>";
                            let siteID = column.columngroup;
                            let rows = $('#productTable').jqxGrid('getrows');
                            let total_anabled = 0;
                            let total_on = 0;
                            //if (_.has(record['products_on_ebay'], siteID)) {
                            $.each(aggregates, function (key, value) {
                                //var name = key == 'sum' ? 'S' : 'Avg';
                                $.each(rows, function (key, value) {
                                    if (_.has(value.products_on_ebay, siteID)) {
                                        total_on += parseInt(value[column.datafield]);
                                    }
                                    if (_.has(value.products_for_ebay, siteID) && value.products_for_ebay[siteID].enabled) {
                                        total_anabled += parseInt(value[column.datafield]);
                                    }
                                });
                                renderstring += '<div style="position: relative; margin: 6px; text-align: right; overflow: hidden;">' + total_on + '<br>'+ total_anabled + '</div>';
                            });
                            renderstring += "</div>";
                            return renderstring;
                        },
                        createeditor: function (row, cellvalue, editor) {
                            editor.jqxNumberInput({ decimalDigits: 2, spinButtons: false});
                        },
                        cellClassName: function (row, column, value, data) {
                            let siteID = $("#productTable").jqxGrid('getcolumnproperty', column, 'columngroup');
                            if (_.has(data.products_on_ebay, siteID)) {
                                return "text-muted";
                            }
                        },
                        cellbeginedit: function (row, datafield, columntype, oldvalue, newvalue) {
                            let siteID = $("#productTable").jqxGrid('getcolumnproperty', datafield, 'columngroup');
                            let dataRecord = $("#productTable").jqxGrid('getrowdata', row);
                            if (_.has(dataRecord.products_on_ebay, siteID)) {
                                $.app.AlertError('Product :'+dataRecord.products_id+ ' on '+siteID+'. You can\'t change price.' );
                                return false;
                            }
                        }
                    },
                    { text: '', dataField: 'ebay_site_'+ebaySite.value+'_enabled', columngroup: ''+ebaySite.value+'', align: 'center', columntype: 'checkbox', width: 30, filterable: false, sortable: false,
                        cellClassName: function (row, column, value, data) {
                            let siteID = $("#productTable").jqxGrid('getcolumnproperty', column, 'columngroup');
                            if (!_.has(data.ebay_categories, siteID)) {
                                return "lime ebay_site_"+data.products_id+"-"+siteID+"_enabled";
                            //} else if (data.ebay_categories[siteID].final_value_fee_max === 0) {
                            //    return "red";
                            } else {
                                return "";
                            }
                        },
                        renderer: function (text, align, height) {
                            var checkBox = "<div id='checkbox' style='z-index: 999; margin: 4px; margin-top: 8px;'>";
                            checkBox += "</div>";
                            return checkBox;
                        },
                        rendered: function (element, align, height) {
                            element.jqxCheckBox({hasThreeStates: true, checked: null});
                            element.on('change', function (event) {
                                applyFilter(event.args.checked);
                            });
                            return true;
                        }
                        /*cellbeginedit: function (row, datafield, columntype, oldvalue, newvalue) {
                            let siteID = $("#productTable").jqxGrid('getcolumnproperty', datafield, 'columngroup');
                            let dataRecord = $("#productTable").jqxGrid('getrowdata', row);
                            if (_.has(dataRecord.products_on_ebay, siteID)) {
                                $.app.AlertError('Product :'+dataRecord.products_id+ ' on '+siteID+'. You can\'t disable checkbox.' );
                                return false;
                            }
                        }*/

                    },
                    { text: '', dataField: 'preview_'+ebaySite.value, columngroup: ''+ebaySite.value+'', align: 'center', cellsalign: 'center', width: 30, filterable: false, sortable: false, editable: false,
                        cellsrenderer: function (row, column, value) {
                            let dataRecord = $("#productTable").jqxGrid('getrowdata', row);
                            let siteID = $("#productTable").jqxGrid('getcolumnproperty', column, 'columngroup');
                            let html = '';
                            if (_.has(dataRecord.products_on_ebay, siteID)) {
                                html = '<a href="https://www.ebay.com/itm/'+dataRecord.products_on_ebay[siteID].ItemID+'" target="_blank"><i aria-hidden="true" class="v-icon material-icons theme--light">exit_to_app</i></a>';
                            } /*else {
                        html = '<a href="https://admin.cameramate.com/preview/ebaylisting/'+siteID+'/'+dataRecord.products_id+'" target="_blank"><i class="icon-layout"></i></a>';
                    }*/
                            html = '<div style="margin-top: 4px; margin-left: 4px;">'+html+'</div>';
                            return html;
                        },
                    },
                ];
            };

            function processClick(event) {

                if (event.args.columnindex === 1) {
                    let rowid = $('#productTable').jqxGrid('getrowid', event.args.rowindex);
                    let data = $('#productTable').jqxGrid('getrowdatabyid', rowid);

                    this.openProductSpecificsDialog(data)
                }

            }

            $("#productTable").on("cellclick", processClick.bind(this));

            $("#productTable").jqxGrid({
                width: '100%',
                height: '100%',
                source: dataAdapter,
                sortable: true,
                //autoheight: true,
                filterable: true,
                virtualmode: true,
                pageable: true,
                //altrows: true,
                //enabletooltips: true,
                editable: true,
                //autorowheight: true,
                showfilterrow: true,
                //selectionmode: 'checkbox',
                showsortmenuitems: true,
                showfiltermenuitems: true,
                autoshowfiltericon: false,
                enablebrowserselection: true,
                showstatusbar: true,
                statusbarheight: 45,
                showaggregates: true,
                //pagesize: 5,
                pagesizeoptions: ['10', '20', '50', '100', '500', '1000'],
                showsortcolumnbackground: false,
                localization: {currencySymbol: "€", currencySymbolPosition: "after"},
                rowdetails: true,
                initrowdetails: initrowdetails,
                rowdetailstemplate: { rowdetails: "<div id='grid'></div>", rowdetailsheight: 152, rowdetailshidden: true },
                ready: function()
                {
                    //var item = $("#ebay-siteID").jqxDropDownList('getSelectedItem');
                    //console.log(item);

                },
                rendergridrows: function (params) {
                    return params.data;
                },
                columns: productTableColumns(),
                columngroups: [
                    { text: '<img src="/images/flags/4x3/us.svg" width="18" alt="United States">', align: 'center', name: '0' },
                    { text: '<img src="/images/flags/4x3/gb.svg" width="18" alt="UK">', align: 'center', name: '3' },
                    { text: '<img src="/images/flags/4x3/de.svg" width="18" alt="Germany">', align: 'center', name: '77' },
                    { text: '<img src="/images/flags/4x3/fr.svg" width="18" alt="FR">', align: 'center', name: '71' },
                    { text: '<img src="/images/flags/4x3/it.svg" width="18" alt="IT">', align: 'center', name: '101' },
                    { text: '<img src="/images/flags/4x3/es.svg" width="18" alt="ES">', align: 'center', name: '186' }
                ]
            });

            let applyFilter = function (checked) {

                if (checked == null) {
                    $('#productTable').jqxGrid('clearfilters');
                    $("#productTable").jqxGrid('updatebounddata', 'filter');
                } else {
                    let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');
                    let filtertype = 'numericfilter';
                    let filtercondition = 'EMPTY';
                    if (checked) filtercondition = 'NOT_EMPTY';
                    let filtergroup = new $.jqx.filter();
                    let filter = filtergroup.createfilter(filtertype, ebaySite.value, filtercondition);
                    filtergroup.addfilter(1, filter);
                    $("#productTable").jqxGrid('addfilter', 'products_on_ebay.siteID', filtergroup);

                    $("#productTable").jqxGrid('applyfilters', true);

                    $("#productTable").jqxGrid('updatebounddata', 'filter');
                }
                //$("#productTable").jqxGrid('refresh');
            };

            $('#OffButton').click(function() {
                let rows = $('#productTable').jqxGrid('getboundrows');
                let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');


                $.each(rows, function (index, value) {

                    //console.log(value.products_for_ebay[ebaySite.value].enabled);
                    //return;

                    save_enabled = false;

                    let site_column = 'ebay_site_'+ebaySite.value+'_enabled';

                    if (value.products_for_ebay[ebaySite.value].enabled === 1) {
                        //console.log('Row - '+value.products_id+' - site - '+ebaySite.value+' - enabled.');
                        //console.log(row);
                        $("#productTable").jqxGrid('setcellvalue', value.boundindex, site_column, 0);
                    }
                    save_enabled = true;
                })
            });

            $('#OnButton').click(function() {
                let rows = $('#productTable').jqxGrid('getboundrows');
                let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');


                $.each(rows, function (index, value) {

                    //console.log(value.products_for_ebay[ebaySite.value].enabled);
                    //return;

                    save_enabled = false;

                    let site_column = 'ebay_site_'+ebaySite.value+'_enabled';

                    if (_.isUndefined(value.products_for_ebay[ebaySite.value].enabled) || value.products_for_ebay[ebaySite.value].enabled === 0) {
                        //console.log('Row - '+value.products_id+' - site - '+ebaySite.value+' - enabled.');
                        //console.log(row);
                        $("#productTable").jqxGrid('setcellvalue', value.boundindex, site_column, 1);
                    }
                    save_enabled = true;
                })
            });

            async function confirm () {
                return await thisis.$refs.confirm.open('Please confirm', 'Are you sure?', { color: 'success' })
            }

            $('#CalcButton').click(function() {
                confirm().then((res) => {
                    console.log("confirm", res)
                    if (res) {
                        console.log("CalcButton")
                        let rows = $('#productTable').jqxGrid('getboundrows');
                        let ebaySite = $("#ebay-siteID").jqxDropDownList('getSelectedItem');
                        $.each(rows, function (index, value) {
                            save_enabled = false;
                            let row = value;

                            if (!_.isEmpty(row.products_for_ebay))
                                $.each(row.products_for_ebay, function (index, value) {
                                    let siteID = index;
                                    if (siteID != ebaySite.value) return;

                                    let site_column = 'ebay_site_'+siteID+'_price';
                                    let site_currency_rate = value.ebay_site.currency.rate_to_EUR;

                                    //ebay category fee
                                    let final_value_fee = 0;
                                    let final_value_fee_max = 0;
                                    let first_fee = 0;
                                    let second_fee = 0;
                                    let limit = 0;
                                    let per_order = 0;
                                    if (_.has(row.ebay_categories, siteID)) {
                                        final_value_fee = row.ebay_categories[siteID].final_value_fee;
                                        final_value_fee_max = row.ebay_categories[siteID].final_value_fee_max;
                                        first_fee = row.ebay_categories[siteID].first_fee;
                                        second_fee = row.ebay_categories[siteID].second_fee;
                                        limit = row.ebay_categories[siteID].limit;
                                        per_order = row.ebay_categories[siteID].per_order;
                                    }

                                    let purchase_price_site_currency = row.purchase_price*site_currency_rate;
                                    let margin_site_currency = row.margin*site_currency_rate;

                                    let new_products_price = value.price;
                                    let want_have = (purchase_price_site_currency + margin_site_currency);
                                    if (siteID == 77) {
                                        new_products_price = row.products_price
                                    }
                                    else {

                                        if (limit === 0 || want_have <= limit) {
                                            new_products_price = want_have + want_have*(first_fee/100) + per_order;
                                        } else {
                                            new_products_price = per_order+(want_have + limit*(first_fee/100-second_fee/100)) / (1-second_fee/100)
                                        }

                                        /*let ebay_fee = 0;
                                        let first_fee_calc = 0;
                                        let second_fee_calc = 0;

                                        if (limit !== 0) {
                                            first_fee_calc = (new_products_price > limit ? limit : new_products_price)*first_fee/100;
                                            second_fee_calc = (new_products_price > limit ? new_products_price-limit : 0)*second_fee/100;
                                            ebay_fee = first_fee_calc+second_fee_calc+per_order;

                                        } else if (first_fee !== 0) {
                                            ebay_fee = new_products_price*first_fee/100+per_order;
                                        }*/

                                        //+3% for eBay.com
                                        if (siteID == 0) {
                                            new_products_price *= 1.03;
                                        }

                                        new_products_price = Math.round(new_products_price);
                                    }

                                    if (value.price != new_products_price) {
                                        console.log('Row -'+row.products_id+'- site - '+siteID+' - price - '+new_products_price);
                                        //console.log(row);
                                        $("#productTable").jqxGrid('setcellvalue', row.boundindex, site_column, new_products_price);
                                    }
                                });
                            save_enabled = true;
                        })
                    }
                })


            });
        },
        computed:{
            ...mapGetters([
                'ebay_global_sites'
            ]),
        },
        methods:{
            openProductSpecificsDialog(item) {
                EventBus.$emit('openProductSpecificsDialog', item);
            },
            calcMargin() {

            }
        }
    }
</script>