<template>
    <v-row>
        <v-col>
            <emails :emailList="emailList" class="ma-1"/>
        </v-col>
    </v-row>

</template>

<script>
    import Emails  from '@/components/Emails'

    export default {
        name: "ProductEmail",
        props:{
            isActive:{},
            id: {}
        },
        data(){
            return {
                emailList: [],
            }
        },

        mounted() {
                this.init()
        },
        methods: {
            init(){
                axios.get(`/api/customers_email?
                    filterGroups[0][field]=orders_id
                    &filterGroups[0][filters][0][value]=${this.id ? this.id : this.$route.params.id}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=and
                    &pagesize=100000`)
                    .then(response => {
                        this.emailList = response.data.data
                        this.$emit('componentReady')
                        // console.log(this.emailList )
                    }).catch(e => {
                    console.log(e)
                })
            },
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-email"){
                    this.init()
                }
            },
        },
        components: {
            Emails
        }
    }
</script>

<style scoped>

</style>