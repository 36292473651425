<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline"><slot></slot></span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="py-0">
                    <v-row justify="center" wrap>
                        <v-col sm="2" cols="4" class="d-flex pa-0 ma-0">
                            <v-select
                                    class="mr-3 styled-select"
                                    :items="searchOption"
                                    v-model="searchBy"
                                    item-value="field"
                                    item-text="text"
                                    return-object
                                    dense
                            />
                        </v-col>
                        <v-col sm="10" cols="8" class="d-flex pa-0 ma-0">
                            <!--                                    id="auto_product_search"-->
                            <v-autocomplete
                                    v-model="selected"
                                    :items="results"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    :autofocus="true"
                                    item-text="forSearch"
                                    item-value="descriptions.products_id"
                                    @change="pushOrRemoveProducts"
                                    return-object
                                    autocomplete="off"
                                    dense
                            />

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-expand-transition>
                <v-col cols="12" v-if="selected" class="py-0">
                    <v-list class="full-width">
                        <v-list-item link :key="selected.products_id">
                            <v-list-item-content>
                                <v-list-item-title class="truncate">
                                    {{ selected.products_id }}:{{ selected.descriptions.products_name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon
                                        @click="add"
                                        color="green"
                                >
                                    save
                                </v-icon>
                                <v-icon
                                        @click="cancel_add"
                                        color="red"
                                >
                                    delete
                                </v-icon>
                            </v-list-item-icon>

                        </v-list-item>
                    </v-list>
                </v-col>

            </v-expand-transition>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click.stop="cancel">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AddProductDialog",
        props: {
            value: Boolean
        },
        data(){
            return {
                isLoading: false,
                results: [],
                selected: null,
                search: null,
                searchOption: [
                    {text: 'ID', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter'},
                    {text: 'Name', field: 'descriptions.products_name', condition: 'CONTAINS', type: 'stringfilter' },
                ],
                searchBy:  {text: 'ID', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter'},
            }
        },
        watch:{
            async search(val){
                if(val){
                    this.isLoading = true

                     axios.get('/api/products',
                        { params: {
                                'filterGroups[0][field]': this.searchBy.field,
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]':this.searchBy.condition,
                                'filterGroups[0][filters][0][type]':this.searchBy.type,
                                'filterGroups[0][filters][0][operator]':'or',
                                'filterGroups[0][operator]':'or',
                                'with[]':'descriptions',
                                'pagesize':'5'
                            },
                        })
                        .then(response => {
                            console.log(response.data)
                            response.data.data.forEach(function(element) {
                                // console.log(element);
                                element.forSearch = element.products_id+':'+element.descriptions.products_name
                            });
                            this.results = response.data.data
                            // console.log(response)

                        })
                        .catch(error => { console.log(error)})
                        .finally(() => (this.isLoading = false))

                }
            }

        },
        computed: {
            items: {
                get () {
                    return this.results
                },
                set (value) {
                    this.results = value
                }
            },
            show: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        methods:{
            pushOrRemoveProducts(val){
                if(val){
                    this.items = []
                }else{

                }
            },
            add(){
                this.show = false
                this.items = []
                this.$emit('add-product', this.selected)
                this.selected=null

            },
            cancel(){
                this.show = false
                this.items = []
                this.selected=null
            },
            cancel_add(){
                this.items = []
                this.selected=null
            }
        }
    }
</script>

<style scoped>

    .styled-select{
        width: 25%;
        max-width: 200px;
    }

    .truncate {
        display: block;
        width: calc(100% - 60px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>