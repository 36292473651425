<template>
    <div>
        <OrderAddressForms v-if="order" :order="order" @submitForm="submitForm"/>
        <v-layout align-space-around justify-start fill-height class="elevation-1 caption px-2 pt-1">
            <v-flex xs12>
                <order-table-info
                        v-if="order && order.order_products"
                        :order_products="order.order_products"
                        :order_shipping="order.order_shipping ? order.order_shipping.value : null"
                        :order_sub_total="order.order_sub_total ? order.order_sub_total.value : null"
                        :order_total="order.order_total ? order.order_total.value : null"
                        @productChanged="recountProducts"
                        @productRemoved="removeProduct"
                        @inputShipping="countOrderSum"
                />
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import OrderTableInfo from './OrderTableInfo'
    import OrderedDatepicker from './OrderedDatepicker'
    import { mapGetters } from 'vuex';
    import OrderAddressForms from "../../components/OrderAddressForms";
    import order from "./Order.vue";

    export default {
        name: "OrderInfo",
        props:{
            isActive:{}
            },
        data(){
            return {
                rules: {
                    max25chars: v => v.length <= 30 || 'Input too long!',
                    required: [value => !!value || ''],
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                showCopyButton: false,
                addressValid: false,
            }
        },
        computed: {
            order() {
                return order
            },
            ...mapGetters([
                'order',
            ]),
        },
        mounted() {
            if(this.order){
                this.$emit('componentReady')
               if(!this.order.duty_date){
                   this.order.duty_date = {
                       "id": null,
                       "orders_id": this.order.orders_id,
                       "duty_date": null
                   }
               }
                console.log(this.order)
            }
        },
        methods:{
            submitForm(order){
                return this.$store.dispatch('updateOrder',  order)
                    .then(() => {
                        //alert('Data is saved')
                    })
                    .catch(error => {
                        alert(`Data is not saved. ${error}`)
                    })
            },
            recountProducts(product, quantity, price){
                product.final_price =  (parseFloat(price) * parseFloat(quantity)).toFixed(0)
                //this.order.order_total.value = Number(this.order.order_shipping.value) + product.final_price
                //this.countOrderSum(this.order.order_shipping.value)
                this.$store.dispatch('updateOrderProducts',  product)
                    .then(response => {
                       // console.log(response)
                    })
                    .catch(error => {
                        alert(`Order was not updated. ${error}`)
                    })
            },
            removeProduct(product){
                const payload = {
                    orderProductId: product.orders_products_id,
                    productId: product.products_id
                }
                this.$store.dispatch('deleteOrderProducts', payload)
                    .then(response => {

                    })
                    .catch(error => {
                        alert(`Product was not removed. ${error}`)
                    })
            },
            countOrderSum(val){
                this.order.order_shipping.value = val
                this.order.order_total.value = Number(this.order.order_shipping.value) + Number(this.order.order_sub_total.value)
                this.$store.dispatch('updateOrder', this.order)
            }

        },
        watch:{
            isActive(){
                if(this.isActive === "tab-order-info"){
                    if(this.order !== null){
                        this.$emit('componentReady');
                    }
                }
            },
        },
        components:{
            OrderTableInfo,
            OrderedDatepicker,
            OrderAddressForms
        }
    }
</script>

<style scoped>


</style>