var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCarousel()
    ? _c(
        "div",
        { staticClass: "full-width" },
        [
          _c(
            "v-row",
            { staticClass: "pt-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "v-show": _vm.images, small: "" },
                      on: {
                        click: function($event) {
                          return _vm.openFileDialog("fileReplace")
                        }
                      }
                    },
                    [_vm._v("Replace")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.enableDelete,
                          expression: "enableDelete"
                        }
                      ],
                      attrs: { small: "", text: "", icon: "", color: "gray" },
                      on: { click: _vm.deleteSelectedImages }
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", text: "", icon: "", color: "green" },
                      on: {
                        click: function($event) {
                          return _vm.openFileDialog("fileAdd")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("add_box")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        text: "",
                        icon: "",
                        color: "blue",
                        id: "refresh"
                      },
                      on: { click: _vm.init }
                    },
                    [_c("v-icon", [_vm._v("mdi-cached")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-file-input", {
                ref: "fileReplace",
                staticClass: "d-none",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: "Replace",
                  accept: "image/*",
                  id: "fileReplace"
                },
                on: { change: _vm.replaceImage }
              }),
              _vm._v(" "),
              _c("v-file-input", {
                ref: "fileAdd",
                staticClass: "d-none",
                attrs: {
                  outlined: "",
                  dense: "",
                  label: "Add",
                  accept: "image/*",
                  id: "fileAdd"
                },
                on: { change: _vm.addImages }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-carousel",
            {
              staticStyle: { height: "calc(100vh - 285px)" },
              attrs: { height: "100%" },
              model: {
                value: _vm.currentImage,
                callback: function($$v) {
                  _vm.currentImage = $$v
                },
                expression: "currentImage"
              }
            },
            _vm._l(_vm.images, function(item, i) {
              return _c(
                "v-carousel-item",
                { key: i, attrs: { href: item.src, target: "_blank" } },
                [
                  _c("img", {
                    staticClass: "elevation-1 mt-1",
                    staticStyle: {
                      "object-fit": "contain",
                      width: "98%",
                      height: "90%"
                    },
                    attrs: { src: item.src, alt: "product image" }
                  })
                ]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }