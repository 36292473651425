<template>
    <v-layout align-space-around justify-start column fill-height style="height: 100% !important;">
        <v-flex xs12 style="max-height: 48px; height: 48px;">
            <v-toolbar dense flat light color="grey lighten-5">
                <p class="text-truncate text-left title ma-0" v-if="productLoaded &&  product.descriptions">
                    {{ product.products_id }} - {{ product.descriptions.products_name }}
                </p>
                <v-spacer></v-spacer>
                <v-btn
                    id="refreshButton"
                    @click="fetchProduct(products_id)"
                >Refresh</v-btn>
                <div class="mx-1"></div>
                <v-btn
                    id="saveButton"
                    @click="saveProductDescription()"
                    :disabled="!isChanged"
                    color="red"
                >Save</v-btn>
                <div class="mx-1"></div>
                <v-btn
                    fab
                    x-small
                    icon
                    color="false"
                    v-if="(product && !product.multilingual_descriptions[ebay_global_site.language])"
                    v-for="(ebay_global_site, index) in ebay_global_sites"
                    :key="ebay_global_site.value"
                    class="mr-1"
                    @click="addDescription(ebay_global_site.value)"
                >
                        <v-avatar
                            size="24"
                        >
                            <v-img
                                :alt="ebay_global_site.country"
                                :src="ebay_global_site.icon"
                            ></v-img>
                        </v-avatar>

                </v-btn>
            </v-toolbar>
        </v-flex>
        <v-flex xs12 style="height: 100%" id="editorProductDescription">
            <v-tabs
                vertical
                v-if="productLoaded &&  product.multilingual_descriptions"
                style="height: 100%"
            >
                <v-tab
                    v-for="(description, index) in product.multilingual_descriptions"
                    :key="description.language_id"
                    class="ps-0 ma-0"
                >
                    <v-avatar
                        size="24"
                    >
                        <img
                            :src="getEbay(description.language.ebay.siteID).icon"
                        >
                    </v-avatar>
                </v-tab>
                <v-tab-item
                    v-for="(description, index) in product.multilingual_descriptions"
                    :key="description.language_id"
                    style="height: 100%"
                >
                    <JqxSplitter
                        width="100%" height="100%" :orientation="'horizontal'"
                        :panels="[{ size: '99%' }]">
                        <div class="splitter-panel">
<!--                            <ckeditor
                                style="height: 100%"
                                :editor="editor"
                                v-model="description.long_products_description"
                                :config="editorConfig"
                                @input="onEditorInput">
                            </ckeditor>-->
                            <editor
                                v-model="description.long_products_description"
                                api-key="a5k5wc2s7zqa3rmuejupxelpaekhnugvaxceagz01ic6jxdu"
                                style="height:100%"
                                :init="{
                                    height: '100%',
                                    menubar: false,
                                    toolbar: 'undo redo styleselect forecolor bold italic alignleft aligncenter alignright bullist numlist outdent indent code',
                                    color_cols: 5,
                                    plugins: 'code lists advlist'
                                }"
                                @onChange="onEditorInput"
                            />

                        </div>
                        <div class="splitter-panel">
                            <v-textarea
                                solo
                                v-model="description.long_products_description"
                                height="100%"
                                hide-details="true"
                                @input="onEditorInput"
                            ></v-textarea>
                        </div>
                    </JqxSplitter>
                </v-tab-item>
            </v-tabs>
        </v-flex>
        <v-speed-dial
            v-model="previewDescriptionMenu"
            bottom
            right
            absolute
            direction="top"
            open-on-hover
        >
            <template v-slot:activator>
                <v-btn
                    v-model="previewDescriptionMenu"
                    color="blue darken-2"
                    dark
                    fab
                >
                    <v-icon v-if="previewDescriptionMenu">mdi-close</v-icon>
                    <v-icon v-else>mdi-presentation</v-icon>
                </v-btn>
            </template>
            <v-btn
                v-for="(ebay_global_site, index) in ebay_global_sites"
                :key="ebay_global_site.value"
                fab
                dark
                small
                color="blue"
                @click="previewDescription(ebay_global_site.value)"
            >
                <v-avatar
                    size="24"
                >
                    <img
                        :alt="ebay_global_site.country"
                        :src="ebay_global_site.icon"
                    >
                </v-avatar>
            </v-btn>
        </v-speed-dial>

    </v-layout>
</template>

<script>

import { mapGetters } from 'vuex'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import JqxSplitter from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxsplitter.vue';

import Editor from '@tinymce/tinymce-vue';

export default {
    name: "ProductDescription",
    props: [ 'id' ],
    data() {
        return {
            product: null,
            productLoaded: false,
            products_id: null,
            editor: ClassicEditor,
            isChanged: false,
            previewDescriptionMenu: false,
            editorConfig: {
                mediaEmbed: {
                    providers: [
                        {
                            name: 'allow-all',
                            // A URL regexp or an array of URL regexps:
                            url: /^.+/
                        }
                    ]
                },
                extraAllowedContent: true,
                /*toolbar: [
                    { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source' ] },
                    { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Scayt' ] },
                    '/',
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat' ] },
                    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                    { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                    { name: 'insert', items: [ 'Image', 'Html5video', 'Table', 'HorizontalRule', 'SpecialChar' ] },
                    '/',
                    { name: 'styles', items: [ 'Styles', 'Format' ] },
                    { name: 'tools', items: [ 'Maximize' ] },
                    { name: 'others', items: [ '-' ] },
                    { name: 'about', items: [ 'About' ] }
                ]*/
            },
            trumbowygConfig: {
                svgPath: false
            },
            content: ''
        }
    },
    computed:{
        ...mapGetters([
            'ebay_global_sites'
        ]),
    },
    created() {
        window.addEventListener('beforeunload', this.beforePageDestroyed)
    },
    mounted() {
        if (!this.id) {
            this.products_id = this.$route.params.id
        } else {
            this.products_id = this.id
        }

        this.fetchProduct(this.products_id);

    },
    methods: {
        addDescription(ebayID) {
            axios.post('/api/products_description',
                {
                    products_id: this.products_id,
                    siteID: ebayID
                }
            ).then(response => {
                this.fetchProduct(this.products_id)
            }).catch((error) => {
                console.log(error);
                console.warn('Request was not sent properly.');
            })
        },
        getEbay: function (siteId) {
            return _.find(this.ebay_global_sites, {value: siteId})
        },
        previewDescription: function (siteId) {
            window.open(`https://admin.cameramate.com/preview/ebaylisting/${siteId}/${this.products_id}/`, '', 'resizable=yes,scrollbars=yes,status=yes')
        },
        onEditorInput: function (data) {
            console.log(data)
            this.isChanged = true;
        },
        beforePageDestroyed: function (event) {
            if (this.isChanged) {
                event.preventDefault();
                event.returnValue = '';
            }
        },
        async fetchProduct(productId) {
            await axios
                .get(`/api/products/?
                    filterGroups[0][field]=products_id
                    &filterGroups[0][filters][0][value]=${productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=or
                    &with[]=descriptions
                    &with[]=multilingual_descriptions.language.ebay`)
                .then(response => {
                    this.product = response.data.data[0];
                    console.log(this.product)
                    if (this.product.multilingual_descriptions) {
                        this.product.multilingual_descriptions = _.keyBy(this.product.multilingual_descriptions, 'language.code');
                        _.forIn(this.product.multilingual_descriptions, function(element, key) {
                            if (!element.long_products_description) element.long_products_description = ''
                        });
                    }
                    this.status = this.product.products_status.toString()
                    this.productLoaded = true
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Product is not present.');
                })

        },
        saveProductDescription() {
            let theThis = this;
            _.forIn(this.product.multilingual_descriptions, function(element, key) {
                console.log(element);
                axios.put('/api/products_description/'+element.id, element
                ).then(response => {
                    theThis.isChanged = false
                }).catch((error) => {
                    theThis.isChanged = true
                    console.log(error);
                    console.warn('Request was not sent properly.');
                })
            })

        }
    },
    components: {
        JqxSplitter,
        'editor': Editor
    }
}
</script>

<style>
    #editorProductDescription .ck.ck-editor {
        height: 100%;
    }
    #editorProductDescription .v-window__container {
        height: 100%;
    }
    #editorProductDescription .v-textarea, #editorProductDescription .v-input__control, #editorProductDescription .v-textarea div, #editorProductDescription .v-input__control div, #editorProductDescription .v-input__slot, .splitter-panel.v-input.v-input__control.v-input__slot, .splitter-panel.v-input.v-input__control {
        height: 100% !important;
        margin: 0;
        padding: 0;
    }
    #editorProductDescription .v-tab {
        margin: 0;
        padding: 0;
        width: 50px;
    }

    #editorProductDescription .ck.ck-editor__main {
        height: 100%;
    }

    #editorProductDescription .ck-editor__editable_inline {
        height: 100%;
    }
</style>