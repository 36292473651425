<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Reset Password</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-alert
                            v-model="show"
                            :type="status"
                            dismissible>
                        {{ message }}
                    </v-alert>
                    <v-form aria-label="Reset Password" ref="reset">

                        <input type="hidden" name="token" :value="token">

                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.email"
                                        :rules="[rules.required, rules.email, rules.max255chars]"
                                        label="Email"
                                        name="email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.password"
                                        :rules="[rules.required, rules.max255chars, rules.min6chars]"
                                        ref="password"
                                        type="password"
                                        label="Password"
                                        name="password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.password_confirmation"
                                        :rules="passwordConfirmationRules"
                                        type="password"
                                        label="Password Confirm"
                                        name="password_confirmation"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-btn
                                @click="validate"
                                color="primary"
                                :loading="loading">
                            Reset Password
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog
                v-model="loading"
                width="300">
            <v-card>
                <v-card-text>
                    {{ text }}
                    <v-progress-linear
                            indeterminate
                            class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import Auth from '../../auth.js'

    export default {
        name: "ResetPassword",
        props: ['token'],
        data() {
            return {
                input: {
                    email: '',
                    password: '',
                    password_confirmation: ''
                },
                rules: {
                    max255chars: v => v.length <= 230 || 'Input too long!',
                    min6chars:  v => v.length > 5 || 'Should be minimum 6 characters long!',
                    required: value => !!value || '',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                //Dialog Data
                loading: false,
                text: '',
                //Alert Data
                status: 'success',
                message: '',
                show: false
            }
        },
        computed:{
            passwordConfirmationRules() {
                return [
                    v => (this.input.password === v) || 'Passwords must match',
                    v => !!v || 'Confirmation Password is required'
                ];
            },
        },
        methods: {
            validate() {
                if(this.$refs.reset.validate()) {
                    this.text = 'Resetting Password...'
                    this.loading = true
                    this.submit()
                }
            },
            submit() {
                Auth.resetPassword(this.input).then(({data}) => {
                    this.message = data.message
                    this.status = data.status
                    this.loading = false
                    this.show = true
                    if (data.status === 'success'){
                        this.text = 'Redirecting to Login Page...'
                        this.loading = true
                        setTimeout(() => {
                            this.$router.push({name: 'auth.login'})
                        }, 2000)
                    }
                }).catch(error => {
                    this.loading = false
                    this.show = false
                })
            }
        }
    }
</script>

<style scoped>

</style>