<template>
    <span
        class="ml-2 caption teal--text text--darken-2"
        v-if="product && product.stocks_quantity"
    >
        <i
            v-for="(stock,index) in product.stocks_quantity"
            :key="index"
        >
            ({{ getStockName(stock) }}<span v-if="product.stocks_quantity.length > 1 && product.stocks_quantity.length !== index + 1">, </span>)
        </i>
    </span>

    
</template>

<script>
    export default {
        name: "WarehouseInfo",
        props: {
            product: {
                required: true
            }
        },
        data:() => ({

        }),
        mounted() {
            // console.log(this.product)
        },
        methods: {
            getStockName: function(stock) {
                //console.log(stock)
                let stockName = ''
                if (stock.stocks_move_to_full && stock.stocks_move_to_full.stock_name) {
                    stockName = (stock.stocks_move_to_full.stock_name ? stock.stocks_move_to_full.stock_name : '')+":"+stock.stocks_move_quantity;
                    if (stock.stocks_move_to_full.parent_stocks && stock.stocks_move_to_full.parent_stocks.length) {
                        stockName = this.getParentsStocksName(stock.stocks_move_to_full.parent_stocks[0])+stockName;
                    }
                }
                return stockName;
            },
            getParentsStocksName: function (stock) {
                let stockName = (stock.stock_name ? stock.stock_name+'-' : '');
                if (stock.parent && stock.parent.length) {
                    stockName = this.getParentsStocksName(stock.parent[0])+'-'+stockName;
                } else {
                    stockName = ((stock.parent && stock.parent.stock_name) ? stock.parent.stock_name+'-' : '')+stockName;
                }
                return stockName;
            }
        }
    }
</script>

<style scoped>

</style>