<template>
    <v-flex xs12>
        <v-card>
            <v-layout>
                <v-flex xs12>
                    <v-card-title class="ma-0 pa-2">
                        <div class="title text-truncate">
                            <v-icon small
                                    :class="'mr-1 mb-1 flag-icon flag-icon-'+ebay_global_sites[bestoffer.siteID].name"></v-icon>
                            <v-icon
                                    class="mr-1 mb-1"
                                    color="red"
                                    v-show="((bestoffers[index-1] && (bestoffers[index-1].products_id === bestoffers[index].products_id)) || (bestoffers[index+1] && (bestoffers[index+1].products_id === bestoffers[index].products_id)))">info</v-icon>
                            {{ bestoffer.products_id}} - {{ bestoffer.product.descriptions.products_name }}
                        </div>
                    </v-card-title>
                </v-flex>
            </v-layout>
            <v-divider light></v-divider>
            <v-layout>
                <v-flex xs2>
                    <v-img
                            v-show="bestoffer.product.products_image"
                            :src="'https://images.cameramate.com/'+bestoffer.products_id+'/'+bestoffer.product.products_image"
                            height="125px"
                            contain
                    ></v-img>
                </v-flex>
                <v-flex xs10>
                    <v-card-text class="pa-1">
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-layout wrap>
                                    <v-flex xs4 sm6 class="text-right">Buy it now:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-bold"><span
                                            v-text="bestoffer.BuyItNowPrice_currency"></span> <span
                                            v-text="bestoffer.BuyItNowPrice_value"></span></v-flex>

                                    <v-flex xs4 sm6 class="text-right">Offer:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-bold red--text text--lighten-1">
                                        <span v-text="bestoffer.Price_currency"></span> <span
                                            v-text="bestoffer.Price_value"></span></v-flex>

                                    <v-flex xs4 sm6 class="text-right">Set:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-medium">
                                        <span v-if="(bestoffer.product.purchase_price.products_set && bestoffer.product.purchase_price.products_set.set_name != undefined)"
                                              :class="{ 'green--text': bestoffer.product.purchase_price.products_set.set_complited > 0, 'orange--text text--lighten-1': bestoffer.product.purchase_price.products_set.set_complited < 0 }">
                                            {{ bestoffer.product.purchase_price.products_set.set_complited+'% - '+ bestoffer.product.purchase_price.products_set.set_name }}
                                              <v-icon color="success" @click="openSetInfo">info</v-icon>
                                               <v-dialog v-model="setsDialog" fullscreen hide-overlay
                                                         transition="dialog-bottom-transition">
                                                     <v-card>
                                                         <v-card-text>
                                                              <set :setInfo="bestoffer.product.purchase_price.products_set"></set>
                                                         </v-card-text>

                                                          <v-card-actions>
                                                              <v-spacer></v-spacer>
                                                              <v-btn color="blue darken-1" @click.stop="setsDialog = false">Close</v-btn>
                                                          </v-card-actions>
                                                     </v-card>
                                               </v-dialog>

                                        </span>
                                    </v-flex>

                                    <v-flex xs4 sm6 class="text-right">Markup:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1  font-weight-bold red--text text--lighten-1">
                                        <span v-if="currenciesList">{{ markup(bestoffer.Price_value) }}</span>
                                    </v-flex>

                                    <v-layout class="mt-2 align-center">
                                        <v-flex xs4 sm6 class="text-right mb-3">Offer range:</v-flex>
                                        <v-flex xs8 sm6 class="text-left pl-1 text--lighten-1">
                                            <auto-respond-form
                                                    :offer_range="bestoffer.product.offer_range"
                                                    :price="bestoffer.BuyItNowPrice_value"
                                                    :loading="loading"
                                                    @updaterange="updaterange">
                                            </auto-respond-form>
                                        </v-flex>
                                    </v-layout>


                                </v-layout>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-layout wrap>
                                    <v-flex xs4 sm6 class="text-right">Purchase:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-medium"><span
                                            v-text="bestoffer.product.purchase_price.currencies_code"></span> <span
                                            v-text="bestoffer.product.purchase_price.products_price"></span><span
                                            v-text="' - '+bestoffer.BuyItNowPrice_currency"
                                            v-show="bestoffer.BuyItNowPrice_currency != bestoffer.product.purchase_price.currencies_code"></span>
                                        <span v-text="bestoffer.product.purchase_price.purchase_price_in_bestoffer_currency"
                                              v-show="bestoffer.BuyItNowPrice_currency != bestoffer.product.purchase_price.currencies_code"></span>
                                    </v-flex>

                                    <v-flex xs4 sm6 class="text-right">Expiration:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-medium"><span
                                            v-text="$moment(bestoffer.ExpirationTime).from()"></span></v-flex>

                                    <v-flex xs4 sm6 class="text-right">Product age:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-medium"><span
                                            v-text="$moment(bestoffer.product.products_date_available).from()"></span>
                                    </v-flex>

                                    <v-flex xs4 sm6 class="text-right">Buyer:</v-flex>
                                    <v-flex xs8 sm6 class="text-left pl-1 font-weight-medium">
                                        <span>@{{ bestoffer.Buyer_UserID }} </span>
                                        <span v-if="bestoffer.Buyer_FeedbackScore">({{bestoffer.Buyer_FeedbackScore}}) </span>
                                        <span v-if="bestoffer.Buyer_CountryName">{{ bestoffer.Buyer_CountryName }}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-flex>
            </v-layout>
            <v-divider light></v-divider>
            <v-layout>
                <v-slide-y-transition>
                    <v-card-text v-show="bestoffer.BuyerMessage != ''" v-text="bestoffer.BuyerMessage">
                    </v-card-text>
                </v-slide-y-transition>
            </v-layout>
            <v-divider light v-show="bestoffer.BuyerMessage != ''"></v-divider>
            <v-layout>
                <v-slide-y-transition>
                    <v-card-text v-show="bestoffer.respond">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-container>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-layout>
                                            <v-flex xs6>
                                                <v-text-field
                                                        v-model="CounterOffer"
                                                        :rules="counterRules"
                                                        label="Counter Offer"
                                                        required
                                                        :suffix="bestoffer.Price_currency"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs6
                                                    class="text-center pl-1 font-weight-bold red--text text--lighten-1">
                                                Markup: <span v-if="currenciesList"> {{ markup(CounterOffer) }}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12>
                                        <!--:rules="sellerTextRules"-->
                                        <v-text-field
                                                v-model="sellerResponse"
                                                label="Message to buyer"
                                                counter="250"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-btn
                                    :disabled="!valid"
                                    color="success"
                                    @click="isReadyToSend('Countered')"
                            >Send
                            </v-btn>
                            <v-btn
                                    color="error"
                                    @click="CounterOfferCancel"
                            >
                                Cancel
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-slide-y-transition>
            </v-layout>
            <v-divider light v-show="bestoffer.respond"></v-divider>
            <v-card-actions v-if="!bestoffer.respond" class="justify-center">
                <v-btn xs3 sm3 class="mb-1" color="success"
                       @click="$emit('readyToUpdate', bestoffer, index, markupValue, 'Accepted')">
                    Accept
                </v-btn>

                <v-btn xs3 sm3 class="mb-1"
                       color="info"
                       @click="openCounterOfferDialog">
                    <span class="hidden-xs-only">  Counter offer</span>
                    <span class="hidden-sm-and-up">Counter</span>
                    <v-icon>{{ bestoffer.showCounterOffer ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
                </v-btn>

                <v-btn xs3 sm3 class="mb-1" color="warning"
                       @click="$emit('readyToUpdate', bestoffer, index, markupValue, 'Declined')">
                    Decline
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirm ref="confirm" @confirmUpdate="updateRequested" @rejectUpdate="updateCancel"></confirm>
    </v-flex>
</template>

<script>

    import {mapGetters} from 'vuex'
    import AutoRespondForm from './AutoRespondForm'
    import Set from '@/components/Set'
    import Confirm from '@/components/Confirm'

    export default {
        name: "BestOfferItem",
        props: {
            bestoffer: {
                required: true
            },
            bestoffers: {
                required: true
            },
            index: {
                required: true
            },
        },
        data() {
            return {
                CounterOffer: '',
                sellerResponse: '',
                counterRules: [
                    value => !!value || 'Required',
                    value => value < this.bestoffer.BuyItNowPrice_value || "Offer can't be bigger than Buy It Now price"
                ],
                sellerTextRules: [
                    value => (value && value.length) <= 250 || 'Max 250'
                ],
                valid: true,
                markupValue: 0,
                setsDialog: false,
                new_range: null,
                loading: false
            }
        },
        methods: {
            askConfirm(){
                this.$refs.confirm.open('Change limits', 'All bestoffers for this product will be declined. Are you sure?', { color: 'success' })
            },
            updateRequested(){
                this.$emit('updaterange', this.new_range, this.bestoffer.products_id, this.index);
                this.loading = false;
            },
            updateCancel(){
                this.loading = false;
            },
            markup(price) {
                if (!price) return ''

                let ebay_categories = _.keyBy(this.bestoffer.product.ebay_categories, 'siteID')
                let ebay_fees = price * (ebay_categories[this.bestoffer.siteID].final_value_fee / 100)
                if (ebay_fees > ebay_categories[this.bestoffer.siteID].final_value_fee_max && ebay_categories[this.bestoffer.siteID].final_value_fee_max != 0) ebay_fees = ebay_categories[this.bestoffer.siteID].final_value_fee_max;
                let paypal_fees = price * 0.05;

                if (!this.currenciesList) {
                    return false
                }

                let currencies = _.keyBy(this.currenciesList, 'code')

                // we don't use it
                // let base_currency_rate = currencies['USD'].value/currencies[this.bestoffer.BuyItNowPrice_currency].value
                let purchase_price = this.bestoffer.product.purchase_price.products_price * currencies[this.bestoffer.BuyItNowPrice_currency].value / currencies[this.bestoffer.product.purchase_price.currencies_code].value
                this.bestoffer.product.purchase_price.purchase_price_in_bestoffer_currency = Math.round(purchase_price, 0);

                let markup = price - purchase_price - ebay_fees - paypal_fees
                this.markupValue = this.bestoffer.BuyItNowPrice_currency + ' ' + Math.round(markup)

                return this.bestoffer.BuyItNowPrice_currency + ' ' + Math.round(markup)
            },
            validate() {
                return this.$refs.form.validate()
            },
            CounterOfferreset() {
                this.$refs.form.reset()
            },
            CounterOfferCancel() {
                this.CounterOfferreset();
                this.bestoffer.respond = !this.bestoffer.respond

            },
            isReadyToSend(respondType) {
                if (this.validate()) {
                    this.$emit('readyToUpdate',
                        this.bestoffer,
                        this.index,
                        this.markupValue,
                        respondType,
                        {
                            counterOffer: this.CounterOffer,
                            sellerResponse: this.sellerResponse
                        })
                }
            },
            openCounterOfferDialog() {
                this.CounterOfferreset();
                this.bestoffer.respond = !this.bestoffer.respond
            },
            updaterange(new_range) {
                this.loading = true;
                this.new_range = new_range;
                //console.log(this.new_range);
                this.askConfirm();
                //console.log(this.new_range);
                //this.$emit('updaterange', new_range, this.bestoffer.products_id, this.index)
            },
            openSetInfo() {
                this.setsDialog = true
            }
        },
        computed: {
            ...mapGetters([
                'currenciesList',
                'ebay_global_sites'
            ])
        },
        components: {
            AutoRespondForm,
            Set,
            Confirm
        }
    }
</script>

<style scoped>

    @media all and (max-width: 480px) {
        .v-btn__content {
            font-size: 0.8em;
        }
    }

</style>