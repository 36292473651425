import Vue from 'vue'
import VueRouter from "vue-router"

//Auth Components
import AuthLayout from '../layouts/AuthLayout.vue'
import Register from '../components/auth/Register.vue'
import Login from '../components/auth/Login.vue'
import ResetEmail from '../components/auth/ResetEmail.vue'
import ResetPassword from '../components/auth/ResetPassword.vue'
import changePassword from '../components/auth/changePassword.vue'

import DashboardLayout from '../layouts/DashboardLayout.vue'

import Cameramate from '../pages/Cameramate.vue'

//Sniper
import SniperSearch from '../pages/Sniper/SniperSearch'
import SniperBuy from '../pages/Sniper/SniperBuy'

//Catalog
import Products from '../pages/Catalog/Products.vue'
import ProductsOld from '../pages/Catalog/ProductsOld.vue'
import AddProduct from '../pages/Catalog/AddProduct.vue'
import AddProductOld from '../pages/Catalog/AddProductOld.vue'
import ProductsForEbay from '../pages/Catalog/ProductsForEbay.vue'
import Sets from '../pages/Catalog/Sets.vue'
import SetsOld from '../pages/Catalog/SetsOld.vue'
import SetsOldNew from '../pages/Catalog/SetsOldNew.vue'
import Stock from '../pages/Catalog/Stock.vue'
import UploadImages from '../pages/Catalog/UploadImages.vue'
import ProductsAttributes from '../pages/Catalog/ProductsAttributes.vue'
import Product from '../pages/Catalog/product/SingleProduct'
import EditProduct from '../pages/Catalog/product/EditProduct'
import ProductDescriptions from '../pages/Catalog/ProductDescriptions'
import ProductDefects from '../pages/Catalog/Defects'

// Sales
import Orders from '../pages/Sales/Orders.vue'
import OrdersOld from '../pages/Sales/OrdersOld.vue'
import Checks from '../pages/Sales/Checks.vue'
import ChecksOld from '../pages/Sales/ChecksOld.vue'
import BestOffers from '../pages/Sales/BestOffers.vue'
import Customers from '../pages/Sales/Customers.vue'

// Tools
//dynamic import for async components
let  FailedJobs = () => import(/* webpackChunkName: "loaders" */ '../pages/Tools/FailedJobs.vue')
import Blogs from '../pages/Tools/Blogs.vue'

// eBay
import Categories from '../pages/EBay/Categories.vue'
import CategorySpecific from '../pages/EBay/CategorySpecific.vue'
import ProductSpecifics from '../pages/EBay/ProductSpecifics.vue'

// Reports
import GoodsFlow from '../pages/Reports/GoodsFlow.vue'
import EBayTrafficReport from '../pages/Reports/EBayTrafficReport.vue'

// Configuration
import Currencies from '../pages/Configuration/Currencies.vue'
import OrdersStatus from '../pages/Configuration/OrdersStatus.vue'
import Defects from '../pages/Configuration/Defects.vue'
import AuctionsBuy from '../pages/Configuration/AuctionsBuy.vue'

import Order from '../pages/order/Order'

import NotFound from '../pages/NotFound.vue'


Vue.use(VueRouter);


const routes =  [
    {path: '/', component: DashboardLayout,
        children: [
            //Sniper routes
            {path: 'sniper_search', exact: true, component: SniperSearch, name: 'Sniper Search'},
            {path: 'sniper_buy', exact: true, component: SniperBuy, name: 'Sniper Buy'},

            // Catalog routes
            // {path: '', component: Cameramate, name: "Cameramate"},
            {path: 'products', exact: true, component: Products, name: 'Products', props: true},
            {path: 'products_old', exact: true, component: ProductsOld, name: 'Products Old', props: true},
            {path: 'add_product', component: AddProduct, name: 'Add Product'},
            {path: 'add_product_old', component: AddProductOld, name: 'Add Product Old'},
            {path: 'edit_product/:productsId', component: EditProduct, name: 'Edit Product', props: true},
            {path: 'products_for_ebay', component: ProductsForEbay, name: 'Products For Ebay'},
            {path: 'sets', component: Sets, name: 'Sets'},
            {path: 'sets_old', component: SetsOld, name: 'Sets Old'},
            {path: 'sets_old_new', component: SetsOldNew, name: 'Sets Old New'},
            {path: 'stock', component: Stock, name: 'Stock'},
            {path: 'upload_images', component: UploadImages, name: 'Upload images'},
            {path: 'products_attributes', component: ProductsAttributes, name: 'Products attributes'},
            {path: 'product_defects/:productsId', component: ProductDefects, name: 'Product defects', props: true},

            // Sales routes
            {path: 'orders', exact: true, component: Orders, name: 'Orders', props: true},
            {path: 'orders_old', exact: true, component: OrdersOld, name: 'Orders Old', props: true},
            {path: 'checks', component: Checks, name: 'Checks'},
            {path: 'checks_old', component: ChecksOld, name: 'Checks Old'},
            {path: 'bestoffers', component: BestOffers, name: 'Best Offers'},
            {path: 'bestoffer/:id', component: BestOffers, name: 'Best Offer'},
            {path: 'customers', component: Customers, name: 'Customers'},

            // Tools routes
            {path: 'failed_jobs', component: FailedJobs, name: 'Failed Jobs'},
            {path: 'blogs', component: Blogs, name: 'Blogs'},

            // eBay routes
            {path: 'ebay_category', component: Categories, name: 'Ebay Categories'},
            {path: 'ebay_categoryspecifics', component: CategorySpecific, name: 'eBay Category Specifics'},
            {path: 'ebay_productspecifics/:id', component: ProductSpecifics, name: 'eBay Product Specifics'},

            // Reports routes
            {path: 'goods_flow', component: GoodsFlow, name: 'Goods Flow'},
            {path: 'ebay_traffic_report', component: EBayTrafficReport, name: 'eBay Traffic Report'},

            // Configuration routes
            {path: 'currencies', component: Currencies, name: 'Currencies'},
            {path: 'orders_status', component: OrdersStatus, name: 'Orders Status'},
            {path: 'defects', component: Defects, name: 'Defects'},
            {path: 'auctions_buy', component: AuctionsBuy, name: 'Auctions Buy'},

            //{path: 'order/:id', component: Order, name: 'Order'},
            {path: 'order/:id/:activeTab', component: Order, name: 'Order', props: true},
            {path: 'product/:id', component: Product, name: 'Product', exact:true},
            {path: 'product_descriptions/:id', component: ProductDescriptions, name: 'Product descriptions'},

            {path: 'change-password', name: 'Change password', component: changePassword},
            {path: 'register', name: 'Registeration new user', component: Register}
        ]
    },

    {
        path: '/auth', component: AuthLayout,
        children: [
            {path: '/login', name: 'auth.login', component: Login},
            {path: '/password/email', name: 'auth.email', component: ResetEmail},
            {path: '/password/reset/:token', component: ResetPassword, props: true},
            {path: '/register', name: 'landing.register', component: Register}
        ]
    },
    {path: '*', component: NotFound}
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router