<template>
    <div>
        <JqxDataTable v-if="dataAdapter"
                 ref="grid"
                 :width="width"
                 :source="dataAdapter"
                 :columns="columns"
                 :groupable="true"
                 :groups="['products_status']"
                 :groupsRenderer="groupsRenderer"/>
    </div>
</template>

<script>
    import JqxDataTable from "jqwidgets-scripts/jqwidgets-vue/vue_jqxdatatable.vue"
    import { mapGetters } from 'vuex'

    export default {
        name: "BuyerHistory",
        props:{
            isActive:{}
        },
        data(){
            return {
                width: '100%',
                columns: [
                    { text: 'ID', dataField: 'products_id', width: 50 },
                    { text: 'Product', dataField: 'products_name'},
                    { text: 'orders_id', dataField: 'orders_products_id', width: 100, hidden: true},
                    { text: 'Quantity', dataField: 'products_quantity', width: 50, cellsalign: 'right' },
                    { text: 'Price', dataField: 'products_price', width: 90, cellsalign: 'right', cellsformat: 'd2' },
                    { text: 'Currency', dataField: 'products_currency', width: 50, cellsalign: 'left' },
                    { text: 'Status', dataField: 'products_status', width: 50, hidden: true },
                ],
                buyersHistoryData: null,
                orders: null
            }

        },
        computed:{
            ...mapGetters([
                'initialCurrencies',
                'order',
                'customerId'
            ]),
            dataAdapter(){
                if(this.orders){
                    return new jqx.dataAdapter(this.orders)
                }
            },
        },
        mounted() {
            this.init()
        },
        methods:{
             init(){
                axios.get(`/api/customers/${this.customerId}?with[]=order_products.order:orders_id,orders_status,currency`)
                    .then(response => {
                        this.buyersHistoryData = response.data.data[0].order_products
                        const buyersHistory = this.buyersHistoryData.filter(item => {
                            return item.order.orders_status_name.orders_status_name !== "Combined"
                        })
                        this.$emit('componentReady')
                        this.orders = {
                            localdata: buyersHistory,
                            datatype: 'array',
                            datafields: [
                                { name: 'products_id', type: 'string' },
                                { name: 'products_name', type: 'string' },
                                { name: 'orders_products_id', type: 'string' },
                                { name: 'products_quantity', type: 'int' },
                                { name: 'products_price', type: 'string' },
                                { name: 'products_status', map: 'order>orders_status_name>orders_status_name', type: 'string' },
                                { name: 'products_currency', map: 'order>currency', type: 'string' },
                            ],

                        };
                    });
            },
            groupsRenderer(value, rowData, level) {

                const EURRate = this.initialCurrencies.filter(item => item.code === "EUR")[0].value

                const data = this.groupBy(rowData.records, 'products_currency')
                let totalEURSum = 0;
                let totalUSDSum = 0;

                Object.values(data).forEach(item => {
                    const sum = item.reduce( function(cnt,o){ return cnt + o.products_price * o.products_quantity }, 0)
                    const rate = this.initialCurrencies.filter(curr => curr.code === item[0].products_currency)[0].value
                    const USDSum = sum * rate;
                    totalUSDSum += USDSum
                    totalEURSum += USDSum / EURRate
                })

                return `<span class="font-weight-bold">Status: ${value};</span>
                        <span>
                            Total ${~~totalEURSum} EUR, ${~~totalUSDSum} USD
                        </span>`

            },
            groupBy(xs, key) {
                return xs.reduce((rv, x) => {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            }
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-buyer-history"){
                    this.init()
                }
            }
        },
        components:{
            JqxDataTable
        },

    }
</script>

<style scoped>

</style>