<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://oldadmin.cameramate.com/_tools/?disableMenu=1#sets"></iframe>
</template>

<script>
export default {
    name: "SetsOldNew"
}
</script>

<style scoped>

</style>