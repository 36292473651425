var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ordered-datepicker" },
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        staticClass: "caption font-weight-regular",
                        attrs: {
                          dense: "",
                          value: _vm.date,
                          rules: _vm.rules,
                          "prepend-icon": "event",
                          label: _vm.labelName,
                          readonly: "",
                          "append-icon": _vm.isCleared ? "clear" : null
                        },
                        on: { "click:append": _vm.toggleClear }
                      },
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _vm._v(" "),
          _c("v-date-picker", {
            staticClass: "caption font-weight-regular text-right",
            attrs: { "no-title": "" },
            on: {
              input: function($event) {
                _vm.menu = false
              }
            },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }