var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "full-width" },
    [
      _c(
        "v-toolbar",
        { attrs: { elevation: "0", dense: "", flat: "" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "elevation-1",
              attrs: { "show-arrows": "", grow: "" },
              model: {
                value: _vm.currentDefectTabIndex,
                callback: function($$v) {
                  _vm.currentDefectTabIndex = $$v
                },
                expression: "currentDefectTabIndex"
              }
            },
            _vm._l(_vm.productParts, function(itemPart, i) {
              return _c(
                "v-tab",
                { key: i },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.getProductsPartById(itemPart.parts_id) +
                          ": " +
                          itemPart.title
                      ) +
                      "\n                "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openDialogEditPartName(itemPart.id)
                        }
                      }
                    },
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "pink", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteProductPart(itemPart.id)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("delete")])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "success", fab: "", dark: "", "x-small": "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addPart.apply(null, arguments)
                }
              }
            },
            [_c("v-icon", { attrs: { small: "", dark: "" } }, [_vm._v("add")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          staticClass: "elevation-1 text-left",
          model: {
            value: _vm.currentDefectTabIndex,
            callback: function($$v) {
              _vm.currentDefectTabIndex = $$v
            },
            expression: "currentDefectTabIndex"
          }
        },
        _vm._l(_vm.productParts, function(itemPart, i) {
          return _c(
            "v-tab-item",
            { key: i, staticClass: "scroll-tabs defects_list" },
            [
              _vm.productsDefects
                ? _c(
                    "v-list",
                    {
                      staticClass: "defects_list",
                      attrs: { expand: "", dense: "" }
                    },
                    _vm._l(
                      _vm.getProductsDefectsByPartId(itemPart.parts_id),
                      function(ProductsDefects, i) {
                        return _c(
                          "v-list-group",
                          {
                            key: i,
                            attrs: { "no-action": "", multiple: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  ProductsDefects.title +
                                                    " (" +
                                                    _vm.getCountProductDefects(
                                                      itemPart.id,
                                                      ProductsDefects.defects_groups_id
                                                    ) +
                                                    ")" +
                                                    (_vm.defects ? "" : "")
                                                ) +
                                                "\n                            "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: ProductsDefects.expanded,
                              callback: function($$v) {
                                _vm.$set(ProductsDefects, "expanded", $$v)
                              },
                              expression: "ProductsDefects.expanded"
                            }
                          },
                          [
                            _vm._v(" "),
                            _vm._l(ProductsDefects.defects, function(defects) {
                              return _c(
                                "v-list-item",
                                { key: defects.defects_id },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "pl-2 ma-0 align-top" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "pa-0 ma-0",
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              "input-value": _vm.getProductDefect(
                                                itemPart,
                                                ProductsDefects.defects_groups_id,
                                                defects.defects_id
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changeProductDefect(
                                                  itemPart,
                                                  defects,
                                                  ProductsDefects,
                                                  $event
                                                )
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "black--text",
                                                          staticStyle: {
                                                            "font-size": "0.8em"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              defects.title
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      }
                    ),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "30%" },
          model: {
            value: _vm.dialogEditPartName,
            callback: function($$v) {
              _vm.dialogEditPartName = $$v
            },
            expression: "dialogEditPartName"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("\n                Edit part name\n            ")
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.currentPart
                    ? _c("v-text-field", {
                        attrs: {
                          label: "",
                          dense: "",
                          "single-line": "",
                          autofocus: ""
                        },
                        model: {
                          value: _vm.currentPart.title,
                          callback: function($$v) {
                            _vm.$set(_vm.currentPart, "title", $$v)
                          },
                          expression: "currentPart.title"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: { click: _vm.cancelEditPartName }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.savePartName }
                    },
                    [_vm._v("\n                    Save\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "30%" },
          model: {
            value: _vm.addProductPartDialog,
            callback: function($$v) {
              _vm.addProductPartDialog = $$v
            },
            expression: "addProductPartDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("\n                Add part\n            ")
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.productsDefects,
                      "item-text": "title",
                      "item-value": "parts_id",
                      "hide-details": "",
                      dense: "",
                      "cache-items": "",
                      autofocus: ""
                    },
                    model: {
                      value: _vm.newPart,
                      callback: function($$v) {
                        _vm.newPart = $$v
                      },
                      expression: "newPart"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: { click: _vm.closeProductPartDialog }
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "s",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          return _vm.saveNewProductPart.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("\n                    Add\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }