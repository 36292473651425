<template>
    <v-layout align-space-around justify-start column fill-height>
        <v-flex xs12 style="max-height: 48px; height: 48px;">
            <v-toolbar dense flat light color="grey lighten-5">
                <p class="text-truncate text-left title ma-0" v-if="productLoaded &&  product.descriptions">
                    {{ product.products_id }} - {{ product.descriptions.products_name }}
                </p>
                <v-spacer></v-spacer>
                <v-btn
                    id="RefreshButton"
                    @click="updateProductToEbayCategory(products_id)"
                >Refresh</v-btn>
            </v-toolbar>
        </v-flex>
        <v-flex xs12>
            <v-row dense v-if="ebay_categoryspecifics.length">
                <v-col
                    v-for="(ebay_categoryspecific, index) in ebay_categoryspecifics"
                    :key="ebay_categoryspecific.siteId"
                    :cols="Math.round(12/ebay_categoryspecifics.length)"
                >
                    <v-card>
                        <v-card-title class="ma-0 pa-2">

                            <v-avatar
                                size="24"
                            >
                                <img
                                    :alt="ebay_global_sites[ebay_categoryspecific.siteId].country"
                                    :src="ebay_global_sites[ebay_categoryspecific.siteId].icon"
                                >
                            </v-avatar>
                            <strong class="ml-1" v-text="(product.ebay_categories[ebay_categoryspecific.siteId] ? product.ebay_categories[ebay_categoryspecific.siteId].name : 'Unknown category')"></strong>
                            <v-spacer></v-spacer>
                            <!--<v-switch
                                v-model="autoUpdate"
                                class="mt-0"
                                color="green lighten-2"
                                hide-details
                                label="Auto Update"
                                dense
                            ></v-switch>-->
                        </v-card-title>
                        <v-card-text>
                            <v-row
                                dense
                                no-gutters
                                align="center"
                            >
                                <v-col
                                    v-for="(aspect, index) in ebay_categoryspecific.aspects"
                                    :key="aspect.aspectName"
                                    cols="12">
                                    <v-combobox
                                        v-if="(aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY') || (aspect.aspectValues)"
                                        :id="aspect.aspectName+'-'+ebay_categoryspecific.siteId+'_'+ebay_categoryspecific.categoryId"
                                        v-model="product_ebay_categoryspecifics[ebay_categoryspecific.siteId][aspect.aspectName].value"
                                        :items="aspect.aspectValues"
                                        item-text="localizedValue"
                                        item-value="localizedValue"
                                        :label="aspect.localizedAspectName"
                                        outlined
                                        dense
                                        :multiple="(aspect.aspectConstraint.itemToAspectCardinality === 'MULTI')"
                                        :required="aspect.aspectConstraint.aspectRequired == true"
                                        :error="aspect.aspectConstraint.aspectRequired == true"
                                        clearable
                                        :chips="(aspect.aspectConstraint.itemToAspectCardinality === 'MULTI')"
                                        :small-chips="(aspect.aspectConstraint.itemToAspectCardinality === 'MULTI')"
                                        :deletableChips="(aspect.aspectConstraint.itemToAspectCardinality === 'MULTI')"
                                        hideDetails
                                        class="mb-2"
                                        hideNoData
                                        @change="saveProductSpecifics(ebay_categoryspecific.siteId, ebay_categoryspecific.categoryId, product_ebay_categoryspecifics[ebay_categoryspecific.siteId])"
                                    >
                                    </v-combobox>
                                    <v-text-field
                                        v-else
                                        v-model="product_ebay_categoryspecifics[ebay_categoryspecific.siteId][aspect.aspectName].value"
                                        :label="aspect.localizedAspectName"
                                        outlined
                                        dense
                                        :required="aspect.aspectConstraint.aspectRequired == true"
                                        :error="aspect.aspectConstraint.aspectRequired == true"
                                        clearable
                                        hideDetails
                                        class="mb-2"
                                        @change="saveProductSpecifics(ebay_categoryspecific.siteId, ebay_categoryspecific.categoryId, product_ebay_categoryspecifics[ebay_categoryspecific.siteId])"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-flex>
    </v-layout>
</template>

<script>

import { mapGetters } from 'vuex'

import localforage from 'localforage'
import memoryDriver from 'localforage-memoryStorageDriver'
import { setup } from 'axios-cache-adapter'

async function configure () {
    // Register the custom `memoryDriver` to `localforage`
    await localforage.defineDriver(memoryDriver)

    // Create `localforage` instance
    const forageStore = localforage.createInstance({
        // List of drivers used
        driver: [
            localforage.INDEXEDDB,
            localforage.LOCALSTORAGE,
            memoryDriver._driver
        ],
        // Prefix all storage keys to prevent conflicts
        name: 'cameramate_db'
    })

    // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
    return setup({
        // `axios` options
        // baseURL: 'http://some-rest.api',

        // `axios-cache-adapter` options
        cache: {
            maxAge: 15 * 60 * 1000,
            exclude: { query: false },
            store: forageStore // Pass `localforage` store to `axios-cache-adapter`
        }
    })
}


export default {
    name: "ProductSpecifics",
    props: [ 'id' ],
    data() {
        return {
            product: null,
            productLoaded: false,
            products_id: null,
            ebay_categoryspecifics: [],
            product_ebay_categoryspecifics: [],
            autoUpdate: true
        }
    },
    computed:{
        categorySpecifics(){
            if(this.ebay_categoryspecifics.length){
                return this.ebay_categoryspecifics
            }
            return false
        },
        ...mapGetters([
            'ebay_global_sites'
        ]),
    },
    mounted() {
        if (!this.id) {
            this.products_id = this.$route.params.id
        } else {
            this.products_id = this.id
        }

        this.updateProductToEbayCategory(this.products_id);

    },
    methods: {
        async updateProductToEbayCategory(productId) {
            this.ebay_categoryspecifics = []
            await axios
                .get(`/api/products_to_ebay_category_forNewProduct/${productId}/`)
                .then(response => {
                    this.fetchProduct(this.products_id);
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Product is not present.');
                })

        },
        async fetchProduct(productId) {
            await axios
                .get(`/api/products/?
                    filterGroups[0][field]=products_id
                    &filterGroups[0][filters][0][value]=${productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=or
                    &with[]=descriptions
                    &with[]=ebay_categoryspecific
                    &with[]=products_for_ebay
                    &with[]=ebay_categories`)
                .then(response => {
                    this.product = response.data.data[0];

                    let ebay_categoryspecific = {}
                    let product_ebay_categories = {}
                    let filtered_ebay_categoryspecific = {}

                    if (this.product.ebay_categoryspecific) {
                        ebay_categoryspecific = this.product.ebay_categoryspecific
                        product_ebay_categories = this.product.ebay_categories
                        filtered_ebay_categoryspecific = _.filter(ebay_categoryspecific, ({catId, siteID}) => {
                            //console.log(catId, siteID)
                            let index = _.findIndex(product_ebay_categories, ({catid:filtercatId, siteID:filtersiteID}) => {
                                //console.log('product_ebay_categories')
                                //console.log(filtercatId, filtersiteID)
                                //console.log((catId == filtercatId && siteID == filtersiteID))
                                return (catId == filtercatId && siteID == filtersiteID)
                            })
                            //console.log('index', index)
                            return  index >= 0;
                        })

                        //console.log(filtered_ebay_categoryspecific)

                        this.product.ebay_categoryspecific = _.keyBy(filtered_ebay_categoryspecific, 'siteID');
                    }

                    if (this.product.products_for_ebay) this.product.products_for_ebay = _.keyBy(this.product.products_for_ebay, 'siteID');

                    let theThis = this;
                    if (this.product.ebay_categories) {
                        this.product.ebay_categories = _.keyBy(this.product.ebay_categories, 'siteID');
                        _.forIn(this.ebay_global_sites, function(element, key) {
                            if (theThis.product.ebay_categories[element.value]) {
                                theThis.fetchCategorySpecifics(element.value, theThis.product.ebay_categories[element.value].catid)
                            }
                        });
                    }

                    console.log(this.product)
                    this.status = this.product.products_status.toString()
                    this.productLoaded = true
                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. Product is not present.');
                })

        },
        fetchCategorySpecifics(siteId, categoryId) {
            configure().then(async (api) => {
                console.log(siteId, categoryId)
                const response = await api.get(`/api/ebay_categoryspecifics/${siteId}/${categoryId}`)

                let product_asspects = {}
                product_asspects.siteId = siteId
                product_asspects.categoryId = categoryId
                product_asspects.aspects = []
                delete this.product_ebay_categoryspecifics[siteId]
                this.product_ebay_categoryspecifics[siteId] = {}

                response.data.aspects.forEach((element) => {
                    element.aspectName = _.camelCase(element.localizedAspectName);
                    let aspect = {}
                    aspect.localizedAspectName = element.localizedAspectName
                    if (this.product.ebay_categoryspecific && this.product.ebay_categoryspecific[siteId] && this.product.ebay_categoryspecific[siteId].formfields && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName] && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value) {
                        if (_.isObject(this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value)) {
                            aspect.value = _.toArray(this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value)
                        } else {
                            aspect.value = this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value
                        }
                    } else {
                        aspect.value = ''
                    }
                    /*if (this.product.ebay_categoryspecific && this.product.ebay_categoryspecific[siteId] && this.product.ebay_categoryspecific[siteId].formfields && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName]) {
                        aspect.value = this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value.localizedValue
                    } else {
                        aspect.value = ''
                    }*/
                    aspect.required = (element.aspectConstraint.aspectRequired ? 1 : 0)
                    this.product_ebay_categoryspecifics[siteId][element.aspectName] = aspect
                });

                let aspects = {}
                aspects.siteId = siteId;
                aspects.categoryId = categoryId
                aspects.aspects = response.data.aspects

                this.ebay_categoryspecifics.push(aspects);
            })
            /*await axios
                .get(`/api/ebay_categoryspecifics/${siteId}/${categoryId}`)
                .then(response => {

                    let product_asspects = {}
                    product_asspects.siteId = siteId
                    product_asspects.categoryId = categoryId
                    product_asspects.aspects = []
                    delete this.product_ebay_categoryspecifics[siteId]
                    this.product_ebay_categoryspecifics[siteId] = {}

                    response.data.aspects.forEach((element) => {
                        element.aspectName = _.camelCase(element.localizedAspectName);
                        let aspect = {}
                        aspect.localizedAspectName = element.localizedAspectName
                        if (this.product.ebay_categoryspecific && this.product.ebay_categoryspecific[siteId] && this.product.ebay_categoryspecific[siteId].formfields && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName] && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value) {
                            if (_.isObject(this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value)) {
                                aspect.value = _.toArray(this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value)
                            } else {
                                aspect.value = this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value
                            }
                        } else {
                            aspect.value = ''
                        }
                        //if (this.product.ebay_categoryspecific && this.product.ebay_categoryspecific[siteId] && this.product.ebay_categoryspecific[siteId].formfields && this.product.ebay_categoryspecific[siteId].formfields[element.aspectName]) {
                        //    aspect.value = this.product.ebay_categoryspecific[siteId].formfields[element.aspectName].value.localizedValue
                        //} else {
                        //    aspect.value = ''
                        //}
                        aspect.required = (element.aspectConstraint.aspectRequired ? 1 : 0)
                        this.product_ebay_categoryspecifics[siteId][element.aspectName] = aspect
                    });

                    let aspects = {}
                    aspects.siteId = siteId;
                    aspects.categoryId = categoryId
                    aspects.aspects = response.data.aspects

                    this.ebay_categoryspecifics.push(aspects);

                }).catch((error) => {
                    console.log(error);
                    console.warn('Request was not sent properly. CategorySpecifics is not present.');
                })*/

        },
        saveProductSpecifics(siteId, categoryId, aspects) {
            console.log(aspects);
            /*_.forIn(aspects, function(element, key) {
                if (typeof element.value == "string") {
                    let value = element.value
                    element.value = {}
                    element.value.localizedValue = value
                }
            });
            console.log(aspects);*/
            axios.put('/api/ebay_categoryspecifics/'+this.products_id,
                {
                    catId: categoryId,
                    siteID: siteId,
                    aspects: aspects
                }

            )
        }
    },
}
</script>

<style scoped>

</style>