<template>
    <v-layout align-space-around justify-start column fill-height id="Checks">
        <v-flex xs12 style="height: 100%">
            <JqxSplitter
                ref="mainSplitter"
                :panels="panels"
                width="100%"
                height="100%"
                :theme="jqwTheme"
            >
                <div>

                    <jqxTabs
                        ref="ChecksTabs"
                        width="100%"
                        height="100%"
                        position="top"
                        animationType="none"
                        @selecting="onChecksTabsSelecting($event)"
                        @selected="onChecksTabsSelected($event)"
                    >
                        <ul>
                            <li>Checks</li>
                            <li>Sold products</li>
                            <li>All products</li>
                        </ul>
                        <div style="overflow: hidden">
                            <JqxDataTable
                                v-if="checksDataAdapter"
                                ref="ChecksDataTable"
                                width="100%"
                                height="100%"
                                :source="checksDataAdapter"
                                :columns="checksColumns"
                                :pageable="true"
                                :serverProcessing="true"
                                :pagerButtonsCount="2"
                                :pagerMode="'default'"
                                :altRows="true"
                                :filterable="true"
                                :filterMode="'default'"
                                :sortable="true"
                                @rowClick="onRowClickChecks($event)"
                                :statusBarHeight="25"
                                :theme="jqwTheme"
                            />
                        </div>
                        <div style="overflow: hidden">
                            <JqxDataTable
                                v-if="ordersProductsDataAdapter"
                                ref="ordersProductsDataTable"
                                width="100%"
                                height="100%"
                                :source="ordersProductsDataAdapter"
                                :columns="ordersProductsColumns"
                                :serverProcessing="true"
                                :pagerButtonsCount="2"
                                :pagerMode="'advanced'"
                                :pageSizeOptions=" [25, 50, 100] "
                                :altRows="true"
                                :pageable="pageable"
                                :sortable="true"
                                :editSettings="editSettings"
                                :localization="localization"
                                :theme="jqwTheme"
                                :filterable="true"
                                @rowClick="onRowClickOrdersProducts($event)"
                                :showToolbar="true"
                                :renderToolbar="renderOrdersProductsToolbar"
                            />
                            <v-flex xs12 sm12 class="text-left absolute full-width" style="top: 45px; left: 10px;">
                                <JqxCheckBox v-model="orderFilter" :width="150" :height="25" :checked="true" :hasThreeStates="false" style="float: left;">Order status filter</JqxCheckBox>
                                <JqxDateTimeInput ref="filerByDataOrdersProductsDataTable" :autoCreate="false" style="float: left;" @change="changeFilerByDataOrdersProductsDataTable()"></JqxDateTimeInput>
                                <v-btn x-small style="float: left;" class="ml-3" @click="showAddSelectedDialog" :disabled="isAddSelectedDialog">Add selected</v-btn>
                                <v-dialog
                                    v-model="isAddSelectedDialog"
                                    hide-overlay
                                    persistent
                                    width="300"
                                >
                                    <v-card
                                        color="wight"
                                    >
                                        <v-card-text class="pt-4">
                                            <v-layout justify-center class="text-bold-200 red--text text--darken-3" v-if="selectedProducts.length === 0">
                                                There are not selected products!
                                            </v-layout>
                                            <v-layout class="text-bold-200 green--text text--darken-4" justify-center v-else-if="!addSelectedProductRunning">
                                                Are you ready?
                                            </v-layout>
                                            <v-layout justify-center v-else>
                                                Please stand by
                                            </v-layout>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-layout justify-center>
                                                <v-spacer/>
                                                <v-btn @click="isAddSelectedDialog = false">Close</v-btn>
                                                <v-spacer v-show="selectedProducts.length" />
                                                <v-btn color="success" v-show="selectedProducts.length" @click="runAddSelectedProduct">Run!</v-btn>
                                                <v-spacer/>
                                            </v-layout>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-flex>
                        </div>
                        <div style="overflow: hidden">
                            <JqxDataTable
                                v-if="allProductsDataAdapter"
                                ref="allProductsDataTable"
                                width="100%"
                                height="100%"
                                :source="allProductsDataAdapter"
                                :columns="allProductsColumns"
                                :serverProcessing="true"
                                :pagerButtonsCount="2"
                                :pagerMode="'advanced'"
                                :pageSizeOptions=" [25, 50, 100] "
                                :altRows="true"
                                :pageable="true"
                                :sortable="true"
                                :editSettings="editSettings"
                                :localization="localization"
                                :theme="jqwTheme"
                                :filterable="true"
                                @rowClick="onRowClickAllProducts($event)"
                            />
                            <v-flex xs12 sm12 class="text-left absolute" style="top: 45px; right: 0px;">
                                <v-btn x-small style="float: right; right: 25px;" class="ml-3" @click="showAddSelectedAllProductsDialog" :disabled="isAddSelectedAllProductsDialog">Add selected</v-btn>
                                <v-dialog
                                    v-model="isAddSelectedAllProductsDialog"
                                    hide-overlay
                                    persistent
                                    width="300"
                                >
                                    <v-card
                                        color="wight"
                                    >
                                        <v-card-text class="pt-4">
                                            <v-layout justify-center class="text-bold-200 red&#45;&#45;text text&#45;&#45;darken-3" v-if="selectedAllProducts.length === 0">
                                                There are not selected products!
                                            </v-layout>
                                            <v-layout class="text-bold-200 green&#45;&#45;text text&#45;&#45;darken-4" justify-center v-else-if="!addSelectedAllProductRunning">
                                                Are you ready?
                                            </v-layout>
                                            <v-layout justify-center v-else>
                                                Please stand by
                                            </v-layout>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-layout justify-center>
                                                <v-spacer/>
                                                <v-btn @click="isAddSelectedAllProductsDialog = false">Close</v-btn>
                                                <v-spacer v-show="selectedAllProducts.length" />
                                                <v-btn color="success" v-show="selectedAllProducts.length" @click="runAddSelectedAllProduct">Run!</v-btn>
                                                <v-spacer/>
                                            </v-layout>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-flex>
                        </div>
                    </jqxTabs>
                    <v-flex xs5 sm6 class="text-right absolute checks-form-buttons" v-if="tabSelectedIndex == 0">
                        <v-btn small icon color="red" @click="addNewCheck"><v-icon>mdi-plus</v-icon></v-btn>
                        <v-btn small icon color="green" @click="refreshCheckDataTable"><v-icon>mdi-cached</v-icon></v-btn>
                    </v-flex>
                    <v-flex xs5 sm6 class="text-right absolute checks-form-buttons" v-if="tabSelectedIndex == 1">
                        <v-btn small icon color="green" @click="refreshOrdersProductsDataTable"><v-icon>mdi-cached</v-icon></v-btn>
                    </v-flex>
                    <v-flex xs5 sm6 class="text-right absolute checks-form-buttons" v-if="tabSelectedIndex == 2">
                        <v-btn small icon color="green" @click="refreshAllProductsDataTable"><v-icon>mdi-cached</v-icon></v-btn>
                    </v-flex>
                </div>
                <div>
                    <JqxSplitter
                        ref="horizontalSplitter"
                        :width="'100%'"
                        :height="'100%'"
                        :orientation="'horizontal'"
                        :showSplitBar="false"
                        :panels="[{ size: 105, min: 105, max: 105, collapsible: false },{ min: 100, collapsible: false }]"
                        :theme="jqwTheme"
                        v-if="currentCheck || isNewCheck"
                    >
                        <div>
                            <v-form
                                ref="formCheck"
                                v-model="validCheck"
                                lazy-validation
                                v-if="currentCheck || isNewCheck"
                            >
                                <v-container
                                    class="pa-0"
                                >
                                    <v-row justify="center" align="center" class="ma-0">
                                        <v-col cols="12" class="pa-0">
                                            <v-row style="margin: 0 !important;">
                                                <v-col cols="2" class="pb-0">
                                                    <datepicker
                                                        v-model="currentCheck.check_date"
                                                        class="font-weight-regular ma-1"
                                                        :labelName="'Date'"
                                                        @input="saveCheck"
                                                        :rules="rules"
                                                        required=true
                                                        :hideDetails="false"
                                                    ></datepicker>
                                                </v-col>
                                                <v-col cols="9" class="pb-0">
                                                    <v-text-field
                                                        dense
                                                        class="font-weight-regular ma-1"
                                                        v-model="currentCheck.check_number"
                                                        label="Name"
                                                        :rules="rules"
                                                        required
                                                        @change="saveCheck"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row style="margin: 0 !important;">
                                                <v-col cols="4" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        v-model="currentCheck.total"
                                                        class="font-weight-regular ma-1"
                                                        label="Check total"
                                                        readonly
                                                        hide-details
                                                    >
                                                        <template v-slot:append>
                                                            <v-select
                                                                dense
                                                                label=""
                                                                v-model="checkCurrency"
                                                                :items="currencies"
                                                                class="caption font-weight-regular"
                                                                :rules="rules"
                                                                required
                                                                hide-details
                                                                single-line
                                                                style="width: 58px;"
                                                                @change="saveCheck"
                                                            ></v-select>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4" class="py-0">
                                                    <v-text-field
                                                        dense
                                                        v-model="currentCheck.duty_total"
                                                        class="font-weight-regular ma-1"
                                                        label="Duty total"
                                                        hide-details
                                                        @change="saveCheck"
                                                    >
                                                        <template v-slot:append>
                                                            <v-select
                                                                dense
                                                                label=""
                                                                v-model="checkDutyCurrency"
                                                                :items="currencies"
                                                                class="caption font-weight-regular"
                                                                :rules="rules"
                                                                required
                                                                hide-details
                                                                single-line
                                                                style="width: 58px;"
                                                                @change="saveCheck"
                                                            ></v-select>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-spacer/>
                                                <v-col cols="2" class="py-0" >
                                                    <v-btn
                                                        color='success'
                                                        fab
                                                        x-small
                                                        outlined
                                                        @click="addCheck"
                                                        :disabled="isCheckUpdating"
                                                        v-if="isNewCheck"
                                                    >
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        color='primary'
                                                        fab
                                                        x-small
                                                        outlined
                                                        @click="saveCheck"
                                                        :disabled="isCheckUpdating"
                                                        v-else
                                                    >
                                                        <v-icon>save</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        x-small
                                                        icon
                                                        @click="printCheck"
                                                        fab
                                                        :disabled="isCheckUpdating"
                                                    >
                                                        <v-icon>print</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </div>
                        <div>
                            <JqxDataTable
                                v-if="productsCheckDataAdapter && currentCheckId"
                                ref="ProductsCheckDataTable"
                                width="100%"
                                height="100%"
                                :source="productsCheckDataAdapter"
                                :columns="productsCheckColumns"
                                :serverProcessing="true"
                                :showAggregates="true"
                                :aggregatesHeight="25"
                                @rowClick="onRowClickProductsCheck($event)"
                                :editSettings="editSettings"
                                :editable="true"
                                :localization="localization"
                                @cellEndEdit="onCellEndEdit($event)"
                                :theme="jqwTheme"
                            />
                            <v-flex xs5 sm6 class="text-right absolute checks-form-buttons" style="margin-right: 18px; margin-top: 2px;" v-if="productsCheckDataAdapter">
                                <v-btn x-small icon color="green" @click="refreshProductsCheckDataTable"><v-icon>mdi-cached</v-icon></v-btn>
                            </v-flex>
                        </div>
                    </JqxSplitter>
                </div>
            </JqxSplitter>
        </v-flex>
    </v-layout>
</template>

<script>
import Datepicker from '@/components/CustomDatepicker.vue'
import EventBus from "@/event-bus";
import JqxCheckBox from "jqwidgets-scripts/jqwidgets-vue/vue_jqxcheckbox.vue";
import JqxDataTable from "jqwidgets-scripts/jqwidgets-vue/vue_jqxdatatable.vue"
import JqxDateTimeInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxdatetimeinput.vue"
import JqxSplitter from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxsplitter.vue';
import JqxTabs from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxtabs.vue';
import { mapGetters } from "vuex";

export default {
        name: "Checks",
        data: () => ({
            checksColumns: [
                { text: 'Number', dataField: 'check_number', align: 'center'},
                { text: 'Date', dataField: 'check_date', align: 'center', cellsAlign: 'center', width: '90px', cellsFormat: 'dd MMM yy', filterable: false},
                { text: 'Total', dataField: 'total', hidden: true},
                { text: '', dataField: 'check_id', align: 'center', cellsalign: 'center', width: 35, filterable: false, sortable: false, cellClassName: 'pointer',
                    cellsrenderer: function (row, column, value) {
                        return '<i aria-hidden="true" class="v-icon small material-icons theme--light">print</i>';

                    },
                },

            ],
            currentCheckId: null,
            currentCheck: null,
            currentCheckIndex: null,
            ordersProductsDataAdapter: null,
            allProductsDataAdapter: null,
            productsCheckDataAdapter: null,
            currentOrdersProductsIndex: null,
            currentAllProductsIndex: null,
            isCheckUpdating: false,
            isChecksTabSelected: true,
            tabSelectedIndex: 0,
            isNewCheck: false,
            newCheck: {
                check_id: null,
                check_date: null,
                check_number: '',
                currency: 'EUR',
                duty_currency: 'CZK',
                duty_total: 0,
                ebay_id: 'leica-post',
                total: 0,
                minus: 0
            },
            editSettings: {
                saveOnPageChange: false, saveOnBlur: false, saveOnSelectionChange: false, cancelOnEsc: true,
                saveOnEnter: true, editSingleCell: true, editOnDoubleClick: true, editOnF2: true
            },
            validCheck: false,
            localization: {
                thousandsSeparator: ''
            },
            rules: [
                v => !!v || 'Required',
            ],
            jqwTheme: 'material', //office,
            panels: [{ size: '40%', min: '30%', collapsible: false }, { size: '60%', min: '50%', collapsible: false  }],
            //dateTimeInput: null
            orderFilter: true,
            dataFilter: null,
            pageable: true,
            isCheckCurrencyChanged: false,
            isAddSelectedDialog: false,
            isAddSelectedAllProductsDialog: false,
            selectedProducts: [],
            selectedAllProducts: [],
            addSelectedProductRunning: false,
            addSelectedAllProductRunning: false
        }),
        watch: {
            orderFilter: function(val) {
                this.getOrdersProductsDataAdapter()
                this.$refs.ordersProductsDataTable.source = this.ordersProductsDataAdapter;
            },
            pageable: function(val) {
                console.log('pageable', val)
            }
        },
        computed: {
            checkCurrency: {
                get: function() {
                    return (this.currentCheck.currency ?? null);
                },
                set: function (newValue) {
                    if (this.currentCheck.currency !== newValue) this.isCheckCurrencyChanged = true
                    this.currentCheck.currency = newValue
                }
            },
            checkDutyCurrency: {
                get: function() {
                    return (this.currentCheck.duty_currency ?? null);
                },
                set: function (newValue) {
                    if (this.currentCheck.duty_currency !== newValue) this.isCheckCurrencyChanged = true
                    this.currentCheck.duty_currency = newValue
                }
            },
            currencies(){
                return this.currenciesList
            },
            checksDataAdapter(){
                let checksSource = {
                    pagesize: 25,
                    id: 'check_id',
                    url: `/api/checks`,
                    root: 'data',
                    datatype: "json",
                    sortcolumn: 'check_date',
                    sortdirection: 'desc',
                    totalrecords: 0,
                    //async: false,
                    datafields: [
                        { name: 'check_id', type: 'int' },
                        { name: 'check_number', type: 'string' },
                        { name: 'currency', type: 'string' },
                        { name: 'total', type: 'float' },
                        { name: 'duty_currency', type: 'string' },
                        { name: 'duty_total', type: 'float' },
                        { name: 'check_date', type: 'date'},
                    ],
                    beforeprocessing: function (data) {
                        checksSource.totalrecords = data.total;
                    },
                    updateRow: this.updateCheck,
                    deleterow: function (rowid, commit) {
                        commit(true);
                    }
                };
                return new jqx.dataAdapter(checksSource ,
                    {
                        formatData: function (data) {
                            data.page = data.pagenum+1;
                            return data;
                        }
                    })
            },
            productsCheckColumns() {
                return [
                    { text: 'ID', dataField: 'products_id', align: 'center', width: 50, editable: false, cellClassName: 'pointer underline'},
                    { text: 'Product', dataField: 'products_name', align: 'center', aggregates: ['count'], editable: false},
                    { text: 'Q', dataField: 'products_quantity', align: 'center', cellsAlign: 'center', width: 25, editable: true},
                    { text: 'Purchase', dataField: 'products_price', align: 'center', cellsAlign: 'right', width: 120, cellsFormat: 'f2', editable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.currencies_code
                        }
                    },
                    { text: 'Check price', dataField: 'c_purchase_price', align: 'center', cellsAlign: 'right', width: 120, cellsFormat: 'f2', cellClassName: 'font-weight-bold',
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.c_currency
                        },
                        aggregates:
                            [{
                                'total': (aggregatedValue, currentValue, column, record) => {
                                    return aggregatedValue + record.products_quantity*currentValue
                                }
                            }],
                        aggregatesRenderer: this.aggregatesCheckPriceRenderer
                    },
                    { text: 'Duty price', dataField: 'duty_price', align: 'center', cellsAlign: 'right', width: 120, cellsFormat: 'f2',
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.duty_currency
                        },
                        aggregates:
                            [{
                                'total': (aggregatedValue, currentValue, column, record) => {
                                    return aggregatedValue + currentValue
                                }
                            }],
                        aggregatesRenderer: this.aggregatesDutyPriceRenderer
                    },
                    { text: '', dataField: 'id', align: 'center', cellsalign: 'center', width: 35, filterable: false, sortable: false, cellClassName: 'pointer',
                        cellsrenderer: function (row, column, value) {
                            return '<i aria-hidden="true" class="v-icon small material-icons theme--light">delete</i>';

                        },
                    },
                ]
            },
            ordersProductsColumns() {
                return [
                    { text: 'ID', dataField: 'products_id', align: 'center', width: 50, cellClassName: 'pointer underline'},
                    { text: 'Product', dataField: 'products_name', align: 'center', aggregates: ['count']
                        /*,
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' - '+rowData.site
                        }*/
                    },
                    { text: 'Price', dataField: 'final_price', align: 'center', cellsAlign: 'right', width: 90, sortable: false, cellsFormat: 'f2', filterable: false,
                        cellsRenderer: (row, column, value, rowData) => {
                            return value+' '+rowData.currency
                        }
                    },
                    { text: 'Order', dataField: 'orders_id', align: 'center', cellsAlign: 'center', width: '50px', cellClassName: 'pointer underline'},
                    { text: 'Status', dataField: 'status', align: 'center', cellsAlign: 'center', width: '50px', sortable: false, filterable: false,
                        cellClassName: function (row, column, value, data) {
                            return value;
                        }
                    },
                    { text: 'Date', dataField: 'order.date_paid', align: 'center', cellsAlign: 'center', width: '90px', filterable: false, sortable: false, cellsFormat: 'dd MMM yy'},
                    { text: 'Q', dataField: 'products_quantity', align: 'center', cellsAlign: 'center', width: '20px', filterable: false, sortable: false},
                    { text: 'A', dataField: 'orders_products_id', align: 'center', cellsalign: 'center', width: 35, filterable: false, sortable: false, cellClassName: 'pointer',
                        cellsrenderer: function (row, column, value) {
                            return '<i aria-hidden="true" class="v-icon material-icons theme--light">exit_to_app</i>';

                        },
                    },
                    { text: '', align: 'center', cellsalign: 'center', width: 5, filterable: false, hidden: true}
                ]
            },
            allProductsColumns() {
                return [
                    { text: 'ID', dataField: 'products_id', align: 'center', width: 50, cellClassName: 'pointer underline'},
                    { text: 'Product', dataField: 'products_name', align: 'center', aggregates: ['count']},
                    { text: 'Q', dataField: 'products_quantity', align: 'center', cellsAlign: 'center', width: '20px', filterable: false, sortable: false},
                    { text: 'A', dataField: 'products_checks_id', align: 'center', cellsalign: 'center', width: 35, filterable: false, sortable: false, cellClassName: 'pointer',
                        cellsrenderer: function (row, column, value) {
                            return '<i aria-hidden="true" class="v-icon material-icons theme--light">exit_to_app</i>';

                        },
                    },
                    { text: '', align: 'center', cellsalign: 'center', width: 5, filterable: false, hidden: true}
                ]
            },
            ...mapGetters([
                'currenciesList'
            ]),
        },
        mounted() {
            this.$refs.ChecksTabs.disableAt(1);
            this.$refs.ChecksTabs.disableAt(2);
            this.$refs.filerByDataOrdersProductsDataTable.createComponent({ width: '175px', height: '25px', selectionMode: 'range' });
            this.isAddSelectedDialog = false
        },
        methods: {
            showAddSelectedDialog() {
                this.isAddSelectedDialog = true;
                this.selectedProducts = this.$refs.ordersProductsDataTable.getSelection();
            },
            showAddSelectedAllProductsDialog() {
                this.isAddSelectedAllProductsDialog = true;
                this.selectedAllProducts = this.$refs.allProductsDataTable.getSelection();
                console.log(this.selectedAllProducts)
            },
            runAddSelectedAllProduct() {
                if (this.selectedAllProducts.length) {
                    this.addSelectedAllProductRunning = true
                    let data = this.selectedAllProducts.map( (currentValue) => {
                        console.log(currentValue)
                        return {
                            'id' : currentValue.products_checks_id,
                            'products_check' : this.currentCheckId
                        }
                    } )
                    this.$refs.ProductsCheckDataTable.disabled = true
                    this.$refs.allProductsDataTable.disabled = true
                    axios.post('/api/massupdate/products_checks', data)
                        .then(() => {
                            this.$refs.allProductsDataTable.clearSelection();
                            this.refreshProductsCheckDataTable()
                            this.refreshAllProductsDataTable()
                            this.isAddSelectedAllProductsDialog = false
                            this.selectedAllProducts = [];
                        })
                    .finally(() => {
                        this.addSelectedAllProductRunning = false
                        this.$refs.ProductsCheckDataTable.disabled = false
                        this.$refs.allProductsDataTable.disabled = false
                    });
                }
            },
            runAddSelectedProduct() {
                if (this.selectedProducts.length) {
                    this.addSelectedProductRunning = true
                    let data = this.selectedProducts.map( (currentValue) => {
                        console.log(currentValue)
                        return {
                            'id' : currentValue.products_checks_id,
                            'products_check' : this.currentCheckId
                        }
                    } )
                    this.$refs.ProductsCheckDataTable.disabled = true
                    this.$refs.ordersProductsDataTable.disabled = true
                    axios.post('/api/massupdate/products_checks', data)
                        .then(() => {
                            this.$refs.ordersProductsDataTable.clearSelection();
                            this.refreshProductsCheckDataTable()
                            this.refreshOrdersProductsDataTable()
                            this.isAddSelectedDialog = false
                            this.selectedProducts = [];
                        })
                    .finally(() => {
                        this.addSelectedProductRunning = false
                        this.$refs.ProductsCheckDataTable.disabled = false
                        this.$refs.ordersProductsDataTable.disabled = false
                    });
                }
            },
            changeFilerByDataOrdersProductsDataTable() {
                this.dataFilter = this.$refs.filerByDataOrdersProductsDataTable.getRange()
                this.getOrdersProductsDataAdapter()
                this.$refs.ordersProductsDataTable.source = this.ordersProductsDataAdapter;
                this.$refs.ordersProductsDataTable.showAggregates = true;
                this.$refs.ordersProductsDataTable.refresh()
            },
            openOrderDialog(orderId) {
                EventBus.$emit('openOrderDialog', orderId);
            },
            openProductDialog(item) {
                EventBus.$emit('openProductDialog', item);
            },
            aggregatesCheckPriceRenderer(aggregates) {
                let renderString = '<div class="font-weight-bold" style="margin-top: 4px; margin-right: 4px; float: right;  height: 100%;">';
                if (!_.isEmpty(aggregates)) {
                    renderString += aggregates.total+' '+this.currentCheck.currency
                    this.updateCheckTotal(parseFloat(aggregates.total))

                }
                renderString += '</div>';
                return renderString;
            },
            aggregatesDutyPriceRenderer(aggregates) {
                let renderString = '<div style="margin-top: 4px; margin-right: 4px; float: right;  height: 100%;">';
                if (!_.isEmpty(aggregates)) {
                    renderString += aggregates.total+' '+this.currentCheck.duty_currency
                    //this.updateCheckTotal(parseFloat(aggregates.total))
                }
                renderString += '</div>';
                return renderString;
            },
            updateCheckTotal(total) {
                if (this.currentCheckIndex !== null && this.currentCheck && this.currentCheckId !== null) {
                    if (this.currentCheck.total !== total) {
                        this.currentCheck.total = total
                        this.$refs.ChecksDataTable.updateRow(this.currentCheckIndex,this.currentCheck);
                    }
                }
            },
            init(){
                axios.get(`/api/checks`)
                    .then(response => {
                        console.log(response.data)
                        this.checks = response.data.data
                    });
            },
            onChecksTabsSelecting(event) {
                if (event.args.item === 1) {
                    this.getOrdersProductsDataAdapter()
                }
                if (event.args.item === 2) {
                    this.getAllProductsDataAdapter()
                }
            },
            onChecksTabsSelected(event) {
                this.isChecksTabSelected = (event.args.item === 0)
                this.tabSelectedIndex = event.args.item
                if (this.isChecksTabSelected) {
                    this.panels[0].size = '40%'
                } else {
                    this.panels[0].size = '50%'
                }
                this.$refs.mainSplitter.refresh();
            },
            onRowClickChecks(event) {
                if (event.args.row.check_id !== this.currentCheckId) {
                    this.isNewCheck = false
                    this.currentCheckIndex = event.args.index
                    this.currentCheckId = event.args.row.check_id
                    this.currentCheck = _.cloneDeep(event.args.row)

                    if (this.$refs.ProductsCheckDataTable) {
                        this.getProductsCheckDataAdapter()
                        this.$refs.ProductsCheckDataTable.source = this.productsCheckDataAdapter;
                    } else {
                        this.getProductsCheckDataAdapter()
                    }
                    this.$refs.ChecksTabs.enableAt(1);
                    this.$refs.ChecksTabs.enableAt(2);
                }
                if (event.args.dataField === "check_id") {
                    this.printCheck()
                }
            },
            printCheck() {
                if (this.currentCheckId) {
                   //window.open("/check/"+this.currentCheckId+"?lang=cz", '_blank')
                    window.open("/stock-list/"+this.currentCheckId+"?lang=cz", '_blank')
                }
            },
            updateCheck(rowId, rowData, commit) {
                this.$refs.ChecksDataTable.disabled = true
                this.isCheckUpdating = true;
                axios.put(`/api/checks/${rowData.check_id}`, rowData)
                    .then((response) => {
                        this.$refs.ChecksDataTable.disabled = false
                        this.isCheckUpdating = false
                        if (this.isCheckCurrencyChanged) {
                            this.refreshProductsCheckDataTable()
                            this.isCheckCurrencyChanged = false
                        }
                        commit(true);
                    }).catch(() => {
                    this.isCheckUpdating = false
                    commit(false);
                })
            },
            addNewCheck() {
                this.isNewCheck = true
                this.currentCheckId = null
                this.currentCheck = this.newCheck
            },
            refreshCheckDataTable() {
                this.$refs.ChecksDataTable.updateBoundData()
            },
            refreshOrdersProductsDataTable() {
                this.$refs.ordersProductsDataTable.updateBoundData()
            },
            refreshAllProductsDataTable() {
                this.$refs.allProductsDataTable.updateBoundData()
            },
            refreshProductsCheckDataTable() {
                this.$refs.ProductsCheckDataTable.updateBoundData()
            },
            onRowClickProductsCheck(event) {
                if (event.args.dataField === "products_id") {
                    this.openProductDialog({
                        products_id: event.args.row.products_id,
                        descriptions: {products_name: event.args.row.products_name}
                    })
                } else if (event.args.dataField === "id") {
                    this.deleteProductsCheck(event.args.row)
                } else return true;
            },
            onCellEndEdit(event) {
                if ((event.args.dataField === "c_purchase_price" || event.args.dataField === "duty_price") && event.args.index !== -1 && event.args.value !== null) {
                    this.saveProductsCheck(event.args.row)
                }
            },
            saveProductsCheck(productCheck) {
                //this.$refs.ProductsCheckDataTable.disabled = true
                axios.put(`/api/products_checks/${productCheck.id}`, productCheck)
                    .then((response) => {
                        //this.$refs.ProductsCheckDataTable.disabled = false
                    })
            },
            deleteProductsCheck(productCheck) {
                console.log(productCheck)
                this.$refs.ProductsCheckDataTable.disabled = true
                if (this.$refs.ordersProductsDataTable) this.$refs.ordersProductsDataTable.disabled = true
                if (this.$refs.allProductsDataTable) this.$refs.allProductsDataTable.disabled = true
                axios.put(`/api/products_checks/${productCheck.id}`, {
                    products_check: 0
                })
                    .then((response) => {
                        this.$refs.ProductsCheckDataTable.disabled = false
                        this.refreshProductsCheckDataTable()
                        if (this.$refs.ordersProductsDataTable) {
                            this.$refs.ordersProductsDataTable.disabled = false
                            this.refreshOrdersProductsDataTable()
                        }
                        if (this.$refs.allProductsDataTable) {
                            this.$refs.allProductsDataTable.disabled = false
                            this.refreshAllProductsDataTable()
                        }
                    })
            },
            getProductsCheckDataAdapter(){
                if (this.currentCheckId) {
                    let productsCheckSource = {
                        pagesize: 100,
                        id: 'id',
                        url: `/api/products_checks/check_id/${this.currentCheckId}?with[]=descriptions&disablepaginate=1`,
                        root: 'data',
                        datatype: "json",
                        sortcolumn: 'products_id',
                        sortdirection: 'desc',
                        totalrecords: 0,
                        //async: false,
                        datafields: [
                            { name: 'id', type: 'int' },
                            { name: 'products_id', type: 'int' },
                            { name: 'products_check', type: 'int' },
                            { name: 'c_currency', type: 'string' },
                            { name: 'c_purchase_price', type: 'float' },
                            { name: 'duty_currency', type: 'string' },
                            { name: 'duty_price', type: 'float' },
                            { name: 'currencies_code', type: 'string' },
                            { name: 'products_price', type: 'float' },
                            { name: 'products_quantity', type: 'int' },
                            { name: 'products_name', type: 'string', map: 'descriptions>products_name' }
                        ],
                        beforeprocessing: function (data) {
                            productsCheckSource.totalrecords = data.total;
                        },
                    };
                    this.productsCheckDataAdapter = new jqx.dataAdapter(productsCheckSource ,
                        {
                            formatData: function (data) {
                                data.page = data.pagenum+1;
                                return data;
                            }
                        })
                } else this.productsCheckDataAdapter = null
            },
            getOrdersProductsDataAdapter() {
                let url = {}
                let i = 1
                /*url[`filterGroups[${i}][field]`]='product_check';
                url[`filterGroups[${i}][filters][0][value]`]='';
                url[`filterGroups[${i}][filters][0][condition]`]='NOTEMPTY';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';

                i = 1*/
                /*url[`filterGroups[${i}][field]`]='product_check.check';
                url[`filterGroups[${i}][filters][0][value]`]='';
                url[`filterGroups[${i}][filters][0][condition]`]='EMPTY';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';*/
                url[`filterGroups[${i}][field]`]='product_check.products_check';
                url[`filterGroups[${i}][filters][0][value]`]='0';
                url[`filterGroups[${i}][filters][0][condition]`]='EQUAL';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';

                if (this.orderFilter) {
                    i = 2
                    url[`filterGroups[${i}][field]`]='order.orders_status';
                    url[`filterGroups[${i}][filters][0][value]`]='2,3,6,8';
                    url[`filterGroups[${i}][filters][0][condition]`]='IN';
                    url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                    url[`filterGroups[${i}][filters][0][operator]`]='and';
                    url[`filterGroups[${i}][operator]`]='and';
                }

                if (this.dataFilter) {
                    this.pageable = false
                    i = 3
                    url[`filterGroups[${i}][field]`]='order.date_paid';
                    url[`filterGroups[${i}][filters][0][value]`]=this.$moment(this.dataFilter.from).format('YYYY-MM-DD HH:mm:ss');
                    url[`filterGroups[${i}][filters][0][condition]`]='GREATER_THAN_OR_EQUAL';
                    url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                    url[`filterGroups[${i}][filters][0][operator]`]='and';
                    url[`filterGroups[${i}][filters][1][value]`]=this.$moment(this.dataFilter.to).format('YYYY-MM-DD HH:mm:ss');
                    url[`filterGroups[${i}][filters][1][condition]`]='LESS_THAN_OR_EQUAL';
                    url[`filterGroups[${i}][filters][1][type]`]='stringfilter';
                    url[`filterGroups[${i}][filters][1][operator]`]='and';
                    url[`filterGroups[${i}][operator]`]='and';
                    this.$refs.ordersProductsDataTable.pageable = false
                } else {
                    this.pageable = true
                }

                i = 4
                url[`filterGroups[${i}][field]`]='order.site';
                url[`filterGroups[${i}][filters][0][value]`]='leica-post';
                url[`filterGroups[${i}][filters][0][condition]`]='CONTAINS';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';

                let searchParams = new URLSearchParams(url).toString();

                const ordersProductsSource = {
                    pagesize: 25,
                    id: 'orders_products_id',
                    url: `/api/orders_products?with[]=order.orders_status_name&with[]=product_check.check&without[]=duty_date&without[]=order.duty_date&${searchParams}&pageable=${this.pageable*1}`,
                    root: 'data',
                    datatype: "json",
                    sortcolumn: 'order.date_paid',
                    sortdirection: 'desc',
                    totalrecords: 0,
                    //async: false,
                    datafields: [
                        { name: 'orders_products_id', type: 'int' },
                        { name: 'products_id', type: 'int' },
                        { name: 'orders_id', type: 'int' },
                        { name: 'products_quantity', type: 'int' },
                        { name: 'products_name', type: 'string' },
                        { name: 'final_price', type: 'float' },
                        { name: 'currency', type: 'string', map: 'order>currency' },
                        { name: 'status', type: 'string', map: 'order>orders_status_name>orders_status_name' },
                        { name: 'order.date_purchased', type: 'date', map: 'order>date_purchased' },
                        { name: 'order.date_paid', type: 'date', map: 'order>date_paid' },
                        { name: 'check_id', type: 'int', map: 'product_check>products_check' },
                        { name: 'products_checks_id', type: 'int', map: 'product_check>0>id' },
                        { name: 'site', type: 'string', map: 'order>site' },
                    ],
                    beforeprocessing: function (data) {
                        this.totalrecords = data.total;
                    },
                    deleterow: function (rowid, commit) {
                        commit(true);
                    }
                };

                this.ordersProductsDataAdapter =  new jqx.dataAdapter(ordersProductsSource ,
                    {
                        //autoBind: false,
                        formatData: function (data) {
                            data.page = data.pagenum+1;
                            return data;
                        }
                    })
            },
            getAllProductsDataAdapter() {
                let url = {}
                let i = 1
                url[`filterGroups[${i}][field]`]='products_check';
                url[`filterGroups[${i}][filters][0][value]`]='0';
                url[`filterGroups[${i}][filters][0][condition]`]='EQUAL';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';

                i = 2
                url[`filterGroups[${i}][field]`]='products_id';
                url[`filterGroups[${i}][filters][0][value]`]='0';
                url[`filterGroups[${i}][filters][0][condition]`]='NOT_EQUAL';
                url[`filterGroups[${i}][filters][0][type]`]='stringfilter';
                url[`filterGroups[${i}][filters][0][operator]`]='and';
                url[`filterGroups[${i}][operator]`]='and';


                const searchParams = new URLSearchParams(url).toString();

                const allProductsSource = {
                    pagesize: 25,
                    id: 'products_checks_id',
                    url: `/api/products_checks?with[]=descriptions&without[]=check&${searchParams}`,
                    root: 'data',
                    datatype: "json",
                    sortcolumn: 'products_id',
                    sortdirection: 'desc',
                    totalrecords: 0,
                    //async: false,
                    datafields: [
                        { name: 'products_id', type: 'int' },
                        { name: 'products_quantity', type: 'int' },
                        { name: 'products_name', type: 'string', map: 'descriptions>products_name' },
                        { name: 'products_checks_id', type: 'int', map: 'id' }
                    ],
                    beforeprocessing: function (data) {
                        this.totalrecords = data.total;
                    },
                    deleterow: function (rowid, commit) {
                        commit(true);
                    }
                };

                this.allProductsDataAdapter =  new jqx.dataAdapter(allProductsSource ,
                    {
                        //autoBind: false,
                        formatData: function (data) {
                            data.page = data.pagenum+1;
                            return data;
                        }
                    })
            },
            onRowClickOrdersProducts(event) {
                if (event.args.dataField === "orders_products_id") {
                    this.currentOrdersProductsIndex = event.args.index
                    this.addProductToCheck(event.args.row)
                } else if (event.args.dataField === "products_id" ) {
                    this.openProductDialog({
                        products_id: event.args.row.products_id,
                        descriptions: {products_name: event.args.row.products_name}
                    })
                } else if (event.args.dataField === "orders_id" ) {
                    this.openOrderDialog(event.args.row.orders_id)
                } else return true;
            },
            onRowClickAllProducts(event) {
                if (event.args.dataField === "products_checks_id") {
                    console.log(event.args)

                    this.currentAllProductsIndex = event.args.index
                    this.addAllProductToCheck(event.args.row)
                } else if (event.args.dataField === "products_id" ) {
                    this.openProductDialog({
                        products_id: event.args.row.products_id,
                        descriptions: {products_name: event.args.row.products_name}
                    })
                } else return true;
            },
            renderOrdersProductsToolbar(toolbar) {
                //const container = '<div id="dateTimeInput" ref="myDateTimeInput"></div>';
                //toolbar.append(container);
                //toolbar.append('<div id="myCheckBox" style="z-index: 999; margin: 5px; margin-left: 30px; margin-top: 8px; margin-bottom: 3px;"></div>');

                /*this.dateTimeInput = jqwidgets.createInstance('#dateTimeInput', 'JqxDateTimeInput', {
                        width: "250",
                        height: "25",
                        selectionMode: 'range'
                    });*/
                //this.dateTimeInput.addEventHandler('change', this.changeDateTimeInput);

                //this.myCheckBox = jqwidgets.createInstance('#myCheckBox', 'JqxCheckBox', {});
                //this.myCheckBox.addEventHandler('change', this.changeOrderFilter);
            },
            addAllProductToCheck(product) {
                this.$refs.ProductsCheckDataTable.disabled = true
                this.$refs.allProductsDataTable.disabled = true
                axios.put(`/api/products_checks/${product.products_checks_id}`, {
                    products_check: this.currentCheckId,
                })
                    .then((response) => {
                        this.$refs.ProductsCheckDataTable.disabled = false
                        this.$refs.allProductsDataTable.disabled = false
                        this.refreshAllProductsDataTable()
                        this.$refs.allProductsDataTable.deleteRow(this.currentAllProductsIndex)
                        this.$refs.allProductsDataTable.refresh()
                    })
            },
            addProductToCheck(product) {
                this.$refs.ProductsCheckDataTable.disabled = true
                this.$refs.ordersProductsDataTable.disabled = true
                axios.put(`/api/products_checks/${product.products_checks_id}`, {
                    products_check: this.currentCheckId,
                })
                    .then((response) => {
                        this.$refs.ProductsCheckDataTable.disabled = false
                        this.$refs.ordersProductsDataTable.disabled = false
                        this.refreshProductsCheckDataTable()
                        this.$refs.ordersProductsDataTable.deleteRow(this.currentOrdersProductsIndex)
                        this.$refs.ordersProductsDataTable.refresh()
                    })
            },
            saveCheck() {
                if (this.$refs.formCheck.validate() && this.currentCheckId !== null) {
                    this.$refs.ChecksDataTable.updateRow(this.currentCheckIndex,this.currentCheck);
                }
            },
            addCheck() {
                this.$refs.ChecksDataTable.disabled = true
                this.isCheckUpdating = true;
                this.currentCheckId = null
                axios.post(`/api/checks`, this.currentCheck)
                    .then((response) => {
                        this.$refs.ChecksDataTable.disabled = false
                        this.isCheckUpdating = false
                        this.isNewCheck = false
                        this.currentCheck = null
                        this.$refs.ChecksDataTable.updateBoundData()
                    })
            }
        },
        components: {
            JqxSplitter,
            JqxDataTable,
            JqxTabs,
            Datepicker,
            JqxDateTimeInput,
            JqxCheckBox
        }
    }
</script>

<style scoped>

</style>

<style>
    #Checks .jqx-tabs-bar {
        display: none !important;
    }

    .checks-form-buttons {
        top: 4px;
        right: 0;
    }

    #Checks .pointer:hover {
        cursor: pointer;
    }

    #Checks .underline {
        text-decoration: underline;
    }

</style>