<template>
    <v-row class="ma-2 caption">
        <v-col cols="12" class="d-flex align-left pa-0">
            <p class="mr-1 mb-0 align-left" style="text-align: left !important;"><strong>{{item.delivery_name.slice(0, 25) + (item.delivery_name.length > 25 ? "..." : "")}}</strong> - {{ item.customers_email_address.slice(0, 25) + (item.customers_email_address.length > 25 ? "..." : "") }}</p>
            <!-- <p class="eclipse mb-0 mr-1">{{ item.customers_email_address}}</p>
            <v-icon color="success" size="medium">info</v-icon> -->
        </v-col>
        <v-col cols="12" class="d-flex aling-left mb-1 pa-0 text-left">
            <p v-if="item.PayPalTransactionID" class="mr-2 caption align-left">
                <span class="font-weight-medium">PayPalId:</span> {{ item.PayPalTransactionID }}
            </p>
            <p v-if="item.order_products[0] && item.order_products[0].ShipmentTrackingNumber" class="mr-1 mb-0 align-left">
                <span class="font-weight-medium">TrackingNumber: </span> {{ item.order_products[0].ShipmentTrackingNumber }}
            </p>
            <span v-if="item.OrderID && item.OrderID !== ''" class="mr-1 mb-0 align-left">
                <span class="font-weight-medium">eBayID: </span>
                {{ item.OrderID }}
                <span v-if="!hideEbayFee">
                    <span
                        v-if="item.order_ebayfees"
                        class="font-italic"
                    >
                        Fee: <span v-html="item.order_ebayfees.text"></span>
                    </span>
                    <span
                        v-if="item.order_ebaytotal"
                        class="font-italic"
                    >
                        Total: <span v-html="item.order_ebaytotal.text"></span>
                    </span>
                </span>
            </span>
        </v-col>
        <v-col cols="12" v-if="item.order_products.length" class="mt-1 pa-0">
            <v-row v-for="product in item.order_products" v-bind:key="product.orders_products_id" style="color: #666666;">
                <v-col cols="12" class="text-left py-0 px-2">
                    (<a class="primary--text"
                           style="cursor: pointer; color: black !important; text-decoration: underline;"
                           @click="openProductDialog(product)">
                    {{ product.products_id }}</a>)
                    <span class="eclipse text-left ma-0 font-italic font-weight-medium" style="color:black !important;">{{ product.products_name }}</span>
                    <warehouse-info v-if="!hideStockData" :product="product.product" />
                </v-col>
            </v-row>

        </v-col>
    </v-row>

</template>

<script>

    import WarehouseInfo from "@/components/WarehouseInfo";

    export default {
        name: "OrderInfo",
        props: {
            item: {
                required: true
            },
            hideStockData: {
                required: false
            },
            hideEbayFee: {
                required: false
            }
        },
        data:() => ({
            customProductDialog: false,
        }),
        methods: {
            openProductDialog(product){
                this.$emit('openProductDialog', product);
            }
        },
        components:{
            WarehouseInfo
        }
    }
</script>

<style scoped>

    p.eclipse{
        max-width: 70%;
    }

</style>