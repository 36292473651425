<template>
<!--    <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-space-between text-center ma-0 mt-2">
            <v-spacer></v-spacer>
            <div>-->
                <span class="font-weight-medium green--text" v-html="productAge"></span>
<!--            </div>
            <v-spacer></v-spacer>
        </v-col>
    </v-row>-->
</template>

<script>
    export default {
        name: "AgeComponent",
        props: {
            item: {
                type: Object,
                required: true,
            }
        },
        computed:{
            productAge(){
                let fromTime = this.$moment(this.item.products_date_available).diff(this.$moment(), "milliseconds")

                let duration = this.$moment.duration(fromTime)
                let timeString=''
                timeString += duration.years() ? duration.years() / -1 + "y<br/>" : ''
                timeString +=  duration.months() ? duration.months() / -1 + "m<br/>" : ''
                timeString +=  duration.days() ? duration.days() / -1 + "d" : ''

                return timeString

            }
        },
    }
</script>

<style scoped>

</style>