<template>
    <v-card>
        <v-card-text class="pt-4">
            <v-row no-gutters>
                <v-col cols="4" v-for="order in orders" v-bind:key="order.orders_id">
                    <v-checkbox
                        dense
                        type="checkbox"
                        id="celniorders"
                        v-model="selected"
                        :value="order.orders_id"
                        :input-value="order.orders_id"
                        :label="''+order.orders_id"
                        :true-value="order.orders_id"
                        hide-details
                        class="shrink mt-0"
                        multiple
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-select
                        v-model="selectedProductType"
                        :items="productTypes"
                        label="Select type of product"
                        persistent-hint
                        single-line
                        hide-details
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer/>
                <v-btn
                    :disabled="selected.length === 0"
                    @click="getCelnicaFile"
                >Submit</v-btn>
            <v-spacer/>
        </v-card-actions>
    </v-card>
    
</template>

<script>

    export default {
        name: "Celnica",
        props: {
            orders: {
                required: true
            }
        },
        data: () => ({
            loading: false,
            productTypes: [
                { text: 'Lens', value: 'lens' },
                { text: 'Digital camera', value: 'digital_camera' },
                { text: 'Film camera', value: 'film_camera' },
                { text: 'Parts and accessories', value: 'parts' },
                { text: 'Binoculars', value: 'binoculars' },
            ],
            selected: [],
            selectedProductType: 'lens'
        }),
        mounted(){
        },
        methods: {
            getCelnicaFile() {
                axios.post(
                    '/celnica', { orders: this.selected, product_type: this.selectedProductType},
                    {responseType: 'blob'}
                ).then(response => {
                    const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/^"(.*)"$/, '$1');
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();// you need to remove that elelment which is created before.
                    this.selected=[]
                })
            }
        }
    }
</script>