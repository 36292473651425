var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pa-2", staticStyle: { height: "100% !important" } },
    [
      _c(
        "v-tabs",
        {
          staticClass: "elevation-1 scroll-tabs order-tabs",
          attrs: { "show-arrows": "", "hide-slider": "", grow: "" },
          model: {
            value: _vm.orderTabActive,
            callback: function($$v) {
              _vm.orderTabActive = $$v
            },
            expression: "orderTabActive"
          }
        },
        [
          _vm._l(_vm.orderTabs, function(orderTab, i) {
            return _c(
              "v-tab",
              {
                key: orderTab.key,
                attrs: {
                  href: "#tab-" + orderTab.key,
                  ripple: "",
                  disabled: _vm.disabledTab(orderTab.name)
                },
                on: {
                  change: function($event) {
                    return _vm.changeTab(orderTab.key)
                  }
                }
              },
              [_vm._v("\n            " + _vm._s(orderTab.name) + "\n        ")]
            )
          }),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              staticClass: "elevation-1",
              attrs: { touchless: "", id: "order-tab-items" },
              model: {
                value: _vm.orderTabActive,
                callback: function($$v) {
                  _vm.orderTabActive = $$v
                },
                expression: "orderTabActive"
              }
            },
            _vm._l(_vm.orderTabs, function(orderTab, i) {
              return _c(
                "v-tab-item",
                {
                  key: orderTab.key,
                  staticClass: "text-center",
                  attrs: { value: "tab-" + orderTab.key }
                },
                [
                  _c("v-progress-circular", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadingTab,
                        expression: "loadingTab"
                      }
                    ],
                    staticClass: "ma-5",
                    attrs: {
                      size: 70,
                      width: 7,
                      color: "green",
                      indeterminate: ""
                    }
                  }),
                  _vm._v(" "),
                  _vm.OrderLoaded
                    ? _c(orderTab.component, {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loadingTab,
                            expression: "!loadingTab"
                          }
                        ],
                        tag: "component",
                        class: orderTab.key,
                        attrs: {
                          isActive: _vm.orderTabActive,
                          id: _vm.id ? _vm.id : _vm.$route.params.id,
                          fullscreen: _vm.fullscreen
                        },
                        on: {
                          componentReady: function($event) {
                            _vm.loadingTab = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }