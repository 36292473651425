<template>
    <v-form class="pa-0" style="height: 100%;">

        <v-card v-if="awaitNewProduct && productid == null" outlined :rounded="false" flat id="new-product-dialog-card" style="border: none; display: flex !important; flex-direction: column;" class="rounded-0">
            <v-card-text style="flex-grow: 1; overflow: auto; height:550px;" class="rounded-0">
                <v-row no-gutters style="height:335px;">
                    <v-col cols="2">
                        <v-subheader class="font-weight-bold">
                            Place:
                        </v-subheader>
                    </v-col>
                    <v-col cols="10" class="d-flex justify-space-between align-start ma-0">
                        <tree-select
                            :clearable="false"
                            name="place"
                            :load-options="loadOptions"
                            :options="productsOptionsCategories"
                            :auto-load-root-options="true"
                            :searchable="true"
                            :normalizer="normalizer"
                            v-model="placeId"
                            :always-open="true"
                            class="pl-0"
                        />
                    </v-col>
                </v-row>
                <v-divider class="my-2" style="width: 100%;"/>
                <v-row no-gutters>
                    <v-col cols="2">
                        <v-subheader class="font-weight-bold">
                            Template:
                        </v-subheader>
                    </v-col>
                    <v-col cols="10">
                        <v-select
                            dense
                            v-model="products_template"
                            :items="products_templates"
                            item-text="products_name"
                            item-value="products_id"
                            label=""
                            hide-details
                            single-line
                            @change="selectTemplate"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="12" class="text-center text-h6 ma-0 pa-0 font-weight-bold">
                        OR
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="2">
                        <v-subheader class="font-weight-bold">
                            Copy:
                        </v-subheader>
                    </v-col>
                    <v-col cols="10">
                        <search-product
                            :clearSearch="clearSearch"
                            @add-product="searchProduct"
                        ></search-product>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="my-0" style="width: 100%;"/>
            <v-card-actions class="text-right">
                <v-spacer/>
                <v-btn
                    small
                    color="primary"
                    class="body-1 ml-2"
                    @click="createNewProduct"
                    :disabled="product_for_copy == null"
                >Add</v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="!awaitNewProduct" outlined  :rounded="false" flat id="new-product-dialog-card" style="border: none; display: flex !important; flex-direction: column;" class="rounded-0">
            <v-card-text style="flex-grow: 1; overflow: auto;" class="rounded-0">
                <v-row no-gutters>
                        <v-col cols="12" class="mt-0 pb-0 pt-0">
                            <v-text-field
                                v-model="form.descriptions.products_name"
                                dense
                                counter="80"
                                :rules="rules.max80chars"
                                prepend-icon="mdi-google-lens"
                                @change="saveProductInfo"
                            >
                                <template v-slot:append-outer>
                                    <v-icon
                                        @click.stop="openProductDialog"
                                        class="mr-1"
                                        small
                                    >
                                        mdi-transit-connection-variant
                                    </v-icon>
                                    <v-icon
                                        @click.stop="printProductBarcode(product)"
                                        small
                                    >
                                        mdi-barcode-scan
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" lg="6" class="pt-0">
                            <v-form ref="formProductMainForm">
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex  align-center">
                                    <span style="min-width:70px;">Category:</span>
                                    <tree-select
                                        :rules="rules.required"
                                        class="px-2"
                                        name="category"
                                        :clearable="false"
                                        :load-options="loadOptions"
                                        :options="productsOptionsCategories"
                                        :auto-load-root-options="true"
                                        :searchable="true"
                                        :normalizer="normalizer"
                                        v-model="form.category.categories_id"
                                        @select="saveProductInfo"
                                        :required="true"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="d-flex  align-center">
                                    <span style="min-width:70px;">Target:</span>
                                    <tree-select
                                        :rules="rules.required"
                                        class="px-2"
                                        name="target"
                                        :clearable="false"
                                        :load-options="loadOptions"
                                        :options="productsOptionsCategoriesTarget"
                                        :auto-load-root-options="true"
                                        :searchable="true"
                                        :normalizer="normalizer"
                                        v-model="form.targetcatid"
                                        @select="saveProductInfo"
                                        :required="true"
                                    />
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"/>
                            <v-row no-gutters class="pr-1">
                                <v-col cols="12" sm="6"  class="d-flex align-baseline my-0 py-0 pr-1">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder; max-width:80px; min-width:80px;">Weight:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center" style="width:50%">
                                        <v-select
                                            dense hide-details single-line
                                            :rules="rules.required"
                                            :cache-items=true
                                            v-model="weight"
                                            :items="productWeightsList"
                                            :menu-props="{ auto: true, offsetY: true }"
                                            class="font-weight-regular pr-1"
                                            item-text="products_options_values_name"
                                            item-value="products_options_values_id"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6"  class="d-flex align-baseline my-0 py-0">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder; max-width:80px; min-width:80px;">Quantity:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            v-model="form.products_quantity"
                                            dense
                                            hide-details
                                            reverse
                                            @change="changeProductQuantity"
                                            disabled
                                            type="number"
                                            style="max-width:80px; min-width:80px;"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pr-1 mt-1">
                                <v-col cols="12" sm="6" class="d-flex align-center my-0 py-0 pr-1">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder; max-width:80px; min-width:80px;">Condition:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center" style="width:50%">
                                        <v-select
                                            dense hide-details single-line
                                            :rules="rules.required"
                                            :cache-items=true
                                            v-model="condition"
                                            :items="productConditionsList"
                                            :menu-props="{ auto: true, offsetY: true }"
                                            class="font-weight-regular currency-select pr-1"
                                            item-text="products_options_values_name"
                                            item-value="products_options_values_id"
                                        >
                                        </v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6" class="d-flex align-center my-0 py-0">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder; max-width:80px; min-width:80px;">Purchase:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            :rules="rules.required"
                                            v-model="form.purchase_price.products_price"
                                            dense
                                            hide-details
                                            reverse
                                            @change="saveProductInfo"
                                            class="font-weight-regular currency-select pl-1 ma-0 pa-0"
                                            step="0.01"
                                            type="number"
                                        />
                                        <v-select
                                            dense hide-details single-line
                                            :rules="rules.required"
                                            :cache-items=true
                                            v-model="form.purchase_price.currencies_code"
                                            :items="currenciesList"
                                            :menu-props="{ auto: true, offsetY: true }"
                                            class="font-weight-regular currency-select pl-1 ma-0 pa-0"
                                            @change="saveProductInfo"
                                            style="max-width:80px; min-width:80px;"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pr-1 mt-0">
                                <v-col cols="12" sm="6" class="d-flex align-baseline my-0 py-0 pr-2">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder;  max-width:80px; min-width:80px;">Discount:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center" style="width:50%">
                                        <v-select
                                            dense hide-details single-line
                                            :cache-items=true
                                            v-model="form.discount.persent"
                                            :items="productDiscountList"
                                            :menu-props="{ auto: true, offsetY: true }"
                                            class="font-weight-regular currency-select pr-1"
                                            item-text="products_options_values_name"
                                            item-value="products_options_values_name"
                                            @change="saveProductInfo"
                                        />
                                        <v-checkbox :input-value="getProductsAttributesValueByName('autodisc')" @change="updateProductProperty('autodisc', $event)" hide-details dense true-value="548" false-value="547" class="ma-0"/>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6" class="d-flex align-baseline my-0 py-0">
                                    <p class="ma-0 pr-2 text-left" style="font-weight: bolder; max-width:80px; min-width:80px;">Price:</p>
                                    <v-spacer/>
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            v-model.number="productPrice"
                                            :rules="rules.required"
                                            dense
                                            hide-details
                                            reverse
                                            class="font-weight-regular currency-select pl-1 ma-0 pa-0"
                                            @change="saveProductInfo"
                                            step="0.01"
                                            type="number"
                                        />
                                        <v-select
                                            dense hide-details
                                            :rules="rules.required"
                                            :cache-items=true
                                            v-model="form.base_currency"
                                            :items="currenciesList"
                                            :menu-props="{ auto: true, offsetY: true }"
                                            class="font-weight-regular currency-select pl-1 ma-0 pa-0"
                                            :readonly="form.products_status == 1"
                                            style="max-width:80px; min-width:80px;"
                                            @change="saveProductInfo"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            </v-form>
                        </v-col>

                    <v-col cols="12" xs="12" lg="6">
                        <v-row no-gutters class="pr-1">
                                <v-col cols="12" class="d-flex align-center">
                                    <v-spacer/>
                                    <v-checkbox :input-value="getProductsAttributesValueByName('repair')" @change="updateProductProperty('repair', $event)" hide-details dense true-value="545" false-value="546" class="ma-0" label="Repair" style="white-space: nowrap;">
                                    </v-checkbox>

                                    <v-spacer/>
                                    <v-checkbox :input-value="getProductsAttributesValueByName('freeship')" @change="updateProductProperty('freeship', $event)" hide-details dense true-value="29" false-value="28" class="ma-0" label="Free Shipment" style="white-space: nowrap;">
                                    </v-checkbox>

                                    <v-spacer/>
                                    <v-checkbox :input-value="getProductsAttributesValueByName('bestoffer')" @change="updateProductProperty('bestoffer', $event)" hide-details dense true-value="533" false-value="534" class="ma-0" label="Best Offers" style="white-space: nowrap;">
                                    </v-checkbox>

                                    <v-spacer/>
                                    <v-checkbox :input-value="getProductsAttributesValueByName('colony')" @change="updateProductProperty('colony', $event)" hide-details dense true-value="600" false-value="601" class="ma-0" label="Colony" style="white-space: nowrap;">
                                    </v-checkbox>

                                    <v-spacer/>
                                    <v-checkbox :input-value="getProductsAttributesValueByName('CMCom')" @change="updateProductProperty('CMCom', $event)" hide-details dense true-value="596" false-value="597" class="ma-0" label="CM.com" style="white-space: nowrap;">
                                    </v-checkbox>

                                    <v-spacer/>
                                    <v-checkbox
                                        :input-value="getProductsAttributesValueByName('GTCCom')"
                                        @change="updateProductProperty('GTCCom', $event)"
                                        hide-details
                                        dense
                                        true-value="30"
                                        false-value="31"
                                        class="ma-0"
                                        label="GTC"
                                        style="white-space: nowrap;"
                                    />

                                    <v-spacer/>
                                </v-col>

                            </v-row>
                        <v-divider class="my-2"/>
                        <v-form ref="formEbayCategories">
                            <v-simple-table style="overflow: hidden">
                            <template v-slot:default>
                                <tbody>
                                <tr>
                                    <td style="width:60px; text-align: left;">
                                        &nbsp;&nbsp;Ebay.com
                                    </td>
                                    <td style="width:30px;">
                                        <!--<v-checkbox v-model="form.is_eBay" @change="saveProductInfo" hide-details dense class="ma-0" style="white-space: nowrap;"/> -->
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <v-select
                                                dense hide-details single-line
                                                :rules="rules.required"
                                                :cache-items=true
                                                v-model="form.ebay_categories[0].catid"
                                                :items="ebayCategoriesCom"
                                                item-text="name"
                                                item-value="catid"
                                                class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                style="max-width:95%;"
                                                @change="changeEbayCategories"
                                            >
                                            </v-select>
                                            <!--                                            <v-icon class="ml-3">edit</v-icon>-->
                                        </div>

                                    </td>
                                    <td style="width:0px;">
                                        &nbsp;
                                    </td>
                                </tr>

                                <tr>
                                    <td style="width:60px; text-align: left;">
                                        &nbsp;&nbsp;Ebay.de
                                    </td>
                                    <td style="width:30px;">
                                        <!-- <v-checkbox v-model="form.is_eBayDE" @change="saveProductInfo" hide-details dense class="ma-0" style="white-space: nowrap;"/> -->
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <v-select
                                                dense hide-details single-line
                                                :rules="rules.required"
                                                :cache-items=true
                                                v-model="form.ebay_categories[77].catid"
                                                :items="ebayCategoriesDe"
                                                item-text="name"
                                                item-value="catid"
                                                class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                style="max-width:95%;"
                                                @change="changeEbayCategories"
                                            />
                                            <!--                                            <v-icon class="ml-3">edit</v-icon>-->
                                        </div>

                                    </td>
                                    <td style="width:0px;">
                                        &nbsp;
                                        <!--                                        <v-checkbox
                                                                                    :input-value="getProductsAttributesValueByName('GTCDe')"
                                                                                    @change="updateProductProperty('GTCDe', $event)"
                                                                                    hide-details
                                                                                    dense
                                                                                    true-value="30"
                                                                                    false-value="31"
                                                                                    class="ma-0 ml-3"/>-->
                                    </td>
                                </tr>

                                <tr>
                                    <td style="width:60px; text-align: left;">
                                        &nbsp;&nbsp;Ebay.co.uk
                                    </td>
                                    <td style="width:30px;">
                                        &nbsp;
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <v-select
                                                dense hide-details single-line
                                                :rules="rules.required"
                                                :cache-items=true
                                                v-model="form.ebay_categories[3].catid"
                                                :items="ebayCategoriesCouk"
                                                item-text="name"
                                                item-value="catid"
                                                class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                style="max-width:95%;"
                                                @change="changeEbayCategories"
                                            />
                                        </div>

                                    </td>
                                    <td style="width:0px;">
                                        &nbsp;
                                    </td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                        </v-form>
                    </v-col>
                    <v-col cols="12" xs="12" lg="12">
                        <v-row no-gutters>
                            <v-col cols="12" v-if="isLiteVersion">
                                <v-tabs v-model="descTabActive" show-arrows grow class="elevation-1">
                                    <v-tab v-for="textTab in textTabs"
                                           :key="textTab.key"
                                           :href="'#tab-' + textTab.key"
                                           ripple
                                    >
                                        {{textTab.name}}
                                    </v-tab>

                                    <v-tabs-items class="elevation-1" touchless v-model="descTabActive">
                                        <v-tab-item value="tab-description" class="text-center  scroll-tab-item">
                                            <v-textarea
                                                outlined hide-details
                                                name="input-7-4"
                                                placeholder="Product description"
                                                v-model="form.descriptions.products_description"
                                                @change="saveProductInfo"
                                            />
                                        </v-tab-item>
                                        <v-tab-item value="tab-note" class="text-center  scroll-tab-item">
                                            <v-textarea
                                                outlined hide-details
                                                name="input-7-4"
                                                placeholder="Product note"
                                                v-model="form.product_note.products_note"
                                                @change="saveProductInfo"
                                            />
                                        </v-tab-item>

                                    </v-tabs-items>
                                </v-tabs>
                            </v-col>

                            <v-col cols="12" v-else>
                                <defects :productsId="productid" class="pa-2"></defects>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider class="my-0" style="width: 100%;"/>
            <v-card-actions>
                <v-checkbox
                    v-model="isLiteVersion"
                    label="Lite version"
                    dense
                    hide-details
                    class="mt-0 pt-0"
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="primary"
                    :loading="awaitSaveProduct"
                    @click="saveProductInfo"
                >Save</v-btn>
            </v-card-actions>
        </v-card>

    </v-form>
</template>

<script>

    import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
    import TreeSelect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect';
    import ProductDefects from "../../../components/ProductDefects";
    import ReusableProduct from "../../../store/product/ReusableProduct";
    import EventBus from "@/event-bus";
    import SearchProduct from '../../../components/SearchProduct'
    import { mapCacheActions } from 'vuex-cache';
    import { setup } from 'axios-cache-adapter'
    import Defects from "../Defects"

    // import CameraDefects from "./CameraDefects";
    // import LensDefects from "./LensDefects";

    const api = setup({
        // `axios-cache-adapter` options
        cache: {
            maxAge: 15 * 60 * 1000,
            exclude: { query: false }
        }
    })

    const formInit = Object.freeze({
        products_status: 0,
        descriptions:{
            products_name: null,
            products_description: null
        },
        product_note: {
            products_note: null
        },
        discount: {
            persent: null
        },
        category: {
            categories_id: null
        },
        targetcatid: null,
        base_currency: 'EUR',
        products_quantity: 1,
        ebay_categories: {
            '0': {
                catid: null,
                siteID: 0,
                name: null
            },
            '77': {
                catid: null,
                siteID: 77,
                name: null
            },
            '3': {
                catid: null,
                siteID: 3,
                name: null
            }
        },
        notedata: null,
        description: null,
        is_eBay: false,
        is_eBayDE: false,
        price: {
            'EUR': {
                'products_price': 0,
                'currency': 'EUR',
                'start': 0
            }
        },
        purchase_price: {
            'currencies_code': 'EUR',
            'products_price': 0,
            'products_quantity': 1,
            'id': null
        }
    });

    export default {
        name: "ProductForm",
        props: {
            productForEdit: {
                required: false,
                type: Object,
                default: null,
            },
            productid: {
                required: true
            }
        },
        data: () => ({
            form: Object.assign({}, formInit),
            productsOptionsCategories: null,
            normalizer(node) {
                if(node && node.descriptions){
                    return {
                        id: node.descriptions.categories_id,
                        label: (node.categories_shop === 1 ? '🌟 ' : '') + node.descriptions.categories_name,
                        children: node.children,
                    }
                }
            },
            placeId: 289,
            //ebayCategoriesCom:[],
            //ebayCategoriesDe: [],
            //ebayCategoriesCouk: [],
            textTabs:[
                { name: 'Description', order:1, key: 'description'},
                { name: 'Note', order:2, key: 'note'},
            ],
            descTabActive: 'tab-description',
            // defectsTabs:[
            //     { name: 'Camera', order:1, key: 'camera'},
            //     { name: 'Lens', order:2, key: 'lens'},
            // ],
            // defectsTabActive: 'tab-camera',
            allItemDefects: {},
            currentDefectTabIndex: 0,
            awaitNewProduct: true,
            awaitSaveProduct: false,
            isLiteVersion: false,
            products_template: null,
            products_templates: [
                {
                    products_id: 2,
                    products_name: "Main template"
                }
            ],
            product_for_copy: null,
            clearSearch: false,
            replicatedProduct: null,
            initialProduct: {},
            rules: {
                max80chars: [v => v.length <= 80 || 'Max 80 characters'],
                required: [
                    value => !!value || '',
                ],
                select: [ v => v.length > 0 || "Item is required in select"],
            },
        }),
        async mounted(){

            if(this.productForEdit){
                if(!this.productForEdit.ebay_categories && !this.productForEdit.ebay_categories.length){
                    this.productForEdit.ebay_categories = [
                        {name: null},
                        {name: null}
                    ]
                }
                this.form ={...this.form, ...this.productForEdit};
            }
            //await this.fetchEbayCategoriesCom();
            //await this.fetchEbayCategoriesDe();
            //await this.fetchEbayCategoriesCouk();
            //this.fetchDefects();
            await this.initProductOptions(/*{
                timeout: 6000
            }*/)
            if (this.productid !== null) {
                await this.createNewProduct()
            }

            this.$refs.formEbayCategories.validate()
            this.$refs.formProductMainForm.validate()
        },
        computed:{
            productsOptionsCategoriesTarget() {
                return _.filter(this.productsOptionsCategories, {'categories_target': 1});
            },
            productWeightsList() {
                if (this.productOptions) {
                    return this.productOptions[3].options_value
                } else return []
            },
            productConditionsList() {
                if (this.productOptions) return this.productOptions[2].options_value
                else return []
            },
            productDiscountList() {
                if (this.productOptions) {
                    return this.productOptions[26].options_value
                }
                else return []
            },
            ...mapGetters([
                'productOptions',
                'productCategories',
                'currenciesList',
                'ebayCategoriesCom',
                'ebayCategoriesDe',
                'ebayCategoriesCouk'
            ]),
            ...mapState({
                state (state) {
                    return state["products/product_"+this.productId]
                },
                productBestOffer (state, getters) {
                    return getters["products/product_"+this.productId + '/productBestOffer']
                },
                product (state, getters) {
                    return getters["products/product_"+this.productId + '/product']
                },
                product_ (state, getters) {
                    return getters["products/product_"+this.productId + '/product_']
                },
                productsAttributes (state, getters) {
                    return getters["products/product_"+this.productId + '/productsAttributes']
                }
            }),
            weight:{
                get(){
                    let attribute = this.product_('weight')
                    if (attribute) {
                        if (attribute.options_values_id === "") {
                            return null
                        } else {
                            return {
                                products_options_values_id: attribute.options_values_id
                            }
                        }
                    } else {
                        return null
                    }
                },
                set(val){
                    this.updateProductProperty('weight', val)
                }

            },
            condition:{
                get(){
                    let attribute = this.product_('condition')
                    if (attribute) {
                        if (attribute.options_values_id === "") {
                            return null
                        } else {
                            return {
                                products_options_values_id: attribute.options_values_id
                            }
                        }
                    } else {
                        return null
                    }
                },
                set(val){
                    this.updateProductProperty('condition', val)
                }
            },
            productPrice: {
                get(){
                    if (this.form.price[this.form.base_currency]) return this.form.price[this.form.base_currency].products_price
                    else return 0
                },
                set(val){
                    this.form.price[this.form.base_currency] = {}
                    this.form.price[this.form.base_currency].products_price = val
                    this.form.price[this.form.base_currency].currency = this.form.base_currency
                    this.form.price[this.form.base_currency].start = 0
                }
            }
        },
        watch: {
            currentTabIndex (tab) {
                console.log(tab);
            },
            isLiteVersion (newValue) {
                localStorage.setItem('add_lite_version', newValue)
            }
        },
        methods:{
            /*...mapActions([
                "initProductOptions"
            ]),*/
            ...mapCacheActions(['initProductOptions']),
            async loadOptions({action}){
                if (action === LOAD_ROOT_OPTIONS) {
                    this.productsOptionsCategories = this.productCategories;
                }

            },
            changeProductQuantity(val) {
                this.form.purchase_price.products_quantity = val
                this.saveProductInfo()
            },
            changeEbayCategories(val){
                if (_.find(this.ebayCategoriesCom, ['catid', val]) !== undefined) {
                    if (this.form.ebay_categories[0].catid == null) this.form.ebay_categories[0].catid = val
                }
                if (_.find(this.ebayCategoriesDe, ['catid', val]) !== undefined) {
                    if (this.form.ebay_categories[77].catid == null) this.form.ebay_categories[77].catid = val
                }
                if (_.find(this.ebayCategoriesCouk, ['catid', val]) !== undefined) {
                    if (this.form.ebay_categories[3].catid == null) this.form.ebay_categories[3].catid = val
                }
                this.saveProductInfo()
            },
            async fetchEbayCategoriesCom(){
                await api.get(`/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=0&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1`)
                    .then(response => {
                        const categories =  response.data;
                        this.ebayCategoriesCom = categories.map(category => {
                            let categoryName = category.name;
                            if (category.parent) {
                                categoryName = categoryName + ' | ' + this.getParentsCategoryName(category.parent)
                            }
                            let ebayCategories = []
                            ebayCategories['name'] = categoryName
                            ebayCategories['catid'] = category.catid
                            ebayCategories['siteID'] = category.siteID
                            return ebayCategories
                        });
                    })
            },
            async fetchEbayCategoriesDe(){
               await api.get('/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=77&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1')
                    .then(response => {
                        const categories =  response.data;
                        this.ebayCategoriesDe = categories.map(category => {
                           let categoryName = category.name;
                           if (category.parent) {
                                   categoryName = categoryName + ' | '  + this.getParentsCategoryName(category.parent)
                           }
                           let ebayCategories = []
                           ebayCategories['name'] = categoryName
                            ebayCategories['catid'] = category.catid
                            ebayCategories['siteID'] = category.siteID
                           return ebayCategories
                        });
                    })
            },
            async fetchEbayCategoriesCouk(){
                await api.get('/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=3&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1')
                    .then(response => {
                        const categories =  response.data;
                        this.ebayCategoriesCouk = categories.map(category => {
                           let categoryName = category.name;
                           if (category.parent) {
                                   categoryName = categoryName + ' | '  + this.getParentsCategoryName(category.parent)
                           }
                           let ebayCategories = []
                           ebayCategories['name'] = categoryName
                            ebayCategories['catid'] = category.catid
                            ebayCategories['siteID'] = category.siteID
                           return ebayCategories
                        });
                    })
            },
            fetchDefects(){
                axios.get('/api/parts?with[]=groups.defects')
                    .then(response => {
                        this.allItemDefects =  response.data;
                        // this.allItemDefects.camera =  response.data.find(element => element.title=== "Camera");
                        // this.allItemDefects.lens =  response.data.find(element => element.title=== "Lens");
                        console.log(this.allItemDefects)
                    })
            },
            getParentsCategoryName(category){
                let categoryName = (category.name ? category.name : '');
                if (category.parent) {
                    categoryName = categoryName + ' | ' + this.getParentsCategoryName(category.parent)
                }
                return categoryName;
            },
            getProductsAttributesValueByName(name) {
                let attribute = this.product_(name)
                return attribute ? attribute.options_values_id+'' : ''
            },
            getAttributeValueById(id){
                if (this.productForEdit && this.productForEdit.products_attributes[id]) {
                    return this.productForEdit.products_attributes[id].options_values_id.toString()
                } else return []
            },
            setAttributeValueById(id, value){
                if(this.form.products_attributes && this.form.products_attributes[id]){
                    this.form.products_attributes[id].options_values_id = value
                } else {
                    this.form.products_attributes={}
                    this.form.products_attributes[id] = [];
                    this.form.products_attributes[id].options_values_id = value
                }
                if (this.form.products_attributes[id].options_value) {
                    this.form.products_attributes[id].options_value[0].products_options_values_id = value
                } else {
                    this.form.products_attributes[id].options_value = [];
                    this.form.products_attributes[id].options_value.push({products_options_values_id: value})
                }
            },
            getCategory(arr, id) {
                for (let e of arr) {
                    if (e.categories_id === id) return e;
                    if(e.children && e.children.length){
                        let found = this.getCategory(e.children, id);
                        if (found) return found;
                    }
                }
            },
            async replicateProduct(products_id) {
                await axios.get("/api/replicate_product/"+products_id).then(response => {
                    if (response.data.products_id !== undefined) {
                        this.replicatedProduct = response.data.products_id
                    }
                })
            },
            async createNewProduct(){
                if (this.productid == null) {
                    if (this.product_for_copy == null) {
                        return
                    }
                    this.awaitNewProduct = true

                    await this.replicateProduct(this.product_for_copy)

                    if (this.replicatedProduct == null) {
                        this.awaitNewProduct = false
                        return
                    }

                    this.productId = this.replicatedProduct
                } else {
                    this.productId = this.productid
                }

                try {
                    if (!this.$store.hasModule(['products', "product_"+this.productId])) {
                        this.$store.registerModule(['products', "product_"+this.productId], ReusableProduct)
                    }

                    await this.$store.dispatch("products/product_"+this.productId+"/fetchProduct",
                        {
                            id: this.productId,
                            params : {
                                with: [
                                    'products_parts',
                                    'descriptions',
                                    'products_attributes',
                                    'discount',
                                    'product_note',
                                    'category',
                                    'ebay_categories',
                                    'price',
                                    'purchase_price'
                                ]
                            }
                        }).then(() => {

                            if (this.product.purchase_price == null) {
                                delete this.product.purchase_price
                            }
                            if (this.product.price == null) {
                                delete this.product.price
                            }
                            if (this.product.price == null) {
                                delete this.product.price
                            }
                            if (this.product.ebay_categories == null) {
                                delete this.product.ebay_categories
                            }
                            if (this.product.descriptions == null) {
                                delete this.product.descriptions
                            }
                            if (this.product.category == null) {
                                delete this.product.category
                            }
                            if (this.product.product_note == null) {
                                delete this.product.product_note
                            }
                            if (this.product.discount == null) {
                                delete this.product.discount
                            }

                            this.form = _.merge(this.form, this.product);
                            this.initialProduct = _.cloneDeep(this.form)


                            this.$emit('fullscreen');
                            this.$emit('productAdded', this.form);

                            this.isLiteVersion = (localStorage.getItem('add_lite_version') == 'true')

                            this.awaitNewProduct = false
                        })

                } catch (ex) {
                    this.error = "Failed to load product"
                }

            },
            difference(object, base, excluded = []) {
                return _.transform(object, (result, value, key) => {
                    if (!_.isEqual(value, base[key]) || excluded.includes(key)) {
                        result[key] = value
                    }
                });
            },
            saveProductInfo(){
                this.$refs.formEbayCategories.validate()
                this.$refs.formProductMainForm.validate()

                if (!this.awaitNewProduct) {
                    this.form.products_attributes = this.productsAttributes
                    this.awaitSaveProduct = true
                    this.$store.dispatch("products/product_"+this.productId+"/updateProduct", this.difference(this.form, this.initialProduct, ['products_id'])).then(() => {
                        this.initialProduct = _.cloneDeep(this.form)
                        this.awaitSaveProduct = false
                    })
                }


                //this.$emit('saveProductInfo', this.form);

                //this.productId = null;
                //this.form = Object.assign({}, formInit);
            },
            updateProductProperty(property, value) {
                let newValue = this.product_(property)
                newValue.options_values_id = value
                let payload = {value: newValue, property: property}
                this.$store.dispatch("products/product_"+this.productId+"/updateProductProperty", payload).then(() => {
                    this.saveProductInfo()
                })
            },
            openProductDialog() {
                EventBus.$emit('closeEditProductDialog');
                EventBus.$emit('closeProductDialog');
                let that = this;
                setTimeout(function() {
                    EventBus.$emit('openProductDialog', that.product)
                }, 25)
            },
            printProductBarcode() {
                window.open("/barcode/"+this.product.products_id, '_blank')
            },
            searchProduct(product) {
                this.product_for_copy = product.products_id
                this.products_template = null
                this.clearSearch = false
            },
            selectTemplate() {
                this.product_for_copy = this.products_template
                this.clearSearch = true
            }
        },
        components:{
            TreeSelect,
            ProductDefects,
            SearchProduct,
            Defects
            // CameraDefects,
            // LensDefects,
        }
    }
</script>

<style>

    #new-product-dialog-card {
        height: 100%;
    }
    #new-product-dialog-card .v-sheet {
        /*height: calc(100% - 48px);*/
    }

</style>