var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.createNewLabel
        ? _c(
            "v-row",
            {
              staticClass: "caption px-2 justify-center",
              staticStyle: { height: "100%" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "11" } },
                            [
                              _vm.order
                                ? _c("fedex-shipment-form", {
                                    on: { refresh: _vm.getLabel }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.createNewLabel
        ? _c(
            "v-row",
            { staticClass: "caption px-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-left" },
                        [
                          _c(
                            "v-list",
                            { attrs: { subheader: "", "two-line": "" } },
                            _vm._l(_vm.shippinglables, function(
                              shippinglable,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                { key: index, attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-large": "" } },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-file\n                                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            shippinglable.name
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "\n                                    Date: " +
                                            _vm._s(
                                              new Date(
                                                shippinglable.metadata
                                                  .timestamp * 1000
                                              ).toLocaleDateString("en-US")
                                            ) +
                                            ", size: " +
                                            _vm._s(
                                              shippinglable.metadata.size
                                            ) +
                                            " bytes\n                                "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "grey lighten-1"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.loadFile(
                                                    shippinglable.path
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("mdi-download")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.shippinglables.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-right mr-4",
                                          attrs: {
                                            color: "error",
                                            fab: "",
                                            "x-small": "",
                                            dark: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteLabel("direct")
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-delete")])],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { attrs: { subheader: "", "two-line": "" } },
                            _vm._l(_vm.returnlables, function(
                              shippinglable,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                { key: index, attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "red", "x-large": "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-file-pdf\n                                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            shippinglable.name
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "\n                                    Date: " +
                                            _vm._s(
                                              new Date(
                                                shippinglable.metadata
                                                  .timestamp * 1000
                                              ).toLocaleDateString("en-US")
                                            ) +
                                            ", Size: " +
                                            _vm._s(
                                              shippinglable.metadata.size
                                            ) +
                                            " bytes\n                                "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "grey lighten-1"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.loadFile(
                                                    shippinglable.path
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("mdi-download")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.returnlables.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-right mr-4",
                                          attrs: {
                                            color: "error",
                                            fab: "",
                                            "x-small": "",
                                            dark: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteLabel("return")
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-delete")])],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-col",
                                [
                                  !_vm.shippinglables.length &&
                                  !_vm.returnlables.length
                                    ? _c("v-progress-linear", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "green"
                                        }
                                      })
                                    : !_vm.returnlables.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-left",
                                          attrs: { color: "success" },
                                          on: { click: _vm.returnLabel }
                                        },
                                        [_vm._v("Create return label")]
                                      )
                                    : !_vm.shippinglables.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-left",
                                          attrs: { color: "success" },
                                          on: { click: _vm.directLabel }
                                        },
                                        [_vm._v("Create shipping label")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }