var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { height: "100% !important" },
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: { "max-height": "48px", height: "48px" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { dense: "", flat: "", light: "", color: "grey lighten-5" }
            },
            [
              _vm.productLoaded && _vm.product.descriptions
                ? _c(
                    "p",
                    { staticClass: "text-truncate text-left title ma-0" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.product.products_id) +
                          " - " +
                          _vm._s(_vm.product.descriptions.products_name) +
                          "\n                "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { id: "refreshButton" },
                  on: {
                    click: function($event) {
                      return _vm.fetchProduct(_vm.products_id)
                    }
                  }
                },
                [_vm._v("Refresh")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mx-1" }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "saveButton",
                    disabled: !_vm.isChanged,
                    color: "red"
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveProductDescription()
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mx-1" }),
              _vm._v(" "),
              _vm._l(_vm.ebay_global_sites, function(ebay_global_site, index) {
                return _vm.product &&
                  !_vm.product.multilingual_descriptions[
                    ebay_global_site.language
                  ]
                  ? _c(
                      "v-btn",
                      {
                        key: ebay_global_site.value,
                        staticClass: "mr-1",
                        attrs: {
                          fab: "",
                          "x-small": "",
                          icon: "",
                          color: "false"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addDescription(ebay_global_site.value)
                          }
                        }
                      },
                      [
                        _c(
                          "v-avatar",
                          { attrs: { size: "24" } },
                          [
                            _c("v-img", {
                              attrs: {
                                alt: ebay_global_site.country,
                                src: ebay_global_site.icon
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          staticStyle: { height: "100%" },
          attrs: { xs12: "", id: "editorProductDescription" }
        },
        [
          _vm.productLoaded && _vm.product.multilingual_descriptions
            ? _c(
                "v-tabs",
                { staticStyle: { height: "100%" }, attrs: { vertical: "" } },
                [
                  _vm._l(_vm.product.multilingual_descriptions, function(
                    description,
                    index
                  ) {
                    return _c(
                      "v-tab",
                      {
                        key: description.language_id,
                        staticClass: "ps-0 ma-0"
                      },
                      [
                        _c("v-avatar", { attrs: { size: "24" } }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getEbay(description.language.ebay.siteID)
                                .icon
                            }
                          })
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.product.multilingual_descriptions, function(
                    description,
                    index
                  ) {
                    return _c(
                      "v-tab-item",
                      {
                        key: description.language_id,
                        staticStyle: { height: "100%" }
                      },
                      [
                        _c(
                          "JqxSplitter",
                          {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              orientation: "horizontal",
                              panels: [{ size: "99%" }]
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "splitter-panel" },
                              [
                                _c("editor", {
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    "api-key":
                                      "a5k5wc2s7zqa3rmuejupxelpaekhnugvaxceagz01ic6jxdu",
                                    init: {
                                      height: "100%",
                                      menubar: false,
                                      toolbar:
                                        "undo redo styleselect forecolor bold italic alignleft aligncenter alignright bullist numlist outdent indent code",
                                      color_cols: 5,
                                      plugins: "code lists advlist"
                                    }
                                  },
                                  on: { onChange: _vm.onEditorInput },
                                  model: {
                                    value:
                                      description.long_products_description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        description,
                                        "long_products_description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "description.long_products_description"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "splitter-panel" },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    solo: "",
                                    height: "100%",
                                    "hide-details": "true"
                                  },
                                  on: { input: _vm.onEditorInput },
                                  model: {
                                    value:
                                      description.long_products_description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        description,
                                        "long_products_description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "description.long_products_description"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-speed-dial",
        {
          attrs: {
            bottom: "",
            right: "",
            absolute: "",
            direction: "top",
            "open-on-hover": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-2", dark: "", fab: "" },
                      model: {
                        value: _vm.previewDescriptionMenu,
                        callback: function($$v) {
                          _vm.previewDescriptionMenu = $$v
                        },
                        expression: "previewDescriptionMenu"
                      }
                    },
                    [
                      _vm.previewDescriptionMenu
                        ? _c("v-icon", [_vm._v("mdi-close")])
                        : _c("v-icon", [_vm._v("mdi-presentation")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.previewDescriptionMenu,
            callback: function($$v) {
              _vm.previewDescriptionMenu = $$v
            },
            expression: "previewDescriptionMenu"
          }
        },
        [
          _vm._v(" "),
          _vm._l(_vm.ebay_global_sites, function(ebay_global_site, index) {
            return _c(
              "v-btn",
              {
                key: ebay_global_site.value,
                attrs: { fab: "", dark: "", small: "", color: "blue" },
                on: {
                  click: function($event) {
                    return _vm.previewDescription(ebay_global_site.value)
                  }
                }
              },
              [
                _c("v-avatar", { attrs: { size: "24" } }, [
                  _c("img", {
                    attrs: {
                      alt: ebay_global_site.country,
                      src: ebay_global_site.icon
                    }
                  })
                ])
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }