<template>
    <div class="full-width" v-if="showCarousel()">
        <v-row no-gutters class="pt-2">
            <v-col>
                <v-btn :v-show="images" small @click="openFileDialog('fileReplace')">Replace</v-btn>
            </v-col>
            <v-col>
                <v-btn v-show="enableDelete" small text icon color="gray" @click="deleteSelectedImages"><v-icon>delete</v-icon></v-btn>
            </v-col>
            <v-col>
                <v-btn small text icon color="green" @click="openFileDialog('fileAdd')"><v-icon>add_box</v-icon></v-btn >
            </v-col>
            <v-col>
                <v-spacer/>
                <v-btn small text icon color="blue" id="refresh" @click="init"><v-icon>mdi-cached</v-icon></v-btn>
            </v-col>
            <v-file-input outlined dense
                          label="Replace"
                          accept="image/*"
                          class="d-none"
                          id="fileReplace"
                          ref="fileReplace"
                          @change="replaceImage"
            />
            <v-file-input outlined dense
                          label="Add"
                          accept="image/*"
                          class="d-none"
                          id="fileAdd"
                          ref="fileAdd"
                          @change="addImages"
            />
        </v-row>

        <v-carousel v-model="currentImage" style="height: calc(100vh - 285px);" height="100%">
            <v-carousel-item

                    v-for="(item,i) in images"
                    :key="i"
                    :href="item.src"
                    target="_blank"
            ><img :src="item.src" alt="product image" style="object-fit: contain; width: 98%; height: 90%;" class="elevation-1 mt-1"/></v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Images",
        props:{
            isActive:{},
            // products_id: {}
        },
        data(){
            return {
                images: [],
                currentImage: null,
                additional_images: null,
            }
        },
        computed:{
            enableDelete () {
                if (this.images && this.currentImage && typeof this.images[this.currentImage] !== 'undefined' && this.images[this.currentImage].additional_images_id !== 0) return true;
                else return false;
                //return true;
            },
            ...mapGetters([
                'productId',
                'product'
            ]),
        },
        mounted() {
            this.init()
        },
        methods: {
            init(){
                axios.get(`/api/products?
                filterGroups[0][field]=products_id
                &filterGroups[0][filters][0][value]=${this.productId}
                &filterGroups[0][filters][0][condition]=EQUAL
                &filterGroups[0][filters][0][type]=numericfilter
                &filterGroups[0][filters][0][operator]=or
                &filterGroups[0][operator]=or
                &pagesize=50`)
                    .then(response => {
                        this.additional_images = null;
                        this.additional_images = response.data.data[0].images;
                        let product = response.data.data[0];
                        this.images = [];
                        if(product.products_image){
                            this.images.push(
                                {
                                    additional_images_id: 0,
                                    src: `https://images.cameramate.com/${product.products_id}/s_${product.products_image}`,
                                    products_id: product.products_id
                                },
                            );
                        }

                        this.additional_images.forEach(image => {
                            this.images.push(
                                {
                                    additional_images_id: image.additional_images_id,
                                    src:`https://images.cameramate.com/${product.products_id}/${image.popup_images}`,
                                    products_id: product.products_id
                                }
                            )
                        });

                        this.$emit('componentReady');
                        console.log(this.additional_images)
                    })
            },
            replaceImage(file){
                if(file){
                    if (this.images[this.currentImage].additional_images_id === 0) { //first image
                        let formData = new FormData();
                        formData.append('file', file);
                        axios.post( '/api/additional_images/products_image/'+this.images[this.currentImage].products_id,
                            formData
                        ).then(function(value){
                            $('#refresh').click();
                            console.log('SUCCESS!!');
                        })
                            .catch(function(){
                                console.log('FAILURE!!');
                            });
                    } else {
                        let formData = new FormData();
                        formData.append('file', file);
                        axios.post( '/api/additional_images/'+this.images[this.currentImage].additional_images_id,
                            formData
                        ).then(function(value){
                            $('#refresh').click();
                            console.log('SUCCESS!!');
                        })
                        .catch(function(){
                            console.log('FAILURE!!');
                        });
                    }
                }

            },
            deleteSelectedImages(){
                if (this.images[this.currentImage].additional_images_id !== 0) { //first image
                    axios.delete('/api/additional_images/' + this.images[this.currentImage].additional_images_id
                    ).then(function (value) {
                        $('#refresh').click();
                        console.log('SUCCESS!!');
                    })
                    .catch(function () {
                        console.log('FAILURE!!');
                    });
                }
            },
            openFileDialog(id) {
                document.getElementById(id).value = '';
                document.getElementById(id).click();
            },
            addImages(file){
                if(file){
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('products_id', this.productId);
                    axios.post( '/api/additional_images',
                        formData
                    ).then(function(value){
                        //$('#refresh').click();
                        document.getElementById('refresh').click();
                        console.log('SUCCESS!!');
                    })
                    .catch(function(){
                        console.log('FAILURE!!');
                    });

                }
            },
            IDGenerator() {

                let length = 8;
                let timestamp = +new Date;

                let _getRandomInt = function (min, max) {
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                }

                let generate = function () {
                    let ts = timestamp.toString();
                    let parts = ts.split("").reverse();
                    let id = "";

                    for (let i = 0; i < length; ++i) {
                        let index = _getRandomInt(0, parts.length - 1);
                        id += parts[index];
                    }

                    return id;
                }

                return generate();
            },
            showCarousel() {
                if (this.product) {
                    let diffDays = this.$moment().diff(this.$moment(this.product.products_date_available), "days")
                    if (diffDays > 180 && this.product.products_status === 0  && this.product.products_quantity === 0 && this.product.category && this.product.category.categories_id === 218) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            },
        },
        watch:{
            isActive(){
                if(this.isActive === "tab-images"){
                    if(this.productId !== null){
                        this.init()
                    }
                }
            },
        },
    }
</script>

<style scoped>

</style>