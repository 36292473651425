var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        { staticStyle: { height: "100%" }, attrs: { xs12: "" } },
        [
          _vm.auctions.length > 0
            ? _c(
                "v-row",
                _vm._l(_vm.auctions, function(item, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "headline ma-0 pa-2" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://" + item.url,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "pink" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditAuction(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "indigo" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditAuctionLogin(
                                        item,
                                        _vm.newAuctionLogin
                                      )
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAuction(item.id)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          item.logins.length > 0
                            ? _c(
                                "v-card-subtitle",
                                [
                                  _c(
                                    "v-row",
                                    _vm._l(item.logins, function(login, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: i,
                                          staticStyle: { "text-align": "left" },
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "10" }
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v("Username:")
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                login.username
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c("strong", [
                                                            _vm._v("Password:")
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                login.password
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c("strong", [
                                                            _vm._v("Email:")
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                login.email
                                                              ) +
                                                              "\n                                            "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "right"
                                                          },
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "pink"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openEditAuctionLogin(
                                                                    item,
                                                                    login
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v("edit")
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteAuctionLogin(
                                                                    login.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v("delete")
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-10",
              attrs: {
                color: "pink",
                dark: "",
                absolute: "",
                bottom: "",
                right: "",
                fab: ""
              },
              on: {
                click: function($event) {
                  return _vm.openEditAuction(_vm.newAuction)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "editAuction",
          attrs: {
            name: "editAuction",
            fullscreen: false,
            width: "50vw",
            scrollable: true,
            persistent: ""
          },
          model: {
            value: _vm.showEditAuction,
            callback: function($$v) {
              _vm.showEditAuction = $$v
            },
            expression: "showEditAuction"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Add/Edit auction")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeEditAuction.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentAuction
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "auction",
                          staticClass: "ma-2",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              id: "name",
                              label: "Name",
                              required: ""
                            },
                            model: {
                              value: _vm.currentAuction.name,
                              callback: function($$v) {
                                _vm.$set(_vm.currentAuction, "name", $$v)
                              },
                              expression: "currentAuction.name"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              label: "URL",
                              id: "url",
                              required: ""
                            },
                            model: {
                              value: _vm.currentAuction.url,
                              callback: function($$v) {
                                _vm.$set(_vm.currentAuction, "url", $$v)
                              },
                              expression: "currentAuction.url"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentAuction
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.currentAuction.id
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "green" },
                              on: { click: _vm.saveAuction }
                            },
                            [
                              _vm._v(
                                "\n                    Save\n                "
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "green" },
                              on: { click: _vm.addAuction }
                            },
                            [
                              _vm._v(
                                "\n                    Add\n                "
                              )
                            ]
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "editAuctionLogin",
          attrs: {
            name: "editAuctionLogin",
            fullscreen: false,
            width: "50vw",
            scrollable: true,
            persistent: ""
          },
          model: {
            value: _vm.showEditAuctionLogin,
            callback: function($$v) {
              _vm.showEditAuctionLogin = $$v
            },
            expression: "showEditAuctionLogin"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Add/Edit auction login")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeEditAuctionLogin.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.currentAuctionLogin
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "auction",
                          staticClass: "ma-2",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              id: "username",
                              label: "Username",
                              required: ""
                            },
                            model: {
                              value: _vm.currentAuctionLogin.username,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentAuctionLogin,
                                  "username",
                                  $$v
                                )
                              },
                              expression: "currentAuctionLogin.username"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              label: "Password",
                              id: "password",
                              required: ""
                            },
                            model: {
                              value: _vm.currentAuctionLogin.password,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentAuctionLogin,
                                  "password",
                                  $$v
                                )
                              },
                              expression: "currentAuctionLogin.password"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.nameRules,
                              label: "Email",
                              id: "email",
                              required: ""
                            },
                            model: {
                              value: _vm.currentAuctionLogin.email,
                              callback: function($$v) {
                                _vm.$set(_vm.currentAuctionLogin, "email", $$v)
                              },
                              expression: "currentAuctionLogin.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentAuctionLogin
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.currentAuctionLogin.id
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "green" },
                              on: { click: _vm.saveAuctionLogin }
                            },
                            [
                              _vm._v(
                                "\n                    Save\n                "
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "green" },
                              on: { click: _vm.addAuctionLogin }
                            },
                            [
                              _vm._v(
                                "\n                    Add\n                "
                              )
                            ]
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }