<template>
    <v-card flat style="height: 100%">
        <v-card-text style="height: calc(100% - 30px);">
            <v-form ref="shipmentDHLLabel" v-model="labelForm">
                <!--        <v-row class="justify-center mt-4" no-gutters>
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-select dense
                                          v-model="ship.serviceType"
                                          :items="serviceTypes"
                                          label="Service type"
                                          :hide-details=true
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-0">
                                <v-select dense
                                          v-model="ship.dropOff"
                                          :items="dropOffTypes"
                                          label="Drop Off Type"
                                          :hide-details=true
                                ></v-select>
                            </v-col>
                        </v-row>-->

                <v-subheader class="justify-center font-weight-black ma-0 mb-1" style="height: 45px;">Recipient</v-subheader>
                <v-row class="justify-center" style="margin-top: -10px;" no-gutters>
                    <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                        <v-text-field dense
                                      v-model="ship.delivery_name"
                                      :rules="[v => (v && v.length <= 50) || '']"
                                      counter
                                      maxlength="50"
                                      color="blue darken-2"
                                      placeholder="Enter delivery name"
                                      class="body-1 full-width"
                                      label="Person"
                                      :hide-details=false
                        ></v-text-field>
                        <v-row justify="space-between" class="pa-0 mt-0 mx-0">
                            <v-col cols="10" class="pa-0 pr-sm-2">
                                <v-text-field dense
                                              v-model="ship.delivery_street"
                                              :rules="[v => (v && v.length <= 50) || '']"
                                              counter
                                              maxlength="50"
                                              color="blue darken-2"
                                              placeholder="Enter delivery street"
                                              class="body-1 full-width mt-4"
                                              label="Street"
                                              :hide-details=false
                                              style="margin-top: -1px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                                <v-text-field dense
                                              v-model="ship.delivery_streetNumber"
                                              :rules="[v => (v && v.length <= 10) || '']"
                                              counter
                                              maxlength="10"
                                              color="blue darken-2"
                                              placeholder="Enter delivery street number"
                                              class="body-1 full-width mt-4"
                                              label="#"
                                              :hide-details=false
                                              style="margin-top: -1px !important;"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="space-between" class="pa-0 mt-0 mx-0">
                            <v-col cols="12" class="pa-0">
                                <v-text-field dense
                                              v-model="ship.customers_telephone"
                                              :rules="[v => (v && v.length <= 20) || '']"
                                              counter
                                              maxlength="20"
                                              color="blue darken-2"
                                              placeholder="Enter Phone"
                                              class="body-1 full-width mt-4"
                                              label="Phone"
                                              :hide-details=false
                                              style="margin-top: 10px !important;"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-0">
                        <v-text-field dense
                                      v-model="ship.delivery_company"
                                      counter
                                      maxlength="50"
                                      color="blue darken-2"
                                      placeholder="enter delivery company"
                                      class="body-1 full-width"
                                      label="Company"
                                      :hide-details=false
                        ></v-text-field>
                        <v-row justify="space-between" class="mx-sm-1 pa-0 mt-0">
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-text-field dense
                                              v-model="ship.delivery_city"
                                              :rules="[v => (v && v.length <= 50) || '']"
                                              counter
                                              maxlength="50"
                                              color="blue darken-2"
                                              placeholder="enter delivery city"
                                              class="body-1 full-width"
                                              label="City"
                                              :hide-details=false
                                              style="margin-top: -1px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-text-field dense
                                              v-model="ship.delivery_state"
                                              counter
                                              maxlength="35"
                                              color="blue darken-2"
                                              placeholder="enter delivery state"
                                              class="body-1 full-width"
                                              label="Province"
                                              :hide-details=false
                                              style="margin-top: -1px !important;"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="space-between" class="mx-sm-1 pa-0 mt-0">
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-text-field dense
                                              v-model="ship.delivery_postcode"
                                              :rules="[v => (v && v.length <= 17) || '']"
                                              counter
                                              maxlength="17"
                                              color="blue darken-2"
                                              placeholder="enter delivery postcode"
                                              class="body-1 full-width mt-4"
                                              label="Zip"
                                              :hide-details=false
                                              style="margin-top: 10px !important;"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-autocomplete
                                    dense
                                    v-model="ship.delivery_country"
                                    :rules="rules.required"
                                    :items="countries"
                                    class="body-1 full-width mt-4"
                                    label="Country"
                                    :hide-details=false
                                    @change="changeCountry($event)"
                                    style="margin-top: 10px !important;"
                                    :readonly="!isCountryEditing"
                                >
                                    <template v-slot:append-outer>
                                        <v-icon
                                            small
                                            :key="`icon-${isCountryEditing}`"
                                            :color="isCountryEditing ? 'success' : 'info'"
                                            @click="isCountryEditing = !isCountryEditing"
                                            v-text="isCountryEditing ? 'mdi-lock-open-variant' : 'mdi-lock'"
                                        ></v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>

                <div v-if="ship.serviceType === 'V53WPAK'">
                    <v-subheader class="justify-center font-weight-black ma-0" style="height: 45px; margin-bottom: -10px !important;">Commodities</v-subheader>
                    <div class="pt-2" :style="(ship.order_products.length === 1 ? '' : 'height: '+heightDialog+'px; overflow-y:auto;') ">
                        <v-row v-if="ship.order_products.length" class="justify-center" no-gutters
                               v-for="(product, index) in ship.order_products"
                               :key="product.products_id">
                            <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                                <v-select
                                    v-model="product.description"
                                    :items="descriptionTypes"
                                    dense
                                    label="Description"
                                    class="full-width"
                                    :hide-details=true
                                ></v-select>
                                <v-row justify="space-between" class="mx-sm-1 pa-0 mt-1">
                                    <v-col cols="12" sm="3" class="mt-3 pa-0 pr-sm-2">
                                        <v-select
                                            :items="countriesManufacture"
                                            v-model="product.countryManufacture"
                                            class="body-1 full-width mt-5"
                                            style="margin-top: 10px !important;"
                                            dense
                                            label="Origin"
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3" class="pa-0 pr-sm-2">
                                        <v-text-field
                                            v-model="product.quantity"
                                            label="Quantity"
                                            type="number"
                                            readonly
                                            :hide-details=true
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" class="pa-0 pr-sm-2">
                                        <v-text-field
                                            v-model="product.pieces"
                                            label="Pieces"
                                            type="number"
                                            readonly
                                            :hide-details=true
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" class="pa-0">
                                        <v-text-field
                                            v-model="product.weight"
                                            @input="countTotal('weight')"
                                            label="Weight"
                                            type="number"
                                            suffix="KG"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-0">
                                <v-text-field dense
                                              v-model="product.products_name"
                                              color="blue darken-2"
                                              placeholder="enter delivery postcode"
                                              class="body-1 full-width"
                                              label="Product"
                                              hide-details
                                ></v-text-field>
                                <v-row justify="space-between" class="mx-sm-1 pa-0 mt-1">
                                    <v-col cols="6" class="pa-0 pr-sm-2">
                                        <v-text-field
                                            v-model="product.custom_price"
                                            :rules="rules.required"
                                            @input="countTotal('custom_price')"
                                            label="Custom price"
                                            type="number"
                                            :suffix="ship.currency"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pa-0">
                                        <v-text-field
                                            v-model="product.insured_price"
                                            @input="countTotal('insured_price')"
                                            label="Insured price"
                                            type="number"
                                            :suffix="ship.currency"
                                            hide-details
                                            style="display: none;"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider v-if="index !== ship.order_products.length - 1" class="ma-4"></v-divider>
                        </v-row>
                    </div>
                </div>

                <v-subheader class="justify-center font-weight-black mt-2 mb-1" style="height: 45px;">Package</v-subheader>
                <v-row class="justify-center" style="margin-top: -10px;" no-gutters>
                    <v-col cols="6" sm="3" class="pa-0 pr-sm-2">
                        <v-text-field dense
                                      v-model="ship.total_custom_price"
                                      color="blue darken-2"
                                      class="body-1 full-width"
                                      label="Total Customs Price"
                                      readonly
                                      :suffix="ship.currency"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3" class="pa-0 pr-sm-2">
                        <v-text-field dense
                                      v-model="ship.total_weight"
                                      :rules="[v => (!!v && v <= 31) || 'Max: 31']"
                                      color="blue darken-2"
                                      label="Total Weight"
                                      class="body-1 full-width"
                                      suffix="KG"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="2" class="pa-0 pr-sm-2">
                        <v-text-field dense
                                      v-model="ship.length"
                                      :rules="[v => (!!v && v >= 15 && v <= 120) || 'Min: 15, Max: 120']"
                                      label="Length"
                                      name="dhlLeght"
                                      type="number"
                                      suffix="cm"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="2" class="pa-0 pr-sm-2">
                        <v-text-field  dense
                                       v-model="ship.width"
                                       :rules="[v => (!!v && v >= 11 && v <= 60) || 'Min: 11, Max: 60']"
                                       label="Width"
                                       name="dhlWidth"
                                       type="number"
                                       suffix="cm"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="2" class="pa-0">
                        <v-text-field dense
                                      v-model="ship.height"
                                      :rules="[v => (!!v && v >= 1 && v <= 60) || 'Min: 1, Max: 60']"
                                      label="Height"
                                      name="dhlHeight"
                                      type="number"
                                      suffix="cm"
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="3" class="mt-3 pa-0">
                        <v-select dense
                                  v-model="ship.units"
                                  :items="units"
                                  label="Units"
                                  color="blue darken-2"
                                  disabled
                        ></v-select>
                    </v-col> -->
                </v-row>

                <v-subheader class="justify-center font-weight-black mt-2" style="height: 45px;">Additional paraments</v-subheader>
                <v-row class="justify-center" style="margin-top: -10px;" no-gutters>
                    <v-spacer/>
                    <v-col cols="12" sm="6" class="pa-0 pr-sm-2" v-if="ship.serviceType !== 'V53WPAK'">
                        <v-switch
                            v-model="ship.print_return"
                            flat
                            dense
                            hide-details
                            disabled
                            class="ma-0 pa-0"
                        >
                            <template v-slot:label>
                                <span style="font-size: 14px;">Return&nbsp;label<!--(€7.5)--></span>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" sm="6" class="pa-0 pr-sm-2" v-if="ship.serviceType !== 'V53WPAK'">
                        <v-switch
                            v-model="ship.neighbor"
                            flat
                            dense
                            hide-details
                            class="ma-0 pa-0"
                            :disabled="isNeighborDisabled"
                        >
                            <template v-slot:label>
                                <span style="font-size: 14px;">No&nbsp;neighbor&nbsp;(€0.29)</span>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" sm="6" class="pa-0 pr-sm-2" v-if="ship.serviceType !== 'V53WPAK'">
                        <v-switch
                            v-model="ship.NamedPersonOnly"
                            flat
                            dense
                            hide-details
                            class="ma-0 pa-0"
                            @change="changeNamedPersonOnly"
                        >
                            <template v-slot:label>
                                <span style="font-size: 14px;">Named&nbsp;person&nbsp;only&nbsp;€1.49)</span>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" sm="6" class="pa-0">
                        <v-radio-group
                            v-model="ship.additional_insurance"
                            row
                            dense
                            hide-details
                            class="mt-0 pt-0 dhl-insurance"
                        >
                            <template v-slot:label>
                                <span style="font-size: 14px; font-weight: bold;">Insurance:</span>
                            </template>
                            <v-radio
                                value="500"
                                dense
                                class="mr-2"
                            >
                                <template v-slot:label>
                                    <span style="font-size: 13px;">€500</span>
                                </template>
                            </v-radio>
                            <v-radio
                                value="2500"
                                dense
                                class="mr-2"
                                v-if="ship.total_custom_price > 500"
                            >
                                <template v-slot:label>
                                    <span style="font-size: 13px;">€2.500&nbsp;(+€6)</span>
                                </template>
                            </v-radio>
                            <v-radio
                                value="25000"
                                dense
                                class="mr-2"
                                v-if="ship.total_custom_price > 2500"
                            >
                                <template v-slot:label>
                                    <span style="font-size: 13px;">€25.000&nbsp;(+€18)</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-spacer/>
                </v-row>

                <v-dialog
                    v-model="alert"
                    width="70vw"
                    persistent
                >
                    <v-card flat>
                        <v-card-title class="ma-0 pa-0">
                            <v-toolbar dark dense color="blue darken-3">
                                <v-toolbar-title>Attention!!!</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn icon dark @click="alert = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                        </v-card-title>
                        <v-card-text class="pa-0">
                            <transition name="fade" mode="out-in" appear>
                                <v-alert dismissible  v-model="alert" :color="type" class="ma-0" elevation="2" v-html="onSaveText"></v-alert>
                            </transition>
                        </v-card-text>
                    </v-card>
                </v-dialog>

            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-subheader v-if="ship.serviceType === 'V01PAK'" class="justify-center font-weight-black mt-2">DHL Paket shipping cost: {{ shippingcost }} EUR ( {{ Math.round(shippingcost*25.5).toLocaleString() }} CZK )</v-subheader>
            <v-spacer></v-spacer>
            <v-btn @click="validateShippingLable" color="warning" :loading="creatingRequested">
                <span>Validate</span>
            </v-btn>
            <v-btn @click="getShippingLable" v-if="validate" color="primary" :loading="creatingRequested">
                <span>Ship</span>
            </v-btn>

        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "DhlShipmentForm",
        props:{
            fullscreen: {}
        },
        data(){
            return {
                ship: {
                    serviceType: 'V53WPAK',
                    dropOff: 'REGULAR_PICKUP',
                    delivery_name: null,
                    delivery_city: null,
                    customers_telephone: null,
                    delivery_street: null,
                    delivery_streetNumber: null,
                    delivery_company: null,
                    delivery_state: null,
                    delivery_postcode: null,
                    delivery_country: null,
                    order_products: [],
                    currency: null,
                    total_custom_price: 0,
                    total_insured_price: null,
                    total_weight: 0,
                    unitsw: 'KG',
                    units:  "CM",
                    packtype: 'YOUR_PACKAGING',
                    length: null,
                    width:null,
                    height: null,
                    signature: '',
                    print_return: true,
                    neighbor: true,
                    additional_insurance: '500',
                    NamedPersonOnly: false
                },
                rules: {
                    required: [value => !!value || ''],
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                packingRules:{
                    required: value => {
                        if(this.ship.packtype === 'YOUR_PACKAGING'){
                            return  !!value || ''
                        }
                        return true
                    }
                },
                labelForm: true,
                serviceTypes: [
                    {text: 'DHL PAKET', value: "V01PAK"},
                    {text: 'DHL PAKET International', value: 'V53WPAK'},
                    //{text: 'DHL Europaket', value: 'V54EPAK'}
                ],
                dropOffTypes: [
                    { text: 'Regular Pickup', value: 'REGULAR_PICKUP'},
                    { text: 'Request Courier', value: 'REQUEST_COURIER'}
                ],
                descriptionTypes:[
                    {text: 'Old used', value:'Old used'},
                    {text: 'Old used Lens', value:'Old used Lens'},
                    {text: 'Old used Cameras', value:'Old used Cameras'},
                    {text: 'Old used Accessories', value:'Old used Accessories'},
                ],
                countriesManufacture: [
                    "DE",
                    "CH"
                ],
                currencies: [
                    "EUR",
                    "CZK"
                ],
                units: [
                    "CM"
                ],
                packtypes: [
                    {value: 'YOUR_PACKAGING', text: "Your Packaging"},
                    {value: 'FEDEX_PAK', text: "FedEx Pak"},
                ],
                unitsw: [
                    "KG",
                    "lbs"
                ],
                signaturetypes: [
                    {value: '', text: "None specified"} ,
                    {value: 'DIRECT', text:  "Direct signature required"},
                    {value: 'ADULT', text: "Adult signature required"},
                ],
                onSaveText: '',
                alert: false,
                type: 'success',
                creatingRequested: false,
                validate: false,
                isCountryEditing: false,
                isNeighborDisabled: false
                //shippngcost: null,
            }
        },
        computed: {
            countries(){
                return _.map(this.countriesList, 'countries_name')
            },
            heightDialog() {
                if (this.fullscreen) {
                    return Math.round($('#tab-dhl-ship').height()/2.3);
                } else {
                    return Math.round($('#tab-dhl-ship').height()/3.5);
                }
            },
            shippingcost() {
                let cost = 0;
                if (this.ship.serviceType === 'V01PAK') {
                    if (this.ship.total_weight <= 1) {
                        cost += 4.25
                    } else if (this.ship.total_weight <= 3) {
                        cost += 4.85
                    } else if (this.ship.total_weight <= 5) {
                        cost += 5.8
                    } else if (this.ship.total_weight <= 10) {
                        cost += 7.45
                    } else if (this.ship.total_weight <= 20) {
                        cost += 11.40
                    } else if (this.ship.total_weight <= 31) {
                        cost += 13.80
                    }
                }

                if (this.ship.additional_insurance === '2500') {
                    cost += 6;
                } else if (this.ship.additional_insurance === '25000') {
                    cost += 18;
                }

                if (this.ship.neighbor) {
                    cost += 0.29;
                }

                if (this.ship.NamedPersonOnly) {
                    cost += 1.49;
                }

                return cost
            },
            ...mapGetters([
                'order',
                'countriesList',
            ]),
        },
        mounted() {
            if(this.order){
                const fromOrder = {
                    delivery_name: this.order.delivery_name,
                    delivery_city: this.order.delivery_city ,
                    customers_telephone: '' + this.order.customers_telephone ,
                    delivery_street: this.order.delivery_street_address ,
                    delivery_company: this.order.delivery_company,
                    delivery_address_country: this.order.delivery_address_country,
                    delivery_state: this.order.delivery_state,
                    delivery_postcode: '' + this.order.delivery_postcode,
                    delivery_country: this.order.delivery_country,
                    currency:  this.order.currency
                    //return: (this.order.print_return ? this.order.print_return : false)
                }

                if (this.order.delivery_address_country) {
                    if (this.order.delivery_address_country.countries_iso_code_2 === 'DE') {
                        this.ship.serviceType = 'V01PAK'
                    } else {
                        this.ship.serviceType = 'V53WPAK'
                        this.ship.print_return = false
                        /*if (this.order.delivery_address_country.geo_zone && this.order.delivery_address_country.geo_zone.length && this.order.delivery_address_country.geo_zone[0].geo_zone_name === 'EU') {
                            this.ship.serviceType = 'V53WPAK'
                        } else {
                            this.ship.serviceType = 'V53WPAK'
                        }*/
                    }

                }

                this.ship = {...this.ship, ...fromOrder}
                let self = this
                this.order.order_products.forEach(function(product){
                    self.ship.order_products.push(
                        {
                            orders_products_id: product.orders_products_id,
                            products_id: product.products_id,
                            products_name: product.products_name,
                            description: '',
                            quantity: product.products_quantity,
                            countryManufacture: "DE",
                            pieces: 1,
                            weight: product.product.products_weight,
                            custom_price:product.products_price * product.products_quantity,
                            insured_price: 0
                        }
                    )

                    self.ship.total_custom_price += product.products_price * product.products_quantity
                    if(product.products_insured_price) self.ship.total_insured_price += parseFloat(product.products_insured_price)
                    self.ship.total_weight += parseFloat(product.product.products_weight)
                })

                if (this.ship.total_custom_price > 500) {
                    this.ship.neighbor = false
                    this.isNeighborDisabled = true
                    this.ship.NamedPersonOnly = true
                }
                if (this.ship.total_custom_price > 500 && this.ship.total_custom_price <= 2500) {
                    this.ship.additional_insurance = '2500'
                } else if (this.ship.total_custom_price >= 2500) {
                    this.ship.additional_insurance = '25000'
                } else {
                    this.ship.additional_insurance = '500'
                }

                this.$refs.shipmentDHLLabel.validate()
            }
        },
        methods: {
            isValid(){
                this.$refs.shipmentDHLLabel.validate()
            },
            validateShippingLable(){
                this.validate = false
                if(this.$refs.shipmentDHLLabel.validate()){
                    this.creatingRequested = true
                    this.ship.type = 'validate'
                    axios.post('/api/orders/dhl/'+this.order.orders_id, this.ship)
                        .then(response => {
                            console.log(response)
                            if (response.status !== 200) {
                                this.showAlert('<strong>Somthing is wrong!!!</strong>', 'warning')
                            } else {
                                if(response.data.Status.statusCode !== 0){
                                    this.showAlert('<strong>Somthing is wrong!!!</strong><br/>'+response.data.Message, 'warning')
                                } else {
                                    this.showAlert('<strong>Notice::</strong><br/>'+response.data.Message, '')
                                    this.validate = true
                                }
                            }
                            this.creatingRequested = false
                        })
                }else{
                    this.showAlert('Fill all fields', 'warning')
                    this.creatingRequested = false
                    this.validate = false
                }

            },
            getShippingLable(){

                if(this.$refs.shipmentDHLLabel.validate() && this.validate){
                    this.creatingRequested = true
                    this.ship.type = 'ship'
                    axios.post('/api/orders/dhl/'+this.order.orders_id, this.ship)
                        .then(response => {
                            console.log(response)
                            if (response.status !== 200) {
                                this.showAlert('<strong>Somthing is wrong!!!</strong>', 'warning')
                            } else {
                                if(response.data.Status.statusCode !== 0){
                                    this.showAlert('<strong>Somthing is wrong!!!</strong><br/>'+response.data.Message, 'warning')
                                } else {
                                    this.showAlert('<strong>Notice::</strong><br/>'+response.data.Message, '')
                                    this.creatingRequested = false
                                    this.$emit('refresh')
                                }
                            }
                            this.creatingRequested = false
                        })
                }else{
                    this.showAlert('Fill all fields', 'warning')
                    this.creatingRequested = false
                }

            },
            getRate(){
                if(this.$refs.shipmentDHLLabel.validate()){
                    this.creatingRequested = true
                    this.ship.type = 'rate'
                    this.shippngcost = ''
                    axios.post('/api/orders/fedex/'+this.order.orders_id, this.ship)
                        .then(response => {
                            if(response.status === 200){
                                this.shippngcost = response.data.html
                            } else {
                                this.showAlert('Somsing is wrong!!!', 'success')
                            }
                        }).finally(() => {
                        this.creatingRequested = false
                    })
                }else{
                    this.showAlert('Fill all fields', 'warning')
                }
            },
            showAlert(text, type){
                this.onSaveText = text
                this.type = type
                this.alert = true
            },
            countTotal(prop){
                this.ship[`total_${prop}`] = 0
                this.ship.order_products.forEach((product)=>{
                    if(product[prop]) {
                        this.ship[`total_${prop}`] += parseFloat(product[prop])
                    }
                })
            },
            changeNamedPersonOnly() {
                this.ship.neighbor = !this.ship.NamedPersonOnly
                this.isNeighborDisabled = this.ship.NamedPersonOnly
            },
            changeCountry(event) {
                if (event === 'Germany') this.ship.serviceType = 'V01PAK'
                else this.ship.serviceType = 'V53WPAK'
                return false
            }
        },
        watch: {
            ship: {
                deep: true,
                handler() {
                    this.validate = false
                }
            }
        }
    }
</script>

<style>
  .dhl-insurance .v-input--selection-controls__input {
      margin: 0 !important;
  }
</style>