<template>
    <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field dense hide-details
                    v-model="dateRangeText"
                    prepend-icon="event"
                    readonly
                    v-on="on"
            >
                <template #append>
                    <v-icon @click="clearDates" v-if="dates.length" class="clear-icon">close</v-icon>
                </template>
            </v-text-field>


        </template>

        <v-date-picker v-model="dates" range>
            <v-spacer/>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="changeDates(dates)">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "CustomDateRange",
        props: ['datesValue'],
        data() {
            return {
                menu: false,
            }
        },

        computed: {
            dates: {
                get: function () {
                    return this.datesValue && this.datesValue.length ? this.datesValue :  []
                },
                set: function (newValue) {
                    console.log('dates',newValue);
                    // let range = [];
                    // range[0] = (newValue[0] <= newValue[1] ? newValue[0] : newValue[1] );
                    // range[1] = (newValue[0] >= newValue[1] ? newValue[0] : newValue[1] );
                     // this.$refs.menu.save(newValue);
                    this.$emit('input', newValue )
                }
            },
            dateRangeText:{
                get(){
                    let range = [];
                    range[0] = (this.dates[0] <= this.dates[1] ? this.dates[0] : this.dates[1] );
                    range[1] = (this.dates[0] >= this.dates[1] ? this.dates[0] : this.dates[1] );
                    return range.join(' ~ ')
                },
                set(newVal){
                    console.log('dateRangeText',newVal)
                }

            },
        },
        methods:{
            changeDates(dates) {

                // let range = [];
                // range[0] = (dates[0] <= dates[1] ? dates[0] : dates[1] );
                // range[1] = (dates[0] >= dates[1] ? dates[0] : dates[1] );
                this.$refs.menu.save(dates);
                // console.log(range)
                // this.dates = range;
                // this.$emit('input', this.dates)
            },
            clearDates(){
                this.dates = [];
                this.dateRangeText = '~';
            }

        },
    }
</script>

<style scoped>

</style>