var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticStyle: { height: "98%", width: "100%", border: "none" },
    attrs: { src: "https://sniper.cameramate.com/buy/" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }