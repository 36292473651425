var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-0",
      staticStyle: {
        border: "none",
        display: "flex !important",
        "flex-direction": "column"
      },
      attrs: { outlined: "", rounded: false, flat: "" }
    },
    [
      _c(
        "v-card-text",
        {
          staticClass: "rounded-0",
          staticStyle: { "flex-grow": "1", overflow: "auto", height: "385px" }
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "6" }
                },
                [_vm._v("Category:")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "6" }
                },
                [_vm._v("Target:")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "6" }
                },
                [
                  _c("tree-select", {
                    attrs: {
                      name: "productCategory",
                      clearable: false,
                      "load-options": _vm.loadOptionsProductCategories,
                      options: _vm.productsOptionsCategories,
                      "auto-load-root-options": true,
                      searchable: true,
                      normalizer: _vm.normalizer,
                      "always-open": true,
                      "search-nested": "",
                      "show-count": true,
                      defaultExpandLevel: 2
                    },
                    model: {
                      value: _vm.product.category.categories_id,
                      callback: function($$v) {
                        _vm.$set(_vm.product.category, "categories_id", $$v)
                      },
                      expression: "product.category.categories_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-start ma-0",
                  attrs: { cols: "6" }
                },
                [
                  _c("tree-select", {
                    attrs: {
                      name: "productTargetCategory",
                      clearable: false,
                      "load-options": _vm.loadOptionsProductTargetCategories,
                      options: _vm.productsOptionsCategoriesTarget,
                      "auto-load-root-options": true,
                      searchable: true,
                      normalizer: _vm.normalizer,
                      "always-open": true,
                      "search-nested": "",
                      "disable-branch-nodes": true,
                      "show-count": true,
                      defaultExpandLevel: 2
                    },
                    model: {
                      value: _vm.product.targetcatid,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "targetcatid", $$v)
                      },
                      expression: "product.targetcatid"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-0", staticStyle: { width: "100%" } }),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "text-right" },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "body-1 ml-2",
              attrs: { small: "", color: "primary" },
              on: { click: _vm.saveProductCategory }
            },
            [_vm._v("Save")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "body-1 ml-2",
              attrs: { small: "", color: "default" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v("Close")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }