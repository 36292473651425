<template>
    <div class="image-upload-timeline">
        <v-row no-gutters>
            <v-col cols="12">

                                <v-timeline
                                    dense
                                    clipped
                                    light
                                    :align-top="false"
                                    v-for="event in timeline"
                                    :key="event.id"
                                >
                                    <v-timeline-item
                                        class="ma-0 pa-0"
                                        :color="event.color"
                                        small
                                    >
                                        <v-layout>
                                            <v-flex xs7 v-text="event.text" text-xs-left></v-flex>
                                            <v-flex xs5 v-text="event.time" text-xs-left></v-flex>
                                        </v-layout>
                                    </v-timeline-item>
                                </v-timeline>

            </v-col>
        </v-row>
        <v-footer v-bind="localAttrs">
            <v-flex xs4>
                <input type="file" id="file" ref="file" onClick="this.value = ''"  accept="application/zip" v-on:change="handleFileUpload()"/>
            </v-flex>
            <v-flex xs6>
                <v-progress-linear v-model="uploadPercentage"></v-progress-linear>
            </v-flex>
            <v-flex xs2>
                <v-btn
                    :disabled="disableSubmit || !file"
                    @click="submitFile()">Submit</v-btn>
            </v-flex>
            <v-flex xs12 class="pa-1" >
                <v-progress-linear color="purple" v-model="processingPercentage" height="20"></v-progress-linear>
            </v-flex>
        </v-footer>
    </div>
</template>

<script>
    export default {
        name: "UploadImages",
        data: function () {
            return {
                btndisabled: {
                    disabled: true
                },
                file: null,
                uploadPercentage: 0,
                processingPercentage: 0,
                totalProducts: 0,
                disableSubmit: false,
                pusher: null,
                channel: null,
                products: [],
                events: [],
                nonce: 0,
                localAttrs: {
                    absolute: false,
                    fixed: false
                }
            }
        },
        methods: {
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
                this.disableSubmit = false;
            },
            async submitFile(){
                this.disableSubmit = true;
                let formData = new FormData();
                formData.append('file', this.file);
                this.events = [];
                this.uploadPercentage = 0;
                this.processingPercentage = 0;
                this.totalProducts = 0;

                await axios.post( '/api/upload_product_images',
                        formData,
                        {
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                                if (this.uploadPercentage == 100) this.localAttrs.absolute = true;
                            }.bind(this)
                        }
                    )
                    .then(() => {

                    })
                    .catch(function(){
                        console.log('FAILURE!!');
                    });
            },
            initPusher: function () {
                this.pusher = new Pusher('e35cfe809776adceb457', {
                    cluster: 'eu',
                    forceTLS: true
                });
            },
            subscribePusher: function () {
                this.channel = this.pusher.subscribe('upload_product_images');
                this.channel.bind("App\\Events\\UploadProductImagesEvent", this.processEvent);
            },
            processEvent: function (data) {
                if (!_.isUndefined(data.event.event_id)) {
                    console.log(data.event.event_id);
                    switch (data.event.event_id) {
                        case "App\\Events\\UploadProductImagesEvent":
                            this.events.push({
                                id: this.nonce++,
                                text: data.event.message.text,
                                time: data.event.message.time,
                                color: data.event.message.color
                            });
                            if (data.event.message.total !== 0) {
                                this.totalProducts = data.event.message.total;
                            }
                            if (data.event.message.progress !== 0 && this.totalProducts !== 0) {
                                this.processingPercentage = parseInt( Math.round( (( data.event.message.progress ) / this.totalProducts)*100 ) );
                            }
                            break;
                    }
                }
            }
        },
        computed: {
            timeline () {
                return this.events.slice().reverse()
            }
        },
        mounted() {
            this.initPusher();
            this.subscribePusher();
        }
    }
</script>

<style scoped>
.image-upload-timeline {
    margin-bottom: 0;
    height: calc(100vh - 35px - 48px - 48px);
    overflow: auto;
    width: 100%;
}
</style>