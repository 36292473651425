import Vue from 'vue'
import Vuex from "vuex"
import order from './order'
import product from './product'
import products from './products'
import createCache from 'vuex-cache';

Vue.use(Vuex)

function getParentsCategoryName(category){
    let categoryName = (category.name ? category.name : '');
    if (category.parent) {
        categoryName = categoryName + ' | ' + getParentsCategoryName(category.parent)
    }
    return categoryName;
}

const store = new Vuex.Store({
    plugins: [createCache(/*{ timeout: 60000 }*/)],
    modules: {
        order,
        product,
        products,
        main: {
            state: {
                user: {
                    name: null,
                    email: null
                },
                debug: true,
                count: 0,
                ebay_global_sites: {
                    0 : { country: 'United States', name: 'us', language: 'en', value: 0, id: 1, icon: '/images/flags/1x1/us.svg'},
                    3 : { country: 'UK', name: 'gb', language: 'en', value: 3, id: 3, icon: '/images/flags/1x1/gb.svg'},
                    77 : { country: 'Germany', name: 'de', language: 'de', value: 77, id: 2, icon: '/images/flags/1x1/de.svg'},
                    71 : { country: 'France', name: 'fr', language: 'fr', value: 71, id: 4, icon: '/images/flags/1x1/fr.svg'},
                    101 : { country: 'Italy', name: 'it', language: 'it', value: 101, id: 5, icon: '/images/flags/1x1/it.svg'},
                    186 : { country: 'Spain', name: 'es', language: 'es', value: 186, id: 6, icon: '/images/flags/1x1/es.svg'}
                },
                currencies: [],
                initialCurrencies: null,
                countries:  null,
                orders_statuses: null,
                paymentMethods: [
                    {text: "PayPal", value: 'PayPal'},
                    {text: "Bank", value: 'BankTransfer'},
                    {text: "Cash", value: 'Cash'},
                    {text: "eBay", value: 'IntegratedMerchantCreditCard'}
                ],
                auction_accounts: [
                    //{text: 'aukro.cz-photo-vision', value: 'aukro.cz-photo-vision', active: false},
                    {text: 'CM', value: 'CM', active: true},
                    {text: 'leica-post', value: 'ebay.co.uk-leica-post', active: true},
                    {text: 'camexpres', value: 'ebay.com-camexpres', active: true},
                    {text: 'eu-tradefactor', value: 'ebay.de-eu-tradefactor', active: true},
                    {text: 'kinoptik', value: 'ebay.com-kinoptik', active: false},
                    {text: 'ebay.com-user13867742', value: 'ebay.com-user13867742', active: false},
                    //{text: 'ebay.de-camexpres', value: 'ebay.de-camexpres', active: false},
                    //{text: 'ebay.de-leica-post', value: 'ebay.de-leica-post', active: false},
                    //{text: 'ebay.fr-camexpres', value: 'ebay.fr-camexpres', active: false},
                    //{text: 'molotok.ru-trade-partner', value: 'molotok.ru-trade-partner', active: false},
                ],
                productCategories: null,
                productOptions: null,
                globalSearchParams: null,
                ebayTokens: null,
                productsDefects: null,
                ebayCategoriesCom: null,
                ebayCategoriesDe: null,
                ebayCategoriesCouk: null
            },
            mutations: {
                setEbayCategories(state, payload) {
                    state[payload.stateVar] = payload.ebayCategories.map(category => {
                        let categoryName = category.name;
                        if (category.parent) {
                            categoryName = categoryName + ' | ' + getParentsCategoryName(category.parent)
                        }
                        let ebayCategories = []
                        ebayCategories['name'] = categoryName
                        ebayCategories['catid'] = category.catid
                        ebayCategories['siteID'] = category.siteID
                        return ebayCategories
                    });
                },
                updateTokens(state, tokens){
                    state.tokens = tokens
                },
                updateCurrentUser(state, currentUser){
                    state.user = currentUser
                },
                logout: (state) => {

                },
                setCountriesAction (state, newValue) {
                    if (this.debug) { console.log('setMessageAction triggered with', newValue)}
                    state.countries = newValue
                },
                clearCountriesAction () {
                    if (this.debug) {console.log('clearMessageAction triggered')}
                    state.countries = ''
                },
                setUser(state, userData){
                    if(userData){
                        state.user = userData
                    }
                },
                setCurrencies(state, currenciesArr){
                    state.initialCurrencies = currenciesArr
                    state.currencies = _.map(currenciesArr, 'code')
                },
                setCountries(state, countriesArr){
                    state.countries = countriesArr
                },
                setStatuses(state, statusesArr){
                    state.orders_statuses = statusesArr
                },
                setProductOptions(state, optionsArr){
                    let productOptions = _.keyBy(optionsArr, 'products_options_id');
                    productOptions = _.forEach(productOptions, function(element) {
                        element.options_value = _.sortBy(element.options_value, ['products_options_values_order', 'asc'])
                    });

                    state.productOptions = productOptions
                },
                setProductCategories(state, categoriesArr){
                    state.productCategories = categoriesArr
                },
                setEbayTokens(state, ebayTokensArr){
                    state.ebayTokens = ebayTokensArr
                },
                setGlobalSearchParams(state, searchParamsObj){
                    state.globalSearchParams = searchParamsObj
                },
                setProductsDefects(state, productsDefects) {
                    state.productsDefects = productsDefects
                }
            },
            getters:{
                currenciesList: state =>  state.currencies,
                initialCurrencies: state => state.initialCurrencies,
                countriesList: state => state.countries,
                orders_statusesList: state => state.orders_statuses,
                ebay_global_sites: state => state.ebay_global_sites,
                paymentMethods: state => state.paymentMethods,
                auction_accounts: state => state.auction_accounts,
                productCategories: state => state.productCategories,
                productOptions: state => state.productOptions,
                // productConditions: state => state.productConditions,
                globalSearchParams: state => state.globalSearchParams,
                ebayTokensList: state => state.ebayTokens,
                productsDefects: state => state.productsDefects,
                ebayCategoriesCom: state => state.ebayCategoriesCom,
                ebayCategoriesDe: state => state.ebayCategoriesDe,
                ebayCategoriesCouk: state => state.ebayCategoriesCouk
            },
            actions:{
                async initEbayCategoriesCom(context){
                    return await axios
                        .get(`/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=0&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1`)
                        .then(response => {
                            context.commit('setEbayCategories', {'ebayCategories' : response.data, 'stateVar': 'ebayCategoriesCom'})
                        }).catch(err => {
                            console.log(err)
                        })
                },
                async initEbayCategoriesDe(context){
                    return await axios
                        .get(`/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=77&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1`)
                        .then(response => {
                            context.commit('setEbayCategories', {'ebayCategories' : response.data, 'stateVar': 'ebayCategoriesDe'})
                        }).catch(err => {
                            console.log(err)
                        })
                },
                async initEbayCategoriesCouk(context){
                    return await axios
                        .get(`/api/ebay_category_selected/?with[]=parentCategory&filterGroups[0][field]=siteID&filterGroups[0][filters][0][value]=3&filterGroups[0][filters][0][condition]=EQUAL&filterGroups[0][filters][0][type]=numericfilter&filterGroups[0][filters][0][operator]=or&filterGroups[0][operator]=and&filterslength=1`)
                        .then(response => {
                            context.commit('setEbayCategories', {'ebayCategories' : response.data, 'stateVar': 'ebayCategoriesCouk'})
                        }).catch(err => {
                            console.log(err)
                        })
                },
                async initCurrencies(context){
                    return await axios
                        .get('/api/currencies')
                        .then(response => {
                            context.commit('setCurrencies', response.data)
                        }).catch(err => {
                            console.log(err)
                        })
                },
                initEbayTokens(context){
                    return axios
                        .get('/api/ebay_token')
                        .then(response => {
                            context.commit('setEbayTokens', response.data)
                            console.log('ebay_token complited')
                        }).catch(err => {
                            console.log(err)
                        })
                },
                initCountries(context) {
                    return axios
                        .get('/api/countries?with[]=geo_zone')
                        .then(response => {
                            context.commit('setCountries', response.data)
                        }).catch(err => {
                            console.log(err)
                        })
                },
                async initStatuses(context) {
                    return await axios
                        .get('/api/order_statuses')
                        .then(response => {
                            context.commit('setStatuses', _.sortBy(response.data, ['sort']))
                        }).catch(err => {
                            console.log(err)
                        })
                },
                getCurrentUser(context, user) {
                    return new Promise((resolve, reject ) => {
                        // console.log('user', user)

                        axios.get('/api/user', data)
                            .then(response => {
                                // console.log('resolve response', response)
                                let responseData = response.data

                                context.commit('updateCurrentUser', responseData)

                                resolve(response)
                            })
                            .catch(response => {
                                // console.log('reject response', response)
                                reject(response)
                            })
                    })
                },
                initCategories({ dispatch, commit }){
                    return axios
                        .get('/api/category?pagesize=10000')
                        .then(response => {
                            // console.log(response.data.data)
                            let categoriesArr = []
                            let categoriesArrData = response.data.data;

                            let tree = function (data, root) {
                                let r = [], o = {};
                                data.forEach(function (a) {

                                    a.categories_id = parseFloat(a.categories_id)
                                    a.parent_id = parseFloat(a.parent_id)

                                    if (o[a.categories_id] && o[a.categories_id].children) {
                                        a.children = o[a.categories_id] && o[a.categories_id].children;
                                    }
                                    o[a.categories_id] = a;
                                    if (a.parent_id === root) {
                                        r.push(a);
                                    } else {
                                        o[a.parent_id] = o[a.parent_id] || {};
                                        o[a.parent_id].children = o[a.parent_id].children || [];
                                        o[a.parent_id].children.push(a);
                                    }
                                });
                                return r;
                            }(categoriesArrData, 0);

                            commit('setProductCategories',tree)

                            })
                },
                initProductOptions(context){
                    return axios.get('/api/products_option?pagesize=10000&with[]=options_value')
                        .then(response => {
                            // console.log(response.data.data)
                            context.commit('setProductOptions', response.data.data)
                        })
                },
                saveSearchRequest(context, searchParamsObj){
                    context.commit('setGlobalSearchParams', searchParamsObj)
                },
                async initProductsDefects(context){
                    return await axios
                        .get('/api/parts?with[]=groups.defects')
                        .then(response => {
                            context.commit('setProductsDefects', response.data)
                            console.log('initProductsDefects')
                        }).catch(err => {
                            console.log(err)
                        })
                }

            }

        }
    },

});

export default store