<template>
    <div>
        I'm home component
    </div>
</template>

<script>
    export default {
        name: "Cameramate"
    }
</script>

<style scoped>

</style>