var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { staticClass: "full-width" },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex justify-start full-width",
          attrs: { flat: "", tile: "" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pt-4",
              staticStyle: { "max-width": "30%" },
              attrs: { flat: "", tile: "" }
            },
            [
              _c("v-select", {
                staticClass: "px-1",
                attrs: {
                  dense: "",
                  "hide-details": "",
                  items: _vm.statuses,
                  label: "Status",
                  clearable: "",
                  "return-object": "",
                  "item-text": "orders_status_name",
                  "item-value": "orders_status_id"
                },
                on: {
                  change: _vm.onChangeOrdersStatus,
                  "click:clear": function($event) {
                    return _vm.onClear("orders_status")
                  }
                },
                model: {
                  value: _vm.form.orders_status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orders_status", $$v)
                  },
                  expression: "form.orders_status"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }