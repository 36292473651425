var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.priceHistoryData
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "blue", icon: "", small: "", text: "" },
                  on: { click: _vm.init }
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "scroll-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.priceHistoryData,
                  dense: "",
                  "group-by": "start",
                  "hide-default-footer": "",
                  "show-group-by": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "group.header",
                      fn: function(ref) {
                        var group = ref.group
                        var headers = ref.headers
                        var toggle = ref.toggle
                        var isOpen = ref.isOpen
                        return [
                          _c(
                            "td",
                            {
                              staticStyle: { "text-align": "left" },
                              attrs: { colspan: headers.length }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: group,
                                  attrs: {
                                    "x-small": "",
                                    icon: "",
                                    "data-open": isOpen
                                  },
                                  on: { click: toggle }
                                },
                                [
                                  isOpen
                                    ? _c("v-icon", [
                                        _vm._v("mdi-minus-circle-outline")
                                      ])
                                    : _c("v-icon", [
                                        _vm._v("mdi-plus-circle-outline")
                                      ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ml-1 font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(group === 0 ? "GTC" : "Auction")
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.products_price",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                parseFloat(props.item.products_price).toFixed(2)
                              ) +
                              "\n      "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.valid_from",
                      fn: function(props) {
                        return [
                          props.item.valid_from
                            ? _c("span", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm
                                        .$moment(props.item.valid_from)
                                        .format("MM-DD-YYYY")
                                    ) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.valid_until",
                      fn: function(props) {
                        return [
                          props.item.valid_until
                            ? _c("span", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(
                                      _vm
                                        .$moment(props.item.valid_until)
                                        .format("MM-DD-YYYY")
                                    ) +
                                    "\n                   "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.rate",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(parseFloat(props.item.rate).toFixed(2)) +
                              "\n      "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(props) {
                        return [
                          _c("v-checkbox", {
                            staticClass: "ma-0 pa-0 justify-center",
                            attrs: {
                              dense: "",
                              disabled: "",
                              "hide-details": ""
                            },
                            model: {
                              value: props.item.star,
                              callback: function($$v) {
                                _vm.$set(props.item, "star", $$v)
                              },
                              expression: "props.item.star"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }