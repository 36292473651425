var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-filters" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "px-4 pt-2",
          attrs: {
            light: "",
            right: "",
            "disable-resize-watcher": "",
            "disable-route-watcher": "",
            fixed: "",
            temporary: "",
            absolute: "",
            width: "300"
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeMenu.apply(null, arguments)
            }
          },
          model: {
            value: _vm.isDrawerOpen,
            callback: function($$v) {
              _vm.isDrawerOpen = $$v
            },
            expression: "isDrawerOpen"
          }
        },
        [
          _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-1 ma-0 pa-0", attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "title ma-0 pa-0" }, [
                        _vm._v("Product filters")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
                    [_c("v-divider")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          items: _vm.statuses,
                          label: "Status:"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChange($event)
                          }
                        },
                        model: {
                          value: _vm.form.products_status,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "products_status", $$v)
                          },
                          expression: "form.products_status"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          "cache-items": true,
                          label: "Weight",
                          items: _vm.productWeightsList,
                          "menu-props": { auto: true, offsetY: true },
                          "item-text": "products_options_values_name",
                          "item-value": "products_options_values_name"
                        },
                        on: { change: _vm.onChange },
                        model: {
                          value: _vm.form.products_weight,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "products_weight", $$v)
                          },
                          expression: "form.products_weight"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          "cache-items": true,
                          label: "Condition",
                          items: _vm.productConditionsList,
                          "menu-props": { auto: true, offsetY: true },
                          "item-text": "products_options_values_name",
                          "item-value": "products_options_values_id"
                        },
                        on: { change: _vm.onChange },
                        model: {
                          value: _vm.form.products_attributes.condition.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.condition,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.condition.value"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          "hide-details": "",
                          "cache-items": true,
                          label: "Discount",
                          items: _vm.productDiscountList,
                          "menu-props": { auto: true, offsetY: true },
                          "item-text": "products_options_values_name",
                          "item-value": "products_options_values_name"
                        },
                        on: { change: _vm.onChange },
                        model: {
                          value: _vm.form["discount.persent"],
                          callback: function($$v) {
                            _vm.$set(_vm.form, "discount.persent", $$v)
                          },
                          expression: "form['discount.persent']"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
                    [_c("v-divider")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
                    [
                      _c("tree-select", {
                        staticClass: "mb-2",
                        attrs: {
                          clearable: "",
                          "hide-details": "",
                          options: _vm.productCategories,
                          "auto-load-root-options": false,
                          searchable: true,
                          normalizer: _vm.normalizer,
                          placeholder: "Category"
                        },
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.form["category.categories_id"],
                          callback: function($$v) {
                            _vm.$set(_vm.form, "category.categories_id", $$v)
                          },
                          expression: "form['category.categories_id']"
                        }
                      }),
                      _vm._v(" "),
                      _c("tree-select", {
                        staticStyle: { display: "none" },
                        attrs: {
                          clearable: "",
                          "hide-details": "",
                          "load-options": _vm.loadOptions,
                          options: _vm.productsOptions,
                          "auto-load-root-options": false,
                          searchable: true,
                          normalizer: _vm.normalizer,
                          placeholder: "Target"
                        },
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.form.targetcatid,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "targetcatid", $$v)
                          },
                          expression: "form.targetcatid"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0", attrs: { cols: "12" } },
                    [_c("v-divider")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pl-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.otk.value
                          ),
                          "true-value": "580",
                          "false-value": "581"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Hold")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.otk
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.otk.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.otk.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.otk.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.otk,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.otk.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.colony.value
                          ),
                          "true-value": "600",
                          "false-value": "601"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Colony")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.colony
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.colony.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.colony.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.colony.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.colony,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.colony.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.autoDiscount.value
                          ),
                          "true-value": "548",
                          "false-value": "547"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Auto Discount")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes
                                            .autoDiscount.value !== null,
                                        expression:
                                          "form.products_attributes.autoDiscount.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.autoDiscount.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value:
                            _vm.form.products_attributes.autoDiscount.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.autoDiscount,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "form.products_attributes.autoDiscount.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.repair.value
                          ),
                          "true-value": "545",
                          "false-value": "546"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Repair")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.repair
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.repair.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.repair.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.repair.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.repair,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.repair.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.cmCom.value
                          ),
                          "true-value": "596",
                          "false-value": "597"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("CM.com")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.cmCom
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.cmCom.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.cmCom.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.cmCom.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.cmCom,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.cmCom.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.gtc.value
                          ),
                          "true-value": "30",
                          "false-value": "31"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("GTC")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.gtc
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.gtc.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.gtc.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.gtc.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.gtc,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.gtc.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.bestOffers.value
                          ),
                          "true-value": "533",
                          "false-value": "534"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Bestoffers")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes
                                            .bestOffers.value !== null,
                                        expression:
                                          "form.products_attributes.bestOffers.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.bestOffers.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.bestOffers.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.bestOffers,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "form.products_attributes.bestOffers.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ma-0 pa-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "checkbox-product-filter",
                        attrs: {
                          "hide-details": "",
                          indeterminate: _vm.isIndeterminate(
                            _vm.form.products_attributes.freeShip.value
                          ),
                          "true-value": "29",
                          "false-value": "28"
                        },
                        on: { change: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-label", [_vm._v("Free ship")]),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.products_attributes.freeShip
                                            .value !== null,
                                        expression:
                                          "form.products_attributes.freeShip.value !== null"
                                      }
                                    ],
                                    attrs: {
                                      small: "",
                                      right: "",
                                      color: "orange darken-2"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.form.products_attributes.freeShip.value = null
                                        _vm.onChange()
                                      }
                                    }
                                  },
                                  [_vm._v("clear")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.form.products_attributes.freeShip.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.products_attributes.freeShip,
                              "value",
                              $$v
                            )
                          },
                          expression: "form.products_attributes.freeShip.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-3 pt-0", attrs: { cols: "12" } },
                    [_c("v-divider")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center my-3"
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { small: "" }, on: { click: _vm.clearFilters } },
                    [_vm._v("Clear")]
                  ),
                  _vm._v(" "),
                  _vm.isDrawerOpen
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            color: "green",
                            disabled: _vm.isFilterEnmpty
                          },
                          on: { click: _vm.filterProducts }
                        },
                        [
                          _vm._v(
                            "\n                    Filter\n                "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }