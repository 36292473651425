<template>
  <v-layout align-space-around justify-start column fill-height >
    <v-flex xs12 style="max-height: 48px; height: 48px;">
      <v-toolbar dense flat light color="grey lighten-5">
        <v-spacer></v-spacer>
        <v-btn id="RefreshButton">Refresh</v-btn>
      </v-toolbar>
    </v-flex>
    <v-flex xs12>
      <div id="splitter" style="height: 100%;">
        <div>
          <div id="productTable"></div>
        </div>
        <div>
          <div id="categoryTableNested"></div>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>

  import JqxGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid.vue';
  import { mapGetters } from 'vuex'

  export default {
    name: "CategorySpecific",
    components: {
      JqxGrid
    },
    mounted() {
      $('#RefreshButton').click(function() {
        $('#productTable').jqxGrid('updateBoundData');
      });

      let source = {
        datatype: "json",
        datafields: [
          { name: 'products_id', type: 'number' },
          { name: 'descriptions.products_name', map: 'descriptions>products_name', type: 'string'},
          { name: 'categories_id', map: 'category>categories_id', type: 'number'},
          { name: 'base_currency', type: 'string'},
          { name: 'products_price', map: 'products_price_EUR', type: 'float'},
          { name: 'purchase_currency', map: 'purchase_price>currencies_code', type: 'string'},
          { name: 'purchase_price', map: 'purchase_price>purchase_price_EUR', type: 'float'},
          { name: 'discount', map: 'discount>persent', type: 'number'},
          { name: 'margin', type: 'float'},
          { name: 'products_for_ebay'},
          { name: 'products_on_ebay.siteID'},
          { name: 'products_on_ebay.ItemID'},
          { name: 'ebay_categories'},
          { name: 'ebay_categories_update'},
          { name: 'products_status', type: 'number'},
          { name: 'products_on_ebay'},
          { name: 'products_attributes'},

          //US
          { name: 'ebay_site_0_id', map: 'products_for_ebay>0>id', type: 'number'},
          { name: 'ebay_site_0_price', map: 'products_for_ebay>0>price', type: 'float'},
          { name: 'ebay_site_0_enabled', map: 'products_for_ebay>0>enabled'},
          { name: 'ebay_site_0_rate', map: 'products_for_ebay>0>ebay_site>currency>value', type: 'float'},

          //DE
          { name: 'ebay_site_77_id', map: 'products_for_ebay>77>id', type: 'number'},
          { name: 'ebay_site_77_price', map: 'products_for_ebay>77>price', type: 'float'},
          { name: 'ebay_site_77_enabled', map: 'products_for_ebay>77>enabled'},
          { name: 'ebay_site_77_rate', map: 'products_for_ebay>77>ebay_site>currency>value', type: 'float'},

          //UK
          { name: 'ebay_site_3_id', map: 'products_for_ebay>3>id', type: 'number'},
          { name: 'ebay_site_3_price', map: 'products_for_ebay>3>price', type: 'float'},
          { name: 'ebay_site_3_enabled', map: 'products_for_ebay>3>enabled'},
          { name: 'ebay_site_3_rate', map: 'products_for_ebay>3>ebay_site>currency>value', type: 'float'},

          //FR
          { name: 'ebay_site_71_id', map: 'products_for_ebay>71>id', type: 'number'},
          { name: 'ebay_site_71_price', map: 'products_for_ebay>71>price', type: 'float'},
          { name: 'ebay_site_71_enabled', map: 'products_for_ebay>71>enabled'},
          { name: 'ebay_site_71_rate', map: 'products_for_ebay>71>ebay_site>currency>value', type: 'float'},

          //IT
          { name: 'ebay_site_101_id', map: 'products_for_ebay>101>id', type: 'number'},
          { name: 'ebay_site_101_price', map: 'products_for_ebay>101>price', type: 'float'},
          { name: 'ebay_site_101_enabled', map: 'products_for_ebay>101>enabled'},
          { name: 'ebay_site_101_rate', map: 'products_for_ebay>101>ebay_site>currency>value', type: 'float'},

          //ES
          { name: 'ebay_site_186_id', map: 'products_for_ebay>186>id', type: 'number'},
          { name: 'ebay_site_186_price', map: 'products_for_ebay>186>price', type: 'float'},
          { name: 'ebay_site_186_enabled', map: 'products_for_ebay>186>enabled'},
          { name: 'ebay_site_186_rate', map: 'products_for_ebay>186>ebay_site>currency>value', type: 'float'},

        ],
        pagesize: 10,
        id: 'products_id',
        url: '/api/products?with[]=descriptions',
        root: 'data',
        beforeprocessing: function (data) {
          source.totalrecords = data.total;
        },
        sortcolumn: 'products_id',
        sortdirection: 'desc',
        sort: function () {
          $("#productTable").jqxGrid('updatebounddata', 'sort');
        },
        filter: function () {
          $("#productTable").jqxGrid('updatebounddata', 'filter');
        }
      };

      let dataAdapter = new $.jqx.dataAdapter(source, {
        formatData: function (data) {
          data.page = data.pagenum+1;
          return data;
        }
      });

      let productTableColumns = function() {
        return [
          { text: 'ID', dataField: 'products_id', width: '60', align: 'center', cellsalign: 'center', editable: false, filtertype: 'textbox', filtercondition: 'CONTAINS',
            cellClassName: function (row, column, value, data) {
              if (data.products_status) {
                return "text-success";
              }
              return "text-warning";
            }
          },
          { text: 'Title', dataField: 'descriptions.products_name', align: 'center', editable: false, sortable: false, filtertype: 'textbox', filtercondition: 'CONTAINS'
          }
        ];
      };

      $("#productTable").jqxGrid({
        width: '100%',
        height: '100%',
        source: dataAdapter,
        sortable: true,
        filterable: true,
        virtualmode: true,
        pageable: true,
        editable: false,
        showfilterrow: true,
        showsortmenuitems: true,
        showfiltermenuitems: true,
        autoshowfiltericon: false,
        enablebrowserselection: true,
        showstatusbar: true,
        statusbarheight: 45,
        showaggregates: true,
        pagesizeoptions: ['10', '20', '50', '100', '500', '1000'],
        showsortcolumnbackground: false,
        localization: {currencySymbol: "€", currencySymbolPosition: "after"},
        rendergridrows: function (params) {
          return params.data;
        },
        columns: productTableColumns()
      });
    }
  }
</script>

<style scoped>

</style>