var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: { "max-height": "48px", height: "48px" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { dense: "", flat: "", light: "", color: "grey lighten-5" }
            },
            [
              _vm.productLoaded && _vm.product.descriptions
                ? _c(
                    "p",
                    { staticClass: "text-truncate text-left title ma-0" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.product.products_id) +
                          " - " +
                          _vm._s(_vm.product.descriptions.products_name) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { id: "RefreshButton" },
                  on: {
                    click: function($event) {
                      return _vm.updateProductToEbayCategory(_vm.products_id)
                    }
                  }
                },
                [_vm._v("Refresh")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _vm.ebay_categoryspecifics.length
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.ebay_categoryspecifics, function(
                  ebay_categoryspecific,
                  index
                ) {
                  return _c(
                    "v-col",
                    {
                      key: ebay_categoryspecific.siteId,
                      attrs: {
                        cols: Math.round(12 / _vm.ebay_categoryspecifics.length)
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "ma-0 pa-2" },
                            [
                              _c("v-avatar", { attrs: { size: "24" } }, [
                                _c("img", {
                                  attrs: {
                                    alt:
                                      _vm.ebay_global_sites[
                                        ebay_categoryspecific.siteId
                                      ].country,
                                    src:
                                      _vm.ebay_global_sites[
                                        ebay_categoryspecific.siteId
                                      ].icon
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("strong", {
                                staticClass: "ml-1",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.product.ebay_categories[
                                      ebay_categoryspecific.siteId
                                    ]
                                      ? _vm.product.ebay_categories[
                                          ebay_categoryspecific.siteId
                                        ].name
                                      : "Unknown category"
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    dense: "",
                                    "no-gutters": "",
                                    align: "center"
                                  }
                                },
                                _vm._l(ebay_categoryspecific.aspects, function(
                                  aspect,
                                  index
                                ) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: aspect.aspectName,
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      aspect.aspectConstraint.aspectMode ===
                                        "SELECTION_ONLY" || aspect.aspectValues
                                        ? _c("v-combobox", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id:
                                                aspect.aspectName +
                                                "-" +
                                                ebay_categoryspecific.siteId +
                                                "_" +
                                                ebay_categoryspecific.categoryId,
                                              items: aspect.aspectValues,
                                              "item-text": "localizedValue",
                                              "item-value": "localizedValue",
                                              label: aspect.localizedAspectName,
                                              outlined: "",
                                              dense: "",
                                              multiple:
                                                aspect.aspectConstraint
                                                  .itemToAspectCardinality ===
                                                "MULTI",
                                              required:
                                                aspect.aspectConstraint
                                                  .aspectRequired == true,
                                              error:
                                                aspect.aspectConstraint
                                                  .aspectRequired == true,
                                              clearable: "",
                                              chips:
                                                aspect.aspectConstraint
                                                  .itemToAspectCardinality ===
                                                "MULTI",
                                              "small-chips":
                                                aspect.aspectConstraint
                                                  .itemToAspectCardinality ===
                                                "MULTI",
                                              deletableChips:
                                                aspect.aspectConstraint
                                                  .itemToAspectCardinality ===
                                                "MULTI",
                                              hideDetails: "",
                                              hideNoData: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveProductSpecifics(
                                                  ebay_categoryspecific.siteId,
                                                  ebay_categoryspecific.categoryId,
                                                  _vm
                                                    .product_ebay_categoryspecifics[
                                                    ebay_categoryspecific.siteId
                                                  ]
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .product_ebay_categoryspecifics[
                                                  ebay_categoryspecific.siteId
                                                ][aspect.aspectName].value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .product_ebay_categoryspecifics[
                                                    ebay_categoryspecific.siteId
                                                  ][aspect.aspectName],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "product_ebay_categoryspecifics[ebay_categoryspecific.siteId][aspect.aspectName].value"
                                            }
                                          })
                                        : _c("v-text-field", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              label: aspect.localizedAspectName,
                                              outlined: "",
                                              dense: "",
                                              required:
                                                aspect.aspectConstraint
                                                  .aspectRequired == true,
                                              error:
                                                aspect.aspectConstraint
                                                  .aspectRequired == true,
                                              clearable: "",
                                              hideDetails: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveProductSpecifics(
                                                  ebay_categoryspecific.siteId,
                                                  ebay_categoryspecific.categoryId,
                                                  _vm
                                                    .product_ebay_categoryspecifics[
                                                    ebay_categoryspecific.siteId
                                                  ]
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .product_ebay_categoryspecifics[
                                                  ebay_categoryspecific.siteId
                                                ][aspect.aspectName].value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .product_ebay_categoryspecifics[
                                                    ebay_categoryspecific.siteId
                                                  ][aspect.aspectName],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "product_ebay_categoryspecifics[ebay_categoryspecific.siteId][aspect.aspectName].value"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }