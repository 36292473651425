var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "ma-0 pt-0",
      staticStyle: { "background-color": "transparent !important" },
      attrs: { id: "main-search-bar" }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-row",
          staticStyle: { "background-color": "transparent !important" },
          attrs: { flat: "", tile: "" }
        },
        [
          _c(
            "v-card",
            {
              staticStyle: { "background-color": "transparent !important" },
              attrs: { outlined: "", tile: "" }
            },
            [
              _c("v-overflow-btn", {
                staticStyle: { width: "175px", "z-index": "1000" },
                attrs: {
                  id: "main-search-bar-selector",
                  small: "",
                  dense: "",
                  "hide-details": "",
                  "hide-selected": "",
                  items: _vm.searchOption,
                  "item-value": "field",
                  "item-text": "text",
                  "return-object": "",
                  dark: ""
                },
                model: {
                  value: _vm.searchBy,
                  callback: function($$v) {
                    _vm.searchBy = $$v
                  },
                  expression: "searchBy"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticStyle: {
                "background-color": "transparent !important",
                width: "100%"
              },
              attrs: { outlined: "", tile: "" }
            },
            [
              _c("v-text-field", {
                staticStyle: {
                  "background-color": "transparent !important",
                  width: "100%",
                  "padding-top": "2px"
                },
                attrs: {
                  dense: "",
                  solo: "",
                  flat: "",
                  "hide-details": "",
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  name: _vm.searchBy.field,
                  autocomplete: "off"
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchAndOpenDialog.apply(null, arguments)
                  },
                  change: _vm.changeSearch,
                  "click:clear": _vm.clearSearch,
                  "click:append": _vm.searchAndOpenDialog
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          staticClass: "search-results",
          attrs: { persistent: "", "max-width": "90%" },
          model: {
            value: _vm.searchDialog,
            callback: function($$v) {
              _vm.searchDialog = $$v
            },
            expression: "searchDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "wrap-results" },
            [
              _c(
                "v-card-title",
                { attrs: { id: "dialog-title" } },
                [
                  _c("span", { staticClass: "headline" }, [
                    _vm._v("Search results")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeAndReset.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {},
                [
                  _c(
                    "v-container",
                    { staticStyle: { height: "100%" } },
                    [
                      _vm.loading
                        ? _c(
                            "v-row",
                            {
                              staticClass: "ma-0 pa-1",
                              staticStyle: { height: "100%" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      size: 150,
                                      width: 7,
                                      color: "purple",
                                      indeterminate: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            { staticStyle: { height: "100%" } },
                            [
                              _vm.results.products.length
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.results.orders.length ? 6 : 12
                                      }
                                    },
                                    [
                                      _c("h2", [_vm._v("Products")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                { attrs: { color: "primary" } },
                                                _vm._l(
                                                  _vm.results.products,
                                                  function(product, i) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: i },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openProductDialog(
                                                                      product
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text ma-0"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          product.products_id
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          product
                                                                            .descriptions
                                                                            .products_name
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.printProductBarcode(
                                                                  product
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-barcode-scan"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.results.products_total > 1
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "title mt-3",
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    exact: "",
                                                    to: { name: "Products" }
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.handleSearch(
                                                        "Products"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-btn", [
                                                    _vm._v(
                                                      "All results (" +
                                                        _vm._s(
                                                          _vm.results
                                                            .products_total
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-divider", { attrs: { vertical: "" } }),
                              _vm._v(" "),
                              _vm.results.orders.length
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.results.products.length
                                          ? 6
                                          : 12
                                      }
                                    },
                                    [
                                      _c("h2", [_vm._v("Orders")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                { attrs: { color: "primary" } },
                                                _vm._l(
                                                  _vm.results.orders,
                                                  function(order, i) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: i },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text ma-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "pa-2",
                                                                        class:
                                                                          order
                                                                            .orders_status_name
                                                                            .orders_status_name,
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.openOrderDialog(
                                                                              order
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            order.orders_id
                                                                          ) +
                                                                            " - " +
                                                                            _vm._s(
                                                                              order.site
                                                                            ) +
                                                                            " - " +
                                                                            _vm._s(
                                                                              order.date_purchased
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "amber"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.printOrderReceipt(
                                                                              order
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-cube-outline"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list",
                                                                  {
                                                                    attrs: {
                                                                      dense: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-group",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary"
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        order.order_products,
                                                                        function(
                                                                          product,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "v-list-item",
                                                                            {
                                                                              key: i,
                                                                              attrs: {
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    {
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.openProductDialog(
                                                                                            product.product
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                            " +
                                                                                          _vm._s(
                                                                                            product.products_id
                                                                                          ) +
                                                                                          " - " +
                                                                                          _vm._s(
                                                                                            product.products_name
                                                                                          ) +
                                                                                          "\n                                                                        "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.printProductBarcode(
                                                                                        product
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-barcode-scan"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.results.orders_total > 1
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "title mt-3",
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    exact: "",
                                                    to: { name: "Orders" }
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.handleSearch(
                                                        "Orders",
                                                        "orderParams"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-btn", [
                                                    _vm._v(
                                                      "All results (" +
                                                        _vm._s(
                                                          _vm.results
                                                            .orders_total
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.results.products.length &&
                              !_vm.results.order_products.length &&
                              !_vm.results.orders.length
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v("Nothing found")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }