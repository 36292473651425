<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://sniper.cameramate.com/all/"></iframe>
</template>

<script>
export default {
    name: "SniperSearch"
}
</script>

<style scoped>

</style>