<template>
        <v-app>
            <v-content>
                <v-container>
                    <router-view></router-view>
                </v-container>
            </v-content>
        </v-app>

</template>

<script>
    export default {
        name: "AuthLayout"
    }
</script>

<style scoped>
  .v-text-field input {
      opacity: unset;
  }
</style>