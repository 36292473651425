var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _vm.currentOrder &&
          _vm.currentOrder.orders_status_name.orders_status_name === "Pending"
            ? _c("v-data-table", {
                staticClass: "elevation-1 width-100 refund-product-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.currentOrder.order_products,
                  "item-key": "products_id",
                  loading: _vm.loading,
                  "hide-default-header": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(props) {
                        return [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "4" } }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("p", { staticClass: "mr-1 ml-2 mb-0" }, [
                                    _vm._v(
                                      "(" + _vm._s(props.item.products_id) + ")"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "eclipse mb-0 mr-1 font-weight-bold"
                                    },
                                    [_vm._v(_vm._s(props.item.products_name))]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "pl-2" },
                              [
                                _c("v-checkbox", {
                                  staticClass: "ma-0",
                                  attrs: { "hide-details": "", dense: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prepend",
                                        fn: function() {
                                          return [
                                            _c("v-label", [_vm._v("Restock:")])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: props.item.restock,
                                    callback: function($$v) {
                                      _vm.$set(props.item, "restock", $$v)
                                    },
                                    expression: "props.item.restock"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("tree-select", {
                                  staticClass: "pr-2",
                                  attrs: {
                                    "load-options": _vm.loadOptions,
                                    options: _vm.productsOptions,
                                    "auto-load-root-options": true,
                                    searchable: true,
                                    normalizer: _vm.normalizer,
                                    placeholder: "Select category",
                                    appendToBody: ""
                                  },
                                  model: {
                                    value: props.item.product.targetcatid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        props.item.product,
                                        "targetcatid",
                                        $$v
                                      )
                                    },
                                    expression: "props.item.product.targetcatid"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    },
                    _vm.loading
                      ? {
                          key: "body.prepend",
                          fn: function() {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "pa-0",
                                    staticStyle: { height: "2px" },
                                    attrs: { colspan: _vm.headers.length + 1 }
                                  },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        color: "blue",
                                        height: 2,
                                        indeterminate: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              })
            : _vm.currentOrder == null
            ? _c("v-progress-linear", {
                attrs: {
                  color: "green darken-4",
                  indeterminate: "",
                  rounded: "",
                  height: "6"
                }
              })
            : _c("p", [_vm._v("The order was canceled.")])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "red",
                disabled: !(
                  _vm.currentOrder &&
                  _vm.currentOrder.orders_status_name.orders_status_name ===
                    "Pending"
                )
              },
              on: { click: _vm.cancelOrder }
            },
            [_vm._v("Cancel order")]
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }