<template>
    <div>
        <v-navigation-drawer
                app
                v-model="drawer"
                clipped
                light
                disable-resize-watcher
                disable-route-watcher
                style="z-index: 1000;"
        >

            <v-list dense v-if="favorites_menu.length > 0"  class="pt-0 pb-0">

                    <v-list-item
                            v-for="(item, index) in favorites_menu"
                            :key="item.title"
                            :to="item.tag"
                            @click=""
                            dense
                    >
                        <v-list-item-content dense>
                            <v-list-item-title dense v-text="item.title"  @click="clickMainMenu"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>


            </v-list>
            <v-divider v-if="favorites_menu.length > 0"></v-divider>

            <v-list dense class="pt-0">
                <v-list-group
                        v-for="item in main_menu"
                        v-model="item.active"
                        :key="item.title"
                        :prepend-icon="item.action"
                        no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item
                            v-for="subItem in item.items"
                            :key="subItem.title"
                            :to="subItem.tag"
                            dense
                    >
                        <!-- <v-list-item-action v-if="subItem.icon">
                            <v-icon>{{ subItem.icon }}</v-icon>
                        </v-list-item-action> -->
                        <v-list-item-content >
                            <v-list-item-title @click="clickMainMenu">{{ subItem.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-btn icon ripple @click="favoritesToggle(subItem)">
                            <v-icon
                                    v-if="isFavorite(subItem)"
                                    color="grey lighten-1"
                            >
                                star_border
                            </v-icon>


                            <v-icon
                                    v-else
                                    color="yellow darken-2"
                            >
                                star
                            </v-icon>
                        </v-btn>
                    </v-list-item>

                </v-list-group>
            </v-list>

        </v-navigation-drawer>
        <v-app-bar
                app
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                color="blue darken-3"
                dark
                dense
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title
                    style="width: 20%;"
                    class="ml-0 pl-4"
            >{{ pageTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items style="width: 60%;">
                <search-bar></search-bar>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items justify-center>

                <v-btn
                        icon
                        v-if="failedJobs > 0"
                        href="/failed-job"
                >
                    <v-badge
                            left
                            overlap
                            color="red"
                    >
                        <template v-slot:badge>
                            <span>{{ failedJobs }}</span>
                        </template>
                        <v-icon
                        color="green lighten-1"
                        >
                        group_work
                        </v-icon>
                    </v-badge>
                </v-btn>

                <v-menu offset-y :open-on-hover=false>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            to="change-password"
                        >
                            <v-list-item-title>
                                Change password
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <!-- <v-list-item-title @click="logout()">logout</v-list-item-title> -->
                            <v-list-item-title>
                                Logout
                                <!--<a href="/logout"  onclick="event.preventDefault(); document.getElementById('logout-form').submit();" class="dropdown-item"><i class="icon-power3"></i> Logout</a>-->
                            </v-list-item-title>
                            <form id="logout-form" action="/logout" method="POST" style="display: none;">
                                <input type="hidden" name="_token" :value="token">
                            </form>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-toolbar-items>
        </v-app-bar>
    </div>

</template>

<script>
    import * as _ from 'lodash';
    import SearchBar from "./SearchBar";

    export default {
        name: "HeaderBar",
        components: {SearchBar},
        props: {
            favorites_menu: {
                required: true,
            },
            main_menu: {
                required: true,
            },
            failedJobs: {
                required: true,
            }
        },
        data(){
            return {
                drawer: false,
                pageTitle: 'Cameramate',
            }
        },
        methods: {
            isFavorite(subItem) {
                return _.findIndex(this.favorites_menu, subItem) < 0
            },
            favoritesToggle(item) {
                console.log('favoritesToggle', item)
                this.$emit('toggle_favorites', item)
            },
            logout(){
                document.getElementById('logout-form').submit()
                // this.$store.dispatch('logout')
                //     .then(response => {
                //         this.$router.push('/login');
                //     })
            },
            clickMainMenu: function() {
                this.drawer = !this.drawer;
            },
        },
        mounted(){
            this.pageTitle = this.$route.name ? this.$route.name : 'Cameramate';
        },
        watch: {
            $route() {
                this.pageTitle = this.$route.name ? this.$route.name : 'Cameramate';
            }
        },
        computed: {
            token() {
                let token = document.head.querySelector('meta[name="csrf-token"]');
                return token.content
            }
        },
    }
</script>

<style scoped>

    .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>div>.v-list-item {
        padding-left: 16px;
    }

    .autocomplete-search{
        /*width: 400px;*/
    }


</style>