var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "v-container",
        {
          staticClass: "product-item ma-0 pa-0",
          staticStyle: { "min-width": "500px" }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "stretch",
                justify: "space-between",
                "no-gutters": ""
              }
            },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "min-width": "490px" },
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "align-center",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex justify-content-between align-center ma-0 pa-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { dense: "" },
                              on: {
                                click: function($event) {
                                  return _vm.printProductBarcode(
                                    _vm.item.products_id
                                  )
                                }
                              }
                            },
                            [_vm._v("mdi-barcode")]
                          ),
                          _vm._v(" "),
                          !_vm.showEditName
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "flex-grow-1 body-1 primary--text ma-0 text-left eclipse pr-2",
                                  on: { click: _vm.editName }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.item.descriptions.products_name
                                    ) + " "
                                  )
                                ]
                              )
                            : _c("v-text-field", {
                                staticClass: "body-2",
                                attrs: {
                                  rules: _vm.rules,
                                  autofocus: "",
                                  counter: "80",
                                  dense: "",
                                  "single-line": ""
                                },
                                on: {
                                  blur: function($event) {
                                    _vm.showEditName = !_vm.showEditName
                                  },
                                  change: _vm.editInfo,
                                  keydown: _vm.escapeCloseEditName
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "flex-grow-0",
                                              attrs: {
                                                color: "success",
                                                icon: "",
                                                small: ""
                                              },
                                              on: { click: _vm.editInfo }
                                            },
                                            [_c("v-icon", [_vm._v("save")])],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1544830663
                                ),
                                model: {
                                  value: _vm.item.descriptions.products_name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.item.descriptions,
                                      "products_name",
                                      $$v
                                    )
                                  },
                                  expression: "item.descriptions.products_name"
                                }
                              }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "flex-grow-0",
                              attrs: { icon: "" },
                              on: { click: _vm.clickProductSpecifics }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "green", small: "" } },
                                [_vm._v("mdi-file-tree")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "flex-grow-0",
                              attrs: {
                                color: _vm.hasNote() ? "green" : "lime",
                                disabled: !_vm.enableEditNote,
                                icon: ""
                              },
                              on: { click: _vm.clickShowNote }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-alpha-n-circle-outline")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showNote
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-2 pb-2",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "body-2",
                                    attrs: {
                                      "append-outer-icon": "save",
                                      "auto-grow": "",
                                      autofocus: "",
                                      "background-color": "blue lighten-4",
                                      color: "light-green",
                                      dense: "",
                                      "hide-details": "",
                                      label: "Note",
                                      outlined: ""
                                    },
                                    on: { "click:append-outer": _vm.editInfo },
                                    model: {
                                      value:
                                        _vm.item.product_note.products_note,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.product_note,
                                          "products_note",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.product_note.products_note"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEditDescription
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-2 pb-2",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "body-2",
                                    attrs: {
                                      "append-outer-icon": "save",
                                      "auto-grow": "",
                                      autofocus: "",
                                      "background-color": "green lighten-5",
                                      color: "light-blue",
                                      dense: "",
                                      "hide-details": "",
                                      label: "Description",
                                      outlined: ""
                                    },
                                    on: { "click:append-outer": _vm.editInfo },
                                    model: {
                                      value:
                                        _vm.item.descriptions
                                          .products_description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.item.descriptions,
                                          "products_description",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "item.descriptions.products_description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : !_vm.showNote && !_vm.showEditDescription
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-0",
                              staticStyle: { "max-width": "800px" },
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-end ma-0 pa-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "caption font-weight-regular currency-select pr-1",
                                    staticStyle: {
                                      width: "110px",
                                      "max-width": "110px"
                                    },
                                    attrs: {
                                      "cache-items": true,
                                      items: _vm.productWeightsList,
                                      "menu-props": {
                                        auto: true,
                                        offsetY: true
                                      },
                                      dense: "",
                                      "hide-details": "",
                                      "item-text":
                                        "products_options_values_name",
                                      "item-value":
                                        "products_options_values_id",
                                      label: "",
                                      "single-line": ""
                                    },
                                    on: { input: function($event) {} },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [_vm._v("Weight:")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3527329301
                                    ),
                                    model: {
                                      value: _vm.weight,
                                      callback: function($$v) {
                                        _vm.weight = $$v
                                      },
                                      expression: "weight"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    staticClass:
                                      "caption font-weight-regular product-info-product-conditions-list pr-1",
                                    staticStyle: {
                                      width: "165px",
                                      "max-width": "165px",
                                      height: "25px"
                                    },
                                    attrs: {
                                      "cache-items": true,
                                      items: _vm.productConditionsList,
                                      "menu-props": {
                                        auto: true,
                                        offsetY: true
                                      },
                                      dense: "",
                                      "hide-details": "",
                                      "item-text":
                                        "products_options_values_name",
                                      "item-value":
                                        "products_options_values_id",
                                      label: ""
                                    },
                                    on: { input: function($event) {} },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [
                                                _vm._v("Condition:")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1385554918
                                    ),
                                    model: {
                                      value: _vm.condition,
                                      callback: function($$v) {
                                        _vm.condition = $$v
                                      },
                                      expression: "condition"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      dense: "",
                                      "false-value": "28",
                                      "hide-details": "",
                                      "true-value": "29"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [
                                                _vm._v("Free Shipment:")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3212772721
                                    ),
                                    model: {
                                      value: _vm.freeShip,
                                      callback: function($$v) {
                                        _vm.freeShip = $$v
                                      },
                                      expression: "freeShip"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      dense: "",
                                      "false-value": "534",
                                      "hide-details": "",
                                      "true-value": "533"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [
                                                _vm._v("Best Offers:")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      158323166
                                    ),
                                    model: {
                                      value: _vm.bestOffer,
                                      callback: function($$v) {
                                        _vm.bestOffer = $$v
                                      },
                                      expression: "bestOffer"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-0",
                              staticStyle: { "max-width": "800px" },
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-space-between pt-1 ma-0 pa-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      dense: "",
                                      "false-value": "546",
                                      "hide-details": "",
                                      "true-value": "545"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [_vm._v("Repair:")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2352084328
                                    ),
                                    model: {
                                      value: _vm.repair,
                                      callback: function($$v) {
                                        _vm.repair = $$v
                                      },
                                      expression: "repair"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      readonly: !!_vm.item.products_status,
                                      dense: "",
                                      "false-value": "31",
                                      "hide-details": "",
                                      "true-value": "30"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.checkBoxGTCClick()
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [_vm._v("GTC:")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      310931205
                                    ),
                                    model: {
                                      value: _vm.gtc,
                                      callback: function($$v) {
                                        _vm.gtc = $$v
                                      },
                                      expression: "gtc"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      dense: "",
                                      "false-value": "581",
                                      "hide-details": "",
                                      "true-value": "580"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _c("v-label", [_vm._v("Hold:")])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1831961306
                                    ),
                                    model: {
                                      value: _vm.otk,
                                      callback: function($$v) {
                                        _vm.otk = $$v
                                      },
                                      expression: "otk"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      readonly: !!_vm.item.products_status,
                                      dense: "",
                                      "false-value": "601",
                                      "hide-details": "",
                                      "true-value": "600"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.checkBoxColonyClick()
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function() {
                                            return [
                                              _vm.colony == 601 &&
                                              _vm.item.products_status
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          "orange darken-2",
                                                        small: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    mdi-arrow-up-bold-box-outline\n                                "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-label",
                                                {
                                                  class:
                                                    _vm.colony === 600
                                                      ? ""
                                                      : "red--text",
                                                  attrs: { color: "red" }
                                                },
                                                [_vm._v("Colony:")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3501851089
                                    ),
                                    model: {
                                      value: _vm.colony,
                                      callback: function($$v) {
                                        _vm.colony = $$v
                                      },
                                      expression: "colony"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-0",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-start pt-1 ma-0 pa-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-left",
                                      attrs: { "flex-grow-0": "" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ma-0 font-weight-bold"
                                        },
                                        [
                                          _vm._v("Q.: "),
                                          _c(
                                            "span",
                                            {
                                              class:
                                                _vm.item.products_quantity == 0
                                                  ? "red--text"
                                                  : ""
                                            },
                                            [
                                              _vm.item.products_quantity === 0
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        color: "red",
                                                        small: ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-alarm-light")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.item.products_quantity
                                                )
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("warehouse-info", {
                                            attrs: { product: _vm.item }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-left ml-4",
                                      attrs: { "flex-grow-0": "" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-normal text-left ma-0 pl-1"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-file-tree")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.productCategoryShop === 1
                                                    ? "🌟 "
                                                    : ""
                                                ) + _vm._s(_vm.productCategory)
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " |\n                                "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-bullseye-arrow")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.productTargetCategory
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "flex-grow-0",
                                              attrs: { icon: "" },
                                              on: {
                                                click:
                                                  _vm.openEditProductCategoryDialog
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "default",
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v("mdi-playlist-edit")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-left ml-4 mr-1",
                                      attrs: { "flex-grow-0": "" }
                                    },
                                    [
                                      _vm.set && !_vm.hideSets
                                        ? _c("set-statistic", {
                                            attrs: {
                                              product: _vm.item,
                                              set: _vm.set
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("confirm", { ref: "confirm" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }