var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "1000" },
          attrs: {
            app: "",
            clipped: "",
            light: "",
            "disable-resize-watcher": "",
            "disable-route-watcher": ""
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _vm.favorites_menu.length > 0
            ? _c(
                "v-list",
                { staticClass: "pt-0 pb-0", attrs: { dense: "" } },
                _vm._l(_vm.favorites_menu, function(item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      attrs: { to: item.tag, dense: "" },
                      on: { click: function($event) {} }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        { attrs: { dense: "" } },
                        [
                          _c("v-list-item-title", {
                            attrs: { dense: "" },
                            domProps: { textContent: _vm._s(item.title) },
                            on: { click: _vm.clickMainMenu }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.favorites_menu.length > 0 ? _c("v-divider") : _vm._e(),
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "pt-0", attrs: { dense: "" } },
            _vm._l(_vm.main_menu, function(item) {
              return _c(
                "v-list-group",
                {
                  key: item.title,
                  attrs: { "prepend-icon": item.action, "no-action": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: { textContent: _vm._s(item.title) }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.active,
                    callback: function($$v) {
                      _vm.$set(item, "active", $$v)
                    },
                    expression: "item.active"
                  }
                },
                [
                  _vm._v(" "),
                  _vm._l(item.items, function(subItem) {
                    return _c(
                      "v-list-item",
                      {
                        key: subItem.title,
                        attrs: { to: subItem.tag, dense: "" }
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { on: { click: _vm.clickMainMenu } },
                              [_vm._v(_vm._s(subItem.title))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", ripple: "" },
                            on: {
                              click: function($event) {
                                return _vm.favoritesToggle(subItem)
                              }
                            }
                          },
                          [
                            _vm.isFavorite(subItem)
                              ? _c(
                                  "v-icon",
                                  { attrs: { color: "grey lighten-1" } },
                                  [
                                    _vm._v(
                                      "\n                            star_border\n                        "
                                    )
                                  ]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { color: "yellow darken-2" } },
                                  [
                                    _vm._v(
                                      "\n                            star\n                        "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
            color: "blue darken-3",
            dark: "",
            dense: ""
          }
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-toolbar-title",
            { staticClass: "ml-0 pl-4", staticStyle: { width: "20%" } },
            [_vm._v(_vm._s(_vm.pageTitle))]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticStyle: { width: "60%" } },
            [_c("search-bar")],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { attrs: { "justify-center": "" } },
            [
              _vm.failedJobs > 0
                ? _c(
                    "v-btn",
                    { attrs: { icon: "", href: "/failed-job" } },
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: { left: "", overlap: "", color: "red" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "badge",
                                fn: function() {
                                  return [
                                    _c("span", [_vm._v(_vm._s(_vm.failedJobs))])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2529843545
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            { attrs: { color: "green lighten-1" } },
                            [
                              _vm._v(
                                "\n                    group_work\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", "open-on-hover": false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: "change-password" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n                            Change password\n                        "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.logout } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n                            Logout\n                            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              staticStyle: { display: "none" },
                              attrs: {
                                id: "logout-form",
                                action: "/logout",
                                method: "POST"
                              }
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "_token" },
                                domProps: { value: _vm.token }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }