<template>
    <div>
        <v-data-table dense
                      :hide-default-footer="hideDefaultFooter"
                      :headers="headers"
                      :items="emailList"
                      class="scroll-table"
                      @click:row="showEmail">
            <template slot="item" slot-scope="props">
                <tr @click="showEmail(props.item)" :class="[{'font-weight-bold': !props.item.is_read}, 'text-left']">
                    <td class="px-1">{{ $moment(props.item.email_date).format('MM-DD-YYYY') }}</td>
                    <td class="px-1">{{ props.item.email_from }}</td>
                    <td class="px-1">{{ props.item.email_subject }}</td>
                </tr>
            </template>

        </v-data-table>
        <v-divider></v-divider>
        <v-row v-if="readEmail" class="text-left">
            <v-col>
                <div class="px-3">
                    <p class="mb-0"><span class="font-weight-bold">From: </span>{{ readEmail.email_from }}</p>
                    <p class="mb-0"><span class="font-weight-bold">To: </span>{{ readEmail.email_to }}</p>
                    <p class="mb-0"><span class="font-weight-bold">Date: </span>{{$moment(readEmail.email_date).format('MM-DD-YYYY') }}</p>
                    <p class="mb-0"><span class="font-weight-bold">Subject: </span>{{ readEmail.email_subject }}</p>
                    <v-divider></v-divider>
                    <v-card flat class="mt-2">
                        <p v-html="readEmail.email_body" v-linkified></p>
                    </v-card>
                </div>

            </v-col>
        </v-row>
    </div>
    
</template>

<script>
    export default {
        name: "Emails",
        props:{
            emailList:{
                type: Array
            }
        },
        data(){
            return {
                headers: [
                    {text: 'Date', value: 'date', align: 'left', sortable: true,  width: "15%"},
                    {text: 'From', value: 'email_from', align: 'left', sortable: false,  width: "15%"},
                    {text: 'Subject', value: 'email_subject', align: 'left', sortable: false},
                ],
                readEmail: null
            }
        },
        computed:{
            hideDefaultFooter(){
                if(this.emailList.length){
                    return this.emailList.length > 10 ? false : true
                }
                return true

            }
        },
        methods:{
            showEmail(item){
                this.readEmail = null
                axios.get(`/api/customers_email_body/${item.id}`)
                    .then(response => {
                        item.is_read = 1
                        this.readEmail = {...item, ...response.data  }
                        // console.log( this.readEmail )
                    })

            }
        }
    }
</script>

<style scoped>

</style>