<template>
    <div class="scroll-content width-100">
        <v-row no-gutters>
            <v-col class="d-flex ">
                <v-data-table
                        v-model="selectedProducts"

                        :footer-props="{
                            itemsPerPageOptions: pagination.rowsPerPageItems,
                            showFirstLastPage: true,
                           }"
                        :headers="headers"
                        :hide-default-header="productsPageConfig.hideDefaultHeader"
                        :items="products"
                        :loading="loading"
                        :options.sync="options"
                        :server-items-length="pagination.totalItems"
                        class="elevation-1 width-100 products-table"
                        dense
                        fixed-header
                        item-key="products_id"
                        show-select
                        sort-desc
                >
                    <template v-slot:top>
                        <v-divider></v-divider>
                        <v-toolbar class="pa-0 ma-0" color="white" dense flat>
                            <add-new-product/>
                            <v-divider class="ml-2 mr-0" style="float:left" vertical></v-divider>
                            <v-btn color="green" icon large @click="updateProductList">
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            <v-divider class="ml-0 mr-0" style="float:left" vertical></v-divider>
                            <v-row v-if="enableFilterWithSearch" class="ml-1" justify="space-around" style="max-width: 75%;">
                                <v-col class="pa-0" cols="12">
                                    <v-chip-group
                                            active-class="primary--text"
                                            show-arrows
                                    >
                                        <v-chip v-for="tag in tags" :key="tag">
                                            {{ tag }}
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>
                            </v-row>
                            <v-row
                                    v-else
                                    class="d-flex justify-start ml-2 mt-0 mr-2 full-width"
                                    flat
                                    tile
                            >
                                <tree-select
                                        v-if="! productsPageConfig.hideSmartCategory"
                                        v-model="smartFilterForm['category.categories_id']"
                                        :appendToBody="true"
                                        :auto-load-root-options="true"
                                        :normalizer="normalizer"
                                        :options="productCategories"
                                        :searchable="true"
                                        :show-count="true"
                                        clearable
                                        placeholder="Category"
                                        style="width: 250px; height: 40px;"
                                        zIndex="99999"
                                        @input="onChangeSmartFilter"
                                >
                                </tree-select>
                                <v-select
                                        v-if="! productsPageConfig.hideSmartStatus"
                                        v-model="smartFilterForm.products_status"
                                        :items="statuses"
                                        class="ml-2"
                                        clearable
                                        dense
                                        placeholder="Status"
                                        style="width: 150px; max-width:150px; margin-top: 5px;"
                                        @change="onChangeSmartFilter"
                                ></v-select>
                                <v-spacer></v-spacer>
                                <v-checkbox
                                        v-model="productsPageConfig.autoReloadSmartFilter"
                                        color="indigo darken-3"
                                        dense
                                        hide-details
                                        label="Auto reload"
                                        style="margin-top: 5px;"
                                ></v-checkbox>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-divider class="ml-2 mr-1" style="float:left" vertical></v-divider>
                            <v-btn class="mr-1" color="green" icon large @click="updateProductList">
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>
                            <v-btn small icon class="mr-2" color="green" @click="exportProducts"><v-icon>mdi-download</v-icon></v-btn>
                            <v-divider style="float:left" vertical></v-divider>
                            <v-menu
                                    offset-y
                                    open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            class="mx-2" color="grey"
                                            icon
                                            v-bind="attrs"
                                            x-small
                                            v-on="on"
                                    >
                                        <v-icon>mdi-tools</v-icon>
                                    </v-btn>
                                </template>
                                <v-list
                                        class="caption"
                                        dense
                                        nav
                                >
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideDefaultHeader" dense hide-details
                                                    label="Hide headers"/>
                                    </v-list-item>
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideAgeColumn" dense hide-details
                                                    label="Hide Age column"/>
                                    </v-list-item>
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideEbayColumn" dense hide-details
                                                    label="Hide eBay column"/>
                                    </v-list-item>
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideSmartCategory" dense hide-details
                                                    label="Hide category filter"/>
                                    </v-list-item>
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideSmartStatus" dense hide-details
                                                    label="Hide status filter"/>
                                    </v-list-item>
                                    <v-list-item class="caption" dense>
                                        <v-checkbox v-model="productsPageConfig.hideSets" dense hide-details
                                                    label="Hide sets"/>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-badge
                                    :content="filtersLength"
                                    :value="filtersLength"
                                    class="mr-1"
                                    color="green"
                                    overlap
                                    right
                            >
                                <span slot="badge" @click="getDataFromApi">{{ filtersLength }}</span>
                                <v-btn
                                        :color="(enableFilterWithSearch ? 'blue' : 'grey')"
                                        icon
                                        small
                                        @click.stop="filterDrawer = !filterDrawer"
                                >
                                    <v-icon v-if="!filtersLength"
                                    >
                                        mdi-filter-outline
                                    </v-icon>
                                    <v-icon v-else
                                    >
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </v-badge>
                            <v-switch
                                    v-model="enableFilterWithSearch"
                                    :hide-details="true"
                                    class="ml-1"
                                    dense
                            ></v-switch>
                        </v-toolbar>
                        <v-divider></v-divider>
                    </template>

                    <template
                            v-slot:item.data-table-select="{ isSelected, select }"
                    >
                        <v-simple-checkbox
                                color="green"
                        ></v-simple-checkbox>
                    </template>

                    <template slot="item" slot-scope="props">
                        <tr :key="props.item.products_id">
                            <td>
                                <v-simple-checkbox
                                        :value="isSelected(props.item.products_id)"
                                        color="green"
                                        @input="selectProduct($event, props.item)"
                                ></v-simple-checkbox>
                            </td>
                            <td>
                                <v-divider style="float:left" vertical></v-divider>
                                <div class="ma-auto">
                                    <a
                                            :class="(props.item.products_status ? 'green--text darken-4 font-weight-medium' : 'red--text')"
                                            @click.stop="openProductDialog(props.item)">
                                        {{ props.item.products_id }}
                                    </a>
                                </div>
                                <v-row align="center" justify="center" no-gutters>
                                    <v-col cols="12">
                                        <v-icon dense @click="openProductDescriptionDialog(props.item)">
                                            mdi-alpha-d-circle
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-icon dense small @click="openProductDefectsDialog(props.item)">mdi-tools
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-icon v-if="props.item.product_emails_count" color="yellow darken-4"
                                                dense @click="openProductDialog(props.item, 'tab-email')">mdi-email
                                        </v-icon>
                                    </v-col>
                                    <v-icon small @click.stop="openEditProductDialog(props.item)">
                                        mdi-square-edit-outline
                                    </v-icon>
                                </v-row>
                            </td>
                            <td>
                                <v-divider class="mr-2" style="float:left" vertical></v-divider>
                                <product-info
                                        :hideSets="productsPageConfig.hideSets"
                                        :item="props.item"
                                        :sets="sets"
                                        @editName="openEdit"
                                        @productChanged="productChanged"
                                />
                            </td>
                            <td v-if="!productsPageConfig.hideEbayColumn">
                                <v-divider style="float:left" vertical></v-divider>
                                <v-lazy style="height: 100%;">
                                    <v-row align="center" justify="center" no-gutters
                                           style="margin-top: auto; margin-bottom: auto; height: 100%;">
                                        <v-col
                                                v-for="(ebayCategory, i) in productEbayCategories(props.item)"
                                                :key="i"
                                                class="ma-0 pa-0 caption text-truncate"
                                                cols="12"
                                        >
                                            <span
                                                    v-if="ebayCategory.siteID === 0 && ebayCategoriesCom"
                                            >
                                                <v-autocomplete
                                                        :cache-items=true :items="ebayCategoriesCom"
                                                        :value="props.item.ebay_categories[0]"
                                                        class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                        dense
                                                        hide-details
                                                        item-text="name"
                                                        item-value="catid"
                                                        return-object
                                                        single-line
                                                        style="max-width:99%;"
                                                        @change="productEbayCategoryChanged($event, props.item)"
                                                >
                                                    <template v-slot:prepend>
                                                        <span
                                                                :class="( ebayCategory.catid === null ? 'red--text text--accent-3 font-weight-bold mt-1' : 'font-weight-bold mt-1')">US:</span>
                                                    </template>
                                                    <template v-slot:append>
                                                        <v-icon v-if="ebayCategory.catid === null"
                                                                class="mb-1 mt-1 mr-1"
                                                                color="red accent-3"
                                                                small>mdi-alarm-light</v-icon>
                                                    </template>
                                                </v-autocomplete>
                                            </span>
                                            <span
                                                    v-if="ebayCategory.siteID === 77 && ebayCategoriesDe"
                                            >
                                                <v-autocomplete
                                                        :cache-items=true :items="ebayCategoriesDe"
                                                        :value="props.item.ebay_categories[77]"
                                                        class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                        dense
                                                        hide-details
                                                        item-text="name"
                                                        item-value="catid"
                                                        return-object
                                                        single-line
                                                        style="max-width:99%;"
                                                        @change="productEbayCategoryChanged($event, props.item)"
                                                >
                                                    <template v-slot:prepend>
                                                        <span
                                                                :class="( ebayCategory.catid === null ? 'red--text text--accent-3 font-weight-bold mt-1' : 'font-weight-bold mt-1')">DE:</span>
                                                    </template>
                                                    <template v-slot:append>
                                                        <v-icon v-if="ebayCategory.catid === null"
                                                                class="mb-1 mt-1 mr-1"
                                                                color="red accent-3"
                                                                small>mdi-alarm-light</v-icon>
                                                    </template>
                                                </v-autocomplete>
                                            </span>
                                            <span
                                                    v-if="ebayCategory.siteID === 3 && ebayCategoriesCouk"
                                            >
                                                <v-autocomplete
                                                        :cache-items=true :items="ebayCategoriesCouk"
                                                        :rules="[ value => !!value || '' ]"
                                                        :value="props.item.ebay_categories[3]"
                                                        class="caption font-weight-regular pl-1 ma-0 pa-0"
                                                        dense
                                                        hide-details
                                                        item-text="name"
                                                        item-value="catid"
                                                        return-object
                                                        single-line
                                                        style="max-width:99%;"
                                                        @change="productEbayCategoryChanged($event, props.item)"
                                                >
                                                    <template v-slot:prepend>
                                                        <span
                                                                :class="( ebayCategory.catid === null ? 'red--text text--accent-3 font-weight-bold mt-1' : 'font-weight-bold mt-1')">UK:</span>
                                                    </template>
                                                    <template v-slot:append>
                                                        <v-icon v-if="ebayCategory.catid === null"
                                                                class="mb-1 mt-1 mr-1"
                                                                color="red accent-3"
                                                                small>mdi-alarm-light</v-icon>
                                                    </template>
                                                </v-autocomplete>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-lazy>
                            </td>
                            <td>
                                <v-divider style="float:left" vertical></v-divider>
                                <v-lazy>
                                    <product-price
                                            :item="props.item"
                                            @productChanged="productChanged"
                                    ></product-price>
                                </v-lazy>
                            </td>
                            <td v-if="!productsPageConfig.hideAgeColumn">
                                <v-divider style="float:left" vertical></v-divider>
                                <v-lazy style="height: 100%;">
                                    <div class="d-flex flex-column justify-center" style="height: 100%;">
                                        <p class="text-center ma-auto">
                                            <age-component v-if="props.item.products_date_available"
                                                           :item="props.item"></age-component>
                                        </p>
                                    </div>
                                </v-lazy>
                            </td>
                            <td>
                                <v-divider class="mr-2" style="float:left" vertical></v-divider>
                                <v-row align="center" justify="center" no-gutters
                                       style="margin-top: auto; margin-bottom: auto; height: 100%;">
                                    <v-col cols="6">
                                        <div>
                                            <v-row no-gutters>
                                                <v-col v-if="(props.item.products_for_ebay && props.item.products_for_ebay[77])"
                                                       class="ma-0 pa-0"
                                                       cols="12">
                                                    <v-checkbox :input-value="props.item.products_for_ebay[77].enabled"
                                                                class="ma-0 pa-0"
                                                                color="cyan"
                                                                dense
                                                                hide-details
                                                                readonly
                                                                @click.stop="changeEbayDeStatus(props.item, $event)">
                                                        <template #prepend>
                                                            <v-label
                                                                    v-if="(props.item.products_on_ebay && props.item.products_on_ebay[77])"
                                                            >
                                                                <div class="mt-1"><a class="red--text"
                                                                                     @click="openEbayItem(props.item.products_on_ebay[77].ItemID)">De: </a>
                                                                </div>
                                                            </v-label>
                                                            <v-label
                                                                    v-else
                                                            >
                                                                <div class="mt-1">De:&nbsp;</div>
                                                            </v-label>
                                                        </template>
                                                    </v-checkbox>
                                                </v-col>
                                                <v-col v-if="(isGTC(props.item) && props.item.products_for_ebay && props.item.products_for_ebay[0])"
                                                       class="ma-0 pa-0"
                                                       cols="12">
                                                    <v-checkbox
                                                            :input-value="props.item.products_for_ebay[0].enabled"
                                                            class="ma-0 pa-0"
                                                            color="cyan"
                                                            dense
                                                            hide-details
                                                            readonly
                                                            @click.stop="changeEbayComStatus(props.item, $event)"
                                                    >
                                                        <template #prepend>
                                                            <v-label
                                                                    v-if="props.item.products_on_ebay && props.item.products_on_ebay[0] && props.item.products_on_ebay[0].ItemID"
                                                            >
                                                                <div class="mt-1"><a class="red--text"
                                                                                     @click="openEbayItem(props.item.products_on_ebay[0].ItemID)">Com: </a>
                                                                </div>
                                                            </v-label>
                                                            <v-label
                                                                    v-else
                                                                    style="width: 50px;">
                                                                <div class="mt-1">Com:&nbsp;</div>
                                                            </v-label>
                                                        </template>
                                                    </v-checkbox>
                                                </v-col>
                                                <!--<v-col v-if="(isGTC(props.item) && props.item.products_for_ebay && props.item.products_for_ebay[3])" class="ma-0 pa-0"
                                                       cols="12">
                                                  <v-checkbox
                                                      :input-value="props.item.products_for_ebay[3].enabled"
                                                      class="ma-0 pa-0"
                                                      color="cyan"
                                                      dense
                                                      hide-details
                                                      readonly
                                                      @click.stop="changeEbayUkStatus(props.item, $event)"
                                                  >
                                                    <template v-slot:prepend>
                                                      <v-label
                                                          v-if="props.item.products_on_ebay && props.item.products_on_ebay[3] && props.item.products_on_ebay[3].ItemID"
                                                      >
                                                        <div class="mt-1"><a class="red--text"
                                                                             @click="openEbayItem(props.item.products_on_ebay[3].ItemID)">Uk: </a></div>
                                                      </v-label>
                                                      <v-label
                                                          v-else
                                                          style="width: 50px;"
                                                      >
                                                        <div class="mt-1">Uk:&nbsp;</div>
                                                      </v-label>
                                                    </template>
                                                  </v-checkbox>
                                                </v-col>-->
                                                <v-col v-if="(isGTC(props.item) && props.item.products_for_ebay && props.item.products_for_ebay[71])"
                                                       class="ma-0 pa-0"
                                                       cols="12">
                                                    <v-checkbox
                                                            :input-value="props.item.products_for_ebay[71].enabled"
                                                            class="ma-0 pa-0"
                                                            color="cyan"
                                                            dense
                                                            hide-details
                                                            readonly
                                                            @click.stop="changeEbayFrStatus(props.item, $event)"
                                                    >
                                                        <template v-slot:prepend>
                                                            <v-label
                                                                    v-if="props.item.products_on_ebay && props.item.products_on_ebay[71] && props.item.products_on_ebay[71].ItemID"
                                                            >
                                                                <div class="mt-1"><a class="red--text"
                                                                                     @click="openEbayItem(props.item.products_on_ebay[71].ItemID)">Fr: </a>
                                                                </div>
                                                            </v-label>
                                                            <v-label
                                                                    v-else
                                                                    style="width: 50px;"
                                                            >
                                                                <div class="mt-1">Fr:&nbsp;</div>
                                                            </v-label>
                                                        </template>
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn :color="props.item.products_status ? 'green darken-4' : 'deep-orange accent-4'"
                                               icon x-small
                                               @click="changeProductStatus(props.item)">
                                            <v-icon>mdi-circle-slice-8</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </td>
                            <td>
                                <v-divider style="float:left" vertical></v-divider>
                                <v-lazy style="height: 100%;">
                                    <div class="d-flex flex-column justify-center" style="height: 100%;">
                                        <img v-if="props.item.products_image && showImage(props.item)"
                                             :src="`https://images.cameramate.com/${props.item.products_id}/${props.item.products_image}`"
                                             class="text-center ma-auto"
                                             style="max-height: 100px; min-height: 100px; height: 100px;"
                                             @click="openProductDialog(props.item, 'tab-images')">
                                        <p v-else class="text-center ma-auto">
                                            No image
                                        </p>
                                    </div>
                                </v-lazy>
                            </td>
                        </tr>
                    </template>

                    <template v-if="loading" v-slot:body.append>
                        <tr>
                            <td :colspan="headers.length+1" class="pa-0" style="height: 2px;">
                                <v-progress-linear :height="2" color="blue" indeterminate></v-progress-linear>
                            </td>
                        </tr>
                    </template>

                </v-data-table>
                <div class="filters-wrapper">
                    <product-filters
                            :drawer="filterDrawer"
                            :enableFilterWithSearch="enableFilterWithSearch"
                            :filtersLength="filtersLength"
                            @OnEnableFilterWithSearch="enableFilterWithSearch = true"
                            @clearFilters="clearFilters"
                            @closeMenu="closeFiltersMenu"
                            @filterProducts="filterProducts"/>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog.name" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">Edit product name</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea ref="name" v-model="editedItem.descriptions.products_name"
                                            auto-grow dense
                                            hide-details
                                            rows="2"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" small text @click="closeEdit('name')">Cancel</v-btn>
                    <v-btn color="success" text @click="saveName">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.description" max-width="600px" persistent>
            <v-card style="min-height: 50vh;">
                <v-card-title>
                    <span class="headline">Edit product description</span>
                </v-card-title>
                <v-card-text style="height: 40vh;">
                    <v-textarea ref="description" v-model="editedItem.descriptions.products_description"
                                auto-grow
                                dense height="35vh"
                                hide-details
                                rows="2"/>
                </v-card-text>
                <v-card-actions style="bottom: 0; position: absolute; width: 100%;">
                    <v-spacer/>
                    <v-btn color="primary" small text @click="closeEdit('description')">Cancel</v-btn>
                    <v-btn color="success" text @click="saveDescription">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.note" max-width="600px" persistent>
            <v-card style="min-height: 50vh;">
                <v-card-title>
                    <span class="headline">Edit product note</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea ref="note" v-model="editedItem.product_note.products_note"
                                            auto-grow dense
                                            hide-details
                                            rows="2"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" small text @click="closeEdit('note')">Cancel</v-btn>
                    <v-btn color="success" text @click="saveProductNote">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-if="selectedProducts.length" v-model="groupActionDialog" persistent scrollable width="35vw">

            <template v-slot:activator="{ on }">
                <v-btn v-if="selectedProducts.length" bottom color="yellow" fixed right v-on="on">
                    Change selected ({{ selectedProducts.length }})
                </v-btn>
            </template>
            <group-action-dialog
                    :selectedProducts="selectedProducts"
                    @closeGroupActionDialog="closeGroupActionDialog"
                    @updateProduct="updateProduct"
            />
        </v-dialog>

        <v-dialog :key="'edit_product_category_'+editedItem.products_id" v-model="showEditProductCategoryDialog"
                  :fullscreen="false" :scrollable="true"
                  hide-overlay name="EditProductCategoryDialog" width="70vw"
                  @click:outside="closeEditProductCategoryDialog">
            <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-toolbar color="blue darken-3" dark dense>
                        <v-toolbar-title>Edit product categories</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark icon @click.stop="closeEditProductCategoryDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <edit-product-category :product="editedItem"/>
                </v-card-text>
            </v-card>
        </v-dialog>

        <confirm ref="confirm"/>
    </div>
</template>

<script>
import {mapCacheActions} from "vuex-cache";
import AgeComponent from './components/AgeComponent'
import GroupActionDialog from './components/GroupActionDialog'
import ProductInfo from './components/ProductInfo'
import ProductPrice from './components/ProductPrice'
import ProductFilters from "./components/ProductFilters";
import SingleProduct from './product/SingleProduct'
import {mapActions, mapGetters} from 'vuex'
import EventBus from "@/event-bus";
import EditProduct from "./components/EditProduct";
import AddNewProduct from "./components/AddNewProduct";
import EditProductCategory from "./components/EditProductCategory"
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'

import Confirm from '@/components/Confirm'

export default {
    name: "Products",
    data() {
        return {
            tags: [],
            dialog: {
                name: false,
                description: false,
                note: false,
                code: false,
                defects: false
            },
            filterDrawer: false,
            filtersLength: 0,
            editedIndex: -1,
            editedItem: {
                descriptions: {products_description: '', products_name: ''},
                product_note: {products_note: ''},
                products_id: null
            },
            defaultItem: {
                descriptions: {products_description: '', products_name: ''},
                product_note: {products_note: ''},
                products_id: null
            },
            headers: [
                {
                    text: 'ID',
                    value: 'products_id',
                    align: 'center',
                    sortable: true,
                    width: "75px"
                },
                {
                    text: 'Product',
                    value: 'info',
                    align: 'center',
                    sortable: false,
                    width: "50vw"
                },
                {
                    text: 'eBay categories',
                    value: 'ebay_categories',
                    align: 'center',
                    sortable: false,
                    width: "20vw"
                },
                {
                    text: 'Price',
                    value: 'price',
                    align: 'center',
                    sortable: false,
                    width: "150px"
                },
                {
                    text: 'Age',
                    value: 'age',
                    align: 'center',
                    sortable: false,
                    width: "65px"
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: "105px"
                },
                {
                    text: 'Image',
                    value: 'image',
                    align: 'center',
                    sortable: false,
                    width: "130px"
                }
            ],
            loading: false,
            products: [],
            originalProducts: [],
            options: {},
            defaultOption: {
                page: 1,
                itemsPerPage: 10
            },
            totalProducts: 0,
            pagination: {
                descending: true,
                page: 1,
                rowsPerPage: 10,
                totalItems: 0,
                rowsPerPageItems: [10, 15, 20, 50, 100]
            },
            showProductDialog: {},
            productDialog: false,
            groupActionDialog: false,
            selectedProducts: [],
            sets: {},
            filtersRequest: {},
            enableFilterWithSearch: false,
            productsPageConfig: {
                hideDefaultHeader: false,
                hideAgeColumn: false,
                hideSmartCategory: false,
                hideSmartStatus: false,
                autoReloadSmartFilter: true,
                hideEbayColumn: true,
                hideSets: false,
            },
            showEditProductCategoryDialog: false,
            smartFilterForm: {
                products_status: null,
                'category.categories_id': null
            },
            statuses: [{value: 1, text: "Status: On"}, {value: 0, text: "Status: Off"}],
            filledSmartFilter: {},
            chagedSmartFilter: false,
            productsOptions: null,
            ebay_categories: {
                '0': {
                    catid: null,
                    siteID: 0,
                    name: null
                },
                '77': {
                    catid: null,
                    siteID: 77,
                    name: null
                },
                '3': {
                    catid: null,
                    siteID: 3,
                    name: null
                }
            },
        }
    },
    computed: {
        products_: {
            get() {
                return JSON.parse(JSON.stringify(this.products));
            },
            set(optionValue) {
            },
        },
        ...mapGetters([
            'globalSearchParams',
            'productOptions',
            'productCategories',
            'ebay_global_sites',
            'ebayCategoriesCom',
            'ebayCategoriesDe',
            'ebayCategoriesCouk'
        ]),
        request: {
            get() {
                let searchParam = {};
                if (this.globalSearchParams && this.globalSearchParams.productParams) {
                    searchParam = this.globalSearchParams.productParams;
                }

                //if (this.enableFilterWithSearch) {
                searchParam = {...searchParam, ...this.filtersRequest}
                //}

                return searchParam
            },
            set(val) {
                // console.log(val)

                this.getDataFromApi()
            }

        },
        productOptionsArray() {
            if (this.productOptions) {
                return Object.keys(this.productOptions).map(key => {
                    return this.productOptions[key];
                });

            }

        }
    },
    watch: {
        enableFilterWithSearch: {
            handler() {
                this.chagedSmartFilter = true
            },
        },
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
        smartFilterForm: {
            handler() {
                if (this.productsPageConfig.autoReloadSmartFilter) {
                    this.updateProductList()
                }
            },
            deep: true,
        },
        productsPageConfig: {
            handler() {
                localStorage.setItem('products_page_config', JSON.stringify(this.productsPageConfig))
                this.headers[4].class = this.productsPageConfig.hideAgeColumn ? 'hided' : ''
                this.headers[2].class = this.productsPageConfig.hideEbayColumn ? 'hided' : ''

            },
            deep: true,
        },
    },
    mounted() {
        EventBus.$on('reloadProducts', () => {
            this.getDataFromApi()
        });

        EventBus.$on('searchProduct', () => {
            this.resetProductsCurrentPage()
        });


        EventBus.$on('saveProductCategory', (item) => {
            this.saveProductCategory(item)
        });

        EventBus.$on('openEditProductCategoryDialog', (item) => {
            this.openEditProductCategoryDialog(item)
        });
        EventBus.$on('closeEditProductCategoryDialog', () => {
            this.closeEditProductCategoryDialog()
        });
        EventBus.$on('getSet', (item) => {
            this.getSet(item)
        });

        if (localStorage.getItem('products_page_config')) {
            this.productsPageConfig = JSON.parse(localStorage.getItem('products_page_config'));
        }

        const filledSmartFilter = localStorage.getItem("productsSmartFilters");
        if (filledSmartFilter) {
            /*setTimeout(() => {*/
            console.log('setTimeout - smartFilterForm')
            this.smartFilterForm = JSON.parse(filledSmartFilter)
            this.onChangeSmartFilter()
            /*}, 1000)*/

        }

        this.initProductOptions()
    },
    destroyed() {
        this.$store.dispatch('saveSearchRequest', null);
    },
    methods: {
        isGTC(product) {
            return this.getAttributeValueById(product, 8) === '30'
        },
        getAttributeValueById(product, id) {
            if (product.products_attributes[id]) {
                return product.products_attributes[id].options_values_id.toString()
            } else return ''
        },
        showImage(product) {
            if (product) {
                let diffDays = this.$moment().diff(this.$moment(product.products_date_available), "days")
                return !(diffDays > 180 && product.products_status === 0 && product.products_quantity === 0 && product.category && product.category.categories_id === 218);
            }
            return false;
        },
        productEbayCategoryChanged(event, product) {
            let editedItem = _.cloneDeep(product)
            this.editedIndex = this.products.indexOf(product)
            editedItem.ebay_categories[event.siteID] = Object.assign({}, event)
            this.updateProduct(this.difference(editedItem, this.products[this.editedIndex], ['products_id', 'ebay_categories'])).then(() => {
                this.products[this.editedIndex].ebay_categories = editedItem.ebay_categories
            })
        },
        productEbayCategories(product) {
            if (product.ebay_categories) {
                return {...this.ebay_categories, ..._.keyBy(product.ebay_categories, 'siteID')}
            } else {
                return this.ebay_categories
            }
        },
        normalizer(node) {
            if (node && node.descriptions) {
                return {
                    id: node.descriptions.categories_id,
                    label: (node.categories_shop === 1 ? '🌟 ' : '') + node.descriptions.categories_name,
                    children: node.children
                }
            }
        },
        async loadOptions({action}) {
            if (action === LOAD_ROOT_OPTIONS) {
                this.productsOptions = this.productCategories;
            }
        },
        onChangeSmartFilter() {
            this.chagedSmartFilter = true
            localStorage.setItem('productsSmartFilters', JSON.stringify(this.smartFilterForm))
            this.filledSmartFilter = Object.keys(this.smartFilterForm)
                .filter(e => (this.smartFilterForm[e] !== null && this.smartFilterForm[e] !== undefined && typeof this.smartFilterForm[e] !== 'object'))
                .reduce((o, e) => {
                    o[e] = this.smartFilterForm[e]
                    return o;
                }, {});
            this.filterProducts({'filledFilters': this.filledSmartFilter, "filledAttr": []})
        },
        resetProductsCurrentPage() {
            if (this.options.page === 1) {
                this.getDataFromApi()
            } else {
                this.options.page = 1
            }
        },
        isSelected(productsId) {
            return _.findIndex(this.selectedProducts, ['products_id', productsId]) !== -1;
        },
        selectProduct(event, product) {
            if (event) {
                this.selectedProducts.push(product)
            } else {
                this.selectedProducts = _.remove(this.selectedProducts, function (n) {
                    return n.products_id !== product.products_id;
                })
            }
        },
        closeGroupActionDialog() {
            this.groupActionDialog = false;
        },
        openProductDialog(item, activeTab = 'tab-check') {
            this.editedIndex = this.products.indexOf(item)
            EventBus.$emit('openProductDialog', item, activeTab);
        },
        openProductDescriptionDialog(item) {
            this.editedIndex = this.products.indexOf(item)
            EventBus.$emit('openProductDescriptionDialog', item);
        },
        openEditProductDialog(item) {
            this.editedIndex = this.products.indexOf(item)
            EventBus.$emit('openEditProductDialog', item);
        },
        openProductSpecificsDialog(item) {
            this.editedIndex = this.products.indexOf(item)
            EventBus.$emit('openProductSpecificsDialog', item);
        },
        openProductDefectsDialog(item) {
            this.editedIndex = this.products.indexOf(item)
            EventBus.$emit('showProductDefect', item);
        },
        closeProductDialog() {
            this.productDialog = false;
        },
        openEditProductCategoryDialog(item) {
            this.editedIndex = this.products.indexOf(item)
            this.editedItem = _.cloneDeep(item)
            this.showEditProductCategoryDialog = true;
        },
        closeEditProductCategoryDialog() {
            this.showEditProductCategoryDialog = false;
            setTimeout(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            }, 300)
        },
        saveProductCategory(item) {
            if (this.editedIndex > -1) {
                this.updateProduct(this.difference(item, this.products[this.editedIndex], ['products_id'])).then(() => {
                    this.products[this.editedIndex].category.categories_id = item.category.categories_id
                    this.products[this.editedIndex].targetcatid = item.targetcatid
                    this.closeEditProductCategoryDialog()
                })
            }
        },
        openEdit(item, what) {
            this.editedIndex = this.products.indexOf(item)
            this.editedItem = _.cloneDeep(item)
            this.dialog[what] = true

            setTimeout(() => {
                // console.log(this.$refs)
                this.$refs[what].focus();
            }, 300)

        },
        closeEdit(what) {
            this.dialog[what] = false
            setTimeout(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            }, 300)
        },
        saveDescription() {
            if (this.editedIndex > -1) {
                this.products[this.editedIndex].descriptions.products_description = this.editedItem.descriptions.products_description
                this.updateProduct(this.difference(this.editedItem, this.products[this.editedIndex], ['products_id'])).then(() => {
                    this.products[this.editedIndex].descriptions.products_description = this.editedItem.descriptions.products_description
                    this.closeEdit('description')
                })
            }
        },
        saveName() {
            if (this.editedIndex > -1) {
                this.updateProduct(this.difference(this.editedItem, this.products[this.editedIndex], ['products_id'])).then(() => {
                    this.products[this.editedIndex].descriptions.products_name = this.editedItem.descriptions.products_name
                    this.closeEdit('name')
                })
            }
        },
        saveProductNote() {
            if (this.editedIndex > -1) {
                this.updateProduct(this.difference(this.editedItem, this.products[this.editedIndex], ['products_id'])).then(() => {
                    this.products[this.editedIndex].product_note.products_note = this.editedItem.product_note.products_note
                    this.closeEdit('note')
                })
            }
        },
        updateProductList() {
            if (this.chagedSmartFilter) {
                this.resetProductsCurrentPage()
            } else {
                this.getDataFromApi()
            }
        },
        async getDataFromApi() {
            if (this.loading) {
                return;
            }

            this.selectedProducts = []
            this.loading = true;

            if (!this.enableFilterWithSearch) {
                this.onChangeSmartFilter()
            }
            this.chagedSmartFilter = false

            let searchParamOptions = this.options

            const searchParam = {
                'with':
                    [
                        'descriptions',
                        'products_on_ebay',
                        'products_for_ebay',
                        'products_attributes.options_value',
                        'category',
                        'price',
                        'start_price',
                        'product_set',
                        'discount',
                        'product_note',
                        'ebay_categories',
                        'stocks_quantity.stocks_move_to_full.parentStocks',
                        'purchase_price'
                    ],
                'withCount': ['product_emails'],
                'pagesize': searchParamOptions.itemsPerPage,
                'current_page': searchParamOptions.page,
                'sortdatafield': ((searchParamOptions.sortBy && searchParamOptions.sortBy[0]) ? searchParamOptions.sortBy[0] : ''),
                'sortorder': ((searchParamOptions.sortDesc && searchParamOptions.sortDesc[0]) ? 'desc' : 'asc'),
            }

            axios.get('/api/products',
                {
                    params: {...searchParam, ...this.request},
                })
                .then(response => {
                    response.data.data.forEach(function (element) {
                        if (element.products_attributes) element.products_attributes = _.keyBy(element.products_attributes, 'options_id')
                        if (element.price) element.price = _.keyBy(element.price, 'currency')
                        if (element.start_price) element.start_price = _.keyBy(element.start_price, 'currency')

                        if (element.products_for_ebay) element.products_for_ebay = _.keyBy(element.products_for_ebay, 'siteID')
                        if (element.products_on_ebay) element.products_on_ebay = _.keyBy(element.products_on_ebay, 'siteID')
                        if (element.ebay_categories) element.ebay_categories = _.keyBy(element.ebay_categories, 'siteID')

                    });
                    this.products = JSON.parse(JSON.stringify(response.data.data));
                    this.originalProducts = JSON.parse(JSON.stringify(response.data.data));
                    this.pagination.totalItems = response.data.total
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        async downloadDataFromApi() {
          let searchParamOptions = this.options

          const searchParam = {
            'download': true,
            'with':
                [
                  'descriptions',
                  'products_on_ebay',
                  'products_for_ebay',
                  'products_attributes.options_value',
                  'category',
                  'price',
                  'start_price',
                  'product_set',
                  'discount',
                  'product_note',
                  'ebay_categories',
                  'stocks_quantity.stocks_move_to_full.parentStocks',
                  'purchase_price'
                ],
            'withCount': ['product_emails'],
            'pagesize': searchParamOptions.itemsPerPage,
            'current_page': searchParamOptions.page,
            'sortdatafield': ((searchParamOptions.sortBy && searchParamOptions.sortBy[0]) ? searchParamOptions.sortBy[0] : ''),
            'sortorder': ((searchParamOptions.sortDesc && searchParamOptions.sortDesc[0]) ? 'desc' : 'asc'),
          }

          return axios.get('/api/products',
              {
                params: {...searchParam, ...this.request},
                responseType: 'blob'
              })
        },
        getFilterTagValue(key, value) {
            let tag_value = '';
            switch (key) {
                case 'products_weight':
                    tag_value = `Weight: ${value}`;
                    break;
                case 'products_status':
                    tag_value = `Status: ${value ? 'On' : 'Off'}`;
                    break;
                case 'discount.persent':
                    tag_value = `Disc.: ${value}`;
                    break;
                case 'category.categories_id':

                    if (!this.productCategories) break

                    const searchcategory = (fn) => (obj) =>
                        Array.isArray(obj)
                            ? obj.length === 0
                                ? null
                                : searchcategory(fn)(obj [0]) || searchcategory(fn)(obj.slice(1))
                            : fn(
                                obj,
                                () => searchcategory(fn)(obj.children || []),
                                () => obj
                            )

                    const findcategory = (target, obj) => searchcategory(
                        (node, next, found) => node.categories_id === target ? found() : next(),
                    )(this.productCategories)

                    let curCategory = findcategory(value, this.productCategories)
                    if (curCategory) {
                        tag_value = `Category: ${curCategory.descriptions.categories_name}`;
                    }
                    break;
                case 'targetcatid':
                    const searchtargetcatid = (fn) => (obj) =>
                        Array.isArray(obj)
                            ? obj.length === 0
                                ? null
                                : searchtargetcatid(fn)(obj [0]) || searchtargetcatid(fn)(obj.slice(1))
                            : fn(
                                obj,
                                () => searchtargetcatid(fn)(obj.children || []),
                                () => obj
                            )

                    const findtargetcatid = (target, obj) => searchtargetcatid(
                        (node, next, found) => node.categories_id === target ? found() : next(),
                    )(this.productCategories)

                    let curTarget = findtargetcatid(value, this.productCategories)
                    if (curTarget) {
                        tag_value = `Target: ${curTarget.descriptions.categories_name}`;
                    }
                    break;
                default:
                    tag_value = value;
                    break;
            }
            return tag_value;


        },
        getAttrTagValue(item) {
            let tag_value = '';

            if (this.productOptionsArray) {
                // console.log(this.productOptionsArray);
                const optionObject = this.productOptionsArray.filter(obj => {
                    return obj.products_options_id === item.options_id
                });

                // console.log(optionObject);

                if (optionObject) {
                    const curValue = optionObject[0].options_value.find(obj => {
                        return obj.products_options_values_id.toString() === item.value.toString();
                    });
                    if (curValue) {
                        // console.log(optionObject);
                        tag_value = `${optionObject[0].products_options_name}: ${curValue.products_options_values_name}`
                    }
                }
            }

            return tag_value;

        },
        filterProducts({filledFilters, filledAttr}) {

            // console.log(filledAttr)

            this.filtersLength = Object.keys(filledFilters).length + filledAttr.length
            let i = 1;
            let uri = {};
            this.tags = []
            let tag_value = ''
            if (Object.entries(filledFilters)) {
                for (const [key, value] of Object.entries(filledFilters)) {
                    tag_value = this.getFilterTagValue(key, value);
                    if (tag_value !== '') {
                        this.tags.push(tag_value);
                        uri[`filterGroups[${i}][field]`] = key;
                        uri[`filterGroups[${i}][filters][0][value]`] = value;
                        uri[`filterGroups[${i}][filters][0][condition]`] = 'EQUAL';
                        uri[`filterGroups[${i}][filters][0][type]`] = 'numericfilter';
                        uri[`filterGroups[${i}][filters][0][operator]`] = 'or';
                        uri[`filterGroups[${i}][operator]`] = 'and';
                        i++;
                    }
                }
            }

            if (filledAttr) {
                filledAttr.forEach(item => {
                    tag_value = this.getAttrTagValue(item);
                    if (tag_value !== '') {
                        this.tags.push(tag_value);
                        uri[`filterGroups[${i}][field]`] = 'products_attributes.options_id';
                        uri[`filterGroups[${i}][filters][0][value]`] = item.options_id;
                        uri[`filterGroups[${i}][filters][0][condition]`] = 'EQUAL';
                        uri[`filterGroups[${i}][filters][0][type]`] = 'numericfilter';
                        uri[`filterGroups[${i}][filters][0][operator]`] = 'or';
                        uri[`filterGroups[${i}][operator]`] = 'and';
                        i++;
                        uri[`filterGroups[${i}][field]`] = 'products_attributes.options_values_id';
                        uri[`filterGroups[${i}][filters][0][value]`] = item.value;
                        uri[`filterGroups[${i}][filters][0][condition]`] = 'EQUAL';
                        uri[`filterGroups[${i}][filters][0][type]`] = 'numericfilter';
                        uri[`filterGroups[${i}][filters][0][operator]`] = 'or';
                        uri[`filterGroups[${i}][operator]`] = 'and';
                        i++;
                    }
                });

            }

            this.filtersRequest = {...uri};

            if (this.enableFilterWithSearch) {
                this.resetProductsCurrentPage()
            }

            this.closeFiltersMenu()
        },
        closeFiltersMenu() {
            this.filterDrawer = false
        },
        clearFilters() {
            this.filtersLength = 0
        },
        difference(object, base, excluded = []) {
            return _.transform(object, (result, value, key) => {
                if (!_.isEqual(value, base[key]) || excluded.includes(key)) {
                    result[key] = value
                }
            });
        },
        updateProduct(product, resolve = null, reject = null) {
            if (_.size(product) === 1) {
                return new Promise((resolve, reject) => {
                })
            }
            //return new Promise((resolve, reject) => {  })
            return axios
                .put(`/api/products/${product.products_id}`, product)
                .then((response) => {
                    if (typeof resolve === 'function') {
                        resolve(response)
                    }
                })
                .catch(error => console.log(error))
        },
        productChanged(editedProduct) {
            // console.log(editedProduct)
            this.updateProduct(editedProduct)
        },
        addSelectedProduct(val) {
            console.log(val)
        },
        printProductBarcode(products_id) {
            EventBus.$emit('printProductBarcode', products_id);
        },
        async replicateProduct(product) {
            if (await this.$refs.confirm.open('Product replication', 'Are you sure?', {color: 'success'})) {
                axios
                    .get(`/api/replicate_product/${product.products_id}`)
                    .then((response) => {
                        this.resetProductsCurrentPage()
                    })
                    .catch(error => console.log(error))
            }
        },
        openEbayItem(ItemId) {
            window.open("http://cgi.ebay.com/ws/eBayISAPI.dll?ViewItem&ssPageName=STRK:MESE:IT&item=" + ItemId, '_blank')
        },
        ...mapCacheActions(['initProductOptions']),
        async changeProductStatus(product) {
            this.editedIndex = this.products.indexOf(product)
            if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                let forSave = {products_id: product.products_id, products_status: !product.products_status}
                let tmpThisProduct = this.products[this.editedIndex]
                await this.updateProduct(forSave, () => tmpThisProduct.products_status = !product.products_status)
            }
            this.editedIndex = -1
        },
        async changeEbayDeStatus(product, event) {
            this.editedIndex = this.products.indexOf(product)
            //if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                product.products_for_ebay[77].enabled = !product.products_for_ebay[77].enabled
                let forSave = {
                    products_id: product.products_id,
                    is_eBayDE: !product.is_eBayDE,
                    products_for_ebay: product.products_for_ebay
                }
                await this.updateProduct(forSave)
            //}
            this.editedIndex = -1
        },
        async changeEbayComStatus(product, event) {
            this.editedIndex = this.products.indexOf(product)
            //if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                product.products_for_ebay[0].enabled = !product.products_for_ebay[0].enabled
                let forSave = {products_id: product.products_id, products_for_ebay: product.products_for_ebay}
                await this.updateProduct(forSave)
            //}
            this.editedIndex = -1
        },
        async changeEbayUkStatus(product, event) {
            this.editedIndex = this.products.indexOf(product)
            //if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                product.products_for_ebay[3].enabled = !product.products_for_ebay[3].enabled
                let forSave = {products_id: product.products_id, products_for_ebay: product.products_for_ebay}
                await this.updateProduct(forSave)
            //}
            this.editedIndex = -1
        },
        async changeEbayFrStatus(product, event) {
            this.editedIndex = this.products.indexOf(product)
            //if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                product.products_for_ebay[71].enabled = !product.products_for_ebay[71].enabled
                let forSave = {products_id: product.products_id, products_for_ebay: product.products_for_ebay}
                await this.updateProduct(forSave)
            //}
            this.editedIndex = -1
        },
        async confirm(product, property, event) {
            if (await this.$refs.confirm.open('Change Product', 'Are you sure?', {color: 'success'})) {
                let forSave = {products_id: product.products_id}
                forSave[property] = event
                this.updateProduct(forSave).then(() => {
                    product[property] = event
                }).catch(() => {
                    product[property] = !event
                })
            } else {
                product[property] = !event
            }
        },
        async getSet(item) {
            if (item.product_set && item.product_set[0] && item.product_set[0].set_id) {
                /*if (sets[this.item.product_set[0].set_id]) {
                    return this.sets[this.item.product_set[0].set_id]
                } else {*/
                await axios.get('/api/sets/' + item.product_set[0].set_id + '?with_cache=0')
                    .then(response => {
                        console.log('getSet ', response.data)
                        this.sets[item.product_set[0].set_id] = response.data
                    });
                /*}*/
            }
        },
      async exportProducts() {
        this.request.with = []
        this.request.withCount = []
        this.request.without = ['duty_date', 'orders_status_name']
        this.loading = true;
        this.downloadDataFromApi(this.request, this.options)
            .then(response => {
              const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/^"(.*)"$/, '$1');
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();// you need to remove that elelment which is created before.
            }).finally(() => {
              this.loading = false;
            })
      },
    },
    components: {
        AgeComponent,
        GroupActionDialog,
        ProductInfo,
        ProductPrice,
        SingleProduct,
        Confirm,
        ProductFilters,
        EditProduct,
        AddNewProduct,
        EditProductCategory,
        TreeSelect
        // CustomDialog,
        // ProductForm
    }
}

</script>

<style scoped>
.v-input__prepend-outer label {
    text-overflow: unset !important;
    max-width: 100%;
    white-space: nowrap;
    font-size: 12px !important;
    color: black;
    width: 25px !important;
}

/*.toggle-filters-button{*/
/*    transform: translateX(0);*/
/*    left: auto;*/
/*    right: 0;*/
/*    position: fixed;*/
/*    z-index:100;*/
/*}*/

</style>

<style>

.hided {
    display: none;
}

.v-data-table td, .v-data-table th {
    padding: 0 !important;
}

/*.v-data-footer {*/
/*    position: fixed;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    left: 0;*/
/*    background-color: inherit;*/
/*    height: 35px;*/
/*}*/

/*.show-header .v-dialog--fullscreen{*/
/*    top: 48px;*/
/*}*/

.products-table .v-data-footer {
    position: static;
}

/*.v-dialog--fullscreen{*/
/*    top: 48px;*/
/*}*/

/*.v-data-table__wrapper {*/
/*    margin-bottom: 5px;*/
/*}*/

.v-data-footer .v-data-footer__select {
    height: 35px;
}

.products-table .v-data-table__wrapper {
    margin-bottom: 0;
    height: calc(100vh - 35px - 48px - 24px - 48px);
    overflow: auto;
}
</style>