var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("product-form", {
    attrs: { productid: _vm.productsId },
    on: { productAdded: _vm.productAdded, fullscreen: _vm.fullscreen }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }