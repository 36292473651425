<template>
    <v-container fluid  class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
            <v-col cols="12" class="d-flex justify-space-around ma-0 pa-0">
                <v-icon size="22" color="black accent-3" @click="openPrintDialog">print</v-icon>
                <v-icon size="20" color="grey darken-2" v-if="item.orders_status_name.orders_status_name === 'Paid'" @click="openShippingDialog">mdi-dump-truck</v-icon>
                <v-icon size="18" v-else>0</v-icon>
                <v-btn icon x-small v-if="item.orders_status_name.orders_status_name === 'Paid'" @click="openCelnicaDialog"><img src="/images/icons/CS_16x16.png" width="16" height="16"/></v-btn>
                <v-icon size="22" v-else>0</v-icon>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-around ma-0 pa-0 mt-2">
                <v-icon size="20" color="amber" @click="printOrderReceipt">mdi-cube-outline</v-icon>
                <v-icon size="20" @click="openOrderDialog">mdi-newspaper-variant-outline</v-icon>
                <v-icon size="20" color="red" v-if="item.order_products.length && item.orders_status_name.orders_status_name === 'Pending'" @click="openCancelDialog">mdi-cancel</v-icon>
                <v-btn icon x-small v-else-if="item.orders_status_name.orders_status_name === 'Paid' || item.orders_status_name.orders_status_name === 'Delivered' || item.orders_status_name.orders_status_name === 'PartialRefunded'" @click="openRefundDialog"><img src="/images/icons/refund_16x16.png" width="16" height="16"/></v-btn>
                <v-icon size="20" v-else>0</v-icon>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-around ma-0 pa-0">
                <v-icon size="20" color="green darken-4" v-if="item.order_emails_count" @click="openOrderDialog('tab-email')">email</v-icon>
                <v-icon size="20" v-else>0</v-icon>
                <v-icon size="20" color="cyan accent-4" @click="openOrderDialog('tab-fedex-ship')">mdi-alpha-f-box</v-icon>

                <v-icon size="20" color="light-green" v-if="item.BuyerCheckoutMessage" @click="openBuyerCheckoutMessageDialog">mdi-android-messages</v-icon>
                <v-icon size="20" v-else>0</v-icon>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EventBus from "@/event-bus";

    export default {
        name: "TableActions",
        props: {
            item: {
                required: true
            }
        },
        data:() => ({
            /*refundDialog: false,
            cancelDialog: false,
            invoiceDialog: false*/
        }),
        mounted(){
            /*EventBus.$on('closeCancelDialog', () => {this.cancelDialog = false})
            EventBus.$on('closeRefundDialog', () => {this.refundDialog = false})
            EventBus.$on('closeInvoiceDialog', () => {this.refundDialog = false})*/
        },
        methods:{
            openOrderDialog(activeTab = 'tab-order-info'){
                EventBus.$emit('openOrderDialog', this.item.orders_id, activeTab);
            },
            openRefundDialog(){
                EventBus.$emit('openRefundDialog', this.item.orders_id);
            },
            openCancelDialog(){
                EventBus.$emit('openCancelDialog', this.item.orders_id);
            },
            openInvoiceDialog(){
                this.$emit('openInvoiceDialog', this.item.orders_id);
            },
            openCelnicaDialog(){
                this.$emit('openCelnicaDialog', this.item.customers_id);
            },
            printOrderReceipt() {
                window.open("/receipt/"+this.item.orders_id+"?lang=cz", '_blank')
            },
            openPrintDialog() {
                this.$emit('openPrintDialog', this.item.orders_id);
            },
            openBuyerCheckoutMessageDialog() {
                this.$emit('openBuyerCheckoutMessageDialog', this.item.orders_id, this.item.BuyerCheckoutMessage);
            },
            openShippingDialog() {
                this.$emit('openShippingDialog', this.item.orders_id);
            }
        }
    }
</script>