var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-space-around": "",
        "justify-start": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        {
          staticStyle: { "max-height": "48px", height: "48px" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { dense: "", flat: "", light: "", color: "grey lighten-5" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-btn", { attrs: { id: "RefreshButton" } }, [
                _vm._v("Refresh")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c(
          "div",
          { staticStyle: { height: "100%" }, attrs: { id: "splitter" } },
          [
            _c("div", [_c("div", { attrs: { id: "productTable" } })]),
            _vm._v(" "),
            _c("div", [_c("div", { attrs: { id: "categoryTableNested" } })])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }