<template>
    <div class="d-flex flex-column align-center justify-center pa-2">
            <p @click="openOrderDialog"
               :class="'ma-0 '+item.orders_status_name.orders_status_name"
               style="cursor: pointer"
            >
            {{ item.orders_id }}
            </p>
        <p class="ma-0">{{ item.ebay_orderid }}</p>
        <p class="ma-0" v-if="daysFromPaid">{{ daysFromPaid }}</p>
    </div>
    
</template>

<script>
    export default {
        name: "TableId",
        props: {
            item: {
                required: true
            }
        },
        data:() => ({
            customOrderDialog: false,
        }),
        computed:{
            daysFromPaid(){
                if(!this.item.date_shipped){
                    let fromTime = this.$moment(this.item.date_paid).diff(this.$moment(), "milliseconds")

                    let duration = this.$moment.duration(fromTime)
                    let timeString=''
                    timeString += duration.years() ? duration.years() / -1 + "y/" : ''
                    timeString +=  duration.months() ? duration.months() / -1 + "m/" : ''
                    timeString +=  duration.days() ? duration.days() / -1 + "d" : ''

                    return timeString
                }
                else return false

            }
        },
        methods:{
            openOrderDialog(){
                this.$emit('openOrderDialog', this.item.orders_id);
            }
        }
    }
</script>

<style scoped>

</style>