<template>
    <v-layout align-space-around justify-start column fill-height >
        <v-flex xs12 style="max-height: 48px; height: 48px;">
            <v-toolbar dense flat light color="grey lighten-5">
                <div style="margin-right: 10px; width: 100%; overflow: hidden; display: none;" id="jqxProgressBar1"></div>
                <v-spacer></v-spacer>
                <v-btn id="RefreshButton" @click="Refresh">Refresh</v-btn>
                <v-btn id="only-selected" disabled>Selected</v-btn>
                <select id="ebay-siteID">
                    <option value="0" selected="selected" data-iconurl="/images/flags/1x1/us.svg">United States</option>
                    <option value="77" data-iconurl="/images/flags/1x1/de.svg">Germany</option>
                    <option value="3" data-iconurl="/images/flags/1x1/gb.svg">UK</option>
                    <option value="71" data-iconurl="/images/flags/1x1/fr.svg">France</option>
                    <option value="101" data-iconurl="/images/flags/1x1/it.svg">Italy</option>
                    <option value="186" data-iconurl="/images/flags/1x1/es.svg">Spain</option>
                </select>
            </v-toolbar>
        </v-flex>
        <v-flex xs12>
            <div id="splitter">
                <div>
                    <div id="categoryTable"></div>
                </div>
                <div>
                    <div id="categoryTableNested"></div>
                </div>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import { mapGetters } from 'vuex'
    import jqxTreeGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxtreegrid';
    import JqxProgressBar from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxprogressbar.vue';
    import _pluck from 'underscore';

    export default {
        name: "Categories",
        computed: {
            ...mapGetters([
                'ebay_global_sites'
            ]),
        },
        methods: {
            /*pluck(val1, val2) {
                return _.pluck(val1, val2)
            }*/
            Refresh() {
                $("#categoryTable").jqxTreeGrid('updateBoundData');
            }
        },
        mounted() {

            $("#jqxProgressBar1").jqxProgressBar({ width: '100%', height: 30, value: 0, max: 0, showText: true});

            $("#ebay-siteID").jqxDropDownList({ width: 150, height: 38, autoDropDownHeight: true });

            // ******************************   MainGrid build ********************************
            $("#splitter").jqxSplitter({ width: '100%', height: '100%', panels: [{ size: '60%', min: '50%' }, { size: '40%', min: '20%' }] });

            let source = {
                datatype: "json",
                datafields: [
                    { name: 'id', type: 'int' },
                    { name: 'catid', type: 'int' },
                    { name: 'parentid', type: 'int' },
                    { name: 'name', type: 'string'},
                    { name: 'selected', type: 'int'},
                    { name: 'siteID', type: 'int'},
                    { name: 'leaf', type: 'int'},
                    { name: 'products_count', type: 'int'},
                    /*{ name: 'final_value_fee', type: 'float'},
                    { name: 'final_value_fee_max', type: 'float'},
                    { name: 'MaxFlatRateShippingCost', type: 'float'},*/
                    { name: 'updated_at', type: 'date'},
                    { name: 'per_order', type: 'float'},
                    { name: 'first_fee', type: 'float'},
                    { name: 'second_fee', type: 'float'},
                    { name: 'limit', type: 'float'},
                    //{ name: 'products_date_added', type: 'date'},
                ],
                hierarchy:
                    {
                        keyDataField: { name: 'catid' },
                        parentDataField: { name: 'parentid' }
                    },
                pagesize: 20,
                id: 'id',
                url: '/api/ebay_category',
                //root: 'data',
                /*beforeprocessing: function (data) {
                    source.totalrecords = data.total;
                },*/
                sortcolumn: 'name',
                sortdirection: 'asc',
                /*sort: function () {
                    $("#categoryTable").jqxGrid('updatebounddata', 'sort');
                },
                filter: function () {
                    $("#categoryTable").jqxTreeGrid('updatebounddata', 'filter');
                },*/
            };
            let dataAdapter = new $.jqx.dataAdapter(source);

            $("#categoryTable").jqxTreeGrid({
                width: '100%',
                height: '100%',
                source: dataAdapter,
                sortable: true,
                pageable: false,
                filterable: true,
                hierarchicalCheckboxes: true,
                checkboxes: true,
                enableBrowserSelection: true,
                showToolbar: true,
                renderToolbar: function(toolBar){
                    $(toolBar).html('');
                    let update_categories = $('<button type="button" style="float: left;">Update selected</button>').jqxButton({ template: "primary", height: '25px' });
                    update_categories.on('click', function () {
                        let checked_rows = $("#categoryTable").jqxTreeGrid('getCheckedRows');

                        //console.log(checked_rows);

                        //let final_value_fee = $('#finalvaluefee').val();
                        //let final_value_fee_max = $('#finalvaluefeemax').val();
                        let per_order = $('#per_order').val();
                        let first_fee = $('#first_fee').val();
                        let second_fee = $('#second_fee').val();
                        let limit = $('#limit').val();

                        if (checked_rows.length == 0) {
                            $(this).focusout();
                            return;
                        }

                        function paginate (arr, size) {
                            return arr.reduce((acc, val, i) => {
                                let idx = Math.floor(i / size)
                                let page = acc[idx] || (acc[idx] = [])
                                page.push(val)

                                return acc
                            }, [])
                        }

                        let pages = paginate(checked_rows, 25)

                        //console.log(pages);

                        //return;

                        const updateCategories = (row, checked_rows, element) => {
                            return axios.post(`/api/ebay_category/massupdate`, row)
                                .then((response) => {
                                    let value = $('#jqxProgressBar1').jqxProgressBar('value');
                                    let new_value = value+element.length;
                                    $('#jqxProgressBar1').jqxProgressBar({  value: new_value});
                                    element.forEach((element, index) => {
                                        if (_.has(row, 'per_order')) element['per_order'] = row['per_order']
                                        if (_.has(row, 'first_fee')) element['first_fee'] = row['first_fee']
                                        if (_.has(row, 'second_fee')) element['second_fee'] = row['second_fee']
                                        if (_.has(row, 'limit')) element['limit'] = row['limit']
                                        $("#categoryTable").jqxTreeGrid('updateRow',element.uid, element)
                                    })
                                    if (new_value === checked_rows.length) {
                                        $('#jqxProgressBar1').hide()
                                        $('#categoryTable').jqxTreeGrid({disabled: false});
                                    }
                                })
                            /*.catch(() => {
                                    console.log('Error')
                                    $('#jqxProgressBar1').hide()
                                    $('#categoryTable').jqxTreeGrid({disabled: false});
                                });*/
                        }

                        const control = async (pages) => {

                            $('#jqxProgressBar1').jqxProgressBar({max: checked_rows.length});
                            $('#jqxProgressBar1').jqxProgressBar({value: 0});
                            $('#jqxProgressBar1').show()
                            $('#categoryTable').jqxTreeGrid({disabled: true});

                            console.log('Start')

                            for (let i = 0; i < pages.length; i++) {
                                let element = pages[i];

                                let categories = _.map(element, 'id');

                                let row = {
                                    categories: categories,
                                };

                                //if (final_value_fee !== '') row['final_value_fee'] = final_value_fee;
                                //if (final_value_fee_max !== '') row['final_value_fee_max'] = final_value_fee_max;

                                if (per_order !== '' && per_order !== '0.00' && per_order !== 0.00) row['per_order'] = per_order;
                                if (first_fee !== '' && first_fee !== '0.00' && first_fee !== 0.00) row['first_fee'] = first_fee;
                                if (second_fee !== '' && second_fee !== '0.00' && second_fee !== 0.00) row['second_fee'] = second_fee;
                                if (limit !== '' && limit !== '0.00' && limit !== 0.00) row['limit'] = limit;

                                await updateCategories(row, checked_rows, element);
                            }
                            console.log('End')
                        }

                        control(pages)

                        /*$.ajax( {
                            'url': "/api/ebay_category/massupdate",
                            'type': 'POST',
                            'data': row,
                            'dataType': 'json'
                        });*/
                    });
                    $(toolBar).append(update_categories).css('padding', '7px');

                    /*
                    let final_value_fee = $('<div id="finalvaluefee" style="float: left"></div>');
                    final_value_fee.jqxNumberInput({ width: 75, height: 23, digits:2,  decimalDigits: 2, spinButtons: false, textAlign: "center", symbol: '%', symbolPosition: 'right'});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">Final value fee:</div>');
                    $(toolBar).append(final_value_fee);

                    let final_value_fee_max = $('<div id="finalvaluefeemax" style="float: left"></div>');
                    final_value_fee_max.jqxNumberInput({ width: 75, height: 23, digits:3,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">Final value fee max:</div>');
                    $(toolBar).append(final_value_fee_max);
                    */

                    let per_order = $('<div id="per_order" style="float: left"></div>');
                    per_order.jqxNumberInput({ width: 75, height: 23, digits:3,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">Order:</div>');
                    $(toolBar).append(per_order);

                    let first_fee = $('<div id="first_fee" style="float: left"></div>');
                    first_fee.jqxNumberInput({ width: 75, height: 23, digits:3,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">First:</div>');
                    $(toolBar).append(first_fee);

                    let second_fee = $('<div id="second_fee" style="float: left"></div>');
                    second_fee.jqxNumberInput({ width: 75, height: 23, digits:3,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">Second:</div>');
                    $(toolBar).append(second_fee);

                    let limit = $('<div id="limit" style="float: left"></div>');
                    limit.jqxNumberInput({ width: 75, height: 23, digits:5,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                    $(toolBar).append('<div style="float: left; margin-top: 5px; margin-left: 10px;">Limit:</div>');
                    $(toolBar).append(limit);

                },
                rendered:function(){
                    //$("#categoryTable").jqxTreeGrid('expandAll');
                },
                columns: [
                    { text: 'Name', dataField: 'name', align: 'center',
                        // row - row's index.
                        // column - column's data field.
                        // value - cell's value.
                        // rowData - rendered row's object.
                        cellsRenderer: function (row, column, value, rowData, cellText) {
                            return (rowData.leaf ? '<strong>' : '')+cellText+(rowData.products_count != 0 ? ' ('+rowData.products_count+')' : '')+(rowData.leaf ? '</strong> ' : '');
                        }
                    },
                    { text: 'ID', dataField: 'catid', align: 'center', width: 58, cellsAlign: 'center'},
                    { text: 'Selected', dataField: 'selected', align: 'center', hidden: true },
                    { text: 'siteID', dataField: 'siteID', align: 'center', hidden: true},
                    { text: 'Parent', dataField: 'parentid', align: 'center', hidden: true },
                    //{ text: 'products_date_added', dataField: 'products_date_added', width: '10%', align: 'center', cellsalign: 'center', cellsformat: "d", filterable: false },
                    /*{ text: 'Fee', dataField: 'final_value_fee', align: 'center', width: 75, cellsFormat :'p2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: 'Max', dataField: 'final_value_fee_max', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: 'Ship', dataField: 'MaxFlatRateShippingCost', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},*/
                    { text: 'Fix', dataField: 'per_order', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: '1%', dataField: 'first_fee', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: '2%', dataField: 'second_fee', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: 'Limit', dataField: 'limit', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    { text: 'Updated', dataField: 'updated_at', align: 'center', width: 75, cellsFormat :'MM-yyyy', cellsAlign: 'center', filterable: false, sortable: false}
                ]
            });

            $('#only-selected').on('click', function () {
                $("#categoryTableNested").jqxDataTable('clear');
                if ($(this).attr('aria-pressed') == 'false') {
                    //$("#categoryTable").jqxTreeGrid('expandAll');
                    applycategoryTableFilter($("#ebay-siteID").val(), 'siteID');
                    applycategoryTableFilter(1, 'selected');
                    $("#categoryTable").jqxTreeGrid('updateBoundData', 'filter');
                    $(this).text('   All  ');
                } else {
                    //$("#categoryTable").jqxTreeGrid('collapseAll');
                    $("#categoryTable").jqxTreeGrid('removeFilter', 'selected');
                    $("#categoryTable").jqxTreeGrid('updateBoundData', 'filter');
                    $(this).text('Selected');
                }

            });

            $('#expand-all').on('click', function () {
                $("#categoryTable").jqxTreeGrid('expandAll');
                return;
                var firstLevelRows = $("#categoryTable").jqxTreeGrid('getRows');
                console.log(_.where(firstLevelRows, {parentid: 0}));
                firstLevelRows = _.where(firstLevelRows, {parentid: 0});
                for (var i = 0; i < firstLevelRows.length; i++) {
                    // get a row.
                    var rowData = firstLevelRows[i].uid ;
                    $("#categoryTable").jqxTreeGrid('expandRow', rowData);
                }
                return;
                if ($(this).attr('aria-pressed') == 'false') {
                    $("#categoryTable").jqxTreeGrid('expandAll');
                    $(this).text('Expanded');
                    console.log('Expanded');
                } else {
                    $("#categoryTable").jqxTreeGrid('collapseAll');
                    $(this).text('Collapsed');
                    console.log('Collapsed');
                }

            });

            $('#get-from-ebay').on('click', function () {
                $.get('api/GetCategories/'+$("#ebay-siteID").val(), function (data) {
                    $("#categoryTable").jqxTreeGrid('clear');
                    $("#categoryTableNested").jqxDataTable('clear');
                    applycategoryTableFilter($("#ebay-siteID").val(), 'siteID');
                    $("#categoryTable").jqxTreeGrid('updateBoundData', 'filter');
                    //console.log('RESPOND', data);
                }).fail(function(e) {
                    console.log(e);
                });
            });

            let applycategoryTableFilter = function (filtervalue, column) {
                $("#categoryTable").jqxTreeGrid('clear');
                let filtertype = 'numericfilter';
                let filtergroup = new $.jqx.filter();
                let filter = filtergroup.createfilter(filtertype, filtervalue, 'EQUAL');
                filtergroup.addfilter(1, filter);
                //$("#categoryTable").jqxTreeGrid('clearFilters');
                $("#categoryTable").jqxTreeGrid('removeFilter',column);
                // add the filters.
                $("#categoryTable").jqxTreeGrid('addFilter', column, filtergroup);
                // apply the filters.
                $("#categoryTable").jqxTreeGrid('applyFilters');
            };

            $("#ebay-siteID").change(function () {
                $("#categoryTableNested").jqxDataTable('clear');
                applycategoryTableFilter($(this).val(), 'siteID');
                $("#categoryTable").jqxTreeGrid('updateBoundData', 'filter');
            });

            $('#categoryTable').on('rowSelect', function (event)
            {
                let args = event.args;
                if (args.row.leaf == 0) return;
                categoryTableNestedFilter(args.row.catid, 'catid');
                categoryTableNestedFilter(args.row.siteID, 'siteID', 'NOT_EQUAL');

                if (sourceNested.url == undefined) sourceNested.url = '/api/ebay_category';

                $("#categoryTableNested").jqxDataTable('updateBoundData', 'filter');

                //$("#categoryTableNested").jqxDataTable({ source: dataAdapterNested });
            });

            let sourceNested = {
                datatype: "json",
                datafields: [
                    { name: 'id', type: 'int' },
                    { name: 'catid', type: 'int' },
                    { name: 'parentid', type: 'int' },
                    { name: 'name', type: 'string'},
                    { name: 'selected', type: 'int'},
                    { name: 'siteID', type: 'int'},
                    { name: 'leaf', type: 'int'},
                    { name: 'products_count', type: 'int'},
                    /*{ name: 'final_value_fee', type: 'float'},
                    { name: 'final_value_fee_max', type: 'float'},*/
                    { name: 'per_order', type: 'float'},
                    { name: 'first_fee', type: 'float'},
                    { name: 'second_fee', type: 'float'},
                    { name: 'limit', type: 'float'},
                ],
                pagesize: 20,
                id: 'id',
                sortcolumn: 'siteID',
                sortdirection: 'asc',
            };
            let dataAdapterNested = new $.jqx.dataAdapter(sourceNested);

            $('#categoryTableNested').on('cellValueChanged', function (event)
            {
                let args = event.args;
                let row = args.row;
                row.selected = (row.selected == 1 ? 1 : 0);
                $.ajax( {
                    'url': "/api/ebay_category/"+row.id,
                    'type': 'PUT',
                    'data': row ,
                    'dataType': 'json'
                }).done(function( data ) {
                    //$("#categoryTableNested").jqxDataTable('updateBoundData', 'filter');
                });
            });
            const ebay_global_sites = this.ebay_global_sites;
            $("#categoryTableNested").jqxDataTable(
                {
                    width: '100%',
                    height: '100%',
                    pageable: false,
                    source: dataAdapterNested,
                    columnsResize: false,
                    filterable: true,
                    enableBrowserSelection: true,
                    filterHeight: 0,
                    showHeader: false,
                    autoRowHeight: false,
                    selectionMode: 'custom',
                    editable: true,
                    //sortable: true,
                    editSettings: { saveOnPageChange: true, saveOnBlur: false, saveOnSelectionChange: true, cancelOnEsc: true, saveOnEnter: true, editSingleCell: true, editOnDoubleClick: true, editOnF2: true },
                    //serverProcessing: true,
                    columns: [
                        { text: 'siteID', dataField: 'siteID', cellsAlign: 'center', width: 38, editable: false,
                            // row - row's index.
                            // column - column's data field.
                            // value - cell's value.
                            // rowData - rendered row's object.
                            cellsRenderer: function (row, column, value, rowData) {

                                var image = "<div style='margin: 5px; margin-bottom: 3px;'>";
                                var img = '<span class="flag-icon flag-icon-'+ebay_global_sites[value].name+'"></span>';
                                image += img;
                                image += "</div>";
                                return image;
                            }
                        },
                        { text: 'ID', dataField: 'catid', cellsAlign: 'center', width: 60, editable: false },
                        { text: 'Name', dataField: 'name', align: 'center', editable: false,
                            // row - row's index.
                            // column - column's data field.
                            // value - cell's value.
                            // rowData - rendered row's object.
                            cellsRenderer: function (row, column, value, rowData) {
                                var image = "<div style='margin: 5px; margin-bottom: 3px;'>";
                                var img = value+(rowData.products_count != 0 ? ' ('+rowData.products_count+')' : '');
                                image += img;
                                image += "</div>";
                                return image;
                            }
                        },
                        { text: 'Selected', dataField: 'selected', cellsAlign: 'center', width: 38, editable: false,
                            // row - row's index.
                            // column - column's data field.
                            // value - cell's value.
                            // rowData - rendered row's object.
                            cellsRenderer: function (row, column, value, rowData) {
                                if (rowData.leaf == 0) return '';
                                var image = "<div style='margin: 5px; margin-bottom: 3px;'>";
                                //var img = '<input type="checkbox" onclick=\'$("#categoryTableNested").jqxDataTable("beginCellEdit",'+row+', "selected"); $("#categoryTableNested").jqxDataTable("endCellEdit",'+row+', "selected");\' value="" '+(value ? 'checked=""' : '')+' '+(rowData.products_count != 0 ? 'disabled' : '')+'>';
                                var img = '<input type="checkbox" onclick=\'$("#categoryTableNested").jqxDataTable("setCellValue",'+row+', "selected", '+(value ? 0 : 1)+');\' value="" '+(value ? 'checked=""' : '')+' '+((rowData.products_count != 0 && value)? 'disabled' : '')+'>';
                                image += img;
                                image += "</div>";
                                return image;
                            }
                        },
                        /*{ text: 'Fee', dataField: 'final_value_fee', align: 'center', width: 75, cellsFormat :'p2', cellsAlign: 'center', columnType: 'template', filterable: false, sortable: false,
                            createEditor: function (row, cellvalue, editor, cellText, width, height) {
                                // construct the editor.
                                editor.attr('id', 'final_value_fee'+row ).jqxNumberInput({ width: width, height: height, digits:2,  decimalDigits: 2, spinButtons: false, textAlign: "center", symbol: '%', symbolPosition: 'right'});
                            },
                            initEditor: function (row, cellvalue, editor, celltext, width, height) {
                                // set the editor's current value. The callback is called each time the editor is displayed.
                                editor.val(cellvalue);
                            },
                            getEditorValue: function (row, cellvalue, editor) {
                                // return the editor's value.
                                return editor.val().toFixed(2);
                            }
                        },
                        { text: 'Fee', dataField: 'final_value_fee_max', align: 'center', width: 75, cellsFormat :'f2', cellsAlign: 'center', columnType: 'template', filterable: false, sortable: false,
                            createEditor: function (row, cellvalue, editor, cellText, width, height) {
                                // construct the editor.
                                editor.attr('id', 'final_value_fee_max'+row ).jqxNumberInput({ width: width, height: height, digits:2,  decimalDigits: 2, spinButtons: false, textAlign: "center"});
                            },
                            initEditor: function (row, cellvalue, editor, celltext, width, height) {
                                // set the editor's current value. The callback is called each time the editor is displayed.
                                editor.val(cellvalue);
                            },
                            getEditorValue: function (row, cellvalue, editor) {
                                // return the editor's value.
                                return editor.val().toFixed(2);
                            }
                        },*/
                        { text: 'Fix', dataField: 'per_order', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                        { text: '1%', dataField: 'first_fee', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                        { text: '2%', dataField: 'second_fee', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                        { text: '2%', dataField: 'limit', align: 'center', width: 75, cellsFormat :'d2', cellsAlign: 'center', filterable: false, sortable: false},
                    ]
                });

            let update_selected = function (row, value) {
                $("#categoryTableNested").jqxDataTable("setCellValue", row, "selected", (value ? 0 : 1));
            };

            let categoryTableNestedFilter = function (filtervalue, column, filtercondition = 'EQUAL') {
                let filtertype = 'numericfilter';
                let filtergroup = new $.jqx.filter();
                let filter = filtergroup.createfilter(filtertype, filtervalue, filtercondition);
                filtergroup.addfilter(1, filter);
                $("#categoryTableNested").jqxDataTable('removeFilter',column);
                // add the filters.
                $("#categoryTableNested").jqxDataTable('addFilter', column, filtergroup);
                // apply the filters.
                $("#categoryTableNested").jqxDataTable('applyFilters');
            };

        }
    }
</script>

<style scoped>

</style>