<template>
    <v-row>
        <v-col class="pt-0">

            <!--Purchase-->
            <v-row no-gutters class="mt-4">
                <v-col class="pt-0">
                    <v-subheader class="grey lighten-4 justify-start font-weight-black">
                        PURCHASES:
                        <v-spacer></v-spacer>
                        <v-btn small text icon color="default" @click="openAddDialog">
                            <v-icon small>mdi-plus-thick</v-icon>
                        </v-btn>
                        <v-btn small text icon color="default" @click="openAddToCheckkDialog">
                            <v-icon small>mdi-note-plus-outline</v-icon>
                        </v-btn>
                        <v-btn small text icon color="default" @click="initInflow">
                            <v-icon small>mdi-cached</v-icon>
                        </v-btn>
                    </v-subheader>
                    <v-divider/>
                    <v-data-table
                            :headers="inflow.headers"
                            :items="inflow.data"
                            :hide-default-footer="toggleFooter(inflow.data)"
                            disable-sort
                            class="elevation-1 check-purchase-table"
                            dense
                            :loading="loadingInflow"
                            loading-text="Loading... Please wait"
                    >
                        <template slot="item" slot-scope="props">
                            <tr>
                                <td class="font-weight-black">
                                    {{ props.item.id }}
                                    <v-icon color="green" dark small v-show="isCheckSelected(props.item.id)">check</v-icon>
                                </td>
                                <td>
                                    <div v-if="props.item.check">
                                        #{{ props.item.check.check_number }} - {{ $moment(props.item.check.check_date).format('YYYY-MM-DD') }}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="props.item.check_last_modified" class="date-field">
                                     <!--{{ $moment(props.item.check_last_modified).format('MM-DD-YYYY') }}-->
                                        <datepicker
                                            hide-details
                                            v-model="props.item.check_last_modified"
                                            class="caption font-weight-regular mt-0 mb-1"
                                            @input="updateCheck(props.item)"
                                        ></datepicker>
                                    </div>
                                </td>
                                <td>
                                    <v-edit-dialog>
                                        {{ props.item.products_quantity }}
                                        <v-text-field type="number"
                                                      slot="input"
                                                      v-model.number="props.item.products_quantity"
                                                      @input="updateCheck(props.item)"
                                        ></v-text-field>
                                    </v-edit-dialog>
                                </td>
                                <td>
                                    <div class="d-flex justify-center align-baseline">
                                        <v-edit-dialog>
                                            <span class="pr-3">{{ props.item.products_price }}</span>
                                            <v-text-field type="number"
                                                          slot="input"
                                                          v-model.number="props.item.products_price"
                                                          step="0.01"
                                                          @input="updateCheck(props.item)"
                                            ></v-text-field>
                                        </v-edit-dialog>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-center align-baseline">
                                        <v-select dense hide-details
                                                  v-model="props.item.currencies_code"
                                                  :items="currencies"
                                                  class="caption font-weight-regular currency-select mt-0 mb-1 pa-0"
                                                  @input="updateCheck(props.item)"
                                        ></v-select>
                                    </div>
                                </td>
                                <td>
                                <span v-if="props.item.check">
                                   {{ props.item.check.ebay_id }}
                                </span>
                                </td>
                            </tr>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="920" persistent>
                <v-card>
                    <v-card-title>
                        <span class="headline">New purchase</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-form
                                ref="formNewPurchase"
                                v-model="validNewPurchase"
                                lazy-validation
                            >
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <datepicker
                                                v-model="newInflow.check_last_modified"
                                                class="font-weight-regular"
                                                :labelName="'Date'"
                                                @input=""
                                                :rules="rules"
                                                required=true
                                                :hideDetails="false"
                                        ></datepicker>
                                        <!--<v-text-field v-model="newInflow.check_last_modified" label="Date"></v-text-field>-->
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            dense
                                            v-model="newInflow.products_quantity"
                                            label="Quantity"
                                            :rules="rules"
                                            required
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            dense
                                            v-model="newInflow.products_price"
                                            label="Purchase Price"
                                            :rules="rules"
                                            required
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            dense
                                            label="Currency"
                                            v-model="newInflow.currencies_code"
                                            :items="currencies"
                                            class="caption font-weight-regular"
                                            :rules="rules"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeNewPurchase('dialog')">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="saveNewPurchase">Save</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

            <v-dialog v-model="addToCheckDialog" max-width="920">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add Product to Check</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <find-check
                                        @input="setNewPurchase"
                                    ></find-check>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeNewPurchase('addToCheckDialog')">Cancel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

            <!--Sales-->
            <v-row no-gutters>
                <v-col>
                    <v-subheader class="grey lighten-4 justify-start font-weight-black mt-2">
                        SALES:
                        <v-spacer></v-spacer>
                        <v-btn small text icon color="default" @click="initOrders">
                            <v-icon small>mdi-cached</v-icon>
                        </v-btn>
                    </v-subheader>
                    <v-divider/>
                    <v-data-table
                            :headers="orders.headers"
                            :items="orders.data"
                            :hide-default-footer="toggleFooter(orders.data)"
                            class="elevation-1"
                            dense
                            :loading="loadingOrders"
                            loading-text="Loading... Please wait"
                            :sort-by="['order.date_purchased', 'order.date_paid']"
                            :sort-desc="[true, true]"
                            multi-sort
                    >
                        <template slot="item" slot-scope="props">
                            <tr>
                                <td class="font-weight-black">
                                    <a @click="openOrderDialog(props.item.orders_id)">{{ props.item.orders_id }}</a>
                                    <v-icon color="green" dark small v-show="isOrderSelected(props.item.orders_id)">check</v-icon>
                                </td>
                                <td>
                                    <div v-if="props.item.order && props.item.order.date_purchased">
                                        {{ $moment(props.item.order.date_purchased).format('YYYY-MM-DD') }}
                                    </div>
                                </td>
                                <td>
                                    <div v-if="props.item.order && props.item.order.date_paid">
                                        {{ $moment(props.item.order.date_paid).format('YYYY-MM-DD') }}
                                    </div>
                                </td>
                                <td>{{ props.item.products_quantity }}</td>
                                <td>{{ props.item.products_price}} <span v-if="props.item.order">{{ props.item.order.currency }}</span></td>
                                <td>
                                    <span :class="props.item.order.orders_status_name.orders_status_name"
                                            v-if="props.item.order">
                                           {{ props.item.order.orders_status_name.orders_status_name}}
                                    </span>

                                </td>
                                <td><span v-if="props.item.order">{{ props.item.order.site }}</span></td>
                            </tr>

                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <!--Correspondence-->
            <v-row no-gutters>
                <v-col>
                    <v-subheader class="grey lighten-4 justify-start font-weight-black mt-2">
                        CORRESPONDENS:
                        <v-spacer></v-spacer>
                        <v-btn small text icon color="default" @click="initCorrespondence"><v-icon small>mdi-cached</v-icon></v-btn>
                    </v-subheader>
                    <v-divider/>
                    <v-simple-table
                            dense
                            class="elevation-1 scroll-table"
                            :loading="loadingCorrespondence"
                            loading-text="Loading... Please wait"
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center font-weight-normal" width="30%">Check ID</th>
                                    <th class="text-center font-weight-normal" width="30%">Order ID</th>
                                    <th class="text-center font-weight-normal">Quantity</th>
                                    <th class="text-center font-weight-normal">Action</th>
                                </tr>
                                <tr class="pa-0 ma-0" style="height: 1px;">
                                    <th colspan="4" style="height: 1px;" class="pa-0 ma-0"><v-progress-linear height="3" indeterminate :active="loadingCorrespondence" absolute style="width: 100%;"></v-progress-linear></th></tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-show="select_orders.length && select_check.length"
                                >
                                    <td align="center">
                                        <v-select
                                            dense
                                            hide-details
                                            v-model="newCorrespondence.check"
                                            :items="select_check"
                                            item-value="products_checks_id"
                                            item-text="products_checks_id"
                                            class="font-weight-regular font-size-small ma-auto"
                                            return-object
                                            style="width: 100px;"
                                        ></v-select>
                                    </td>
                                    <td align="center">
                                        <v-select
                                            dense
                                            hide-details
                                            v-model="newCorrespondence.order"
                                            :items="select_orders"
                                            item-value="orders_id"
                                            item-text="orders_id"
                                            return-object
                                            class="font-weight-regular font-size-small ma-auto"
                                            style="width: 100px;"
                                        ></v-select>
                                    </td>
                                    <td>
                                        <v-text-field
                                            dense
                                            hide-details
                                            number
                                            v-model="newCorrespondence.quantity"
                                            class="font-weight-regular ma-auto"
                                            style="width: 100px;"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon
                                            color="success"
                                            @click="addCorrespondence"
                                            v-show="newCorrespondence.order.orders_id && newCorrespondence.check.products_checks_id"
                                        >add</v-icon>
                                    </td>
                                </tr>
                                <tr v-for="item in correspondence.data" :key="item.id">
                                    <td width="30%">{{ item.products_checks_id }}
                                        <span v-if="item.check">
                                            #{{ item.check.check_number }}
                                        </span>
                                    </td>
                                    <td width="30%">
                                        <a @click="openOrderDialog(item.order_id)">
                                            <span :class="item.order.orders_status_name.orders_status_name"
                                                  v-if="item.order">
                                                {{ item.order_id }}
                                            </span>
                                        </a>
                                        <v-icon color="red" medium dense v-if="item.order && (item.order.orders_status === 4 || item.order.orders_status === 7 || item.order.orders_status === 9 )">error</v-icon>
                                    </td>
                                    <td>{{ item.quantity }}</td>
                                    <td>
                                        <v-icon color="red" size="medium" v-show="item.id" @click="deleteCorrespondence(item.id)">delete</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </v-col>
            </v-row>

            <v-row no-gutters class="mt-3" style="position: absolute; width: 100%; bottom: 15px;">
                <v-col cols="12">
                    <v-row justify="center">
                        <v-col cols="2" class="text-right font-weight-bold my-auto">Purchased:</v-col>
                        <v-col cols="1" class="text-left my-auto">{{ statistics.purchased }}</v-col>

                        <v-col cols="2" class="text-right font-weight-bold my-auto"> Sold:</v-col>
                        <v-col cols="1" class="text-left my-auto">{{ statistics.sold }}</v-col>

                        <v-col cols="2" class="text-right font-weight-bold my-auto">Remain:</v-col>
                        <v-col cols="1" class="text-left d-flex pa-0">
                                <v-text-field
                                    dense
                                    hide-details
                                    v-if="statistics.purchased !== null && statistics.sold !== null"
                                    v-model.number="remainQantity"
                                    class="pa-0"
                                    readonly
                                >
                                    <v-icon
                                        :color="(remainQantity !== product.products_quantity ? 'red' : 'grey')"
                                        slot="append-outer"
                                        small
                                        class="mt-1"
                                        @click="saveProductQuantity()">save</v-icon>
                                </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-col>
    </v-row>

</template>

<script>
    import Datepicker from '@/components/CustomDatepicker.vue'
    import { mapGetters } from 'vuex'
    import FindCheck from './FindCheck'
    import EventBus from "@/event-bus";


    export default {
        name: "Check",
        props:{
            isActive:{},
        },
        data(){
            return {
                inflow: {
                    headers:[
                        {text: 'Check ID', value: 'products_check', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Check Numbers', value: 'check', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Purchase date', value: 'check_last_modified', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Quantity', value: 'products_quantity', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Purchase price', value: 'price', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Currency', value: 'currency', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Account', value: 'account', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                    ],
                    data: []
                },
                newCorrespondence: {
                    check_id: null,
                    check: {
                        check_id: null,
                        products_checks_id: null
                    },
                    order_id: null,
                    order: {
                        order_id: null,
                        orders_products_id: null
                    },
                    quantity: 1,
                    orders_products_id: null
                },
                newInflow: {
                    check_id: null,
                    order_id: null,
                    quantity: 1,
                    check_last_modified: null
                },
                orders: {
                    headers: [
                        {text: 'Order ID', value: 'orders_id', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Order Date', value: 'order.date_purchased', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Paid Date', value: 'order.date_paid', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Quantity', value: 'products_quantity', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Selling Price', value: 'products_price', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Status', value: 'order.orders_status_name.orders_status_name', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                        {text: 'Account', value: 'order.site', align: 'center', class: ['font-weight-normal', 'text-uppercase']},
                    ],
                    data: []
                },
                correspondence:{
                    headers:[
                        {text: 'Check ID', value: 'check_id', align: 'center', class: 'text-uppercase'},
                        {text: 'Order ID', value: 'order_id', align: 'center', class: 'text-uppercase'},
                        {text: 'Quantity', value: 'quantity', align: 'center', class: 'text-uppercase'},
                        {text: 'Action', value: 'action', align: 'center', sortable: false,},
                    ],
                    data: []
                },
                statistics:{
                        purchased: null,
                        sold: null,
                        remain: null,
                },
                dialog: false,
                addToCheckDialog: false,
                loadingInflow: true,
                loadingCorrespondence: true,
                loadingOrders: true,
                rules: [
                    v => !!v || 'Required',
                ],
                validNewPurchase: true,
            }
        },
        computed:{
            currencies(){
                return this.currenciesList
            },
            remainQantity() {
                return this.statistics.purchased - this.statistics.sold
            },
            select_check: {
                get(){
                    let arr = []
                    for(let i = 0; i < this.inflow.data.length; i++){
                        const isExist = this.correspondence.data.some( ({products_checks_id}) => products_checks_id === this.inflow.data[i].id)
                        if(!isExist){
                            arr.push({
                                    check_id: this.inflow.data[i].products_check,
                                    products_checks_id: this.inflow.data[i].id
                                }
                            )
                        }
                        if(arr.length){
                            this.newCorrespondence.check = arr[0]
                        }
                    }
                    console.log('select_check', arr);
                    return arr
                },
                set(value){
                    this.newCorrespondence.check_id = value[0]
                }
            },
            select_orders:{
                get(){
                    let arr = []
                    for(let i = 0; i < this.orders.data.length; i++){
                        const isExist = this.correspondence.data.some( ({order_id}) => order_id === this.orders.data[i].orders_id)

                        if(!isExist && this.orders.data[i].order && this.orders.data[i].order.orders_status !== 4 && this.orders.data[i].order.orders_status !== 9 && this.orders.data[i].order.orders_status !== 7){
                            arr.push(this.orders.data[i])
                        }
                        if(arr.length){
                            this.newCorrespondence.order = arr[0]
                        }
                    }
                    console.log('select_orders getter', arr)
                    return arr
                },
                set(value){
                    console.log('select_orders setter', value)
                    this.newCorrespondence.order_id = value[0].orders_id
                    this.newCorrespondence.orders_products_id = value[0].orders_products_id
                }
            },
            ...mapGetters([
                'currenciesList',
                'productId',
                'product'
            ]),
        },
        mounted() {
            this.init()
            this.$emit('componentReady')
        },
        methods: {
            isCheckSelected(id) {
                return this.correspondence.data.some( ({products_checks_id}) => products_checks_id === id)
            },
            isOrderSelected(id) {
                return this.correspondence.data.some( ({order_id}) => order_id === id)
            },
            setNewPurchase(val) {
                this.newInflow = val
                this.addToExistCheck()
            },
            init() {
                this.initInflow()
                this.initOrders()
                this.initCorrespondence()
            },
            async initInflow() {
                this.loadingInflow = true
                return axios.get(`/api/products_checks?
                    filterGroups[0][field]=products_id
                    &filterGroups[0][filters][0][value]=${this.productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=and
                    &pagesize=100000`)
                    .then(response => {
                        this.inflow.data = response.data.data
                        this.statistics.purchased = this.inflow.data.reduce((n, {products_quantity}) => n + products_quantity, 0)
                    }).catch((error) => {
                        console.log(error);
                        console.warn('Request was not sent properly. Product is not present.');
                    }).finally(() => {
                        this.loadingInflow = false
                    })
            },
            async initOrders() {
                this.loadingOrders = true
                return axios.get(`/api/orders_products?
                    filterGroups[0][field]=products_id
                    &filterGroups[0][filters][0][value]=${this.productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=and
                    &pagesize=100000
                    &with[]=order
                    &sortdatafield=order.date_purchased`)
                    .then(response => {
                        this.orders.data = response.data.data
                    }).catch((error) => {
                        console.log(error);
                        console.warn('Request was not sent properly. Product is not present.');
                    }).finally(() => {
                        this.loadingOrders = false
                    })
            },
            async initCorrespondence(){
                this.loadingCorrespondence = true
                return axios.get(`/api/products_orders_to_check?
                    filterGroups[0][field]=product_id
                    &filterGroups[0][filters][0][value]=${this.productId}
                    &filterGroups[0][filters][0][condition]=EQUAL
                    &filterGroups[0][filters][0][type]=numericfilter
                    &filterGroups[0][filters][0][operator]=or
                    &filterGroups[0][operator]=and
                    &with[]=order
                    &with[]=check
                    &pagesize=100000`)
                    .then(response => {
                        this.correspondence.data = response.data.data
                        this.statistics.sold = this.correspondence.data.reduce((n, {quantity}) => n + quantity, 0)
                    }).catch((error) => {
                        console.log(error);
                        console.warn('Request was not sent properly. Product is not present.');
                    }).finally(() => {
                        this.loadingCorrespondence = false
                    })
            },
            updateCheck(item){
                this.loadingInflow = true
                axios.put(`/api/products_checks/${item.id}`, item)
                    .finally(() => {
                        this.initInflow()
                    })
            },
            deleteCorrespondence(id) {
                this.loadingCorrespondence = true
                axios.delete(`/api/products_orders_to_check/${id}`)
                    .then(response => {
                        this.correspondence.data = this.correspondence.data.filter(function( obj ) {
                            return obj.id !== id;
                        });
                    }).finally(() => {
                        this.initCorrespondence()
                })
            },
            saveProductQuantity(){
                console.debug('this.productId', this.productId)
                return this.$store.dispatch('updateProduct', {products_id: this.productId, products_quantity: this.remainQantity})
                    .then(() => {
                        //alert('Data is saved')
                    })
                    .catch(error => {
                        alert(`Data is not saved. ${error}`)
                    })

/*                axios.put(`/api/products/${this.productId}`, { products_quantity: quantity})
                    .then(response => {
                       this.product.products_quantity = quantity;
                     })*/
            },
            openAddDialog(){
                this.dialog = true
            },
            openAddToCheckkDialog(){
                this.addToCheckDialog = true
            },
            closeNewPurchase (dialogId) {
                this.newInflow = {
                    check_id: null,
                    order_id: null,
                    quantity: 1
                }
                this[dialogId] = false
            },
            addToExistCheck() {
                this.loadingInflow = true
                this.newInflow.products_id = this.productId;
                axios.post('/api/products_checks', this.newInflow)
                    .then(response => {
                        this.dialog=false
                        this.newInflow = {
                            check_id: null,
                            order_id: null,
                            quantity: 1
                        }
                        this.inflow.data.push(response.data)
                    }).finally(() => {
                    this.initInflow()
                })
            },
            saveNewPurchase(){
                if (this.$refs.formNewPurchase.validate()) {
                    this.loadingInflow = true
                    this.newInflow.products_id = this.productId;
                    axios.post('/api/products_checks', this.newInflow)
                        .then(response => {
                            this.dialog=false
                            this.newInflow = {
                                check_id: null,
                                order_id: null,
                                quantity: 1
                            }
                            this.inflow.data.push(response.data)
                        }).finally(() => {
                        this.initInflow()
                    })
                }
            },
            toggleFooter(data){
                if(data.length > 10){
                    return false //show footer
                }
                return true //hide footer
            },
            addCorrespondence(){
                this.loadingCorrespondence = true
                axios.post('/api/products_orders_to_check', {
                    id: null,
                    product_id: this.productId,
                    products_checks_id: this.newCorrespondence.check.products_checks_id,
                    check_id: this.newCorrespondence.check.check_id,
                    order_id: this.newCorrespondence.order.orders_id,
                    quantity: this.newCorrespondence.quantity,
                    orders_products_id: this.newCorrespondence.order.orders_products_id
                })
                    .then(response => {
                        this.initCorrespondence()
                    })

                this.correspondence.data.push({
                    id: null,
                    product_id: this.productId,
                    products_checks_id: this.newCorrespondence.check.products_checks_id,
                    check_id: this.newCorrespondence.check.check_id,
                    order_id: this.newCorrespondence.order.orders_id,
                    quantity: this.newCorrespondence.quantity
                })

            },
            openOrderDialog(orderId) {
                console.log(orderId)
                EventBus.$emit('openOrderDialog', orderId);
            }

        },
        watch:{
            isActive(){
                if(this.isActive === "tab-check"){
                    if(this.inflow.data !== null){
                        this.$emit('componentReady');
                    }else{
                        this.init()
                    }
                }
            },
        },
        components:{
            Datepicker,
            FindCheck
        }
    }
</script>

<style scoped>

    .currency-select{
        max-width: 100px;
    }

    .date-field{
        max-width: 110px;
        margin: 0 auto;
    }

</style>
<style>
    .date-field .v-text-field .v-input__control .v-input__slot .v-text-field__slot input{
        text-align: center;
    }

</style>