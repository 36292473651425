<template>
    <iframe style="height: 98%; width: 100%; border: none;" src="https://admin:a1z5c6d8@cameramate.com/_tools/?disableMenu=1#defects_config"></iframe>
</template>

<script>
    export default {
        name: "Defects"
    }
</script>

<style scoped>

</style>