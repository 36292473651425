<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row no-gutters justify="center" wrap>
                <v-col sm="2" cols="4" class="d-flex pa-0 ma-0">
                    <v-select
                        class="mr-3 styled-select caption"
                        :items="searchOption"
                        v-model="searchBy"
                        item-value="field"
                        item-text="text"
                        return-object
                        hide-details
                        single-line
                        dense
                    />
                </v-col>
                <v-col sm="10" cols="8" class="d-flex pa-0 ma-0">
                    <v-autocomplete
                        v-model="selected"
                        :items="results"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :autofocus="false"
                        item-text="forSearch"
                        item-value="products_id"
                        @change="add"
                        return-object
                        autocomplete="off"
                        dense
                        hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "SearchProduct",
        props: {
            clearSearch: Boolean
        },
        data(){
            return {
                isLoading: false,
                results: [],
                selected: null,
                search: null,
                searchOption: [
                    {text: 'ID', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter'},
                    {text: 'Name', field: 'descriptions.products_name', condition: 'CONTAINS', type: 'stringfilter' },
                ],
                searchBy:  {text: 'ID', field: 'products_id', condition: 'STARTS_WITH', type: 'numericfilter'},
            }
        },
        watch:{
            async search(val){
                if(val){
                    this.isLoading = true

                     axios.get('/api/products',
                        { params: {
                                'filterGroups[0][field]': this.searchBy.field,
                                'filterGroups[0][filters][0][value]': val,
                                'filterGroups[0][filters][0][condition]':this.searchBy.condition,
                                'filterGroups[0][filters][0][type]':this.searchBy.type,
                                'filterGroups[0][filters][0][operator]':'or',
                                'filterGroups[0][operator]':'or',
                                'with[]':'descriptions',
                                'pagesize':'5'
                            },
                        })
                        .then(response => {
                            response.data.data.forEach(function(element) {
                                element.forSearch = element.products_id+':'+element.descriptions.products_name
                            });
                            this.results = response.data.data
                        })
                        .catch(error => { console.log(error)})
                        .finally(() => (this.isLoading = false))

                }
            },
            clearSearch(val) {
                if (val) {
                    this.selected = null
                    this.items = []
                }
            }
        },
        computed: {
            items: {
                get () {
                    return this.results
                },
                set (value) {
                    this.results = value
                }
            }
        },
        methods:{
            add(){
                this.$emit('add-product', this.selected)
            }
        }
    }
</script>

<style scoped>

    .styled-select{
        width: 25%;
        max-width: 200px;
    }

    .truncate {
        display: block;
        width: calc(100% - 60px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>