var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scroll-content width-100" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex " },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 width-100 products-table",
                attrs: {
                  "footer-props": {
                    itemsPerPageOptions: _vm.pagination.rowsPerPageItems,
                    showFirstLastPage: true
                  },
                  headers: _vm.headers,
                  "hide-default-header":
                    _vm.productsPageConfig.hideDefaultHeader,
                  items: _vm.products,
                  loading: _vm.loading,
                  options: _vm.options,
                  "server-items-length": _vm.pagination.totalItems,
                  dense: "",
                  "fixed-header": "",
                  "item-key": "products_id",
                  "show-select": "",
                  "sort-desc": ""
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "pa-0 ma-0",
                              attrs: { color: "white", dense: "", flat: "" }
                            },
                            [
                              _c("add-new-product"),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "ml-2 mr-0",
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    large: ""
                                  },
                                  on: { click: _vm.updateProductList }
                                },
                                [_c("v-icon", [_vm._v("mdi-cached")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "ml-0 mr-0",
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _vm.enableFilterWithSearch
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: { "max-width": "75%" },
                                      attrs: { justify: "space-around" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-chip-group",
                                            {
                                              attrs: {
                                                "active-class": "primary--text",
                                                "show-arrows": ""
                                              }
                                            },
                                            _vm._l(_vm.tags, function(tag) {
                                              return _c(
                                                "v-chip",
                                                { key: tag },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(tag) +
                                                      "\n                                    "
                                                  )
                                                ]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "d-flex justify-start ml-2 mt-0 mr-2 full-width",
                                      attrs: { flat: "", tile: "" }
                                    },
                                    [
                                      !_vm.productsPageConfig.hideSmartCategory
                                        ? _c("tree-select", {
                                            staticStyle: {
                                              width: "250px",
                                              height: "40px"
                                            },
                                            attrs: {
                                              appendToBody: true,
                                              "auto-load-root-options": true,
                                              normalizer: _vm.normalizer,
                                              options: _vm.productCategories,
                                              searchable: true,
                                              "show-count": true,
                                              clearable: "",
                                              placeholder: "Category",
                                              zIndex: "99999"
                                            },
                                            on: {
                                              input: _vm.onChangeSmartFilter
                                            },
                                            model: {
                                              value:
                                                _vm.smartFilterForm[
                                                  "category.categories_id"
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.smartFilterForm,
                                                  "category.categories_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "smartFilterForm['category.categories_id']"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.productsPageConfig.hideSmartStatus
                                        ? _c("v-select", {
                                            staticClass: "ml-2",
                                            staticStyle: {
                                              width: "150px",
                                              "max-width": "150px",
                                              "margin-top": "5px"
                                            },
                                            attrs: {
                                              items: _vm.statuses,
                                              clearable: "",
                                              dense: "",
                                              placeholder: "Status"
                                            },
                                            on: {
                                              change: _vm.onChangeSmartFilter
                                            },
                                            model: {
                                              value:
                                                _vm.smartFilterForm
                                                  .products_status,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.smartFilterForm,
                                                  "products_status",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "smartFilterForm.products_status"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("v-checkbox", {
                                        staticStyle: { "margin-top": "5px" },
                                        attrs: {
                                          color: "indigo darken-3",
                                          dense: "",
                                          "hide-details": "",
                                          label: "Auto reload"
                                        },
                                        model: {
                                          value:
                                            _vm.productsPageConfig
                                              .autoReloadSmartFilter,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productsPageConfig,
                                              "autoReloadSmartFilter",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "productsPageConfig.autoReloadSmartFilter"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticClass: "ml-2 mr-1",
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    color: "green",
                                    icon: "",
                                    large: ""
                                  },
                                  on: { click: _vm.updateProductList }
                                },
                                [_c("v-icon", [_vm._v("mdi-cached")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    icon: "",
                                    color: "green"
                                  },
                                  on: { click: _vm.exportProducts }
                                },
                                [_c("v-icon", [_vm._v("mdi-download")])],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", {
                                staticStyle: { float: "left" },
                                attrs: { vertical: "" }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "open-on-hover": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    color: "grey",
                                                    icon: "",
                                                    "x-small": ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-tools")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "caption",
                                      attrs: { dense: "", nav: "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide headers"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig
                                                  .hideDefaultHeader,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideDefaultHeader",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideDefaultHeader"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide Age column"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig
                                                  .hideAgeColumn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideAgeColumn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideAgeColumn"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide eBay column"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig
                                                  .hideEbayColumn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideEbayColumn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideEbayColumn"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide category filter"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig
                                                  .hideSmartCategory,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideSmartCategory",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideSmartCategory"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide status filter"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig
                                                  .hideSmartStatus,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideSmartStatus",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideSmartStatus"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Hide sets"
                                            },
                                            model: {
                                              value:
                                                _vm.productsPageConfig.hideSets,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.productsPageConfig,
                                                  "hideSets",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "productsPageConfig.hideSets"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-badge",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    content: _vm.filtersLength,
                                    value: _vm.filtersLength,
                                    color: "green",
                                    overlap: "",
                                    right: ""
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "badge" },
                                      on: { click: _vm.getDataFromApi },
                                      slot: "badge"
                                    },
                                    [_vm._v(_vm._s(_vm.filtersLength))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: _vm.enableFilterWithSearch
                                          ? "blue"
                                          : "grey",
                                        icon: "",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.filterDrawer = !_vm.filterDrawer
                                        }
                                      }
                                    },
                                    [
                                      !_vm.filtersLength
                                        ? _c("v-icon", [
                                            _vm._v(
                                              "\n                                    mdi-filter-outline\n                                "
                                            )
                                          ])
                                        : _c("v-icon", [
                                            _vm._v(
                                              "\n                                    mdi-filter\n                                "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-switch", {
                                staticClass: "ml-1",
                                attrs: { "hide-details": true, dense: "" },
                                model: {
                                  value: _vm.enableFilterWithSearch,
                                  callback: function($$v) {
                                    _vm.enableFilterWithSearch = $$v
                                  },
                                  expression: "enableFilterWithSearch"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider")
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.data-table-select",
                      fn: function(ref) {
                        var isSelected = ref.isSelected
                        var select = ref.select
                        return [
                          _c("v-simple-checkbox", { attrs: { color: "green" } })
                        ]
                      }
                    },
                    {
                      key: "item",
                      fn: function(props) {
                        return [
                          _c("tr", { key: props.item.products_id }, [
                            _c(
                              "td",
                              [
                                _c("v-simple-checkbox", {
                                  attrs: {
                                    value: _vm.isSelected(
                                      props.item.products_id
                                    ),
                                    color: "green"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.selectProduct(
                                        $event,
                                        props.item
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-divider", {
                                  staticStyle: { float: "left" },
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "ma-auto" }, [
                                  _c(
                                    "a",
                                    {
                                      class: props.item.products_status
                                        ? "green--text darken-4 font-weight-medium"
                                        : "red--text",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.openProductDialog(
                                            props.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(props.item.products_id) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    attrs: {
                                      align: "center",
                                      justify: "center",
                                      "no-gutters": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { dense: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openProductDescriptionDialog(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        mdi-alpha-d-circle\n                                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { dense: "", small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openProductDefectsDialog(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "mdi-tools\n                                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        props.item.product_emails_count
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "yellow darken-4",
                                                  dense: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openProductDialog(
                                                      props.item,
                                                      "tab-email"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-email\n                                    "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.openEditProductDialog(
                                              props.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    mdi-square-edit-outline\n                                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-divider", {
                                  staticClass: "mr-2",
                                  staticStyle: { float: "left" },
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("product-info", {
                                  attrs: {
                                    hideSets: _vm.productsPageConfig.hideSets,
                                    item: props.item,
                                    sets: _vm.sets
                                  },
                                  on: {
                                    editName: _vm.openEdit,
                                    productChanged: _vm.productChanged
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.productsPageConfig.hideEbayColumn
                              ? _c(
                                  "td",
                                  [
                                    _c("v-divider", {
                                      staticStyle: { float: "left" },
                                      attrs: { vertical: "" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "v-lazy",
                                      { staticStyle: { height: "100%" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "auto",
                                              "margin-bottom": "auto",
                                              height: "100%"
                                            },
                                            attrs: {
                                              align: "center",
                                              justify: "center",
                                              "no-gutters": ""
                                            }
                                          },
                                          _vm._l(
                                            _vm.productEbayCategories(
                                              props.item
                                            ),
                                            function(ebayCategory, i) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "ma-0 pa-0 caption text-truncate",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  ebayCategory.siteID === 0 &&
                                                  _vm.ebayCategoriesCom
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("v-autocomplete", {
                                                            staticClass:
                                                              "caption font-weight-regular pl-1 ma-0 pa-0",
                                                            staticStyle: {
                                                              "max-width": "99%"
                                                            },
                                                            attrs: {
                                                              "cache-items": true,
                                                              items:
                                                                _vm.ebayCategoriesCom,
                                                              value:
                                                                props.item
                                                                  .ebay_categories[0],
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "catid",
                                                              "return-object":
                                                                "",
                                                              "single-line": ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.productEbayCategoryChanged(
                                                                  $event,
                                                                  props.item
                                                                )
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "prepend",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            ebayCategory.catid ===
                                                                            null
                                                                              ? "red--text text--accent-3 font-weight-bold mt-1"
                                                                              : "font-weight-bold mt-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "US:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key: "append",
                                                                  fn: function() {
                                                                    return [
                                                                      ebayCategory.catid ===
                                                                      null
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mb-1 mt-1 mr-1",
                                                                              attrs: {
                                                                                color:
                                                                                  "red accent-3",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-alarm-light"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  ebayCategory.siteID === 77 &&
                                                  _vm.ebayCategoriesDe
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("v-autocomplete", {
                                                            staticClass:
                                                              "caption font-weight-regular pl-1 ma-0 pa-0",
                                                            staticStyle: {
                                                              "max-width": "99%"
                                                            },
                                                            attrs: {
                                                              "cache-items": true,
                                                              items:
                                                                _vm.ebayCategoriesDe,
                                                              value:
                                                                props.item
                                                                  .ebay_categories[77],
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "catid",
                                                              "return-object":
                                                                "",
                                                              "single-line": ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.productEbayCategoryChanged(
                                                                  $event,
                                                                  props.item
                                                                )
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "prepend",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            ebayCategory.catid ===
                                                                            null
                                                                              ? "red--text text--accent-3 font-weight-bold mt-1"
                                                                              : "font-weight-bold mt-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "DE:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key: "append",
                                                                  fn: function() {
                                                                    return [
                                                                      ebayCategory.catid ===
                                                                      null
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mb-1 mt-1 mr-1",
                                                                              attrs: {
                                                                                color:
                                                                                  "red accent-3",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-alarm-light"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  ebayCategory.siteID === 3 &&
                                                  _vm.ebayCategoriesCouk
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("v-autocomplete", {
                                                            staticClass:
                                                              "caption font-weight-regular pl-1 ma-0 pa-0",
                                                            staticStyle: {
                                                              "max-width": "99%"
                                                            },
                                                            attrs: {
                                                              "cache-items": true,
                                                              items:
                                                                _vm.ebayCategoriesCouk,
                                                              rules: [
                                                                function(
                                                                  value
                                                                ) {
                                                                  return (
                                                                    !!value ||
                                                                    ""
                                                                  )
                                                                }
                                                              ],
                                                              value:
                                                                props.item
                                                                  .ebay_categories[3],
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "catid",
                                                              "return-object":
                                                                "",
                                                              "single-line": ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.productEbayCategoryChanged(
                                                                  $event,
                                                                  props.item
                                                                )
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "prepend",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            ebayCategory.catid ===
                                                                            null
                                                                              ? "red--text text--accent-3 font-weight-bold mt-1"
                                                                              : "font-weight-bold mt-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "UK:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key: "append",
                                                                  fn: function() {
                                                                    return [
                                                                      ebayCategory.catid ===
                                                                      null
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mb-1 mt-1 mr-1",
                                                                              attrs: {
                                                                                color:
                                                                                  "red accent-3",
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-alarm-light"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-divider", {
                                  staticStyle: { float: "left" },
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-lazy",
                                  [
                                    _c("product-price", {
                                      attrs: { item: props.item },
                                      on: { productChanged: _vm.productChanged }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.productsPageConfig.hideAgeColumn
                              ? _c(
                                  "td",
                                  [
                                    _c("v-divider", {
                                      staticStyle: { float: "left" },
                                      attrs: { vertical: "" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "v-lazy",
                                      { staticStyle: { height: "100%" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column justify-center",
                                            staticStyle: { height: "100%" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center ma-auto"
                                              },
                                              [
                                                props.item
                                                  .products_date_available
                                                  ? _c("age-component", {
                                                      attrs: {
                                                        item: props.item
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-divider", {
                                  staticClass: "mr-2",
                                  staticStyle: { float: "left" },
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticStyle: {
                                      "margin-top": "auto",
                                      "margin-bottom": "auto",
                                      height: "100%"
                                    },
                                    attrs: {
                                      align: "center",
                                      justify: "center",
                                      "no-gutters": ""
                                    }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              props.item.products_for_ebay &&
                                              props.item.products_for_ebay[77]
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ma-0 pa-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          "input-value":
                                                            props.item
                                                              .products_for_ebay[77]
                                                              .enabled,
                                                          color: "cyan",
                                                          dense: "",
                                                          "hide-details": "",
                                                          readonly: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.changeEbayDeStatus(
                                                              props.item,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "prepend",
                                                              fn: function() {
                                                                return [
                                                                  props.item
                                                                    .products_on_ebay &&
                                                                  props.item
                                                                    .products_on_ebay[77]
                                                                    ? _c(
                                                                        "v-label",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "red--text",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openEbayItem(
                                                                                        props
                                                                                          .item
                                                                                          .products_on_ebay[77]
                                                                                          .ItemID
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "De: "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-label",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "De: "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isGTC(props.item) &&
                                              props.item.products_for_ebay &&
                                              props.item.products_for_ebay[0]
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ma-0 pa-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          "input-value":
                                                            props.item
                                                              .products_for_ebay[0]
                                                              .enabled,
                                                          color: "cyan",
                                                          dense: "",
                                                          "hide-details": "",
                                                          readonly: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.changeEbayComStatus(
                                                              props.item,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "prepend",
                                                              fn: function() {
                                                                return [
                                                                  props.item
                                                                    .products_on_ebay &&
                                                                  props.item
                                                                    .products_on_ebay[0] &&
                                                                  props.item
                                                                    .products_on_ebay[0]
                                                                    .ItemID
                                                                    ? _c(
                                                                        "v-label",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "red--text",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openEbayItem(
                                                                                        props
                                                                                          .item
                                                                                          .products_on_ebay[0]
                                                                                          .ItemID
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Com: "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-label",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "50px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Com: "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isGTC(props.item) &&
                                              props.item.products_for_ebay &&
                                              props.item.products_for_ebay[71]
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ma-0 pa-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          "input-value":
                                                            props.item
                                                              .products_for_ebay[71]
                                                              .enabled,
                                                          color: "cyan",
                                                          dense: "",
                                                          "hide-details": "",
                                                          readonly: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.changeEbayFrStatus(
                                                              props.item,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "prepend",
                                                              fn: function() {
                                                                return [
                                                                  props.item
                                                                    .products_on_ebay &&
                                                                  props.item
                                                                    .products_on_ebay[71] &&
                                                                  props.item
                                                                    .products_on_ebay[71]
                                                                    .ItemID
                                                                    ? _c(
                                                                        "v-label",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "red--text",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openEbayItem(
                                                                                        props
                                                                                          .item
                                                                                          .products_on_ebay[71]
                                                                                          .ItemID
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Fr: "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-label",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "50px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Fr: "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: props.item.products_status
                                                ? "green darken-4"
                                                : "deep-orange accent-4",
                                              icon: "",
                                              "x-small": ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeProductStatus(
                                                  props.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-circle-slice-8")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-divider", {
                                  staticStyle: { float: "left" },
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-lazy",
                                  { staticStyle: { height: "100%" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column justify-center",
                                        staticStyle: { height: "100%" }
                                      },
                                      [
                                        props.item.products_image &&
                                        _vm.showImage(props.item)
                                          ? _c("img", {
                                              staticClass:
                                                "text-center ma-auto",
                                              staticStyle: {
                                                "max-height": "100px",
                                                "min-height": "100px",
                                                height: "100px"
                                              },
                                              attrs: {
                                                src:
                                                  "https://images.cameramate.com/" +
                                                  props.item.products_id +
                                                  "/" +
                                                  props.item.products_image
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openProductDialog(
                                                    props.item,
                                                    "tab-images"
                                                  )
                                                }
                                              }
                                            })
                                          : _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center ma-auto"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        No image\n                                    "
                                                )
                                              ]
                                            )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    },
                    _vm.loading
                      ? {
                          key: "body.append",
                          fn: function() {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "pa-0",
                                    staticStyle: { height: "2px" },
                                    attrs: { colspan: _vm.headers.length + 1 }
                                  },
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        height: 2,
                                        color: "blue",
                                        indeterminate: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedProducts,
                  callback: function($$v) {
                    _vm.selectedProducts = $$v
                  },
                  expression: "selectedProducts"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filters-wrapper" },
                [
                  _c("product-filters", {
                    attrs: {
                      drawer: _vm.filterDrawer,
                      enableFilterWithSearch: _vm.enableFilterWithSearch,
                      filtersLength: _vm.filtersLength
                    },
                    on: {
                      OnEnableFilterWithSearch: function($event) {
                        _vm.enableFilterWithSearch = true
                      },
                      clearFilters: _vm.clearFilters,
                      closeMenu: _vm.closeFiltersMenu,
                      filterProducts: _vm.filterProducts
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.dialog.name,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "name", $$v)
            },
            expression: "dialog.name"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit product name")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                ref: "name",
                                attrs: {
                                  "auto-grow": "",
                                  dense: "",
                                  "hide-details": "",
                                  rows: "2"
                                },
                                model: {
                                  value:
                                    _vm.editedItem.descriptions.products_name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedItem.descriptions,
                                      "products_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editedItem.descriptions.products_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeEdit("name")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: { click: _vm.saveName }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.dialog.description,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "description", $$v)
            },
            expression: "dialog.description"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { "min-height": "50vh" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit product description")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticStyle: { height: "40vh" } },
                [
                  _c("v-textarea", {
                    ref: "description",
                    attrs: {
                      "auto-grow": "",
                      dense: "",
                      height: "35vh",
                      "hide-details": "",
                      rows: "2"
                    },
                    model: {
                      value: _vm.editedItem.descriptions.products_description,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editedItem.descriptions,
                          "products_description",
                          $$v
                        )
                      },
                      expression: "editedItem.descriptions.products_description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                {
                  staticStyle: {
                    bottom: "0",
                    position: "absolute",
                    width: "100%"
                  }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeEdit("description")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: { click: _vm.saveDescription }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.dialog.note,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "note", $$v)
            },
            expression: "dialog.note"
          }
        },
        [
          _c(
            "v-card",
            { staticStyle: { "min-height": "50vh" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit product note")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                ref: "note",
                                attrs: {
                                  "auto-grow": "",
                                  dense: "",
                                  "hide-details": "",
                                  rows: "2"
                                },
                                model: {
                                  value:
                                    _vm.editedItem.product_note.products_note,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editedItem.product_note,
                                      "products_note",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editedItem.product_note.products_note"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeEdit("note")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", text: "" },
                      on: { click: _vm.saveProductNote }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedProducts.length
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", scrollable: "", width: "35vw" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _vm.selectedProducts.length
                          ? _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "yellow",
                                    fixed: "",
                                    right: ""
                                  }
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                Change selected (" +
                                    _vm._s(_vm.selectedProducts.length) +
                                    ")\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                3529634705
              ),
              model: {
                value: _vm.groupActionDialog,
                callback: function($$v) {
                  _vm.groupActionDialog = $$v
                },
                expression: "groupActionDialog"
              }
            },
            [
              _vm._v(" "),
              _c("group-action-dialog", {
                attrs: { selectedProducts: _vm.selectedProducts },
                on: {
                  closeGroupActionDialog: _vm.closeGroupActionDialog,
                  updateProduct: _vm.updateProduct
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "edit_product_category_" + _vm.editedItem.products_id,
          attrs: {
            fullscreen: false,
            scrollable: true,
            "hide-overlay": "",
            name: "EditProductCategoryDialog",
            width: "70vw"
          },
          on: { "click:outside": _vm.closeEditProductCategoryDialog },
          model: {
            value: _vm.showEditProductCategoryDialog,
            callback: function($$v) {
              _vm.showEditProductCategoryDialog = $$v
            },
            expression: "showEditProductCategoryDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "blue darken-3", dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Edit product categories")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeEditProductCategoryDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("edit-product-category", {
                    attrs: { product: _vm.editedItem }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }