var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "v-container",
        { staticClass: "product-item ma-0 pa-0" },
        [
          _c(
            "v-row",
            { staticClass: "ma-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _vm.isGTC
                    ? _c("v-text-field", {
                        staticClass:
                          "font-weight-regular currency-select ma-0 pa-0",
                        attrs: {
                          rules: _vm.rules,
                          dense: "",
                          "hide-details": "",
                          reverse: "",
                          "single-line": ""
                        },
                        on: { change: _vm.editInfo },
                        model: {
                          value: _vm.price,
                          callback: function($$v) {
                            _vm.price = $$v
                          },
                          expression: "price"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAuction
                    ? _c("v-text-field", {
                        staticClass:
                          "font-weight-regular currency-select ma-0 pa-0",
                        attrs: {
                          rules: _vm.rules,
                          dense: "",
                          "hide-details": "",
                          reverse: "",
                          "single-line": ""
                        },
                        on: { change: _vm.editInfo },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function() {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        color: "red",
                                                        small: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                mdi-alert-circle-outline\n              "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3065306558
                                      )
                                    },
                                    [
                                      _vm._v(
                                        "\n            Auctions!!!\n          "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1723437720
                        ),
                        model: {
                          value: _vm.start_price,
                          callback: function($$v) {
                            _vm.start_price = $$v
                          },
                          expression: "start_price"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pl-2" },
                [
                  _c("v-select", {
                    staticClass:
                      "caption font-weight-regular currency-select pr-1 ma-0 pa-0",
                    staticStyle: { "min-width": "55px" },
                    attrs: {
                      "cache-items": true,
                      items: _vm.currencies,
                      "menu-props": { auto: true, offsetY: true },
                      dense: "",
                      "hide-details": "",
                      label: "",
                      "single-line": ""
                    },
                    on: { change: _vm.editInfo, input: function($event) {} },
                    model: {
                      value: _vm.currency,
                      callback: function($$v) {
                        _vm.currency = $$v
                      },
                      expression: "currency"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between text-center",
                  attrs: { cols: "12" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass: "ma-0 pt-2",
                    attrs: {
                      dense: "",
                      "false-value": "547",
                      "hide-details": "",
                      "true-value": "548"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function() {
                            return [_c("v-label", [_vm._v("Auto Discount:")])]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1973135911
                    ),
                    model: {
                      value: _vm.autoDisc,
                      callback: function($$v) {
                        _vm.autoDisc = $$v
                      },
                      expression: "autoDisc"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between text-center",
                  attrs: { cols: "12" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass:
                      "caption font-weight-regular currency-select pr-1 ma-0 pa-0",
                    staticStyle: {
                      width: "75px",
                      "max-width": "75px",
                      "margin-top": "-4px !important"
                    },
                    attrs: {
                      "cache-items": true,
                      items: _vm.productDiscountList,
                      "menu-props": { auto: true, offsetY: true },
                      dense: "",
                      "hide-details": "",
                      "item-text": "products_options_values_name",
                      "item-value": "products_options_values_name",
                      label: "",
                      "single-line": ""
                    },
                    on: { input: function($event) {} },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function() {
                            return [_c("v-label", [_vm._v("%:")])]
                          },
                          proxy: true
                        },
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              _c(
                                "div",
                                {
                                  class:
                                    "discount-" +
                                    item.products_options_values_name
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.products_options_values_name)
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                {
                                  class:
                                    "discount-" +
                                    data.item.products_options_values_name
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      data.item.products_options_values_name
                                    ) + "\n          "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3790267213
                    ),
                    model: {
                      value: _vm.discount,
                      callback: function($$v) {
                        _vm.discount = $$v
                      },
                      expression: "discount"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.purchase_price
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "text-center font-weight-medium text-caption",
                      attrs: { cols: "12" }
                    },
                    [
                      _vm._v(
                        "\n      Purchase: " +
                          _vm._s(_vm.item.purchase_price.products_price) +
                          " " +
                          _vm._s(_vm.item.purchase_price.currencies_code) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass:
                      "font-weight-regular currency-select ma-0 ml-4 mr-4 pa-0",
                    attrs: {
                      rules: _vm.rules,
                      dense: "",
                      "hide-details": "",
                      reverse: "",
                      "single-line": ""
                    },
                    on: { change: _vm.editInfo },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function() {
                            return [_c("v-label", [_vm._v("BOMIN:")])]
                          },
                          proxy: true
                        },
                        {
                          key: "append-outer",
                          fn: function() {
                            return [_c("v-label", [_vm._v("%")])]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3809315283
                    ),
                    model: {
                      value: _vm.bestoffer_min,
                      callback: function($$v) {
                        _vm.bestoffer_min = $$v
                      },
                      expression: "bestoffer_min"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }