var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.EbayPaypal,
              expression: "EbayPaypal"
            }
          ],
          staticClass: "pt-4"
        },
        [
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.EbayPaypal["ebay"],
                  expression: "EbayPaypal['ebay']"
                }
              ],
              attrs: { "no-gutters": "" }
            },
            _vm._l(_vm.EbayPaypal["ebay"], function(order_products, index) {
              return _c("v-col", { key: index, attrs: { cols: "12" } }, [
                _c("strong", [_vm._v("eBay:")]),
                _vm._v(" "),
                order_products.ebay_receipt
                  ? _c(
                      "a",
                      {
                        class: !order_products.is_stored
                          ? "font-weight-bold"
                          : "",
                        on: {
                          click: function($event) {
                            return _vm.openLink(order_products.ebay_receipt)
                          }
                        }
                      },
                      [_vm._v(_vm._s(order_products.products_name))]
                    )
                  : _c("span", [_vm._v(_vm._s(order_products.products_name))])
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2" }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _vm.EbayPaypal["paypal"]
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _c("strong", [_vm._v("PayPal: ")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openLink(_vm.EbayPaypal["paypal"])
                          }
                        }
                      },
                      [_c("strong", [_vm._v("Instant payment received!")])]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }