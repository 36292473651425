import './bootstrap';
import Vue from 'vue';
import { mapActions } from "vuex";

import vuetify from './plugins/vuetify'
import Router from './routes'
import Store from './store'

import VueMoment from 'vue-moment'
import moment from "moment-timezone";

moment.tz.setDefault('Europe/Berlin');
Vue.use(VueMoment, { moment });

import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

import Vlf from 'vlf'
import localforage from 'localforage'
Vue.use(Vlf, localforage)

//import PrimeVue from 'primevue/config';
//Vue.use(PrimeVue);

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material.css'

import jqxNotification from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxnotification.vue';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    $.jqx.theme = "material";
    $.jqx.data.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token.content,
            'X-Requested-With': 'XMLHttpRequest'
        },
        statusCode: {
            401: function () {
                location.reload();
            }
        }
    });
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token.content,
            'X-Requested-With': 'XMLHttpRequest'
        },
        statusCode: {
            401: function () {
                location.reload();
            }
        }
        },
        { beforeSend: function(xhr) {
            console.log(xhr)
            //thitis.loading=true;
        }
    });
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import App from './views/App.vue'

const app = new Vue({
    el: '#app',
    router: Router,
    store: Store,
    vuetify,
    async created()  {
        await Promise.all([
            this.initCountries(),
            this.initCurrencies(),
            this.initStatuses(),
            this.initEbayTokens(),
            this.initEbayCategoriesCom(),
            this.initEbayCategoriesDe(),
            this.initEbayCategoriesCouk()
        ])
    },
    methods: {
        ...mapActions([
            'initCurrencies',
            'initCountries',
            "initStatuses",
            "initEbayTokens",
            "initEbayCategoriesCom",
            "initEbayCategoriesDe",
            "initEbayCategoriesCouk"
        ])
    },
    render: h => h(App),
});

export default app;