var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.set
    ? _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "indigo--text" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Set Name: ")
                    ]),
                    _vm._v(_vm._s(_vm.set.set_name.replaceAll(" ", " ")))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "purple--text" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Purchase Price: ")
                    ]),
                    _vm._v(
                      _vm._s(Math.round(_vm.set.set_total_price_EUR, 0)) +
                        " EUR"
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "light-green--text" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Sold Price: ")
                    ]),
                    _vm._v(_vm._s(Math.round(_vm.set.set_sold_EUR, 0)) + " EUR")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            icon: "",
                            color: "blue",
                            id: "refresh"
                          },
                          on: { click: _vm.init }
                        },
                        [_c("v-icon", [_vm._v("mdi-cached")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        attrs: {
                          items: _vm.set.products,
                          headers: _vm.headers,
                          "items-per-page": 100,
                          dense: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.products_id",
                              fn: function(props) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openProductDialog(
                                            props.item.product
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(props.item.products_id))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.price",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(props.item.products_price) +
                                      " " +
                                      _vm._s(props.item.currencies_code) +
                                      "\n                    "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.quantity_sum",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.countQuantity(
                                          props.item,
                                          "quantity"
                                        )
                                      ) +
                                      "\n\n                    "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.final_sum",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.countQuantity(
                                          props.item,
                                          "products_price_EUR"
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2956465718
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }