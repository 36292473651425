<template>
    <product-form
        :productid="productsId"
        @productAdded="productAdded"
        @fullscreen="fullscreen"
    />
</template>

<script>
    import ProductForm from '../components/ProductForm';

    export default {
        name: "EditProduct",
        props:{
            productsId: {
                required: true
            }
        },
        methods:{
            fullscreen() {

            },
            productAdded() {

            }
        },
        components:{
            ProductForm
        }
    }
</script>

<style scoped>

</style>