var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-0" },
    [
      _c(
        "v-btn",
        {
          attrs: {
            small: "",
            icon: "",
            color: "pink",
            disabled: _vm.addNewProductDialog
          }
        },
        [
          _c("v-icon", { on: { click: _vm.showAddNewProductDialog } }, [
            _vm._v("mdi-tab-plus")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          key: "add_new_porduct_" + _vm.dialogId,
          attrs: {
            name: "AddNewProductDialog",
            fullscreen: _vm.fullscreenAddNewProductDialog,
            width: "60vw",
            scrollable: true,
            persistent: "",
            transition: false
          },
          model: {
            value: _vm.addNewProductDialog,
            callback: function($$v) {
              _vm.addNewProductDialog = $$v
            },
            expression: "addNewProductDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "", color: "blue darken-3" } },
                    [
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closeAddNewProductDialog.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c("product-form", {
                    attrs: { productid: _vm.productId },
                    on: {
                      productAdded: _vm.newProductAdded,
                      saveProductInfo: _vm.saveProductInfo,
                      fullscreen: function($event) {
                        _vm.fullscreenAddNewProductDialog = true
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }