<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Reset Password</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-alert
                            v-model="show"
                            dismissible
                            :type="status">
                        {{ message }}
                    </v-alert>
                    <v-form aria-label="Reset Password" ref="reset">

                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.email"
                                        :rules="[rules.required, rules.email, rules.max255chars]"
                                        label="Email"
                                        name="email"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-btn
                                color="primary"
                                @click="validate"
                                :loading="loading">
                            Send Reset Link
                        </v-btn>
                        <router-link :to="{name: 'auth.login'}">Login</router-link>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import Auth from '../../auth.js'
    export default {
        name: "ResetEmail",
        data(){
            return {
                input: {
                    email: '',
                },
                rules: {
                    max255chars: v => v.length <= 230 || 'Input too long!',
                    required: value => !!value || '',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                message: '',
                status: 'success',
                loading: false,
                show: false
            }
        },
        methods: {
            validate() {
                if(this.$refs.reset.validate()) {
                    this.show = false
                        this.loading = true
                        this.submit()
                }
            },
            submit() {
                Auth.emailLink(this.input).then(({data}) => {
                    console.log(data)
                    this.status = data.status
                    this.message = data.message
                    this.loading = false
                    this.show = true
                }).catch((error) => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>

</style>