var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.order
        ? _c("OrderAddressForms", {
            attrs: { order: _vm.order },
            on: { submitForm: _vm.submitForm }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          staticClass: "elevation-1 caption px-2 pt-1",
          attrs: {
            "align-space-around": "",
            "justify-start": "",
            "fill-height": ""
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.order && _vm.order.order_products
                ? _c("order-table-info", {
                    attrs: {
                      order_products: _vm.order.order_products,
                      order_shipping: _vm.order.order_shipping
                        ? _vm.order.order_shipping.value
                        : null,
                      order_sub_total: _vm.order.order_sub_total
                        ? _vm.order.order_sub_total.value
                        : null,
                      order_total: _vm.order.order_total
                        ? _vm.order.order_total.value
                        : null
                    },
                    on: {
                      productChanged: _vm.recountProducts,
                      productRemoved: _vm.removeProduct,
                      inputShipping: _vm.countOrderSum
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }