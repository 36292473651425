<template>
   <v-card>
      <v-card-text class="pt-4">
         <OrderAddressForms @saveNewOrder="saveNewOrder"  @submitForm="submitForm" :newOrder="true"  />
      </v-card-text>
   </v-card>

</template>

<script>
    import {mapGetters} from "vuex";
    import OrderAddressForms from "../../../components/OrderAddressForms";

    export default {
        name: "NewOrder",
        data: () => ({

        }),
        computed: {
        },
        mounted(){
            this.$store.commit('setOrder', {})
        },
        methods:{
            submitForm(newOrder){
               //TODO what to do with input value
            },
           saveNewOrder(newOrder){
                this.$emit('newOrderAdded', newOrder)
           },
           close(){
              this.$emit('closeDialog')
           }
        },
        components:{
           OrderAddressForms
        }

    }
</script>

<style scoped>

</style>