<template>
  <v-row>
    <v-col v-if="priceHistoryData" cols="12">
      <v-btn color="blue" icon small text @click="init">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <!--<v-text-field dense-->
      <!--v-model="filterCurrency"-->
      <!--append-icon="search"-->
      <!--label="Filter by currency"-->
      <!--single-line-->
      <!--hide-details-->
      <!--class="px-2"-->
      <!--&gt;</v-text-field>-->
      <v-data-table
          :headers="headers"
          :items="priceHistoryData"
          class="scroll-table"
          dense
          group-by="start"
          hide-default-footer
          show-group-by
      >

        <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length" style="text-align: left;">
            <v-btn @click="toggle" x-small icon :ref="group" :data-open="isOpen">
              <v-icon v-if="isOpen">mdi-minus-circle-outline</v-icon>
              <v-icon v-else>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <span class="ml-1 font-weight-bold">{{ group === 0 ? 'GTC' : 'Auction'  }}</span>
          </td>
        </template>

        <template slot="item.products_price" slot-scope="props">
          {{ parseFloat(props.item.products_price).toFixed(2) }}
        </template>


        <template slot="item.valid_from" slot-scope="props">
                    <span v-if="props.item.valid_from">
                        {{ $moment(props.item.valid_from).format('MM-DD-YYYY') }}
                    </span>

        </template>
        <template slot="item.valid_until" slot-scope="props">
                     <span v-if="props.item.valid_until">
                        {{ $moment(props.item.valid_until).format('MM-DD-YYYY') }}
                     </span>
        </template>

        <template slot="item.rate" slot-scope="props">
          {{ parseFloat(props.item.rate).toFixed(2) }}
        </template>

        <template slot="item.action" slot-scope="props">
          <v-checkbox v-model="props.item.star" class="ma-0 pa-0 justify-center" dense disabled
                      hide-details></v-checkbox>
        </template>
      </v-data-table>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "PriceHistory",
  props: {
    isActive: {},
  },
  data() {
    return {
      filterCurrency: '',
      headers: [
        {
          text: 'Currency', value: 'currency', align: 'center',
          sortable: false, filterable: true, width: "15%", groupable: false,
        },
        {
          text: 'Price', value: 'products_price', align: 'right',
          sortable: false, filterable: false, width: "40%", groupable: false,
        },
        {
          text: 'Valid from', value: 'valid_from', align: 'center',
          sortable: true, filterable: false, width: "10%", groupable: false,
        },
        {
          text: 'Valid Until', value: 'valid_until', align: 'center',
          sortable: true, filterable: false, width: "10%", groupable: false,
        },
        {
          text: 'Rate', value: 'rate', align: 'center',
          sortable: false, filterable: false, width: "15%", groupable: false,
        },
        {
          text: 'Auction', value: 'start', align: 'center',
          sortable: false, filterable: false, width: "10%"
        },
      ],
      priceHistoryData: [],
    }
  },
  computed: {
    ...mapGetters([
      'productId'
    ]),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      axios.get(`/api/products_price?
                filterGroups[0][field]=products_id
                &filterGroups[0][filters][0][value]=${this.productId}
                &filterGroups[0][filters][0][condition]=EQUAL
                &filterGroups[0][filters][0][type]=numericfilter
                &filterGroups[0][filters][0][operator]=or
                &filterGroups[0][operator]=and
                &filterGroups[1][field]=start
                &filterGroups[1][filters][0][value]=0
                &filterGroups[1][filters][0][condition]=EQUAL
                &filterGroups[1][filters][0][type]=numericfilter
                &filterGroups[1][filters][0][operator]=or
                &filterGroups[1][filters][1][value]=1
                &filterGroups[1][filters][1][condition]=EQUAL
                &filterGroups[1][filters][1][type]=numericfilter
                &filterGroups[1][filters][1][operator]=or
                &filterGroups[1][operator]=and
                &filterGroups[2][field]=valid_until
                &filterGroups[2][filters][0][value]=${this.$moment().subtract(30, 'days').format('YYYY-MM-DD') }
                &filterGroups[2][filters][0][condition]=GREATER_THAN_OR_EQUAL
                &filterGroups[2][filters][0][type]=datefilter
                &filterGroups[2][filters][0][operator]=or
                &filterGroups[2][filters][1][value]=
                &filterGroups[2][filters][1][condition]=NULL
                &filterGroups[2][filters][1][type]=stringfilter
                &filterGroups[2][filters][1][operator]=or
                &filterGroups[2][operator]=and
                &pagesize=100000
                &sortdatafield=valid_until
                &sortorder=asc`)
          .then(response => {
            this.priceHistoryData = response.data.data
            console.log(this.priceHistoryData)
            this.$emit('componentReady')

          }).catch(e => {
        console.log(e)
      })
    },
  },
  watch: {
    isActive() {
      if (this.isActive === "tab-price-history") {
        if (this.priceHistoryData !== null) {
          this.init()
        }
      }
    },
  },
}
</script>

<style scoped>

</style>