<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Register</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form aria-label="Register" ref="register">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.email"
                                        :rules="[rules.required, rules.email, rules.max255chars]"
                                        label="Email"
                                        name="email"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.password"
                                        :rules="[rules.required, rules.max255chars, rules.min6chars]"
                                        ref="password"
                                        label="Password"
                                        name="password"
                                        type="password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="input.password_confirmation"
                                        :rules="passwordConfirmationRules"
                                        label="Password Confirm"
                                        name="password_confirmation"
                                        type="password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn
                                @click="validate"
                                :loading="loading"
                                color="primary">Submit</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>

        <v-dialog
                v-model="show"
                persistent
                width="300">
            <v-card>
                <v-card-text>
                    {{message}}
                    <div v-if="url">
                        <p>Click on the URL to be directed to the personalized app login page</p>
                        <p>
                            <a :href="url">{{ url }}</a>
                        </p>
                    </div>
                    <v-progress-linear
                            v-show="loading"
                            indeterminate
                            class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import Auth from '../../auth.js'

    export default {
        name: "Register",
        data(){
            return {
                input: {
                    email: '',
                    password: '',
                    passsword_confirmation: ''
                },
                rules: {
                    max255chars: v => v.length <= 230 || 'Input too long!',
                    min6chars:  v => v.length > 5 || 'Should be minimum 6 characters long!',
                    required: value => !!value || '',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                //Dialog Data
                url: null,
                message: '',
                show: false,
                loading: false
            }
        },
        computed:{
            passwordConfirmationRules() {
                return [
                    v => this.input.password === v || 'Passwords must match',
                    v => !!v || 'Confirmation Passwords is required'
                ];
            },
        },
        methods: {
            validate(){
                if(this.$refs.register.validate()){
                    this.loading = true
                    this.show = true
                    this.message = 'Registering...'
                    this.submit()
                }
            },
            submit(){
                Auth.register(this.input).then(({data}) => {
                    this.loading = false
                    this.message = data.message
                    this.url = data.redirect
                }).catch(error => {
                    this.loading = false
                    this.show = false
                    this.url = null
                })
            }
        }
    }
</script>

<style scoped>

</style>