<template>
    <v-card>
<!--        <v-card-title class="ma-0 pa-0">
            <v-toolbar dark dense color="blue darken-3">
                <v-toolbar-title>Refund - {{ order.orders_id}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click.stop="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </v-card-title>-->
        <v-card-text class="pt-4">
            <v-data-table
                v-if="currentOrder"
                :headers="headers"
                :items="currentOrder.order_products"
                item-key="products_id"
                :disable-pagination=true
                hide-default-header
                hide-default-footer
                class= "elevation-1 width-100 refund-product-table"
            >
                <template slot="item" slot-scope="props">
                    <tr>
                        <td colspan="4">
                            <div class="d-flex align-center">
                                <p class="mr-1 mb-0">({{props.item.products_id}})</p>
                                <p class="eclipse mb-0 mr-1 font-weight-bold">{{ props.item.products_name}}</p>
                                <p class="mb-0 mr-1 Refunded" v-if="props.item.Refunded">- refunded</p>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="!props.item.Refunded">
                        <td>
                            <v-row no-gutters class="ma-0 pl-1">
                                <v-col cols="12" class="ma-0 pa-0">
                                    <v-text-field
                                        dense
                                        hide-details
                                        v-model="props.item.products_quantity"
                                        class="ma-0"
                                    >
                                        <template v-slot:prepend>
                                            <v-label>Q:</v-label>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="my-3">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox v-model="props.item.restock" hide-details dense class="ma-0">
                                        <template #prepend>
                                            <v-label>Restock:</v-label>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </td>
                        <td>
                            <v-row no-gutters class="ma-0">
                                <v-col cols="3" class="ma-0 pa-0">
                                    <v-subheader>Date:</v-subheader>
                                </v-col>
                                <v-col cols="9" class="ma-0 pa-0">
                                    <datepicker
                                            v-model="props.item.date_refund"
                                            class="caption font-weight-regular mr-2"
                                            @input=""
                                    />
                                </v-col>
                                <v-col cols="12" class="ma-0 pa-0">
                                    <tree-select class="pl-3 pr-2"
                                                 :load-options="loadOptions"
                                                 :options="productsOptions"
                                                 :auto-load-root-options="true"
                                                 :searchable="false"
                                                 :normalizer="normalizer"
                                                 placeholder="Select category"
                                                 v-model="props.item.product.targetcatid"
                                                 appendToBody
                                    />
                                </v-col>
                            </v-row>
                        </td>
                        <td class="text-center px-2">
                            <v-btn @click="refundProduct(props.item)" :disabled="!props.item.date_refund" color="red">Refund Item</v-btn>
                        </td>
                    </tr>
                </template>

            </v-data-table>
            <v-progress-linear
                v-else
                color="green darken-4"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </v-card-text>
<!--        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer/>
            <v-btn @click="close">Close</v-btn>
            <v-spacer/>
        </v-card-actions>-->
    </v-card>
</template>

<script>
    import TreeSelect from '@riophae/vue-treeselect';
    import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {mapActions, mapGetters} from "vuex";
    import Datepicker from '@/components/CustomDatepicker.vue';

    export default {
        name: "RefundOrder",
        props: {
            orderId: {
                required: true
            }
        },
        data: () => ({
            productsOptions: null,
            normalizer(node) {
                if(node && node.descriptions){
                    return {
                        id: node.descriptions.categories_id,
                        label: node.descriptions.categories_name,
                        children: node.children,
                    }
                }
            },
            currentOrder: null,
            headers:[
                {
                    text: 'Product',
                    value: 'product',
                    align: 'center',
                    sortable: true,
                    width: '155px',
                },
                {
                    text: 'Category',
                    value: 'category',
                    align: 'center',
                    width: '100%',
                },
                /*{
                    text: 'Date Refund',
                    value: 'date_refund',
                    align: 'center',
                    sortable: true,
                    width:'120px',
                },*/
                {
                    text: 'Action',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                    width: '200px',
                },
            ],
        }),
        computed: {
            ...mapGetters([
                'productCategories',
                'order'
            ]),

        },
        mounted(){
            this.loadOrder()
        },
        methods:{
            async loadOrder(){
                console.log('loadOrder')
                try {
                    await this.$store.dispatch('fetchOrder', this.orderId)
                } catch (ex) {
                    console.log('loadOrder - error')
                } finally {
                    this.order.order_products.map(p => {
                        p.restock = true;
                        return p
                    });
                    this.currentOrder = this.order;
                }
            },
            async refundProduct(product){
                this.loading = true;
                const productsForRefund= [];

                productsForRefund.push({
                    orders_id: product.orders_id,
                    orders_products_id: product.orders_products_id,
                    products_quantity: product.products_quantity,
                    restock: product.restock,
                    targetcatid: product.product.targetcatid,
                    date_refund: product.date_refund
                });

                try {
                    await this.refundOrderProduct(productsForRefund);
                } catch (ex) {
                    console.log('loadOrder - error')
                } finally {
                    this.currentOrder = this.order;
                }

                //this.$emit('orderUpdated');
                this.loading = false;


                // console.log('response', response);
                // console.log('order', this.order);
                //product.Refunded = 1;

            },
            close(){
                this.$emit('closeRefundDialog')
            },
            ...mapActions([
                "refundOrderProduct"
            ]),
            async loadOptions({action}){
                if (action === LOAD_ROOT_OPTIONS) {
                    this.productsOptions = this.productCategories;
                }

            },
        },
        components:{
            TreeSelect,
            Datepicker
        }
    }
</script>

<style>

    .refund-product-table td{
        padding: 8px 12px !important;
    }
    .refund-product-table {
        /*max-height: 500px;
        min-height: 150px;*/
        overflow-y: auto;
        overflow-x: hidden;
    }

    .datepicker{
        width: 150px;
    }

    .width200{
        width: 200px;
    }

</style>
