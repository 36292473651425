var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-center justify-center pa-2" },
    [
      _c(
        "p",
        {
          class: "ma-0 " + _vm.item.orders_status_name.orders_status_name,
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.openOrderDialog }
        },
        [_vm._v("\n        " + _vm._s(_vm.item.orders_id) + "\n        ")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "ma-0" }, [_vm._v(_vm._s(_vm.item.ebay_orderid))]),
      _vm._v(" "),
      _vm.daysFromPaid
        ? _c("p", { staticClass: "ma-0" }, [_vm._v(_vm._s(_vm.daysFromPaid))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }