var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "elevation-1 caption px-2",
      attrs: { "align-space-around": "", wrap: "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { sm7: "", xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "transparent", grow: "" },
                  model: {
                    value: _vm.orderAddressTab,
                    callback: function($$v) {
                      _vm.orderAddressTab = $$v
                    },
                    expression: "orderAddressTab"
                  }
                },
                [
                  _c("v-tabs-slider"),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    {
                      attrs: { href: "#delivery", ripple: "" },
                      on: {
                        click: function() {
                          this$1.showCopyToBillingButton = true
                          this$1.showCopyToDeliveryButton = false
                        }
                      }
                    },
                    [_vm._v("\n                    Delivery\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    {
                      attrs: { href: "#shipping", ripple: "" },
                      on: {
                        click: function() {
                          this$1.showCopyToBillingButton = false
                          this$1.showCopyToDeliveryButton = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Billing\n                    "
                      ),
                      !_vm.isBillingaddressFormValid
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "red",
                                outlined: "",
                                pill: "",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Please fill\n                        "
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-1",
                                  attrs: { color: "red", small: "" }
                                },
                                [_vm._v("error")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  staticClass: "text-right absolute address-form-buttons",
                  attrs: { sm6: "", xs5: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      staticClass: "body-1 address-action",
                      attrs: { color: "primary", small: "" },
                      on: { click: _vm.addressFormCopyToDelivery }
                    },
                    [_vm._v("Copy From Billing\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCopyToDeliveryButton,
                          expression: "showCopyToDeliveryButton"
                        }
                      ],
                      staticClass: "body-1 address-action",
                      attrs: { color: "primary", small: "" },
                      on: { click: _vm.addressFormCopyToBilling }
                    },
                    [_vm._v("Copy From Delivery\n                ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.orderAddressTab,
                    callback: function($$v) {
                      _vm.orderAddressTab = $$v
                    },
                    expression: "orderAddressTab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "mt-0 mb-1",
                      attrs: { eager: true, value: "delivery" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "addressForm",
                          staticClass: "mt-2",
                          model: {
                            value: _vm.addressValid,
                            callback: function($$v) {
                              _vm.addressValid = $$v
                            },
                            expression: "addressValid"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "address",
                              staticStyle: { "padding-top": "5px" },
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1",
                                        attrs: {
                                          "append-icon": _vm.newOrder
                                            ? "mdi-magnify"
                                            : "",
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          colorvaluer: "blue darken-2",
                                          dense: "",
                                          label: "Delivery name"
                                        },
                                        on: {
                                          change: _vm.submitForm,
                                          "click:append": function($event) {
                                            return _vm.openAddCustomerDialog(
                                              "delivery_name"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.form.delivery_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_name",
                                              $$v
                                            )
                                          },
                                          expression: "form.delivery_name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "blue darken-2",
                                          dense: "",
                                          label: "Delivery street address",
                                          placeholder:
                                            "enter delivery street address",
                                          required: ""
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value:
                                            _vm.form.delivery_street_address,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_street_address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.delivery_street_address"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          color: "blue darken-2",
                                          dense: "",
                                          label: "Delivery state",
                                          placeholder: "enter delivery state"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.delivery_state,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_state",
                                              $$v
                                            )
                                          },
                                          expression: "form.delivery_state"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "blue darken-2",
                                          dense: "",
                                          label: "Delivery city",
                                          placeholder: "enter delivery city"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.delivery_city,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_city",
                                              $$v
                                            )
                                          },
                                          expression: "form.delivery_city"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          items: _vm.countries,
                                          rules: _vm.rules.required,
                                          color: "pink",
                                          dense: "",
                                          label: "Delivery country"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.delivery_country,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_country",
                                              $$v
                                            )
                                          },
                                          expression: "form.delivery_country"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "blue darken-2",
                                          dense: "",
                                          label: "Delivery postcode",
                                          placeholder: "enter delivery postcode"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.delivery_postcode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "delivery_postcode",
                                              $$v
                                            )
                                          },
                                          expression: "form.delivery_postcode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "append-icon": _vm.newOrder
                                            ? "mdi-magnify"
                                            : "",
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Email address",
                                          placeholder: "enter email address"
                                        },
                                        on: {
                                          change: _vm.submitForm,
                                          "click:append": function($event) {
                                            return _vm.openAddCustomerDialog(
                                              "customers_email_address"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.form.customers_email_address,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "customers_email_address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.customers_email_address"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "append-outer-icon":
                                            _vm.newOrder ||
                                            !(
                                              _vm.order.ebay_site &&
                                              _vm.order.ebay_site.length
                                            )
                                              ? ""
                                              : "mdi-update",
                                          "hide-details": true,
                                          loading: _vm.updatingPhoneNumber,
                                          rules: _vm.rules.required,
                                          color: "blue darken-2",
                                          dense: "",
                                          label: "Telephone",
                                          placeholder: "enter telephone"
                                        },
                                        on: {
                                          change: _vm.submitForm,
                                          "click:append-outer": function(
                                            $event
                                          ) {
                                            return _vm.getPhoneNumberFromEbay()
                                          }
                                        },
                                        model: {
                                          value: _vm.form.customers_telephone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "customers_telephone",
                                              $$v
                                            )
                                          },
                                          expression: "form.customers_telephone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    {
                      staticClass: "mt-3",
                      attrs: { eager: true, value: "shipping" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "billingaddressForm",
                          model: {
                            value: _vm.billingaddressForm,
                            callback: function($$v) {
                              _vm.billingaddressForm = $$v
                            },
                            expression: "billingaddressForm"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "address",
                              staticStyle: { "padding-top": "5px" },
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1",
                                        attrs: {
                                          "append-icon": _vm.newOrder
                                            ? "mdi-magnify"
                                            : "",
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Billing name",
                                          placeholder: "enter billing name"
                                        },
                                        on: {
                                          change: _vm.submitForm,
                                          "click:append": function($event) {
                                            return _vm.openAddCustomerDialog(
                                              "billing_name"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.form.billing_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_name",
                                              $$v
                                            )
                                          },
                                          expression: "form.billing_name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Billing street address",
                                          placeholder:
                                            "enter billing street address"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value:
                                            _vm.form.billing_street_address,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_street_address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.billing_street_address"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Billing state",
                                          placeholder: "enter billing state"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.billing_state,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_state",
                                              $$v
                                            )
                                          },
                                          expression: "form.billing_state"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Billing city",
                                          placeholder: "enter billing city"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.billing_city,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_city",
                                              $$v
                                            )
                                          },
                                          expression: "form.billing_city"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          items: _vm.countries,
                                          rules: _vm.rules.required,
                                          color: "pink",
                                          dense: "",
                                          label: "Billing country"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.billing_country,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_country",
                                              $$v
                                            )
                                          },
                                          expression: "form.billing_country"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "body-1 mt-4",
                                        attrs: {
                                          "hide-details": true,
                                          rules: _vm.rules.required,
                                          color: "purple darken-2",
                                          dense: "",
                                          label: "Billing postcode",
                                          placeholder: "enter billing postcode"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.billing_postcode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "billing_postcode",
                                              $$v
                                            )
                                          },
                                          expression: "form.billing_postcode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticClass: "hidden-sm-and-up", attrs: { xs12: "" } },
        [_c("v-divider")],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { sm5: "", xs12: "" } },
        [
          _c(
            "v-form",
            { ref: "additionalParams", staticClass: "pt-2" },
            [
              _c(
                "v-list",
                { attrs: { dense: "", flat: "" } },
                [
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "font-weight-regular full-width",
                                    attrs: {
                                      items: _vm.statuses,
                                      rules: _vm.rules.required,
                                      dense: "",
                                      "item-text": "orders_status_name",
                                      "item-value": "orders_status_id",
                                      label: "Status",
                                      "prepend-icon": "timeline"
                                    },
                                    on: { change: _vm.submitForm },
                                    model: {
                                      value: _vm.form.orders_status,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "orders_status", $$v)
                                      },
                                      expression: "form.orders_status"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "caption font-weight-regular full-width",
                                    attrs: {
                                      disabled:
                                        _vm.form.orders_status !==
                                          _vm.OrderStatusEnum.PENDING &&
                                        !_vm.newOrder,
                                      items: _vm.currencies,
                                      rules: _vm.rules.required,
                                      dense: "",
                                      label: "Currency",
                                      "prepend-icon": "euro_symbol"
                                    },
                                    on: { change: _vm.submitForm },
                                    model: {
                                      value: _vm.form.currency,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "currency", $$v)
                                      },
                                      expression: "form.currency"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "caption font-weight-regular full-width",
                                    attrs: {
                                      items: _vm.paymentMethods,
                                      rules: _vm.rules.required,
                                      dense: "",
                                      label: "Payment method",
                                      "prepend-icon": "account_balance_wallet"
                                    },
                                    on: { change: _vm.submitForm },
                                    model: {
                                      value: _vm.form.payment_method,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "payment_method",
                                          $$v
                                        )
                                      },
                                      expression: "form.payment_method"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  !_vm.newOrder
                                    ? _c("v-text-field", {
                                        staticClass:
                                          "caption font-weight-regular",
                                        attrs: {
                                          disabled:
                                            _vm.form.payment_method !==
                                            "PayPal",
                                          dense: "",
                                          label: "PayPal Id",
                                          "prepend-icon": "payment"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.PayPalTransactionID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "PayPalTransactionID",
                                              $$v
                                            )
                                          },
                                          expression: "form.PayPalTransactionID"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "caption font-weight-regular full-width",
                                    attrs: {
                                      items: _vm.activeAuctionAccounts,
                                      dense: "",
                                      label: "Auction account",
                                      "prepend-icon": "account_box"
                                    },
                                    on: { change: _vm.submitForm },
                                    model: {
                                      value: _vm.orderAccount,
                                      callback: function($$v) {
                                        _vm.orderAccount = $$v
                                      },
                                      expression: "orderAccount"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  !_vm.newOrder
                                    ? _c("v-text-field", {
                                        staticClass:
                                          "caption font-weight-regular",
                                        attrs: {
                                          dense: "",
                                          disabled: "",
                                          label: "Source",
                                          "prepend-icon": "payment",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.orderSource,
                                          callback: function($$v) {
                                            _vm.orderSource = $$v
                                          },
                                          expression: "orderSource"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  !_vm.newOrder
                                    ? _c("v-select", {
                                        staticClass:
                                          "caption font-weight-regular full-width",
                                        attrs: {
                                          items: _vm.ebaySites,
                                          dense: "",
                                          label: "eBay Site",
                                          "prepend-icon": "account_box"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.orderEbaySiteId,
                                          callback: function($$v) {
                                            _vm.orderEbaySiteId = $$v
                                          },
                                          expression: "orderEbaySiteId"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  !_vm.newOrder
                                    ? _c("v-text-field", {
                                        staticClass:
                                          "caption font-weight-regular",
                                        attrs: {
                                          dense: "",
                                          label: "EbayID",
                                          "prepend-icon": "account_box"
                                        },
                                        on: { change: _vm.submitForm },
                                        model: {
                                          value: _vm.form.temp_ebay_OrderID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "temp_ebay_OrderID",
                                              $$v
                                            )
                                          },
                                          expression: "form.temp_ebay_OrderID"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("ordered-datepicker", {
                                    staticClass: "caption font-weight-regular",
                                    attrs: {
                                      labelName: "Ordered",
                                      rules: _vm.rules.required
                                    },
                                    on: { input: _vm.submitForm },
                                    model: {
                                      value: _vm.form.date_purchased,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "date_purchased",
                                          $$v
                                        )
                                      },
                                      expression: "form.date_purchased"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("ordered-datepicker", {
                                    staticClass: "caption font-weight-regular",
                                    attrs: {
                                      labelName: "Paid",
                                      rules:
                                        _vm.form.orders_status ===
                                        _vm.OrderStatusEnum.PAID
                                          ? _vm.rules.required
                                          : []
                                    },
                                    on: { input: _vm.submitForm },
                                    model: {
                                      value: _vm.form.date_paid,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "date_paid", $$v)
                                      },
                                      expression: "form.date_paid"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("ordered-datepicker", {
                                    staticClass: "caption font-weight-regular",
                                    attrs: {
                                      labelName: "Shipped",
                                      isCleared: false,
                                      rules:
                                        _vm.form.orders_status ===
                                        _vm.OrderStatusEnum.DELIVERED
                                          ? _vm.rules.required
                                          : []
                                    },
                                    on: { input: _vm.submitForm },
                                    model: {
                                      value: _vm.form.date_shipped,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "date_shipped", $$v)
                                      },
                                      expression: "form.date_shipped"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _vm.form.duty_date && !_vm.newOrder
                                    ? _c("ordered-datepicker", {
                                        staticClass:
                                          "caption font-weight-regular",
                                        attrs: {
                                          labelName: "Cleared",
                                          isCleared: true
                                        },
                                        on: { input: _vm.submitForm },
                                        model: {
                                          value: _vm.form.duty_date.duty_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.duty_date,
                                              "duty_date",
                                              $$v
                                            )
                                          },
                                          expression: "form.duty_date.duty_date"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("ordered-datepicker", {
                                    staticClass: "caption font-weight-regular",
                                    attrs: {
                                      labelName: "Refunded",
                                      rules:
                                        _vm.form.orders_status ===
                                        _vm.OrderStatusEnum.REFUNDED
                                          ? _vm.rules.required
                                          : []
                                    },
                                    on: { input: _vm.submitForm },
                                    model: {
                                      value: _vm.form.date_refund,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "date_refund", $$v)
                                      },
                                      expression: "form.date_refund"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.newOrder
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "body-1 address-action mb-3",
                          attrs: { small: "" },
                          on: { click: _vm.clearForm }
                        },
                        [_vm._v("Clear\n                ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "body-1 address-action mb-3",
                                            attrs: {
                                              color: "success",
                                              small: ""
                                            },
                                            on: { click: _vm.saveNewOrder }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "Create new order\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            719361444
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "You will be able to add products after creating an order."
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "add-customer",
        {
          attrs: { searchBy: _vm.searchByCustomerDialog },
          on: {
            addCustomer: _vm.saveUserToOrder,
            closeDialog: function($event) {
              _vm.searchCustomerDialog = false
            }
          },
          model: {
            value: _vm.searchCustomerDialog,
            callback: function($$v) {
              _vm.searchCustomerDialog = $$v
            },
            expression: "searchCustomerDialog"
          }
        },
        [_vm._v("\n        Search Customer\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: false, scrollable: false, width: "600px" },
          model: {
            value: _vm.isAllert,
            callback: function($$v) {
              _vm.isAllert = $$v
            },
            expression: "isAllert"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0", attrs: { color: "red" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "red darken-3", dark: "", dense: "" } },
                    [_c("v-toolbar-title", [_vm._v("Alert!!!")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticClass: "py-2 mx-auto font-weight-bold font-large-3",
                  attrs: { justify: "center" }
                },
                [
                  _vm._v("\n                Please, fill all "),
                  _c(
                    "span",
                    { staticClass: "px-2", staticStyle: { color: "red" } },
                    [_vm._v('"red"')]
                  ),
                  _vm._v(" fields!\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.isAllert = !_vm.isAllert
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }